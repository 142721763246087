import {NyButton} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React from 'react';

import {cleanObject} from '../../../utils/utilities';
import {Access} from '../../displays/access';
import {OPRAEntryModal} from '../../modals/opraEntry/opraEntry.modal';

export class OPRAEntryCreateBtn extends React.Component {
    static propTypes = {
        crd: PropTypes.number,
        dealId: PropTypes.string,
        tradeExecTime: PropTypes.string,
        dealIdOrig: PropTypes.string,
        onClose: PropTypes.func.isRequired
    };

    state = {
        isSuccessful: false,
        show: false
    };

    _opraEntry = cleanObject({
        crd: this.props.crd,
        dealId: this.props.dealId,
        symbol: this.props.symbol,
        dealIdOrig: this.props.dealIdOrig,
        tradeExecTime: this.props.tradeExecTime
    });

    _onClick = () => {
        this.setState({
            isSuccessful: false,
            show: true
        });
    };

    _onClose = (isSuccessful) => {
        this.setState(
            {
                show: false
            },
            () => {
                this.props.onClose(isSuccessful);
            }
        );
    };

    render() {
        return (
            <Access permissions={['add opra entry']}>
                <NyButton
                    circle
                    data-e2e-tag='createOpraEntryBtn'
                    className='mdi mdi-plus'
                    onClick={this._onClick}
                />
                <OPRAEntryModal
                    hideModal={this._onClose}
                    data={this._opraEntry}
                    show={this.state.show}
                />
            </Access>
        );
    }
}
