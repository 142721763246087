import {agGridCellRenderer} from 'nyse-web-tools-common/lib/react';
import React from 'react';

import {HaltedSymbolsList} from '../../components/lists/haltedSymbols.list';

export const haltedSymbolsColumnDefs = [
    {
        headerName: 'Tape A',
        headerClass: ['text-center'],
        field: 'tapeA',
        cellRendererFramework: agGridCellRenderer((params) => (
            <HaltedSymbolsList
                classes={params.context.classes}
                height={params.context.getRowHeight(params)}
                rowHeight={params.context.rowHeight}
                symbols={params.value}
                tape='tapeA'
            />
        )),
        sortable: false,
        minWidth: 200,
        width: 200
    },
    {
        headerName: 'Tape B',
        headerClass: ['text-center'],
        field: 'tapeB',
        cellRendererFramework: agGridCellRenderer((params) => (
            <HaltedSymbolsList
                classes={params.context.classes}
                height={params.context.getRowHeight(params)}
                rowHeight={params.context.rowHeight}
                symbols={params.value}
                tape='tapeB'
            />
        )),
        sortable: false,
        minWidth: 200,
        width: 200
    },
    {
        headerName: 'Tape C',
        headerClass: ['text-center'],
        field: 'tapeC',
        cellRendererFramework: agGridCellRenderer((params) => (
            <HaltedSymbolsList
                classes={params.context.classes}
                height={params.context.getRowHeight(params)}
                rowHeight={params.context.rowHeight}
                symbols={params.value}
                tape='tapeC'
            />
        )),
        sortable: false,
        minWidth: 200,
        width: 200
    }
];
