import {TemporalAdjusters} from '@js-joda/core';
import {omit} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getMyVolumesBrokerRank = (params) => ({
    types: [
        events.MY_VOLUMES_BROKER_RANK_GET,
        events.MY_VOLUMES_BROKER_RANK_SUCCESS,
        events.MY_VOLUMES_BROKER_RANK_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/volume/broker-rank',
            params: cleanObject({
                ...omit(params, ['date', 'offset']),
                endDate: params.date
                    ? joda.format(joda.toEndOfDay(params.date).with(TemporalAdjusters.lastDayOfMonth()), 'yyyy-MM-dd')
                    : undefined,
                startDate: params.date
                    ? joda.format(joda.toStartOfDay(params.date).with(TemporalAdjusters.firstDayOfMonth()), 'yyyy-MM-dd')
                    : undefined
            })
        }
    }
});
