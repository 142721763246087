export const tradesColumnDefs = [
    {
        headerName: 'Deal Id',
        field: 'dealId',
        pinned: 'left',
        type: 'numericString',
        width: 100
    },
    {
        field: 'executionTime',
        headerName: 'Event Time',
        type: 'dateTime',
        width: 200
    },
    {
        field: 'refExecTime',
        headerName: 'Ref Exec Time',
        type: 'dateTime',
        width: 200
    },
    {
        field: 'symbol',
        headerName: 'Symbol',
        width: 110
    },
    {
        headerName: 'Original Deal Id',
        field: 'dealIdOrig',
        type: 'numericString',
        width: 150
    },
    {
        field: 'side',
        headerName: 'Side',
        width: 110
    },
    {
        field: 'quantity',
        type: 'number',
        headerName: 'Qty',
        width: 110
    },
    {
        field: 'price',
        type: 'priceLong',
        headerName: 'Price',
        width: 110
    },
    {
        field: 'participantId',
        headerName: 'ETPID / MPID'
    },
    {
        field: 'contactId',
        headerName: 'Sub Acct Contact Id',
        permissions: ['XCHI'],
        width: 130
    },
    {
        field: 'accountNumber',
        headerName: 'Account Number',
        width: 130
    },
    {
        field: 'domain',
        headerName: 'Domain',
        width: 160
    },
    {
        field: 'clientOrderId',
        headerName: 'Client Order Id',
        width: 200
    },
    {
        field: 'liquidityIndicator',
        headerName: 'Liquidity Ind.',
        width: 180
    },
    {
        field: 'senderSubId',
        headerName: 'Sender Sub Id',
        width: 150
    },
    {
        field: 'sessionId',
        headerName: 'Session Id',
        width: 190
    },
    {
        field: 'mmType',
        headerName: 'MM Type',
        width: 80
    },
    {
        field: 'orderType',
        headerName: 'Order Type',
        width: 90
    },
    {
        field: 'omsId',
        headerName: 'OMS Id',
        permissions: ['XCHI'],
        width: 90
    },
    {
        field: 'tif',
        headerName: 'TIF',
        width: 100
    },
    {
        field: 'capacity',
        headerName: 'Capacity',
        width: 110
    },
    {
        field: 'tradingSessionElig',
        headerName: 'Trading Session Elig',
        width: 140
    },
    {
        field: 'execInst',
        headerName: 'Exec Inst',
        width: 90
    },
    {
        field: 'extendedExecInst',
        headerName: 'Extended Exec Inst',
        width: 160
    },
    {
        field: 'routingInst',
        headerName: 'Routing Inst',
        width: 100
    },
    {
        field: 'stpFlag',
        headerName: 'STP Flag',
        width: 80
    },
    {
        field: 'proactiveIfLocked',
        headerName: 'Proactive if Locked',
        width: 140
    },
    {
        field: 'quoteType',
        headerName: 'Quote Type',
        width: 110
    },
    {
        field: 'leavesQty',
        headerName: 'Leaves Qty',
        type: 'number',
        width: 90
    },
    {
        field: 'cumulativeQty',
        headerName: 'Cumulative Qty',
        type: 'number',
        width: 120
    },
    {
        field: 'minimumQty',
        headerName: 'Min Qty',
        type: 'number',
        width: 100
    },
    {
        field: 'parentPublicOrderId',
        headerName: 'Parent Public Order Id',
        width: 180
    },
    {
        field: 'childOrderId',
        headerName: 'Child Order Id',
        width: 180
    },
    {
        field: 'publicOrderId',
        headerName: 'Public Order Id',
        width: 180
    },
    {
        field: 'onBehalfOfSubId',
        headerName: 'OnBehalf Of Sub Id',
        width: 140
    },
    {
        field: 'submittingBrokerId',
        headerName: 'Submitting Broker Id',
        width: 180
    },
    {
        field: 'specialOrderType',
        headerName: 'Special Order Type',
        width: 180
    },
    {
        field: 'parentEtpIdMpId',
        headerName: 'Parent ETPID/MPID',
        width: 180
    },
    {
        field: 'parentClorderId',
        headerName: 'Parent ClOrderId',
        width: 180
    },
    {
        field: 'parentOrderId',
        headerName: 'Parent Order Id',
        width: 180
    },
    {
        field: 'billToIndicator',
        headerName: 'Bill To Indicator',
        width: 180
    },
    {
        field: 'clientId',
        headerName: 'Client Id',
        width: 100
    },
    {
        field: 'targetSubId',
        headerName: 'Target Sub Id',
        width: 100
    },
    {
        field: 'limitPrice',
        headerName: 'Limit Price',
        type: 'priceLong',
        width: 140
    },
    {
        field: 'averagePrice',
        headerName: 'Average Price',
        type: 'priceLong',
        width: 140
    },
    {
        field: 'retailInd',
        headerName: 'Retail Ind',
        width: 100
    },
    {
        field: 'nativeProactiveIfLock',
        headerName: 'Native Proactive If Lock',
        width: 160
    },
    {
        field: 'nativeRepriceOrCancel',
        headerName: 'Native Reprice or Cancel',
        width: 160
    },
    {
        field: 'execBroker',
        headerName: 'Exec Broker',
        width: 100
    },
    {
        field: 'reportType',
        headerName: 'Report Type',
        width: 100
    },
    {
        field: 'gatewayConnType',
        headerName: 'Gateway Conn Type',
        width: 140
    },
    {
        field: 'senderType',
        headerName: 'Sender Type',
        width: 100
    },
    {
        field: 'sourceExchange',
        headerName: 'Source Exchange',
        permissions: ['XCHI'],
        width: 120
    },
    {
        field: 'discretion',
        headerName: 'Discretion',
        width: 140
    },
    {
        field: 'parentFlag',
        headerName: 'Parent',
        permissions: ['XCHI'],
        width: 100
    },
    {
        field: 'participantType',
        headerName: 'Participant Type',
        width: 120
    },
    {
        field: 'tape',
        headerName: 'Tape',
        width: 80
    },
    {
        field: 'quoteMessageSeq',
        headerName: 'Quote Message Seq',
        type: 'numericString',
        width: 140
    },
    {
        field: 'tradingSession',
        headerName: 'Trading Session',
        width: 120
    },
    {
        field: 'eventType',
        headerName: 'Event Type',
        width: 80
    },
    {
        field: 'idb',
        headerName: 'IDB',
        permissions: ['XCHI'],
        width: 120
    },
    {
        field: 'dealIdRef',
        headerName: 'Reference Deal Id',
        permissions: ['XCHI'],
        width: 180
    },
    {
        field: 'childOrdDest',
        headerName: 'Child Order Destination',
        permissions: ['XCHI'],
        width: 180
    },
    {
        field: 'settlementType',
        headerName: 'Settlement Type',
        permissions: ['XCHI'],
        width: 120
    }
];
