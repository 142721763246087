import {useQuery} from 'react-query';
import type {QueryFunctionContext} from 'react-query';
import {sentinelMw} from '../api';
import usePlatform from './usePlatform';

export default function useClearingNumberList(sideCrd, firmId) {
    const config = {
        placeholderData: [],
        staleTime: 60 * 1000 * 5
    };
    const platforms = usePlatform();

    const clearingNumberList = useQuery(
        ['/viewgborefdata', sideCrd, firmId, platforms],
        async (context: QueryFunctionContext<[string, any]>): Promise<any> => {
            if (!sideCrd || !firmId) {
                return [];
            }
            const {data} = await sentinelMw.get(context.queryKey[0], {params: {crd: sideCrd, mpId: firmId}});
            return data?.data ?? [];
        },
        config
    );

    return clearingNumberList;
}