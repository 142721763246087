import {isEmpty} from 'lodash-es';
import {NyFooter} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {SnackbarProvider} from 'notistack';

import {BannerHeader} from '../components/displays/banner.header';
import {PageTitle} from '../components/displays/page.title';
import BrowserDetectionModal from '../components/modals/browserDetection.modal';
import {SessionModal} from '../components/modals/session.modal';
import {NavigationHeader} from '../components/navigation/navigation.header';
import {NavigationMenuDesktop} from '../components/navigation/navigation.menu.desktop';
import {NavigationMenuMobile} from '../components/navigation/navigation.menu.mobile';
import {RouteBound} from '../components/route.component';
import environment from '../configs/environment.config';
import {getPillarStatus} from '../stores/pillarStatus/pillarStatus.actions';
import {routeChanged} from '../stores/selectors';
import MessageSnackbar from '../portClientWorker/MessageSnackbar';
import Bowser from 'bowser';
import ReactGA from 'react-ga4';

@connect(['platform', 'platforms', 'permissions', 'user', 'route', 'routes', 'router'], {
    routeChanged,
    getPillarStatus
})
export default class App extends React.PureComponent {
    state = {
        isJwtValid: this.props.user.isJwtValid,
        hasUserStateChanged: false,
        url: '',
        authHeaders: {}
    };

    _isMounted = false;
    _interval = null;
    _isSupportSharedWorker = Bowser.getParser(window.navigator.userAgent).satisfies({
        chrome: '>=90',
        edge: '>=79',
        firefox: '>=60',
        safari: '>=16'
    });

    static getDerivedStateFromProps({user}, {isJwtValid}) {
        return {
            isJwtValid: user.isJwtValid,
            hasUserStateChanged: user.isJwtValid !== isJwtValid
        };
    }

    componentDidMount() {
        this._isMounted = true;
        ReactGA.initialize(environment.environmentUID,{
            send_page_view: false,
            cookie_flags: 'secure'
        });
        ReactGA.send('pageview');

        this.togglePillarStatus(
            this.props.user.isJwtValid && !isEmpty(this.props.permissions)
        );

        if (this.props.user?.sentinel?.accessToken) {
            this.setState({
                url: environment.websocketNotifications,
                authHeaders: {
                    accessToken: this.props.user.sentinel.accessToken
                }
            });
        }
    }

    componentDidUpdate({router: {location}, user: {sentinel}}) {
        if (this.state.hasUserStateChanged) {
            this.togglePillarStatus(this.props.user.isJwtValid);
        }

        if (location.pathname !== this.props.router.location.pathname) {
            ReactGA.initialize(environment.environmentUID,{
                anonymize_ip: true,
                page_path: this.props.router.location.pathname,
                cookie_flags: 'secure'
            });
            ReactGA.send('pageview');

            this.props.routeChanged();
        }

        if (this.props.user?.sentinel?.accessToken && sentinel?.accessToken !== this.props.user.sentinel.accessToken) {
            this.setState({
                url: environment.websocketNotifications,
                authHeaders: {
                    accessToken: this.props.user.sentinel.accessToken
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.togglePillarStatus(false);
    }

    togglePillarStatus = (start) => {
        const { platforms, getPillarStatus } = this.props;

        const fetchPillarStatus = async () => {
            try {
                await Promise.all(platforms.map((platform) => getPillarStatus(platform.mic)));
            }
            catch(error) {
                console.error('Error fetching pillar status:', error);
            }
        };

        if (!this._interval && start) {
            fetchPillarStatus();
            this._interval = setInterval(() => {
                if (this._isMounted) {
                    fetchPillarStatus();
                }
            }, 30000);
        }

        if (this._interval && !start) {
            clearInterval(this._interval);
            this._interval = null;
        }
    };

    render() {
        if (this.props.route.platform && !this.props.platform.mic) {
            return <Redirect to='/' />;
        }

        const messageSubscriptionList = this.props.platforms.map((platform) =>
            `/topic/${platform.mic.toLowerCase()}.message`).concat('/topic/message');

        return (
            <>
                {
                    this._isSupportSharedWorker && this.props.user?.sentinel?.accessToken && (
                        <MessageSnackbar
                            clientConfig={{url: this.state.url, authHeaders: this.state.authHeaders}}
                            subscriptionList={messageSubscriptionList}
                        />
                    )
                }
                <SnackbarProvider maxSnack={7} hideIconVariant>
                    <NavigationHeader/>
                    <NavigationMenuMobile/>
                    <NavigationMenuDesktop/>
                    <BannerHeader/>
                    <BrowserDetectionModal/>
                    <div className='l-main flex-column'>
                        <PageTitle/>
                        <Switch>
                            {this.props.routes.map((state) => (
                                <Route
                                    key={state.url}
                                    exact={state.exact}
                                    path={state.url}
                                    render={({...props}) => (
                                        <RouteBound {...props} {...state} />
                                    )}
                                />
                            ))}
                            <Redirect to='/'/>
                        </Switch>
                    </div>
                    <SessionModal/>
                    <NyFooter/>
                </SnackbarProvider>
            </>
        );
    }
}
