import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {NyTooltip} from 'nyse-web-tools-common/lib/react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
        minWidth: 'unset',
        paddingLeft: 0,
        paddingRight: 0
    }
}));

export const OptnlDataWildcardInfoTooltip = (props) => {

    const classes = useStyles(props);
    return (<NyTooltip
        enterDelay={500}
        trigger={['hover']}
        overlay={
            <div className='definition-tooltip flex-column'>
                <h4 className='m-0'>Supports % as a wildcard parameter</h4>
                <ul style={{marginLeft: '10px', listStyleType: 'disc'}}>
                    <li>%abc will return abc, aabc, and xyzabc, but not xabcd</li>
                    <li>can be used in any position in a search (i.e. a%bc, abc%, or %abc%)</li>
                    <li>can be treated as a regular character if preceeded by backslash <br/>(i..e. a\%b will look for
                        an exact match on a%b)
                    </li>
                </ul>
            </div>
        }>
        <SvgIcon
            data-e2e-tag='optnlDataRegexInfoTooltip'
            className={classes.icon}>
            <path
                fill='currentColor'
                d='M16,16.92C15.67,16.97 15.34,17 15,17C14.66,17 14.33,16.97 14,16.92V13.41L11.5,15.89C11,15.5 10.5,15 10.11,14.5L12.59,12H9.08C9.03,11.67 9,11.34 9,11C9,10.66 9.03,10.33 9.08,10H12.59L10.11,7.5C10.3,7.25 10.5,7 10.76,6.76V6.76C11,6.5 11.25,6.3 11.5,6.11L14,8.59V5.08C14.33,5.03 14.66,5 15,5C15.34,5 15.67,5.03 16,5.08V8.59L18.5,6.11C19,6.5 19.5,7 19.89,7.5L17.41,10H20.92C20.97,10.33 21,10.66 21,11C21,11.34 20.97,11.67 20.92,12H17.41L19.89,14.5C19.7,14.75 19.5,15 19.24,15.24V15.24C19,15.5 18.75,15.7 18.5,15.89L16,13.41V16.92H16V16.92M5,19A2,2 0 0,1 7,17A2,2 0 0,1 9,19A2,2 0 0,1 7,21A2,2 0 0,1 5,19H5Z'
            />
        </SvgIcon>
    </NyTooltip>);
};
