import {events} from '../events';

export const createDownloadAction = ({
    client,
    method,
    parseData,
    parseParams,
    url
}) => ({fileExtension, fileName, params, data}) => (dispatch) => {
    dispatch({
        types: [
            events.DOWNLOADS_GET,
            events.DOWNLOADS_SUCCESS,
            events.DOWNLOADS_ERROR
        ],
        payload: {
            client,
            request: {
                url,
                method,
                fileName,
                fileExtension,
                data: parseData ? parseData(data) : data,
                params: parseParams ? parseParams(params) : params,
                responseType: 'blob',
                onDownloadProgress: (e) => {
                    dispatch({
                        type: events.DOWNLOADS_PROGRESS,
                        payload: {
                            fileName,
                            progress: (e.loaded / e.total) * 100 || 0
                        }
                    });
                }
            }
        }
    });
};
