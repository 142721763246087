import numbro from 'numbro';

export const topAuctionColumnDefs = [
    {
        headerName: 'Symbol',
        field: 'symbol',
        pinned: 'left',
        width: 70
    },
    {
        field: 'primary_exch',
        headerName: 'Primary Exchange',
        width: 110
    },
    {
        field: 'auction_type',
        headerName: 'Auction Type',
        width: 110
    },
    {
        field: 'mktimbside',
        headerName: 'Market Imbalance Side',
        width: 110
    },
    {
        field: 'mktimbalance',
        headerName: 'Market Imbalance',
        type: 'number',
        width: 110
    },
    {
        field: 'totimbside',
        headerName: 'Total Imbalance Side',
        width: 110
    },
    {
        field: 'totimbalance',
        headerName: 'Total Imbalance',
        type: 'number',
        width: 110
    },
    {
        field: 'matchedvol',
        headerName: 'Matched Vol',
        type: 'number',
        width: 110
    },
    {
        field: 'indmatchprice',
        headerName: 'Indicative Matched Price',
        type: 'priceLong',
        width: 110
    },
    {
        field: 'lower_collar',
        type: 'priceLong',
        headerName: 'Lower Collar',
        width: 160
    },
    {
        field: 'higher_collar',
        type: 'priceLong',
        headerName: 'Higher Collar',
        width: 160
    },
    {
        field: 'auconlyprice',
        type: 'priceLong',
        headerName: 'Auction Only Price',
        width: 160
    },
    {
        field: 'bookclearprice',
        type: 'priceLong',
        headerName: 'Book Clearing Price',
        width: 160
    },
    {
        field: 'refprice',
        type: 'priceLong',
        headerName: 'Reference Price',
        width: 160
    },
    {
        field: 'aucind',
        headerName: 'Auction Indicator',
        width: 120
    },
    {
        field: 'frzind',
        headerName: 'Freeze Indicator',
        width: 120
    },
    {
        field: 'significant_imbalance',
        headerName: 'Significant Imbalance',
        width: 170
    },
    {
        field: 'netchange',
        headerName: 'Net Change',
        pinned: 'right',
        cellStyle: ({data: {positiveNetChange, negativeNetChange}}) => ({
            color: positiveNetChange
                ? '#11b009'
                : negativeNetChange
                    ? '#c9001a'
                    : 'initial'
        }),
        cellRenderer: ({data}) =>
            data.netchange === undefined
                ? ''
                : numbro(data.netchange).format({
                    forceSign: true,
                    mantissa: 2,
                    thousandSeparated: true
                }),
        type: 'price',
        width: 120
    }
];
