import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import NyCheckboxInput from 'nyse-web-tools-common/lib/react/form/NyCheckboxInput/NyCheckboxInput';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyRadioButtonInput from 'nyse-web-tools-common/lib/react/form/NyRadioButtonInput/NyRadioButtonInput';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, { useCallback,useEffect } from 'react';

import { capitalizeRole } from '../../../../configs/entitlementRoleMap';
import useResource from '../../../../hooks/useResource.hook';
import {
    clearingFirmDetailsResource,
    ClearingFirmSettings,
    consentResource
} from '../../../../resources/riskSettings.resource';

type ConsentFormProps = {
    classes: {
        alert: string;
        controls: string;
        form: string;
        formBody: string;
    };
    className: string;
    data: any;
    onSuccess: () => void;
};

export const ConsentForm: React.FC<ConsentFormProps> = React.memo((props) => {
    const resources = useResource(consentResource);
    const clearingFirm = useResource<ClearingFirmSettings>(clearingFirmDetailsResource);

    useEffect(() => {
        if (!props.className.includes('hideForm')) {
            clearingFirm.get({
                entityId: props.data.entityId,
                userCrd: props.data.limitCrd,
                userCrdRole: props.data.limitCrdRole
            });
        }
    }, [props.className]);

    const consentValues = {
        limitCrd: props.data.limitCrd,
        consentOption: clearingFirm.data.consent,
        limitCrdRole: props.data.limitCrdRole,
        riskEntity: props.data.riskEntity
    }
    const onConsentSubmit = useCallback(
        (values) =>
            resources.put(
                {
                    entityId: props.data.entityId,
                    limitCrd: values.limitCrd,
                    limitCrdRole: values.limitCrdRole,
                    read:
                        values.consentOption === 'read' ||
                        values.consentOption === 'readwrite',
                    write:
                        values.consentOption === 'write' ||
                        values.consentOption === 'readwrite',
                    version: 'v1'
                },
                values
            ),
        [resources.put]
    );

    return (
        <NyForm
            className={`${props.classes.form} ${props.className}`}
            initialValues={consentValues}
            enableReinitialize
            onSuccess={props.onSuccess}
            onSubmit={onConsentSubmit}>
            <Grid className={props.classes.formBody} container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box display='inline' fontWeight='fontWeightMedium'>
                        {capitalizeRole(props.data.limitCrdRole)}
                        {props.data.limitCrdRole === 'floor_broker'
                            ? ' (Entering Firm)'
                            : ' Firm'}
                        :
                    </Box>
                    &nbsp;{props.data.limitCrd}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display='inline' fontWeight='fontWeightMedium'>
                        Clearing Firm:
                    </Box>
                    &nbsp;{clearingFirm.data.limitCrd}
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box fontWeight='fontWeightMedium'>
                        Entering Firm Consents To Clearing Firm Having The
                        Following Entitlements
                    </Box>
                </Grid>
                <Grid item xs={12} className='form-group'>
                    <NyRadioButtonInput
                        name='consentOption'
                        selectedValue='read'
                        required>
                        a. View Pre-Trade Risk Controls* set by Entering Firm
                    </NyRadioButtonInput>
                    <NyRadioButtonInput
                        name='consentOption'
                        selectedValue='write'
                        required>
                        b. Set Pre-Trade Risk Controls* for Entering Firm
                    </NyRadioButtonInput>
                    <NyRadioButtonInput
                        name='consentOption'
                        selectedValue='readwrite'
                        required>
                        c. Both a and b
                    </NyRadioButtonInput>
                    <NyRadioButtonInput
                        name='consentOption'
                        selectedValue='none'
                        required>
                        d. None (cancels all entitlements for the Clearing Firm
                        from the Entering Firm)
                    </NyRadioButtonInput>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box ml={1} mr={1}>
                        Entering Firm has indicated that Clearing Firm will be
                        entitled to the above-selected Pre-Trade Risk Controls*
                        on the Entering Firm’s trading activity on the Exchange.
                        If Entering Firm selects (b) or (c) from the Entitlement
                        List, Entering Firm: (i) expressly consents to let the
                        Exchange take direction from the Clearing Firm about
                        whether and to what extent to block and cancel orders
                        and suspend or terminate Entering Firm’s access if
                        Pre-Trade Risk Controls* set by the Clearing Firm are
                        breached; and (ii) agrees to hold the Exchange harmless
                        from direction taken from the Clearing Firm in
                        accordance with (i). This Entitlement Consent remains in
                        effect until canceled or modified by Entering Firm by
                        submitting a new Entitlement Consent or by the Exchange.
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <NyCheckboxInput name='consentConfirm' required>
                        Entering Firm Consent
                    </NyCheckboxInput>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box className='disclaimer' ml={1} mr={1} fontSize={10}>
                        *&nbsp;Note: For more information on Pre-Trade Risk
                        Controls, please see:&nbsp;
                        <a href='https://top.nyse.com' target='_blank'>
                            https://top.nyse.com
                        </a>
                        , which may be updated from time to time. The Exchange
                        will take commercially reasonable efforts to provide
                        notice of any changes to the available Pre-Trade Risk
                        Controls via trader notice.
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    component={NySubmitAlert}
                    hasCloseBtn
                    className={props.classes.alert}
                />
                <Grid item xs={12} className={props.classes.controls}>
                    <NySubmitBtn className='space-right-1'/>
                    <NyResetBtn/>
                </Grid>
            </Grid>
        </NyForm>
    );
});
