import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    get,
    isEmpty,
    isEqual,
    noop
} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridProvider,
    NyAlert,
    NyForm,
    NyLoader,
    NyTextInput
} from 'nyse-web-tools-common/lib/react';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import GridDivider from 'nyse-web-tools-common/lib/react/generic/GridDivider/GridDivider';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {v4} from 'uuid';
import {tradeActionMultiEditColumnDefs} from '../../../../configs/columnDefs/tradeActionDetails.columnDefs';
import {useEntitlement} from '../../../../hooks/useEntitlement';
import useResource from '../../../../hooks/useResource.hook';
import {useSndrSubIdOptions} from '../../../../hooks/useSndrSubIdOptions';
import useUser from '../../../../hooks/useUser.hook';
import {capStrategyIdResource} from '../../../../resources/capStrategyId.resource';
import {reasonCodeResource} from '../../../../resources/reasonCode.resource';
import {
    Entitlement,
    FieldsOfInterestInMultiAdjustForm,
    SideTradeType,
    TradeSummaryObj,
    TradeSummaryObjEx,
    myOwnership,
    tradeAdjustmentResource,
    tradeDetailResource
} from '../../../../resources/tradeSummary.resource';
import {
    viewGBOBrokerFirmsResource,
    viewGBOMarketMakerFirmsResource
} from '../../../../resources/viewGBO.resource';
import {ReasonCodeSelect} from '../../../selects/reasonCode.select';
import {MultiEditCustomerSideSection} from '../sections/multiEditCustomerSide.section';
import usePlatform from '../../../../hooks/usePlatform';

const keepAsIs = 'Keep as is';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        flex: '1 0 auto'
    },
    table: {
        maxHeight: 'calc(100% - 40px)',
        height: '100%'
    }
}));

const FLOOR_BROKER_TRADE = 1;
const NON_FLOOR_BROKER_TRADE = 2;

const fieldsOfInterestInMultiAdjustForm: FieldsOfInterestInMultiAdjustForm[] = [
    'custAccount',
    'custClearingNumber',
    'custCmta',
    'custFirm',
    'custFirmId',
    'custOpenClose',
    'custSndrSubId',
    'custSubmittingBkrId',
    'custOptnlData',
    'custOmsId',
    'reasonCode',
    'custCapStrategyId'
];

const isMapValueUnique = (map, field) => map.get(field)?.size === 1;

export const MultiTradeAdjustmentForm: React.FC<{
    data: TradeSummaryObj[];
    crd: number;
}> = React.memo((props) => {
    const classes = useStyles(props);
    const user = useUser();
    const entitlement = useEntitlement() || user.entitlement;
    const mic = usePlatform();

    const [reasonCode, setReasonCode] = useState('');
    const [agGrid, setAgGrid] = useState(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [formContext, setFormContext] = useState(null);
    const [submittedGridData, setSubmittedGridData] = useState([]);

    const selectionFields = ['custFirm', 'custOpenClose', 'custClearingNumber', 'custSubmittingBkrId', 'custCapStrategyId'];
    const inputFields = ['custFirmId', 'custCmta', 'custAccount', 'custOptnlData'];

    const gridData: TradeSummaryObjEx[] = useMemo(() => props.data.map((row: TradeSummaryObj) => {
        const obj: any = {...row};
        fieldsOfInterestInMultiAdjustForm.forEach((field) => {
            obj[`${field}IsUpdatedByUser`] = false;
            obj[`${field}OriginalValue`] = get(row, field, '');
        });
        obj['status'] = 'To be processed';
        obj['reasonCode'] = ['Non-Contractual Field Change', ''];
        obj['reasonCodeOriginalValue'] = ['Non-Contractual Field Change', ''];

        obj['uniquePrimaryKey'] = [
            get(row, 'origTdate', ''),
            get(row, 'evtTyp', ''),
            get(row, 'dealNum', ''),
            get(row, 'selectedSide', '')
        ].join('_');
        return obj;
    }), [props.data]);

    const {
        formValues,
        isBrokerFirmOrTradeChecker,
        isFloorBrokerTrade,
        isMultiEditForAllFields,
        tradeSummaryObjWithMatchingMpId
    } = useMemo(() => {
        const fieldValueMap = new Map<string, Set<any>>();
        fieldsOfInterestInMultiAdjustForm.forEach(field => {
            fieldValueMap.set(field, new Set<any>());
        });
        fieldValueMap.set('tradeTypes', new Set<any>());

        props.data.forEach((row: TradeSummaryObj) => {
            fieldsOfInterestInMultiAdjustForm.forEach(field => fieldValueMap.get(field).add(get(row, field, '')));
            fieldValueMap.get('tradeTypes').add(row.custSubmittingBkrId ? FLOOR_BROKER_TRADE : NON_FLOOR_BROKER_TRADE);
        });

        const getMapValue = (map, field) => {
            if (map.has(field)) {
                const arr = Array.from(map.get(field));
                if (arr.length === 1) {
                    return arr[0];
                } else {
                    return selectionFields.includes(field) ? keepAsIs : '';
                }
            } else {
                return '';
            }
        };

        const formValues: any = {};
        fieldsOfInterestInMultiAdjustForm.forEach((field) => {
            formValues[field] = selectionFields.includes(field) ? keepAsIs : getMapValue(fieldValueMap, field);
        });

        const isBrokerFirmOrTradeChecker = entitlement === Entitlement.BROKER_FIRM || entitlement === Entitlement.TRADE_CHECKER;
        const isOnlyOneTradeType = isMapValueUnique(fieldValueMap, 'tradeTypes');
        const isFloorBrokerTrade = getMapValue(fieldValueMap, 'tradeTypes') === FLOOR_BROKER_TRADE;
        const isUniqueCustFirmId = isMapValueUnique(fieldValueMap, 'custFirmId');
        const isUniqueFloorBrokerBadge = isMapValueUnique(fieldValueMap, 'custSubmittingBkrId');
        // a. non-broker firm, non-trade checker, unique custFirmId, unique trade type
        // b. unique custSubmittingBkrId, unique custFirmId, unique trade type
        // can edit 'custFirm', 'MPID', 'MMID', 'custClearingNumber', 'custOpenClose', 'custSubmittingBkrId'
        const isMultiEditForAllFields = isUniqueCustFirmId && isOnlyOneTradeType && (!isBrokerFirmOrTradeChecker || isUniqueFloorBrokerBadge);

        const tradeSummaryObjWithMatchingMpId = props.data[0];
        return {
            formValues,
            isBrokerFirmOrTradeChecker,
            isFloorBrokerTrade,
            isMultiEditForAllFields,
            tradeSummaryObjWithMatchingMpId
        };
    }, [props.data]);

    const initialValues = useMemo(() => ({...formValues, reasonCode: 'Non-Contractual Field Change'}), [formValues, props.data]);

    const [curFormValues, setCurFormValues] = useState(initialValues);
    const [curApplyFormValues, setCurApplyFormValues] = useState(initialValues);

    const gboResourceParams = {request: {mpidStatus: 'Active', mic}};
    const marketMakerFirmsResource = useResource(viewGBOMarketMakerFirmsResource, gboResourceParams);
    const allSndrSubIdOptions = useMemo(() => marketMakerFirmsResource?.data, [marketMakerFirmsResource]);

    const adjustmentDetailResource = useResource(tradeDetailResource, {
        crd: props.crd,
        origTdate: tradeSummaryObjWithMatchingMpId.origTdate,
        evtTyp: tradeSummaryObjWithMatchingMpId.evtTyp,
        dealNum: tradeSummaryObjWithMatchingMpId.dealNum
    });
    const {
        omsId,
        crd,
        multiEditCustOmsCrd,
        multiEditCustCrd
    } = useMemo(() => {
        const omsId = adjustmentDetailResource?.data?.custOmsId === formValues.custOmsId ? adjustmentDetailResource.data.custOmsId : adjustmentDetailResource.data.contraOrdOmsId;
        const crd = adjustmentDetailResource?.data?.custFirmId === formValues.custFirmId ? adjustmentDetailResource.data.custCrd : adjustmentDetailResource.data.contraOrdCrd;
        const multiEditCustOmsCrd = adjustmentDetailResource?.data?.custOmsCrd;
        const multiEditCustCrd = adjustmentDetailResource?.data?.custCrd;
        return {
            omsId,
            crd,
            multiEditCustOmsCrd,
            multiEditCustCrd
        };
    }, [adjustmentDetailResource, formValues, tradeSummaryObjWithMatchingMpId, props.data]);

    const clearingNumberSideCrd = useMemo(() => isFloorBrokerTrade ?
        adjustmentDetailResource?.data?.custOmsCrd : adjustmentDetailResource?.data?.custCrd, [isFloorBrokerTrade, adjustmentDetailResource]);

    const retrievedCustSndrSubIdOptions = useSndrSubIdOptions({
        sideCrd: crd,
        firmId: formValues.custFirmId,
        tradeType: null,
        sndrSubId: formValues.custSndrSubId,
        csgCustFirm: formValues.custFirm
    });
    const custSndrSubIdOptions = useMemo(() => Array.isArray(retrievedCustSndrSubIdOptions)? retrievedCustSndrSubIdOptions : [], [retrievedCustSndrSubIdOptions])

    const brokerFirmsResource = useResource(viewGBOBrokerFirmsResource, gboResourceParams);
    const custSubmittingBkrIdOptions = useMemo(() => isBrokerFirmOrTradeChecker ? Array.from(brokerFirmsResource?.data?.filter((brokerIdOption) => brokerIdOption.firmId === omsId)) : [], [isBrokerFirmOrTradeChecker, brokerFirmsResource, omsId, props.data]);

    const multiEditCapStrategyIdResource = useResource(capStrategyIdResource, true);
    const multiEditCapStrategyIdOptions = useMemo(() => [{label: '-', value: ''}, ...multiEditCapStrategyIdResource.data], [multiEditCapStrategyIdResource.data]);

    const multiEditReasonCodeResource = useResource(reasonCodeResource, true);
    const multiEditReasonCodeOptions = useMemo(() => [{label: '-', value: ''}, {label: '\u00A0Other', value: 'Other'}, ...multiEditReasonCodeResource.data], [multiEditReasonCodeResource.data]);

    const submissionResource = useResource({
        ...tradeAdjustmentResource,
        headers: {
            userRequestId: v4(),
            meta: JSON.stringify({userAction: 'multi-adjustment'})
        }
    });

    const multiEditCustSndrSubIdOptions = useMemo(() => entitlement === Entitlement.MM_FIRM ? custSndrSubIdOptions : allSndrSubIdOptions, [entitlement, custSndrSubIdOptions, allSndrSubIdOptions]);
    const isMultiAdjustmentCapStrategyIdValid = useMemo(() => props.data.every((data) => !!data?.outCry || (!!data?.qcc &&
        [SideTradeType.FLOOR_BROKER, SideTradeType.FLOOR_BROKER_LOCAL_MM, SideTradeType.FLOOR_BROKER_AWAY_MM].includes(data?.custTradeType))) , [props.data]);
    const isFormUpdatedAfterApply = useMemo(() => !isEqual(curFormValues, curApplyFormValues), [curFormValues, curApplyFormValues]);
    const agGridData = useMemo(() => isFormSubmitted ? submittedGridData : gridData, [gridData, isFormSubmitted, submittedGridData]);
    const tableStatus = useMemo(() => isFormSubmitted ? (submittedGridData.length === 0 ? 'SUBMITTED-TABLE-WITHOUT-ERROR' : 'SUBMITTED-TABLE-WITH-ERROR')
        : 'MULIT-EDIT-TABLE', [isFormSubmitted, submittedGridData]);

    useEffect(() => {
        agGrid && agGrid.refreshCells({force: true});
    }, [
        multiEditCustSndrSubIdOptions,
        multiEditCustOmsCrd,
        multiEditCustCrd,
        multiEditCapStrategyIdOptions,
        multiEditReasonCodeOptions,
        custSubmittingBkrIdOptions
    ]);

    const getDealObj = (data) => ({
        custClearingNumber: get(data, 'custClearingNumber', ''),
        custFirm: get(data, 'custFirm', ''),
        custFirmId: get(data, 'custFirmId', ''),
        custSndrSubId: get(data, 'custSndrSubId', ''),
        custSubmittingBkrId: get(data, 'custSubmittingBkrId', ''),
        custCmta: get(data, 'custCmta', ''),
        custAccount: get(data, 'custAccount', ''),
        custOptnlData: get(data, 'custOptnlData', ''),
        custOpenClose: get(data, 'custOpenClose', ''),
        custCapStrategyId: get(data, 'custCapStrategyId', ''),
        dealNum: get(data, 'dealNum', ''),
        evtTyp: get(data, 'evtTyp', ''),
        origTdate: get(data, 'origTdate', ''),
        otherReason: get(data, 'reasonCode[1]', ''),
        reasonCode: get(data, 'reasonCode[0]', ''),
        selectedSide: get(data, 'selectedSide', '')
    });

    const onReasonCodeChange = useCallback(({value}) => {
        setReasonCode(value);
    }, []);

    const onReady = useCallback((context) => {
        setFormContext(context);
    }, []);

    const onSubmit = useCallback(async ({reasonCode, otherReason, ...values}) => {
        if (!agGrid) {
            return;
        }

        const {crd} = props;
        const map = new Map<string, Array<any>>();
        agGrid.forEachNode(({data}) => {
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            if (!map.has(uniqueKey)) {
                map.set(uniqueKey, new Array());
            }
            map.get(uniqueKey).push(data);
        });

        const keyArray = new Array()
        const objArray = new Array();
        for (let [key, valueArray] of map) {
            if (valueArray.length === 1) {
                // one side action
                const obj = getDealObj(valueArray[0]);
                objArray.push({
                    custFirmId: obj.custFirmId,
                    custFirm: obj.custFirm,
                    custSndrSubId: obj.custSndrSubId,
                    custClearingNumber: obj.custClearingNumber,
                    custSubmittingBkrId: obj.custSubmittingBkrId,
                    custCmta: obj.custCmta,
                    custAccount: obj.custAccount,
                    custOptnlData: obj.custOptnlData,
                    custCapStrategyId: obj.custCapStrategyId,
                    ...(obj.custOpenClose !== '' && {custOpenClose: obj.custOpenClose}),
                    crd,
                    origTdate: obj.origTdate,
                    evtTyp: obj.evtTyp,
                    dealNum: obj.dealNum,
                    selectedSide: obj.selectedSide,
                    reasonCode: obj.reasonCode === 'Other' ? obj.otherReason : obj.reasonCode
                });
                keyArray.push(key);
            } else if (valueArray.length === 2) {
                // two side action
                const custValue = valueArray.find(value => value.selectedSide === myOwnership.ORD);
                const contraOrdValue = valueArray.find(value => value.selectedSide === myOwnership.CONTRA);
                let obj = {};
                if (custValue) {
                    const custObj = getDealObj(custValue);
                    obj = {
                        ...obj,
                        custFirmId: custObj.custFirmId,
                        custFirm: custObj.custFirm,
                        custSndrSubId: custObj.custSndrSubId,
                        custClearingNumber: custObj.custClearingNumber,
                        custSubmittingBkrId: custObj.custSubmittingBkrId,
                        custCmta: custObj.custCmta,
                        custAccount: custObj.custAccount,
                        custOptnlData: custObj.custOptnlData,
                        custCapStrategyId: custObj.custCapStrategyId,
                        ...(custObj.custOpenClose !== '' && {custOpenClose: custObj.custOpenClose}),
                        crd,
                        origTdate: custObj.origTdate,
                        evtTyp: custObj.evtTyp,
                        dealNum: custObj.dealNum,
                        selectedSide: custObj.selectedSide,
                        reasonCode: custObj.reasonCode === 'Other' ? custObj.otherReason : custObj.reasonCode
                    };
                }
                if (contraOrdValue) {
                    const contraOrdObj = getDealObj(contraOrdValue);
                    obj = {
                        ...obj,
                        contraOrdFirmId: contraOrdObj.custFirmId,
                        contraOrdCustFirm: contraOrdObj.custFirm,
                        contraOrdSndrSubId: contraOrdObj.custSndrSubId,
                        contraOrdClearingNumber: contraOrdObj.custClearingNumber,
                        contraOrdSubmittingBkrId: contraOrdObj.custSubmittingBkrId,
                        contraOrdCmta: contraOrdObj.custCmta,
                        contraOrdAccount: contraOrdObj.custAccount,
                        contraOrdOptnlData: contraOrdObj.custOptnlData,
                        contraOrdCapStrategyId: contraOrdObj.custCapStrategyId,
                        ...(contraOrdObj.custOpenClose !== '' && {contraOrdOpenClose: contraOrdObj.custOpenClose}),
                        crd,
                        origTdate: contraOrdObj.origTdate,
                        evtTyp: contraOrdObj.evtTyp,
                        dealNum: contraOrdObj.dealNum,
                        selectedSide: contraOrdObj.selectedSide,
                        reasonCode: contraOrdObj.reasonCode === 'Other' ? contraOrdObj.otherReason : contraOrdObj.reasonCode
                    };
                }
                if (custValue && contraOrdValue) {
                    obj = {
                        ...obj,
                        selectedSide: myOwnership.BOTH
                    };
                }
                objArray.push(obj);
                keyArray.push(key);
            }
        }

        const results = await Promise.allSettled(objArray.map((value) => submissionResource.put(value, {
            origTdate: value.origTdate,
            evtTyp: value.evtTyp,
            dealNum: value.dealNum
        })));
        const keyErrorObj = {};
        results.forEach((result, idx) => result.status !== 'fulfilled' && (keyErrorObj[keyArray[idx]] = get(result, 'reason.detail', 'Error')));

        const errorDataList = new Array();
        agGrid.forEachNode(({data}) => {
            const errorMessage = get(keyErrorObj, [data.origTdate, data.evtTyp, data.dealNum].join('_'), '');
            errorMessage !== '' && errorDataList.push({...data, status: `Error: ${errorMessage}`});
        });

        setSubmittedGridData(errorDataList);
        setIsFormSubmitted(true);
        agGrid.gridPanel.columnApi.setColumnsVisible(['status'], errorDataList.length !== 0);
        agGrid.gridPanel.columnApi.setColumnWidth('status', 300);
    }, [submissionResource, agGrid]);

    const onApply = () => {
        if (agGrid) {
            agGrid.forEachNode((row) => {
                fieldsOfInterestInMultiAdjustForm.forEach((key) => {
                    const isInputField = inputFields.indexOf(key) !== -1;
                    const value = curFormValues[key];
                    if (!isInputField && value === keepAsIs) {
                        // reset aggrid row data value if form value is changed to 'keep as is'
                        _updateRowDataByField(row?.data, key, row?.data[`${key}OriginalValue`]);
                    } else if (key === 'custSndrSubId' && curFormValues['custFirm'] === keepAsIs) {
                        // reset 'mmid' value if form 'customer or firm' value is changed to 'keep as is'
                        _updateRowDataByField(row?.data, key, row?.data[`${key}OriginalValue`]);
                    } else if (isInputField && value === '') {
                        _updateRowDataByField(row?.data, key, row?.data[`${key}OriginalValue`]);
                    } else if (key === 'reasonCode') {
                        _updateRowDataByField(row?.data, key, [value, get(curFormValues, 'otherReason', '')]);
                    } else {
                        _updateRowDataByField(row?.data, key, value);
                    }
                });
            });
            agGrid.refreshCells({force: true});
        }
        setCurApplyFormValues(curFormValues);
    };

    const onReset = () => {
        // reset form value
        formContext && formContext.resetForm();
        // reset table value
        if (agGrid) {
            agGrid.forEachNode((row) => {
                fieldsOfInterestInMultiAdjustForm.forEach((field) => {
                    _updateRowDataByField(row.data, field, row.data[`${field}OriginalValue`]);
                });
            });
            agGrid.refreshCells({force: true});
        }
        setCurApplyFormValues(initialValues);
    };

    const onFormChange = useCallback(
        (values, {setFieldValue}) => {
            if (!isEqual(values?.custFirm, curFormValues?.custFirm)) {
                if (values?.custFirm === keepAsIs && initialValues.custFirm === keepAsIs) {
                    // reset 'mmid' to original 'mmid' if change 'customer or firm' to 'keep as is' and original 'customer or firm' is 'keep as is'
                    setFieldValue('custSndrSubId', initialValues.custSndrSubId);
                } else {
                    // clear 'mmid' if 'customer or firm' is changed
                    setFieldValue('custSndrSubId', '');
                }
            }
            setCurFormValues(values);
        },
        [curFormValues, initialValues]
    );

    const _onGridReady = (grid) => {
        setAgGrid(grid);
        grid.gridPanel.columnApi.setColumnsVisible(['status'], false);
    };

    const onCellValueChanged = (grid, value) => {
        const {
            api,
            colDef: {field},
            data,
            eGridCell
        } = grid;
        // only refresh grid table if 'customer or firm' value is changed
        if (field === 'custFirm') {
            _updateRowDataByField(data, 'custFirm', value);
            _updateRowDataByField(data, 'custSndrSubId', '');
            api.refreshCells({force: true});
        } else {
            _updateRowDataByField(data, field, value);
        }
        // control class since grid table does not update at react flow
        data[`${field}IsUpdatedByUser`] ? eGridCell?.classList?.add('updated-background') : eGridCell?.classList?.remove('updated-background');
    };

    const _updateRowDataByField = (data, field, value) => {
        data[`${field}IsUpdatedByUser`] = !isEqual(data[`${field}OriginalValue`], value);
        data[field] = value;
    };

    return (
        <div className={classes.root}>
            <NyAlert
                title={`Unable to get trade summary details`}
                type='danger'
                show={
                    isMultiEditForAllFields &&
                    !adjustmentDetailResource.pending &&
                    !isEmpty(adjustmentDetailResource.error)
                }
            />
            <NyLoader
                show={
                    adjustmentDetailResource.pending &&
                    !adjustmentDetailResource.error
                }
            />

            <NyForm
                initialValues={initialValues}
                onChange={onFormChange}
                onReady={onReady}
                onSubmit={onSubmit}
            >
                <Grid container alignItems='flex-end' spacing={2}>
                    <Grid item xs={6} className='padding-left-0'>
                        Change Reason Code
                        <ReasonCodeSelect
                            name='reasonCode'
                            resetFields={['otherReason']}
                            onChange={onReasonCodeChange}
                            disabled={
                                submissionResource.pending || isFormSubmitted
                            }
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {reasonCode === 'Other' && (
                            <>
                                <label>Other Reason</label>
                                <NyTextInput name='otherReason' required />
                            </>
                        )}
                    </Grid>

                    {(!isMultiEditForAllFields ||
                        (!adjustmentDetailResource.pending &&
                            adjustmentDetailResource.data &&
                            adjustmentDetailResource.data.custCrd)) && (
                        <MultiEditCustomerSideSection
                            clearingNumberSideCrd={clearingNumberSideCrd}
                            custFirmId={formValues.custFirmId}
                            custClearingNumber={formValues.custClearingNumber}
                            custSubmittingBkrIdOptions={custSubmittingBkrIdOptions}
                            custSndrSubIdOptions={multiEditCustSndrSubIdOptions}
                            disabled={submissionResource.pending || isFormSubmitted}
                            isFloorBroker={isFloorBrokerTrade}
                            isMultiEditForAllFields={isMultiEditForAllFields}
                            isMultiAdjustmentCapStrategyIdValid={isMultiAdjustmentCapStrategyIdValid}
                        />
                    )}
                    <GridDivider />
                    <Grid item xs={12}>
                        <NySubmitBtn disabled={isFormSubmitted || isFormUpdatedAfterApply} />
                        {
                            !isFormSubmitted && (
                                <>
                                    <Button
                                        data-e2e-tag='applyBtn'
                                        color='secondary'
                                        variant='text'
                                        onClick={onApply}
                                    >
                                        Apply
                                    </Button>
                                    <Button
                                        data-e2e-tag='resetBtn'
                                        color='secondary'
                                        variant='text'
                                        onClick={onReset}
                                    >
                                        Reset
                                    </Button>
                                </>
                            )
                        }
                    </Grid>
                </Grid>
            </NyForm>

            <Grid container spacing={2} className='flex-grow'>
                <Grid item xs={12}>
                    <NyAlert
                        show={tableStatus !== 'MULIT-EDIT-TABLE'}
                        type={tableStatus === 'SUBMITTED-TABLE-WITHOUT-ERROR' ? 'success' : 'danger'}
                        title={tableStatus === 'SUBMITTED-TABLE-WITHOUT-ERROR' ? 'Your request was successful!' : 'Error encountered. Please see table below!'}
                    />
                    {
                        tableStatus === 'MULIT-EDIT-TABLE' && (
                            <>
                                <span style={{fontWeight: 'bold'}}>Note:</span>
                                <ol style={{paddingLeft: '25px', margin: 0}}>
                                    <li>
                                        In the table below, updated values are highlighted
                                        in yellow
                                    </li>
                                    <li>
                                        To blank out a text (input) field in the form above the grid, enter one or more spaces and hit 'apply'. 
                                    </li>
                                    <li>
                                        To blank out a text (input) field within the multi-edit grid on an individual record, delete the contents in the cell.
                                    </li>
                                </ol>
                            </>
                        )
                    }
                    {
                        (tableStatus === 'MULIT-EDIT-TABLE' || tableStatus === 'SUBMITTED-TABLE-WITH-ERROR') && (
                            <AgGridProvider
                                className={classes.table}
                                onGridReady={_onGridReady}
                                onLoad={noop}
                                columnNamespace='multiTradeAdjustment'
                                columnDefs={tradeActionMultiEditColumnDefs}
                                rowData={agGridData}
                                suppressRowClickSelection={true}
                                submitOnLoad={false}>
                                <div className='flex-pull-right'>
                                    <AgGridColumnMenuTrigger />
                                    <AgGridExportMenu exportFileName='multiEdit' />
                                </div>
                                <AgGrid
                                    context={{
                                        errors: '',
                                        rowField: 'multiEdit',
                                        primaryKey: 'uniquePrimaryKey',
                                        multiEdit: {
                                            custSndrSubIdOptions: multiEditCustSndrSubIdOptions,
                                            custOmsCrd: multiEditCustOmsCrd,
                                            custCrd: multiEditCustCrd,
                                            capStrategyIdOptions: multiEditCapStrategyIdOptions,
                                            reasonCodeOptions: multiEditReasonCodeOptions,
                                            custSubmittingBkrIdOptions,
                                            isPending: submissionResource.pending
                                        },
                                        onCellValueChanged
                                    }}
                                />
                            </AgGridProvider>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    );
});
