import React from 'react';

import {Link} from '../../components/navigation/navigation.link';

const StatusBtn = (props) => {
    const status = props.data.status;
    const crd = props.data.crd;
    const origTdate = props.data.origTdate;
    return (
        <Link
            to='/:platform/trade-summary/:view'
            params={{
                view: 'transactions'
            }}
            search={`?crd=${crd}&status=${status}`}>
            {props.data.displayStatus}
        </Link>
    );
};

export const transactionDashboardColumnDefs = [
    {
        field: 'status',
        headerName: 'Status',
        cellRendererFramework: StatusBtn
    },
    {
        headerName: 'Count',
        field: 'count'
    }
];
