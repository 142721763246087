import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {noop} from 'lodash-es';
import {AgGrid, AgGridProvider, NyAlert, NyForm} from 'nyse-web-tools-common/lib/react';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useState} from 'react';
import {useQueries} from 'react-query';
import {useUnmount} from 'react-use';
import useResource from '../../../../hooks/useResource.hook';
import {tradeRecallResource, TransactionProps} from '../../../../resources/tradeSummary.resource';
import {multiRecallColumnDef} from './multiRecall.form';

export const MultiRecallFormFields: React.FC<TransactionProps> = React.memo(
    (props) => {

        const recallResource = useResource(tradeRecallResource);
        const [agGrid, setAgGrid] = useState(null);
        const [refetchData, setRefetchData] = useState(false);
        const [formSubmitted, setFormSubmitted] = useState(false);
        const [failedRequest, setFailedRequest] = useState(false);

        const rowNode = (txId) => {
            return agGrid.getRowNode(txId);
        };

        const txId = (url) => {
            const subSubString = url.substring(url.indexOf('transactions') + 1 + 'transactions'.length);
            return subSubString.substring(0 ,subSubString.indexOf('/'));
        };

        const recallTrade = (trade) => {
            const row = rowNode(trade.txId);
            row.setDataValue('processStatus','loading');
            return recallResource.put(
                {
                    crd: props.crd
                },
                {
                    origTdate: trade.origTdate,
                    evtTyp: trade.evtTyp,
                    dealIdRef: trade.dealIdRef,
                    txId: trade.txId
                }
            );
        };

        const recallTradeQueries = useQueries(
            props.data.map((trade) => {
                return {
                    queryKey: ['id', trade.txId],
                    queryFn: () => recallTrade(trade),
                    enabled: false
                };
            })
        );

        const triggerQuery = (queries,agGrid) => {
            return queries.map(async (recallQuery) => {
                await recallQuery.refetch().then((response) => {
                    if (response.isSuccess) {
                        const aRow = agGrid.getRowNode(response.data.data.meta.txId);
                        if (aRow) {
                            aRow.setDataValue('processStatus', response.status);
                            aRow.setDataValue('transactionStatus','RECALLED');
                        }
                    } else if (response.isError) {
                        setFailedRequest(true);
                        const aRow = agGrid.getRowNode(txId(response.error.request.responseURL));
                        if (aRow) {
                            aRow.setDataValue('processStatus', response.status);
                            aRow.setDataValue('error', response.error.detail);
                        }
                    }
                });
                return recallQuery;
            });
        };

        const onGridReady = useCallback((grid) => {
            setAgGrid(grid);
        }, []);

        const onConfirm = useCallback(async () => {
            await Promise.all(triggerQuery(recallTradeQueries,agGrid));
            setRefetchData(true);
        } , [agGrid]);

        useUnmount(() => {
            if(refetchData) {
                props.onClose();
            }
        });

        const onSuccess = useCallback(() => {
            setFormSubmitted(() => true);
        }, []);

        return (
            <div>
                <NyForm
                    onSubmit={onConfirm}
                    onSuccess={onSuccess}>
                    <NyAlert
                        title={'Warning'}
                        detail={
                            'All outstanding allocations need to be executed prior to EOD processing'
                        }
                        type='warning'
                        show
                        className='space-top-1'
                    />
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            { formSubmitted && (
                                <NyAlert

                                    title={`${
                                        failedRequest
                                            ? 'Error encountered for some transaction(s). Please review below.'
                                            : 'Your request was successful!'
                                    }`}
                                    type={failedRequest ? 'warning' : 'success'}
                                    show={formSubmitted}
                                />
                            )}
                        </div>
                    </div>
                    <Divider className='space-bottom-1' />
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <NySubmitBtn
                                color={'primary'}
                                disabled={formSubmitted}
                                className='space-right-1'>
                                RECALL
                            </NySubmitBtn>
                        </div>
                    </div>
                </NyForm>
                <Divider className='space-bottom-1'/>
                <Grid container alignItems='flex-end' spacing={2}>
                    <Grid item xs={12}>
                        <AgGridProvider
                            onGridReady={onGridReady}
                            autoSize={true}
                            onLoad={noop}
                            columnNamespace='multiRecall'
                            columnDefs={multiRecallColumnDef}
                            primaryKey='txId'
                            rowData={props.data}
                            suppressRowClickSelection={true}
                            submitOnLoad={false}>
                            <AgGrid height={700} />
                        </AgGridProvider>
                    </Grid>
                </Grid>

            </div>

        );
    });
