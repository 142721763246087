import {LocalDateTime} from '@js-joda/core';

import {cdmMw} from '../api';

type TransactionActivityResource = {
    rowData: any[];
    timeStamp: LocalDateTime;
};

export const transactionActivityResource = {
    api: cdmMw,
    url: () => `/api/options-trades/summary`,
    useCache: true,
    parseResponse: ({data}): TransactionActivityResource => ({
        rowData: data,
        timeStamp: LocalDateTime.now()
    }),
    defaultValue: {
        rowData: [],
        timeStamp: LocalDateTime.now()
    }
};
