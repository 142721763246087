import {LocalDateTime} from '@js-joda/core';
import {CircularProgress} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import NyAlert from 'nyse-web-tools-common/lib/react/generic/NyAlert/NyAlert';
import React from 'react';

import {Access} from '../components/displays/access';
import {useGboRefData} from '../hooks/useGboRefData.hook';
import useResource from '../hooks/useResource.hook';
import {gboRefDataResource} from '../resources/gboRefData.resource';

export const GboRefDataPage = () => {
    const gboRefData = useGboRefData();
    const pullGboData = useResource(gboRefDataResource);

    const formatDateString = (date: LocalDateTime): string => {
        return `${date.month()} ${date.dayOfMonth()}, ${date.year()} at ${
            date.toString().split('T')[1]
        } EST`;
    };

    const onSubmit = () => pullGboData.put();

    const onSuccess = async () => {
        await gboRefData.refetch();
    };

    const firmMap = {
        entering: 'Entering',
        clearing: 'Clearing',
        marketMaker: 'Market Maker',
        broker: 'Floor Broker'
    };

    return (
        <NyForm onSubmit={onSubmit} onSuccess={onSuccess}>
            {gboRefData.isLoading && (
                <CircularProgress color='secondary' size={14} />
            )}
            <NyAlert
                show={gboRefData.isError}
                type='danger'
                title='Error occurred fetching GBO cache data information.'
            />
            {gboRefData.isFetched && (
                <>
                    {Object.entries(gboRefData.data?.data ?? {}).map(
                        ([key, value]) => {
                            return (
                                <p key={key}>
                                    {firmMap[key] || key} Firm last refreshed on
                                    {' ' +
                                        formatDateString(
                                            LocalDateTime.parse(value as string)
                                        )}
                                </p>
                            );
                        }
                    )}
                    <Access permissions={['update gbo ref data']}>
                        <NySubmitBtn>PULL FROM GBO</NySubmitBtn>
                    </Access>
                </>
            )}
            <Box component={NySubmitAlert} py={1} />
        </NyForm>
    );
};
