import { AxiosAdapter, AxiosRequestConfig } from 'axios';
import LRUCache from 'lru-cache';
import buildURL from 'axios/lib/helpers/buildURL';

const FIVE_MINUTES = 1000 * 60 * 5;
const CAPACITY = 100;

type Options = {
    enabledByDefault?: boolean,
	cacheFlag?: string,
	defaultCache?: any,
    customCacheKeyGenerator?: (axiosRequestConfig: AxiosRequestConfig) => string,
}

function buildSortedURL(...args: any[]) {
    const url = buildURL(...args);
    const [urlPath, urlQueryString] = url.split('?');
    return urlQueryString ? `${urlPath}?${urlQueryString.split('&').sort().join('&')}`: url;
}

function isCacheLike(cache: any) {
    return typeof cache.get === 'function'
        && typeof cache.set === 'function'
        && (typeof cache.delete === 'function' || typeof cache.del === 'function');
}

export function cacheAdapterEnhancer(adapter: AxiosAdapter, options: Options = {}): AxiosAdapter {
    const {
        enabledByDefault = true,
        cacheFlag = 'cache',
        defaultCache = new LRUCache({ maxAge: FIVE_MINUTES, max: CAPACITY }),
        customCacheKeyGenerator = null,
    } = options;
    return config => {
        const {
            url,
            method,
            params,
            paramsSerializer,
            forceUpdate,
        } = config;
        const useCache = (config[cacheFlag] !== void 0 && config[cacheFlag] !== null) ? config[cacheFlag] : enabledByDefault;
        if (method === 'get' && useCache) {
            const cache = isCacheLike(useCache) ? useCache : defaultCache;
            const index = (customCacheKeyGenerator && typeof customCacheKeyGenerator === 'function')
                ? customCacheKeyGenerator(config) : buildSortedURL(url, params, paramsSerializer);
            let responsePromise = cache.get(index);
            if (!responsePromise || forceUpdate) {
                responsePromise = (async () => {
                    try {
                        return await adapter(config);
                    } catch (e) {
                        'delete' in cache ? cache.delete(index) : cache.del(index);
                        throw e;
                    }
                })();
                cache.set(index, responsePromise);
                return responsePromise;
            }
            return responsePromise;
        }
        return adapter(config);
    };
}
