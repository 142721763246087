import Divider from '@material-ui/core/Divider';
import {isEmpty, pick} from 'lodash-es';
import {NyForm, NySelectInput} from 'nyse-web-tools-common/lib/react';
import {NyTextInput} from 'nyse-web-tools-common/lib/react';
import {NyButton} from 'nyse-web-tools-common/lib/react';
import {NyLoader} from 'nyse-web-tools-common/lib/react';
import {NyAlert} from 'nyse-web-tools-common/lib/react';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useMemo, useState} from 'react';

import {useEntitlement} from '../../../../hooks/useEntitlement';
import useResource from '../../../../hooks/useResource.hook';
import {useSndrSubIdOptions} from '../../../../hooks/useSndrSubIdOptions';
import {useSubmittingBkrIdOptions} from '../../../../hooks/useSubmittingBkrIdOptions';
import useUser from '../../../../hooks/useUser.hook';
import {reasonCodeResource} from '../../../../resources/reasonCode.resource';
import {
    CustFirm,
    Entitlement,
    SideTradeType,
    TradeSummaryProps,
    TradeSummaryTabs,
    contraBothSideFields,
    contraSideFields,
    customerSideFields,
    myOwnership,
    requiredFields,
    tradeApprovalResource,
    tradeRejectionResource
} from '../../../../resources/tradeSummary.resource';
import {
    viewGBOBrokerFirmsResource,
    viewGBOMarketMakerFirmsResource
} from '../../../../resources/viewGBO.resource';
import {cleanObject} from '../../../../utils/utilities';
import {CustomerSideSection} from '../sections/customerSide.section';
import usePlatform from '../../../../hooks/usePlatform';

export const TradeApprovalForm: React.FC<TradeSummaryProps> = React.memo(
    (props) => {
        const isCustFloorBroker =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER;
        const isCustFloorBrokerOnBehalfOfLocalMM =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isCustFloorBrokerOnBehalfOfAwayMM =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER_AWAY_MM;

        const [formSubmitted, setFormSubmitted] = useState(false);
        const [formRejected, setFormRejected] = useState(false);

        const useReasonCodeResource = useResource<
            {label: string; value: string}[]
        >(reasonCodeResource, true);
        const reasonCodeOptions = useMemo(
            () => [
                {label: '-', value: ''},
                {label: '\u00A0Other', value: 'Other'},
                ...useReasonCodeResource.data
            ],
            [useReasonCodeResource.data]
        );

        const isReasonFromOptions = useMemo(
            () =>
                reasonCodeOptions.some(
                    (reasonCodeOption) =>
                        reasonCodeOption.value === props.csgData.reason
                ),
            [reasonCodeOptions, props.csgData.reason]
        );
        const [reasonCode, setReasonCode] = useState(
            isReasonFromOptions ? props.csgData.reason : 'Other'
        );
        const [otherReason, setOtherReason] = useState(
            isReasonFromOptions ? '' : props.csgData.reason
        );
        const approveResource = useResource(tradeApprovalResource);
        const rejectResource = useResource(tradeRejectionResource);

        const custSubmittingBkrIdOptions = useSubmittingBkrIdOptions({
            sideCrd: props.data.custCrd,
            firmId: props.data.custFirmId,
            tradeType: props.data.custTradeType,
            submittingBkrId: props.data.custSubmittingBkrId
        });
        const custSndrSubIdOptions = useSndrSubIdOptions({
            sideCrd: props.data.custCrd,
            firmId: props.data.custFirmId,
            tradeType: props.data.custTradeType,
            sndrSubId: props.data.custSndrSubId,
            csgCustFirm: props.csgData.custSndrSubId
        });

        const gboResourceParams = {
            request: {
                mpidStatus: 'Active',
                mic: usePlatform()
            }
        };

        const brokerFirmsResource = useResource(
            viewGBOBrokerFirmsResource,
            gboResourceParams
        );
        const marketMakerFirmsResource = useResource(
            viewGBOMarketMakerFirmsResource,
            gboResourceParams
        );

        const allSndrSubIdOptions = useMemo(
            () => marketMakerFirmsResource.data,
            [marketMakerFirmsResource.data]
        );
        const allSubmittingBkrIdOptions = useMemo(
            () => brokerFirmsResource.data,
            [brokerFirmsResource.data]
        );

        const user = useUser();
        const entitlement = useEntitlement() || user.entitlement;

        const isCustFloorTrade =
            isCustFloorBroker ||
            isCustFloorBrokerOnBehalfOfAwayMM ||
            isCustFloorBrokerOnBehalfOfLocalMM;

        const isBrokerFirm = entitlement === Entitlement.BROKER_FIRM;

        const initialValues = useMemo(
            () => ({
                ...props.data,
                reasonCode: isReasonFromOptions
                    ? props.csgData.reason
                    : 'Other',
                otherReason: !isReasonFromOptions && props.csgData.reason
            }),
            [props.data, props.csgData.reason, isReasonFromOptions]
        );

        const onSubmit = useCallback(
            ({reasonCode, otherReason, ...values}) =>
                approveResource.put(
                    cleanObject({
                        crd: props.crd,
                        ...pick(values, [
                            ...requiredFields,
                            ...customerSideFields,
                            ...contraSideFields,
                            ...(values.selectedSide === myOwnership.BOTH
                                ? contraBothSideFields
                                : [])
                        ]),
                        ...(reasonCode === 'Other'
                            ? {reasonCode: otherReason}
                            : {reasonCode: reasonCode})
                    }),
                    {
                        origTdate: props.data.origTdate,
                        evtTyp: props.data.evtTyp,
                        dealIdRef: props.data.dealIdRef,
                        txId: props.data.txId
                    }
                ),
            [approveResource, props.crd, props.data.txId]
        );

        const onReject = useCallback(() => {
            rejectResource.put(
                cleanObject({
                    crd: props.crd,
                    ...(reasonCode === 'Other'
                        ? {reasonCode: otherReason}
                        : {reasonCode: reasonCode})
                }),
                {
                    origTdate: props.data.origTdate,
                    evtTyp: props.data.evtTyp,
                    dealIdRef: props.data.dealIdRef,
                    txId: props.data.txId
                }
            );
            setFormRejected(true);
        }, [
            rejectResource,
            props.crd,
            props.data.txId,
            reasonCode,
            otherReason
        ]);

        const onReasonCodeChange = useCallback(({value}) => {
            setReasonCode(value);
        }, []);

        const onOtherReasonChange = useCallback(({value}) => {
            setOtherReason(value);
        }, []);

        const onSuccess = useCallback(() => {
            setFormSubmitted(() => true);
        }, []);

        const onFormChange = useCallback(
            (values, {setFieldValue}) => {
                const custFirmIdUsingSubmittingBkrId = allSubmittingBkrIdOptions.find(
                    (custSubmittingBkrIdOption) =>
                        custSubmittingBkrIdOption.value ===
                        values.custSubmittingBkrId
                )?.firmId;

                if (isBrokerFirm || isCustFloorTrade) {
                    if (custFirmIdUsingSubmittingBkrId) {
                        setFieldValue(
                            'custFirmId',
                            custFirmIdUsingSubmittingBkrId
                        );
                    }
                }
            },
            [
                allSubmittingBkrIdOptions,
                allSndrSubIdOptions,
                isBrokerFirm,
                isCustFloorTrade
            ]
        );

        return (
            <>
                {!useReasonCodeResource.pending &&
                    !isEmpty(useReasonCodeResource.data) && (
                        <NyForm
                            initialValues={initialValues}
                            className={props.className}
                            onChange={onFormChange}
                            onSuccess={onSuccess}
                            onSubmit={onSubmit}>
                            <div className='row flex-0-0 space-top-1'>
                                <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                                    <label>(Change) Reason Code</label>
                                    <NySelectInput
                                        name='reasonCode'
                                        options={reasonCodeOptions}
                                        resetFields={['otherReason']}
                                        onChange={onReasonCodeChange}
                                        disabled={formSubmitted}
                                        required
                                    />
                                </div>
                                {reasonCode === 'Other' && (
                                    <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                                        <label>Other Reason</label>
                                        <NyTextInput
                                            name='otherReason'
                                            onChange={onOtherReasonChange}
                                            required
                                        />
                                    </div>
                                )}
                            </div>
                            <CustomerSideSection
                                custCrd={props.data.custCrd}
                                custFirmId={props.data.custFirmId}
                                custFirm={props.data.custFirm}
                                csgCustFirm={props.csgData.custFirm}
                                custTradeType={props.data.custTradeType}
                                custClearingNumber={
                                    props.data.custClearingNumber
                                }
                                qccTrade={props.data.qccTrade}
                                outcryTrade={props.data.outcryTrade}
                                custSide={props.data.custSide}
                                selectedSide={props.data.selectedSide}
                                currentTab={TradeSummaryTabs.contraApproval}
                                custSubmittingBkrIdOptions={
                                    custSubmittingBkrIdOptions
                                }
                                custSubmittingBkrId={
                                    props.data.custSubmittingBkrId
                                }
                                custSndrSubIdOptions={
                                    entitlement === Entitlement.MM_FIRM
                                        ? custSndrSubIdOptions
                                        : allSndrSubIdOptions
                                }
                                disabled={formSubmitted}
                                isMultiAdjustment={false}
                                isMultiEditForAllFields={false}
                            />
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <NySubmitAlert hasCloseBtn />
                                    {rejectResource.pending && (
                                        <NyLoader show />
                                    )}
                                    {!rejectResource.pending &&
                                        !rejectResource.error &&
                                        rejectResource.data && (
                                            <NyAlert
                                                title={
                                                    'Your request was successful!'
                                                }
                                                detail={rejectResource.data}
                                                type='success'
                                                show={
                                                    !isEmpty(
                                                        rejectResource.data
                                                    )
                                                }
                                            />
                                        )}
                                    {!rejectResource.pending &&
                                        rejectResource.error &&
                                        !approveResource.error &&
                                        isEmpty(approveResource.data) && (
                                            <NyAlert
                                                title='Error'
                                                detail={
                                                    rejectResource.error.detail
                                                }
                                                type='danger'
                                                show={
                                                    !isEmpty(
                                                        rejectResource.error
                                                    )
                                                }
                                            />
                                        )}
                                </div>
                            </div>
                            <Divider className='space-bottom-1' />
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <NySubmitBtn
                                        disabled={formSubmitted || formRejected}
                                        data-e2e-tag='submitBtn'
                                        className='space-right-1'>
                                        Approve
                                    </NySubmitBtn>
                                    <NyButton
                                        danger
                                        className='space-right-1'
                                        data-e2e-tag='rejectBtn'
                                        onClick={onReject}
                                        disabled={
                                            formSubmitted || formRejected
                                        }>
                                        Reject
                                    </NyButton>
                                    {/* todo:: use disable prop instead of hide after upgrade */}
                                    {!formSubmitted && <NyResetBtn />}
                                </div>
                            </div>
                        </NyForm>
                    )}
            </>
        );
    }
);
