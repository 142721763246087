import cn from 'classnames';
import {isEmpty, omit} from 'lodash-es';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import pathToRegex from 'path-to-regexp';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';

import {cleanObject, hasPermissions} from '../../utils/utilities';

@connect(['user', 'platform', 'route', 'routes'])
export class NavigationSectionMenu extends React.Component {
    static propTypes = {
        route: PropTypes.shape({
            url: PropTypes.string,
            permissions: PropTypes.arrayOf(PropTypes.string),
            group: PropTypes.string,
            title: PropTypes.string,
            path: PropTypes.string,
            platform: PropTypes.string
        }),
        routes: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                permissions: PropTypes.arrayOf(PropTypes.string),
                group: PropTypes.string,
                title: PropTypes.string,
                path: PropTypes.string,
                platform: PropTypes.string
            })
        ),
        queryParams: PropTypes.object,
        views: PropTypes.arrayOf(
            PropTypes.shape({
                disabled: PropTypes.bool,
                params: PropTypes.object,
                omit: PropTypes.arrayOf(PropTypes.string),
                title: PropTypes.string,
                url: PropTypes.string
            })
        )
    };

    static defaultProps = {
        queryParams: {},
        views: []
    };

    render() {
        const platform = (this.props.platform.mic || '').toLowerCase();

        const views = this.props.views
            .map((view) => ({
                ...view,
                route: this.props.routes.find(({url}) => url === view.url)
            }))
            .filter(({route}) => route);

        return (
            <ul className='tab-menu'>
                {views.map(({route, ...view}) => {
                    const disabled =
                        view.disabled ||
                        !hasPermissions(
                            this.props.user,
                            view.permissions || route.permissions,
                            [this.props.platform.mic],
                            true
                        );
                    const isHidden = disabled && view.hideWhenDisabled;
                    const props = omit({...this.props.queryParams}, [
                        'limit',
                        'offset',
                        ...(view.omit || [])
                    ]);
                    const params = JSON.parse(
                        JSON.stringify(cleanObject(props))
                    );
                    const queryParams = isEmpty(params)
                        ? ''
                        : `?${qs.stringify(params)}`;
                    const path = `#${pathToRegex.compile(view.url)({
                        platform,
                        ...(view.params || {})
                    })}${queryParams}`;
                    const active = path.includes(this.props.route.url);

                    return (
                        !isHidden && (
                            <li key={path} className={cn({active, disabled})}>
                                <a
                                    data-e2e-tag='navigationTabs'
                                    data-tab-title={view.title}
                                    data-is-active={active.toString()}
                                    href={disabled ? undefined : path}>
                                    {view.title}
                                </a>
                            </li>
                        )
                    );
                })}
            </ul>
        );
    }
}
