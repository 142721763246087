import {get} from 'lodash-es';
import {CheckboxInput, NySelectInput} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {useToggle} from 'react-use';

import {useGiveUps} from '../../hooks/useGiveUps.hook';

export const TradingAccountsSelect = React.memo((props) => {
    const [hideInactive, toggleHideInactive] = useToggle(false);

    const [giveUps, isLoading] = useGiveUps({crd: props.crd});

    const sortBy = useMemo(
        () => [
            ({label}) => label.includes('Inactive'),
            ({label}) => label.toLowerCase()
        ],
        []
    );

    const filterOptions = useCallback(
        ({props}) => !hideInactive || !props || props.active,
        [hideInactive]
    );

    const renderChildOption = useCallback(
        ({label, props}) => (
            <div className='flex-row flex-align-center'>
                <span className='ellipsis flex-1'>{label}&nbsp;&nbsp;</span>
                {!props.active && (
                    <small className='flex-pull-right text-warning padding-right-1 flex-0'>
                        Inactive
                    </small>
                )}
            </div>
        ),
        []
    );

    const renderControls = useCallback(
        () => (
            <div className='flex-row flex-align-center control'>
                <CheckboxInput
                    className='text-warning'
                    name='hideInactive'
                    onChange={toggleHideInactive}
                    value={hideInactive}>
                    Hide Inactive
                </CheckboxInput>
            </div>
        ),
        [hideInactive]
    );

    const renderParentOption = useCallback(
        ({label, props}) => (
            <div className='flex-row flex-align-center'>
                <span className='ellipsis flex-1'>{label}&nbsp;&nbsp;</span>
                {!props.active && (
                    <small className='flex-pull-right text-warning padding-right-1 flex-0'>
                        Inactive
                    </small>
                )}
            </div>
        ),
        []
    );

    const options = useMemo(() => {
        const accounts = get(
            giveUps.find(({name}) => name === props.mpId),
            'tradingAccounts',
            []
        );

        return [
            {
                label: '-',
                value: '',
                props: {
                    active: true
                }
            },
            ...accounts.map((acc) => ({
                label: acc.name,
                value: acc.name,
                props: {
                    active: acc.status === 'Active'
                }
            }))
        ];
    }, [giveUps, props.mpId]);

    return (
        <NySelectInput
            controls={renderControls}
            disabled={props.disabled}
            filter={filterOptions}
            initialValue={props.initialValue}
            isLoading={isLoading}
            name={props.name}
            onChange={props.onChange}
            options={options}
            renderChildOption={renderChildOption}
            renderParentOption={renderParentOption}
            required={props.required}
            sortBy={sortBy}
        />
    );
});

TradingAccountsSelect.propTypes = {
    mpId: PropTypes.string,
    disabled: PropTypes.bool,
    initialValue: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    showAll: PropTypes.bool,
    showInactive: PropTypes.bool
};
