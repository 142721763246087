import Grid from '@material-ui/core/Grid';
import {SelectInput, TextInput} from 'nyse-web-tools-common/lib/react';
import React, {useCallback, useEffect, useState} from 'react';

type MultiEditReasonCodeSelectProps = {
    data?: any,
    context?: any,
    name: string,
    value?: string,
    onChange?: any
};

export const MultiEditReasonCodeSelect: React.FC<MultiEditReasonCodeSelectProps> = React.memo((props) => {
    const [[reasonCode, reasonText], setReason] = useState(props?.value ?? ['', '']);

    useEffect(() => setReason(props?.value), [props?.value]);

    const onInputChange = useCallback((e) => {
        setReason(e);
        props.onChange(e);
    }, []);

    return (
        <Grid container>
            <Grid item xs={reasonCode === 'Other' ? 5 : 12}>
                <SelectInput
                    name={props.name}
                    value={reasonCode}
                    onChange={(e) => onInputChange([e, ''])}
                    options={props?.context?.multiEdit?.reasonCodeOptions || []}
                />
            </Grid>
            {
                reasonCode === 'Other' && (
                    <Grid item xs={7}>
                        <TextInput
                            name={`${props.name}Text`}
                            placeholder='Other reason...'
                            value={reasonText}
                            onChange={(e) => onInputChange(['Other', e])}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
});
