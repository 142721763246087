import axios, {AxiosRequestConfig} from 'axios';
import {Store} from 'redux';
import {v4} from 'uuid';

import * as enums from '../enums';

export function nodeRequestInterceptor(
    redux: Store,
    config: AxiosRequestConfig
): AxiosRequestConfig {
    const user = redux.getState().user;

    if (user.isJwtThreshold && !config.url.includes('/auth/refreshToken')) {
        throw new axios.Cancel(enums.AUTH_THRESHOLD);
    }

    config.headers.Authorization = `Bearer ${user.jwt}`;

    if (config?.headers?.userRequestId === undefined) {
        config.headers.userRequestId = v4();
    }

    return config;
}
