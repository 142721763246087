import makeStyles from '@material-ui/core/styles/makeStyles';
import {isEmpty} from 'lodash-es';
import {NyAlert, NyPropertyList} from 'nyse-web-tools-common/lib/react';
import {NyLoader} from 'nyse-web-tools-common/lib/react';
import React, {useEffect, useMemo, useState} from 'react';

import {
    tradeActionBothSideColumnDefs,
    tradeActionOneSideColumnDefs
} from '../../../configs/columnDefs/tradeActionDetails.columnDefs';
import useResource from '../../../hooks/useResource.hook';
import {
    TradeSummaryProps,
    myOwnership,
    tradeApprovalDetailResource
} from '../../../resources/tradeSummary.resource';
import {TabMenu} from '../../navigation/tabMenu';
import {TradeApprovalForm} from './forms/tradeApproval.form';
import {TradeRecallForm} from './forms/tradeRecall.form';
import {TRADE_APPROVAL_TABS} from './tradeApproval.tabs';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%'
    },
    tabMenu: {
        minHeight: 25
    },
    nyPropertyList: {
        height: '80%'
    }
}));

export const TradeApprovalModal: React.FC<TradeSummaryProps> = React.memo(
    (props) => {
        const modalGetResource = useResource(tradeApprovalDetailResource);
        const classes = useStyles(props);

        const permissionedTabs = useMemo(
            () => [
                ...TRADE_APPROVAL_TABS.filter((current) =>
                    props.data.allowedTradeActions.includes(current.value)
                )
            ],
            [props]
        );

        const [currentTab, setCurrentTab] = useState(permissionedTabs[0].value);

        useEffect(() => {
            modalGetResource.get(
                {
                    crd: props.data.crd
                },
                {
                    origTdate: props.data.origTdate,
                    evtTyp: props.data.evtTyp,
                    dealIdRef: props.data.dealIdRef,
                    txId: props.data.txId
                }
            );
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <div>
                <NyLoader
                    show={modalGetResource.pending && !modalGetResource.error}
                />

                {modalGetResource?.error?.detail && (
                    <NyAlert
                        title={'Error'}
                        detail={modalGetResource.error.detail}
                        type='danger'
                        show
                        className='space-top-1'
                    />
                )}

                {!modalGetResource.pending && !isEmpty(modalGetResource.data) && (
                    <div className={classes.root}>
                        <TabMenu
                            className={classes.tabMenu}
                            tabs={permissionedTabs}
                            onChange={setCurrentTab}
                            value={currentTab}
                        />

                        <div className='row'>
                            <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9 flex-column'>
                                {currentTab === 'CONTRA_APPROVE' && (
                                    <TradeApprovalForm
                                        data={modalGetResource.data}
                                        csgData={props.data}
                                        crd={props.data.crd}
                                    />
                                )}
                                {currentTab === 'RECALL' && (
                                    <TradeRecallForm
                                        data={modalGetResource.data}
                                        csgData={props.data}
                                        crd={modalGetResource.data.custCrd}
                                    />
                                )}
                            </div>
                            <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 flex-column'>
                                <NyPropertyList
                                    className={`flex-column ${classes.nyPropertyList}`}
                                    columnDefs={
                                        modalGetResource.data.selectedSide ===
                                        myOwnership.BOTH
                                            ? tradeActionBothSideColumnDefs
                                            : tradeActionOneSideColumnDefs
                                    }
                                    object={modalGetResource.data}
                                    isLoading={modalGetResource.pending}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
);
