import React from 'react';

import {BlockTimeTab} from '../components/modals/eodProcessing/blockTime.tab';
import {FRJobsForm} from '../components/modals/eodProcessing/fRJobs.form';
import {NavigationSectionMenu} from '../components/navigation/navigation.section.menu';
import useRoute from '../hooks/useRoute.hook';

export const EodProcessingPage = () => {
    const route = useRoute();

    const views = [
        {
            title: 'Trade Action Block',
            url: '/:platform/eod/block-time'
        },
        {
            title: 'F/R Jobs',
            url: '/:platform/eod/fr-jobs'
        }
    ];

    return (
        <>
            <div className='row'>
                <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                    <NavigationSectionMenu views={views} />
                </div>
            </div>
            {route.path === '/:platform/eod/block-time' && <BlockTimeTab />}
            {route.path === '/:platform/eod/fr-jobs' && <FRJobsForm />}
        </>
    );
};
