import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';
import {Link} from 'react-router-dom';

@connect(['user'])
export class NavigationHeader extends React.Component {
    render() {
        return (
            <div className='container hidden-xs nav-desktop-header'>
                <a href='https://www.nyse.com'>
                    <svg role='img' viewBox='0 0 377.48 149.42' height='20' className='inline-block text-black'>
                        <title>NYSE Logo</title>
                        {/* eslint-disable */}
                        <polygon points='52.33 121.54 14.26 68.42 0 68.42 0 147.48 15.23 147.48 15.23 94.32 53.3 147.48 67.56 147.48 67.56 68.42 52.33 68.42 52.33 121.54'></polygon>
                        <polygon points='155.8 68.42 137.33 68.42 117.28 101.09 96.66 68.42 77.22 68.42 108 115.41 108 147.48 124.53 147.48 124.53 115.62 155.8 68.42'></polygon>
                        <path d='M199.1,101.2l-9.25-2.11C179,96.66,176.7,94.71,176.7,90c0-4.55,4.7-8.77,13.63-8.77a32.48,32.48,0,0,1,21.6,7.8l9.58-12.67c-8.93-7.3-20.3-9.9-30.53-9.9-18.51,0-31.5,10.88-31.5,25.33,0,11.36,7.64,18.67,23.22,21.75l9.1,1.79c10.55,2.11,14.44,6,14.44,10.55,0,6.33-5.35,9.26-14.28,9.42-8.61.16-16.89-2.92-24.36-10.55l-9.9,12c7.63,8.77,21.11,12.67,33.77,12.67,19.32,0,31.34-9.42,31.34-24.68C222.81,111.6,214.53,104.78,199.1,101.2Z'></path>
                        <path d='M249.56,114.92c2.56,11.9,11.71,19.76,23.75,19.76,8.75,0,15.72-4.05,20.74-11l11.5,9.73a39,39,0,0,1-32.24,16c-25.11,0-41.31-18-41.31-41.48s16.07-41.48,39.08-41.48c17.23,0,40.8,12,36.9,48.45Zm42.79-12.8C290.59,85.93,280.12,81.05,273,81.05c-12.11,0-20.88,7.61-23.69,21.07Z'></path>
                        <polygon fill='currentColor'
                            points='299.63 23.85 353.62 23.85 353.62 77.84 377.48 77.84 377.48 0 299.63 0 299.63 23.85'></polygon>
                        {/* eslint-enable */}
                    </svg>
                </a>

                <div className='flex-row flex-align-end'>
                    {this.props.user.isJwtValid && (
                        <span className='user-name space-right-2'>
                            {this.props.user.user_firstname}
                        </span>
                    )}
                    {this.props.user.isJwtValid && (
                        <Link className='hidden-xs' to='/logout'>
                            LOGOUT
                        </Link>
                    )}
                </div>
            </div>
        );
    }
}
