import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import * as PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import useResource from '../../hooks/useResource.hook';
import opraExchangesResource from '../../resources/opraExchanges.resource';

export const OPRAExchangeSelect = React.memo((props) => {
    const resource = useResource(opraExchangesResource, true);

    const options = useMemo(
        () =>
            resource.data.reduce(
                (acc, {exchangeMic, name}) => [
                    ...acc,
                    {
                        label: `${exchangeMic} - ${name}`,
                        value: exchangeMic
                    }
                ],
                [{label: '-', value: ''}]
            ),
        [resource.data]
    );

    return (
        <NySelectInput
            disabled={props.disabled}
            initialValue={props.initialValue}
            name={props.name}
            isLoading={resource.pending}
            onChange={props.onChange}
            options={options}
            listHeight={200}
            readOnly={props.readOnly}
            required={props.required}
        />
    );
});

OPRAExchangeSelect.propTypes = {
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool
};
