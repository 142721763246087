import {cdmMw} from '../api';

export const mstuFieldResource = {
    api: cdmMw,
    url: (param) => `/api/mstu/${param.systemId}/field`,
    parseResponse: (data) => data,
    defaultValue: []
};

export const mstuSummariesResource = {
    api: cdmMw,
    url: (param) => `/api/mstu/${param.systemId}/summaries/filter?${param.filter}`,
    parseResponse: (data) => data,
    defaultValue: []
};
