import {useEffect} from 'react';

import { fetchRangesResource } from '../resources/riskSettings.resource';
import useResource from './useResource.hook';

export function useRiskRanges(rangeType) {
    const resource = useResource(fetchRangesResource);

    useEffect(() => {
        resource.get({rangeType});
    }, [rangeType]);

    return [resource.data, resource.pending];
}
