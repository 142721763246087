import {LocalDateTime} from '@js-joda/core';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {events} from '../events';

export const opraEntriesReducer = {
    [events.OPRA_ENTRIES_GET]: (state) => ({
        ...state,
        error: false,
        isLoading: true,
        rowData: []
    }),
    [events.OPRA_ENTRIES_SUCCESS]: (
        state,
        {
            payload: {
                data: {data}
            }
        }
    ) => ({
        isLoading: false,
        rowData: data.map((row) => ({
            ...row,
            tradeDate: joda.format(
                LocalDateTime.parse(row.tradeExecTime),
                'MM/dd/yyyy'
            )
        })),
        timestamp: LocalDateTime.now()
    }),
    [events.OPRA_ENTRIES_ERROR]: (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
        rowData: [],
        timestamp: LocalDateTime.now()
    })
};
