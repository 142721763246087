import Box from '@material-ui/core/Box';
import {capitalize} from 'lodash-es';
import {agGridCellRenderer} from 'nyse-web-tools-common/lib/react';
import {formatNumber} from 'nyse-web-tools-common/lib/utils';
import React from 'react';

import {Entitlements} from '../../components/displays/entitlements';
import {SettingDetailsModal} from '../../components/modals/risk/settingDetails.modal';
import {capitalizeRole} from '../entitlementRoleMap';
import {riskSettingsTypeMap} from '../riskEntityHelper';
import {v4} from 'uuid';

const GetRange =  (rangeLow, rangeHigh) => {
    if (rangeLow === undefined && rangeHigh === undefined)
        return '';
    else if (rangeLow === undefined)
        return `\u2264 ${formatNumber(rangeHigh)}`;
    else if (rangeHigh === undefined)
        return `> ${formatNumber(rangeLow)}`;
    else
        return `> ${formatNumber(rangeLow)} and \u2264 ${formatNumber(rangeHigh)}`;
};

const GetLimit = (limit, priceThreshold, riskType) => {

    let prefix='', postfix='';

    if (['cred_ord_exec', 'ord_val'].includes(riskType)) {
        //Gross, Order Max Notional
        return limit !== undefined ? `$${formatNumber(limit)}`:'';
    }
    else  if (['rolling_pct', 'ord_qty_adv'].includes(riskType)){
        //Percentage, Order % ADV
        return limit !== undefined ? `${limit}%`:'';
    }
    else if (['lmt_ord_prc_eq', 'lmt_ord_prc_eq_cls_coef', 'lmt_ord_prc_eq_trd_coef', 'lmt_ord_prc_opt_leg', 'lmt_ord_prc_opt_complex'].includes(riskType)) {
        //Price Protection, Price Protection, Price Protection, Single Leg Price Protection, Complex Price Protection
        const prefValue = limit !== undefined ? `${limit}%`:'';
        const postValue = priceThreshold !== undefined ? `$${formatNumber(priceThreshold, 0,2)}`:''
        return `${prefValue} / ${postValue}`;
    }
    else
        /*
            ord_qty: 'Order Max Qty',
            rolling_vol: 'Volume',
            rolling_trans: 'Transaction',
            trans_vol_pct: 'GRMP',
            rej_iso: 'Reject ISO',
            rej_iso_day: 'Reject ISO Day',
            rej_iso_ioc: 'Reject ISO IOC',
            rej_mkt_day: 'Reject Market Day',
            rej_moo_moc: 'Reject MOO and MOC Orders',
            dupe_ord: 'Max Duplicate Orders',
            ord_rate: 'Order Rate Threshold - Max Orders',
            rej_rst_sym: 'Reject Restricted Symbol',
            rej_sell_short: 'Reject Sell Short for Symbol',
            rej_sell_short_ex: 'Reject Sell Short Exempt for Symbol',
            locate_broker: 'Locate Broker',
            rej_early_sess: 'Reject Early Trading Session',
            rej_late_sess: 'Reject Late Trading Session'
         */
        return limit === undefined ? '' : `${formatNumber(limit)}`;
}
export const riskSettingsDetailColumnDefs = [
    {
        headerName: '',
        field: 'detailSettings',
        pinned: 'left',
        suppressToolPanel: true,
        lockPosition: true,
        resizable: false,
        suppressSizeToFit: true,
        permissions: ['update risk settings'],
        cellRendererFramework: agGridCellRenderer(({data, context}) => (
            <Entitlements role={data.limitCrdRole} checkOptionsMarket={true}>
                <SettingDetailsModal data={data} context={context} />
            </Entitlements>
        )),
        width: 50
    },
    {
        headerName: 'Risk User CRD',
        field: 'limitCrd',
        minWidth: 95,
        maxWidth: 95
    },
    {
        headerName: 'Type',
        field: 'limitCrdRole',
        valueFormatter: ({value}) =>
            value === 'options_mm' ? 'Market Maker' : capitalizeRole(value),
        width: 120
    },
    {
        headerName: 'Breach Action',
        field: 'limits',
        autoHeight: true,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({action, riskType, uuid}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>
                                {riskSettingsTypeMap[riskType]}
                                {['ord_qty', 'ord_val','rej_iso', 'rej_mkt_day','rej_moo_moc',
                                'rej_early_sess','rej_late_sess','locate_broker','rej_rst_sym',
                                'rej_sell_short','rej_sell_short_ex','dupe_ord','ord_rate','ord_qty_adv',
                                'lmt_ord_prc_eq','lmt_ord_prc_opt_leg','lmt_ord_prc_opt_complex'].includes(riskType)
                                    ? ''
                                    : ` - ${capitalize(action)}`}
                            </Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 300
    },
    {
        headerName: 'On/Off',
        field: 'limits',
        autoHeight: true,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({activation, uuid}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>{activation === 'Y' ? 'On' : activation === 'N' ? 'Off' : undefined}</Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 65
    },
    {
        headerName: 'Range',
        field: 'limits',
        autoHeight: true,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({rangeHigh, rangeLow, uuid}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>{GetRange(rangeLow, rangeHigh)}</Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 200
    },
    {
        headerName: 'Min Value',
        field: 'limits',
        autoHeight: true,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({limitLow, uuid}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>{formatNumber(limitLow)}</Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 80
    },
    {
        headerName: 'Early/Late Multiplier',
        field: 'limits',
        autoHeight: true,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({earlyLateMultiplier, uuid}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>{earlyLateMultiplier}</Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 100
    },
    {
        headerName: 'On Close Multiplier',
        field: 'limits',
        autoHeight: true,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({onCloseMultiplier, uuid}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>{onCloseMultiplier}</Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 100
    },
    {
        headerName: 'Limits',
        field: 'limits',
        autoHeight: true,
        sortable: false,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({ limit, priceThreshold, riskType, uuid}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>{GetLimit(limit, priceThreshold, riskType)}</Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 125
    },
    {
        headerName: 'Time Window',
        field: 'limits',
        autoHeight: true,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({uuid, window}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>{formatNumber(window)}</Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 125
    },
    {
        headerName: 'IOC/GTX',
        field: 'limits',
        autoHeight: true,
        cellRendererFramework: agGridCellRenderer(
            ({
                 value,
                 node: {
                     rowHeight,
                     data: {riskEntity, limitCrd, limitCrdRole}
                 }
             }) => (
                <Box display='flex' flexDirection='column'>
                    {value.map(({ioc, uuid}) => (
                        <Box
                            key={uuid?uuid:v4()}
                            display='flex'
                            justifyContent='space-between'
                            height={rowHeight}>
                            <Box lineHeight={2.333}>
                                {ioc?.replace(/^\w/, (c) => c.toUpperCase()) ??
                                ''}
                            </Box>
                        </Box>
                    ))}
                </Box>
            )
        ),
        width: 70
    },
    {
        headerName: 'Email',
        field: 'email',
        width: 280
    },
    {
        headerName: 'Read',
        cellClass: 'ag-value-change-delta-up',
        field: 'read',
        type: 'checkMark',
        valueGetter: ({colDef, data}) => data[colDef.field],
        width: 100
    },
    {
        headerName: 'Read Without Reinstatement',
        cellClass: 'ag-value-change-delta-up',
        field: 'readWoReinstate',
        type: 'checkMark',
        valueGetter: ({colDef, data}) => data[colDef.field],
        width: 180
    },
    {
        headerName: 'Write',
        cellClass: 'ag-value-change-delta-up',
        field: 'write',
        type: 'checkMark',
        valueGetter: ({colDef, data}) => data[colDef.field],
        width: 100
    }
];