import React from 'react';

import {Access} from '../components/displays/access';
import {MarketWideCircuitBreaker} from '../components/displays/marketWideCircuitBreaker';
import {CoreSessionDataPanel} from '../components/panels/coreSessionData.panel';
import {HaltedSymbolsPanel} from '../components/panels/haltedSymbols.panel';
import {SymbolStatesPanel} from '../components/panels/symbolStates.panel';
import {TopAuctionsPanel} from '../components/panels/topAuctions.panel';
import {TransactionDashboardPanel} from '../components/panels/transactionDashboard.panel';

export class OverviewPage extends React.PureComponent {
    render() {
        return (
            <div className='row'>
                <Access permissions={['view summary trade stats']}>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 space-bottom-2'>
                        <TransactionDashboardPanel />
                    </div>
                </Access>
                <Access permissions={['view core trading session']}>
                    <MarketWideCircuitBreaker />
                </Access>
                <Access permissions={['list of auctions']}>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 space-bottom-2'>
                        <TopAuctionsPanel />
                    </div>
                </Access>
                <Access permissions={['view core trading session']}>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 space-bottom-2'>
                        <div className='panel-primary panel'>
                            <CoreSessionDataPanel />
                            <SymbolStatesPanel />
                            <HaltedSymbolsPanel />
                        </div>
                    </div>
                </Access>
            </div>
        );
    }
}
