export const liquidityMetricsFirmColumnDefs = [
    {
        headerName: '-',
        cellStyle: ({data: {isGroup}}) => ({
            fontWeight: isGroup ? 500 : undefined
        }),
        field: 'symbol',
        pinned: 'left',
        sort: 'asc',
        suppressMovable: true,
        suppressToolPanel: true,
        width: 200
    },
    {
        headerName: 'Symbol',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Issuer Name',
                field: 'issueName',
                width: 200
            },
            {
                headerName: 'Issuer Company',
                field: 'issuingCompany',
                width: 200
            },
            {
                headerName: 'Adviser',
                field: 'advisor',
                width: 200
            },
            {
                headerName: 'Trust Name',
                field: 'trustName',
                width: 200
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Execution Volume',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Total',
                field: 'totalVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Posted',
                field: 'postedVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Posted Part. %',
                field: 'postedParticipation',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Posted Part. % of CADV',
                field: 'postedParticipationCADV',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Exchange Part. %',
                field: 'exchangeParticipation',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Buy',
                field: 'buyVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Buy %',
                field: 'buyPercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Sell',
                field: 'sellVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Sell %',
                field: 'sellPercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'SSH',
                field: 'sellShortVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'SSHE',
                field: 'sellShortExemptVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'SSH & SSHE %',
                field: 'sshSshePercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Opening Auction',
                field: 'openAuctionVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Opening Auction %',
                field: 'openAuctionPercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Closing Auction',
                field: 'closeAuctionVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Closing Auction %',
                field: 'closeAuctionPercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Close Price',
                field: 'closePrice',
                type: 'price',
                width: 150
            }
        ],
        marryChildren: true
    }
];
