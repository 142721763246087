import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getMySessions = (params = {}) => ({
    types: [
        events.MY_SESSIONS_GET,
        events.MY_SESSIONS_SUCCESS,
        events.MY_SESSIONS_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/user-session',
            params: cleanObject(params),
            isPillarSource: true
        }
    }
});
