import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridProvider,
    AgGridRowCount,
    AgGridTimestamp,
    AgGridToggleShowFilters,
    NyDateTimeRangeInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {FirmSelect} from '../components/selects/firm.select';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {getMarketAccessExceptions} from '../stores/marketAccessExceptions/marketAccessExceptions.actions';

@connect(['columnDefs', 'firms', 'marketAccessExceptions', 'platform'], {
    getMarketAccessExceptions,
    setColumns
})
export class MarketAccessExceptionsPage extends React.Component {
    state = {
        filterValues: {}
    };

    _dateRange = {
        max: joda.endOfDay(),
        min: joda.startOfDay().minusMonths(6)
    };

    _initialFilters = {
        crd: (this.props.firms[0] || {}).crd,
        startDate: joda.startOfDay(),
        endDate: joda.startOfDay()
    };

    _onLoad = (params) => {
        const firm = this.props.firms.find(({crd}) => crd === params.crd);

        this.props.getMarketAccessExceptions({
            ...params,
            participantId: firm
                ? firm.participants
                    .filter(
                        ({type, active}) => type === 'MPID' && active === true
                    )
                    .map(({id}) => id)
                : []
        });
    };

    render() {
        return (
            <AgGridProvider
                columnNamespace='marketAccessExceptions'
                columnDefs={this.props.columnDefs.marketAccessExceptions}
                onColumnsChanged={this.props.setColumns}
                filtersClassName='row space-bottom-1'
                initialFilters={this._initialFilters}
                onLoad={this._onLoad}
                submitOnLoad={true}
                {...this.props.marketAccessExceptions}>
                <AgGridProvider.Filters>
                    <div className='form-group col-xs-12 col-sm-5 col-md-4 col-lg-4'>
                        <label>Date Range</label>
                        <NyDateTimeRangeInput
                            className='form-control'
                            format='MM/dd/yyyy'
                            startName='startDate'
                            endName='endDate'
                            min={this._dateRange.min}
                            max={this._dateRange.max}
                            required
                        />
                    </div>

                    {this.props.firms.length > 1 && (
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Firm</label>
                            <FirmSelect
                                name='crd'
                                required={true}
                                showInactive={true}
                            />
                        </div>
                    )}
                </AgGridProvider.Filters>

                <div className='row space-bottom-1'>
                    <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                        <div className='flex-row flex-align-bottom'>
                            <AgGridProvider.SubmitBtn>
                                Search
                            </AgGridProvider.SubmitBtn>
                            <AgGridProvider.ResetBtn>
                                Reset
                            </AgGridProvider.ResetBtn>
                            <div className='btn-circle-grp flex-pull-right'>
                                <AgGridToggleShowFilters />
                                <AgGridExportMenu
                                    exportFileName='market_access_exceptions'
                                    exportSheetName={this.props.platform.name}
                                />
                                <AgGridColumnMenuTrigger />
                            </div>
                        </div>
                    </div>
                </div>

                <AgGrid reactNext={true} />

                <div className='flex-row flex-space-between flex-align-bottom space-top-1 space-bottom-2'>
                    <AgGridTimestamp />
                    <AgGridRowCount className='flex-pull-right' />
                </div>
            </AgGridProvider>
        );
    }
}
