import {LocalDateTime} from '@js-joda/core';
import Box from '@material-ui/core/Box';
import {
    NyDateTimeInput,
    NyNumberInput,
    NySelectInput,
    NyTextInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import AgGrid from 'nyse-web-tools-common/lib/react/grid/AgGrid/AgGrid';
import AgGridColumnMenuTrigger from
    'nyse-web-tools-common/lib/react/grid/AgGridColumnMenuTrigger/AgGridColumnMenuTrigger';
import AgGridExportMenu from 'nyse-web-tools-common/lib/react/grid/AgGridExportMenu/AgGridExportMenu';
import AgGridPagination from 'nyse-web-tools-common/lib/react/grid/AgGridPagination/AgGridPagination';
import AgGridProvider from 'nyse-web-tools-common/lib/react/grid/AgGridProvider/AgGridProvider';
import AgGridToggleShowFilters from
    'nyse-web-tools-common/lib/react/grid/AgGridToggleShowFilters/AgGridToggleShowFilters';
import useManagedState from 'nyse-web-tools-common/lib/react/hooks/useManagedState/useManagedState';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import React, {useCallback, useMemo} from 'react';

import {EntitlementSelect} from '../components/selects/entitlement.select';
import {FirmSelect} from '../components/selects/firm.select';
import {FirmParticipantSelect} from '../components/selects/firmParticipant.select';
import {MmIdSelect} from '../components/selects/mmid.select';
import {liveOrdersColumnDefs} from '../configs/columnDefs/liveOrders.columnDefs';
import useResource from '../hooks/useResource.hook';
import useRoute from '../hooks/useRoute.hook';
import useStore from '../hooks/useStore.hook';
import viewLiveOrdersResource from '../resources/viewLiveOrders.resource';
import {cleanObject} from '../utils/utilities';

export const LiveOrdersPage = React.memo(() => {
    const route = useRoute();
    const firms = useStore('firms');
    const permissions = useStore('permissions');
    const platform = useStore('platform');
    const resource = useResource(viewLiveOrdersResource);

    const init = useMemo(
        () => ({
            pagination: {
                limit: 500,
                offset: 0
            },
            values: {
                crd: route.params.crd || (firms[0] || {}).crd
            },
            dateRange: {
                min: LocalDateTime.now(),
                max: LocalDateTime.now().plusYears(15)
            }
        }),
        []
    );

    const [values, setValues] = useManagedState(init.values);

    const onFirmSelect = () => {
        setValues('entityMpid', []);
    };

    const onLoad = useCallback(
        ({...params}) =>
            resource.get(
                cleanObject({
                    ...params,
                    expirationDate: params.expirationDate
                        ? joda.format(
                            LocalDateTime.parse(
                                params.expirationDate.toString()
                            ),
                            'yyyyMMdd'
                        )
                        : undefined
                })
            ),
        [resource.get]
    );

    return (
        <AgGridProvider
            columnDefs={liveOrdersColumnDefs}
            columnNamespace='liveOrders'
            error={resource.error}
            filtersClassName='row space-bottom-1'
            initialPagination={init.pagination}
            initialFilters={init.values}
            isLoading={resource.pending}
            onFiltersChanged={setValues}
            onLoad={onLoad}
            permissions={permissions}
            primaryKey='uuid'
            more={resource.data.more}
            rowData={resource.data.rowData}
            count={resource.data.count}>
            <AgGridProvider.Filters>
                <EntitlementSelect
                    className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'
                    name='entitlement'
                />
                {firms.length > 1 && (
                    <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                        <label>Firm</label>
                        <FirmSelect
                            name='crd'
                            onChange={onFirmSelect}
                            required
                            showInactive
                        />
                    </div>
                )}
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>Underlying Symbol</label>
                    <NyUppercaseInput name='ulSymbol' />
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>OSI Symbol</label>
                    <NyUppercaseInput name='symbol' />
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>OSI Root</label>
                    <NyTextInput name='osiRoot' />
                </div>
                <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                    <label>Expiration Date</label>
                    <NyDateTimeInput
                        className='form-control'
                        format='MM/dd/yyyy'
                        name='expirationDate'
                        min={init.dateRange.min}
                        max={init.dateRange.max}
                        minView='months'
                    />
                </div>
                <div className='form-group col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                    <label>MPID</label>
                    <FirmParticipantSelect
                        crd={parseInt(values.crd)}
                        isMulti
                        mpidMust={['MM_FIRM']}
                        entitlements={['BROKER_FIRM', 'MEMBER_FIRM']}
                        name='entityMpid'
                    />
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>MMID</label>
                    <MmIdSelect name='entityMmid' isMulti crd={values.crd} />
                </div>
                <div className='form-group col-xs-12 col-sm-5 col-md-3 col-lg-2'>
                    <label>Strike Price</label>
                    <NyNumberInput name='strikePrice' precision={8} />
                </div>
                <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                    <label>Side</label>
                    <NySelectInput name='side'>
                        <option value=''>-</option>
                        <option value='buy'>Buy</option>
                        <option value='sell'>Sell</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                    <label>Open/Close</label>
                    <NySelectInput name='openClose'>
                        <option value=''>-</option>
                        <option value='Open'>Open</option>
                        <option value='Close'>Close</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                    <label>Put/Call</label>
                    <NySelectInput name='callPut'>
                        <option value=''>-</option>
                        <option value='Put'>Put</option>
                        <option value='Call'>Call</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                    <label>Order Type</label>
                    <NySelectInput name='orderType'>
                        <option value=''>-</option>
                        <option value='limit'>Limit</option>
                        <option value='market'>Market</option>
                        <option value='stop'>Stop</option>
                        <option value='stop_limit'>Stop Limit</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                    <label>Security Type</label>
                    <NySelectInput name='securityType'>
                        <option value=''>-</option>
                        <option value='single_leg'>Single Leg</option>
                        <option value='complex'>Complex</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                    <label>TIF</label>
                    <NySelectInput name='tif'>
                        <option value=''>-</option>
                        <option value='day'>DAY</option>
                        <option value='open_auction'>ON_OPEN</option>
                        <option value='gtc'>GTC</option>
                        <option value='gtx'>GTX</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                    <label>Customer or Firm</label>
                    <NySelectInput name='customerFirm'>
                        <option value=''>-</option>
                        <option value='customer'>Customer</option>
                        <option value='firm'>Firm</option>
                        <option value='broker'>Broker</option>
                        <option value='market_maker'>Market Maker</option>
                        <option value='away_market_maker'>
                            Away Market Maker
                        </option>
                        <option value='pro_customer'>
                            Professional Customer
                        </option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-2'>
                    <label>CMTA</label>
                    <NyTextInput name='cmta' maxLength={5} />
                </div>
                <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-2'>
                    <label>Clearing Number</label>
                    <NyTextInput name='clearingNumber' />
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>Client Order Id</label>
                    <NyTextInput name='clordId' />
                </div>
                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                    <label>Sender Comp. ID</label>
                    <NyUppercaseInput name='senderCompId' />
                </div>
            </AgGridProvider.Filters>

            <div className='row space-bottom-1'>
                <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                    <Box display='flex' alignItems='flex-end'>
                        <AgGridProvider.SubmitBtn />
                        <AgGridProvider.ResetBtn />
                        <Box display='flex' ml='auto'>
                            <AgGridToggleShowFilters />
                            <AgGridExportMenu
                                exportFileName={`liveOrders${platform.mic}`}
                                exportSheetName={platform.name}
                            />
                            <AgGridColumnMenuTrigger />
                        </Box>
                    </Box>
                </div>
            </div>

            <AgGrid reactNext />

            <AgGridPagination>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1,000</option>
            </AgGridPagination>
        </AgGridProvider>
    );
});
