import React, {useMemo} from 'react';
import * as yup from 'yup';
import useNyField from 'nyse-web-tools-common/lib/react/hooks/useNyField/useNyField';
import { testIsRequired } from 'nyse-web-tools-common/lib/utils';
import { NyFieldInput } from 'nyse-web-tools-common/lib/react/types';
import MultiEmailInput from './MultiEmailInput';
import { createDataAttribute } from 'nyse-web-tools-common/lib/utils';

type NyMultiEmailInputProps = NyFieldInput & {
    name?: string;
    maxLength?: number;
    placeholder?: string;
    separator?: string;
    value?: string;
};

const NyMultiEmailInput: React.FC<NyMultiEmailInputProps> = React.memo(
    (props) => {

        const fieldCtx = useNyField(
            props,
            useMemo(
                () =>
                    yup
                        .mixed()
                        .test({
                            test: testIsRequired({
                                disabled: props.disabled,
                                required: props.required,
                                readOnly: props.readOnly
                            })
                        })
                        .test(
                            'validEmail',
                            'Invalid email address!',
                            (value: string): boolean => {
                                if (value === undefined || value === '')
                                    return true;
                                const emails = value.split((props.separator === undefined || props.separator ===''?',':props.separator));
                                return !emails.some((e) => !yup.string().email().isValidSync(e.trim()));
                            }
                        )
                        .test(
                            'validLength',
                            `maximum ${props.maxLength} characters allowed`,
                            (value: string): boolean => {
                                if (value === undefined || value === '' || props.maxLength === undefined)
                                    return true;
                                const emails = value.split(',');
                                const sum = emails.reduce((partialSum, e)=> partialSum + e.length, 0);
                                return (sum  + (emails.length ? emails.length - 1 : 0)) <= props.maxLength;
                            }
                        ),
                [props.disabled, props.readOnly, props.required]
            )
        );

        return <MultiEmailInput
            data-e2e-tag={props['data-e2e-tag']}
            data-disabled={createDataAttribute(props.disabled)}
            data-read-only={createDataAttribute(props.readOnly)}
            data-valid={createDataAttribute(fieldCtx.valid)}
            data-value={createDataAttribute(fieldCtx.value)}
            disabled={props.disabled}
            maxLength={props.maxLength}
            name={props.name}
            onChange={fieldCtx.onChange}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            required={props.required}
            separator={props.separator}
            valid={fieldCtx.valid}
            validation={fieldCtx.validation}
            value={fieldCtx.value}/>
    }
);

NyMultiEmailInput.defaultProps = {
    disabled: false,
    maxLength: 500,
    readOnly: false,
    required: false,
    separator: ','
};


export default NyMultiEmailInput;
