import qs from 'querystring';

import {get, omit} from 'lodash-es';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import pathToRegex from 'path-to-regexp';
import React from 'react';
import {Redirect} from 'react-router';

import environment from '../configs/environment.config';
import {setEntitlement} from '../stores/entitlement/entitlement.actions';
import {cleanObject, hasPermissions, hasRoles} from '../utils/utilities';

@connect(
    [
        'entitlement',
        'permissions',
        'platform',
        'route',
        'routes',
        'termsAndConditions',
        'user'
    ],
    {setEntitlement}
)
export class RouteBound extends React.PureComponent {
    state = {
        activeMic: false,
        hasPlatformChanged: false
    };

    static getDerivedStateFromProps({entitlement, route, platform}, state) {
        const routeEntitlement = route.entitlement || {};
        const currentEntitlement = entitlement.name;
        const entitlements = routeEntitlement[state.activeMic] || [];
        const isEntitlementNotSet =
            (!currentEntitlement && entitlements.length) ||
            (currentEntitlement &&
                entitlements.length &&
                !entitlements.includes(currentEntitlement));

        return {
            activeMic: platform.mic,
            currentEntitlement,
            hasPlatformChanged: platform.mic !== state.activeMic,
            isEntitlementNotSet,
            entitlements
        };
    }

    componentDidUpdate() {
        if (
            this.state.isEntitlementNotSet &&
            this.props.user.entitlement === 'SUPER_USER'
        ) {
            this.props.setEntitlement(this.state.entitlements[0]);
        }

        if (!this.state.entitlements.length && this.state.currentEntitlement) {
            this.props.setEntitlement('');
        }
    }

    render() {
        const hasJwt = !!this.props.user.exp;
        const Component = this.props.component;
        const platform = (
            get(this.props.match, 'params.platform', undefined) ||
            this.props.platform.mic ||
            ''
        ).toUpperCase();
        const isExpiredJwt =
            hasJwt && new Date().getTime() >= this.props.user.exp;
        const isJwtValid =
            hasJwt && new Date().getTime() <= this.props.user.exp;

        const hasMatchPermission = hasPermissions(
            this.props.user,
            this.props.route.permissionParams
                ? this.props.route.permissionParams[
                    this.props.match.params[
                        this.props.route.permissionParam
                    ]
                ]
                : this.props.route.permissions,
            [this.props.platform.mic],
            this.props.route.strict
        );
        const hasMatchRole = hasRoles(this.props.user, this.props.route.roles);
        const hasPermissionsKey = !!this.props.route.permissions;
        const hasRolesKey = !!this.props.route.roles;

        if (
            isExpiredJwt &&
            !['/logout', '/login'].includes(this.props.match.path)
        ) {
            return <Redirect to='/logout' />;
        }

        if (this.props.route.platform && !this.props.platform.mic) {
            return <Redirect to='/' />;
        }

        if (['/', '/login'].includes(this.props.match.path) && isJwtValid && !environment.integrationEnv) {
            return <Redirect to='/home'/>;
        }

        if (['/home', '/system/broadcast-message'].includes(this.props.match.path) && !isJwtValid) {
            return <Redirect to='/' />;
        }

        if (
            ['/', '/login'].includes(this.props.match.path) &&
            !isExpiredJwt &&
            this.props.platform.mic
        ) {
            const route = this.props.routes.find(
                (route) =>
                    route.permissions &&
                    hasPermissions(
                        this.props.user,
                        route.permissions,
                        [this.props.platform.mic],
                        route.strict
                    )
            );
            return (
                <Redirect
                    to={route.url.replace(
                        /:platform/g,
                        this.props.platform.mic.toLowerCase()
                    )}
                />
            );
        }

        if (!(
            (!hasPermissionsKey && !hasRolesKey) ||
            (!hasPermissionsKey && hasRolesKey && hasMatchRole) ||
            (hasPermissionsKey && !hasRolesKey && hasMatchPermission) ||
            (hasPermissionsKey && hasRolesKey && hasMatchPermission && hasMatchRole)
        )) {
            return <Redirect to='/unauthorized' />;
        }

        // if the next state requires terms & conditions, forward them
        if (
            this.props.route.termsAndConditions &&
            this.props.termsAndConditions[this.props.match.url] !== true
        ) {
            return (
                <Redirect
                    to={{
                        pathname: `/${platform.toLowerCase()}/terms+and+conditions`,
                        search: qs.encode({
                            file: this.props.route.termsAndConditions,
                            pageTitle: this.props.route.title,
                            to: this.props.match.url,
                            from: this.props.location.pathname
                        })
                    }}
                />
            );
        }

        // note: this is the only way to detect a manual change in the location; required to force
        // platform / route validation.
        if (
            !['/logout', '/login'].includes(this.props.match.path) &&
            this.state.hasPlatformChanged &&
            isJwtValid
        ) {
            return (
                <Redirect
                    to={{
                        pathname: pathToRegex.compile(this.props.url)({
                            ...(this.props.match.params || {}),
                            platform: platform.toLowerCase()
                        }),
                        search: qs.encode({
                            ...cleanObject(
                                omit(this.props.route.params || {}, [
                                    'platform',
                                    'view'
                                ])
                            )
                        })
                    }}
                />
            );
        }

        return <Component key={this.props.route.url} />;
    }
}
