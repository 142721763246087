import {agGridCellRenderer} from 'nyse-web-tools-common/lib/react';

import {KillSwitchModal} from '../../components/modals/risk/killSwitch.modal';
import {ReinstatementModal} from '../../components/modals/risk/reinstatement.modal';

export const riskMonitorV2ColumnDefs = [
    {
        field: 'killSwitch',
        pinned: 'right',
        suppressToolPanel: true,
        lockPosition: true,
        resizable: false,
        suppressSizeToFit: true,
        sorting: false,
        headerName: '',
        permissions: ['risk - kill switch actions'],
        cellRendererFramework: agGridCellRenderer(KillSwitchModal),
        valueGetter: ({data}) => data,
        cellStyle: ({data}) => ({
            display: data.entityType === 'SYMBOL_MPID_SUBID' && 'none'
        }),
        width: 120
    },
    {
        field: 'displayReinstateStatus',
        pinned: 'right',
        suppressToolPanel: true,
        lockPosition: true,
        resizable: false,
        suppressSizeToFit: true,
        sorting: false,
        headerName: '',
        permissions: ['risk - reinstate risk entity'],
        cellRendererFramework: agGridCellRenderer(ReinstatementModal),
        width: 100
    },
    {
        headerName: 'Risk Entity',
        pinned: 'left',
        field: 'displayRiskEntity',
        lockPosition: true,
        suppressToolPanel: true,
        cellClassRules: {
            danger: ({data: {blocked, blockedKs}}) =>
                blocked === 'Y' || blockedKs === 'Y'
        },
        type: 'agTreeBtn'
    },
    {
        headerName: 'Block By Breach',
        cellClass: 'ag-value-change-delta-up',
        field: 'blocked',
        type: 'checkMark',
        valueGetter: ({colDef, data}) => data[colDef.field] === 'Y',
        width: 120
    },
    {
        headerName: 'Block By Kill Switch',
        cellClass: 'ag-value-change-delta-up',
        field: 'blockedKs',
        type: 'checkMark',
        valueGetter: ({colDef, data}) => data[colDef.field] === 'Y',
        width: 120
    }
];
