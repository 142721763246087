import {omit} from 'lodash-es';
import {AgGrid, AgGridProvider} from 'nyse-web-tools-common/lib/react';
import {NyForm} from 'nyse-web-tools-common/lib/react';
import {NyTextInput} from 'nyse-web-tools-common/lib/react';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {
    tradeSplitBothSideColumnDefs,
    tradeSplitOneSideColumnDefs
} from '../../../../configs/columnDefs/tradeSplits.columnDefs';
import {useEntitlement} from '../../../../hooks/useEntitlement';
import useResource from '../../../../hooks/useResource.hook';
import {useSndrSubIdOptions} from '../../../../hooks/useSndrSubIdOptions';
import {useSubmittingBkrIdOptions} from '../../../../hooks/useSubmittingBkrIdOptions';
import useUser from '../../../../hooks/useUser.hook';
import {
    CustFirm,
    Entitlement,
    SideTradeType,
    TradeSummaryProps,
    TradeSummaryTabs,
    myOwnership,
    tradeSplitResource
} from '../../../../resources/tradeSummary.resource';
import {
    viewGBOBrokerFirmsResource,
    viewGBOFirmsResource,
    viewGBOMarketMakerFirmsResource
} from '../../../../resources/viewGBO.resource';
import {cleanObject} from '../../../../utils/utilities';
import {TradeSliceCounter} from '../../../displays/tradeSliceCounter';
import {Link} from '../../../navigation/navigation.link';
import {ReasonCodeSelect} from '../../../selects/reasonCode.select';
import {ContraSideSection} from '../sections/contraSide.section';
import {CustomerSideSection} from '../sections/customerSide.section';
import usePlatform from '../../../../hooks/usePlatform';

export const TradeSplitForm: React.FC<TradeSummaryProps> = React.memo(
    (props) => {
        const isCustFloorBroker =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER;
        const isCustFloorBrokerOnBehalfOfLocalMM =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isCustFloorBrokerOnBehalfOfAwayMM =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER_AWAY_MM;

        const isContraOrdFloorBroker =
            props.data.contraOrdTradeType === SideTradeType.FLOOR_BROKER;
        const isContraOrdFloorBrokerOnBehalfOfLocalMM =
            props.data.contraOrdTradeType ===
            SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isContraOrdFloorBrokerOnBehalfOfAwayMM =
            props.data.contraOrdTradeType ===
            SideTradeType.FLOOR_BROKER_AWAY_MM;

        const user = useUser();
        const entitlement = useEntitlement() || user.entitlement;

        const isCustFloorTrade =
            isCustFloorBroker ||
            isCustFloorBrokerOnBehalfOfAwayMM ||
            isCustFloorBrokerOnBehalfOfLocalMM;
        const isContraOrdFloorTrade =
            isContraOrdFloorBroker ||
            isContraOrdFloorBrokerOnBehalfOfAwayMM ||
            isContraOrdFloorBrokerOnBehalfOfLocalMM;
        const isBrokerFirm = entitlement === Entitlement.BROKER_FIRM;

        const resource = useResource(tradeSplitResource);
        const gboResourceParams = {
            request: {
                mpidStatus: 'Active',
                mic: usePlatform()
            }
        };
        const brokerFirmsResource = useResource(
            viewGBOBrokerFirmsResource,
            gboResourceParams
        );
        const firmsResource = useResource(
            viewGBOFirmsResource,
            gboResourceParams
        );
        const marketMakerFirmsResource = useResource(
            viewGBOMarketMakerFirmsResource,
            gboResourceParams
        );
        const allSndrSubIdOptions = useMemo(
            () => marketMakerFirmsResource.data,
            [marketMakerFirmsResource.data]
        );
        const allFirms = useMemo(() => firmsResource.data, [
            firmsResource.data
        ]);
        const allSubmittingBkrIdOptions = useMemo(
            () => brokerFirmsResource.data,
            [brokerFirmsResource.data]
        );

        const custSubmittingBkrIdOptions = useSubmittingBkrIdOptions({
            sideCrd: props.data.custCrd,
            firmId: props.data.custFirmId,
            tradeType: props.data.custTradeType,
            submittingBkrId: props.data.custSubmittingBkrId
        });
        const contraOrdSubmittingBkrIdOptions = useSubmittingBkrIdOptions({
            sideCrd: props.data.contraOrdCrd,
            firmId: props.data.contraOrdFirmId,
            tradeType: props.data.contraOrdTradeType,
            submittingBkrId: props.data.contraOrdSubmittingBkrId
        });
        const custSndrSubIdOptions = useSndrSubIdOptions({
            sideCrd: props.data.custCrd,
            firmId: props.data.custFirmId,
            tradeType: props.data.custTradeType,
            sndrSubId: props.data.custSndrSubId,
            csgCustFirm: props.csgData.custSndrSubId
        });
        const contraOrdSndrSubIdOptions = useSndrSubIdOptions({
            sideCrd: props.data.contraOrdCrd,
            firmId: props.data.contraOrdFirmId,
            tradeType: props.data.contraOrdTradeType,
            sndrSubId: props.data.contraOrdSndrSubId,
            csgCustFirm: props.csgData.contraOrdSndrSubId
        });

        const [formSubmitted, setFormSubmitted] = useState(false);
        const [reasonCode, setReasonCode] = useState('');
        const [custCrdUsingCustFirmId, setCustCrdUsingCustFirmId] = useState();
        const [
            contraOrdCrdUsingContraOrdFirmId,
            setContraOrdCrdUsingContraOrdFirmId
        ] = useState();
        const [totalSliceQuantity, setTotalSliceQuantity] = useState(0);
        const [sliceQuantity, setSliceQuantity] = useState(props.data.execQty);
        const [slice, setSlice] = useState({});
        const [tradeSplits, setTradeSplits] = useState([]);

        const initialValues = useMemo(
            () => ({
                ...props.data,
                reasonCode: 'Non-Contractual Field Change',
                execQty: sliceQuantity
            }),
            [props.data, sliceQuantity]
        );

        const isBothSelectedSide = props.data.selectedSide === myOwnership.BOTH;

        const onReasonCodeChange = ({values}) => {
            setReasonCode(values.reasonCode);
        };

        const onAddSlice = useCallback(() => {
            setTradeSplits((values) => [...values, cleanObject(slice)]);
        }, [slice]);

        const onFormChange = useCallback(
            (values, {setFieldValue}) => {
                setSlice(omit(values, ['dealNum', 'evtTyp', 'origTdate']));

                if (isBrokerFirm || isCustFloorTrade) {
                    const custFirmIdUsingSubmittingBkrId = allSubmittingBkrIdOptions.find(
                        (custSubmittingBkrIdOption) =>
                            custSubmittingBkrIdOption.value ===
                            values.custSubmittingBkrId
                    )?.firmId;

                    if (custFirmIdUsingSubmittingBkrId) {
                        setFieldValue(
                            'custFirmId',
                            custFirmIdUsingSubmittingBkrId
                        );
                    }
                }

                if (isBrokerFirm || isContraOrdFloorTrade) {
                    const contraOrdFirmIdUsingSubmittingBkrId = allSubmittingBkrIdOptions.find(
                        (contraOrdSubmittingBkrIdOption) =>
                            contraOrdSubmittingBkrIdOption.value ===
                            values.contraOrdSubmittingBkrId
                    )?.firmId;
                    if (contraOrdFirmIdUsingSubmittingBkrId) {
                        setFieldValue(
                            'contraOrdFirmId',
                            contraOrdFirmIdUsingSubmittingBkrId
                        );
                    }
                }
            },
            [
                allSubmittingBkrIdOptions,
                allSndrSubIdOptions,
                isBrokerFirm,
                isContraOrdFloorTrade,
                isCustFloorTrade
            ]
        );

        const isReadyToSubmit = props.data.execQty === totalSliceQuantity;

        const isFormDisabled =
            formSubmitted ||
            props.data.updateToTradeIsInProgress ||
            !props.data.tradeCanBeUpdated;

        const onSubmit = useCallback(
            () =>
                resource.put(
                    {
                        crd: props.crd,
                        splits: tradeSplits
                    },
                    {
                        origTdate: props.data.origTdate,
                        evtTyp: props.data.evtTyp,
                        dealNum: props.data.dealNum
                    }
                ),
            [props.crd, props.data, tradeSplits, resource]
        );

        const onSuccess = useCallback(() => {
            setFormSubmitted(() => true);
        }, []);

        const context = {
            onClick: useCallback((params) => {
                if (params.column.colId === 'delete') {
                    setTradeSplits((splits) =>
                        splits.filter((split, i) => i !== params.rowIndex)
                    );
                }
            }, [])
        };

        useEffect(() => {
            setTotalSliceQuantity(
                tradeSplits.reduce((total, i) => total + i.execQty, 0)
            );
        }, [tradeSplits]);

        useEffect(() => {
            setSliceQuantity(props.data.execQty - totalSliceQuantity);
        }, [props, totalSliceQuantity]);

        return (
            <>
                <NyForm
                    onSuccess={onSuccess}
                    onChange={onFormChange}
                    onSubmit={isReadyToSubmit ? onSubmit : onAddSlice}
                    successMessage={
                        <Link
                            to='/:platform/trade-summary/:view'
                            params={{
                                view: 'transactions'
                            }}
                            search={`?crd=${props.csgData.crd}&origTdate=${props.csgData.origTdate}&dealIdOrig=${props.csgData.dealIdOrig}`}>
                            Click here to see Transactions
                        </Link>
                    }
                    enableReinitialize
                    initialValues={initialValues}>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-2'>
                            <label>Slice Quantity</label>
                            <NyNumberInput
                                name='execQty'
                                min={0}
                                max={sliceQuantity}
                                disabled={isReadyToSubmit || isFormDisabled}
                                required
                            />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-4'>
                            <label>(Change) Reason Code</label>
                            <ReasonCodeSelect
                                name='reasonCode'
                                onChange={onReasonCodeChange}
                                resetFields={['otherReason']}
                                disabled={isReadyToSubmit || isFormDisabled}
                                required
                            />
                        </div>
                        {reasonCode === 'Other' && (
                            <div className='col-xs-12 col-sm-12 col-md-3 col-lg-4'>
                                <label>Other Reason</label>
                                <NyTextInput name='otherReason' required />
                            </div>
                        )}

                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 space-top-1'>
                            <CustomerSideSection
                                currentTab={TradeSummaryTabs.split}
                                custOmsCrd={props.data.custOmsCrd}
                                custCrd={props.data.custCrd}
                                custCrdUsingCustFirmId={custCrdUsingCustFirmId}
                                custFirmId={props.data.custFirmId}
                                custOmsId={props.data.custOmsId}
                                custFirm={props.data.custFirm}
                                custTradeType={props.data.custTradeType}
                                custClearingNumber={
                                    props.data.custClearingNumber
                                }
                                csgCustFirm={props.csgData.custFirm}
                                custSide={props.data.custSide}
                                selectedSide={props.data.selectedSide}
                                qccTrade={props.data.qccTrade}
                                outcryTrade={props.data.outcryTrade}
                                disabled={isFormDisabled || isReadyToSubmit}
                                custSubmittingBkrIdOptions={
                                    custSubmittingBkrIdOptions
                                }
                                custSndrSubIdOptions={
                                    entitlement === Entitlement.MM_FIRM
                                        ? custSndrSubIdOptions
                                        : allSndrSubIdOptions
                                }
                                custSubmittingBkrId={
                                    props.data.custSubmittingBkrId
                                }
                                isMultiAdjustment={false}
                                isMultiEditForAllFields={false}
                            />
                            <ContraSideSection
                                currentTab={TradeSummaryTabs.split}
                                contraOrdCrd={props.data.contraOrdCrd}
                                contraOrdOmsCrd={props.data.contraOrdOmsCrd}
                                contraOrdCrdUsingContraOrdFirmId={
                                    contraOrdCrdUsingContraOrdFirmId
                                }
                                contraOrdFirmId={props.data.contraOrdFirmId}
                                contraOrdOmsId={props.data.contraOrdOmsId}
                                contraOrdCustFirm={props.data.contraOrdCustFirm}
                                selectedSide={props.data.selectedSide}
                                contraOrdTradeType={
                                    props.data.contraOrdTradeType
                                }
                                contraOrdClearingNumber={
                                    props.data.contraOrdClearingNumber
                                }
                                csgContraOrdCustFirm={
                                    props.csgData.contraOrdCustFirm
                                }
                                qccTrade={props.data.qccTrade}
                                outcryTrade={props.data.outcryTrade}
                                contraOrdSubmittingBkrIdOptions={
                                    contraOrdSubmittingBkrIdOptions
                                }
                                contraOrdSndrSubIdOptions={
                                    entitlement === Entitlement.MM_FIRM
                                        ? contraOrdSndrSubIdOptions
                                        : allSndrSubIdOptions
                                }
                                contraOrdSide={props.data.contraOrdSide || ('-' + props.data.custSide)}
                                disabled={isFormDisabled || isReadyToSubmit}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <NySubmitBtn
                                disabled={isFormDisabled}
                                data-e2e-tag={
                                    isReadyToSubmit
                                        ? 'submitBtn'
                                        : 'addSliceBtn'
                                }>
                                {isReadyToSubmit ? 'Submit' : 'Add Slice'}
                            </NySubmitBtn>
                            <NyResetBtn />
                        </div>

                        <div className='col-lg-12 space-top-1'>
                            <TradeSliceCounter
                                totalTradeSplits={totalSliceQuantity}
                                maxQty={props.data.execQty}
                                quantityAllowedForSubmission={
                                    props.data.execQty
                                }
                                title='Total Splits'
                            />
                            <NySubmitAlert hasCloseBtn />
                        </div>
                    </div>
                </NyForm>
                <div className='row space-top-1'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <AgGridProvider
                            columnNamespace={
                                isBothSelectedSide
                                    ? 'tradeSplitBothSide'
                                    : 'tradeSplit'
                            }
                            columnDefs={
                                isBothSelectedSide
                                    ? tradeSplitBothSideColumnDefs
                                    : tradeSplitOneSideColumnDefs
                            }
                            rowData={tradeSplits}
                            submitOnLoad={false}>
                            <AgGrid context={context} height={200} />
                        </AgGridProvider>
                    </div>
                </div>
            </>
        );
    }
);
