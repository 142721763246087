import {v4} from 'uuid';

interface IWorker {
    subscribeClient(destination: string): void;
    unsubscribeClient(destination: string): void;
    sendClientMessage(destination: string, message: any): void;
    destroyWorker(): void;
    destroyClient(): void;
};

type PortClientWorkerProps = {
    url: string | null,
    authHeaders: any,
    reconnectDelay?: number,
    heartbeatIncoming?: number,
    heartbeatOutgoing?: number,
    onConnected?: Function,
    onMessageReceived?: Function,
    onStompError?: Function
};

export default class PortClientWorker implements IWorker{
    private _clientConfig: any;
    private _portId: string;
    private _sharedWorker: SharedWorker;

    private _onConnected: Function;
    private _onMessageReceived: Function;
    private _onStompError: Function;

    constructor(props?: PortClientWorkerProps) {
        this._clientConfig = {
            url: props.url,
            authHeaders: props.authHeaders,
            reconnectDelay: props?.reconnectDelay ?? 5000,
            heartbeatIncoming: props?.heartbeatIncoming ?? 5000,
            heartbeatOutgoing: props?.heartbeatOutgoing ?? 5000
        };
        this._portId = v4();
        this._sharedWorker = new SharedWorker('worker.js');

        const noOp = () => {};
        this._onConnected = props?.onConnected ?? noOp;
        this._onMessageReceived = props?.onMessageReceived ?? noOp;
        this._onStompError = props?.onStompError ?? noOp;

        this._sharedWorker.port.start();

        this._sharedWorker.port.addEventListener('message', (e: MessageEvent) => {
            const { data: { type } } = e;
            switch (type) {
                case 'PORT_CONNECTED':
                    this._onPortConnected();
                    break;
                case 'CLIENT_CONNECTED':
                    this._onClientConnected();
                    break;
                case 'CLIENT_MESSAGE_RECEIVED':
                    this._onClientMessageReceived(e);
                    break;
                case 'CLIENT_STOMP_ERROR':
                    this._onClientStompError(e);
                    break;
            };
        });
    }

    // receive message from worker
    private _onPortConnected() {
        this._addPort();
        this._connectClient();
    };
    
    private _onClientConnected() {
        this._onConnected();
    };
    
    private _onClientMessageReceived(e: MessageEvent) {
        this._onMessageReceived(e?.data?.value);
    };

    private _onClientStompError(e: MessageEvent) {
        this._onStompError(e?.data?.value);
    };

    // post message to worker
    private _addPort() {
        this._sharedWorker.port.postMessage({
            type: 'ADD_PORT',
            id: this._portId
        });
    };

    private _removePort() {
        this._sharedWorker.port.postMessage({
            type: 'REMOVE_PORT',
            id: this._portId
        });
    };

    private _connectClient() {
        this._sharedWorker.port.postMessage({
            type: 'CONNECT_CLIENT',
            id: this._portId,
            value: {
                wsURL: this._clientConfig.url,
                authHeaders: this._clientConfig.authHeaders,
                reconnectDelay: this._clientConfig.reconnectDelay,
                heartbeatIncoming: this._clientConfig.heartbeatIncoming,
                heartbeatOutgoing: this._clientConfig.heartbeatOutgoing,
            }
        });
    };

    private _disconnectClient() {
        this._sharedWorker.port.postMessage({
            type: 'DISCONNECT_CLIENT'
        });
    };

    public subscribeClient(destination: string) {
        this._sharedWorker.port.postMessage({
            type: 'SUBSCRIBE_CLIENT',
            id: this._portId,
            value: { destination }
        });
    };

    public unsubscribeClient(destination: string) {
        this._sharedWorker.port.postMessage({
            type: 'UNSUBSCRIBE_CLIENT',
            id: this._portId,
            value: { destination }
        });
    };

    public sendClientMessage(destination: string, message?: any) {
        this._sharedWorker.port.postMessage({
            type: 'SEND_CLIENT_MESSAGE',
            id: this._portId,
            value: { destination, message }
        });
    };

    public destroyWorker() {
        this._removePort();
        this._sharedWorker = null;
    };

    public destroyClient() {
        this._disconnectClient();
        this._sharedWorker = null;
    };
}
