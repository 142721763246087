import React, {useMemo, useState} from 'react';
import {omit} from 'lodash-es';
import {Box, Grid, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'notistack';
import {
    NyButton,
    NyConfirmSubmitBtn,
    NyForm,
    NyResetBtn,
    NySelectInput,
    NySubmitAlert,
    NyTextInput
} from 'nyse-web-tools-common/lib/react';
import useResource from '../hooks/useResource.hook';
import usePlatforms from '../hooks/usePlatforms';
import {broadcastMessageResource} from '../resources/broadcastMessage.resource';

const messageTypeOptions = [
    {
        label: 'Information',
        value: {
            preview: 'info',
            submit: 'INFORMATION'
        }
    },
    {
        label: 'Error/Outage',
        value: {
            preview: 'error',
            submit: 'ERROR'
        }
    },
    {
        label: 'Announcement',
        value: {
            preview: 'info',
            submit: 'ANNOUNCEMENT'
        }
    }
];

const broadcastOptions = [
    {
        label: 'Notification to logged in user',
        value: 'NOTIFY'
    }
];

const Divider = React.memo(() =>
    <hr
        style={{
            border: 'none',
            height: '1px',
            width: '100%',
            margin: '30px 0',
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
        }}
    />
);

export const BroadcastMessagePage = React.memo(() => {
    const resources = useResource(broadcastMessageResource);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const platforms = usePlatforms();
    const marketOptions = useMemo(() => platforms ? platforms.map(platform => ({label: platform.name, value: platform.mic})) : [], [platforms]);
    const initFmValues = {
        selectedMarketOptions: [],
        messageTitle: '',
        messageContent: '',
        selectedMessageTypeOptions: '',
        selectedBroadcastOptions: ['NOTIFY']
    };

    const [fmValues, setFmValues] = useState(initFmValues);

    const isSubmitValid = useMemo(() => fmValues.messageTitle !== '' && fmValues.selectedMessageTypeOptions !== '' && fmValues.selectedBroadcastOptions.length > 0, [fmValues]);

    const onChange = (e) => {
        setFmValues({
            ...fmValues,
            ...omit(e, ['messageContent']),
        })
    };

    const onTextareaChange = (e) => {
        setFmValues({
            ...fmValues,
            messageContent: e.target.value
        });
    };

    const onPreview = () => {
        const {
            selectedMarketOptions,
            messageTitle,
            messageContent,
            selectedMessageTypeOptions
        } = fmValues;
        const timeStamp = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], {timeZoneName: 'short'})}`;
        // no selected market => send message to general channel
        const marketOptions = selectedMarketOptions.length === 0 ? [''] : selectedMarketOptions;
        marketOptions.forEach(market => {
            enqueueSnackbar(
                (
                    <span data-e2e-tag='hintMessageBox' style={{maxWidth: '400px', wordBreak: 'break-word', wordWrap: 'break-word'}}>
                        {messageTitle && <div style={{fontSize: '1.1em', fontWeight: 'bold'}}>{market ? `[${market}] ${messageTitle}` : messageTitle}</div>}
                        {messageContent && <div style={{fontSize: '1em', padding: '5px 0 0'}}>{messageContent}</div>}
                        {timeStamp && <div style={{fontSize: '0.9em', padding: '10px 0 0'}}>{timeStamp}</div>}
                    </span>
                ),
                {
                    variant: selectedMessageTypeOptions?.preview ?? 'default',
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom'
                    },
                    style: {
                        textAlign: 'left',
                        top: -50
                    },
                    persist: true,
                    action: (snackbarId) => (
                        <IconButton
                            style={{position: 'absolute', top: '2px', right: '0px'}}
                            color='inherit'
                            onClick={() => closeSnackbar(snackbarId)}
                        >
                            <CloseIcon />
                        </IconButton>
                    )
                },
            );
        });
    };

    const onSubmit = async () => {
        const {
            selectedMarketOptions,
            messageTitle,
            messageContent,
            selectedMessageTypeOptions,
            selectedBroadcastOptions,
        } = fmValues;
        // remove all preview snackbar before submitting
        closeSnackbar();
        try {
            await resources.post({
                mics: selectedMarketOptions,
                title: messageTitle,
                content: messageContent,
                messageType: selectedMessageTypeOptions?.submit,
                broadCastOptions: selectedBroadcastOptions
            });
        } catch (e) {
            throw e;
        }
    };

    const onReset = () => {
        setFmValues(initFmValues);
    };

    return (
        <div className='text-center mt-2'>
            <NyForm
                className='row'
                initialValues={initFmValues}
                enableReinitialize
                confirmMessage='Are you sure you would like to submit message?'
                confirmText='APPROVE'
                onChange={onChange}
                onSubmit={onSubmit}
                onReset={onReset}
            >
                <div className='row col-lg-12 col-md-12 col-sm-12 col-xs-12 text-left'>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Markets</label>
                        <NySelectInput
                            isMulti
                            name='selectedMarketOptions'
                            options={marketOptions}
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Message Type</label>
                        <NySelectInput
                            required
                            name='selectedMessageTypeOptions'
                            options={messageTypeOptions}
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Broadcast Type</label>
                        <NySelectInput
                            isMulti
                            required
                            name='selectedBroadcastOptions'
                            options={broadcastOptions}
                        />
                    </div>
                </div>
                <div className='form-group col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <h2 className='flex-row flex-align-bottom'>MESSAGE</h2>
                    <Grid container>
                        <Grid item xs={12} lg={1}>
                            <Box display='flex' justifyContent='flex-start'>
                                <label>Title:</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <NyTextInput required name='messageTitle' />
                        </Grid>
                        <Grid item lg={12}>
                            <Box mb={4} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} lg={1}>
                            <Box display='flex' justifyContent='flex-start'>
                                <label>Content:</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <textarea
                                data-e2e-tag='messageContent'
                                rows={3}
                                maxLength={150}
                                style={{
                                    width: '100%',
                                    padding: 0,
                                    resize: 'none',
                                    backgroundColor: 'white',
                                    transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
                                    textIndent: '0.5em',
                                    border: '1px solid transparent',
                                    boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
                                }}
                                onChange={onTextareaChange}
                                value={fmValues.messageContent}
                            />
                            <div className='text-right'>{fmValues.messageContent.length} / 150</div>
                        </Grid>
                    </Grid>
                </div>
                <Divider/>
                <div className='row col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <NyButton
                        primary
                        data-e2e-tag='previewBtn'
                        className='space-left-1 space-right-1'
                        onClick={onPreview}
                        title='Preview'
                    >
                        Preview
                    </NyButton>
                    <NyConfirmSubmitBtn disabled={!isSubmitValid} />
                    <NyResetBtn/>
                </div>
                <Box component={NySubmitAlert} py={1} className='col-lg-12 col-md-12 col-sm-12 col-xs-12'/>
            </NyForm>
        </div>
    );
});