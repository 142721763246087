import {AgGrid, AgGridProvider} from 'nyse-web-tools-common/lib/react';
import {NyForm} from 'nyse-web-tools-common/lib/react';
import {NyTextInput} from 'nyse-web-tools-common/lib/react';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useMemo, useState} from 'react';

import {tradeUnallocateColumnDefs} from '../../../../configs/columnDefs/tradeUnallocate.columnDefs';
import useResource from '../../../../hooks/useResource.hook';
import {
    MAX_ALLOWED_SLICES,
    TradeUnallocateProps,
    myOwnership,
    tradeUnallocateDetailResource,
    tradeUnallocateResource
} from '../../../../resources/tradeSummary.resource';
import {Link} from '../../../navigation/navigation.link';
import {ReasonCodeSelect} from '../../../selects/reasonCode.select';

export const TradeUnallocateForm: React.FC<TradeUnallocateProps> = React.memo(
    (props) => {
        const resource = useResource(tradeUnallocateResource);
        const detailParams = useMemo(
            () => ({
                crd: props.crd,
                allocatedTradeSummary: true,
                dealIdRef: props.data.dealIdRef,
                evtTyp: 'ALC',
                orig_Tdate: props.data.origTdate,
                endRefExecTs: `${props.data.origTdate}T23:59:59.999999`,
                startRefExecTs: `${props.data.origTdate}T00:00:00.000000`,
                limit: 500,
                offset: 0
            }),
            [props.crd, props.data]
        );
        const detailResource = useResource(
            tradeUnallocateDetailResource,
            detailParams
        );

        const isBothSelectedSide = props.data.selectedSide === myOwnership.BOTH;

        const [formSubmitted, setFormSubmitted] = useState(false);
        const [reasonCode, setReasonCode] = useState('');

        const [selected, setSelected] = useState([]);

        const isFormDisabled =
            formSubmitted ||
            props.data.updateToTradeIsInProgress ||
            !props.data.tradeCanBeUpdated;

        const onSubmit = useCallback(
            ({reasonCode, otherReason}) =>
                resource.put(
                    {
                        crd: props.crd,
                        unallocations: selected.map((tradeUnallocation) => ({
                            ...tradeUnallocation,
                            ...(reasonCode === 'Other'
                                ? {reasonCode: otherReason}
                                : {reasonCode: reasonCode})
                        }))
                    },
                    {
                        origTdate: props.data.origTdate,
                        evtTyp: props.data.evtTyp,
                        dealNum: props.data.dealNum
                    }
                ),
            [
                props.crd,
                props.data.origTdate,
                props.data.evtTyp,
                props.data.dealNum,
                selected,
                resource
            ]
        );

        const onReasonCodeChange = ({values}) => {
            setReasonCode(values.reasonCode);
        };

        const onSuccess = useCallback(() => {
            setFormSubmitted(() => true);
        }, []);

        const onSelectionChanged = useCallback((selected) => {
            setSelected(selected);
        }, []);

        const isAtMaxNumberOfUnallocates = selected.length > MAX_ALLOWED_SLICES;

        const onLoad = useCallback(() => {
            detailResource.get();
        }, [detailResource]);

        return (
            <>
                <div className='row space-bottom-2'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <AgGridProvider
                            columnNamespace={
                                isBothSelectedSide
                                    ? 'tradeUnallocateBothSide'
                                    : 'tradeUnallocate'
                            }
                            columnDefs={tradeUnallocateColumnDefs}
                            rowSelection='multiple'
                            onSelectionChanged={onSelectionChanged}
                            isLoading={detailResource.pending}
                            rowData={detailResource.data}
                            onLoad={onLoad}
                            submitOnLoad={false}>
                            <AgGrid height={200} />
                        </AgGridProvider>
                    </div>
                </div>
                <NyForm
                    onSuccess={onSuccess}
                    onSubmit={onSubmit}
                    initialValues={{reasonCode: 'Non-Contractual Field Change'}}
                    successMessage={
                        <Link
                            to='/:platform/trade-summary/:view'
                            params={{
                                view: 'transactions'
                            }}
                            search={`?crd=${props.csgData.crd}&origTdate=${props.csgData.origTdate}&dealIdOrig=${props.csgData.dealIdOrig}`}>
                            Click here to see Transactions
                        </Link>
                    }>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-4'>
                            <label>(Change) Reason Code</label>
                            <ReasonCodeSelect
                                name='reasonCode'
                                onChange={onReasonCodeChange}
                                resetFields={['otherReason']}
                                disabled={
                                    isFormDisabled || isAtMaxNumberOfUnallocates
                                }
                                required
                            />
                        </div>
                        {reasonCode === 'Other' && (
                            <div className='col-xs-12 col-sm-12 col-md-3 col-lg-4'>
                                <label>Other Reason</label>
                                <NyTextInput name='otherReason' required />
                            </div>
                        )}
                    </div>
                    <div className='row space-top-1'>
                        <div className='col-lg-12 space-top-1'>
                            <NySubmitAlert hasCloseBtn />
                        </div>
                        <div className='col-lg-12'>
                            <NySubmitBtn
                                disabled={
                                    isFormDisabled || isAtMaxNumberOfUnallocates
                                }
                            />
                        </div>
                    </div>
                </NyForm>
            </>
        );
    }
);
