import React from 'react';

export class UnauthorizedPage extends React.PureComponent {
    render() {
        return (
            <>
                <p>
                    You do not have permissions to access this page. Please
                    contact &nbsp;<a href='mailto:crs@nyse.com'>crs@nyse.com</a>
                    &nbsp; with any questions.
                </p>
            </>
        );
    }
}
