import { scale } from 'chroma-js';
import NySelectInput from 'nyse-web-tools-common/lib/react/form/NySelectInput/NySelectInput';
import NyUppercaseInput from 'nyse-web-tools-common/lib/react/form/NyUppercaseInput/NyUppercaseInput';
import AgGrid from 'nyse-web-tools-common/lib/react/grid/AgGrid/AgGrid';
import AgGridAutoRefresh from 'nyse-web-tools-common/lib/react/grid/AgGridAutoRefresh/AgGridAutoRefresh';
import AgGridPagination from 'nyse-web-tools-common/lib/react/grid/AgGridPagination/AgGridPagination';
import AgGridProvider from 'nyse-web-tools-common/lib/react/grid/AgGridProvider/AgGridProvider';
import AgGridToggleShowFilters
    from 'nyse-web-tools-common/lib/react/grid/AgGridToggleShowFilters/AgGridToggleShowFilters';
import useManagedState from 'nyse-web-tools-common/lib/react/hooks/useManagedState/useManagedState';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FirmSelect } from '../components/selects/firm.select';
import { FirmParticipantSelect } from '../components/selects/firmParticipant.select';
import { MmIdSelect } from '../components/selects/mmid.select';
import { riskMonitorDetailColumnDefs } from '../configs/columnDefs/riskMonitorDetail.columnDefs';
import { riskMonitorV2ColumnDefs } from '../configs/columnDefs/riskMonitorV2.columnDefs';
import usePlatform from '../hooks/usePlatform';
import useResource from '../hooks/useResource.hook';
import useRoute from '../hooks/useRoute.hook';
import useStore from '../hooks/useStore.hook';
import useOptionsCheck from '../hooks/useOptionsCheck.hook';
import { riskMonitorDetailsResource, riskMonitorResource } from '../resources/riskMonitor.resource';
import { mapUserCrdRole } from '../resources/riskSettings.resource';

export const RiskMonitorPage = React.memo(() => {
    const route = useRoute();
    const firms = useStore('firms');
    const user = useStore('user');
    const permissions = useStore('permissions');
    const platforms = usePlatform();
    const resource = useResource(riskMonitorResource);
    const detailResource = useResource(riskMonitorDetailsResource);
    const [closeSuccess, setCloseSuccess] = useState(false);
    const isOptionsMarket = useOptionsCheck();

    const userCrdRole = useMemo(
        () => mapUserCrdRole(user.entitlement, platforms),
        [user.entitlement, platforms]
    );

    const [values, setValues] = useManagedState({
        userCrd: route.params.crd || (firms[0] || {}).crd,
        entityMpid: '',
        myLimits: '',
    });

    const dangerScale = scale(['white', 'yellow', 'orange', 'red']);

    const init = useMemo(
        () => ({
            detailCellRendererParams: {
                suppressRefresh: false,
                refreshStrategy: 'rows',
                detailGridOptions: {
                    columnDefs: riskMonitorDetailColumnDefs,
                    enableCellChangeFlash: true,
                    immutableData: true,
                    getRowStyle: ({data: {currentPct}}) => ({
                        backgroundColor:
                            currentPct >= 50
                                ? dangerScale(currentPct / 100)
                                : undefined,
                        color: currentPct / 100 >= 0.8 ? '#ffffff' : undefined
                    }),
                    getRowNodeId: function (data) {
                        return (
                            data.limitCrd +
                            data.limitCrdRole +
                            data.action +
                            data.riskType
                        );
                    }
                },
                getDetailRowData: (params) => {
                    detailResource
                        .get({
                            userCrd: params.data.userCrd,
                            userCrdRole,
                            entityId: params.data.entityId,
                            myLimits: params.data.myLimits
                        })
                        .then(({data: {data}}) => {
                            params.node.setRowHeight(
                                data === undefined || data.length ===0
                                            ? 120
                                            : data.length * 28 + 66
                            );
                            params.node.gridApi.onRowHeightChanged();
                            params.successCallback(data);
                        });
                }
            },
            pagination: {
                limit: 100,
                offset: 0
            },
            values: {
                userCrd: route.params.crd || (firms[0] || {}).crd,
                entityMpid: '',
                blockBy: 'BREACH_AND_KILL_SWITCH'
            }
        }),
        [detailResource, dangerScale, userCrdRole, firms, route.params.crd]
    );

    const onLoad = useCallback(
        (values) => {
            resource.get({
                ...values,
                userCrdRole
            });
        },
        [userCrdRole, resource]
    );

    useEffect(() => {
        if (closeSuccess) {
            onLoad(values);
            setCloseSuccess(false);
        }
    }, [closeSuccess, onLoad, values]);

    const onModalClosed = (isSuccesful) => {
        if (isSuccesful) {
            setCloseSuccess(isSuccesful);
        }
    };

    const agGridOverride = {
        onRowGroupOpened: (e) => {
            if (e.node.expanded) {
                const detailId = `detail_${e.node.id}`;
                e.api.forEachDetailGridInfo((node) => {
                    if (node.id !== detailId) {
                        const closedNode = e.api.getRowNode(
                            node.id.replace('detail_', '')
                        );
                        closedNode.setExpanded(false);
                        e.api.redrawRows({rowNodes: [closedNode]});
                    }
                });
            }
        }
    };

    const gridCtx = {
        isOptionsMarket,
        onModalClosed,
        entitlement: user.entitlement,
        userCrdRole
    };

    return (
        <AgGridProvider
            columnDefs={riskMonitorV2ColumnDefs}
            columnNamespace='riskMonitorV2'
            onFiltersChanged={setValues}
            onLoad={onLoad}
            isLoading={resource.pending}
            error={resource.error}
            initialFilters={init.values}
            initialPagination={init.pagination}
            permissions={permissions}
            primaryKey='displayRiskEntity'
            rowData={resource.data}
            submitOnLoad={user.entitlement !== 'SUPER_USER'}>
            <AgGridProvider.Filters>
                {firms.length > 1 && (
                    <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                        <label>Risk User</label>
                        <FirmSelect
                            initialValue={route.params.crd}
                            name='userCrd'
                            resetFields={['entityMpid']}
                            required
                        />
                    </div>
                )}
                {(user.entitlement !== 'MM_FIRM' || !isOptionsMarket) && (
                    <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-1'>
                        <label>MPID</label>
                        <FirmParticipantSelect
                            crd={values.userCrd}
                            disableInactiveControl
                            entitlements={[
                                'BROKER_FIRM',
                                'MEMBER_FIRM',
                                'IB_FIRM',
                                'TRADE_CHECKER'
                            ]}
                            hideEtpid
                            name='entityMpid'
                        />
                    </div>
                )}
                {isOptionsMarket &&
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-1'>
                    <label>MMID</label>
                    {user.entitlement === 'MM_FIRM' ? (
                        <MmIdSelect crd={values.userCrd} name='entityMmid'/>
                    ) : (
                        <NyUppercaseInput name='entityMmid'/>
                    )}
                </div>
                }
                <div className='form-group col-xs-12 col-sm-3 col-md-3 col-lg-2'>
                    <label>View My Limits</label>
                    <NySelectInput name='myLimits'>
                        <option value=''>-</option>
                        <option value='true'>Y</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-1'>
                    <label>Block By</label>
                    <NySelectInput name='blockBy'>
                        <option value=''>-</option>
                        <option value='BREACH'>Breach</option>
                        <option value='KILL_SWITCH'>Kill Switch</option>
                        <option value='BREACH_AND_KILL_SWITCH'>
                            Breach/Kill Switch
                        </option>
                    </NySelectInput>
                </div>
                {isOptionsMarket && (
                    <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-1'>
                        <label>Symbol</label>
                        <NyUppercaseInput name='entitySymbol'/>
                    </div>
                )}
            </AgGridProvider.Filters>

            <div className='row space-bottom-1'>
                <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                    <div className='flex-row flex-align-bottom'>
                        <AgGridProvider.SubmitBtn/>
                        <AgGridProvider.ResetBtn/>
                        <div className='btn-circle-grp flex-pull-right flex-row'>
                            <AgGridAutoRefresh/>
                            <AgGridToggleShowFilters/>
                        </div>
                    </div>
                </div>
            </div>

            <AgGrid
                autoRefreshInterval={10000}
                agGridOverride={agGridOverride}
                context={gridCtx}
                detailsRowProperty='limits'
                detailCellRendererParams={init.detailCellRendererParams}
                masterDetail
            />
            <AgGridPagination>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1,000</option>
                <option value={5000}>5,000</option>
                <option value={10000}>10,000</option>
            </AgGridPagination>
        </AgGridProvider>
    );
});
