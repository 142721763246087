import {NyModal} from 'nyse-web-tools-common/lib/react';
import React from 'react';

import {MultiTradeAdjustmentProps} from '../../../resources/tradeSummary.resource';
import {MultiTradeAdjustmentForm} from './forms/multiTradeAdjustment.form';

export const MultiTradeActionModal = React.memo(
    (props: MultiTradeAdjustmentProps) => {
        return (
            <NyModal
                className='full-screen'
                hideModal={props.hideModal}
                name='multiTradeSummaryAdjustment'
                show={props.show}
                parentTitle='Trade Summary'
                title={'MULTI-EDIT'}>
                <div className='col-xs-12 flex-column flex-grow'>
                    <MultiTradeAdjustmentForm
                        data={props.data}
                        crd={props.crd}
                    />
                </div>
            </NyModal>
        );
    }
);
