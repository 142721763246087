import {noop} from 'lodash-es';
import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React from 'react';

export class PrimaryExchangeSelect extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        required: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        required: false,
        onChange: noop
    };

    render() {
        return (
            <NySelectInput
                {...this.props}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
                name={this.props.name}>
                <option value=''>-</option>
                <option value='ARCX'>ARCX</option>
                <option value='BATY'>BATY</option>
                <option value='BATZ'>BATZ</option>
                <option value='XASE'>XASE</option>
                <option value='XCHI'>XCHI</option>
                <option value='XNAS'>XNAS</option>
                <option value='XNYS'>XNYS</option>
                <option value='XPHL'>XPHL</option>
            </NySelectInput>
        );
    }
}
