import Details from '@material-ui/icons/Details';
import React from 'react';

export const marketStatusTraderUpdatesPanelColumnDefs = [
    {
        cellRendererFramework: () => <Details style={{height: '100%', color: '#00b9e4'}} />,
        field: '_details_',
        headerName: '',
        pinned: 'left',
        sortable: false,
        width: 15
    },
    {
        field: 'date',
        headerName: 'Date',
        sortable: false,
        width: 80
    },
    {
        field: 'description',
        headerName: 'Description',
        sortable: false,
        width: 250
    },
    {
        field: 'market',
        headerName: 'Market',
        sortable: false,
        width: 200
    },
    {
        field: 'service',
        headerName: 'Service',
        sortable: false,
        width: 50
    }
];
