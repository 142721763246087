import {LocalDateTime} from '@js-joda/core';
import {get} from 'lodash-es';

import {events} from '../events';

export const tradesReducer = {
    [events.TRADES_GET]: (state) => ({
        ...state,
        isLoading: true
    }),
    [events.TRADES_SUCCESS]: (state, {payload: {data}}) => ({
        ...state,
        isLoading: false,
        error: false,
        count: get(data, 'meta.totalRecords', 0),
        rowData: get(data, 'data', []),
        timestamp: LocalDateTime.now()
    }),
    [events.TRADES_ERROR]: (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
        rowData: [],
        count: 0,
        timestamp: LocalDateTime.now()
    })
};
