import {cdmMw} from '../api';

export type SubAccountContact = {
    activeStatus?: boolean;
    contactId?: number;
    createdBy?: string;
    createdTime?: string;
    firstName?: string;
    lastName?: string;
    modifiedBy?: string;
    modifiedTime?: string;
    subAccountId: number;
};

export default {
    api: cdmMw,
    url: (params) =>
        params.contactId
            ? `api/sub-accounts/${params.subAccountId}/contacts/${params.contactId}`
            : `api/sub-accounts/${params.subAccountId}/contacts`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
};
