import {useQuery} from 'react-query';
import type {QueryFunctionContext, UseQueryResult} from 'react-query';

import {cdmMw} from '../api';

export const useActionBlockTime = (): UseQueryResult<string, any> => {
    return useQuery(
        ['api/eod/trade-action-block-time'],
        async (context: QueryFunctionContext): Promise<any> => {
            const {data} = await cdmMw.get(context.queryKey[0] as string);

            return data?.data ?? [];
        }
    );
};

export const useCleanUpJobs = (): UseQueryResult<string, any> => {
    return useQuery(
        ['api/eod/jobs/clean-up-job'],
        async (context: QueryFunctionContext): Promise<any> => {
            const {data} = await cdmMw.get(context.queryKey[0] as string);

            return data?.data ?? '';
        }
    );
};

export const useGoodNightJobs = (): UseQueryResult<string, any> => {
    return useQuery(
        ['api/eod/jobs/good-night-job'],
        async (context: QueryFunctionContext): Promise<any> => {
            const {data} = await cdmMw.get(context.queryKey[0] as string);

            return data?.data ?? '';
        }
    );
};
