import {capitalize, debounce, omit} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridProvider,
    AgGridTimestamp,
    AgGridToggleShowFilters,
    NyDateTimeInput,
    NySelectInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {NavigationSectionMenu} from '../components/navigation/navigation.section.menu';
import {FirmSelect} from '../components/selects/firm.select';
import {FirmParticipantSelect} from '../components/selects/firmParticipant.select';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {getMyVolumesCadv} from '../stores/myVolumes/myVolumesCadv.actions';
import {getMyVolumesFilters} from '../stores/myVolumes/myVolumesFilters.actions';

@connect(
    [
        'firms',
        'columnDefs',
        'myVolumesCadv',
        'myVolumesFilters',
        'platform',
        'route'
    ],
    {
        getMyVolumesCadv,
        getMyVolumesFilters,
        setColumns
    }
)
export class MyVolumesCadvPage extends React.Component {
    state = {
        filterValues: {
            searchBy: 'month'
        },
        views: [
            {
                title: 'CADV',
                url: '/:platform/volumes/cadv'
            },
            {
                title: 'Broker Rank',
                url: '/:platform/volumes/broker-rank'
            },
            {
                title: 'Trade Count',
                url: '/:platform/volumes/trade-count'
            },
            {
                title: 'Search',
                url: '/:platform/volumes/search'
            }
        ]
    };

    _dateRange = {
        min: joda.startOfDay().minusMonths(6)
    };

    _initialFilters = {
        brokerBadge: '',
        crd: this.props.route.params.crd || (this.props.firms[0] || {}).crd,
        date: '',
        mmId: '',
        participantId: '',
        searchBy: 'month',
        sessionId: ''
    };

    _getMyVolumesFilters = debounce(this.props.getMyVolumesFilters, 500);

    componentDidUpdate(prevProps, prevState) {
        const hasCrdChanged =
            prevState.filterValues.crd !== this.state.filterValues.crd;
        const hasMpidChanged =
            prevState.filterValues.participantId !==
            this.state.filterValues.participantId;
        const hasDateChanged =
            (prevState.filterValues.date || '').toString() !==
            (this.state.filterValues.date || '').toString();

        if (hasCrdChanged || hasMpidChanged || hasDateChanged) {
            this._getMyVolumesFilters(this.state.filterValues);
        }
    }

    componentWillUnmount() {
        this._getMyVolumesFilters.cancel();
    }

    _onDateChange = ({setValue}) => {
        setValue('brokerBadge', '');
        setValue('mmId', '');
        setValue('sessionId', '');
    };

    _onFiltersChanged = (filterValues) => {
        this.setState({
            filterValues
        });
    };

    _onFirmChange = ({setValue}) => {
        setValue('brokerBadge', '');
        setValue('mmId', '');
        setValue('participantId', '');
        setValue('sessionId', '');
    };

    render() {
        const {brokerBadges, mmIds, sessionIds} = this.props.myVolumesFilters;
        const namespace = `myVolumesCadv${capitalize(
            this.state.filterValues.searchBy
        )}`;

        return (
            <>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                        <NavigationSectionMenu
                            queryParams={omit(
                                this.state.filterValues,
                                this.state.filterValues.searchBy === 'month'
                                    ? ['searchBy']
                                    : ['searchBy', 'date']
                            )}
                            views={this.state.views}
                        />
                    </div>
                </div>
                <AgGridProvider
                    columnDefs={this.props.columnDefs[namespace]}
                    columnNamespace={namespace}
                    filtersClassName='row space-bottom-1'
                    initialFilters={this._initialFilters}
                    onColumnsChanged={this.props.setColumns}
                    onFiltersChanged={this._onFiltersChanged}
                    onLoad={this.props.getMyVolumesCadv}
                    submitOnLoad={true}
                    submitOnNamespaceChanged={true}
                    {...this.props.myVolumesCadv}>
                    <AgGridProvider.Filters>
                        <div className='form-group col-lg-2 col-md-2 col-md-2 col-sm-3 col-xs-12'>
                            <label>Search By</label>
                            <NySelectInput name='searchBy'>
                                <option value='month'>Month</option>
                                <option value='day'>Day</option>
                            </NySelectInput>
                        </div>
                        <div className='form-group col-lg-2 col-md-2 col-md-2 col-sm-3 col-xs-12'>
                            <label>Date</label>
                            <NyDateTimeInput
                                className='form-control'
                                format={
                                    this.state.filterValues.searchBy === 'month'
                                        ? 'MM/yyyy'
                                        : 'MM/dd/yyyy'
                                }
                                initialValue={this.props.route.params.date}
                                minView={`${this.state.filterValues.searchBy}s`}
                                min={this._dateRange.min}
                                name='date'
                                onChange={this._onDateChange}
                            />
                        </div>
                        {this.props.firms.length > 1 && (
                            <div className='form-group col-lg-2 col-md-2 col-md-2 col-sm-3 col-xs-12'>
                                <label>Firm</label>
                                <FirmSelect
                                    name='crd'
                                    onChange={this._onFirmChange}
                                    required={true}
                                    showInactive={true}
                                />
                            </div>
                        )}
                        <div className='form-group col-lg-2 col-md-2 col-md-2 col-sm-3 col-xs-12'>
                            <label>ETPID / MPID</label>
                            <FirmParticipantSelect
                                crd={this.state.filterValues.crd}
                                initialValue={
                                    this.props.route.params.participantId
                                }
                                name='participantId'
                            />
                        </div>
                        <div className='form-group col-lg-2 col-md-2 col-md-2 col-sm-3 col-xs-12'>
                            <label>Session Id</label>
                            <NySelectInput
                                name='sessionId'
                                disabled={sessionIds.length === 0}
                                initialValue={
                                    this.props.route.params.sessionId
                                }>
                                <option value=''>-</option>
                                {sessionIds.map((sessionId) => (
                                    <option key={sessionId} value={sessionId}>
                                        {sessionId}
                                    </option>
                                ))}
                            </NySelectInput>
                        </div>
                        <div className='form-group col-lg-2 col-md-2 col-md-2 col-sm-3 col-xs-12'>
                            <label>Broker Badge</label>
                            <NySelectInput
                                name='brokerBadge'
                                disabled={brokerBadges.length === 0}
                                initialValue={
                                    this.props.route.params.brokerBadge
                                }>
                                <option value=''>-</option>
                                {brokerBadges.map((brokerBadge) => (
                                    <option
                                        key={brokerBadge}
                                        value={brokerBadge}>
                                        {brokerBadge}
                                    </option>
                                ))}
                            </NySelectInput>
                        </div>
                        <div className='form-group col-lg-2 col-md-2 col-md-2 col-sm-3 col-xs-12'>
                            <label>MMID</label>
                            <NySelectInput
                                name='mmId'
                                disabled={mmIds.length === 0}
                                initialValue={this.props.route.params.mmId}>
                                <option value=''>-</option>
                                {mmIds.map((mmId) => (
                                    <option key={mmId} value={mmId}>
                                        {mmId}
                                    </option>
                                ))}
                            </NySelectInput>
                        </div>
                    </AgGridProvider.Filters>

                    <div className='row space-bottom-1'>
                        <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                            <div className='flex-row flex-align-bottom'>
                                <AgGridProvider.SubmitBtn>
                                    Search
                                </AgGridProvider.SubmitBtn>
                                <AgGridProvider.ResetBtn>
                                    Reset
                                </AgGridProvider.ResetBtn>
                                <div className='btn-circle-grp flex-pull-right'>
                                    <AgGridToggleShowFilters />
                                    <AgGridExportMenu
                                        customHeader={
                                            this.props.myVolumesCadv.timestamp
                                        }
                                        exportFileName={namespace}
                                        exportSheetName={
                                            this.props.platform.name
                                        }
                                    />
                                    <AgGridColumnMenuTrigger />
                                </div>
                            </div>
                        </div>
                    </div>

                    <AgGrid reactNext={true} treeData={true} />

                    <div className='row space-top-1'>
                        <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                            <AgGridTimestamp timestampPrefix='Volumes' />
                        </div>
                    </div>

                    <div className='disclaimer space-top-1'>
                        This information is provided 'as is', and NYSE, NYSE
                        Arca, NYSE American, NYSE Arca Options, NYSE American
                        Options and their respective affiliates do not warrant
                        the accuracy, adequacy, timeliness or completeness of
                        this information. The information, which excludes any
                        data from the current trading day, is intended to be
                        used for informational purposes only and will not be
                        used to determine qualification for any rebates or
                        incentives.
                    </div>
                </AgGridProvider>
            </>
        );
    }
}
