import Box from '@material-ui/core/Box';
import {NySymbolInput} from 'nyse-web-tools-common/lib/react';
import AgGrid from 'nyse-web-tools-common/lib/react/grid/AgGrid/AgGrid';
import AgGridColumnMenuTrigger from
        'nyse-web-tools-common/lib/react/grid/AgGridColumnMenuTrigger/AgGridColumnMenuTrigger';
import AgGridPagination from 'nyse-web-tools-common/lib/react/grid/AgGridPagination/AgGridPagination';
import AgGridProvider from 'nyse-web-tools-common/lib/react/grid/AgGridProvider/AgGridProvider';
import AgGridToggleShowFilters from
        'nyse-web-tools-common/lib/react/grid/AgGridToggleShowFilters/AgGridToggleShowFilters';
import React, {useCallback, useMemo} from 'react';

import {symbolAdvListColumnDefs} from '../configs/columnDefs/symbolAdvList.columnDefs';
import useResource from '../hooks/useResource.hook';
import useStore from '../hooks/useStore.hook';
import symbolAdvListResource from '../resources/viewSymbolAdvList.resource'
import {cleanObject} from '../utils/utilities';

export const SymbolAdvListPage = React.memo(() => {
    const permissions = useStore('permissions');
    const resource = useResource(symbolAdvListResource);

    const init = useMemo(
        () => ({
            pagination: {
                limit: 100,
                offset: 0
            },
            values: {
            }
        }),
        []
    );

    const onLoad = useCallback(
        ({...params}) =>
            resource.get(
                cleanObject({
                    ...params
                })
            ),
        [resource.get]
    );

    return (
        <AgGridProvider
            columnDefs={symbolAdvListColumnDefs}
            columnNamespace='symbolAdvList'
            error={resource.error}
            filtersClassName='row space-bottom-1'
            initialPagination={init.pagination}
            initialFilters={init.values}
            isLoading={resource.pending}
            onLoad={onLoad}
            permissions={permissions}
            primaryKey='uuid'
            more={resource.data.more}
            rowData={resource.data.rowData}
            // @ts-ignore
            count={resource.data.count}>
            <AgGridProvider.Filters>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-1'>
                    <label>Symbol</label>
                    <NySymbolInput name='symbol' />
                </div>
            </AgGridProvider.Filters>
            <div className='row space-bottom-1'>
                <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                    <Box display='flex' alignItems='flex-end'>
                        <AgGridProvider.SubmitBtn />
                        <AgGridProvider.ResetBtn />
                        <Box display='flex' ml='auto'>
                            <AgGridToggleShowFilters />
                            <AgGridColumnMenuTrigger />
                        </Box>
                    </Box>
                </div>
            </div>

            <AgGrid
                autoSizeColumns
                reactNext />

            <AgGridPagination>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1,000</option>
                <option value={5000}>5,000</option>
                <option value={10000}>10,000</option>
            </AgGridPagination>
        </AgGridProvider>
    );
});
