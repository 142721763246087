import isEqual from 'react-fast-compare';
import {useQuery} from 'react-query';
import type {QueryFunctionContext, UseQueryResult} from 'react-query';
import {useSelector} from 'react-redux';

import {sentinelMw} from '../api';
import {useEntitlement} from './useEntitlement';
import usePlatform from './usePlatform';

export default function useUserFirms(): UseQueryResult<string, any> {
    const entitlement = useEntitlement();
    const firms = useSelector((ctx: any) => ctx.firms, isEqual);
    const platforms = usePlatform();

    return useQuery(
        ['firmAttributes', entitlement, platforms],
        async (context: QueryFunctionContext): Promise<any> => {
            if (entitlement) {
                const {data} = await sentinelMw.get(
                    context.queryKey[0] as string
                );

                return data?.data ?? [];
            }

            return firms;
        },
        {staleTime: 60 * 1000 * 30}
    );
}
