import {isEmpty} from 'lodash-es';
import {useDeepCompareEffect} from 'react-use';

import giveUpsResource from '../resources/giveUps.resource';
import {cleanObject} from '../utils/utilities';
import useResource from './useResource.hook';

export function useGiveUps(params) {
    const resource = useResource(giveUpsResource);

    useDeepCompareEffect(() => {
        if (!isEmpty(cleanObject(params))) {
            resource.get(params);
        }
    }, [params]);

    return [resource.data, resource.pending];
}
