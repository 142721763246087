import { cdmMw } from '../api';
import entitlementRoleMap, { CrdRole, Entitlement, OptionsMarketMics } from '../configs/entitlementRoleMap';
import {cleanObject} from '../utils/utilities';

type riskLimits = {
    action: 'notify' | 'block' | 'cancel' | string;
    limit: number;
    riskType: string;
    window: number;
    ioc: 'include' | 'exclude' | string;
};

export type ClearingFirmSettings = {
    limitCrd: number;
    consent: string;
    limitCrdRole: string;
    read: boolean;
    write: boolean;
    readWoReinstate: boolean;
};

type riskSettings = {
    email: string;
    limitCrd: number;
    limitCrdRole: CrdRole;
    limits: riskLimits[];
    read: boolean;
    readWoReinstate: boolean;
    write: boolean;
};

export type RiskSettingsData = {
    displayRiskEntity: string;
    entityId: number;
    entityType: string;
    riskEntity: string;
    settings: riskSettings[];
};

export const optionsSettingsResource = {
    api: cdmMw,
    url: 'api/v2/risk-entities/settings',
    parseResponse: (param) =>
        param.data.map((item) => ({
            ...item,
            limits: [
                {
                    data: {}
                }
            ],
            myLimits: param.meta.myLimits
        })),
    resetDataOnRequest: false,
    defaultValue: []
};

export const optionsSettingsDetailsResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/settings/${params.entityId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
};

export const optionsSettingsUpdateResource = {
    api: cdmMw,
    url: (params) => `api/v2/risk-entities/settings/${params.entityId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
};

export const clearingFirmDetailsResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/settings/${params.entityId}`,
    parseResponse: ({data}) => {
        const clearing = data?.settings.find(({limitCrdRole}) => limitCrdRole === 'clearing');
        return {
            ...clearing,
            consent:
                clearing.read && clearing.write
                    ? 'readwrite'
                    : clearing.read
                    ? 'read'
                    : clearing.write
                        ? 'write'
                        : 'none'
        };
    },
    resetDataOnRequest: false,
    defaultValue: {
        limitCrd: 0,
        limitCrdRole: 'clearing',
        consent: 'none',
        read: false,
        readWoReinstate: false,
        write: false,
        email: '',
        limits: []
    }
};

export const consentResource = {
    api: cdmMw,
    url: (param) => `api/v2/risk-entities/settings/${param.entityId}/consent`,
    useCache: true,
    parseResponse: ({data}) => data,
    defaultValue: []
};


export const disableRiskSettingsResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/settings/${params.entityId}/reset`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
}

export const orderRestrictionResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/order-restrictions/${params.entityId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
}

export const orderPercentageAdvNoneSymbolResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/order-adv/non-symbol/${params.entityId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
}

export const orderPercentageAdvSymbolResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/order-adv/symbol/${params.entityId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
}

export const priceProtectionsResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/price-protections/${params.entityId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
}

export const deleteRiskEntityResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/${params.entityId}`,
    filter: (params) => ({
        entityId: params?.entityId,
        limitCrd: params?.userCrd,
        limitCrdRole: params?.userCrdRole
    }),
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
}

export const addRiskEntitySymbolResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/${params.entityId}`,
    filter: (params) => ({
        entityId: params?.entityId,
        limitCrd: params?.userCrd,
        limitCrdRole: params?.userCrdRole,
        symbol: params?.symbol
    }),
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
}

export const fetchRangesResource = {
    api: cdmMw,
    url: (params) => `api/risk-ranges/${params.rangeType}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
}

export const mapUserCrdRole = (entitlement: Entitlement, mic: string) =>
    OptionsMarketMics.includes(mic) && entitlement === 'BROKER_FIRM'
        ? 'entering'
        : entitlementRoleMap[entitlement];

const actionValue = {
    notify: 1,
    block: 2,
    cancel: 3
};
const actionFilter = (currentAction, newAction) =>
    (actionValue[currentAction] ?? 0) > (actionValue[newAction] ?? 0)
        ? currentAction
        : newAction;

export const formatCreditLimit = (limit) => {
    const firstActivityRisk = limit.find(({riskType}) =>
        ['rolling_vol', 'rolling_trans', 'rolling_pct'].includes(riskType)
    );
    return limit.reduce(
        (acc, {riskType, limit, action, ...rest}) =>
            riskType === firstActivityRisk?.riskType ?? ''
                ? {
                    ...acc,
                    activity: {
                        riskType,
                        limit,
                        action: actionFilter(
                            acc?.activity?.action ?? '',
                            action
                        ),
                        ...rest
                    }
                }
                : riskType === 'trans_vol_pct'
                ? {
                    ...acc,
                    trans_vol_pct: {
                        limit,
                        ...rest
                    }
                }
                : riskType === 'ord_val' || riskType === 'ord_qty'
                    ? {
                        ...acc,
                        [riskType]: limit
                    }
                    : riskType === 'cred_ord_exec' || riskType === 'cred_exec' || riskType === 'cred_ord'
                        ? {
                            ...acc,
                            [riskType]: {
                                limit,
                                action
                            }
                        } : acc,
        {}
    );
};
