import {events} from '../events';

export const userReducer = {
    [events.USER_SET]: (state, {payload}) => ({
        ...state,
        ...payload
    }),
    [events.USER_LOGOUT_POST]: (state) => ({
        ...state,
        ...{
            isLogoutPending: true
        }
    }),
    [[events.USER_LOGOUT_SUCCESS, events.USER_LOGOUT_ERROR]]: (state) => ({
        ...state,
        ...{
            isLogoutPending: false
        }
    }),
    [events.USER_SUCCESS]: (state, {payload: {data}}) => ({
        ...state,
        ...(!state.isLogoutPending ? {...data, isJwtValid: true} : {})
    }),
    [events.USER_REFRESH_TOKEN_GET]: (state) => ({
        ...state,
        isAuthPending: true
    }),
    [events.USER_REFRESH_TOKEN_SUCCESS]: (state, {payload: {data}}) => ({
        ...state,
        ...(!state.isLogoutPending && state.isJwtValid
            ? {
                  isAuthPending: false,
                  isJwtThreshold: false,
                  isJwtValid: true,
                  ...data
              }
            : {})
    })
};
