import React from 'react';

export const expiredAccessTokenError = {
    title: 'Session Expired',
    detail: (
        <span>
            You will now be <strong>logged out</strong>
        </span>
    ),
    type: 'warning'
};
