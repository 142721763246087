import joda from 'nyse-web-tools-common/lib/utils/joda';

import {ActivityReportPage} from '../pages/activityReport.page';
import {AuctionsPage} from '../pages/auctions.page';
import {BroadcastMessagePage} from '../pages/broadcastMessage.page';
import {DocumentLinksPage} from '../pages/documentLinks.page';
import {EodProcessingPage} from '../pages/eodProcessing.page';
import {GboRefDataPage} from '../pages/gboRefData.page';
import {HomePage} from '../pages/home.page';
import {IdbOriginatorsPage} from '../pages/idbOriginators.page';
import {LandingPage} from '../pages/landing.page';
import {LiquidityMetricsPage} from '../pages/liquidityMetrics.page';
import {LiveOrdersPage} from '../pages/liveOrders.page';
import {LoginPage} from '../pages/login.page';
import {LogoutPage} from '../pages/logout.page';
import {MarketAccessExceptionsPage} from '../pages/marketAccessExceptions.page';
import {MyFirmMPIDs} from '../pages/myFirmMpids.page';
import {MySessionsPage} from '../pages/mySessions.page';
import {MyVolumesBrokerRankPage} from '../pages/myVolumesBrokerRank.page';
import {MyVolumesCadvPage} from '../pages/myVolumesCadv.page';
import {MyVolumesSearchPage} from '../pages/myVolumesSearch.page';
import {MyVolumesTradeCountPage} from '../pages/myVolumesTradeCount.page';
import {OpraEntriesPage} from '../pages/opraEntries.page';
import {OverviewPage} from '../pages/overview.page';
import {QuoteMetricsPage} from '../pages/quoteMetrics.page';
import {RdlSettingsPage} from '../pages/rdlSettings.page';
import {RiskMonitorPage} from '../pages/riskMonitor.page';
import {RiskSettingsPage} from '../pages/riskSettings.page';
import {SubAccountsPage} from '../pages/subAccounts.page';
import {SymbolAdvListPage} from '../pages/symbolAdvList.page';
import {TermsAndConditionsPage} from '../pages/termsAndConditions.page';
import {TradesPage} from '../pages/trades.page';
import {TradeSummaryPage} from '../pages/tradeSummary.page';
import {UnauthorizedPage} from '../pages/unauthorized.page';
import {Permissions} from '../resources/tradeSummary.resource';
import {glossaryConfig} from './glossary.config';

export const routesConfig = [
    {
        url: '/',
        exact: true,
        component: LandingPage,
        title: 'Trade Ops Portal',
        showBanner: true
    },
    {
        url: '/login',
        exact: true,
        component: LoginPage
    },
    {
        url: '/logout',
        exact: true,
        component: LogoutPage
    },
    {
        url: '/unauthorized',
        exact: true,
        component: UnauthorizedPage,
        title: 'Unauthorized'
    },
    {
        url: '/:platform/terms+and+conditions',
        component: TermsAndConditionsPage
    },
    {
        url: '/:platform/overview',
        permissions: [
            'list of auctions',
            'view core trading session',
            'view summary trade stats'
        ],
        component: OverviewPage,
        entitlement: {
            ARCO: [
                'MEMBER_FIRM',
                'BROKER_FIRM',
                'CLEARING_FIRM',
                'TRADE_CHECKER',
                'MM_FIRM'
            ],
            AMXO: [
                'MEMBER_FIRM',
                'BROKER_FIRM',
                'CLEARING_FIRM',
                'TRADE_CHECKER',
                'MM_FIRM'
            ]
        },
        hasPillarSource: true,
        group: 'Dashboards',
        title: 'Overview'
    },
    {
        url: '/:platform/documents',
        permissions: ['view document list'],
        component: DocumentLinksPage,
        group: 'Resources',
        title: 'Documents & Links'
    },
    {
        url: '/:platform/idb-originators',
        permissions: ['view idb-originators'],
        component: IdbOriginatorsPage,
        group: 'Firm Information',
        title: 'IDB Originators',
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined
        }),
        glossary: glossaryConfig.idbOriginators
    },
    {
        url: '/:platform/my-firm-mpids',
        permissions: ['view user firm mpids'],
        component: MyFirmMPIDs,
        hasPillarSource: true,
        group: 'Firm Information',
        title: 'My Firm MPIDs',
        glossary: glossaryConfig.myFirmMpids
    },
    {
        url: '/:platform/my-sessions',
        permissions: ['view user sessions'],
        component: MySessionsPage,
        hasPillarSource: true,
        group: 'Firm Information',
        title: 'My Sessions',
        glossary: glossaryConfig.mySessions
    },
    {
        url: '/:platform/activity-report',
        permissions: ['view account activities'],
        component: ActivityReportPage,
        group: 'Reports',
        title: 'Activity Report',
        glossary: glossaryConfig.activityReport
    },
    {
        url: '/:platform/eod/block-time',
        permissions: ['view trade action block time'],
        component: EodProcessingPage,
        group: 'Settings',
        title: 'EOD - Trade Action Block'
    },
    {
        url: '/:platform/eod/fr-jobs',
        permissions: ['view eod processing jobs'],
        component: EodProcessingPage,
        group: 'Settings',
        title: 'EOD - F/R Jobs'
    },
    {
        url: '/:platform/gbo-ref-data',
        permissions: ['view gbo ref data'],
        component: GboRefDataPage,
        group: 'Settings',
        title: 'GBO Reference Data'
    },
    {
        url: '/:platform/liquidity-metrics/:view',
        permissions: ['view liquidity metrics'],
        component: LiquidityMetricsPage,
        group: 'Volumes',
        title: 'Liquidity Metrics',
        defaultParams: {
            view: 'firm'
        },
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined
        }),
        glossary: glossaryConfig.liquidityMetrics
    },
    {
        url: '/:platform/sub-accounts',
        permissions: ['view sub accounts'],
        component: SubAccountsPage,
        group: 'Firm Information',
        title: 'Sub Accounts',
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined
        }),
        glossary: glossaryConfig.subAccounts
    },
    {
        url: '/:platform/trades/all',
        permissions: ['view trades'],
        component: TradesPage,
        group: 'Firm Information',
        title: 'Trades',
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined,
            date: params.date
                ? {
                    end: joda.marshall(params.date),
                    start: joda.marshall(params.date)
                }
                : undefined
        }),
        glossary: glossaryConfig.trades
    },
    {
        url: '/:platform/trades/qct',
        permissions: ['view trades', 'view opra entry'],
        component: TradesPage,
        group: 'Firm Information',
        strict: true,
        title: 'QCT Trades',
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined,
            matcherTimes: params.matcherTimes
                ? {
                    end: joda.marshall(params.matcherTimes.end),
                    start: joda.marshall(params.matcherTimes.start)
                }
                : undefined
        }),
        glossary: glossaryConfig.qctTrades
    },
    {
        url: '/:platform/trade-summary/:view',
        permissions: [
            Permissions.viewAllocatedTradeSummary,
            Permissions.viewFloorTradeSummary,
            Permissions.viewTransactions,
            Permissions.viewTransactionHistory
        ],
        component: TradeSummaryPage,
        entitlement: {
            ARCO: [
                'MEMBER_FIRM',
                'BROKER_FIRM',
                'CLEARING_FIRM',
                'TRADE_CHECKER',
                'MM_FIRM'
            ],
            AMXO: [
                'MEMBER_FIRM',
                'BROKER_FIRM',
                'CLEARING_FIRM',
                'TRADE_CHECKER',
                'MM_FIRM'
            ]
        },
        defaultParams: {
            view: 'allocated'
        },
        group: 'Firm Information',
        title: 'Trade Summary',
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined,
            date: params.date
                ? {
                    end: joda.marshall(params.date),
                    start: joda.marshall(params.date)
                }
                : undefined
        }),
        glossary: glossaryConfig.tradeSummary
    },
    {
        url: '/:platform/volumes/search',
        permissions: ['view volumes - adhoc'],
        component: MyVolumesSearchPage,
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined
        }),
        group: 'Volumes',
        title: 'Search',
        glossary: glossaryConfig.myVolumes
    },
    {
        url: '/:platform/volumes/broker-rank',
        permissions: ['view volumes - broker rank'],
        component: MyVolumesBrokerRankPage,
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined
        }),
        group: 'Volumes',
        title: 'Broker Rank',
        glossary: glossaryConfig.myVolumes
    },
    {
        url: '/:platform/volumes/cadv',
        permissions: ['view volumes - cadv'],
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined
        }),
        component: MyVolumesCadvPage,
        group: 'Volumes',
        title: 'CADV',
        glossary: glossaryConfig.myVolumes
    },
    {
        url: '/:platform/volumes/trade-count',
        permissions: ['view volumes - trade count'],
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined
        }),
        component: MyVolumesTradeCountPage,
        group: 'Volumes',
        title: 'Trade Count',
        glossary: glossaryConfig.myVolumes
    },
    {
        url: '/:platform/risk-settings',
        permissions: ['view risk settings'],
        component: RiskSettingsPage,
        entitlement: {
            ARCO: ['MEMBER_FIRM']
        },
        group: 'RISK MANAGEMENT',
        title: 'Risk Settings'
    },
    {
        url: '/:platform/risk-monitor',
        permissions: ['view risk monitor'],
        component: RiskMonitorPage,
        entitlement: {
            ARCO: ['MEMBER_FIRM']
        },
        group: 'RISK MANAGEMENT',
        title: 'Risk Monitor'
    },
    {
        url: '/:platform/symbol-adv-list',
        permissions: ['view symbol adv list'],
        component: SymbolAdvListPage,
        platforms: ['ARCX', 'XASE', 'XCHI', 'XCIS', 'XNYS'],
        entitlement: {
            ARCO: ['MEMBER_FIRM']
        },
        group: 'RISK MANAGEMENT',
        title: 'Symbol ADV List'
    },
    {
        url: '/:platform/auctions',
        permissions: ['list of auctions'],
        component: AuctionsPage,
        hasPillarSource: true,
        group: 'Market Data',
        title: 'Auctions',
        glossary: glossaryConfig.auctions
    },
    {
        url: '/:platform/quote-metrics',
        permissions: ['view quote metrics'],
        component: QuoteMetricsPage,
        group: 'Market Data',
        title: 'Quote Metrics',
        glossary: glossaryConfig.quoteMetrics
    },
    {
        url: '/:platform/opra-entries',
        permissions: ['view opra entry'],
        component: OpraEntriesPage,
        group: 'Firm Information',
        title: 'OPRA Entries',
        glossary: glossaryConfig.opraEntries,
        parseParams: (params) => ({
            ...params,
            crd: params.crd ? Number(params.crd) : undefined,
            dealId: params.dealId || undefined,
            tradeExecTime: params.tradeExecTime
        })
    },
    {
        url: '/:platform/market-access-exceptions',
        permissions: ['view market access exceptions'],
        component: MarketAccessExceptionsPage,
        group: 'Reports',
        title: 'Market Access Exceptions',
        glossary: glossaryConfig.marketAccessExceptions
    },
    {
        url: '/:platform/rdl-settings',
        permissions: ['view intraday modes', 'update intraday modes'],
        component: RdlSettingsPage,
        group: 'Settings',
        strict: true,
        title: 'RDL Settings'
    },
    {
        url: '/:platform/live-orders',
        permissions: ['view live orders'],
        component: LiveOrdersPage,
        entitlement: {
            ARCO: ['MEMBER_FIRM', 'BROKER_FIRM', 'MM_FIRM'],
            AMXO: ['MEMBER_FIRM', 'BROKER_FIRM', 'MM_FIRM']
        },
        group: 'Firm Information',
        title: 'Live Orders',
        glossary: glossaryConfig.liveOrders
    },
    {
        url: '/system/broadcast-message',
        roles: ['SUPER_USER'],
        component: BroadcastMessagePage,
        title: 'Broadcast Message'
    },
    {
        url: '/home',
        exact: true,
        component: HomePage,
        title: 'Home'
    }
];
