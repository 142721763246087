import Box from '@material-ui/core/Box';
import {useFormikContext} from 'formik';
import {
    NySelectInput,
    NyTextInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';

import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import React, {useCallback, useMemo, useState} from 'react';

import {
    ContraSideSectionObject,
    CustFirm,
    SideTradeType,
    TradeSummaryTabs,
    custFirmChoices,
    myOwnership,
    stripSpecialChars, getBuySellSideDisplayString, stripCharsForAccountOptionalData
} from '../../../../resources/tradeSummary.resource';
import {CapStrategyIdSelect} from '../../../selects/capStrategyId.select';
import {ClearingNumberSelect} from '../../../selects/clearingNumber.select';

export const ContraSideSection: React.FC<ContraSideSectionObject> = React.memo(
    (props) => {
        const formik = useFormikContext<ContraSideSectionObject>();

        const isLocalMM =
            formik.values.contraOrdCustFirm === CustFirm.MarketMaker;
        const isAwayMM =
            formik.values.contraOrdCustFirm === CustFirm.AwayMarketMaker;
        const isLocalOrAwayMM = isLocalMM || isAwayMM;
        const isFloorBroker =
            props.contraOrdTradeType === SideTradeType.FLOOR_BROKER;
        const isFloorBrokerOnBehalfOfLocalMM =
            props.contraOrdTradeType === SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isFloorBrokerOnBehalfOfAwayMM =
            props.contraOrdTradeType === SideTradeType.FLOOR_BROKER_AWAY_MM;

        const isBothSide = props.selectedSide === 'BOTH';

        const isAllocateTab = props.currentTab === TradeSummaryTabs.allocate;

        const capStrategyShouldDisplay = useMemo(
            () =>
                props.outcryTrade ||
                ((isFloorBroker ||
                    isFloorBrokerOnBehalfOfAwayMM ||
                    isFloorBrokerOnBehalfOfLocalMM) &&
                    props.qccTrade)
                    ? true
                    : false,
            [
                props.qccTrade,
                props.outcryTrade,
                isFloorBroker,
                isFloorBrokerOnBehalfOfAwayMM,
                isFloorBrokerOnBehalfOfLocalMM
            ]
        );

        const contraSideDisplayString = useMemo(
            () => getBuySellSideDisplayString(props.contraOrdSide)
            , [props.contraOrdSide]);

        useMountedEffect(() => {
            formik.setFieldValue('contraOrdSndrSubId', '');
        }, [formik.values.contraOrdCustFirm]);

        const [firmId, setFirmId] = useState(props.contraOrdFirmId);

        const onSetFirmIdChange = useCallback(({value}) => {
            setFirmId(value);
        }, []);

        const onContraOrdSndrSubIdChange = useCallback(
            ({value, setFieldValue}) => {
                setFieldValue('contraOrdSndrSubId', stripSpecialChars(value));
            },
            []
        );
        const onContraOrdSubmittingBkrIdChange = useCallback(
            ({value, setFieldValue}) => {
                setFieldValue(
                    'contraOrdSubmittingBkrId',
                    stripSpecialChars(value)
                );
            },
            []
        );
        const onContraOrdCmtaChange = useCallback(({value, setFieldValue}) => {
            setFieldValue('contraOrdCmta', stripSpecialChars(value));
        }, []);
        const onContraOrdAccountChange = useCallback(
            ({value, setFieldValue}) => {
                setFieldValue('contraOrdAccount', stripCharsForAccountOptionalData(value));
            },
            []
        );
        const onContraOrdOptnlDataChange = useCallback(
            ({value, setFieldValue}) => {
                    setFieldValue('contraOrdOptnlData', stripCharsForAccountOptionalData(value));
            },
            []
        );
        if (
            (isBothSide && props.currentTab !== TradeSummaryTabs.allocate) ||
            (props.myOwnership === myOwnership.BOTH &&
                props.currentTab === TradeSummaryTabs.allocate)
        ) {
            return (
                <div className='row'>
                    <Box
                        className='col-xs-12 col-sm-12 col-md-12 col-lg-12'
                        fontWeight='fontWeightMedium'>
                        Contra Side ({contraSideDisplayString})
                    </Box>
                    <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                        <label>Customer or Firm</label>
                        <NySelectInput
                            name='contraOrdCustFirm'
                            disabled={props.disabled}
                            options={custFirmChoices}
                            required
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                        <label>MPID</label>
                        {isAllocateTab &&
                        formik.values.contraOrdCustFirm !==
                            CustFirm.MarketMaker ? (
                            <NySelectInput
                                name='contraOrdFirmId'
                                disabled={props.disabled}
                                options={props.contraOrdFirmIdOptions}
                                onChange={onSetFirmIdChange}
                            />
                        ) : (
                            <NyTextInput
                                name='contraOrdFirmId'
                                disabled={props.disabled}
                                onChange={onSetFirmIdChange}
                                readOnly
                            />
                        )}
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                        <label>MMID</label>
                        {!props.disabled && isLocalMM ? (
                            <NySelectInput
                                name='contraOrdSndrSubId'
                                options={props.contraOrdSndrSubIdOptions}
                                disabled={props.disabled}
                                required
                            />
                        ) : (
                            <NyUppercaseInput
                                name='contraOrdSndrSubId'
                                disabled={props.disabled}
                                readOnly={!isAwayMM}
                                required={isAwayMM}
                                onChange={onContraOrdSndrSubIdChange}
                            />
                        )}
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                        <label>Clearing #</label>
                        <ClearingNumberSelect
                            name='contraOrdClearingNumber'
                            sideCrd={
                                isFloorBroker ||
                                isFloorBrokerOnBehalfOfLocalMM ||
                                isFloorBrokerOnBehalfOfAwayMM
                                    ? props.contraOrdOmsCrd
                                    : props.contraOrdCrd
                            }
                            firmId={
                                isFloorBrokerOnBehalfOfLocalMM && !isLocalMM
                                    ? props.contraOrdOmsId
                                    : firmId
                            }
                            disabled={props.disabled}
                            clearingNumber={props.contraOrdClearingNumber}
                            custFirm={formik.values.contraOrdCustFirm}
                            isLocalMM={isLocalMM}
                            isFloorBrokerOnBehalfOfLocalMM={
                                isFloorBrokerOnBehalfOfLocalMM
                            }
                            required={!isLocalMM}
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                        <label>Open/Close</label>
                        <NySelectInput
                            name='contraOrdOpenClose'
                            disabled={props.disabled}
                            required={
                                !isLocalOrAwayMM &&
                                !isFloorBrokerOnBehalfOfLocalMM &&
                                !isFloorBrokerOnBehalfOfAwayMM
                            }>
                            {(isLocalOrAwayMM ||
                                isFloorBrokerOnBehalfOfLocalMM ||
                                isFloorBrokerOnBehalfOfAwayMM) && (
                                <option value=''>-</option>
                            )}
                            <option value='O'>Open</option>
                            <option value='C'>Close</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                        <label>Badge</label>
                        {isFloorBroker ||
                        isFloorBrokerOnBehalfOfLocalMM ||
                        isFloorBrokerOnBehalfOfAwayMM ? (
                            <NySelectInput
                                name='contraOrdSubmittingBkrId'
                                disabled={props.disabled}
                                options={props.contraOrdSubmittingBkrIdOptions}
                            />
                        ) : (
                            <NyTextInput
                                name='contraOrdSubmittingBkrId'
                                disabled={props.disabled}
                                readOnly={
                                    !isFloorBrokerOnBehalfOfAwayMM &&
                                    !isFloorBrokerOnBehalfOfLocalMM &&
                                    !isFloorBroker
                                }
                                onChange={onContraOrdSubmittingBkrIdChange}
                            />
                        )}
                    </div>
                    {capStrategyShouldDisplay ? (
                        <>
                            <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                                <label>CMTA</label>
                                <NyTextInput
                                    name='contraOrdCmta'
                                    maxLength={5}
                                    disabled={props.disabled}
                                    onChange={onContraOrdCmtaChange}
                                />
                            </div>
                            <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                                <label>Account</label>
                                <NyTextInput
                                    name='contraOrdAccount'
                                    disabled={props.disabled}
                                    maxLength={20}
                                    onChange={onContraOrdAccountChange}
                                />
                            </div>
                            <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                                <label>Optional Data</label>
                                <NyTextInput
                                    name='contraOrdOptnlData'
                                    disabled={props.disabled}
                                    maxLength={16}
                                    onChange={onContraOrdOptnlDataChange}
                                />
                            </div>
                            <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                                <label>Cap Strategy Id</label>
                                <CapStrategyIdSelect
                                    name='contraOrdCapStrategyId'
                                    disabled={props.disabled}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                                <label>CMTA</label>
                                <NyTextInput
                                    name='contraOrdCmta'
                                    maxLength={5}
                                    disabled={props.disabled}
                                    onChange={onContraOrdCmtaChange}
                                />
                            </div>
                            <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                                <label>Account</label>
                                <NyTextInput
                                    name='contraOrdAccount'
                                    disabled={props.disabled}
                                    maxLength={20}
                                    onChange={onContraOrdAccountChange}
                                />
                            </div>
                            <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                                <label>Optional Data</label>
                                <NyTextInput
                                    name='contraOrdOptnlData'
                                    disabled={props.disabled}
                                    maxLength={16}
                                    onChange={onContraOrdOptnlDataChange}
                                />
                            </div>
                        </>
                    )}
                </div>
            );
        }
        return (
            <div className='row'>
                <Box
                    className='col-xs-12 col-sm-12 col-md-12 col-lg-12'
                    fontWeight='fontWeightMedium'>
                    Contra Side ({contraSideDisplayString})
                </Box>
                {isAllocateTab ? (
                    <>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>MPID</label>
                            <NyTextInput
                                name='contraOrdFirmId'
                                disabled={props.disabled}
                                readOnly
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>MMID</label>
                            {!props.disabled ? (
                                <NySelectInput
                                    name='contraOrdSndrSubId'
                                    disabled={props.disabled}
                                    options={props.contraOrdSndrSubIdOptions}
                                />
                            ) : (
                                <NyUppercaseInput
                                    name='contraOrdSndrSubId'
                                    readOnly
                                    disabled={props.disabled}
                                    onChange={onContraOrdSndrSubIdChange}
                                />
                            )}
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>Badge</label>
                            {!props.disabled ? (
                                <NySelectInput
                                    name='contraOrdSubmittingBkrId'
                                    disabled={props.disabled}
                                    options={
                                        props.contraOrdSubmittingBkrIdOptions
                                    }
                                />
                            ) : (
                                <NyTextInput
                                    name='contraOrdSubmittingBkrId'
                                    disabled={props.disabled}
                                    readOnly={
                                        !isFloorBrokerOnBehalfOfAwayMM &&
                                        !isFloorBrokerOnBehalfOfLocalMM &&
                                        !isFloorBroker
                                    }
                                    onChange={onContraOrdSubmittingBkrIdChange}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>MPID</label>
                            <NyTextInput
                                name='contraOrdFirmId'
                                disabled={props.disabled}
                                readOnly
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>MMID</label>
                            <NyUppercaseInput
                                name='contraOrdSndrSubId'
                                readOnly
                                disabled={props.disabled}
                                onChange={onContraOrdSndrSubIdChange}
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>Badge</label>
                            <NyTextInput
                                name='contraOrdSubmittingBkrId'
                                disabled={props.disabled}
                                readOnly={
                                    !isFloorBrokerOnBehalfOfAwayMM &&
                                    !isFloorBrokerOnBehalfOfLocalMM &&
                                    !isFloorBroker
                                }
                                onChange={onContraOrdSubmittingBkrIdChange}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
);
