export const symbolStatesColumnDefs = [
    {
        headerName: 'Tape A',
        headerClass: ['text-center'],
        children: [
            {
                field: 'tapeA',
                headerName: '(n)',
                type: 'fraction',
                minWidth: 100
            },
            {
                field: 'tapeAPercent',
                headerName: '(%)',
                type: 'percentage',
                mantissa: 2,
                minWidth: 85
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Tape B',
        headerClass: ['text-center'],
        children: [
            {
                field: 'tapeB',
                headerName: '(n)',
                type: 'fraction',
                minWidth: 100
            },
            {
                field: 'tapeBPercent',
                headerName: '(%)',
                type: 'percentage',
                mantissa: 2,
                minWidth: 85
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Tape C',
        headerClass: ['text-center'],
        children: [
            {
                field: 'tapeC',
                headerName: '(n)',
                type: 'fraction',
                minWidth: 100
            },
            {
                field: 'tapeCPercent',
                headerName: '(%)',
                type: 'percentage',
                mantissa: 2,
                minWidth: 85
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Total',
        headerClass: ['text-center'],
        children: [
            {
                field: 'total',
                headerName: '(n)',
                type: 'fraction',
                pinned: 'right',
                minWidth: 125
            },
            {
                field: 'totalPercent',
                headerName: '(%)',
                type: 'percentage',
                mantissa: 2,
                pinned: 'right',
                minWidth: 85
            }
        ],
        marryChildren: true
    }
];
