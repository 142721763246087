import {useFormikContext} from 'formik';
import {flatten, uniqBy} from 'lodash-es';
import {NySelectInput, NyTextInput} from 'nyse-web-tools-common/lib/react';
import React, {useCallback, useEffect, useMemo} from 'react';

import {useEntitlement} from '../../hooks/useEntitlement';
import useResource from '../../hooks/useResource.hook';
import useUser from '../../hooks/useUser.hook';
import useUserFirms from '../../hooks/useUserFirms';
import {clearingNumberResource} from '../../resources/clearingNumber.resource';
import {
    Entitlement,
    keepAsIs,
    stripSpecialChars
} from '../../resources/tradeSummary.resource';
import {combineOptionsWithDbValue} from './selectUtils';

type ClearingNumberSelectProps = {
    disabled?: boolean;
    name: string;
    isMulti?: boolean;
    required?: boolean;
    onChange?: any;
    resetFields?: string[];
    sideCrd?: number;
    firmId?: string;
    custFirm?: string;
    clearingNumber: string;
    readOnly?: boolean;
    initialValue?: any;
    isLocalMM?: boolean;
    isFloorBrokerOnBehalfOfLocalMM?: boolean;
    isMultiAdjustment?: boolean;
};

export const ClearingNumberSelect = React.memo<ClearingNumberSelectProps>(
    (props) => {
        const userFirms = useUserFirms();
        const user = useUser();
        const entitlement = useEntitlement() || user.entitlement;

        const firms = useMemo(
            () => (userFirms.data ?? []).filter(({active}) => active),
            [userFirms.data]
        );
        const participants = useMemo(
            () =>
                firms.find(({crd}) => crd === props.sideCrd)?.participants ??
                [],
            [firms, props.sideCrd]
        );
        const clearingParticipants = flatten(
            firms.map(({participants}) => participants)
        );

        const formik = useFormikContext();

        const resource = useResource(clearingNumberResource);

        useEffect(() => {
            if (props.sideCrd && props.firmId) {
                resource.get({
                    crd: props.sideCrd,
                    mpId: props.firmId
                });
            }
        }, [props.sideCrd, props.firmId]);

        const clearingNumbersFromGBORefData = useMemo(
            () =>
                !resource.pending && resource.data
                    ? resource.data.map(({clearingNumber}) => ({
                          label: clearingNumber,
                          value: clearingNumber
                      }))
                    : [],
            [resource.data]
        );

        const clearingNumbersFromParticipants =
            entitlement === Entitlement.CLEARING_FIRM
                ? uniqBy(clearingParticipants, 'clearingNumber').map(
                      (participant) => ({
                          label: participant.clearingNumber,
                          value: participant.clearingNumber
                      })
                  )
                : uniqBy(participants, 'clearingNumber').map((participant) => ({
                      label: participant.clearingNumber,
                      value: participant.clearingNumber
                  }));

        const entitledOptions =
            (entitlement === Entitlement.CLEARING_FIRM
                ? clearingNumbersFromGBORefData.filter((option) =>
                      clearingNumbersFromParticipants.some(
                          (clearingNumber) =>
                              option.value === clearingNumber.value
                      )
                  )
                : clearingNumbersFromGBORefData)
            .concat(props.isMultiAdjustment ? keepAsIs : []);

        const onClearingNumberChange = useCallback(({value, setFieldValue}) => {
            setFieldValue(props.name, stripSpecialChars(value));
        }, []);

        if (!props.disabled) {
            return (
                <NySelectInput
                    disabled={props.disabled}
                    isLoading={resource.pending}
                    isMulti={props.isMulti}
                    name={props.name}
                    onChange={props.onChange}
                    options={combineOptionsWithDbValue(
                        props,
                        entitledOptions,
                        'clearingNumber'
                    )}
                    resetFields={props.resetFields}
                    required={props.required}
                    readOnly={props.readOnly}
                />
            );
        }
        return (
            <NyTextInput
                name={props.name}
                readOnly={props.isLocalMM}
                disabled={props.disabled}
                onChange={onClearingNumberChange}
            />
        );
    }
);
