import {sentinelMw} from '../api';

export default {
    api: sentinelMw,
    url: 'admin/client/entitlements',
    useCache: false,
    parseResponse: ({data}) =>
        data.find(({clientIdChar}) => clientIdChar === 'CDM-01')
            ?.entitlements ?? [],
    defaultValue: []
};
