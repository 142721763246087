import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, { useCallback, useMemo, useState } from 'react';

import { capitalizeRole, ENTERING, Entitlement, FLOOR_BROKER } from '../../../../configs/entitlementRoleMap';
import useResource from '../../../../hooks/useResource.hook';
import {orderPercentageAdvNoneSymbolResource,
    orderPercentageAdvSymbolResource} from '../../../../resources/riskSettings.resource';
import{ useRiskRanges } from '../../../../hooks/useRiskRanges';
// @ts-ignore
import {cleanObject, getRangeLabel} from '../../../../utils/utilities';
import {omit} from 'lodash-es';
import isEqual from "react-fast-compare";
import {formatOrderAdvRangeLabels, formatOrderAdvCreditLimit} from './form.utilities'

type OrderPercentageAdvFormProps = {
    classes: {
        alert: string;
        controls: string;
        form: string;
        formBody: string;
        radioBtns: string;
    };
    context: {
        entitlement: Entitlement;
        onModalClosed(status: boolean): void;
        isOptionsMarket: boolean;
    };
    className: string;
    data: any;
    onSuccess(isSuccessful: boolean);
};
export const OrderPercentageAdvForm: React.FC<OrderPercentageAdvFormProps> = React.memo((props) => {

    const entityType = props.data.entityType;
    const isSymbolOrderAdv = (entityType === 'SYMBOL_MPID' || entityType === 'SYMBOL_MPID_SUBID');
    const [preValues, setPreValues] = useState([]);
    const resources = useResource(isSymbolOrderAdv
                            ? orderPercentageAdvSymbolResource
                            : orderPercentageAdvNoneSymbolResource);
    const [ranges] = useRiskRanges('ord_qty_adv');

    const labels = useMemo(()=> {
        return isSymbolOrderAdv? [] : formatOrderAdvRangeLabels(ranges);
    }, [isSymbolOrderAdv, ranges]);

    const initCreditLimits = useMemo(
        () => {
            let creditLimits = [];
            const limits = formatOrderAdvCreditLimit(props.data.limits, labels)
            if (isSymbolOrderAdv) {
                creditLimits = limits.slice(0,1);
            }
            else {
                limits[0].rangeId = 0;
                if (limits[0]?.limitLow !== undefined)
                    limits[0].limit = limits[0].limitLow;
                creditLimits = limits.map((e)=> { delete e.limitLow; return e;} );
            }
            setPreValues(creditLimits);
            return creditLimits;
        },
        [props.data.limits, isSymbolOrderAdv, labels]
    );

    const [values, setValues] = useState({
        email: props.data.email,
        entityId: props.data.entityId,
        limitCrdRole: props.data.limitCrdRole,
        limitCrd: props.data.limitCrd,
        creditLimits: initCreditLimits
    });

    const initValues = useMemo(() => {
        return {
            entityId: props.data.entityId,
            limitCrdRole:
                props.data.limitCrdRole === FLOOR_BROKER &&
                props.context.isOptionsMarket
                    ? ENTERING
                    : props.data.limitCrdRole,
            limitCrd: props.data.limitCrd,
            creditLimits: initCreditLimits
        };
    }, [props, initCreditLimits])


    const onSubmit = useCallback(
        (values) => {
            if (isSymbolOrderAdv) {
                if (!!values.creditLimits[0]?.limit || !!values.creditLimits[0]?.limitLow) {
                    const creditLimits = values.creditLimits.map((e)=>cleanObject(omit(e, ['rangeId'])));
                    return resources.put(
                        cleanObject({
                            ...values,
                            creditLimits
                        }),
                        values.entityId
                    );
                } else if (!!preValues[0]?.limit || !!preValues[0]?.limitLow) {
                    return resources.put(
                        {
                            ...cleanObject(omit(values,['creditLimits'])),
                            resetRiskType: true
                        },
                        values.entityId
                    );

                }
            }
            else {
                const creditLimits = values.creditLimits.filter((e)=>!!(e.limit)).map((e)=>cleanObject(e));
                const resetRangeIds = [];
                for (let idx = 0; idx != values.creditLimits.length; idx++) {
                    if (!values.creditLimits[idx]?.limit && !!preValues[idx]?.limit)
                        resetRangeIds.push(values.creditLimits[idx].rangeId);
                }
                return resources.put(
                    cleanObject({
                        ...values,
                        creditLimits,
                        resetRangeIds
                    }),
                    values.entityId
                );
            }
        },
        [resources, values, isSymbolOrderAdv, preValues]
    );

    const onChange = (values) => setValues(values);

    const onSuccess = useCallback((response, context, values) => {
        const creditLimits = [];
        for (let idx = 0; idx != values.creditLimits.length; idx++) {
            creditLimits[idx] = {
                rangeId: idx,
                limit:  values.creditLimits[idx].limit,
                limitLow : values.creditLimits[idx].limitLow
            };
        }
        if (isSymbolOrderAdv)
            creditLimits[0].limitLow = values.creditLimits[0].limitLow;
        setPreValues(creditLimits);

        if (props.onSuccess)
            props.onSuccess(response);

    }, [props,isSymbolOrderAdv, values]);

    const isChanged = useMemo(
        () => {
            return !isEqual(
                preValues.map(e => cleanObject(e)),
                values.creditLimits.map(e => cleanObject(e))
            );
        }, [values, preValues]);


    return (
        <NyForm
            data-e2e-tag='updateOrderPercentageAdvSettings'
            className={`${props.classes.form} ${props.className}`}
            initialValues={initValues}
            enableReinitialize={true}
            onChange={onChange}
            onSuccess={onSuccess}
            onSubmit={onSubmit}>
            <Grid className={props.classes.formBody} container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Box display='inline' fontWeight='fontWeightMedium'>
                        {capitalizeRole(props.data.limitCrdRole)} Firm:
                    </Box>
                    &nbsp;{props.data.limitCrd}
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                { isSymbolOrderAdv
                    ? (
                        <>
                        <Grid item xs={12}>
                            <Box display='inline'  className='fontWeightRegular'>
                                Order Max Qty as % ADV
                            </Box>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid container>
                            <Grid item xs={6}>
                                <div className='form-group'>
                                    <label>Minimum ADV to Apply Check</label>
                                    <NyNumberInput
                                        name={`creditLimits[0].limitLow`}
                                        min={1}
                                        max={999999999}
                                    />
                                </div>
                            </Grid>
                            <Grid xs={1}/>
                            <Grid xs={5}>
                                <div className='form-group'>
                                    <label>% ADV</label>
                                    <NyNumberInput
                                        name={`creditLimits[0].limit`}
                                        min={1}
                                        max={999999999}
                                    />
                                </div>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}/>
                        </>
                    )
                    : (
                        <>
                        <Grid item xs={12}>
                            <Box display='inline'  className='fontWeightRegular'>
                                Order Max Qty as % ADV
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label>Range Id</label>
                        </Grid>
                        <Grid item xs={3}>
                            <label>ADV Range</label>
                        </Grid>
                        <Grid item xs={3}>
                            <label>% ADV</label>
                        </Grid>
                        <Grid item xs={4}/>
                        {labels.filter((e) => e !== 0).map((e)=> {
                            return (
                                <Grid container spacing={1} key={e.id}>
                                    <Grid item xs={2}>
                                        <label className='space-left-1'>{e.id}</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <label>{e.label}</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <NyNumberInput
                                            name={`creditLimits[${e.id}].limit`}
                                            min={1}
                                            max={999999999}
                                        />
                                    </Grid>
                                    <Grid item xs={4}/>
                                    </Grid>);
                        })}
                        <Grid item xs={12}/>
                        <Grid item xs={11}>
                            <div className='form-group'>
                                <label>Minimum ADV to Apply Check</label>
                                <Grid item xs={3}>
                                    <NyNumberInput
                                        name={`creditLimits[0].limit`}
                                        min={1}
                                        max={999999999}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={1}/>
                        </>
                    )
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    component={NySubmitAlert}
                    hasCloseBtn
                    className={props.classes.alert}
                />
                <Grid item xs={12} className={props.classes.controls}>
                    <NySubmitBtn
                        disabled={!isChanged}
                        className='space-right-1'
                    />
                </Grid>
            </Grid>
        </NyForm>
    );
});
