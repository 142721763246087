import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import NyCheckboxInput from 'nyse-web-tools-common/lib/react/form/NyCheckboxInput/NyCheckboxInput';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NyRadioButtonInput from 'nyse-web-tools-common/lib/react/form/NyRadioButtonInput/NyRadioButtonInput';
import NySelectInput from 'nyse-web-tools-common/lib/react/form/NySelectInput/NySelectInput';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, { useCallback, useMemo, useState } from 'react';

import { capitalizeRole, ENTERING, Entitlement, FLOOR_BROKER } from '../../../../configs/entitlementRoleMap';
import useResource from '../../../../hooks/useResource.hook';
import { formatCreditLimit, optionsSettingsUpdateResource } from '../../../../resources/riskSettings.resource';
import {HelpTooltip} from '../../../displays/HelpTooltip';
import NyMultiEmailInput from "../../../input/NyMultiEmailInput";

type SettingsFormProps = {
    classes: {
        alert: string;
        controls: string;
        form: string;
        formBody: string;
        radioBtns: string;
    };
    context: {
        entitlement: Entitlement;
        onModalClosed(status: boolean): void;
        isOptionsMarket: boolean;
    };
    className: string;
    data: any;
    onSuccess(isSuccessful: boolean);
};

const getEmails = (inEmails) => {
    if (inEmails === undefined || inEmails.trim().length === 0)
        return "";
    let str = inEmails.trim();
    while (str.startsWith(',') || str.endsWith(',')) {
        if (str.startsWith(','))
            str = str.slice(1);
        if (str.endsWith(','))
            str = str.slice(0, -1);
        str = str.trim();
    }
    return str;
}

export const SettingsForm: React.FC<SettingsFormProps> = React.memo((props) => {
    const resources = useResource(optionsSettingsUpdateResource);
    const entityType = props.data.entityType;

    const initialLimitsHash = useMemo(
        () => formatCreditLimit(props.data.limits),
        [props.data.limits]
    );

    const shouldInputRender = {
        activityBase:
            props.context.isOptionsMarket && (entityType === 'SYMBOL_MPID' || entityType === 'SYMBOL_MPID_MMID'),
        grossLimits: !props.context.isOptionsMarket && entityType !== 'SYMBOL_MPID' && entityType !== 'SYMBOL_MPID_SUBID',
        grossLimitsOpenAndExec: !props.context.isOptionsMarket && (entityType === 'MPID' || entityType === 'MPID_SUBID'),
        singleOrder:
            entityType === 'MPID' ||
            entityType === 'MPID_MMID' ||
            entityType === 'MPID_SUBID' ||
            !props.context.isOptionsMarket && entityType !== 'SYMBOL_MPID' && entityType !== 'SYMBOL_MPID_SUBID',
        grmp: props.context.isOptionsMarket && (entityType === 'MPID' || entityType === 'MPID_MMID'),
        bulk: props.context.isOptionsMarket && (entityType === 'SYMBOL_MPID' || entityType === 'SYMBOL_MPID_MMID' || entityType === 'SYMBOL_MPID_SUBID')
    };

    const [values, setValues] = useState({
        creditLimits: initialLimitsHash,
        email: props.data.email,
        entityId: props.data.entityId,
        limitCrdRole:
            props.data.limitCrdRole === FLOOR_BROKER &&
            props.context.isOptionsMarket
                ? ENTERING
                : props.data.limitCrdRole,
        limitCrd: props.data.limitCrd
    });

    const onChange = (values) => setValues(values);

    const disabledActivity = values?.creditLimits?.activity?.limit <= 0;

    const generateResetRiskTypes = (newVal, oldVal) => {
        const resetSettings = [];
        if (!newVal?.ord_qty && oldVal?.ord_qty > 0) {
            resetSettings.push('ord_qty');
        }
        if (!newVal?.ord_val && oldVal?.ord_val > 0) {
            resetSettings.push('ord_val');
        }
        if (!newVal?.activity?.limit && oldVal?.activity?.limit > 0) {
            resetSettings.push(oldVal.activity.riskType);
        }
        if (!newVal?.trans_vol_pct?.limit && oldVal?.trans_vol_pct?.limit > 0) {
            resetSettings.push('trans_vol_pct');
        }
        if (!newVal?.cred_ord_exec?.limit && oldVal?.cred_ord_exec?.limit > 0) {
            resetSettings.push('cred_ord_exec');
        }
        if (!newVal?.cred_exec?.limit && oldVal?.cred_exec?.limit > 0) {
            resetSettings.push('cred_exec');
        }
        if (!newVal?.cred_ord?.limit && oldVal?.cred_ord?.limit > 0) {
            resetSettings.push('cred_ord');
        }
        return resetSettings;
    };

    const createCreditLimit = (creditLimits) =>
        Object.entries(creditLimits)
            .map((limit) => {
                const [key, value] = limit;
                return key === 'activity'
                    ? value
                    : key === 'trans_vol_pct' || key === 'cred_ord_exec' || key === 'cred_exec' || key === 'cred_ord'
                        ? {
                            riskType: key,
                            // @ts-ignore
                            ...value
                        }
                        : {
                            riskType: key,
                            limit: value
                        };
            })
            .filter(({limit}) => limit > 0);

    const onSubmit = useCallback(
        (values) => {
            const emails = getEmails(values.email);
            const resetRiskTypes = generateResetRiskTypes(
                values.creditLimits,
                initialLimitsHash
            );
            const creditLimits = createCreditLimit(values.creditLimits);
            return resources.put(
                {
                    ...values,
                    email: emails,
                    creditLimits,
                    resetRiskTypes
                },
                values.entityId
            );
        },
        [resources, initialLimitsHash]
    );

    return (
        <NyForm
            data-e2e-tag='updateSettings'
            className={`${props.classes.form} ${props.className}`}
            initialValues={values}
            onChange={onChange}
            onSuccess={props.onSuccess}
            onSubmit={onSubmit}>
            <Grid className={props.classes.formBody} container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Box display='inline' fontWeight='fontWeightMedium'>
                        {capitalizeRole(props.data.limitCrdRole)} Firm:
                    </Box>
                    &nbsp;{props.data.limitCrd}
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {props.data.write && shouldInputRender.grossLimits && (
                    <>
                        <Grid item xs={12}>
                            <div className='fontWeightRegular'>
                                * Gross (Open + Execute)
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={props.classes.radioBtns}>
                                <label>Action</label>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_ord_exec.action'
                                    required={values?.creditLimits?.cred_ord_exec?.limit !== ''}
                                    selectedValue='notify'>
                                    Notify
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_ord_exec.action'
                                    required={values?.creditLimits?.cred_ord_exec?.limit !== ''}
                                    selectedValue='block'>
                                    Block
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_ord_exec.action'
                                    required={values?.creditLimits?.cred_ord_exec?.limit !== ''}
                                    selectedValue='cancel'>
                                    Cancel & Block
                                </NyRadioButtonInput>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className='form-group'>
                                <label>Limit</label>
                                <NyNumberInput
                                    name={'creditLimits.cred_ord_exec.limit'}
                                    min={1}
                                    max={999999999999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}/>
                    </>
                )}
                {props.data.write && shouldInputRender.grossLimitsOpenAndExec && (
                    <>
                        <Grid item xs={12}>
                            <div className='fontWeightRegular'>
                                * Gross (Execute)
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={props.classes.radioBtns}>
                                <label>Action</label>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_exec.action'
                                    required={values?.creditLimits?.cred_exec?.limit !== ''}
                                    selectedValue='notify'>
                                    Notify
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_exec.action'
                                    required={values?.creditLimits?.cred_exec?.limit !== ''}
                                    selectedValue='block'>
                                    Block
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_exec.action'
                                    required={values?.creditLimits?.cred_exec?.limit !== ''}
                                    selectedValue='cancel'>
                                    Cancel & Block
                                </NyRadioButtonInput>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className='form-group'>
                                <label>Limit</label>
                                <NyNumberInput
                                    name={'creditLimits.cred_exec.limit'}
                                    min={1}
                                    max={999999999999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}/>
                        <Grid item xs={12}>
                            <div className='fontWeightRegular'>
                                * Gross (Open)
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={props.classes.radioBtns}>
                                <label>Action</label>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_ord.action'
                                    required={values?.creditLimits?.cred_ord?.limit !== ''}
                                    selectedValue='notify'>
                                    Notify
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_ord.action'
                                    required={values?.creditLimits?.cred_ord?.limit !== ''}
                                    selectedValue='block'>
                                    Block
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.cred_ord.action'
                                    required={values?.creditLimits?.cred_ord?.limit !== ''}
                                    selectedValue='cancel'>
                                    Cancel & Block
                                </NyRadioButtonInput>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className='form-group'>
                                <label>Limit</label>
                                <NyNumberInput
                                    name={'creditLimits.cred_ord.limit'}
                                    min={1}
                                    max={999999999999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}/>
                    </>
                )}
                {props.data.write && shouldInputRender.activityBase && (
                    <>
                        <Grid item xs={12}>
                            <div className='fontWeightRegular'>Activity Base</div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className='form-group'>
                                <label>Limit</label>
                                <NyNumberInput
                                    name='creditLimits.activity.limit'
                                    min={1}
                                    max={999999}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Type</label>
                                <NySelectInput
                                    disabled={disabledActivity}
                                    name='creditLimits.activity.riskType'
                                    required>
                                    <option value=''>-</option>
                                    <option value='rolling_vol'>Volume</option>
                                    <option value='rolling_trans'>
                                        Transaction
                                    </option>
                                    <option value='rolling_pct'>
                                        Percentage
                                    </option>
                                </NySelectInput>
                            </div>
                            <div className='form-group'>
                                <label>Time Window (μs)</label>
                                <NyNumberInput
                                    disabled={disabledActivity}
                                    required
                                    name='creditLimits.activity.window'
                                    min={1}
                                    max={999999999999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={props.classes.radioBtns}>
                                <label>Action</label>
                                <NyRadioButtonInput
                                    name='creditLimits.activity.action'
                                    required
                                    disabled={disabledActivity}
                                    selectedValue='notify'>
                                    Notify
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.activity.action'
                                    required
                                    disabled={disabledActivity}
                                    selectedValue='block'>
                                    Block
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.activity.action'
                                    required
                                    disabled={disabledActivity}
                                    selectedValue='cancel'>
                                    Cancel & Block
                                </NyRadioButtonInput>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={props.classes.radioBtns}>
                                <label>IOC/GTX</label>
                                <NyRadioButtonInput
                                    name='creditLimits.activity.ioc'
                                    required
                                    disabled={disabledActivity}
                                    selectedValue='include'>
                                    Include
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='creditLimits.activity.ioc'
                                    required
                                    disabled={disabledActivity}
                                    selectedValue='exclude'>
                                    Exclude
                                </NyRadioButtonInput>
                            </div>
                        </Grid>
                        <Grid item xs={4}/>
                    </>
                )}
                {props.data.write && shouldInputRender.grmp && (
                    <>
                        <Grid item xs={12}>
                            <div className='fontWeightRegular'>GRMP Update</div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className='form-group'>
                                <label>Limit</label>
                                <NyNumberInput
                                    disabled={!props.data.write}
                                    required
                                    name='creditLimits.trans_vol_pct.limit'
                                    min={1}
                                    max={999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className='form-group'>
                                <label>Time Window (μs)</label>
                                <NyNumberInput
                                    required
                                    disabled={!props.data.write}
                                    name='creditLimits.trans_vol_pct.window'
                                    min={1}
                                    max={999999999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}/>
                        <Box mt={2} mb={2}>
                            <Divider/>
                        </Box>
                    </>
                )}
                {props.data.write && shouldInputRender.singleOrder && (
                    <>
                        <Grid item xs={4}>
                            <div className='form-group'>
                                <label>Single Order - Max Qty</label>
                                <NyNumberInput
                                    disabled={!props.data.write}
                                    name='creditLimits.ord_qty'
                                    min={1}
                                    max={999999999999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className='form-group'>
                                <label>Single Order - Max Notional</label>
                                <NyNumberInput
                                    disabled={!props.data.write}
                                    name='creditLimits.ord_val'
                                    min={1}
                                    max={999999999999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}/>
                        <Box mt={2} mb={2}>
                            <Divider/>
                        </Box>
                    </>
                )}
                <Grid item xs={8}>
                    <div className='form-group'>
                        <label>
                            Notification Email&nbsp;
                            <HelpTooltip tooltip={'Hit \'enter\' between each email address if entering multiple. (maximum 200 characters)'}/>
                        </label>
                        <NyMultiEmailInput
                            maxLength={200}
                            placeholder={'input email'}
                            name='email'
                            separator={','}/>
                    </div>
                </Grid>
                <Grid item xs={4}/>
                {shouldInputRender.bulk && (
                    <Grid item xs={12}>
                        <div className='form-group-span'>
                            <label>Apply To ALL Symbols</label>
                            <NyCheckboxInput name='bulk'/>
                        </div>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    component={NySubmitAlert}
                    hasCloseBtn
                    className={props.classes.alert}
                />
                <Grid item xs={12} className={props.classes.controls}>
                    <NySubmitBtn className='space-right-1'/>
                </Grid>
            </Grid>
        </NyForm>
    );
});
