import {
    AgGrid,
    AgGridAutoRefresh,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridPagination,
    AgGridProvider,
    AgGridToggleShowFilters,
    NyNumberInput,
    NySelectInput,
    NySymbolInput
} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

//import {DisplayBooksSelect} from '../components/selects/displayBooks.select';
import {PrimaryExchangeSelect} from '../components/selects/primaryExchange.select';
import {getAuctionsData} from '../stores/auctions/auctions.actions';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';

@connect(['columnDefs', 'auctions', 'platform'], {getAuctionsData, setColumns})
export class AuctionsPage extends React.Component {
    state = {
        filterValues: {}
    };

    _initialFilters = {
        auction_type: 'UPCOMING'
    };

    _initialPagination = {
        limit: 10000,
        offset: 0
    };

    _onFiltersChanged = (filterValues) => {
        this.setState({
            filterValues
        });
    };

    _onPrimaryExchangeChanged = ({setValue}) => {
        setValue('market_maker', '');
    };

    render() {
        return (
            <AgGridProvider
                columnNamespace='auctions_2'
                columnDefs={this.props.columnDefs.auctions}
                filtersClassName='row space-bottom-1'
                hasServerSort={true}
                initialFilters={this._initialFilters}
                initialPagination={this._initialPagination}
                onColumnsChanged={this.props.setColumns}
                onFiltersChanged={this._onFiltersChanged}
                onLoad={this.props.getAuctionsData}
                submitOnLoad={true}
                {...this.props.auctions}>
                <AgGridProvider.Filters>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Symbol</label>
                        <NySymbolInput name='symbol' />
                    </div>
                    {/*{this.props.platform.mic === 'XNYS' && (*/}
                    {/*    <>*/}
                    {/*        <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>*/}
                    {/*            <label>Display Books</label>*/}
                    {/*            <DisplayBooksSelect*/}
                    {/*                isMulti={true}*/}
                    {/*                name='books'*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>*/}
                    {/*            <label>DMM Unit</label>*/}
                    {/*            <NySelectInput*/}
                    {/*                name='dmms'*/}
                    {/*                isMulti={true}*/}
                    {/*                showLabelAsValue={true}>*/}
                    {/*                <option value={62}>GTSS</option>*/}
                    {/*                <option value={65}>VTR</option>*/}
                    {/*                <option value={75}>IMC</option>*/}
                    {/*                <option value={77}>BRN</option>*/}
                    {/*                <option value={87}>DMCT</option>*/}
                    {/*                <option value={99}>ATS</option>*/}
                    {/*            </NySelectInput>*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*)}*/}
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Primary Exchange</label>
                        <PrimaryExchangeSelect
                            onChange={this._onPrimaryExchangeChanged}
                            name='primary_exch'
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Auction Type</label>
                        <NySelectInput name='auction_type'>
                            <option value=''>-</option>
                            <option value='CLOSE'>Closing</option>
                            <option value='CORE'>Core</option>
                            <option value='IPO'>IPO</option>
                            <option value='MORNING'>Morning</option>
                            <option value='NONE'>None</option>
                            <option value='REOPENING'>Reopening</option>
                            <option value='UPCOMING'>Upcoming</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Market Imbalance Side</label>
                        <NySelectInput name='mktimbside'>
                            <option value=''>-</option>
                            <option value='BUY'>Buy</option>
                            <option value='CROSS'>Cross</option>
                            <option value='NONE'>None</option>
                            <option value='SELL'>Sell</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Total Imbalance Side</label>
                        <NySelectInput name='totimbside'>
                            <option value=''>-</option>
                            <option value='BUY'>Buy</option>
                            <option value='CROSS'>Cross</option>
                            <option value='NONE'>None</option>
                            <option value='SELL'>Sell</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Imbalance/Matched Vol</label>
                        <NySelectInput name='hasimbalance'>
                            <option value=''>-</option>
                            <option value='NO'>No</option>
                            <option value='YES'>Yes</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Market Imbalance</label>
                        <NySelectInput name='mktimbalance'>
                            <option value=''>-</option>
                            <option value='NO'>No</option>
                            <option value='YES'>Yes</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Matched Volume</label>
                        <NySelectInput name='matchedvol'>
                            <option value=''>-</option>
                            <option value='NO'>No</option>
                            <option value='YES'>Yes</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-xs-6 col-sm-4 col-md-2 col-lg-2 col-xl-2'>
                        <label>Auction Indicator</label>
                        <NySelectInput name='aucind'>
                            <option value=''>-</option>
                            <option value='NO_AUCTION'>No Auction</option>
                            <option value='NO_CLOSING_AUCTION'>
                                No Closing Auction
                            </option>
                            <option value='NO_IMBALANCE_AUCTION'>
                                No Imbalance Auction
                            </option>
                            <option value='RUNNING_AUCTION'>Running Auction</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-xs-6 col-sm-4 col-md-2 col-lg-2 col-xl-2'>
                        <label>Freeze Indicator</label>
                        <NySelectInput name='frzind'>
                            <option value=''>-</option>
                            <option value='FREEZE'>Freeze</option>
                            <option value='NO_FREEZE'>No Freeze</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-xs-12 col-sm-6 col-md-4 col-lg-2 col-xl-2'>
                        <label>Auction Only Price</label>
                        <div className='flex-row flex-align-center'>
                            <NyNumberInput
                                name='auconlyprices.start'
                                max='auconlyprices.end'
                                precision={6}
                            />
                            <span className='flex-shrink to-span'>TO</span>
                            <NyNumberInput
                                name='auconlyprices.end'
                                min='auconlyprices.start'
                                precision={6}
                            />
                        </div>
                    </div>
                    <div className='form-group col-xs-12 col-sm-6 col-md-4 col-lg-2 col-xl-2'>
                        <label>Book Clearing Price</label>
                        <div className='flex-row flex-align-center'>
                            <NyNumberInput
                                name='bookclearprices.start'
                                max='bookclearprices.end'
                                precision={6}
                            />
                            <span className='flex-shrink to-span'>TO</span>
                            <NyNumberInput
                                name='bookclearprices.end'
                                min='bookclearprices.start'
                                precision={6}
                            />
                        </div>
                    </div>
                    <div className='form-group col-xs-12 col-sm-6 col-md-4 col-lg-2 col-xl-2'>
                        <label>Reference Price</label>
                        <div className='flex-row flex-align-center'>
                            <NyNumberInput
                                name='refprices.start'
                                max='refprices.end'
                                precision={6}
                            />
                            <span className='flex-shrink to-span'>TO</span>
                            <NyNumberInput
                                name='refprices.end'
                                min='refprices.start'
                                precision={6}
                            />
                        </div>
                    </div>
                </AgGridProvider.Filters>
                <div className='row space-bottom-1'>
                    <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                        <div className='flex-row flex-align-bottom'>
                            <AgGridProvider.SubmitBtn>
                                Search
                            </AgGridProvider.SubmitBtn>
                            <AgGridProvider.ResetBtn>
                                Reset
                            </AgGridProvider.ResetBtn>
                            <div className='btn-circle-grp flex-pull-right'>
                                <AgGridAutoRefresh />
                                <AgGridToggleShowFilters />
                                <AgGridExportMenu
                                    exportFileName='auctions'
                                    exportSheetName={this.props.platform.name}
                                />
                                <AgGridColumnMenuTrigger />
                            </div>
                        </div>
                    </div>
                </div>

                <AgGrid reactNext={true} />

                <AgGridPagination>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1,000</option>
                    <option value={5000}>5,000</option>
                    <option value={10000}>10,000</option>
                </AgGridPagination>
            </AgGridProvider>
        );
    }
}
