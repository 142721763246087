import {NyButton} from 'nyse-web-tools-common/lib/react';
import React from 'react';

import {GlossaryModal} from './glossary.modal';

export class GlossaryBtn extends React.PureComponent {
    state = {
        show: false
    };

    _onClose = () => {
        this.setState({
            show: false
        });
    };

    _onShow = () => {
        this.setState({
            show: true
        });
    };

    render() {
        return (
            <React.Fragment>
                <NyButton
                    circle
                    className='mdi mdi-help-circle-outline'
                    data-e2e-tag='glossaryTrigger'
                    data-active={this.state.show.toString()}
                    onClick={this._onShow}
                    title='Glossary'
                />
                <GlossaryModal
                    hideModal={this._onClose}
                    show={this.state.show}
                />
            </React.Fragment>
        );
    }
}
