import React from 'react';

export const timeoutError = {
    title: 'Request Timed Out',
    detail: (
        <span>
            The server took longer than 180 seconds to respond. Please connect{' '}
            <strong>crs@nyse.com</strong> to report this issue
        </span>
    ),
    type: 'danger'
};
