import {agGridCellRenderer, NyButton} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React from 'react';

@agGridCellRenderer
export class OPRAEntryEditBtn extends React.PureComponent {
    static propTypes = {
        context: PropTypes.shape({
            onDetailsClosed: PropTypes.func.isRequired
        }),
        data: PropTypes.shape({
            opraId: PropTypes.number,
            dealId: PropTypes.string,
            tradeExecTime: PropTypes.string,
            crd: PropTypes.number,
            opraEntryType: PropTypes.oneOf(['MANUAL', 'SEQUENCE']),
            opraSeqNumber: PropTypes.number,
            symbol: PropTypes.string,
            priceSpread: PropTypes.number,
            hedgeRatioPercentage: PropTypes.number,
            strategy: PropTypes.oneOf([
                'BW',
                'SPRD',
                'STDL',
                'RVSL',
                'CONV',
                'OTHR',
                'MA',
                'RA',
                'CST'
            ]),
            qctType: PropTypes.oneOf([
                'ETO',
                'OTO',
                'FLEX',
                'EQTY',
                'FUTR',
                'OTHR'
            ]),
            exchange: PropTypes.string,
            printTime: PropTypes.string,
            expirationDate: PropTypes.string,
            strikePrice: PropTypes.number,
            callPut: PropTypes.oneOf(['CALL', 'PUT', 'BOTH']),
            quantity: PropTypes.number,
            shortSale: PropTypes.oneOf(['UNK', 'LS', 'SSH', 'SSE']),
            status: PropTypes.oneOf([
                'ACCEPTED',
                'COMPLETED',
                'MATCHING',
                'OPEN',
                'REJECTED_MULTIPLE_MATCHES',
                'REJECTED_NO_MATCH'
            ]),
            comments: PropTypes.string,
            tplusNReconciliationAllowed: PropTypes.bool
        })
    };

    state = {
        isSuccessful: false,
        show: false
    };

    _onClick = () => {
        this.props.context.showDetailModal(this.props.data);

        this.setState({
            isSuccessful: false
        });
    };

    _onClose = (isSuccessful) => {
        this.setState(
            {
                show: false
            },
            () => {
                this.props.context.onDetailsClosed(isSuccessful);
            }
        );
    };

    render() {
        return (
            <NyButton
                circle
                data-modal-name='opraEntryDetailsModal'
                data-e2e-tag='opraEntryDetailsBtn'
                data-deal-id={this.props.data.dealId}
                data-opra-id={this.props.data.opraId}
                disabled={this.props.data.lock}
                className='mdi mdi-details mdi-18px align-self-center'
                onClick={this._onClick}
            />
        );
    }
}
