import {events} from '../events';

export const myVolumesFiltersReducer = {
    [events.MY_VOLUMES_FILTERS_GET]: (state = {}) => ({
        ...state,
        sessionIds: [],
        mmIds: [],
        brokerBadges: [],
        isLoading: true
    }),
    [events.MY_VOLUMES_FILTERS_SUCCESS]: (
        state,
        {
            payload: {
                data: {data}
            }
        }
    ) => ({
        isLoading: false,
        error: false,
        ...data
    }),
    [events.MY_VOLUMES_FILTERS_ERROR]: (state, {error}) => ({
        error,
        sessionIds: [],
        mmIds: [],
        brokerBadges: [],
        isLoading: false
    })
};
