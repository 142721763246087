import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import pathToRegex from 'path-to-regexp';
import PropTypes from 'prop-types';
import React from 'react';
import {NavLink} from 'react-router-dom';

@connect(['platform', 'routes'])
export class Link extends React.PureComponent {
    static propTypes = {
        activeClassName: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        params: PropTypes.object,
        style: PropTypes.object,
        to: PropTypes.string.isRequired,
        search: PropTypes.string
    };

    static defaultProps = {
        activeClassName: 'active',
        className: '',
        disabled: false,
        params: {},
        style: {}
    };

    _onClick = (e) => {
        if (this.props.disabled) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    render() {
        const state = this.props.routes.find(({url}) => url === this.props.to);
        const platform = (
            this.props.params.platform ||
            this.props.platform.mic ||
            ''
        ).toLowerCase();

        return (
            <>
                {state ? (
                    <NavLink
                        className={this.props.className}
                        onClick={this._onClick}
                        to={
                            this.props.search
                                ? `${pathToRegex.compile(state.url)({
                                    ...this.props.params,
                                    platform
                                })}${this.props.search}`
                                : pathToRegex.compile(state.url)({
                                    ...this.props.params,
                                    platform
                                })
                        }
                        activeClassName={this.props.activeClassName}
                        style={this.props.style}>
                        {this.props.children}
                    </NavLink>
                ) : null}
            </>
        );
    }
}
