import {get} from 'lodash';
import React from 'react';

export const ERROR_BORDER = 'error-border';

export const UPDATED_BACKGROUND = 'updated-background';

export const agRenderCellClassRules = {
    [ERROR_BORDER]: (params) => get(params.context.errors, `${params.data[params.context.primaryKey]}.${params.colDef.field}`, false),
    [UPDATED_BACKGROUND]: (params) => get(params.data, `${params.colDef.field}IsUpdatedByUser`, false)
};

export default function createRendererColumnDef(config: any, Component: React.ElementType) {
    const RenderingClassComponent = class Renderer extends React.Component<
        any,
        {value: any}
    > {
        name: string;

        constructor(props) {
            super(props);
            this.state = {
                value: get(this.props.data, this.props.colDef.field, '')
            };
            const idx = this.props.agGridReact.gridOptions.rowData.findIndex((row) => row[this.props.context.primaryKey] === this.props.data[this.props.context.primaryKey]);
            this.name = `${this.props.context.rowField || ''}[${idx}].${this.props.colDef.field}`;
        }

        _onChange = (value) => {
            this.setState({value});
            this.props.context.onCellValueChanged(this.props, value);
        };

        render() {
            const {value} = this.state;
            return (
                <div>
                    <Component
                        {...this.props}
                        onChange={this._onChange}
                        value={value}
                        autoFocus
                        disablePortal
                        isSearchable
                        name={this.name}
                        noBorder
                        noBoxShadow
                        openOnRender
                        menuPortalTarget={document.body}
                    />
                </div>
            );
        }
    };

    return {
        ...config,
        editable: false,
        cellClassRules: agRenderCellClassRules,
        cellRendererFramework: RenderingClassComponent
    };
};
