import Box from '@material-ui/core/Box';
import {useFormikContext} from 'formik';
import {
    NySelectInput,
    NyTextInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import React, {useCallback, useMemo, useState} from 'react';

import {
    CustFirm,
    custFirmChoices,
    CustomerSideSectionObject,
    getBuySellSideDisplayString, keepAsIs,
    SideTradeType, stripCharsForAccountOptionalData, stripSpecialChars, TradeSummaryTabs
} from '../../../../resources/tradeSummary.resource';
import {CapStrategyIdSelect} from '../../../selects/capStrategyId.select';
import {ClearingNumberSelect} from '../../../selects/clearingNumber.select';
import {NyMultiEditInputSelect} from '../../../selects/multiEditInputSelect.select';


export const CustomerSideSection: React.FC<CustomerSideSectionObject> = React.memo(
    (props) => {
        const formik = useFormikContext<CustomerSideSectionObject>();

        const isLocalMM = formik.values.custFirm === CustFirm.MarketMaker;
        const isAwayMM = formik.values.custFirm === CustFirm.AwayMarketMaker;
        const isLocalOrAwayMM = isLocalMM || isAwayMM;
        const isFloorBroker =
            props.custTradeType === SideTradeType.FLOOR_BROKER;
        const isFloorBrokerOnBehalfOfLocalMM =
            props.custTradeType === SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isFloorBrokerOnBehalfOfAwayMM =
            props.custTradeType === SideTradeType.FLOOR_BROKER_AWAY_MM;

        const capStrategyShouldDisplay = useMemo(
            () =>
                props.outcryTrade ||
                ((isFloorBroker ||
                    isFloorBrokerOnBehalfOfAwayMM ||
                    isFloorBrokerOnBehalfOfLocalMM) &&
                    props.qccTrade),
            [
                props.qccTrade,
                props.outcryTrade,
                isFloorBrokerOnBehalfOfLocalMM,
                isFloorBrokerOnBehalfOfAwayMM,
                isFloorBroker
            ]
        );

        const custSideDisplayString = useMemo(
            () => getBuySellSideDisplayString(props.custSide)
            , [props.custSide]);

        useMountedEffect(() => {
            !props.isMultiAdjustment && formik.setFieldValue('custSndrSubId', '');
        }, [formik.values.custFirm]);

        const [firmId, setFirmId] = useState(props.custFirmId);
        const onSetFirmIdChange = useCallback(({value}) => {
            setFirmId(value);
        }, []);

        const isContraApproval =
            props.currentTab === TradeSummaryTabs.contraApproval;

        const isDisabledForMultiEdit =
            props.isMultiAdjustment && !props.isMultiEditForAllFields;

        const onCustSndrSubIdChange = useCallback(({value, setFieldValue}) => {
            setFieldValue('custSndrSubId', stripSpecialChars(value));
        }, []);
        const onCustCmtaChange = useCallback(({value, setFieldValue}) => {
            setFieldValue('custCmta', stripSpecialChars(value));
        }, []);
        const onCustAccountChange = useCallback(({value, setFieldValue}) => {
            setFieldValue('custAccount', stripCharsForAccountOptionalData(value));
        }, []);
        const onCustOptnlDataChange = useCallback(({value, setFieldValue}) => {
            setFieldValue('custOptnlData', stripCharsForAccountOptionalData(value));
        }, []);

        return (
            <div className='row' style={{width: props.isMultiAdjustment ? '100%' : 'initial'}}>
                <Box
                    className='col-xs-12 col-sm-12 col-md-12 col-lg-12'
                    fontWeight='fontWeightMedium'>
                    Customer Side ({custSideDisplayString})
                </Box>
                <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                    <label>Customer or Firm</label>
                    <NySelectInput
                        name='custFirm'
                        options={ props.isMultiAdjustment ? [...custFirmChoices , ...keepAsIs] : custFirmChoices}
                        disabled={
                            props.disabled ||
                            isDisabledForMultiEdit ||
                            (isContraApproval &&
                                props.custTradeType === SideTradeType.LOCAL_MM)
                        }
                        required={!props.isMultiAdjustment}
                    />
                </div>
                <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                    <label>MPID</label>
                    {props.currentTab === TradeSummaryTabs.allocate &&
                    formik.values.custFirm !== CustFirm.MarketMaker ? (
                        <NySelectInput
                            name='custFirmId'
                            disabled={props.disabled || isLocalMM}
                            options={props.custFirmIdOptions}
                            onChange={onSetFirmIdChange}
                        />
                    ) : (
                        <NyTextInput
                            name='custFirmId'
                            disabled={props.disabled || isDisabledForMultiEdit}
                            onChange={onSetFirmIdChange}
                            readOnly
                        />
                    )}
                </div>
                <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                    <label>MMID</label>
                    {!props.disabled && isLocalMM ? (
                        <NySelectInput
                            name='custSndrSubId'
                            disabled={
                                props.disabled ||
                                isDisabledForMultiEdit ||
                                (isContraApproval &&
                                    props.custTradeType === SideTradeType.LOCAL_MM)
                            }
                            options={props.custSndrSubIdOptions}
                            required
                        />
                    ) : (
                        <NyUppercaseInput
                            name='custSndrSubId'
                            disabled={props.disabled || isDisabledForMultiEdit}
                            readOnly={!isAwayMM}
                            required={isAwayMM}
                            onChange={onCustSndrSubIdChange}
                        />
                    )}
                </div>
                <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                    <label>Clearing #</label>
                    <ClearingNumberSelect
                        name='custClearingNumber'
                        sideCrd={
                            isFloorBroker ||
                            isFloorBrokerOnBehalfOfLocalMM ||
                            isFloorBrokerOnBehalfOfAwayMM
                                ? props.custOmsCrd
                                : props.custCrd
                        }
                        firmId={
                            isFloorBrokerOnBehalfOfLocalMM && !isLocalMM
                                ? props.custOmsId
                                : firmId
                        }
                        disabled={props.disabled || isDisabledForMultiEdit}
                        custFirm={formik.values.custFirm}
                        clearingNumber={props.custClearingNumber}
                        isLocalMM={isLocalMM}
                        isFloorBrokerOnBehalfOfLocalMM={
                            isFloorBrokerOnBehalfOfLocalMM
                        }
                        required={!props.isMultiAdjustment && !isLocalMM}
                        isMultiAdjustment
                    />
                </div>
                <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                    <label>Open/Close</label>
                    <NySelectInput
                        name='custOpenClose'
                        disabled={props.disabled || isDisabledForMultiEdit}
                        required={
                            !isLocalOrAwayMM &&
                            !isFloorBrokerOnBehalfOfLocalMM &&
                            !isFloorBrokerOnBehalfOfAwayMM && !props.isMultiAdjustment
                        }>
                        {(isLocalOrAwayMM ||
                            isFloorBrokerOnBehalfOfLocalMM ||
                            isFloorBrokerOnBehalfOfAwayMM) && (
                            <option value=''>-</option>
                        )}
                        {props.isMultiAdjustment && <option value='Keep as is'>- Keep as is -</option>}
                        <option value='O'>Open</option>
                        <option value='C'>Close</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                    <label>Badge</label>
                    {isFloorBroker ||
                    isFloorBrokerOnBehalfOfLocalMM ||
                    isFloorBrokerOnBehalfOfAwayMM ? (
                            <NySelectInput
                                name='custSubmittingBkrId'
                                disabled={
                                    isContraApproval ||
                                props.disabled ||
                                isDisabledForMultiEdit
                                }
                                options={props.custSubmittingBkrIdOptions.concat(props.isMultiAdjustment ? keepAsIs : [])}
                            />
                        ) : (
                            <NyTextInput
                                name='custSubmittingBkrId'
                                disabled={
                                    isContraApproval ||
                                props.disabled ||
                                isDisabledForMultiEdit
                                }
                                readOnly
                            />
                        )}
                </div>
                {capStrategyShouldDisplay === true || (props.isMultiAdjustment && props.isMultiAdjustmentCapStrategyIdValid) ? (
                    <>
                        <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                            <label>CMTA</label>
                            {
                                props.isMultiAdjustment ? (
                                    <NyMultiEditInputSelect
                                        name='custCmta'
                                        disabled={props.disabled}
                                        maxLength={5}
                                        onChange={onCustCmtaChange}
                                    />
                                ) : (
                                    <NyTextInput
                                        name='custCmta'
                                        disabled={props.disabled}
                                        maxLength={5}
                                        onChange={onCustCmtaChange}
                                    />
                                )
                            }
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                            <label>Account</label>
                            {
                                props.isMultiAdjustment ? (
                                    <NyMultiEditInputSelect
                                        name='custAccount'
                                        disabled={props.disabled}
                                        maxLength={20}
                                        onChange={onCustAccountChange}
                                    />
                                ) : (
                                    <NyTextInput
                                        name='custAccount'
                                        disabled={props.disabled}
                                        maxLength={20}
                                        onChange={onCustAccountChange}
                                    />
                                )
                            }
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                            <label>Optional Data</label>
                            {
                                props.isMultiAdjustment ? (
                                    <NyMultiEditInputSelect
                                        name='custOptnlData'
                                        disabled={props.disabled}
                                        maxLength={16}
                                        onChange={onCustOptnlDataChange}
                                    />
                                ) : (
                                    <NyTextInput
                                        name='custOptnlData'
                                        disabled={props.disabled}
                                        maxLength={16}
                                        onChange={onCustOptnlDataChange}
                                    />
                                )
                            }
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                            <label>Cap Strategy Id</label>
                            <CapStrategyIdSelect
                                name='custCapStrategyId'
                                disabled={props.disabled}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>CMTA</label>
                            {
                                props.isMultiAdjustment ? (
                                    <NyMultiEditInputSelect
                                        name='custCmta'
                                        disabled={props.disabled}
                                        maxLength={5}
                                        onChange={onCustCmtaChange}
                                    />
                                ) : (
                                    <NyTextInput
                                        name='custCmta'
                                        disabled={props.disabled}
                                        maxLength={5}
                                        onChange={onCustCmtaChange}
                                    />
                                )
                            }
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>Account</label>
                            {
                                props.isMultiAdjustment ? (
                                    <NyMultiEditInputSelect
                                        name='custAccount'
                                        disabled={props.disabled}
                                        maxLength={20}
                                        onChange={onCustAccountChange}
                                    />
                                ) : (
                                    <NyTextInput
                                        name='custAccount'
                                        disabled={props.disabled}
                                        maxLength={20}
                                        onChange={onCustAccountChange}
                                    />
                                )
                            }
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>Optional Data</label>
                            {
                                props.isMultiAdjustment ? (
                                    <NyMultiEditInputSelect
                                        name='custOptnlData'
                                        disabled={props.disabled}
                                        maxLength={16}
                                        onChange={onCustOptnlDataChange}
                                    />
                                ) : (
                                    <NyTextInput
                                        name='custOptnlData'
                                        disabled={props.disabled}
                                        maxLength={16}
                                        onChange={onCustOptnlDataChange}
                                    />
                                )
                            }
                        </div>
                    </>
                )}
            </div>
        );
    }
);
