import {get} from 'lodash';
import {NyButton, NyModal} from 'nyse-web-tools-common/lib/react';
import * as PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import useRoute from '../../../hooks/useRoute.hook';

export const GlossaryModal = React.memo((props) => {
    const route = useRoute();
    const routeTerms = useMemo(() => {
        const terms = get(route, 'glossary.terms', []);
        return typeof terms === 'function' ? terms(route) : terms;
    }, [route]);
    const originalTerms = useMemo(
        () =>
            routeTerms ? routeTerms.filter(({description}) => description) : [],
        [routeTerms]
    );
    const title = useMemo(() => {
        const title = get(route, 'glossary.title', '');

        return typeof title === 'function' ? title(route) : title;
    }, [route]);

    const [searchText, setSearchText] = useState('');
    const [terms, setTerms] = useState([]);

    const onResetFilter = useCallback(() => setSearchText(() => ''), []);

    const onSearchChange = useCallback(({currentTarget: {value}}) => {
        setSearchText(() => value);
    }, []);

    useEffect(() => {
        if (!props.show) {
            setSearchText(() => '');
        }
    }, [props.show]);

    useEffect(() => {
        setTerms(() =>
            searchText === ''
                ? originalTerms
                : originalTerms.filter(
                    ({term, description}) =>
                        term
                            .toLowerCase()
                            .includes(searchText.toLocaleLowerCase()) ||
                          description
                              .toLowerCase()
                              .includes(searchText.toLocaleLowerCase())
                )
        );
    }, [originalTerms, searchText]);

    const addLineBreaks = (string) =>
        string.split('\n').map((text, index) => (
            <React.Fragment key={`${text}-${index}`}>
                {text}
                <br />
            </React.Fragment>
        ));

    return (
        <NyModal
            hideModal={props.hideModal}
            name='glossary'
            parentTitle='Glossary'
            show={props.show}
            title={title}>
            {originalTerms.length > 6 && (
                <div className='row space-top-1 space-bottom-2 flex-align-center'>
                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-9 col-xs-9'>
                        <input
                            className='form-control'
                            data-e2e-tag='glossarySearchInput'
                            placeholder='Search'
                            onChange={onSearchChange}
                            value={searchText}
                        />
                    </div>
                    <div className='col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3'>
                        <NyButton
                            link
                            data-e2e-tag='glossaryResetBtn'
                            onClick={onResetFilter}>
                            Reset
                        </NyButton>
                    </div>
                </div>
            )}
            <div className='row overflow-y'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    {terms.map((entry) => (
                        <React.Fragment key={entry.term}>
                            <h3
                                className='padding-0 space-0'
                                data-e2e-tag='glossaryTerm'>
                                {entry.term.toLowerCase()}
                            </h3>
                            <div
                                className='space-top-1 space-bottom-3'
                                data-e2e-tag='glossaryDefinition'>
                                {addLineBreaks(entry.description)}
                                {entry.example && (
                                    <div
                                        style={{
                                            color: 'gray',
                                            fontStyle: 'italic'
                                        }}>
                                        Example: {entry.example}
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                    {terms.length === 0 && (
                        <div
                            className='flex-row flex-justify-center flex-align-center padding-bottom-2'
                            data-e2e-tag='glossaryNoTerms'>
                            <i className='mdi mdi-information-outline mdi-18px' />
                            &nbsp; No Terms Found
                        </div>
                    )}
                </div>
            </div>
        </NyModal>
    );
});

GlossaryModal.propTypes = {
    hideModal: PropTypes.func,
    route: PropTypes.shape({
        glossary: PropTypes.shape({
            title: PropTypes.string,
            terms: PropTypes.arrayOf(
                PropTypes.shape({
                    term: PropTypes.string,
                    description: PropTypes.string,
                    example: PropTypes.string
                })
            )
        })
    }),
    show: PropTypes.bool
};

GlossaryModal.defaultProps = {
    show: false
};
