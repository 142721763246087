import Divider from '@material-ui/core/Divider';
import {NyAlert, NyForm} from 'nyse-web-tools-common/lib/react';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useMemo, useState} from 'react';

import useResource from '../../../../hooks/useResource.hook';
import {
    TradeSummaryProps,
    tradeRecallResource
} from '../../../../resources/tradeSummary.resource';

export const TradeRecallForm: React.FC<TradeSummaryProps> = React.memo(
    (props) => {
        const [formSubmitted, setFormSubmitted] = useState(false);
        const recallResource = useResource(tradeRecallResource);

        const initialValues = useMemo(
            () => ({
                ...props.data,
                reasonCode: 'Non-Contractual Field Change'
            }),
            [props.data]
        );

        const onSubmit = useCallback(
            () =>
                recallResource.put(
                    {
                        crd: props.crd
                    },
                    {
                        origTdate: props.data.origTdate,
                        evtTyp: props.data.evtTyp,
                        dealIdRef: props.data.dealIdRef,
                        txId: props.data.txId
                    }
                ),
            [recallResource, props.crd, props.data.txId]
        );

        const onSuccess = useCallback(() => {
            setFormSubmitted(() => true);
        }, []);

        return (
            <NyForm
                initialValues={initialValues}
                className={props.className}
                onSuccess={onSuccess}
                onSubmit={onSubmit}>
                <NyAlert
                    title={'Warning'}
                    detail={
                        'All outstanding allocations need to be executed prior to EOD processing'
                    }
                    type='warning'
                    show
                    className='space-top-1'
                />
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 space-top-1'>
                    <NySubmitAlert hasCloseBtn />
                </div>
                <Divider />
                <div className='row space-top-1'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <NySubmitBtn
                            disabled={formSubmitted}
                            data-e2e-tag='recallBtn'
                            className='space-right-1'>
                            Recall
                        </NySubmitBtn>
                        {/* todo:: use disable prop instead of hide after upgrade */}
                    </div>
                </div>
            </NyForm>
        );
    }
);
