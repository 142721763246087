import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import { useFormikContext } from 'formik';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyRadioButtonInput from 'nyse-web-tools-common/lib/react/form/NyRadioButtonInput/NyRadioButtonInput';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import GridDivider from 'nyse-web-tools-common/lib/react/generic/GridDivider/GridDivider';
import NyModal from 'nyse-web-tools-common/lib/react/generic/NyModal/NyModal';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import React, { useCallback, useState } from 'react';
import { useToggle } from 'react-use';

import { ENTERING, Entitlement, getRoleByEntitlement, SUPER_USER } from '../../../configs/entitlementRoleMap';
import useResource from '../../../hooks/useResource.hook';
import { cancelAndBlockResource, RiskMonitorData } from '../../../resources/riskMonitor.resource';

const useStyles = makeStyles({
    root: {
        margin: 0
    }
});

type KillSwitchModalProps = {
    context: {
        entitlement: Entitlement;
        isOptionsMarket: boolean;
        onModalClosed(status: boolean): void;
    };
    data: RiskMonitorData;
};

export const KillSwitchModal: React.FC<KillSwitchModalProps> = React.memo(
    (props) => {
        const classes = useStyles(props);
        const resources = useResource(cancelAndBlockResource);
        const [status, setStatus] = useState(false);
        const [show, toggleShow] = useToggle(false);

        const userCrdRole = getRoleByEntitlement(
            props.context.entitlement,
            props.context.isOptionsMarket,
            props.data.entityType
        );

        const isOptionsEntity =
            props.data.entityType === 'MPID_MMID' ||
            props.data.entityType === 'SYMBOL_MPID_MMID';

        const isEntityCrdUsed = props.context.entitlement === SUPER_USER
            && props.data.entityType === 'BROKER_MPID_SUBID';

        const gridCtx = useFormikContext<any>();

        const onSubmit = useCallback(
            ({action, blockedKsCrd, blockedKsCrdRole, userCrd, entityId}) => {
                return action.includes('block')
                    ? resources.put(
                        action === 'unblock' && props.context.entitlement === SUPER_USER ? {
                                userCrd: blockedKsCrd,
                                userCrdRole: blockedKsCrdRole,
                                entityId
                            }
                            : {
                                userCrd: isEntityCrdUsed ? props.data.entityCrd : userCrd,
                                userCrdRole,
                                entityId
                            },
                        {
                            action,
                            entityId
                        }
                    )
                    : resources.delete(
                        {
                            userCrd: isEntityCrdUsed ? props.data.entityCrd : userCrd,
                            userCrdRole,
                            entityId
                        },
                        {
                            action,
                            entityId
                        }
                    );
            },
            [resources, userCrdRole, props.context.entitlement]
        );


        const shouldButtonRender =
            !(isOptionsEntity && userCrdRole === ENTERING) ||
            props.context.entitlement === 'SUPER_USER';

        const onSuccess = useCallback(() => {
            setStatus(() => true);
        }, []);

        const isBlockedByUser =
            props.context.entitlement === 'SUPER_USER' ||
            (gridCtx.values.userCrd === props.data.blockedKsCrd &&
                props.data.blockedKsCrdRole === userCrdRole);

        useMountedEffect(() => {
            if (!show) {
                props.context.onModalClosed(status);
            } else if (status) {
                setStatus(() => false);
            }
        }, [show]);

        return (
            <>
                {shouldButtonRender && (
                    <Button
                        data-e2e-tag='killSwitchBtn'
                        data-display-risk-entity={props.data.displayRiskEntity}
                        data-name='killSwitch'
                        data-modal-name='killSwitchModal'
                        className={classes.root}
                        color='primary'
                        onClick={toggleShow}
                        variant='contained'>
                        KILL SWITCH
                    </Button>
                )}
                <NyModal
                    data-type='modal'
                    hideModal={toggleShow}
                    name='killSwitch'
                    show={show}
                    parentTitle='Kill Switch'
                    title={props.data.displayRiskEntity}>
                    <NyForm
                        initialValues={props.data}
                        onSuccess={onSuccess}
                        onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <NyRadioButtonInput
                                    name='action'
                                    selectedValue='block'
                                    disabled={props.data.blockedKs === 'Y'}
                                    required>
                                    Block
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='action'
                                    selectedValue='unblock'
                                    disabled={
                                        !(
                                            props.data.blockedKs === 'Y' &&
                                            isBlockedByUser
                                        )
                                    }
                                    required>
                                    Unblock
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='action'
                                    selectedValue='non-auction-order'
                                    required>
                                    Cancel Non-Auction Orders
                                </NyRadioButtonInput>
                                <NyRadioButtonInput
                                    name='action'
                                    selectedValue='auction-order'
                                    required>
                                    Cancel Auction Orders
                                </NyRadioButtonInput>
                                {props.context.isOptionsMarket && (
                                    <NyRadioButtonInput
                                        name='action'
                                        selectedValue='gtc-order'
                                        required>
                                        Cancel GTC Orders
                                    </NyRadioButtonInput>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                component={NySubmitAlert}
                                hasCloseBtn
                            />
                            <GridDivider pt={0}/>
                            <Grid item xs={12}>
                                <NySubmitBtn className='space-right-1'/>
                                <NyResetBtn/>
                            </Grid>
                        </Grid>
                    </NyForm>
                </NyModal>
            </>
        );
    }
);
