import {AxiosError} from 'axios';
import {get} from 'lodash-es';
import {Store} from 'redux';

import environment from '../../configs/environment.config';
import {resetUser} from '../../stores/user/user.actions';
import * as messages from '../enums';
import * as errors from '../errors';

export function middlewareResponseErrorInterceptor(
    store: Store,
    error: AxiosError
): Promise<AxiosError> {
    const state = store.getState();

    const url = get(error, 'config.url', '');
    const baseURL = get(error, 'config.baseURL', '');

    const response = get(error, 'response', {
        data: {
            title: errors.defaultError.title,
            detail: errors.defaultError.detail,
            type: 'danger'
        },
        status: 400
    });

    if (response.status === 401 && !url.includes('/auth/refreshToken')) {
        store.dispatch(resetUser());

        return Promise.reject(errors.expiredAccessTokenError);
    }

    if (response.status === 403) {
        return Promise.reject(errors.unAuthorizedError);
    }

    if (response.status === 503) {
        return Promise.reject(errors.serviceUnavailableError(error.response.data.detailMessage, error?.request));
    }

    if (response.status === 504) {
        return Promise.reject(errors.timeoutError);
    }

    if (error.message === messages.AUTH_THRESHOLD) {
        return Promise.reject(errors.authThresholdError);
    }

    if ([messages.DEFAULT, messages.CANCELLED].includes(error.message)) {
        // jest requires you to specify expect.assertions whenever you are expecting to
        // to encounter a rejected promise; since we cannot determine this during integration testing,
        // we return a resolve promise.

        //@ts-ignore
        return environment.integrationEnv
            ? Promise.resolve({data: {}})
            : Promise.reject(errors.defaultError);
    }

    if (error.message === messages.PILLAR_OFFLINE) {
        return Promise.reject(
            errors.pillarOfflineError(
                get(state, 'platform.name', '').toLowerCase()
            )
        );
    }

    return Promise.reject(
        (url.includes('cdmmw') || baseURL.includes('cdmmw')) && response.status !== 500
            ? {
                title: error.response.data.message,
                detail: error.response.data.apiFieldErrors
                    ? error.response.data.apiFieldErrors
                        .map(({field, message}) => `${field} - ${message}`)
                        .join('\n')
                    : error.response.data.detailMessage,
                type: 'danger',
                request: error?.request
            }
            : errors.defaultError
    );
}
