import React from 'react';

export function pillarOfflineError(
    micName: string
): {title: string; detail: React.ReactNode; type: string} {
    return {
        title: 'The Pillar trading engine is currently offline',
        detail: (
            <span>
                Please see the&nbsp;
                <a
                    href={`https://www.nyse.com/markets/hours-calendars#${micName}`}
                    target='_blank'>
                    market hours schedule
                </a>
            </span>
        ),
        type: 'info'
    };
}
