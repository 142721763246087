import {events} from '../events';

export const pillarStatusReducer = {
    [events.PILLAR_STATUS_SUCCESS]: (
        state,
        {
            payload: {
                data: {data}
            }
        }
    ) => {
        const {status, details: {mic}} = data;
        return ({
            ...state,
            [mic]: status === 'UP'
        });
    },
    [events.PILLAR_STATUS_ERROR]: () => ({})
};
