import {get} from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {createDownloadAction} from '../stores/downloads/downloads.actions';
import useStore from './useStore.hook';

export default function useDownload(
    fileName: string,
    fileExtension: string
): {start(e?: React.SyntheticEvent): void; progress: number; pending: boolean} {
    const dispatch = useDispatch();

    const downloads = useStore('downloads');

    const downloadAction = useMemo(
        () => {
            return createDownloadAction({
                client: 'cdmMw',
                method: 'GET',
                url: `api/documents/${fileName}.${fileExtension}`
            });},
        []
    );

    const start = useCallback(() => {
        dispatch(
            downloadAction({
                fileName,
                fileExtension
            })
        );
    }, [dispatch, downloadAction, fileExtension, fileName]);

    const download = get(downloads, fileName, {
        error: false,
        isDownloading: false,
        progress: 0
    });

    return useMemo(
        () => ({
            pending: download.isDownloading,
            progress: download.progress,
            start
        }),
        [download.isDownloading, download.progress, start]
    );
}
