import {createHashHistory} from 'history';

import {activityReportColumnDefs} from '../configs/columnDefs/activityReport.columnDefs';
import {auctionsColumnDefs} from '../configs/columnDefs/auctions.columnDefs';
import {liquidityMetricsFirmColumnDefs} from '../configs/columnDefs/liquidityMetricsFirm.columnDefs';
import {liquidityMetricsMmidColumnDefs} from '../configs/columnDefs/liquidityMetricsMmid.columnDefs';
import {liquidityMetricsMpidColumnDefs} from '../configs/columnDefs/liquidityMetricsMpid.columnDefs';
import {marketAccessExceptionsColumnDefs} from '../configs/columnDefs/marketAccessExceptions.columnDefs';
import {myFirmMpidsColumnDefs} from '../configs/columnDefs/myFirmMpids.columnDefs';
import {mySessionsColumnDefs} from '../configs/columnDefs/mySessions.columnDefs';
import {myVolumesBrokerRankColumnDefs} from '../configs/columnDefs/myVolumesBrokerRank.columnDefs';
import {myVolumesCadvDayColumnDefs} from '../configs/columnDefs/myVolumesCadvDay.columnDefs';
import {myVolumesCadvMonthColumnDefs} from '../configs/columnDefs/myVolumesCadvMonth.columnDefs';
import {myVolumesSearchColumnDefs} from '../configs/columnDefs/myVolumesSearch.columnDefs';
import {myVolumesTradeCountsColumnDefs} from '../configs/columnDefs/myVolumesTradeCount.columnDefs';
import {opraEntriesColumnDefs} from '../configs/columnDefs/opraEntries.columnDefs';
import {opraSeqNumberColumnDefs} from '../configs/columnDefs/opraSeqNumber.columnDefs';
import {qctTradesColumnDefs} from '../configs/columnDefs/qctTrades.columnDefs';
import {quoteMetricsColumnDefs} from '../configs/columnDefs/quoteMetrics.columnDefs';
import {riskMonitorColumnDefs} from '../configs/columnDefs/riskMonitor.columnDefs';
import {riskMonitorV2ColumnDefs} from '../configs/columnDefs/riskMonitorV2.columnDefs';
import {riskSettingsColumnDefs} from '../configs/columnDefs/riskSettings.columnDefs';
import {topAuctionColumnDefs} from '../configs/columnDefs/topAuctions.columnDefs';
import {tradesColumnDefs} from '../configs/columnDefs/trades.columnDefs';
import {tradeSummaryAllocatedColumnDefs} from '../configs/columnDefs/tradeSummaryAllocated.columnDefs';
import {tradeSummaryFloorTradeColumnDefs} from '../configs/columnDefs/tradeSummaryFloorTrade.columnDefs';
import {tradeSummaryTransactionColumnDefs} from '../configs/columnDefs/tradeSummaryTransaction.columnDefs';
import {tradeSummaryTransactionHistoryColumnDefs} from '../configs/columnDefs/tradeSummaryTransactionHistory.columnDefs';
import {glossaryConfig} from '../configs/glossary.config';
import {activityReportReducer} from './activityReport/activityReport.reducer';
import {auctionsReducer} from './auctions/auctions.reducer';
import {columnDefsReducer} from './columnDefs/columnDefs.reducer';
import {
    coreSessionDefaultState,
    coreSessionReducer
} from './coreSession/coreSession.reducer';
import {downloadsReducer} from './downloads/downloads.reducer';
import {entitlementReducer} from './entitlement/entitlement.reducer';
import {liquidityMetricsReducer} from './liquidityMetrics/liquidityMetrics.reducer';
import {marketAccessExceptionsReducer} from './marketAccessExceptions/marketAccessExceptions.reducer';
import {mmTypesReducer} from './mmTypes/mmTypes.reducer';
import {myFirmMpidsReducer} from './myFirmMpids/myFirmMpids.reducer';
import {mySessionsReducer} from './mySessions/mySessions.reducer';
import {myVolumesBrokerRankReducer} from './myVolumes/myVolumesBrokerRank.reducer';
import {myVolumesCadvReducer} from './myVolumes/myVolumesCadv.reducer';
import {myVolumesFiltersReducer} from './myVolumes/myVolumesFilters.reducer';
import {myVolumesSearchReducer} from './myVolumes/myVolumesSearch.reducer';
import {myVolumesTradeCountReducer} from './myVolumes/myVolumesTradeCount.reducer';
import {opraEntriesReducer} from './opraEntries/opraEntries.reducer';
import {pillarStatusReducer} from './pillarStatus/pillarStatus.reducer';
import {quoteMetricsReducer} from './quoteMetrics/quoteMetrics.reducer';
import {riskMonitorAlertLimitReducer} from './riskMonitor/riskMonitor.AlertLimit.reducer';
import {riskMonitorBulkActionReducer} from './riskMonitor/riskMonitor.bulkAction.reducer';
import {riskMonitorReducer} from './riskMonitor/riskMonitor.reducer';
import {
    firms,
    menu,
    permissions,
    platform,
    platforms,
    roles,
    route,
    routes
} from './selectors';
import {symbolGroupsReducer} from './symbolGroups/symbolGroups.reducer';
import {createSyncStore} from './syncStore';
import {termsAndConditionsReducer} from './termsAndConditions/termsAndConditions.reducer';
import {topAuctionsReducer} from './topAuctions/topAuctions.reducer';
import {tradesReducer} from './trades/trades.reducer';
import {tradeSummaryReducer} from './tradeSummary/tradeSummary.reducer';
import {userReducer} from './user/user.reducer';

export const history = createHashHistory({
    hashType: 'slash'
});

export const store = createSyncStore(history, [
    {
        key: 'activityReport',
        reducer: activityReportReducer,
        defaultState: {
            error: false,
            isLoading: false,
            rowData: []
        },
        resetOnRouteChange: true
    },
    {
        key: 'auctions',
        reducer: auctionsReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'columnDefs',
        reducer: columnDefsReducer,
        defaultState: {
            activityReport: {
                default: activityReportColumnDefs
            },
            auctions: {
                default: auctionsColumnDefs
            },
            liquidityMetricsFirm: {
                default: liquidityMetricsFirmColumnDefs
            },
            liquidityMetricsMmid: {
                default: liquidityMetricsMmidColumnDefs
            },
            liquidityMetricsMpid: {
                default: liquidityMetricsMpidColumnDefs
            },
            marketAccessExceptions: {
                default: marketAccessExceptionsColumnDefs
            },
            myFirmMpids: {
                default: myFirmMpidsColumnDefs
            },
            mySessions: {
                default: mySessionsColumnDefs
            },
            myVolumesSearch: {
                default: myVolumesSearchColumnDefs
            },
            myVolumesBrokerRank: {
                default: myVolumesBrokerRankColumnDefs
            },
            myVolumesCadvMonth: {
                default: myVolumesCadvMonthColumnDefs
            },
            myVolumesCadvDay: {
                default: myVolumesCadvDayColumnDefs
            },
            myVolumesTradeCount: {
                default: myVolumesTradeCountsColumnDefs
            },
            opraEntries: {
                default: opraEntriesColumnDefs
            },
            opraSeqNumber: {
                default: opraSeqNumberColumnDefs
            },
            qctTrades: {
                default: qctTradesColumnDefs
            },
            quoteMetrics: {
                default: quoteMetricsColumnDefs
            },
            riskMonitor: {
                default: riskMonitorColumnDefs
            },
            riskMonitorV2: {
                default: riskMonitorV2ColumnDefs
            },
            riskSettings: {
                default: riskSettingsColumnDefs
            },
            trades: {
                default: tradesColumnDefs
            },
            tradeSummaryAllocated: {
                default: tradeSummaryAllocatedColumnDefs
            },
            tradeSummaryFloorTrade: {
                default: tradeSummaryFloorTradeColumnDefs
            },
            tradeSummaryTransaction: {
                default: tradeSummaryTransactionColumnDefs
            },
            tradeSummaryTransactionHistory: {
                default: tradeSummaryTransactionHistoryColumnDefs
            },
            topAuctions: {
                default: topAuctionColumnDefs
            }
        },
        excludes: ['default'],
        adapter: 'localStorage',
        stickyVersion: true
    },
    {
        key: 'coreSession',
        reducer: coreSessionReducer,
        defaultState: coreSessionDefaultState,
        resetOnRouteChange: true
    },
    {
        key: 'downloads',
        reducer: downloadsReducer,
        defaultState: {}
    },
    {
        key: 'entitlement',
        reducer: entitlementReducer,
        adapter: 'sessionStorage',
        defaultState: {
            name: ''
        }
    },
    {
        key: 'firms',
        selector: firms,
        defaultState: []
    },
    {
        key: 'glossary',
        reducer: {},
        defaultState: {
            ...glossaryConfig
        }
    },
    {
        key: 'liquidityMetrics',
        reducer: liquidityMetricsReducer,
        defaultState: {
            error: false,
            isLoading: false,
            pinnedTopRowData: [],
            rowData: []
        }
    },
    {
        key: 'menu',
        selector: menu,
        defaultState: []
    },
    {
        key: 'marketAccessExceptions',
        reducer: marketAccessExceptionsReducer,
        defaultState: []
    },
    {
        key: 'mmTypes',
        reducer: mmTypesReducer,
        defaultState: {
            options: []
        }
    },
    {
        key: 'myFirmMpids',
        reducer: myFirmMpidsReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'mySessions',
        reducer: mySessionsReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'myVolumesSearch',
        reducer: myVolumesSearchReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'myVolumesBrokerRank',
        reducer: myVolumesBrokerRankReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'myVolumesCadv',
        reducer: myVolumesCadvReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'myVolumesTradeCount',
        reducer: myVolumesTradeCountReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'myVolumesFilters',
        reducer: myVolumesFiltersReducer,
        defaultState: {
            brokerBadges: [],
            sessionIds: [],
            mmIds: []
        },
        resetOnRouteChange: true
    },
    {
        key: 'opraEntries',
        reducer: opraEntriesReducer,
        defaultState: {
            isLoading: false,
            rowData: []
        },
        resetOnRouteChange: true
    },
    {
        key: 'permissions',
        selector: permissions,
        priority: 1,
        defaultState: [],
        resetOnRouteChange: true
    },
    {
        key: 'pillarStatus',
        reducer: pillarStatusReducer,
        defaultState: {
            ARCX: true,
            XASE: true,
            XCIS: true,
            XNYS: true,
            XCHI: true,
            ARCO: true
        }
    },
    {
        key: 'platform',
        selector: platform,
        priority: 2,
        defaultState: {}
    },
    {
        key: 'platforms',
        selector: platforms,
        priority: 2,
        defaultState: {}
    },
    {
        key: 'quoteMetrics',
        reducer: quoteMetricsReducer,
        defaultState: {
            error: false,
            isLoading: false,
            rowData: []
        },
        resetOnRouteChange: true
    },
    {
        key: 'riskMonitor',
        reducer: riskMonitorReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'riskMonitorBulkAction',
        reducer: riskMonitorBulkActionReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'riskMonitorAlertLimit',
        reducer: riskMonitorAlertLimitReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'roles',
        selector: roles,
        defaultState: []
    },
    {
        key: 'route',
        priority: 3,
        selector: route,
        defaultState: {
            ...route()
        }
    },
    {
        key: 'routes',
        selector: routes,
        defaultState: []
    },
    {
        key: 'symbolGroups',
        reducer: symbolGroupsReducer,
        defaultState: {
            ADVISOR: {
                options: []
            },
            FUND_NAME: {
                options: []
            },
            INDEX_1: {
                options: []
            },
            INDEX_2: {
                options: []
            },
            ARCA_ISSUER: {
                options: []
            },
            TAPE: {
                options: []
            },
            TRUST_NAME: {
                options: []
            },
            isLoading: false,
            error: false
        }
    },
    {
        key: 'termsAndConditions',
        reducer: termsAndConditionsReducer,
        adapter: 'localStorage',
        stickyVersion: true,
        defaultState: {}
    },
    {
        key: 'topAuctions',
        reducer: topAuctionsReducer,
        defaultState: {},
        resetOnRouteChange: true
    },
    {
        key: 'trades',
        reducer: tradesReducer,
        defaultState: {
            download: {
                fileName: undefined,
                progress: undefined,
                error: undefined
            }
        },
        resetOnRouteChange: true
    },
    {
        key: 'tradeSummary',
        reducer: tradeSummaryReducer,
        defaultState: {
            download: {
                fileName: undefined,
                progress: undefined,
                error: undefined
            }
        },
        resetOnRouteChange: true
    },
    {
        key: 'user',
        reducer: userReducer,
        adapter: 'localStorage',
        defaultState: {
            isAuthPending: false,
            isJwtThreshold: false,
            isJwtValid: false,
            isLogoutPending: false,
            sentinel: {
                accessToken: '',
                refreshToken: ''
            },
            permissions: {}
        }
    }
]);
