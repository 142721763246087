import axios, {AxiosResponse} from 'axios';
import {cacheAdapterEnhancer} from 'axios-extensions';
import LRUCache from 'lru-cache';

import environment from '../../configs/environment.config';
import dedupeAdapter from '../adapters/dedupe.adapter';
import {cdmmwRequestInterceptor} from '../interceptors/cdmmw.requestInterceptor';
import {middlewareResponseErrorInterceptor} from '../interceptors/middleware.responseErrorInterceptor';
import {middlewareResponseInterceptor} from '../interceptors/middleware.responseInterceptor';
import qs from "qs";

const cdmMw = axios.create({
    baseURL: `${environment.baseURL}/cdmmw/`,
    adapter: cacheAdapterEnhancer(
        environment.testEnv ? axios.defaults.adapter : dedupeAdapter,
        {
            enabledByDefault: false,
            cacheFlag: 'cache',
            defaultCache: new LRUCache({
                maxAge: environment.cacheMaxAge
            })
        }
    ),
    headers: {
        common: {
            buildTime: environment.buildTime,
            versionNumber: environment.versionNumber
        }
    },
    paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'}),
    responseType: 'json'
});

cdmMw.interceptors.request.use((config) =>
    cdmmwRequestInterceptor(require('../../stores/index').store, config)
);

cdmMw.interceptors.response.use(
    (response) =>
        middlewareResponseInterceptor(
            require('../../stores/index').store,
            response
        ) as Promise<AxiosResponse>,
    (response) =>
        middlewareResponseErrorInterceptor(
            require('../../stores').store,
            response
        )
);

export default cdmMw;
