import axios from 'axios';
import {cacheAdapterEnhancer} from 'axios-extensions';
import LRUCache from 'lru-cache';

import environment from '../../configs/environment.config';
import dedupeAdapter from '../adapters/dedupe.adapter';
import {middlewareResponseErrorInterceptor} from '../interceptors/middleware.responseErrorInterceptor';
import {nodeRequestInterceptor} from '../interceptors/node.requestInterceptor';
import qs from "qs";

const nodeJs = axios.create({
    baseURL: environment.baseURL,
    adapter: cacheAdapterEnhancer(
        environment.testEnv ? axios.defaults.adapter : dedupeAdapter,
        {
            enabledByDefault: false,
            cacheFlag: 'cache',
            defaultCache: new LRUCache({
                maxAge: environment.cacheMaxAge
            })
        }
    ),
    headers: {
        common: {
            buildTime: environment.buildTime,
            versionNumber:  environment.versionNumber
        }
    },
    paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'}),
    responseType: 'json'
});

nodeJs.interceptors.request.use((config) =>
    nodeRequestInterceptor(require('../../stores/index').store, config)
);

nodeJs.interceptors.response.use(
    (response) => Promise.resolve(response),
    (response) =>
        middlewareResponseErrorInterceptor(
            require('../../stores/index').store,
            response
        )
);

export default nodeJs;
