import {ConnectedRouter} from 'connected-react-router';
import {AgGridStyle, NyThemeProvider} from 'nyse-web-tools-common/lib/react';
import React from 'react';
import {QueryClientProvider} from 'react-query';
import {Provider} from 'react-redux';

import environment from './configs/environment.config';
import {reactQueryClient} from './configs/reactQueryClient';
import App from './pages/app.page';
import {history, store} from './stores';

const Root: React.FC = () => (
    <NyThemeProvider>
        <AgGridStyle />
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <QueryClientProvider client={reactQueryClient}>
                    <App />
                </QueryClientProvider>
            </ConnectedRouter>
        </Provider>
    </NyThemeProvider>
);

export default environment.devEnv
    ? require('react-hot-loader/root').hot(Root)
    : Root;
