import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import {NyTooltip} from 'nyse-web-tools-common/lib/react';
import React from 'react';

export const HintErrorTooltip = (props) => (
    <NyTooltip
        overlay={
            <div className='definition-tooltip flex-column' style={{width: '400px'}}>
                <ul>
                    {
                        props.hintErrorMessage.map((e, idx) => <li key={`hintErrorTooltip_${idx}`}>{e}</li>)
                    }
                </ul>
            </div>
        }
        trigger={['hover']}
    >
        <ErrorSharpIcon
            color='error'
            style={{height: '100%'}}
        />
    </NyTooltip>
);

HintErrorTooltip.defaultProps = {
    hintErrorMessage: []
};
