import {
    custFirmChoices,
    openCloseChoices
} from '../../resources/tradeSummary.resource';
import {mapApiValue} from '../../utils/utilities';
export const tradeUnallocateColumnDefs = [
    {
        field: '_selected_',
        pinned: 'left',
        cellStyle: {
            background: 'white'
        },
        checkboxSelection: ({data}) => data,
        headerCheckboxSelection: true,
        suppressToolPanel: true,
        movable: false,
        resizable: false,
        suppressSizeToFit: true,
        sorting: false,
        headerName: '',
        minWidth: 50,
        width: 50
    },
    {
        field: 'execQty',
        headerName: 'Slice Quantity',
        type: 'number'
    },
    {
        field: 'dealNum',
        headerName: 'Deal Id'
    },
    {
        field: 'custFirmId',
        headerName: 'Customer side: MPID'
    },
    {
        field: 'custOpenClose',
        headerName: 'Customer side: Open/Close',
        valueGetter: ({data}) =>
            mapApiValue(data, 'custOpenClose', openCloseChoices)
    },
    {
        field: 'custFirm',
        headerName: 'Customer side: Customer or Firm',
        valueGetter: ({data}) => mapApiValue(data, 'custFirm', custFirmChoices)
    },
    {
        field: 'custSndrSubId',
        headerName: 'Customer side: MMID'
    },
    {
        field: 'custClearingNumber',
        headerName: 'Customer side: Clearing #'
    },
    {
        field: 'custCapStrategyId',
        headerName: 'Customer side: Cap Strategy'
    },
    {
        field: 'custSubmittingBkrId',
        headerName: 'Customer side: Badge'
    },
    {
        field: 'custCmta',
        headerName: 'Customer side: CMTA'
    },
    {
        field: 'custAccount',
        headerName: 'Customer side: Account'
    },
    {
        field: 'custOptnlData',
        headerName: 'Customer side: Optional Data'
    },
    {
        field: 'custSide',
        headerName: 'Customer side: Side'
    },
    {
        field: 'contraOrdFirmId',
        headerName: 'Contra side: MPID'
    },
    {
        field: 'contraOrdOpenClose',
        headerName: 'Contra side: Open/Close',
        valueGetter: ({data}) =>
            mapApiValue(data, 'contraOrdOpenClose', openCloseChoices)
    },
    {
        field: 'contraOrdCustFirm',
        headerName: 'Contra side: Customer or Firm',
        valueGetter: ({data}) =>
            mapApiValue(data, 'contraOrdCustFirm', custFirmChoices)
    },
    {
        field: 'contraOrdSndrSubId',
        headerName: 'Contra side: MMID'
    },
    {
        field: 'contraOrdClearingNumber',
        headerName: 'Contra side: Clearing #'
    },
    {
        field: 'contraOrdCapStrategyId',
        headerName: 'Contra side: Cap Strategy'
    },
    {
        field: 'contraOrdSubmittingBkrId',
        headerName: 'Contra side: Badge'
    },
    {
        field: 'contraOrdCmta',
        headerName: 'Contra side: CMTA'
    },
    {
        field: 'contraOrdAccount',
        headerName: 'Contra side: Account'
    },
    {
        field: 'contraOrdOptnlData',
        headerName: 'Contra side: Optional Data'
    }
];
