import Divider from '@material-ui/core/Divider';
import {pick} from 'lodash-es';
import {NyForm} from 'nyse-web-tools-common/lib/react';
import {NyTextInput} from 'nyse-web-tools-common/lib/react';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useMemo, useState} from 'react';

import {useEntitlement} from '../../../../hooks/useEntitlement';
import useResource from '../../../../hooks/useResource.hook';
import {useSndrSubIdOptions} from '../../../../hooks/useSndrSubIdOptions';
import {useSubmittingBkrIdOptions} from '../../../../hooks/useSubmittingBkrIdOptions';
import useUser from '../../../../hooks/useUser.hook';
import {
    CustFirm,
    Entitlement,
    SideTradeType,
    TradeSummaryProps,
    TradeSummaryTabs,
    contraBothSideFields,
    contraSideFields,
    customerSideFields,
    myOwnership,
    requiredFields,
    tradeAdjustmentResource
} from '../../../../resources/tradeSummary.resource';
import {
    viewGBOBrokerFirmsResource,
    viewGBOFirmsResource,
    viewGBOMarketMakerFirmsResource
} from '../../../../resources/viewGBO.resource';
import {cleanObject} from '../../../../utils/utilities';
import {Link} from '../../../navigation/navigation.link';
import {ReasonCodeSelect} from '../../../selects/reasonCode.select';
import {ContraSideSection} from '../sections/contraSide.section';
import {CustomerSideSection} from '../sections/customerSide.section';
import usePlatform from '../../../../hooks/usePlatform';

export const TradeAdjustmentForm: React.FC<TradeSummaryProps> = React.memo(
    (props) => {
        const isCustFloorBroker =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER;
        const isCustFloorBrokerOnBehalfOfLocalMM =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isCustFloorBrokerOnBehalfOfAwayMM =
            props.data.custTradeType === SideTradeType.FLOOR_BROKER_AWAY_MM;

        const isContraOrdFloorBroker =
            props.data.contraOrdTradeType === SideTradeType.FLOOR_BROKER;
        const isContraOrdFloorBrokerOnBehalfOfLocalMM =
            props.data.contraOrdTradeType ===
            SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isContraOrdFloorBrokerOnBehalfOfAwayMM =
            props.data.contraOrdTradeType ===
            SideTradeType.FLOOR_BROKER_AWAY_MM;

        const user = useUser();
        const entitlement = useEntitlement() || user.entitlement;

        const isCustFloorTrade =
            isCustFloorBroker ||
            isCustFloorBrokerOnBehalfOfAwayMM ||
            isCustFloorBrokerOnBehalfOfLocalMM;
        const isContraOrdFloorTrade =
            isContraOrdFloorBroker ||
            isContraOrdFloorBrokerOnBehalfOfAwayMM ||
            isContraOrdFloorBrokerOnBehalfOfLocalMM;
        const isBrokerFirm = entitlement === Entitlement.BROKER_FIRM;

        const [formSubmitted, setFormSubmitted] = useState(false);
        const [reasonCode, setReasonCode] = useState('');
        const [custCrdUsingCustFirmId, setCustCrdUsingCustFirmId] = useState();
        const [
            contraOrdCrdUsingContraOrdFirmId,
            setContraOrdCrdUsingContraOrdFirmId
        ] = useState();
        const resource = useResource(tradeAdjustmentResource);
        const gboResourceParams = {
            request: {
                mpidStatus: 'Active',
                mic: usePlatform(),
            }
        };
        const brokerFirmsResource = useResource(
            viewGBOBrokerFirmsResource,
            gboResourceParams
        );
        const firmsResource = useResource(
            viewGBOFirmsResource,
            gboResourceParams
        );
        const marketMakerFirmsResource = useResource(
            viewGBOMarketMakerFirmsResource,
            gboResourceParams
        );
        const allSndrSubIdOptions = useMemo(
            () => marketMakerFirmsResource.data,
            [marketMakerFirmsResource.data]
        );
        const allFirms = useMemo(() => firmsResource.data, [
            firmsResource.data
        ]);
        const allSubmittingBkrIdOptions = useMemo(
            () => brokerFirmsResource.data,
            [brokerFirmsResource.data]
        );
        const custSubmittingBkrIdOptions =
            entitlement === Entitlement.TRADE_CHECKER
                ? allSubmittingBkrIdOptions.filter(
                      (brokerIdOption) =>
                          brokerIdOption.firmId === props.data.custOmsId
                  )
                : useSubmittingBkrIdOptions({
                      sideCrd: props.data.custOmsCrd,
                      firmId: props.data.custOmsId,
                      tradeType: props.data.custTradeType,
                      submittingBkrId: props.data.custSubmittingBkrId
                  });
        const contraOrdSubmittingBkrIdOptions =
            entitlement === Entitlement.TRADE_CHECKER
                ? allSubmittingBkrIdOptions.filter(
                      (brokerIdOption) =>
                          brokerIdOption.firmId === props.data.contraOrdOmsId
                  )
                : useSubmittingBkrIdOptions({
                      sideCrd: props.data.contraOrdOmsCrd,
                      firmId: props.data.contraOrdOmsId,
                      tradeType: props.data.contraOrdTradeType,
                      submittingBkrId: props.data.contraOrdSubmittingBkrId
                  });
        const custSndrSubIdOptions = useSndrSubIdOptions({
            sideCrd: props.data.custCrd,
            firmId: props.data.custFirmId,
            tradeType: props.data.custTradeType,
            sndrSubId: props.data.custSndrSubId,
            csgCustFirm: props.csgData.custSndrSubId
        });
        const contraOrdSndrSubIdOptions = useSndrSubIdOptions({
            sideCrd: props.data.contraOrdCrd,
            firmId: props.data.contraOrdFirmId,
            tradeType: props.data.contraOrdTradeType,
            sndrSubId: props.data.contraOrdSndrSubId,
            csgCustFirm: props.csgData.contraOrdSndrSubId
        });

        const initialValues = useMemo(
            () => ({
                ...props.data,
                reasonCode: 'Non-Contractual Field Change'
            }),
            [props.data]
        );

        const onSubmit = useCallback(
            ({reasonCode, otherReason, ...values}) =>
                resource.put(
                    cleanObject({
                        crd: props.crd,
                        ...pick(values, [
                            ...requiredFields,
                            ...customerSideFields,
                            ...contraSideFields,
                            ...(values.selectedSide === myOwnership.BOTH
                                ? contraBothSideFields
                                : [])
                        ]),
                        ...(reasonCode === 'Other'
                            ? {reasonCode: otherReason}
                            : {reasonCode: reasonCode})
                    }),
                    {
                        origTdate: props.data.origTdate,
                        evtTyp: props.data.evtTyp,
                        dealNum: props.data.dealNum
                    }
                ),
            [
                resource,
                props.crd,
                props.data.origTdate,
                props.data.evtTyp,
                props.data.dealNum
            ]
        );

        const onReasonCodeChange = useCallback(({value}) => {
            setReasonCode(value);
        }, []);

        const onSuccess = useCallback(() => {
            setFormSubmitted(() => true);
        }, []);

        const isFormDisabled =
            formSubmitted ||
            props.data.updateToTradeIsInProgress ||
            !props.data.tradeCanBeUpdated;

        const onFormChange = useCallback(
            (values, {setFieldValue}) => {
                const custFirmIdUsingSubmittingBkrId = allSubmittingBkrIdOptions.find(
                    (custSubmittingBkrIdOption) =>
                        custSubmittingBkrIdOption.value ===
                        values.custSubmittingBkrId
                )?.firmId;
                const contraOrdFirmIdUsingSubmittingBkrId = allSubmittingBkrIdOptions.find(
                    (contraOrdSubmittingBkrIdOption) =>
                        contraOrdSubmittingBkrIdOption.value ===
                        values.contraOrdSubmittingBkrId
                )?.firmId;
                const custCrdUsingCustFirmId = allFirms.find(
                    (firmId) => firmId.value === values.custFirmId
                )?.crd;
                const contraOrdCrdUsingContraOrdFirmId = allFirms.find(
                    (firmId) => firmId.value === values.contraOrdFirmId
                )?.crd;

                if (isBrokerFirm || isCustFloorTrade) {
                    if (custFirmIdUsingSubmittingBkrId) {
                        setFieldValue(
                            'custFirmId',
                            custFirmIdUsingSubmittingBkrId
                        );
                    }
                }
                if (isBrokerFirm || isContraOrdFloorTrade) {
                    if (contraOrdFirmIdUsingSubmittingBkrId) {
                        setFieldValue(
                            'contraOrdFirmId',
                            contraOrdFirmIdUsingSubmittingBkrId
                        );
                    }
                }
            },
            [
                allSubmittingBkrIdOptions,
                allSndrSubIdOptions,
                isBrokerFirm,
                isContraOrdFloorTrade,
                isCustFloorTrade
            ]
        );
        return (
            <NyForm
                initialValues={initialValues}
                className={props.className}
                onChange={onFormChange}
                successMessage={
                    <Link
                        to='/:platform/trade-summary/:view'
                        params={{
                            view: 'transactions'
                        }}
                        search={`?crd=${props.csgData.crd}&origTdate=${props.csgData.origTdate}&dealIdOrig=${props.csgData.dealIdOrig}`}>
                        Click here to see Transactions
                    </Link>
                }
                onSuccess={onSuccess}
                onSubmit={onSubmit}>
                <div className='row flex-0-0'>
                    <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                        <label>(Change) Reason Code</label>
                        <ReasonCodeSelect
                            name='reasonCode'
                            resetFields={['otherReason']}
                            onChange={onReasonCodeChange}
                            disabled={isFormDisabled}
                            required
                        />
                    </div>
                    {reasonCode === 'Other' && (
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>Other Reason</label>
                            <NyTextInput name='otherReason' required />
                        </div>
                    )}
                </div>
                <CustomerSideSection
                    currentTab={TradeSummaryTabs.adjust}
                    custCrd={props.data.custCrd}
                    custOmsCrd={props.data.custOmsCrd}
                    custCrdUsingCustFirmId={custCrdUsingCustFirmId}
                    custFirmId={props.data.custFirmId}
                    custFirm={props.data.custFirm}
                    custOmsId={props.data.custOmsId}
                    csgCustFirm={props.csgData.custFirm}
                    custTradeType={props.data.custTradeType}
                    custClearingNumber={props.data.custClearingNumber}
                    qccTrade={props.data.qccTrade}
                    outcryTrade={props.data.outcryTrade}
                    custSubmittingBkrId={props.data.custSubmittingBkrId}
                    custSubmittingBkrIdOptions={custSubmittingBkrIdOptions}
                    custSndrSubIdOptions={
                        entitlement === Entitlement.MM_FIRM
                            ? custSndrSubIdOptions
                            : allSndrSubIdOptions
                    }
                    custSide={props.data.custSide}
                    selectedSide={props.data.selectedSide}
                    disabled={isFormDisabled}
                    isMultiAdjustment={false}
                    isMultiEditForAllFields={false}
                />
                <ContraSideSection
                    currentTab={TradeSummaryTabs.adjust}
                    contraOrdCrd={props.data.contraOrdCrd}
                    contraOrdOmsCrd={props.data.contraOrdOmsCrd}
                    contraOrdCrdUsingContraOrdFirmId={
                        contraOrdCrdUsingContraOrdFirmId
                    }
                    contraOrdFirmId={props.data.contraOrdFirmId}
                    contraOrdOmsId={props.data.contraOrdOmsId}
                    csgContraOrdCustFirm={props.csgData.contraOrdCustFirm}
                    contraOrdCustFirm={props.data.contraOrdCustFirm}
                    contraOrdClearingNumber={props.data.contraOrdClearingNumber}
                    selectedSide={props.data.selectedSide}
                    contraOrdTradeType={props.data.contraOrdTradeType}
                    qccTrade={props.data.qccTrade}
                    outcryTrade={props.data.outcryTrade}
                    contraOrdSubmittingBkrIdOptions={
                        contraOrdSubmittingBkrIdOptions
                    }
                    contraOrdSndrSubIdOptions={
                        entitlement === Entitlement.MM_FIRM
                            ? contraOrdSndrSubIdOptions
                            : allSndrSubIdOptions
                    }
                    contraOrdSide={props.data.contraOrdSide || ('-' + props.data.custSide)}
                    disabled={isFormDisabled}
                />
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <NySubmitAlert hasCloseBtn />
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <Divider />
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <NySubmitBtn
                        disabled={isFormDisabled}
                        data-e2e-tag='submitBtn'
                    />
                    {/* todo:: use disable prop instead of hide after upgrade */}
                    {!isFormDisabled && <NyResetBtn />}
                </div>
            </NyForm>
        );
    }
);
