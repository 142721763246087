import {LocalDateTime, ZoneId} from '@js-joda/core';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyAlert from 'nyse-web-tools-common/lib/react/generic/NyAlert/NyAlert';
import NyLoader from 'nyse-web-tools-common/lib/react/generic/NyLoader/NyLoader';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import React, {useState} from 'react';

import {useActionBlockTime} from '../../../hooks/useEodProcess.hook';
import useResource from '../../../hooks/useResource.hook';
import {tradeActionBlockTimeResource} from '../../../resources/eodProcessing.resource';
import {BlockTimeForm} from './blockTime.form';

export const BlockTimeTab = () => {
    const resource = useResource(tradeActionBlockTimeResource);
    const [isBefore, setIsBefore] = useState(false);
    const time = useActionBlockTime();

    const onSuccess = () => resource.get();

    const onSubmit = ({time}) => {
        if (
            LocalDateTime.now(ZoneId.of('America/New_York')).compareTo(time) < 0
        ) {
            setIsBefore(false);
            return resource.put({
                time: joda.format(time, 'HH:mm:ss')
            });
        }
        setIsBefore(true);
    };

    if (time.isError) {
        return (
            <NyAlert
                show
                type='danger'
                detail={time.error.detail}
                title={time.error.title}
            />
        );
    }

    return time.isFetchedAfterMount ? (
        <NyForm onSuccess={onSuccess} onSubmit={onSubmit}>
            <BlockTimeForm isBefore={isBefore} time={time.data} />
        </NyForm>
    ) : (
        <NyLoader show />
    );
};
