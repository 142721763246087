import {useFormikContext} from 'formik';
import {NySelectInput, NyTextInput} from 'nyse-web-tools-common/lib/react';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import type {NyFieldInput} from 'nyse-web-tools-common/lib/react/types';
import React, {useMemo, useState} from 'react';

import {useEntitlement} from '../../hooks/useEntitlement';
import {useRouteEntitlement} from '../../hooks/useRouteEntitlement';
import useUser from '../../hooks/useUser.hook';
import useUserFirms from '../../hooks/useUserFirms';
import type {Entitlement} from '../../types';

type BadgeIdSelectProps = {
    crd: number;
    entitlements?: Entitlement[];
    disableInactiveControl?: boolean;
    hideEtpid?: boolean;
    initialValue?: string;
    isMulti?: boolean;
    showAll?: boolean;
    showInactive?: boolean;
} & Partial<NyFieldInput>;

export const BadgeIdSelect = React.memo<BadgeIdSelectProps>((props) => {
    const userFirms = useUserFirms();
    const formik = useFormikContext();
    const entitlement = useEntitlement();
    const routeEntitlement = useRouteEntitlement();
    const user = useUser();

    const firms = useMemo(
        () =>
            (userFirms.data ?? []).filter(
                ({active}) => props.showInactive || active
            ),
        [userFirms.data, props.showInactive]
    );

    const entitlements = useMemo(() => props.entitlements || ['BROKER_FIRM'], [
        props.entitlements
    ]);

    const hasEntitlement = useMemo(
        () =>
            !routeEntitlement.length ||
            entitlements.includes(entitlement) ||
            entitlements.includes(user.entitlement),
        [entitlement, entitlements, user.entitlement, routeEntitlement]
    );

    const [sortBy] = useState([
        ({label}) => label.includes('Inactive'),
        ({label}) => label.toLowerCase()
    ]);

    const participants = useMemo(
        () => firms.find(({crd}) => crd === props.crd)?.participants ?? [],
        [firms, props.crd]
    );

    const options = useMemo(
        () => [
            ...(props.isMulti || props.required
                ? []
                : [
                      {
                          label: '-',
                          value: '',
                          props: {
                              active: true
                          }
                      }
                  ]),
            ...participants.reduce(
                (acc, {badges = []}) => [
                    ...acc,
                    ...badges.map((badge) => ({
                        label: badge,
                        value: badge,
                        props: badge
                    }))
                ],
                []
            )
        ],
        [participants, props.isMulti, props.required]
    );

    useMountedEffect(() => {
        formik.setFieldValue(
            props.name,
            props.initialValue || (props.isMulti && hasEntitlement ? [] : '')
        );
    }, [hasEntitlement, props.crd, props.isMulti]);

    if (hasEntitlement || Array.isArray(formik.values[props.name])) {
        return (
            <NySelectInput
                disabled={props.disabled}
                initialValue={props.initialValue}
                isLoading={userFirms.isFetching}
                isMulti={props.isMulti}
                name={props.name}
                onChange={props.onChange}
                options={options}
                required={props.required}
                sortBy={sortBy}
            />
        );
    }

    return <NyTextInput {...props} name={props.name} />;
});
