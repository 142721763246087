import {LocalDateTime} from '@js-joda/core';
import {get} from 'lodash-es';

import {events} from '../events';

export const activityReportReducer = {
    [events.ACTIVITY_REPORT_GET]: (state) => ({
        ...state,
        error: false,
        isLoading: true,
        rowData: []
    }),
    [events.ACTIVITY_REPORT_SUCCESS]: (state, {payload: {data}}) => ({
        ...state,
        isLoading: false,
        rowData: get(data, 'data.deskLogDetails', []),
        timestamp: LocalDateTime.now(),
        more: data.data.more,
        count: data.data.totalCount
    }),
    [events.ACTIVITY_REPORT_ERROR]: (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
        rowData: [],
        timestamp: LocalDateTime.now(),
        more: false,
        count: 0
    })
};
