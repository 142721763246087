import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, {useCallback, useMemo} from 'react';

import useStore from '../../hooks/useStore.hook';

type TabMenuProps = {
    permissions?: string;
    className?: string;
    tabs: {
        label: string;
        value: string;
        permissions?: string[];
    }[];
    onChange(tab: string): void;
    value: string;
};

const useStyles = makeStyles((theme) => ({
    tab: {
        marginRight: theme.spacing(2),
        minWidth: 'unset',
        paddingLeft: 0,
        paddingRight: 0
    }
}));

export const TabMenu: React.FC<TabMenuProps> = React.memo((props) => {
    const classes = useStyles(props);
    const userPermissions = useStore('permissions');

    const permissions = useMemo(
        () => [...userPermissions, ...(props.permissions || [])],
        [props.permissions, userPermissions]
    );

    const onTabChange = useCallback((e, value) => props.onChange(value), [
        props.onChange
    ]);

    const tabs = useMemo(() => {
        return props.tabs.filter((tab) => {
            return (
                !tab.permissions ||
                tab.permissions.every((p) => permissions.includes(p))
            );
        });
    }, [permissions, props.tabs]);

    return (
        <Tabs
            className={props.className}
            indicatorColor='secondary'
            onChange={onTabChange}
            textColor='secondary'
            value={props.value}>
            {tabs.map((option) => (
                <Tab
                    data-e2e-tag={option.value}
                    key={option.value}
                    className={classes.tab}
                    label={option.label}
                    value={option.value}
                />
            ))}
        </Tabs>
    );
});
