import NyButton from 'nyse-web-tools-common/lib/react/generic/NyButton/NyButton';
import exportJSONToXLSX from 'nyse-web-tools-common/lib/utils/xlsx';
import React, {useCallback, useMemo} from 'react';

import {SubAccount} from '../../../resources/subAccount.resource';

export const SubAccountContactExportBtn: React.FC<{
    subAccounts: SubAccount[];
}> = React.memo((props) => {
    const onClick = useCallback(() => {
        exportJSONToXLSX({
            fileName: 'contacts',
            columns: [
                {
                    field: 'subAccountId',
                    title: 'SubAccount Id',
                    width: 100
                },
                {
                    field: 'mpId',
                    title: 'MPID',
                    width: 100
                },
                {
                    field: 'tradingAccount',
                    title: 'Trading Account',
                    width: 100
                },
                {
                    field: 'name',
                    title: 'Sub Account',
                    width: 150
                },
                {
                    field: 'subAccountCreatedBy',
                    title: 'SubAccount Created By',
                    width: 150
                },
                {
                    field: 'subAccountCreatedTime',
                    title: 'SubAccount Created Time',
                    width: 150
                },
                {
                    field: 'subAccountModifiedBy',
                    title: 'SubAccount Modified By',
                    width: 150
                },
                {
                    field: 'subAccountModifiedTime',
                    title: 'SubAccount Modified Time',
                    width: 150
                },
                {
                    field: 'contactId',
                    title: 'Sub Acc Contact Id',
                    width: 100
                },
                {
                    field: 'firstName',
                    title: 'First Name',
                    width: 125
                },
                {
                    field: 'lastName',
                    title: 'Last Name',
                    width: 125
                },
                {
                    field: 'contactCreatedBy',
                    title: 'Contact Created By',
                    width: 150
                },
                {
                    field: 'contactCreatedTime',
                    title: 'Contact Created Time',
                    width: 150
                },
                {
                    field: 'contactModifiedBy',
                    title: 'Contact Modified By',
                    width: 150
                },
                {
                    field: 'contactModifiedTime',
                    title: 'Contact Modified Time',
                    width: 150
                }
            ],
            data: props.subAccounts.reduce(
                (acc, {contacts, ...subAccount}) => [
                    ...acc,
                    ...(contacts
                        ? contacts.map((contact) => ({
                              ...subAccount,
                              ...contact,
                              contactCreatedBy: contact.createdBy,
                              contactCreatedTime: contact.createdTime,
                              contactModifiedBy: contact.modifiedBy,
                              contactModifiedTime: contact.modifiedTime,
                              subAccountCreatedBy: subAccount.createdBy,
                              subAccountCreatedTime: subAccount.createdTime,
                              subAccountModifiedBy: subAccount.modifiedBy,
                              subAccountModifiedTime: subAccount.modifiedTime
                          }))
                        : [
                              {
                                  ...subAccount,
                                  subAccountCreatedBy: subAccount.createdBy,
                                  subAccountCreatedTime: subAccount.createdTime,
                                  subAccountModifiedBy: subAccount.modifiedBy,
                                  subAccountModifiedTime:
                                      subAccount.modifiedTime
                              }
                          ])
                ],
                []
            )
        });
    }, [props.subAccounts]);

    const enabled = useMemo(
        () => props.subAccounts.some(({contacts}) => contacts?.length),
        [props.subAccounts]
    );

    return (
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <NyButton
                link
                data-e2e-tag='contactsExportBtn'
                className='width-full'
                disabled={!enabled}
                onClick={onClick}
                type='button'>
                Export Contacts
            </NyButton>
        </div>
    );
});
