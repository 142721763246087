export const platformConfigs = [
    {
        mic: 'ARCX',
        name: 'Arca',
        confirmText: 'ARCA',
        type: 'Equities'
    },
    {
        mic: 'XASE',
        name: 'American',
        confirmText: 'AMERICAN',
        type: 'Equities'
    },
    {
        mic: 'XCIS',
        name: 'National',
        confirmText: 'NATIONAL',
        type: 'Equities'
    },
    {
        mic: 'XNYS',
        name: 'NYSE',
        confirmText: 'NYSE',
        type: 'Equities'
    },
    {
        mic: 'XCHI',
        name: 'Chicago',
        confirmText: 'CHICAGO',
        type: 'Equities'
    },
    {
        mic: 'ARCO',
        name: 'Arca Options',
        confirmText: 'ARCO',
        type: 'Options'
    },
    {
        mic: 'AMXO',
        name: 'American Options',
        confirmText: 'AMXO',
        type: 'Options'
    }
];