import {get, groupBy, isEmpty, isFunction, orderBy, sortBy} from 'lodash-es';
import pathToRegex from 'path-to-regexp/index';
import qs from 'qs';
import {matchPath} from 'react-router';
import {createSelector} from 'reselect';

import {platformConfigs} from '../configs/platforms.config';
import {routesConfig} from '../configs/routes.config';
import {events} from './events';

export const allPermissions = (state) => get(state, 'user.permissions', {});

export const routeChanged = () => ({
    type: events._SYNC_ROUTE_
});

export const platforms = createSelector(allPermissions, (permissions) =>
    orderBy(
        Object.keys(permissions).map((platform) =>
            platformConfigs.find(({mic}) => mic === platform)
        ),
        'name'
    )
);

export const routes = createSelector(
    allPermissions,
    platforms,
    (permissions, platforms) =>
        routesConfig.filter((route) =>
            route.permissions
                ? platforms.some(({mic}) =>
                      route.permissions[
                          route.strict ? 'every' : 'some'
                      ]((perm) =>
                          (permissions[mic].clientResourceNames || []).includes(
                              perm
                          )
                      )
                  )
                : route
        )
);

export const route = () => {
    const path = window.location.hash.replace(/#/, '').split('?')[0];
    const routeParams =
        window.location.hash.replace(/#/, '').split('?')[1] || '';
    const route = routesConfig.find((r) =>
        matchPath(path, {...r, path: r.url})
    ) || {
        url: path
    };

    const pathParams = pathToRegex
        .parse(route.url)
        .filter((part) => typeof part === 'object');
    const pathParamValues = Array.from(
        pathToRegex(route.url).exec(path)
    ).filter((p, i) => i !== 0);
    const queryParams = routeParams
        ? qs.parse(routeParams, {arrayFormat: 'repeat'})
        : {};

    const params = {
        ...(route.parseParams ? route.parseParams(queryParams) : queryParams),
        ...pathParams.reduce(
            (acc, {name}, i) => ({
                ...acc,
                [name]: pathParamValues[i]
            }),
            {}
        )
    };

    return {
        ...route,
        glossary: isFunction(route.glossary)
            ? route.glossary(params)
            : route.glossary,
        title: isFunction(route.title) ? route.title(params) : route.title,
        url: path,
        path: route ? route.url : path,
        platform:
            route && route.url.includes(':platform')
                ? pathToRegex(route.url).exec(path)[1]
                : '',
        params
    };
};

export const menu = createSelector(
    allPermissions,
    platforms,
    (permissions, platforms) =>
        platforms
            .reduce((platformMenu, platform) => {
                const routes = routesConfig.filter((route) =>
                    route.permissions
                        ? route.permissions[
                              route.strict ? 'every' : 'some'
                          ]((perm) =>
                              get(
                                  permissions[platform.mic],
                                  'clientResourceNames',
                                  []
                              ).includes(perm)
                          )
                        : route.group && route.title
                        ? route
                        : null
                ).filter((route)=> route?.platforms
                    ? route.platforms.includes(platform.mic)
                    : true);

                const groups = groupBy(sortBy(routes, 'group'), 'group');

                return [
                    ...platformMenu,
                    {
                        title: platform.name,
                        mic: platform.mic,
                        menu: Object.keys(groups).reduce(
                            (groupMenu, key) => [
                                ...groupMenu,
                                {
                                    title: key,
                                    mic: platform.mic,
                                    menu: sortBy(groups[key], 'title')
                                }
                            ],
                            []
                        )
                    }
                ];
            }, [])
            .filter(({menu}) => menu.length > 0)
);

export const permissions = createSelector(
    allPermissions,
    route,
    (permissions, route) =>
        route.platform
            ? get(
                  permissions[route.platform.toUpperCase()],
                  'clientResourceNames',
                  []
              )
            : []
);

export const platform = createSelector(
    allPermissions,
    route,
    platforms,
    (permissions, route, platforms) => {
        const hasPermissions = !isEmpty(permissions);

        // if no permissions, or no permissions and platform is defined
        if (!hasPermissions || (!hasPermissions && !route.platform)) {
            return {};
        }

        // user has permissions, but no platform is set yet,
        if (hasPermissions && !route.platform) {
            return platforms[0];
        }

        if (hasPermissions && route.platform) {
            return (
                platforms.find(
                    ({mic}) => route.platform.toUpperCase() === mic
                ) || {}
            );
        }

        return {};
    }
);

export const roles = createSelector(
    allPermissions,
    route,
    (permissions, route) =>
        route.platform
            ? get(permissions[route.platform.toUpperCase()], 'roleName', [])
            : []
);

export const firms = createSelector(
    allPermissions,
    route,
    (permissions, route) =>
        route.platform
            ? sortBy(
                  get(
                      permissions[route.platform.toUpperCase()],
                      'firmAttributes',
                      []
                  ),
                  'name'
              )
            : []
);
