import cn from 'classnames';
import {orderBy} from 'lodash-es';
import {NyAccordion} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {Link} from './navigation.link';

@connect(['platform', 'menu', 'router', 'user'])
export class NavigationMenuMobile extends React.Component {
    state = {
        activeMenu: '',
        activeState: '',
        showBanner: false,
        showSideNav: false
    };

    static getDerivedStateFromProps(props, state) {
        const didStateChange =
            props.router.location.pathname !== state.activeState;

        return {
            activeMenu: didStateChange ? props.platform.mic : state.activeMenu,
            activeState: props.router.location.pathname,
            showSideNav: didStateChange ? false : state.showSideNav
        };
    }

    componentDidUpdate() {
        const $link = document.querySelector('.sub-link.active');

        if ($link) {
            $link.scrollIntoView();
        }
    }

    onSetState = ({
        currentTarget: {
            dataset: {title}
        }
    }) => {
        this.setState({
            activeMenu: title
        });
    };

    toggleSideNav = () => {
        this.setState({
            showSideNav: !this.state.showSideNav
        });
    };

    render() {
        const showClass = cn({show: this.state.showSideNav});

        return (
            <>
                <div
                    className={`mobile-nav-overlay ${showClass}`}
                    onClick={this.toggleSideNav}
                />
                <ul className={`mobile-nav-header visible-xs ${showClass}`}>
                    <li className='nav-mobile-left'>
                        <a href='https://www.nyse.com'>
                            <svg role='img' viewBox='0 0 377.48 149.42' height='20' className='inline-block text-black'>
                                <title>NYSE Logo</title>
                                {/* eslint-disable */}
                                <polygon fill='white'
                                    points='52.33 121.54 14.26 68.42 0 68.42 0 147.48 15.23 147.48 15.23 94.32 53.3 147.48 67.56 147.48 67.56 68.42 52.33 68.42 52.33 121.54'></polygon>
                                <polygon fill='white'
                                    points='155.8 68.42 137.33 68.42 117.28 101.09 96.66 68.42 77.22 68.42 108 115.41 108 147.48 124.53 147.48 124.53 115.62 155.8 68.42'></polygon>
                                <path fill='white'
                                    d='M199.1,101.2l-9.25-2.11C179,96.66,176.7,94.71,176.7,90c0-4.55,4.7-8.77,13.63-8.77a32.48,32.48,0,0,1,21.6,7.8l9.58-12.67c-8.93-7.3-20.3-9.9-30.53-9.9-18.51,0-31.5,10.88-31.5,25.33,0,11.36,7.64,18.67,23.22,21.75l9.1,1.79c10.55,2.11,14.44,6,14.44,10.55,0,6.33-5.35,9.26-14.28,9.42-8.61.16-16.89-2.92-24.36-10.55l-9.9,12c7.63,8.77,21.11,12.67,33.77,12.67,19.32,0,31.34-9.42,31.34-24.68C222.81,111.6,214.53,104.78,199.1,101.2Z'></path>
                                <path fill='white'
                                    d='M249.56,114.92c2.56,11.9,11.71,19.76,23.75,19.76,8.75,0,15.72-4.05,20.74-11l11.5,9.73a39,39,0,0,1-32.24,16c-25.11,0-41.31-18-41.31-41.48s16.07-41.48,39.08-41.48c17.23,0,40.8,12,36.9,48.45Zm42.79-12.8C290.59,85.93,280.12,81.05,273,81.05c-12.11,0-20.88,7.61-23.69,21.07Z'></path>
                                <polygon fill='currentColor'
                                    points='299.63 23.85 353.62 23.85 353.62 77.84 377.48 77.84 377.48 0 299.63 0 299.63 23.85'></polygon>
                                {/* eslint-enable */}
                            </svg>
                        </a>
                    </li>
                    <li className='nav-mobile-right flex-pull-right'>
                        <a onClick={this.toggleSideNav}>
                            <i
                                className={`mdi mdi-${
                                    this.state.showSideNav ? 'close' : 'menu'
                                }`}
                                style={{
                                    color: 'white',
                                    fontSize: '2rem'
                                }}
                            />
                        </a>
                    </li>
                </ul>
                <ul className={`visible-xs mobile-nav-menu ${showClass}`}>
                    {this.props.user.isJwtValid && (
                        <li
                            data-title='home'
                            data-mobile='true'
                            onClick={this.onSetState}
                            className='visible-xs'
                        >
                            <a className={`category-link ${cn({active: this.state.activeState === '/home'})}`}>
                                <Link
                                    activeClassName='active'
                                    className='link'
                                    to='/home'
                                >
                                    Home
                                </Link>
                            </a>
                        </li>
                    )}
                    {this.props.menu.map(({menu, mic, title}) => (
                        <li
                            data-title={mic}
                            data-mobile='true'
                            key={`${title}-mobile`}
                            onClick={this.onSetState}
                            className='visible-xs'>
                            <a
                                className={`category-link ${cn({
                                    active: this.state.activeMenu === mic
                                })}`}>
                                {title}
                            </a>
                            <NyAccordion show={this.state.activeMenu === mic}>
                                <div className='overflow-y'>
                                    {menu.map(({menu, mic}) =>
                                        orderBy(menu, 'groupOrder').map(
                                            (item) => (
                                                <Link
                                                    activeClassName='active'
                                                    className='sub-link'
                                                    key={`/${mic.toLowerCase()}/${
                                                        item.url
                                                    }`}
                                                    to={item.url}
                                                    params={{
                                                        platform: mic,
                                                        ...(item.defaultParams ||
                                                            {})
                                                    }}>
                                                    {item.title}
                                                </Link>
                                            )
                                        )
                                    )}
                                </div>
                            </NyAccordion>
                        </li>
                    ))}
                    {this.props.user.entitlement === 'SUPER_USER' && (
                        <li
                            data-title='system'
                            data-mobile='true'
                            onClick={this.onSetState}
                            className='visible-xs'
                        >
                            <a
                                className={`category-link ${cn({
                                    active: this.state.activeState === '/system/broadcast-message'
                                })}`}>
                                System
                            </a>
                            <NyAccordion show={this.state.activeMenu === 'system'}>
                                <div className='overflow-y'>
                                    <Link
                                        activeClassName='active'
                                        className='sub-link'
                                        to='/system/broadcast-message'
                                    >
                                        Broadcast Message
                                    </Link>
                                </div>
                            </NyAccordion>
                        </li>
                    )}
                    <li className='login-link'>
                        <Link
                            to={
                                this.props.user.isJwtValid
                                    ? '/logout'
                                    : '/login'
                            }>
                            {this.props.user.isJwtValid ? 'Logout' : 'Login'}
                        </Link>
                    </li>
                </ul>
            </>
        );
    }
}
