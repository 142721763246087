import {LocalDateTime} from '@js-joda/core';
import Divider from '@material-ui/core/Divider';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import NyAlert from 'nyse-web-tools-common/lib/react/generic/NyAlert/NyAlert';
import NyLoader from 'nyse-web-tools-common/lib/react/generic/NyLoader/NyLoader';
import React, {useMemo} from 'react';

import {
    useCleanUpJobs,
    useGoodNightJobs
} from '../../../hooks/useEodProcess.hook';
import usePlatform from '../../../hooks/usePlatform';
import useResource from '../../../hooks/useResource.hook';
import useUser from '../../../hooks/useUser.hook';
import {
    cleanUpJobRunResource,
    goodNightJobRunResource,
    reScheduleResource
} from '../../../resources/eodProcessing.resource';

const EODPlatformSet = new Set(['XNYS', 'ARCO', 'AMXO']);
const GoodNightPlatformSet = new Set(['ARCX', 'XCIS', 'XASE', 'XNYS', 'ARCO', 'AMXO']);

export const FRJobsForm = React.memo(() => {
    const user = useUser();
    const platform = usePlatform();
    const cleanUp = useCleanUpJobs();
    const goodnight = useGoodNightJobs();
    const reSchedule = useResource(reScheduleResource);
    const cleanUpJobRun = useResource(cleanUpJobRunResource);
    const goodNightJobRun = useResource(goodNightJobRunResource);

    const hasUpdatePermission = useMemo(
        () =>
            (user.permissions?.[platform]?.clientResourceNames ?? [])?.includes(
                'run eod processing jobs'
            ),
        [platform, user.permissions]
    );

    const [hasEODPermission, hasGoodNightPermission] = useMemo(() => [EODPlatformSet.has(platform), GoodNightPlatformSet.has(platform)], [platform]);

    const onSuccess = () => {
        cleanUp.refetch().then();
        goodnight.refetch().then();
    };

    const formatDateString = (date: LocalDateTime): string => {
        return `${date.month()} ${date.dayOfMonth()}, ${date.year()} at ${
            date.toString().split('T')[1]
        } EST`;
    };

    return (
        <>
            <NyAlert
                show
                type='warning'
                detail='THIS PAGE IS TO BE USED IN THE EVENT OF A SYSTEM FAILURE. OPERATIONS TEAM MAY PERFORM F/R FUNCTIONS BELOW.'
            />
            <NyForm onSuccess={onSuccess} onSubmit={reSchedule.put}>
                <h2>Re-Schedule Jobs</h2>
                <p>
                    Re-schedule jobs in case of Pillar EOD is updated or jobs
                    were not created at 9 AM due to an error.
                </p>
                {hasUpdatePermission && (
                    <NySubmitBtn data-e2e-tag='rescheduleBtn'>
                        Re-Schedule
                    </NySubmitBtn>
                )}
                <NySubmitAlert />
            </NyForm>
            <Divider style={{margin: '20px 0'}} />

            {
                hasEODPermission && (
                    <>
                        {cleanUp.isLoading && <NyLoader show />}
                        {!cleanUp.isLoading && cleanUp.isError && (
                            <NyAlert
                                show
                                type='danger'
                                detail={cleanUp.error.detail}
                                title={cleanUp.error.title}
                            />
                        )}
                        {!cleanUp.isLoading && !cleanUp.isError && (
                            <NyForm onSubmit={cleanUpJobRun.put}>
                                <h2>EOD Clean Up Jobs</h2>
                                {!cleanUp.data ? (
                                    <p>EOD clean up is not scheduled.</p>
                                ) : (
                                    <p
                                        children={`Clean Up job is scheduled to run at  ${formatDateString(
                                            LocalDateTime.parse(cleanUp.data)
                                        )}`}
                                    />
                                )}
                                {hasUpdatePermission && (
                                    <NySubmitBtn data-e2e-tag='cleanUpJobBtn'>
                                        Run
                                    </NySubmitBtn>
                                )}
                                <NySubmitAlert />
                            </NyForm>
                        )}
                        <Divider style={{margin: '20px 0'}} />
                    </>
                )
            }

            {
                hasGoodNightPermission && (
                    <>
                        {goodnight.isLoading && <NyLoader show />}
                        {!goodnight.isLoading && goodnight.isError && (
                            <NyAlert
                                show
                                type='danger'
                                detail={goodnight.error.detail}
                                title={goodnight.error.title}
                            />
                        )}
                        {!goodnight.isLoading && !goodnight.isError && (
                            <NyForm onSubmit={goodNightJobRun.put}>
                                <h2>Good night Jobs</h2>
                                {JSON.stringify(goodnight.data) === '{}' ||
                                !goodnight.data ? (
                                    <p>Good night job is not scheduled.</p>
                                ) : (
                                    <p
                                        children={`Good night job is scheduled to run at ${formatDateString(
                                            LocalDateTime.parse(goodnight.data)
                                        )}`}
                                    />
                                )}
                                {hasUpdatePermission && (
                                    <NySubmitBtn data-e2e-tag='goodNightJobBtn'>
                                        Run
                                    </NySubmitBtn>
                                )}
                                <NySubmitAlert />
                            </NyForm>
                        )}
                    </>
                )
            }
        </>
    );
});
