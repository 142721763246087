import {DateTimeFormatter, LocalDateTime} from '@js-joda/core';
import joda from 'nyse-web-tools-common/lib/utils/joda';

export const liveOrdersColumnDefs = [
    {
        headerName: 'Time',
        field: 'TIMESTAMP',
        cellRenderer: ({data: {TIMESTAMP}}) => {
            return TIMESTAMP !== undefined && TIMESTAMP.length === 24
                ? joda.format(
                    LocalDateTime.parse(
                        TIMESTAMP,
                        DateTimeFormatter.ofPattern(
                            'yyyyMMddHHmmss.SSSSSSSSS'
                        )
                    ),
                    'yyyy-MM-dd HH:mm:ss.SSSSSSSSS'
                )
                : TIMESTAMP;
        },
        width: 150
    },
    {
        headerName: 'ClordID',
        field: 'CLIENT_ORDER_ID',
        width: 110
    },
    {
        headerName: 'OSI Symbol',
        field: 'SYMBOL',
        width: 200
    },
    {
        headerName: 'OSI Root',
        field: 'OSI_ROOT_SYMBOL',
        width: 100
    },
    {
        headerName: 'UL Symbol',
        field: 'UNDERLYING_SYMBOL',
        width: 100
    },
    {
        headerName: 'Side',
        field: 'ORDER_SIDE',
        width: 80
    },
    {
        headerName: 'Price',
        field: 'PRICE',
        type: 'price',
        width: 100
    },
    {
        headerName: 'Quantity',
        field: 'LEAVESQTY',
        type: 'number',
        width: 150
    },
    {
        headerName: 'MPID',
        field: 'FIRM_IDENTIFIER',
        width: 150
    },
    {
        headerName: 'MMID',
        field: 'MARKET_MAKER_ID',
        width: 150
    },
    {
        headerName: 'SenderCompID',
        field: 'CUSTOMER_SENDERCOMPID',
        width: 150
    },
    {
        headerName: 'Customer/Firm',
        field: 'CUST_FIRM',
        width: 150
    },
    {
        headerName: 'CMTA',
        field: 'CLEARING_NUMBER_CMTA',
        width: 150
    },
    {
        headerName: 'Clearing Number',
        field: 'ALTERNATE_CLEARING_NUMBER',
        width: 150
    },
    {
        headerName: 'Open/Close',
        field: 'OPEN_CLOSE',
        width: 150
    },
    {
        headerName: 'Optional Data',
        field: 'OPTIONAL_DATA',
        width: 150
    },
    {
        headerName: 'Order Type',
        field: 'ORDER_TYPE',
        width: 150
    },
    {
        headerName: 'TIF',
        field: 'TIMEINFORCE',
        width: 150
    },
    {
        headerName: 'Exec Instruction',
        field: 'ORDER_EXEC_INSTRUCTIONS',
        width: 150
    },
    {
        headerName: 'Routing Instruction',
        field: 'ORDER_ROUTING_INSTRUCTIONS',
        width: 150
    },
    {
        headerName: 'ExtendedExec Instruction',
        field: 'ORDER_EXTENDED_EXEC_INSTRUCTIONS',
        width: 150
    },
    {
        headerName: 'Strike Price',
        field: 'STRIKE_PRICE',
        width: 150
    },
    {
        headerName: 'Expiration Date',
        field: 'MATURITY_EXPIRATION_DATE',
        width: 150
    },
    {
        headerName: 'Put/Call',
        field: 'CALL_PUT',
        width: 150
    },
    {
        headerName: 'Security Type',
        field: 'SECURITY_TYPE',
        width: 150
    }
];
