export const liquidityMetricsMmidColumnDefs = [
    {
        headerName: '-',
        cellStyle: ({data: {isGroup}}) => ({
            fontWeight: isGroup ? 500 : undefined
        }),
        field: 'symbol',
        pinned: 'left',
        sort: 'asc',
        suppressMovable: true,
        suppressToolPanel: true,
        width: 200
    },
    {
        headerName: 'ETPID / MPID',
        field: 'mpId',
        width: 100
    },
    {
        headerName: 'MMID',
        field: 'mmId',
        width: 100
    },
    {
        headerName: 'MM Type',
        field: 'mmType',
        width: 125
    },
    {
        headerName: 'Symbol',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Issuer Name',
                field: 'issueName',
                width: 200
            },
            {
                headerName: 'Issuer Company',
                field: 'issuingCompany',
                width: 200
            },
            {
                headerName: 'Adviser',
                field: 'advisor',
                width: 200
            },
            {
                headerName: 'Trust Name',
                field: 'trustName',
                width: 200
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Execution Volume',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Total',
                field: 'totalVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Posted',
                field: 'postedVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Posted Part. %',
                field: 'postedParticipation',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Posted Part. % of CADV',
                field: 'postedParticipationCADV',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Exchange Part. %',
                field: 'exchangeParticipation',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Price Improved Shares %',
                field: 'priceImprovedVolumePercentage',
                type: 'percentage',
                width: 200
            },
            {
                headerName: 'Buy',
                field: 'buyVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Buy %',
                field: 'buyPercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Sell',
                field: 'sellVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Sell %',
                field: 'sellPercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'SSH',
                field: 'sellShortVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'SSHE',
                field: 'sellShortExemptVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'SSH & SSHE %',
                field: 'sshSshePercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Opening Auction',
                field: 'openAuctionVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Opening Auction %',
                field: 'openAuctionPercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Closing Auction',
                field: 'closeAuctionVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Closing Auction %',
                field: 'closeAuctionPercentage',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Close Price',
                field: 'closePrice',
                type: 'price',
                width: 150
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Quote Metrics',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Spread Cents',
                field: 'spreadCents',
                type: 'price',
                width: 150
            },
            {
                headerName: 'Spread BPs',
                field: 'spreadBps',
                type: 'price',
                width: 150
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Time Weighted Shares',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'NBB',
                field: 'twVolumeAtBid',
                type: 'number',
                width: 150
            },
            {
                headerName: 'NBB %',
                field: 'pctInsdBid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'NBO',
                field: 'twVolumeAtAsk',
                type: 'number',
                width: 150
            },
            {
                headerName: 'NBO %',
                field: 'pctInsdAsk',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Shares w/in 8% of NBB',
                field: 'twVolume8PctAwayBid',
                type: 'number'
            },
            {
                headerName: 'Shares w/in 8% of NBO',
                field: 'twVolume8PctAwayAsk',
                type: 'number'
            },
            {
                headerName: 'NBB -1 MPV',
                field: 'twVolume1MpvAwayBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +1 MPV',
                field: 'twVolume1MpvAwayAsk',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBB -2 MPV',
                field: 'twVolume2MpvAwayBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +2 MPV',
                field: 'twVolume2MpvAwayAsk',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBB -3 MPV',
                field: 'twVolume3MpvAwayBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +3 MPV',
                field: 'twVolume3MpvAwayAsk',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBB -1/2% MPV',
                field: 'twVolume05PctAwayBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +1/2% MPV',
                field: 'twVolume05PctAwayAsk',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBB -1% MPV',
                field: 'twVolume1PctAwayBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +1% MPV',
                field: 'twVolume1PctAwayAsk',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBB -2% MPV',
                field: 'twVolume2PctAwayBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +2% MPV',
                field: 'twVolume2PctAwayAsk',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBB -3% MPV',
                field: 'twVolume3PctAwayBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +3% MPV',
                field: 'twVolume3PctAwayAsk',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBB -5bp',
                field: 'twVolume5BpFromBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +5bp',
                field: 'twVolume5BpFromAsk',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBB -10bp',
                field: 'twVolume10BpFromBid',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBO +10bp',
                field: 'twVolume10BpFromAsk',
                type: 'price',
                width: 150
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Liquidity Time Duration',
        headerClass: 'text-center',
        children: [
            {
                headerName: '% of Core Hours w/in 8% of NBB',
                field: 'pct8PctInsdBid',
                type: 'percentage'
            },
            {
                headerName: '% of Core Hours w/in 8% of NBO',
                field: 'pct8PctInsdAsk',
                type: 'percentage'
            },
            {
                headerName: '% NBB -5bp',
                field: 'pct5BpBid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '% NBO +5bp',
                field: 'pct5BpAsk',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '% NBB -10bp',
                field: 'pct10BpBid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '% NBO +10bp',
                field: 'pct10BpAsk',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '1000+ Shares ≤ 1% - NBB',
                field: 'pct1Pct1000Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '1000+ Shares ≤ 1% - NBO',
                field: 'pct1Pct1000Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '2500+ Shares ≤ 1% - NBB',
                field: 'pct1Pct2500Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '2500+ Shares ≤ 1% - NBO',
                field: 'pct1Pct2500Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '5000+ Shares ≤ 1% - NBB',
                field: 'pct1Pct5000Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '5000+ Shares ≤ 1% - NBO',
                field: 'pct1Pct5000Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '1000+ Shares ≤ 2% - NBB',
                field: 'pct2Pct1000Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '1000+ Shares ≤ 2% - NBO',
                field: 'pct2Pct1000Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '2500+ Shares ≤ 2% - NBB',
                field: 'pct2Pct2500Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '2500+ Shares ≤ 2% - NBO',
                field: 'pct2Pct2500Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '5000+ Shares ≤ 2% - NBB',
                field: 'pct2Pct5000Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '5000+ Shares ≤ 2% - NBO',
                field: 'pct2Pct5000Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '1000+ Shares ≤ 3% - NBB',
                field: 'pct3Pct1000Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '1000+ Shares ≤ 3% - NBO',
                field: 'pct3Pct1000Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '1000+ Shares ≤ 8% - NBB',
                field: 'pct8Pct1000Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '1000+ Shares ≤ 8% - NBO',
                field: 'pct8Pct1000Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '2500+ Shares ≤ 3% - NBB',
                field: 'pct3Pct2500Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '2500+ Shares ≤ 3% - NBO',
                field: 'pct3Pct2500Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '2500+ Shares ≤ 8% - NBB',
                field: 'pct8Pct2500Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '2500+ Shares ≤ 8% - NBO',
                field: 'pct8Pct2500Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '5000+ Shares ≤ 3% - NBB',
                field: 'pct3Pct5000Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '5000+ Shares ≤ 3% - NBO',
                field: 'pct3Pct5000Ask',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '5000+ Shares ≤ 8% - NBB',
                field: 'pct8Pct5000Bid',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '5000+ Shares ≤ 8% - NBO',
                field: 'pct8Pct5000Ask',
                type: 'percentage',
                width: 150
            }
        ],
        marryChildren: true
    }
];
