import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useMemo, useState} from 'react';

import {capitalizeRole, ENTERING, Entitlement, FLOOR_BROKER} from '../../../../configs/entitlementRoleMap';
import useResource from '../../../../hooks/useResource.hook';
import {priceProtectionsResource} from '../../../../resources/riskSettings.resource';
import {useRiskRanges} from '../../../../hooks/useRiskRanges';
// @ts-ignore
import {cleanObject, getRangeLabel} from '../../../../utils/utilities';

import ClearPPButton from '../../../buttons/riskSettings/clearPriceProtections.btn';
import isEqual from 'react-fast-compare';
import {formatPriceProtectionCreditLimit, formatPriceProtectionRangeLabels} from './form.utilities';

type PriceProtectionFormProps = {
    classes: {
        alert: string;
        controls: string;
        form: string;
        formBody: string;
        radioBtns: string;
    };
    context: {
        entitlement: Entitlement;
        onModalClosed(status: boolean): void;
        isOptionsMarket: boolean;
    };
    className: string;
    data: any;
    onSuccess(isSuccessful: boolean);
    riskType: string;
};

export const PriceProtectionForm: React.FC<PriceProtectionFormProps> = React.memo((props) => {

    const resources = useResource(priceProtectionsResource);
    const [ranges] = useRiskRanges(props.riskType);
    const [preValues, setPreValues] = useState([]);

    const labels = useMemo(() => formatPriceProtectionRangeLabels(ranges), [ranges]);

    const initCreditLimits = useMemo(
        () => {
            const creditLimits = formatPriceProtectionCreditLimit(props.data.limits, labels, props.riskType);
            setPreValues(creditLimits);
            return creditLimits;
        },
        [props.data, labels]
    );

    const [values, setValues] = useState({
        entityId: props.data.entityId,
        limitCrdRole:
            props.data.limitCrdRole === FLOOR_BROKER &&
            props.context.isOptionsMarket
                ? ENTERING
                : props.data.limitCrdRole,
        limitCrd: props.data.limitCrd,
        creditLimits: initCreditLimits
    });

    const initValues = useMemo(() => {
        return {
            entityId: props.data.entityId,
            limitCrdRole:
                props.data.limitCrdRole === FLOOR_BROKER &&
                props.context.isOptionsMarket
                    ? ENTERING
                    : props.data.limitCrdRole,
            limitCrd: props.data.limitCrd,
            creditLimits: initCreditLimits
        };
    }, [props, initCreditLimits])

    const isRequired = useMemo(() => {
        let isRequired = false;
        if (values.creditLimits[0]?.earlyLateMultiplier || values.creditLimits[0]?.onCloseMultiplier)
            isRequired = true;
        else if (values.creditLimits.slice(1).find(e => ((e.limit !== undefined && e.limit !== '') ||
            (e.priceThreshold !== undefined && e.priceThreshold !== ''))))
            isRequired = true;
        return isRequired;
    }, [values]);

    const isEmpty = useMemo(() => {
        let isEmpty = true;
        if ((values.creditLimits[0]?.earlyLateMultiplier !== undefined && values.creditLimits[0]?.earlyLateMultiplier !== '') ||
            (values.creditLimits[0]?.onCloseMultiplier !== undefined && values.creditLimits[0]?.onCloseMultiplier !== ''))
            isEmpty = false;
        else if (values.creditLimits.slice(1).find(e => ((e.limit !== undefined && e.limit !== '') ||
            (e.priceThreshold !== undefined && e.priceThreshold !== ''))))
            isEmpty = false;
        return isEmpty;
    }, [values]);

    const isChanged = useMemo(
        () => {
            return !isEqual(
                preValues.map(e => cleanObject(e)),
                values.creditLimits.map(e => cleanObject(e))
            );
        }, [values, preValues]);

    const onSubmit = useCallback(
        (values) => {
            if (isChanged) {
                if (isEmpty) {
                    let isReset = false;
                    if (preValues[0]?.earlyLateMultiplier || preValues[0]?.onCloseMultiplier)
                        isReset = true;
                    else if (preValues.slice(1).find(e => ((e.limit !== undefined && e.limit !== '') ||
                        (e.priceThreshold !== undefined && e.priceThreshold !== ''))))
                        isReset = true;
                    if (isReset) {
                        return resources.put(
                            cleanObject({
                                ...values,
                                creditLimits: undefined,
                                resetRiskType: props.riskType
                            }),
                            values.entityId
                        );
                    }
                } else {
                    const earlyLateMultiplier = props.riskType === 'lmt_ord_prc_eq' ? values.creditLimits[0]?.earlyLateMultiplier : undefined;
                    const onCloseMultiplier = props.riskType === 'lmt_ord_prc_eq' ? values.creditLimits[0]?.onCloseMultiplier : undefined;
                    return resources.put(
                        cleanObject({
                            ...values,
                            earlyLateMultiplier,
                            onCloseMultiplier,
                            creditLimits: values.creditLimits.slice(1).map((e) => {
                                return cleanObject({
                                    riskType: props.riskType,
                                    ...e,
                                })
                            })
                        }),
                        values.entityId
                    );
                }
            }
        },
        [resources, preValues, props, isEmpty, isChanged, values]
    );

    const onChange = (values) => {
        setValues(values);
    }

    const onSuccess = useCallback((response, context, values) => {
        const creditLimits = [];
        for (let idx = 1; idx != values.creditLimits.length; idx++) {
            creditLimits[idx] = {
                rangeId: values.creditLimits[idx].rangeId,
                limit: values.creditLimits[idx].limit,
                priceThreshold: values.creditLimits[idx].priceThreshold
            };
        }
        if (props.riskType === 'lmt_ord_prc_eq') {
            creditLimits[0] = {
                rangeId: values.creditLimits[0].rangeId,
                earlyLateMultiplier: values.creditLimits[0]?.earlyLateMultiplier,
                onCloseMultiplier: values.creditLimits[0]?.onCloseMultiplier
            };
        }
        setPreValues(creditLimits);
        if (props.onSuccess)
            props.onSuccess(response);
    }, [props, values]);

    return (
        <NyForm
            data-e2e-tag='updatePriceProtectionSettings'
            className={`${props.classes.form} ${props.className}`}
            initialValues={initValues}
            enableReinitialize={true}
            onChange={onChange}
            onSuccess={onSuccess}
            onSubmit={onSubmit}>
            <Grid className={props.classes.formBody} container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Box display='inline' fontWeight='fontWeightMedium'>
                        {capitalizeRole(props.data.limitCrdRole)} Firm:
                    </Box>
                    &nbsp;{props.data.limitCrd}
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box display='inline' className='fontWeightRegular'>
                        Limit Order Price Protection
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <label>Range Id</label>
                </Grid>
                <Grid item xs={2}>
                    <label>Limit Price Range</label>
                </Grid>
                <Grid item xs={1}>
                    <Box display='flex' justifyContent={'flex-end'}>
                        <label>Exchange%</label>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box display='flex' className='space-left-2'>
                        <label>User %</label>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box display='flex' justifyContent={'flex-end'}>
                        <label>Exchange $</label>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box display='flex' className='space-left-2'>
                        <label>User $</label>
                    </Box>
                </Grid>
                <Grid item xs={3}/>
                {labels.filter((e) => e !== 0).map((e) => {
                    return (
                        <Grid container spacing={1} key={e.id}>
                            <Grid item xs={1}>
                                <label className='space-left-1'>{e.id}</label>
                            </Grid>
                            <Grid item xs={2}>
                                <label>{e.label}</label>
                            </Grid>
                            <Grid item xs={1}>
                                <Box display='flex' className='space-right-2' justifyContent={'flex-end'}>
                                    <label>{e.percentage}%</label>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box display='flex' className='space-left-2'>
                                    <NyNumberInput
                                        name={`creditLimits[${e.id}].limit`}
                                        required={isRequired}
                                        min={0}
                                        max={999}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box display='flex' className='space-right-2' justifyContent={'flex-end'}>
                                    <label>${e.priceThreshold}</label>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box display='flex' className='space-left-2'>
                                    <NyNumberInput
                                        name={`creditLimits[${e.id}].priceThreshold`}
                                        min={0}
                                        max={9999999.99}
                                        precision={2}
                                        required={isRequired}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={3}/>
                        </Grid>);
                })}
                <Grid item xs={12}/>
                {props.riskType === 'lmt_ord_prc_eq' && (
                    <>
                        <Grid item xs={2}>
                            <div className='form-group'>
                                <label>Early/Late Multiplier</label>
                                <NyNumberInput
                                    name={`creditLimits[0].earlyLateMultiplier`}
                                    min={1}
                                    max={999}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className='form-group space-left-2'>
                                <label>On Close Multiplier</label>
                                <NyNumberInput
                                    name={`creditLimits[0].onCloseMultiplier`}
                                    min={1}
                                    max={999}
                                />
                            </div>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    component={NySubmitAlert}
                    hasCloseBtn
                    className={props.classes.alert}
                />
                <Grid item xs={4} className={props.classes.controls}>
                    <NySubmitBtn
                        className='space-right-1'
                        disabled={!isChanged}
                    />
                    <ClearPPButton
                        color={'primary'}
                        className='space-right-1'
                    >
                        Clear Settings
                    </ClearPPButton>
                </Grid>
            </Grid>
        </NyForm>
    );
});
