import axios from 'axios';
import stringify from 'fast-json-stable-stringify';

const pending = new Map();

export default function axiosDedupeAdapter(config) {
    const key = stringify({
        url: config.url,
        data: config.data,
        params: {
            ...(config?.params?.request
                ? {request: JSON.parse(config.params.request)}
                : config.params || {})
        }
    });

    if (pending.has(key)) {
        return pending.get(key);
    }

    const request = axios.defaults.adapter(config);

    pending.set(key, request);

    return request
        .then((response) => {
            pending.delete(key);

            return response;
        })
        .catch((error) => {
            pending.delete(key);

            return Promise.reject(error);
        });
}
