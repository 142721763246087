import Box from '@material-ui/core/Box';
import React from 'react';

export const coreSessionDataColumnDefs = [
    {
        headerName: '',
        field: 'label',
        cellRendererFramework: ({value}) => (
            <Box fontWeight='fontWeightMedium'>{value}</Box>
        ),
        pinned: 'left',
        minWidth: 100
    },
    {
        field: 'tapeA',
        headerName: 'Tape A',
        headerClass: ['text-center'],
        type: 'number',
        minWidth: 100
    },
    {
        field: 'tapeB',
        headerName: 'Tape B',
        headerClass: ['text-center'],
        type: 'number',
        minWidth: 100
    },
    {
        field: 'tapeC',
        headerName: 'Tape C',
        headerClass: ['text-center'],
        type: 'number',
        minWidth: 100
    },
    {
        field: 'total',
        headerName: 'Total',
        headerClass: ['text-center'],
        pinned: 'right',
        type: 'number',
        minWidth: 100
    }
];
