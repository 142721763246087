import Box from '@material-ui/core/Box';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NyTextInput from 'nyse-web-tools-common/lib/react/form/NyTextInput/NyTextInput';
import AgGrid from 'nyse-web-tools-common/lib/react/grid/AgGrid/AgGrid';
import AgGridColumnMenuTrigger from 'nyse-web-tools-common/lib/react/grid/AgGridColumnMenuTrigger/AgGridColumnMenuTrigger';
import AgGridCreateBtn from 'nyse-web-tools-common/lib/react/grid/AgGridCreateBtn/AgGridCreateBtn';
import AgGridExportMenu from 'nyse-web-tools-common/lib/react/grid/AgGridExportMenu/AgGridExportMenu';
import AgGridPagination from 'nyse-web-tools-common/lib/react/grid/AgGridPagination/AgGridPagination';
import AgGridProvider from 'nyse-web-tools-common/lib/react/grid/AgGridProvider/AgGridProvider';
import AgGridToggleShowFilters from 'nyse-web-tools-common/lib/react/grid/AgGridToggleShowFilters/AgGridToggleShowFilters';
import useManagedState from 'nyse-web-tools-common/lib/react/hooks/useManagedState/useManagedState';
import React, {useMemo} from 'react';

import {SubAccountContactExportBtn} from '../components/buttons/subAccounts/subAccountContactExport.btn';
import {Access} from '../components/displays/access';
import {SubAccountModal} from '../components/modals/subAccounts/subAccount.modal';
import {FirmSelect} from '../components/selects/firm.select';
import {IbMpidSelect} from '../components/selects/mpid.select';
import {TradingAccountsSelect} from '../components/selects/tradingAccounts.select';
import {subAccountColumnDefs} from '../configs/columnDefs/subAccount.columnDefs';
import {subAccountContactsColumnDefs} from '../configs/columnDefs/subAccountContacts.columnDefs';
import useResource from '../hooks/useResource.hook';
import useRoute from '../hooks/useRoute.hook';
import useStore from '../hooks/useStore.hook';
import subAccountResource from '../resources/subAccount.resource';

export const SubAccountsPage: React.FC = React.memo(() => {
    const route = useRoute();
    const firms = useStore('firms');
    const permissions = useStore('permissions');
    const platform = useStore('platform');
    const resource = useResource(subAccountResource);

    const init = useMemo(
        () => ({
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: subAccountContactsColumnDefs,
                    primaryKey: 'contactId'
                },
                suppressRefresh: true
            },
            pagination: {
                limit: 500,
                offset: 0
            },
            values: {
                activeStatus: true,
                crd: route.params.crd || (firms[0] || {}).crd,
                mpId: ''
            }
        }),
        [firms, route.params.crd]
    );

    const [values, setValues] = useManagedState(init.values);

    return (
        <AgGridProvider
            columnDefs={subAccountColumnDefs}
            columnNamespace='subAccounts'
            error={resource.error}
            filtersClassName='row space-bottom-1'
            initialPagination={init.pagination}
            initialFilters={init.values}
            isLoading={resource.pending}
            onFiltersChanged={setValues}
            onLoad={resource.get}
            permissions={permissions}
            primaryKey='subAccountId'
            rowData={resource.data}>
            <AgGridProvider.Filters>
                {firms.length > 1 && (
                    <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                        <label>IB</label>
                        <FirmSelect
                            name='crd'
                            required
                            resetFields={['mpId', 'tradingAccount']}
                            showInactive
                        />
                    </div>
                )}
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>MPID</label>
                    <IbMpidSelect
                        name='mpId'
                        crd={values.crd}
                        resetFields={['tradingAccount']}
                    />
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>Trading Account</label>
                    <TradingAccountsSelect
                        name='tradingAccount'
                        crd={values.crd}
                        mpId={values.mpId}
                    />
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>Sub Account</label>
                    <NyTextInput name='name' maxLength={16} />
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>Contact Id</label>
                    <NyNumberInput name='contactId' />
                </div>
            </AgGridProvider.Filters>

            <div className='row space-bottom-1'>
                <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                    <Box display='flex' alignItems='flex-end'>
                        <AgGridProvider.SubmitBtn />
                        <AgGridProvider.ResetBtn />
                        <Box display='flex' ml='auto'>
                            <Access permissions={['create sub accounts']}>
                                <AgGridCreateBtn
                                    Form={SubAccountModal}
                                    initialValueFields={['crd', 'mpId']}
                                    name='createSubAccount'
                                    parentTitle='Sub Accounts'
                                    resource={subAccountResource}
                                    resourceMethod='post'
                                    size={25}
                                    title='Create'
                                    tooltip='Create'
                                    triggerName='createSubAccountBtn'
                                />
                            </Access>
                            <AgGridExportMenu
                                exportFileName='subAccounts'
                                exportSheetName={platform.name}>
                                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                    <hr className='space-0' />
                                </div>
                                <SubAccountContactExportBtn
                                    subAccounts={resource.data}
                                />
                            </AgGridExportMenu>
                            <AgGridToggleShowFilters />
                            <AgGridColumnMenuTrigger />
                        </Box>
                    </Box>
                </div>
            </div>

            <AgGrid
                detailCellRendererParams={init.detailCellRendererParams}
                detailsRowProperty='contacts'
                enableCellChangeFlash
                masterDetail
            />

            <AgGridPagination>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1,000</option>
            </AgGridPagination>
        </AgGridProvider>
    );
});
