import cn from 'classnames';
import {NyConfirmBtn} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {removeOpraEntry} from '../../../stores/opraEntries/opraEntries.actions';
import {Access} from '../../displays/access';

@connect(['platform'], {removeOpraEntry})
export class OPRAEntryRemoveBtn extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        opraEntries: PropTypes.arrayOf(PropTypes.object)
    };

    _confirmMessage = () => (
        <>
            Are you sure you want to delete these following entries?
            <ul className='space-0 space-top-1 padding-left-1'>
                {this.props.opraEntries.map(({opraId}) => (
                    <li key={opraId}>
                        -&nbsp;<strong>{opraId}</strong>
                    </li>
                ))}
            </ul>
        </>
    );

    _onConfirm = () =>
        Promise.all(
            this.props.opraEntries.map(({opraId}) =>
                this.props.removeOpraEntry(opraId)
            )
        );

    render() {
        return (
            <Access permissions={['delete opra entry']}>
                <NyConfirmBtn
                    data-e2e-tag='removeUserBtn'
                    circleDanger
                    className={cn(
                        'mdi mdi-delete-outline',
                        this.props.className
                    )}
                    children={false}
                    confirmMessage={this._confirmMessage}
                    confirmText={this.props.platform.confirmText}
                    disabled={!this.props.opraEntries.length}
                    onClose={this.props.onClose}
                    onConfirm={this._onConfirm}
                    placement='bottom'
                    title='Remove Entries'
                />
            </Access>
        );
    }
}
