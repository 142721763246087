import {noop, omit} from 'lodash-es';
import {AgGrid, AgGridProvider} from 'nyse-web-tools-common/lib/react';
import {NyForm} from 'nyse-web-tools-common/lib/react';
import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import {NyTextInput} from 'nyse-web-tools-common/lib/react';
import {NyAlert} from 'nyse-web-tools-common/lib/react';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {tradeAllocateColumnDefs} from '../../../../configs/columnDefs/tradeAllocate.columnDefs';
import {useFirmIdOptions} from '../../../../hooks/useFirmIdOptions';
import useResource from '../../../../hooks/useResource.hook';
import {useSndrSubIdOptions} from '../../../../hooks/useSndrSubIdOptions';
import {useSubmittingBkrIdOptions} from '../../../../hooks/useSubmittingBkrIdOptions';
import {
    Entitlement,
    MAX_ALLOWED_SLICES,
    TradeAllocateProps,
    TradeSummaryTabs,
    tradeAllocateResource
} from '../../../../resources/tradeSummary.resource';
import {
    viewGBOBrokerFirmsResource,
    viewGBOMarketMakerFirmsResource
} from '../../../../resources/viewGBO.resource';
import {cleanObject} from '../../../../utils/utilities';
import {TradeSliceCounter} from '../../../displays/tradeSliceCounter';
import {Link} from '../../../navigation/navigation.link';
import {ReasonCodeSelect} from '../../../selects/reasonCode.select';
import {
    removeDuplicateOptions,
    removeOptionsWithFirmId
} from '../../../selects/selectUtils';
import {ContraSideSection} from '../sections/contraSide.section';
import {CustomerSideSection} from '../sections/customerSide.section';
import usePlatform from '../../../../hooks/usePlatform';
import useUser from "../../../../hooks/useUser.hook";
import {useEntitlement} from "../../../../hooks/useEntitlement";

export const TradeAllocateForm: React.FC<TradeAllocateProps> = React.memo(
    (props) => {

        const user = useUser();
        const entitlement = useEntitlement() || user.entitlement;

        const {contraOrdFirmId, ...filteredData} = useMemo(
            // this is to guarantee contraOrdFirmId is not received
            () => props.data,
            [props.data]
        );

        const resource = useResource(tradeAllocateResource);
        const gboResourceParams = {
            request: {
                mpidStatus: 'Active',
                mic: usePlatform()
            }
        };
        const brokerFirmsResource = useResource(
            viewGBOBrokerFirmsResource,
            gboResourceParams
        );
        const marketMakerFirmsResource = useResource(
            viewGBOMarketMakerFirmsResource,
            gboResourceParams
        );

        const [formSubmitted, setFormSubmitted] = useState(false);
        const [reasonCode, setReasonCode] = useState('');
        const [myOwnership, setMyOwnership] = useState('');
        const [totalSliceQuantity, setTotalSliceQuantity] = useState(0);
        const maxSliceQuantity = useMemo(
            () =>
                filteredData.execQty -
                (filteredData.allocations || 0) -
                (filteredData.inFlightAllocations || 0),
            [filteredData]
        );
        const [sliceQuantity, setSliceQuantity] = useState(maxSliceQuantity);
        const [slice, setSlice] = useState({});
        const [tradeAllocates, setTradeAllocates] = useState([]);
        const [currentOptions, setCurrentOptions] = useState({
            contraOrdSubmittingBkrId: '',
            contraOrdSndrSubId: '',
            myOwnership: ''
        });

        const custFirmIdOptions = useFirmIdOptions({
            sideCrd: filteredData.custCrd,
            firmId: filteredData.custFirmId
        });

        const custSubmittingBkrIdOptions = useSubmittingBkrIdOptions({
            sideCrd: filteredData.custCrd,
            firmId: filteredData.custFirmId,
            tradeType: filteredData.custTradeType,
            submittingBkrId: filteredData.custSubmittingBkrId
        });

        const allSndrSubIdOptions = useMemo(
            () => marketMakerFirmsResource.data,
            [marketMakerFirmsResource.data]
        );

        const custSndrSubIdOptions = useSndrSubIdOptions({
            sideCrd: filteredData.custCrd,
            firmId: filteredData.custFirmId,
            tradeType: filteredData.custTradeType,
            sndrSubId: filteredData.custSndrSubId,
            csgCustFirm: props.csgData.custFirm
        });

        const contraOrdFirmIdOptions = custFirmIdOptions;

        const contraOrdSubmittingBkrIdOptions = useMemo(
            () =>
                removeDuplicateOptions(
                    removeOptionsWithFirmId(
                        brokerFirmsResource.data,
                        custFirmIdOptions
                    ),
                    custSubmittingBkrIdOptions
                ),
            [
                brokerFirmsResource.data,
                custSubmittingBkrIdOptions,
                custFirmIdOptions
            ]
        );

        const contraOrdSubmittingBkrIdBothOptions = custSubmittingBkrIdOptions;

        const contraOrdSndrSubIdOptions = useMemo(
            () =>
                removeDuplicateOptions(
                    removeOptionsWithFirmId(
                        marketMakerFirmsResource.data,
                        custFirmIdOptions
                    ),
                    custSndrSubIdOptions
                ),
            [
                marketMakerFirmsResource.data,
                custSndrSubIdOptions,
                custFirmIdOptions
            ]
        );
        const initialValues = useMemo(
            () => ({
                ...filteredData,
                reasonCode: 'Non-Contractual Field Change',
                execQty: sliceQuantity
            }),
            [filteredData, sliceQuantity]
        );

        const isBothOwnership = myOwnership === 'BOTH';

        const onReasonCodeChange = ({values}) => {
            setReasonCode(values.reasonCode);
        };

        const onMyOwnershipChange = ({values}) => {
            setMyOwnership(values.myOwnership);
        };

        const onAddSlice = useCallback(() => {
            setTradeAllocates((values) => [...values, cleanObject(slice)]);
        }, [slice]);

        const contraOrdSndrSubIdOrSubmittingBkrIdPresentInOneSide = useMemo(
            () =>
                currentOptions.contraOrdSndrSubId ||
                currentOptions.contraOrdSubmittingBkrId,
            [
                isBothOwnership,
                currentOptions.contraOrdSubmittingBkrId,
                currentOptions.contraOrdSndrSubId
            ]
        );

        const onFormChange = useCallback(
            // This handles logic for ContraSndrSubId, ContraSubmittingBkrId, and ContraFirm
            (values, {setFieldValue}) => {
                setSlice({
                    ...omit(values, ['dealNum', 'evtTyp', 'origTdate']),
                    selectedSide: values.myOwnership,
                    reasonCode:
                        values.reasonCode === 'Other'
                            ? values.otherReason
                            : values.reasonCode
                });
                const contraOrdFirmIdUsingContraOrdSubmittingBkrId = contraOrdSubmittingBkrIdOptions.find(
                    (contraOrdSubmittingBkrIdOption) =>
                        contraOrdSubmittingBkrIdOption.value ===
                        values.contraOrdSubmittingBkrId
                )?.firmId;
                const contraOrdFirmIdUsingContraOrdSndrSubId = contraOrdSndrSubIdOptions.find(
                    (contraOrdSndrSubIdOption) =>
                        contraOrdSndrSubIdOption.value ===
                        values.contraOrdSndrSubId
                )?.firmId;
                if (values.myOwnership !== currentOptions.myOwnership) {
                    //only when myownership dropdown value has changed
                    if (values.myOwnership === 'BOTH') {
                        //set default values for fields when ownership is both sides
                        setFieldValue(
                            'contraOrdCustFirm',
                            filteredData.custFirm
                        );
                        setFieldValue(
                            'contraOrdFirmId',
                            filteredData.custFirmId
                        );
                        setFieldValue(
                            'contraOrdSndrSubId',
                            filteredData.custSndrSubId
                        );
                        setFieldValue(
                            'contraOrdSubmittingBkrId',
                            filteredData.custSubmittingBkrId
                        );
                        setFieldValue(
                            'contraOrdClearingNumber',
                            filteredData.custClearingNumber
                        );
                        setFieldValue(
                            'contraOrdOpenClose',
                            filteredData.custOpenClose
                        );
                        setFieldValue(
                            'contraOrdCmta',
                            filteredData.custCmta
                        );
                        setFieldValue(
                            'contraOrdAccount',
                            filteredData.custAccount
                        );
                        setFieldValue(
                            'contraOrdOptnlData',
                            filteredData.custOptnlData
                        );
                    } else {
                        setFieldValue('contraOrdFirmId', '');
                    }
                    setCurrentOptions({
                        contraOrdSndrSubId: '',
                        contraOrdSubmittingBkrId: '',
                        myOwnership: values.myOwnership
                    });
                }

                if (
                    values.myOwnership === 'ORD' ||
                    values.myOwnership === 'CONTRA'
                ) {
                    // 3rd party info is on the contra side
                    if (
                        values.contraOrdSubmittingBkrId &&
                        values.contraOrdSubmittingBkrId !==
                            currentOptions.contraOrdSubmittingBkrId &&
                        values.contraOrdSubmittingBkrId !==
                            filteredData.custSubmittingBkrId
                    ) {
                        // Logic for SubmittingBkrId
                        setFieldValue(
                            'contraOrdFirmId',
                            contraOrdFirmIdUsingContraOrdSubmittingBkrId
                        );
                        setFieldValue('contraOrdSndrSubId', '');
                        setCurrentOptions({
                            contraOrdSndrSubId: '',
                            contraOrdSubmittingBkrId:
                                values.contraOrdSubmittingBkrId,
                            myOwnership: values.myOwnership
                        });
                    } else if (
                        values.contraOrdSndrSubId &&
                        values.contraOrdSndrSubId !==
                            currentOptions.contraOrdSndrSubId &&
                        values.contraOrdSndrSubId !== filteredData.custSndrSubId
                    ) {
                        // Logic for SndrSubId
                        setFieldValue(
                            'contraOrdFirmId',
                            contraOrdFirmIdUsingContraOrdSndrSubId
                        );
                        setFieldValue('contraOrdSubmittingBkrId', '');
                        setCurrentOptions({
                            contraOrdSubmittingBkrId: '',
                            contraOrdSndrSubId: values.contraOrdSndrSubId,
                            myOwnership: values.myOwnership
                        });
                    }
                }
            },
            [
                contraOrdSndrSubIdOptions,
                contraOrdSubmittingBkrIdOptions,
                currentOptions,
                filteredData.custClearingNumber,
                filteredData.custFirmId,
                filteredData.custSndrSubId,
                filteredData.custSubmittingBkrId
            ]
        );

        const isSliceAtMax = maxSliceQuantity === totalSliceQuantity;
        const isReadyToSubmit = totalSliceQuantity > 0;

        const noAdditionalQtyToAllocate = useMemo(
            () =>
                props.data.allocations + props.data.inFlightAllocations >=
                props.data.execQty,
            [
                props.data.allocations,
                props.data.inFlightAllocations,
                props.data.execQty
            ]
        );

        const isFormDisabled =
            formSubmitted ||
            noAdditionalQtyToAllocate ||
            filteredData.updateToTradeIsInProgress ||
            !filteredData.tradeCanBeUpdated;

        const onSubmit = useCallback(
            () =>
                resource.put(
                    {
                        crd: props.crd,
                        allocations: tradeAllocates
                    },
                    {
                        origTdate: filteredData.origTdate,
                        evtTyp: filteredData.evtTyp,
                        dealNum: filteredData.dealNum
                    }
                ),
            [props.crd, filteredData, tradeAllocates, resource]
        );

        const onSuccess = useCallback(() => {
            setFormSubmitted(() => true);
        }, []);

        const context = {
            onClick: useCallback((params) => {
                if (params.column.colId === 'delete') {
                    setTradeAllocates((allocates) =>
                        allocates.filter((allocate, i) => i !== params.rowIndex)
                    );
                }
            }, [])
        };

        useEffect(() => {
            setTotalSliceQuantity(
                tradeAllocates.reduce((total, i) => total + i.execQty, 0)
            );
        }, [tradeAllocates]);

        useEffect(() => {
            setSliceQuantity(maxSliceQuantity - totalSliceQuantity);
        }, [maxSliceQuantity, totalSliceQuantity]);
        const isAtMaxNumberOfAllocates =
            tradeAllocates.length >= MAX_ALLOWED_SLICES;
        return (
            <>
                <NyForm
                    onSuccess={onSuccess}
                    onChange={onFormChange}
                    onSubmit={onAddSlice}
                    enableReinitialize
                    initialValues={initialValues}>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-2'>
                            <label>My Ownership</label>
                            <NySelectInput
                                name='myOwnership'
                                disabled={
                                    isFormDisabled ||
                                    isSliceAtMax ||
                                    isAtMaxNumberOfAllocates
                                }
                                onChange={onMyOwnershipChange}
                                required>
                                <option value=''>-</option>
                                <option value='ORD'>Buy Side</option>
                                <option value='CONTRA'>Sell Side</option>
                                <option value='BOTH'>Both Side</option>
                            </NySelectInput>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-2'>
                            <label>Slice Quantity</label>
                            <NyNumberInput
                                name='execQty'
                                min={1}
                                max={sliceQuantity}
                                disabled={
                                    isSliceAtMax ||
                                    isAtMaxNumberOfAllocates ||
                                    isFormDisabled
                                }
                                required
                            />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-4'>
                            <label>(Change) Reason Code</label>
                            <ReasonCodeSelect
                                name='reasonCode'
                                onChange={onReasonCodeChange}
                                resetFields={['otherReason']}
                                disabled={
                                    isSliceAtMax ||
                                    isAtMaxNumberOfAllocates ||
                                    isFormDisabled
                                }
                                required
                            />
                        </div>
                        {reasonCode === 'Other' && (
                            <div className='col-xs-12 col-sm-12 col-md-3 col-lg-4'>
                                <label>Other Reason</label>
                                <NyTextInput name='otherReason' required />
                            </div>
                        )}
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 space-top-1'>
                            <CustomerSideSection
                                currentTab={TradeSummaryTabs.allocate}
                                custCrd={filteredData.custCrd}
                                custOmsCrd={props.data.custOmsCrd}
                                custFirmId={filteredData.custFirmId}
                                custFirm={filteredData.custFirm}
                                custTradeType={filteredData.custTradeType}
                                custClearingNumber={
                                    filteredData.custClearingNumber
                                }
                                csgCustFirm={props.csgData.custFirm}
                                qccTrade={filteredData.qccTrade}
                                outcryTrade={filteredData.outcryTrade}
                                custSide={myOwnership === 'ORD' || myOwnership === 'BOTH' ? '1' : myOwnership ? '2' : '-'}
                                selectedSide={filteredData.selectedSide}
                                disabled={
                                    isFormDisabled ||
                                    isSliceAtMax ||
                                    isAtMaxNumberOfAllocates ||
                                    !myOwnership
                                }
                                custFirmIdOptions={custFirmIdOptions}
                                custSubmittingBkrIdOptions={
                                    custSubmittingBkrIdOptions
                                }
                                custSndrSubIdOptions={
                                    entitlement === Entitlement.MM_FIRM
                                        ? custSndrSubIdOptions
                                        : allSndrSubIdOptions}
                                myOwnership={myOwnership}
                                custSubmittingBkrId={
                                    filteredData.custSubmittingBkrId
                                }
                                isMultiAdjustment={false}
                                isMultiEditForAllFields={false}
                            />
                            <ContraSideSection
                                currentTab={TradeSummaryTabs.allocate}
                                contraOrdCrd={filteredData.custCrd}
                                contraOrdOmsCrd={filteredData.custOmsCrd}
                                contraOrdFirmId={filteredData.custFirmId}
                                contraOrdCustFirm={
                                    filteredData.contraOrdCustFirm
                                }
                                selectedSide={filteredData.selectedSide}
                                contraOrdTradeType={filteredData.custTradeType}
                                contraOrdClearingNumber={
                                    filteredData.contraOrdClearingNumber
                                }
                                csgContraOrdCustFirm={
                                    props.csgData.contraOrdCustFirm
                                }
                                qccTrade={filteredData.qccTrade}
                                outcryTrade={filteredData.outcryTrade}
                                contraOrdFirmIdOptions={contraOrdFirmIdOptions}
                                contraOrdSndrSubIdOptions={
                                    entitlement === Entitlement.MM_FIRM
                                        ? contraOrdSndrSubIdOptions
                                        : allSndrSubIdOptions
                                }
                                contraOrdSubmittingBkrIdOptions={
                                    isBothOwnership
                                        ? contraOrdSubmittingBkrIdBothOptions
                                        : contraOrdSubmittingBkrIdOptions
                                }
                                contraOrdSide={myOwnership === 'ORD' || myOwnership === 'BOTH' ? '2' : myOwnership ? '1' : '-'}
                                disabled={
                                    isFormDisabled ||
                                    isSliceAtMax ||
                                    isAtMaxNumberOfAllocates ||
                                    !myOwnership
                                }
                                myOwnership={myOwnership}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <NySubmitBtn
                                disabled={
                                    isFormDisabled ||
                                    isSliceAtMax ||
                                    isAtMaxNumberOfAllocates ||
                                    (!isBothOwnership &&
                                        !contraOrdSndrSubIdOrSubmittingBkrIdPresentInOneSide)
                                }
                                data-e2e-tag='addSliceBtn'>
                                Add Slice
                            </NySubmitBtn>
                            <NyResetBtn />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 space-top-1 space-bottom-1'>
                            {noAdditionalQtyToAllocate ? (
                                <NyAlert
                                    show
                                    title={`${props.data.allocations} out of ${props.data.execQty} are allocated, and the remaining ${props.data.inFlightAllocations} are allocations in progress. Currently, there is no quantity available for allocation.`}
                                    type='warning'
                                />
                            ) : (
                                <TradeSliceCounter
                                    totalTradeSplits={totalSliceQuantity}
                                    maxQty={maxSliceQuantity}
                                    quantityAllowedForSubmission={1}
                                    title='Total Allocations'
                                />
                            )}
                            <NySubmitAlert hasCloseBtn />
                        </div>
                    </div>
                </NyForm>
                <div className='row space-bottom-1'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <AgGridProvider
                            columnNamespace={'tradeAllocate'}
                            columnDefs={tradeAllocateColumnDefs}
                            rowData={tradeAllocates}
                            onLoad={noop}
                            submitOnLoad={false}>
                            <AgGrid context={context} height={200} />
                        </AgGridProvider>
                    </div>
                </div>
                <NyForm
                    onSuccess={onSuccess}
                    onChange={onFormChange}
                    successMessage={
                        <Link
                            to='/:platform/trade-summary/:view'
                            params={{
                                view: 'transactions'
                            }}
                            search={`?crd=${props.csgData.crd}&origTdate=${props.csgData.origTdate}&dealIdOrig=${props.csgData.dealIdOrig}`}>
                            Click here to see Transactions
                        </Link>
                    }
                    onSubmit={onSubmit}>
                    <div className='row space-top-1'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <NySubmitAlert hasCloseBtn />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <NySubmitBtn
                                disabled={isFormDisabled || !isReadyToSubmit}>
                                Submit
                            </NySubmitBtn>
                        </div>
                    </div>
                </NyForm>
            </>
        );
    }
);