import {sentinelMw} from '../api';
import environment from '../configs/environment.config';

export const clearingNumberResource = {
    api: sentinelMw,
    url: 'viewgborefdata',
    useCache: !(environment.testEnv || environment.integrationEnv),
    parseResponse: ({data}) => data.clearingDetails,
    defaultValue: []
};
