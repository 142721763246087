import {omit} from 'lodash-es';

import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getMyFirmMpids = (params = {}) => ({
    types: [
        events.MY_FIRM_MPIDS_GET,
        events.MY_FIRM_MPIDS_SUCCESS,
        events.MY_FIRM_MPIDS_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/user-firmmpids',
            params: cleanObject({
                ...omit(params, ['sendercomp']),
                mmsendermpid_regx: params.sendercomp
                    ? params.sendercomp + '/%'
                    : undefined
            }),
            isPillarSource: true
        }
    }
});
