import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import { useFormikContext } from 'formik';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import React, { useCallback, useState } from 'react';
import { useToggle } from 'react-use';
import useResource from '../../../hooks/useResource.hook';
import {optionsSettingsUpdateResource, RiskSettingsData } from '../../../resources/riskSettings.resource';
import {
    NyAlert,
    NyButton,
    NyLoader,
    NySelectInput,
    NySymbolInput,
    NyTextInput,
    NyUppercaseInput
} from "nyse-web-tools-common/lib/react";
import {capitalizeRole} from "../../../configs/entitlementRoleMap";
import {cleanObject} from '../../../utils/utilities';
import NySubmitAlert from "nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert";
import GridDivider from "nyse-web-tools-common/lib/react/generic/GridDivider/GridDivider";

const useStyles = makeStyles({
    root: {
        margin: 0
    }
});

type AddSymbolRiskEntityModalProps = {
    context: {
        isOptionsMarket: boolean;
        onModalClosed(status: boolean): void;
    };
    values: any;
};

export const AddSymbolRiskEntityModal: React.FC<AddSymbolRiskEntityModalProps> = React.memo(
    (props) => {
        return (
                <Grid container alignItems='flex-start' spacing={2}>
                    <Grid item xs={12}>
                        <Box display='inline' fontSize={20} fontWeight='fontWeightBold'>
                            {props.values?.displayRiskEntity}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='row flex-0-0 space-top-1'>
                            <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                                <label>Symbol</label>
                                <NySymbolInput name='symbol' maxLength={24} required/>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} component={NySubmitAlert} hasCloseBtn />
                    <GridDivider />
                    <Grid item xs={12}>
                        <NySubmitBtn />
                        <NyResetBtn />
                    </Grid>
                </Grid>
        );


    }
);
