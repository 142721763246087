import {events} from '../events';

export const setAlertLimit = ({mpid, crd, alertLimit}) => ({
    types: [
        events.RISK_MONITOR_ALERT_LIMIT_POST,
        events.RISK_MONITOR_ALERT_LIMIT_SUCCESS,
        events.RISK_MONITOR_ALERT_LIMIT_ERROR
    ],
    payload: {
        client: 'tdmMw',
        request: {
            url: 'risk-monitor/update-risk-alert-limit',
            method: 'POST',
            headers: {
                crds: [crd],
                mpids: [mpid]
            },
            params: {
                newLimit: alertLimit
            }
        }
    }
});

export const resetAlertLimit = () => ({
    type: events.RISK_MONITOR_ALERT_LIMIT_RESET
});
