import NySelectInput from 'nyse-web-tools-common/lib/react/form/NySelectInput/NySelectInput';
import NyUppercaseInput from 'nyse-web-tools-common/lib/react/form/NyUppercaseInput/NyUppercaseInput';
import AgGrid from 'nyse-web-tools-common/lib/react/grid/AgGrid/AgGrid';
import AgGridPagination from 'nyse-web-tools-common/lib/react/grid/AgGridPagination/AgGridPagination';
import AgGridProvider from 'nyse-web-tools-common/lib/react/grid/AgGridProvider/AgGridProvider';
import AgGridToggleShowFilters
    from 'nyse-web-tools-common/lib/react/grid/AgGridToggleShowFilters/AgGridToggleShowFilters';
import useManagedState from 'nyse-web-tools-common/lib/react/hooks/useManagedState/useManagedState';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FirmSelect } from '../components/selects/firm.select';
import { FirmParticipantSelect } from '../components/selects/firmParticipant.select';
import { MmIdSelect } from '../components/selects/mmid.select';
import { riskSettingsColumnDefs } from '../configs/columnDefs/riskSettings.columnDefs';
import { riskSettingsOptionColumnDefs } from '../configs/columnDefs/riskSettingsOption.columnDefs';
import { riskSettingsDetailColumnDefs } from '../configs/columnDefs/riskSettingsDetail.columnDefs';
import usePlatform from '../hooks/usePlatform';
import useResource from '../hooks/useResource.hook';
import useRoute from '../hooks/useRoute.hook';
import useStore from '../hooks/useStore.hook';
import useOptionsCheck from '../hooks/useOptionsCheck.hook';
import {
    mapUserCrdRole,
    optionsSettingsDetailsResource,
    optionsSettingsResource
} from '../resources/riskSettings.resource';
import {v4} from "uuid";

export const RiskSettingsPage = React.memo(() => {
    const route = useRoute();
    const firms = useStore('firms');
    const user = useStore('user');
    const permissions = useStore('permissions');
    const platforms = usePlatform();
    const isOptionsMarket = useOptionsCheck();
    const resource = useResource(optionsSettingsResource);
    const detailResource = useResource(optionsSettingsDetailsResource);
    const [closeSuccess, setCloseSuccess] = useState(false);

    const userCrdRole = useMemo(
        () => mapUserCrdRole(user.entitlement, platforms),
        [user.entitlement, platforms]
    );

    const perms = useMemo(()=>{
            return [...permissions, userCrdRole.toUpperCase()];
        }, [permissions, userCrdRole]);

    const [values, setValues] = useManagedState({
        userCrd: route.params.crd || (firms[0] || {}).crd,
        entityMpid: '',
        myLimits: ''
    });

    const calculateRowHeight = (params, settings) =>
        settings === undefined || settings.length === 0
            ? 120
            : settings.reduce(
                (acc, setting) =>
                    setting.limits.length
                        ? acc + setting.limits.length * 30
                        : acc + 30,
                70
        );

    const riskSettingDetailColumns = useMemo(()=> (
        isOptionsMarket === true
            ? riskSettingsDetailColumnDefs.filter((e)=>
                !['Early/Late Trading Multiplier','On Close Multiplier'].includes(e.headerName)
            )
            : riskSettingsDetailColumnDefs),
        [isOptionsMarket, platforms]);

    const riskSettingColumns = useMemo(()=> isOptionsMarket === true ?riskSettingsOptionColumnDefs : riskSettingsColumnDefs,
        [isOptionsMarket, platforms]);

    const init = useMemo(
        () => ({
            detailCellRendererParams: {
                suppressRefresh: false,
                refreshStrategy: 'rows',
                detailGridOptions: {
                    columnDefs: riskSettingDetailColumns,
                    enableCellChangeFlash: true,
                    immutableData: true,
                    onFirstDataRendered: (params) =>{
                        const columnState = params.columnApi.getColumnState();
                    },
                    getRowHeight: ({
                                       api: {
                                           gridOptionsWrapper: {
                                               gridOptions: {rowHeight}
                                           }
                                       },
                                       node,
                                       data
                                   }) => {
                        if (!node.master && data.limits) {
                            return data.limits.length * rowHeight || rowHeight;
                        }
                        return 80;
                    },
                    getRowNodeId: function (data) {
                        return (
                            data.limitCrd +
                            data.limitCrdRole +
                            data.riskEntity +
                            data.action +
                            data.riskType
                        );
                    }
                },
                getDetailRowData: (params) => {
                    detailResource
                        .get({
                            userCrd: params.data.userCrd,
                            userCrdRole,
                            entityId: params.data.entityId,
                            myLimits: params.data.myLimits
                        })
                        .then(({data: {data}}) => {
                            const settings = data.settings.map((setting) => ({
                                ...setting,
                                riskEntity: data.riskEntity,
                                displayRiskEntity: data.displayRiskEntity,
                                entityType: data.entityType,
                                entityId: params.data.entityId,
                                limits: setting.limits.map((limit) => ({
                                    ...limit,
                                    uuid: v4()
                                }))
                            }));
                            params.node.setRowHeight(
                                calculateRowHeight(params, settings)
                            );
                            params.node.gridApi.onRowHeightChanged();
                            params.successCallback(settings);
                        });
                }
            },
            pagination: {
                limit: 100,
                offset: 0
            },
            values: {
                userCrd: route.params.crd || (firms[0] || {}).crd,
                entityMpid: ''
            }
        }),
        [detailResource, userCrdRole, firms, route.params.crd]
    );

    const onLoad = useCallback(
        (values) => {
            resource.get({
                ...values,
                userCrdRole
            });
        },
        [userCrdRole, resource]
    );

    useEffect(() => {
        if (closeSuccess) {
            onLoad(values);
            setCloseSuccess(false);
        }
    }, [closeSuccess, onLoad, values]);

    const onModalClosed = (isSuccesful) => {
        if (isSuccesful) {
            setCloseSuccess(isSuccesful);
        }
    };

    const agGridOverride = {
        alwaysShowHorizontalScroll: true,
        embedFullWidthRows: true,
        onRowGroupOpened: (e) => {
            if (e.node.expanded) {
                const detailId = `detail_${e.node.id}`;
                e.api.forEachDetailGridInfo((node) => {
                    if (node.id !== detailId) {
                        const closedNode = e.api.getRowNode(
                            node.id.replace('detail_', '')
                        );
                        closedNode.setExpanded(false);
                        e.api.redrawRows({rowNodes: [closedNode]});
                    }
                });
            }
        }
    };

    const gridCtx = {
        onModalClosed,
        isOptionsMarket,
        entitlement: user.entitlement
    };

    return (
        <AgGridProvider
            columnDefs={riskSettingColumns}
            columnNamespace={`RiskSettings-${!isOptionsMarket?'EQ':'OPT'}`}
            onFiltersChanged={setValues}
            onLoad={onLoad}
            isLoading={resource.pending}
            error={resource.error}
            initialFilters={init.values}
            initialPagination={init.pagination}
            permissions={perms}
            primaryKey='displayRiskEntity'
            rowData={resource.data}
            submitOnLoad={user.entitlement !== 'SUPER_USER'}>
            <AgGridProvider.Filters>
                {firms.length > 1 && (
                    <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                        <label>Risk User</label>
                        <FirmSelect
                            initialValue={route.params.crd}
                            name='userCrd'
                            resetFields={['entityMpid']}
                            required
                        />
                    </div>
                )}
                {(user.entitlement !== 'MM_FIRM' || !isOptionsMarket) && (
                    <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-1'>
                        <label>MPID</label>
                        <FirmParticipantSelect
                            crd={values.userCrd}
                            disableInactiveControl
                            entitlements={[
                                'BROKER_FIRM',
                                'MEMBER_FIRM',
                                'IB_FIRM',
                                'TRADE_CHECKER'
                            ]}
                            hideEtpid
                            name='entityMpid'
                        />
                    </div>
                )}
                {isOptionsMarket &&
                <div className='form-group col-xs-12 col-sm-3 col-md-3 col-lg-1'>
                    <label>MMID</label>
                    {user.entitlement === 'MM_FIRM' ? (
                        <MmIdSelect crd={values.userCrd} name='entityMmid'/>
                    ) : (
                        <NyUppercaseInput name='entityMmid'/>
                    )}
                </div>}
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                    <label>View My Settings</label>
                    <NySelectInput name='myLimits'>
                        <option value=''>-</option>
                        <option value='true'>Y</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-1'>
                    <label>Symbol</label>
                    <NyUppercaseInput name='entitySymbol'/>
                </div>
            </AgGridProvider.Filters>

            <div className='row space-bottom-1'>
                <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                    <div className='flex-row flex-align-bottom'>
                        <AgGridProvider.SubmitBtn/>
                        <AgGridProvider.ResetBtn/>
                        <div className='btn-circle-grp flex-pull-right flex-row'>
                            <AgGridToggleShowFilters/>
                        </div>
                    </div>
                </div>
            </div>

            <AgGrid
                autoSizeColumns
                noBoxShadow
                context={gridCtx}
                agGridOverride={agGridOverride}
                detailsRowProperty='limits'
                detailCellRendererParams={init.detailCellRendererParams}
                masterDetail
            />
            <AgGridPagination>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1,000</option>
                <option value={5000}>5,000</option>
                <option value={10000}>10,000</option>
            </AgGridPagination>
        </AgGridProvider>
    );
});
