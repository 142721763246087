import {useFormikContext} from 'formik';
import {uniqBy} from 'lodash-es';
import {NyNumberInput, NySelectInput} from 'nyse-web-tools-common/lib/react';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import type {NyFieldInput} from 'nyse-web-tools-common/lib/react/types';
import React, {useCallback, useMemo} from 'react';

import {useEntitlement} from '../../hooks/useEntitlement';
import {useRouteEntitlement} from '../../hooks/useRouteEntitlement';
import useUser from '../../hooks/useUser.hook';
import useUserFirms from '../../hooks/useUserFirms';
import {Entitlement as TradeSummaryEntitlement} from '../../resources/tradeSummary.resource';
import type {Entitlement} from '../../types';

type ClearingNumberSelectProps = {
    crd: number;
    entitlements?: Entitlement[];
    disableInactiveControl?: boolean;
    hideEtpid?: boolean;
    initialValue?: string;
    isMulti?: boolean;
    showAll?: boolean;
    showInactive?: boolean;
} & Partial<NyFieldInput>;

export const ClearingNumberFilterSelect = React.memo<ClearingNumberSelectProps>(
    (props) => {
        const userFirms = useUserFirms();
        const formik = useFormikContext();
        const routeEntitlement = useRouteEntitlement();
        const user = useUser();
        const entitlement = useEntitlement() || user.entitlement;

        const firms = useMemo(
            () => (userFirms.data ?? []).filter(({active}) => active),
            [userFirms.data]
        );

        const entitlements = useMemo(
            () => props.entitlements || [TradeSummaryEntitlement.CLEARING_FIRM],
            [props.entitlements]
        );

        const hasEntitlement = useMemo(
            () =>
                !routeEntitlement.length ||
                entitlements.includes(entitlement) ||
                entitlements.includes(entitlement),
            [entitlement, entitlements, entitlement, routeEntitlement]
        );

        const filterOptions = useCallback(
            ({props: selectProps}) =>
                !props ||
                (selectProps.active &&
                    (!props.hideEtpid ||
                        !selectProps.type ||
                        selectProps.type === 'MPID')),
            [props]
        );

        const participants = useMemo(
            () => firms.find(({crd}) => crd === props.crd)?.participants ?? [],
            [firms, props.crd]
        );

        const options = useMemo(
            () => [
                ...(props.isMulti || props.required
                    ? []
                    : [
                          {
                              label: '-',
                              value: '',
                              props: {
                                  active: true
                              }
                          }
                      ]),
                ...uniqBy(participants, 'clearingNumber')
                    .filter(({clearingNumber}) => clearingNumber)
                    .map((participant) => ({
                        label: participant.clearingNumber.toString(),
                        value: participant.clearingNumber,
                        props: participant
                    }))
            ],
            [participants, props.isMulti, props.required]
        );

        useMountedEffect(() => {
            formik.setFieldValue(
                props.name,
                props.initialValue ||
                    (props.isMulti && hasEntitlement ? [] : '')
            );
        }, [hasEntitlement, props.crd, props.isMulti]);

        if (hasEntitlement || Array.isArray(formik.values[props.name])) {
            return (
                <NySelectInput
                    disabled={props.disabled}
                    filter={filterOptions}
                    initialValue={props.initialValue}
                    isLoading={userFirms.isFetching}
                    isMulti={props.isMulti}
                    name={props.name}
                    onChange={props.onChange}
                    options={options}
                    required={props.required}
                />
            );
        }

        return (
            <NyNumberInput
                {...props}
                includeThousandsSeparator={false}
                name={props.name}
            />
        );
    }
);
