import Typography from '@material-ui/core/Typography';
import {NyLoader} from 'nyse-web-tools-common/lib/react';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useMount} from 'react-use';

import useUser from '../hooks/useUser.hook';
import {resetUser, setUser, userLogout} from '../stores/user/user.actions';

export const LogoutPage = () => {
    const user = useUser();
    const dispatch = useDispatch();

    useMount(() => {
        if (user.sentinel.accessToken && !user.isLoggingOut) {
            dispatch(
                setUser({
                    isLoggingOut: true
                })
            );
            dispatch(userLogout(user.sentinel.accessToken))
                .then(onLogout)
                .catch(onLogout);
        } else {
            onLogout();
        }
    });

    const onLogout = () => {
        dispatch(resetUser());

        window.location.hash = '#/';
    };

    return (
        <div className='text-center mt-2'>
            <Typography variant='h6'>Logging out...</Typography>
            <NyLoader show />
        </div>
    );
};
