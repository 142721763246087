import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {clamp, noop} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridProvider,
    AgGridTimestamp
} from 'nyse-web-tools-common/lib/react';
import React, {useCallback, useMemo, useRef} from 'react';

import {haltedSymbolsColumnDefs} from '../../configs/columnDefs/haltedSymbols.columnDefs';
import useStore from '../../hooks/useStore.hook';

const useStyles = makeStyles({
    list: {
        overflowY: 'auto',
        webkitOverflowScrolling: 'touch'
    },
    symbol: {
        color: '#ffffff',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        height: 'calc(100% - 2px)'
    },
    luldHalts: {
        background: '#ff42ab'
    },
    mwcbHalts: {
        background: '#ff8a0d'
    },
    newListing: {
        background: '#0046cb'
    },
    regHalts: {
        background: '#ff2828'
    },
    systemIssues: {
        background: '#ffde27'
    }
});

export function HaltedSymbolsPanel(props): JSX.Element {
    const classes = useStyles(props);
    const coreSession = useStore('coreSession');

    const rowHeight = 32;
    const rowData = coreSession.haltedSymbolsRowData;

    const columnDefs = useRef({
        default: haltedSymbolsColumnDefs
    });

    const getHeight = useCallback(
        (data, rowHeight) =>
            clamp(
                data
                    ? rowHeight *
                          Math.max(
                              data.tapeA.length,
                              data.tapeB.length,
                              data.tapeC.length
                          ) +
                          2
                    : rowHeight * 3 + 2,
                rowHeight * 3 + 2,
                rowHeight * 10 + 2
            ),
        []
    );

    const getRowHeight = useCallback(
        (params) =>
            getHeight(params.data, params.context.rowHeight) -
            params.context.rowHeight -
            2,
        [getHeight]
    );

    const height = useMemo(() => getHeight(rowData[0], rowHeight), [
        rowData,
        rowHeight
    ]);

    const context = useMemo(
        () => ({
            classes,
            getRowHeight,
            rowHeight: rowHeight
        }),
        [classes, getRowHeight, rowHeight]
    );

    return (
        <AgGridProvider
            columnDefs={columnDefs.current}
            columnNamespace='haltedSymbols'
            error={coreSession.error}
            isLoading={coreSession.isLoading}
            onLoad={noop}
            primaryKey='uuid'
            rowData={rowData}
            timestamp={coreSession.timestamp}>
            <Box alignItems='center' className='panel-heading' display='flex'>
                <span className='panel-title'>Halted Symbols</span>
                <AgGridColumnMenuTrigger
                    className='mdi mdi-settings mdi-18px flex-pull-right'
                    component={<a />}
                />
            </Box>

            <div className='panel-body'>
                <AgGrid
                    autoSizeColumns
                    context={context}
                    height={height}
                    getRowHeight={getRowHeight}
                    noBoxShadow
                />
            </div>

            <Box alignItems='center' className='panel-footer' display='flex'>
                <AgGridTimestamp />
                <Box
                    alignItems='center'
                    display='flex'
                    flexDirection='row'
                    ml='auto'>
                    {coreSession.haltLabels.map((state) => (
                        <React.Fragment key={state.label}>
                            <Box
                                className={classes[state.group]}
                                height={10}
                                mr={1}
                                width={10}
                            />
                            <Box data-e2e-tag='haltLabel' mr={2}>
                                {state.label}
                            </Box>
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        </AgGridProvider>
    );
}
