export const myVolumesCadvDayColumnDefs = [
    {
        headerName: 'All Tapes',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Volume',
                field: 'allTapesMtd',
                type: 'number',
                width: 125
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Tape A',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Volume',
                field: 'tapeAMtd',
                type: 'number',
                width: 100
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Tape B',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Volume',
                field: 'tapeBMtd',
                type: 'number',
                width: 100
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Tape C',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Volume',
                field: 'tapeCMtd',
                type: 'number',
                width: 100
            }
        ],
        marryChildren: true
    }
];
