import {SelectInput} from 'nyse-web-tools-common/lib/react';
import React, {useMemo} from 'react';
import {CustFirm, openCloseChoices, SideTradeType} from '../../resources/tradeSummary.resource';

type MultiEditOpenCloseSelectProps = {
    data?: any,
    context?: any,
    name: string,
    value?: string,
    onChange?: any
};

export const MultiEditOpenCloseSelect = React.memo<MultiEditOpenCloseSelectProps>((props) => {
    const options = useMemo(() => {
        const isFloorBrokerOnBehalfOfLocalMM = props?.data?.custTradeType === SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isFloorBrokerOnBehalfOfAwayMM = props?.data?.custTradeType === SideTradeType.FLOOR_BROKER_AWAY_MM;
        const isLocalMM = props?.data?.custFirm === CustFirm.MarketMaker;
        const isAwayMM = props?.data?.custFirm === CustFirm.AwayMarketMaker;
        const isLocalOrAwayMM = isLocalMM || isAwayMM;
        const options = [...openCloseChoices];
        (isLocalOrAwayMM || isFloorBrokerOnBehalfOfLocalMM || isFloorBrokerOnBehalfOfAwayMM) && options.push({label: '-', value: ''});
        return options;
    }, [props?.data?.custTradeType, props?.data?.custFirm]);

    return (
        <SelectInput
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            options={options}
        />
    );
});
