import {NyModal} from 'nyse-web-tools-common/lib/react';
import React from 'react';

import {MultiTradeAdjustmentProps} from '../../../resources/tradeSummary.resource';
import {MultiApprovalForm} from './forms/multiApproval.form';

export const MultiApprovalModal = React.memo(
    (props: MultiTradeAdjustmentProps) => {

        return (
            <NyModal
                className='modal-large'
                hideModal={props.hideModal}
                name='multiTransactionApproval'
                show={props.show}
                parentTitle='Transactions'
                title={'Approve/Reject'}>
                <div className='col-xs-12 flex-column'>
                    <MultiApprovalForm
                        data={props.data}
                        crd={props.crd}
                        onClose={props.onClose}
                        isApproveAction={props.isApproveAction}
                    />
                </div>
            </NyModal>
        );
    }
);
