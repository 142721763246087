import {Box} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import cn from 'classnames';
import {orderBy} from 'lodash-es';
import {NyAccordion} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {Link} from './navigation.link';

@connect(['platform', 'menu', 'router', 'route', 'user'])
export class NavigationMenuDesktop extends React.Component {
    static propTypes = {
        activePlatformMenu: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        activePlatform: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        isActivePlatform: PropTypes.func,
        isActiveState: PropTypes.func
    };

    state = {
        activePlatformMenu: '',
        activePlatform: '',
        activeState: '',
        showBanner: false,
        showSideNav: false
    };

    static getDerivedStateFromProps(props, state) {
        const didStateChange =
            props.router.location.pathname !== state.activeState;

        return {
            activePlatformMenu: didStateChange
                ? false
                : state.activePlatformMenu,
            activePlatform: props.route.platform.toUpperCase(),
            activeState: props.router.location.pathname,
            showSideNav: didStateChange ? false : state.showSideNav
        };
    }

    _onMenuOpen = ({
        currentTarget: {
            dataset: {title}
        }
    }) => {
        this.setState({
            activePlatformMenu: title
        });
    };

    _onMenuClose = () => {
        this.setState({
            activePlatformMenu: false
        });
    };

    render() {
        return (
            <>
                <div
                    className='hidden-xs flex-column'
                    style={{
                        flex: '0 0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative'
                    }}>
                    <ul
                        className={cn('nav nav-flyout', {
                            'is-open': this.props.showSideNav
                        })}
                        style={{
                            paddingBottom: '0.5em',
                            paddingTop: '0.5em',
                            background: 'rgba(0, 0, 0, 0.8)'
                        }}>
                        {!this.props.user.isJwtValid && (
                            <li>
                                <Link to='/login'>Login</Link>
                            </li>
                        )}
                        {
                            this.props.user.isJwtValid && (
                                <li
                                    data-e2e-tag='navPlatform'
                                    data-title='home'
                                    data-platform='HOME'
                                    className={cn({active: this.state.activeState === '/home'})}
                                    onClick={this._onMenuOpen}
                                >
                                    <Link to='/home'>Home</Link>
                                </li>
                            )
                        }
                        {this.props.menu.map(({title, mic}) => (
                            <li
                                key={`${mic}-desktop`}
                                data-e2e-tag='navPlatform'
                                data-title={mic}
                                data-platform={mic}
                                className={cn({
                                    active:
                                        this.state.activePlatform === mic ||
                                        this.state.activePlatformMenu === mic
                                })}
                                onClick={this._onMenuOpen}>
                                <a>{title}</a>
                            </li>
                        ))}
                        {
                            this.props.user.entitlement === 'SUPER_USER' && (
                                <li
                                    data-e2e-tag='navPlatform'
                                    data-title='system'
                                    data-platform='SYSTEM'
                                    className={cn({active: this.state.activeState === '/system/broadcast-message'})}
                                    onClick={this._onMenuOpen}
                                >
                                    <a>System</a>
                                </li>
                            )
                        }
                    </ul>
                    {this.props.menu.map(({mic, ...rest}) => (
                        <NyAccordion
                            key={mic}
                            show={this.state.activePlatformMenu === mic}
                            style={{
                                position: 'absolute',
                                top: '100%',
                                width: '100%',
                                zIndex: 1
                            }}>
                            <div
                                className='container nav-flyout-drawer'
                                data-e2e-tag='navPlatformMenu'
                                data-platform={mic}
                                data-active={(
                                    this.state.activePlatformMenu === mic
                                ).toString()}>
                                <ClickAwayListener
                                    touchEvent={
                                        this.state.activePlatformMenu !== mic
                                            ? false
                                            : 'onTouchStart'
                                    }
                                    mouseEvent={
                                        this.state.activePlatformMenu !== mic
                                            ? false
                                            : 'onClick'
                                    }
                                    onClickAway={this._onMenuClose}>
                                    <Box
                                        alignItems='flex-start'
                                        display='flex'
                                        flexDirection='row'
                                        flexWrap='wrap'
                                        width='100%'>
                                        {rest.menu.map(({title, mic, menu}) => (
                                            <Box
                                                display='flex'
                                                flex='0 0 190px'
                                                flexDirection='column'
                                                key={title}>
                                                <p className='list-featured-header'>
                                                    {title}
                                                </p>
                                                <ul className='list-featured'>
                                                    {orderBy(
                                                        menu,
                                                        'groupOrder'
                                                    ).map((item) => (
                                                        <li
                                                            key={`/${mic.toLowerCase()}${
                                                                item.url
                                                            }`}
                                                            data-e2e-tag='navPlatformLink'
                                                            data-title={
                                                                item.title
                                                            }
                                                            className='nav-link'>
                                                            <Link
                                                                to={item.url}
                                                                params={{
                                                                    platform: mic.toLowerCase(),
                                                                    ...(item.defaultParams ||
                                                                        {})
                                                                }}>
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Box>
                                        ))}
                                    </Box>
                                </ClickAwayListener>
                            </div>
                        </NyAccordion>
                    ))}
                    {
                        this.props.user.entitlement === 'SUPER_USER' && (
                            <NyAccordion
                                show={this.state.activePlatformMenu === 'system'}
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    width: '100%',
                                    zIndex: 1
                                }}>
                                <div
                                    className='container nav-flyout-drawer'
                                    data-e2e-tag='navPlatformMenu'
                                    data-platform='SYSTEM'
                                    data-active={(
                                        this.state.activePlatformMenu === 'system'
                                    ).toString()}>
                                    <ClickAwayListener
                                        touchEvent={
                                            this.state.activePlatformMenu !== 'system'
                                                ? false
                                                : 'onTouchStart'
                                        }
                                        mouseEvent={
                                            this.state.activePlatformMenu !== 'system'
                                                ? false
                                                : 'onClick'
                                        }
                                        onClickAway={this._onMenuClose}>
                                        <Box
                                            alignItems='flex-start'
                                            display='flex'
                                            flexDirection='row'
                                            flexWrap='wrap'
                                            width='100%'
                                        >
                                            <Box
                                                display='flex'
                                                flex='0 0 190px'
                                                flexDirection='column'
                                            >
                                                <ul className='list-featured'>
                                                    <li data-e2e-tag='navPlatformLink' data-title='Broadcast Message'>
                                                        <Link to='/system/broadcast-message'>Broadcast Message</Link>
                                                    </li>
                                                </ul>
                                            </Box>
                                        </Box>
                                    </ClickAwayListener>
                                </div>
                            </NyAccordion>
                        )
                    }
                </div>
            </>
        );
    }
}
