export const symbolAdvListColumnDefs = [
    {
        headerName: 'Symbol',
        field: 'symbol',
        width: 110
    },
    {
        headerName: 'Range ID',
        field: 'rangeId',
        width: 80
    },
    {
        headerName: 'ADV',
        field: 'adv',
        type: 'numericString',
        width: 200
    }
];
