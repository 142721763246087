import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NyAlert from 'nyse-web-tools-common/lib/react/generic/NyAlert/NyAlert';
import NyLoader from 'nyse-web-tools-common/lib/react/generic/NyLoader/NyLoader';
import NyMasonry from 'nyse-web-tools-common/lib/react/generic/NyMasonry/NyMasonry';
import React, {useCallback} from 'react';

import {DownloadLink} from '../components/displays/download.link';
import useResource from '../hooks/useResource.hook';
import documentLinksResource, {
    Documents
} from '../resources/documentLinks.resource';

const useStyle = makeStyles((theme) => ({
    alert: {
        background: 'white',
        marginBottom: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto'
    },
    container: {
        height: `calc(100% - ${theme.spacing(1)}px)`
    },
    header: {
        color: '#565a5c',
        fontWeight: 500,
        letterSpacing: '.0375em',
        textTransform: 'uppercase'
    },
    loader: {
        marginBottom: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto'
    }
}));

export const DocumentLinksPage: React.FC = React.memo((props) => {
    const classes = useStyle(props);

    const resource = useResource<Documents>(documentLinksResource, true);

    const renderCell = useCallback(
        (cell) => (
            <Box
                data-e2e-tag='category'
                data-index={cell.index.toString()}
                data-title={cell.category}
                display='flex'
                flexDirection='column'>
                <Box className={classes.header}>{cell.category}</Box>
                {cell.resources.map((resource) => (
                    <DownloadLink key={resource.title} {...resource} />
                ))}
            </Box>
        ),
        [classes.header]
    );

    return (
        <>
            <NyLoader
                data-e2e-tag='loader'
                className={classes.loader}
                show={resource.pending && !resource.error}
            />
            {!resource.pending && !!resource.error && (
                <NyAlert
                    className={classes.alert}
                    title={resource.error.title}
                    detail={resource.error.detail}
                    type={resource.error.type}
                    show
                />
            )}
            {!resource.pending && !resource.error && (
                <Box className={classes.container} display='flex' mt={1}>
                    <NyMasonry
                        list={resource.data}
                        overscanByPixels={20}
                        renderCell={renderCell}
                        show
                    />
                </Box>
            )}
        </>
    );
});
