import {events} from '../events';

export const myVolumesSearchReducer = {
    [events.MY_VOLUMES_SEARCH_GET]: (state = {}) => ({
        ...state,
        isLoading: true,
        timestamp: false
    }),
    [events.MY_VOLUMES_SEARCH_SUCCESS]: (
        state,
        {
            payload: {
                data: {data}
            }
        }
    ) => {
        const endDate = data.endDate.replace(/-/g, '/');
        const startDate = data.startDate.replace(/-/g, '/');

        return {
            isLoading: false,
            error: false,
            more: false,
            endDate,
            startDate,
            rowData: [
                {
                    ...data.volume,
                    categoryName: 'Volume'
                },
                {
                    ...data.trade,
                    categoryName: 'Trades'
                },
                {
                    ...data.notional,
                    categoryName: 'Notional'
                }
            ],
            timestamp: `${startDate} - ${endDate}`
        };
    },
    [events.MY_VOLUMES_SEARCH_ERROR]: (state, {error}) => ({
        error,
        isLoading: false,
        more: false,
        rowData: [],
        timestamp: null
    })
};
