import {QueryClient} from 'react-query';

const reactQueryConfig = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
};

export const reactQueryClient = new QueryClient(reactQueryConfig);
