import {LocalDateTime} from '@js-joda/core';
import copy from 'copy-text-to-clipboard';
import {
    NyButton,
    NyModal,
    NyTooltip,
    TextInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useToggle} from 'react-use';

export const ActivityReportModal = React.memo((props) => {
    const [copied, setCopied] = useState(false);

    const createdTime = useMemo(
        () =>
            joda.format(
                LocalDateTime.parse(props.createdTime),
                'MM/dd/yyyy HH:mm:ss.SSS'
            ),
        [props.createdTime]
    );

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => setCopied(false), 1000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [copied]);

    const onCopy = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        setCopied(copy(JSON.stringify(props, null, 2)));
    }, []);

    const [show, toggleShow] = useToggle(false);

    return (
        <>
            <NyButton
                circle
                data-e2e-tag='activityReportBtn'
                data-user-id={props.userId}
                data-user-name={props.userName}
                data-client-id={props.clientId}
                data-action={props.action}
                className='mdi mdi-details mdi-18px'
                onClick={toggleShow}
            />
            <NyModal
                hideModal={toggleShow}
                name='activityReport'
                parentTitle='Activity Report'
                title={
                    <>
                        <span className='text-transform-none'>
                            {props.userName}
                        </span>{' '}
                        -&nbsp;
                        <small className='flex-pull-right'>{createdTime}</small>
                    </>
                }
                show={show}>
                <div className='row flex-0-0'>
                    <div className='form-group col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <label>User Id</label>
                        <TextInput
                            name='userId'
                            readOnly
                            value={props.userId}
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <label>Action</label>
                        <TextInput
                            name='action'
                            readOnly
                            value={props.action}
                        />
                    </div>
                </div>
                <div className='flex-column overflow-y'>
                    {props.response && (
                        <div className='form-group flex-column'>
                            <label className='flex-0-0'>Response</label>
                            <pre className='overflow-y'>
                                {JSON.stringify(props.response, null, 2)}
                            </pre>
                        </div>
                    )}
                    {props.request && (
                        <div className='form-group flex-column'>
                            <label className='flex-0-0'>Request</label>
                            <pre className='overflow-y'>
                                {JSON.stringify(props.request, null, 2)}
                            </pre>
                        </div>
                    )}
                </div>
                <div className='form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 flex-0-0'>
                    <label className='flex-row'>
                        Copy Request/Response&nbsp;&nbsp;
                        <NyTooltip overlay='Copied!' visible={copied}>
                            <a
                                className='mdi mdi-content-copy'
                                onClick={onCopy}
                            />
                        </NyTooltip>
                    </label>
                </div>
            </NyModal>
        </>
    );
});

ActivityReportModal.propTypes = {
    action: PropTypes.string,
    response: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    createdTime: PropTypes.string,
    request: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    userId: PropTypes.string,
    userName: PropTypes.string
};
