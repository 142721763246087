import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

@connect(['user', 'platform', 'platforms'])
export class Access extends React.Component {
    static propTypes = {
        every: PropTypes.bool,
        isMultiPlatform: PropTypes.bool,
        permissions: PropTypes.arrayOf(PropTypes.string),
        platform: PropTypes.shape({
            mic: PropTypes.string
        }),
        platforms: PropTypes.arrayOf(
            PropTypes.shape({
                mic: PropTypes.string,
                name: PropTypes.string
            })
        ),
        user: PropTypes.object
    };

    static defaultProps = {
        every: false,
        isMultiPlatform: false,
        permissions: [],
        user: PropTypes.object
    };

    hasPermissions(userPerms, platforms, strict) {
        if (!this.props.user.isJwtValid || platforms.length === 0) {
            return false;
        }

        return (
            userPerms.length === 0 ||
            platforms.some((mic) => {
                const micPerms = userPerms[mic].clientResourceNames.map((p) =>
                    p.toLowerCase()
                );

                return this.props.permissions[strict ? 'every' : 'some']((p) =>
                    micPerms.includes(p.toLowerCase())
                );
            })
        );
    }

    render() {
        const hasMultiplePerms = this.props.permissions.length > 1;

        const platforms = this.props.isMultiPlatform
            ? this.props.platforms.map(({mic}) => mic)
            : [this.props.platform.mic];

        return (
            <>
                {this.hasPermissions(
                    this.props.user.permissions,
                    platforms,
                    !hasMultiplePerms || this.props.every
                ) && this.props.children}
            </>
        );
    }
}
