export const quoteMetricsColumnDefs = [
    {
        headerName: 'Exchange',
        headerClass: 'text-center',
        pinned: 'left',
        children: [
            {
                headerName: 'Group',
                field: 'exchangeGroup',
                width: 125
            },
            {
                headerName: 'Name',
                field: 'exchange',
                width: 125
            },
            {
                headerName: 'Volume',
                field: 'exchangeVolume',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Market Share',
                field: 'exchangeVolumeMarketShare',
                type: 'percentage',
                width: 150
            },
            {
                headerName: 'Time at Inside %',
                field: 'pctTimeNbbo',
                type: 'percentage',
                width: 150
            },
            {
                headerName: '% BBO Is Largest In NBBO',
                field: 'pctTimeLargerNbbo',
                type: 'percentage',
                width: 240
            },
            {
                headerName: '% Of Core Hours = NBB',
                field: 'bidPctFullDay',
                type: 'percentage',
                width: 175
            },
            {
                headerName: '% Of Core Hours = NBO',
                field: 'ofrPctFullDay',
                type: 'percentage',
                width: 175
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Average',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Spread Cents',
                field: 'spreadCents',
                type: 'price',
                width: 150
            },
            {
                headerName: 'Spread BPS',
                field: 'spreadBps',
                type: 'price',
                width: 150
            },
            {
                headerName: 'NBBO Quote Size',
                field: 'avgBboQuoteSize',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Size At NBB',
                field: 'bidTwsFullDay',
                type: 'number',
                width: 150
            },
            {
                headerName: 'Size At NBO',
                field: 'ofrTwsFullDay',
                type: 'number',
                width: 150
            }
        ],
        marryChildren: true
    }
];
