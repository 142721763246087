import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import stringify from 'fast-json-stable-stringify';
import {get} from 'lodash-es';
import {Store} from 'redux';
import {v4} from 'uuid';

import {cleanObject} from '../../utils/utilities';
import * as enums from '../enums';

export async function cdmmwRequestInterceptor(
    store: Store,
    config: AxiosRequestConfig & {isPillarSource?: boolean}
): Promise<AxiosRequestConfig | AxiosResponse> {
    const state = store.getState();

    const micIsUp = state.pillarStatus[state.platform.mic];
    const user = state.user;
    const entitlement =
        state.user.entitlement === 'SUPER_USER'
            ? state.entitlement.name || 'MEMBER_FIRM'
            : state.user.entitlement;

    const mic = get(state, 'route.url', '') === '/system/broadcast-message' ? ''
        : (get(config, 'params.platform', undefined) || state.platform.mic);

    if (user.isJwtThreshold) {
        throw new axios.Cancel(enums.AUTH_THRESHOLD);
    }

    if (config.isPillarSource && !micIsUp) {
        throw new axios.Cancel(enums.PILLAR_OFFLINE);
    }

    // @ts-ignore
    if (window.MOCK_MODE) {
        config.headers['Mock-Mode'] = true;
        //eslint-disable-next-line no-console
        console.warn('[Mock Mode Enable] the API::', config.url);
    }

    const userRequestId = v4();

    return {
        ...config,
        headers: {
            userRequestId,
            ...config.headers,
            Authorization: stringify(
                cleanObject({
                    accessToken: state.user.sentinel.accessToken,
                    mic,
                    entitlement
                })
            )
        }
    };
}
