import {LocalDateTime} from '@js-joda/core';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridProvider,
    AgGridTimestamp,
    NyModal,
    NyPropertyList
} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {setColumns} from '../../../stores/columnDefs/columnDefs.actions';
import {getOpraEntries} from '../../../stores/opraEntries/opraEntries.actions';
import {OPRAEntryCreateBtn} from '../../buttons/opraEntry/opraEntryCreate.btn';
import {OPRAEntryRemoveBtn} from '../../buttons/opraEntry/opraEntryRemove.btn';
import {OPRAEntryModal} from './opraEntry.modal';

@connect(['columnDefs', 'permissions', 'platform', 'opraEntries'], {
    getOpraEntries,
    setColumns
})
export class OPRAEntriesModal extends React.PureComponent {
    static propTypes = {
        crd: PropTypes.number.isRequired,
        trade: PropTypes.shape({
            dealId: PropTypes.string.isRequired,
            executionTime: PropTypes.string.isRequired,
            symbol: PropTypes.string.isRequired,
            dealIdOrig: PropTypes.string.isRequired
        }),
        onClose: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired
    };

    state = {
        isSuccessful: false,
        selected: []
    };

    _initialFilters = {
        crd: this.props.crd,
        dealId: this.props.trade.dealId,
        tradeExecDate: LocalDateTime.parse(this.props.trade.executionTime)
    };

    _initialPagination = {
        limit: 100000,
        offset: 0
    };

    componentDidUpdate({show}) {
        if (!show && this.props.show) {
            this.setState({
                isSuccessful: false
            });
        }
    }

    _onClose = () => {
        this.props.onClose(this.state.isSuccessful);
    };

    _onEditModalClosed = (isSuccessful) => {
        this.setState({
            isSuccessful
        });
    };

    _onLoad = (values) => {
        this.props.getOpraEntries({
            ...values,
            crd: this.props.crd,
            dealId: this.props.trade.dealId,
            // todo:: figure out how to get UTC here
            tradeExecDate: LocalDateTime.parse(this.props.trade.executionTime)
        });
    };

    _onModalClosed = (isSuccessful) => {
        this.setState(
            {
                isSuccessful
            },
            () => {
                this.props.getOpraEntries({
                    ...this._initialPagination,
                    ...this._initialFilters
                });
            }
        );
    };

    _onSelectionChanged = (selected) => {
        this.setState({
            selected: selected.filter(({lock}) => !lock)
        });
    };

    render() {
        return (
            <NyModal
                className='medium-large'
                hideModal={this._onClose}
                name={this.props.name}
                show={this.props.show}
                parentTitle='QCT Trades'
                title='OPRA Entries'>
                {() => (
                    <div className='flex-row overflow-none flex-1'>
                        <AgGridProvider
                            className='col-xs-12 col-sm-8 col-md-8 col-lg-9 flex-column flex-0-0'
                            autoSize={true}
                            columnDefs={this.props.columnDefs.opraEntries}
                            columnNamespace='opraEntries'
                            initialFilters={this._initialFilters}
                            initialPagination={this._initialPagination}
                            onColumnsChanged={this.props.setColumns}
                            onLoad={this.props.getOpraEntries}
                            onSelectionChanged={this._onSelectionChanged}
                            permissions={this.props.permissions}
                            rowSelection='multiple'
                            submitOnLoad
                            suppressRowClickSelection={true}
                            {...this.props.opraEntries}>
                            <div className='row space-bottom-1'>
                                <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                                    <div className='flex-row flex-align-bottom'>
                                        <div className='btn-circle-grp flex-pull-right flex-row'>
                                            <OPRAEntryCreateBtn
                                                crd={this.props.crd}
                                                dealId={this.props.trade.dealId}
                                                symbol={this.props.trade.symbol}
                                                onClose={this._onModalClosed}
                                                tradeExecTime={
                                                    this.props.trade
                                                        .executionTime
                                                }
                                                dealIdOrig={this.props.trade.dealIdOrig}
                                            />
                                            <OPRAEntryRemoveBtn
                                                onClose={this._onModalClosed}
                                                opraEntries={
                                                    this.state.selected
                                                }
                                            />
                                            <div className='divider' />
                                            <AgGridExportMenu
                                                exportFileName='opraEntry'
                                                exportSheetName={
                                                    this.props.platform.name
                                                }
                                            />
                                            <AgGridColumnMenuTrigger />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <AgGrid
                                DetailsModal={OPRAEntryModal}
                                onDetailsClosed={this._onEditModalClosed}
                                submitOnDetailsClosed
                                reactNext={true}
                            />

                            <div className='row space-top-1 space-bottom-2'>
                                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                    <AgGridTimestamp />
                                </div>
                            </div>
                        </AgGridProvider>
                        <NyPropertyList
                            className='col-xs-12 col-sm-4 col-md-4 col-lg-3 flex-column'
                            columnDefs={this.props.columnDefs.trades.default}
                            object={this.props.trade}
                        />
                    </div>
                )}
            </NyModal>
        );
    }
}
