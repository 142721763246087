import React from 'react';

import {cdmMw} from '../api';

export enum Permissions {
    adjustTradeSummary = 'adjust trade summary',
    allocateTradeSummary = 'allocate trade summary',
    contraApproveTradeSummary = 'contra approve allocation trade summary',
    recallTradeSummary = 'recall allocation trade summary',
    splitTradeSummary = 'split trade summary',
    unallocateTradeSummary = 'unallocate trade summary',
    viewAllocatedTradeSummary = 'view allocated trade summary',
    viewFloorTradeSummary = 'view floor trade summary',
    viewTransactions = 'view trade summary transactions',
    viewTransactionHistory = 'view trade summary transaction history'
}

export enum Views {
    allocated = 'allocated',
    floorTrades = 'floor-trades',
    transactions = 'transactions',
    transactionHistory = 'transaction-history'
}

export enum Columndefs {
    allocated = 'tradeSummaryAllocated',
    'floor-trades' = 'tradeSummaryFloorTrade',
    transactions = 'tradeSummaryTransaction',
    'transaction-history' = 'tradeSummaryTransactionHistory'
}

export enum TradeSummaryTabs {
    allocate = 'ALLOCATE',
    adjust = 'ADJUST',
    split = 'SPLIT',
    floorTrade = 'FLOORTRADE',
    recall = 'RECALL',
    contraApproval = 'CONTRA_APPROVE'
}

export const fieldsToOmitFromUrlQueryParams = [
    'allocatedTradeSummary',
    'crossId',
    'crossId',
    'custClOrdId',
    'custClearingNumber',
    'custCmta',
    'custFirm',
    'custFirmId',
    'custIdPub',
    'custLqdtyInd',
    'custOpenClose',
    'custSessionId',
    'custSide',
    'custSndrSubId',
    'custSubmittingBkrId',
    'dealIdOrig',
    'dealIdRef',
    'dealNum',
    'endExecQty',
    'endOrdPrc',
    'endOrdPrc',
    'endRefExecTs',
    'endUpdatedTs',
    'expirationDate',
    'isFullyAllocated',
    'tradeSequenceNumber',
    'osiRootSymb',
    'putCall',
    'startExecQty',
    'startOrdPrc',
    'startOrdPrc',
    'startRefExecTs',
    'startUpdatedTs',
    'status',
    'strikePrice',
    'symb',
    'underlyingSymbol',
    'updatedDate',
    'view'
];

export enum CustFirm {
    Customer = '0',
    Firm = '1',
    Broker = '2',
    MarketMaker = '3',
    AwayMarketMaker = '4',
    ProfessionalCustomer = '8'
}

export enum SideTradeType {
    ELECTRONIC = 'ELECTRONIC',
    LOCAL_MM = 'LOCAL_MM',
    AWAY_MM = 'AWAY_MM',
    FLOOR_BROKER = 'FLOOR_BROKER',
    FLOOR_BROKER_LOCAL_MM = 'FLOOR_BROKER_LOCAL_MM',
    FLOOR_BROKER_AWAY_MM = 'FLOOR_BROKER_AWAY_MM',
    NA = 'NA'
}

export const keepAsIs = [
    {
        label: '- Keep as is -',
        value: 'Keep as is'
    }
];

export const custFirmChoices = [
    {
        label: 'Customer',
        value: CustFirm.Customer
    },
    {
        label: 'Firm',
        value: CustFirm.Firm
    },
    {
        label: 'BD Customer',
        value: CustFirm.Broker
    },
    {
        label: 'Market Maker',
        value: CustFirm.MarketMaker
    },
    {
        label: 'Away Market Maker',
        value: CustFirm.AwayMarketMaker
    },
    {
        label: 'Professional Customer',
        value: CustFirm.ProfessionalCustomer
    }
];

export enum OpenClose {
    Open = 'O',
    Close = 'C'
}

export enum myOwnership {
    ORD = 'ORD',
    CONTRA = 'CONTRA',
    BOTH = 'BOTH'
}

export const openCloseChoices = [
    {
        label: 'Open',
        value: OpenClose.Open
    },
    {
        label: 'Close',
        value: OpenClose.Close
    }
];

export const myOwnershipChoices = [
    {
        label: 'Buy Side',
        value: myOwnership.ORD
    },
    {
        label: 'Sell Side',
        value: myOwnership.CONTRA
    },
    {
        label: 'Both Side',
        value: myOwnership.BOTH
    }
];

export type EventType = 'ALC' | 'RALC' | 'INF';
export type TradeType =
    | 'LOCAL_MM'
    | 'AWAY_MM'
    | 'ELECTRONIC'
    | 'FLOOR_BROKER'
    | 'FLOOR_BROKER_LOCAL_MM'
    | 'FLOOR_BROKER_AWAY_MM'
    | 'NA';

export type AllowedTradeActions =
    | 'ALLOCATE'
    | 'ADJUST'
    | 'SPLIT'
    | 'UNALLOCATE'
    | 'RECALL';

export const AWAY_MARKET_MAKER = 'Away Market Maker';

export const MAX_ALLOWED_SLICES = 50;

export type TradeSummaryObj = {
    allowedTradeActions?: string[];
    crd?: number;
    custAccount?: string;
    custClearingNumber?: string;
    custCmta?: string;
    custCrd?: number;
    custFirm?: string;
    custFirmId?: string;
    custOpenClose?: string;
    custSndrSubId?: string;
    custSubmittingBkrId?: string;
    custOptnlData?: string;
    custOmsId?: string;
    custOmsCrd?: number;
    custCapStrategyId?: string;
    dealNum?: string;
    dealIdRef?: string;
    dealIdOrig?: string;
    evtTyp?: EventType;
    origTdate?: string;
    tdate?: string;
    userId?: string;
    underlyingSymbol?: string;
    opraSymbol?: string;
    osiSymbol?: string;
    execPrc?: number;
    execQty?: number;
    quantity?: number;
    reason?: string;
    reasonCode?: string;
    otherReason?: string;
    contraOrdCrd?: number;
    contraOrdOpenClose?: string;
    contraOrdFirmId?: string;
    contraOrdSndrSubId?: string;
    contraOrdClearingNumber?: string;
    contraOrdCustFirm?: string;
    contraOrdSubmittingBkrId?: string;
    contraOrdOmsId?: string;
    contraOrdOmsCrd?: number;
    contraOrdCapStrategyId?: string;
    selectedSide?: string;
    custSide: string;
    contraOrdSide: string;
    tradeCanBeUpdated?: boolean;
    updateToTradeIsInProgress?: boolean;
    custTradeType?: TradeType;
    contraOrdTradeType?: TradeType;
    qccTrade?: boolean;
    outcryTrade?: boolean;
    allocations?: number;
    inFlightAllocations?: number;
    status: string;
    txId?: string;
};

export type TradeSummaryObjEx = TradeSummaryObj & {
    custAccountOriginalValue?: string;
    custClearingNumberOriginalValue?: string;
    custCmtaOriginalValue?: string;
    custFirmOriginalValue?: string;
    custFirmIdOriginalValue?: string;
    custOpenCloseOriginalValue?: string;
    custSndrSubIdOriginalValue?: string;
    custSubmittingBkrIdOriginalValue?: string;
    custOptnlDataOriginalValue?: string;

    custAccountIsUpdatedByUser?: boolean;
    custClearingNumberIsUpdatedByUser?: boolean;
    custCmtaIsUpdatedByUser?: boolean;
    custFirmIsUpdatedByUser?: boolean;
    custFirmIdIsUpdatedByUser?: boolean;
    custOpenCloseIsUpdatedByUser?: boolean;
    custSndrSubIdIsUpdatedByUser?: boolean;
    custSubmittingBkrIdIsUpdatedByUser?: boolean;
    custOptnlDataIsUpdatedByUser?: boolean;
};

export type DealCompoundKey = {
    origTdate?: string;
    evtTyp?: EventType;
    dealNum?: string;
};

export type FieldsOfInterestInMultiAdjustForm =
    | 'custAccount'
    | 'custClearingNumber'
    | 'custCmta'
    | 'custFirm'
    | 'custFirmId'
    | 'custOpenClose'
    | 'custSndrSubId'
    | 'custSubmittingBkrId'
    | 'custOptnlData'
    | 'custOmsId'
    | 'reasonCode'
    | 'otherReason'
    | 'custCapStrategyId';

export type FieldsInMultiAdjustPayload =
    | 'custAccount'
    | 'custClearingNumber'
    | 'custCmta'
    | 'custFirm'
    | 'custFirmId'
    | 'custOpenClose'
    | 'custSndrSubId'
    | 'custSubmittingBkrId'
    | 'custOptnlData'
    | 'selectedSide'
    | 'reasonCode'
    | 'crd'
    | 'origTdate'
    | 'evtTyp'
    | 'dealNum'
    | 'contraOrdAccount'
    | 'contraOrdClearingNumber'
    | 'contraOrdCmta'
    | 'contraOrdCustFirm'
    | 'contraOrdFirmId'
    | 'contraOrdOpenClose'
    | 'contraOrdSndrSubId'
    | 'contraOrdSubmittingBkrId'
    | 'contraOrdOptnlData';

export type TradeSummaryProps = {
    className?: string;
    data: TradeSummaryObj;
    csgData: TradeSummaryObj;
    crd: number;
};

export type TransactionProps = {
    className?: string;
    data: TradeSummaryObj[];
    crd: number;
    onClose?: () => void;
    reasonCodeOptions?: any;
    isApproveAction?: boolean;
};

export type Option = {
    label: string;
    value: string;
    firmId?: string;
};

export type TradeActionSectionObject = {
    csgCustFirm: string;
    currentTab: TradeSummaryTabs;
    custClearingNumber: string;
    custCrd: number;
    custFirm: string;
    custFirmId: string;
    custSndrSubId: string;
    custSubmittingBkrId: string;
    custTradeType: TradeType;
    disabled?: boolean;
    myOwnership?: string;
    outcryTrade: boolean;
    qccTrade: boolean;
    selectedSide: string;
};

export type CustomerSideSectionObject = {
    csgCustFirm: string;
    currentTab: TradeSummaryTabs;
    custClearingNumber: string;
    custCrd: number;
    custOmsCrd?: number;
    custCrdUsingCustFirmId?: number;
    custOmsId?: string;
    custFirm: string;
    custFirmId: string;
    custTradeType: TradeType;
    disabled?: boolean;
    myOwnership?: string;
    outcryTrade: boolean;
    qccTrade: boolean;
    selectedSide: string;
    custFirmIdOptions?: Option[];
    custSubmittingBkrIdOptions: Option[];
    custSndrSubIdOptions: Option[];
    isMultiAdjustment: boolean;
    isMultiEditForAllFields: boolean;
    isMultiAdjustmentCapStrategyIdValid?: boolean;
    custCmta?: string;
    custAccount?: string;
    custOptnlData?: string;
    custSubmittingBkrId: string;
    custSide: string;
};

export type MultiEditCustomerSideSectionObject = {
    clearingNumberSideCrd: number;
    custFirmId: string;
    custClearingNumber: string;
    custSubmittingBkrIdOptions: Option[];
    custSndrSubIdOptions: Option[];
    disabled?: boolean;
    isFloorBroker: boolean;
    isMultiEditForAllFields: boolean;
    isMultiAdjustmentCapStrategyIdValid?: boolean;
};

export type ContraSideSectionObject = {
    contraOrdClearingNumber: string;
    contraOrdCrd: number;
    contraOrdOmsCrd?: number;
    contraOrdCustFirm: string;
    contraOrdCrdUsingContraOrdFirmId?: number;
    contraOrdFirmId: string;
    contraOrdOmsId?: string;
    contraOrdTradeType?: TradeType;
    csgContraOrdCustFirm: string;
    filteredOptions?: any;
    contraOrdFirmIdOptions?: Option[];
    contraOrdSndrSubIdOptions: Option[];
    contraOrdSubmittingBkrIdOptions: Option[];
    currentTab: TradeSummaryTabs;
    disabled?: boolean;
    myOwnership?: string;
    outcryTrade: boolean;
    qccTrade: boolean;
    selectedSide: string;
    contraOrdSide: string;
};

export type MultiTradeAdjustmentProps = {
    data: TradeSummaryObj[];
    crd: number;
    hideModal: () => void;
    show: boolean;
    onClose?: () => void;
    isApproveAction?: boolean
};

export type TradeAllocateProps = {
    className?: string;
    crd: number;
    csgData: TradeSummaryObj;
    data: TradeSummaryObj;
    myOwnership?: string;
};

export type TradeUnallocateProps = {
    className?: string;
    data: TradeSummaryObj;
    csgData: TradeSummaryObj;
    crd: number;
};

export type SubmitTradeSplitsFormProps = {
    crd: number;
    data: TradeSummaryObj;
    setClickedRow: React.Dispatch<React.SetStateAction<TradeSummaryObj>>;
    setTradeSplits: React.Dispatch<React.SetStateAction<TradeSummaryObj[]>>;
    tradeSplits: TradeSummaryObj[];
};

export enum Entitlement {
    CLEARING_FIRM = 'CLEARING_FIRM',
    MM_FIRM = 'MM_FIRM',
    BROKER_FIRM = 'BROKER_FIRM',
    TRADE_CHECKER = 'TRADE_CHECKER'
}

export const requiredFields = ['custFirmId', 'selectedSide'];

export const customerSideFields = [
    'custAccount',
    'custClearingNumber',
    'custCmta',
    'custFirm',
    'custOpenClose',
    'custOptnlData',
    'custSndrSubId',
    'custSubmittingBkrId',
    'custCapStrategyId'
];

export const contraSideFields = [
    'contraOrdFirmId',
    'contraOrdSndrSubId',
    'contraOrdSubmittingBkrId'
];

export const contraBothSideFields = [
    'contraOrdAccount',
    'contraOrdClearingNumber',
    'contraOrdCmta',
    'contraOrdCustFirm',
    'contraOrdOpenClose',
    'contraOrdOptnlData',
    'contraOrdCapStrategyId'
];

export const tradeAdjustmentResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealNum}/adjustment`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const multiTradeAdjustmentResource = {
    api: cdmMw,
    url: () => 'api/options-trades/multi-adjustment',
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const tradeSplitResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealNum}/split`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const tradeAllocateResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealNum}/allocate`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const tradeUnallocateResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealNum}/unallocate`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const tradeDetailResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealNum}`,
    parseResponse: ({data}) => data,
    defaultValue: {}
};

export const tradeApprovalResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealIdRef}/transactions/${urlParams.txId}/approve`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const tradeApprovalDetailResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealIdRef}/transactions/${urlParams.txId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const tradeRejectionResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealIdRef}/transactions/${urlParams.txId}/reject`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const tradeUnallocateDetailResource = {
    api: cdmMw,
    url: 'api/options-trades/allocated',
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
};

export const tradeRecallResource = {
    api: cdmMw,
    url: (urlParams) =>
        `api/options-trades/${urlParams.origTdate}/${urlParams.evtTyp}/${urlParams.dealIdRef}/transactions/${urlParams.txId}/recall`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: {}
};

export const stripSpecialChars = (value) => {
    return value ? value.replace(/[^a-zA-Z0-9 -]/, '') : value;
};

export const stripCharsForAccountOptionalData = (value) => {
    return value ? value.replace(/[,;|@><&'"]/g, '') : value;
};

export const getBuySellSideDisplayString = (value) => {
    return (value === '1' || value === '-2' ? 'Buy' :
        value === '-1' || value === '2' ? 'Sell' : '-');
};

export enum NotificationMessage {
    EQUAL_TO_1 = 'Please select at least 2 records to continue.',
    MORE_THAN_MAX_5 = 'Please select between 2 and 5 records to continue.',
    MORE_THAN_MAX_20 = 'Please select between 2 and 20 records to continue.',
    MORE_THAN_MAX_50 = 'Please select between 2 and 50 records to continue.',
    ACTION_ON_REVERSED_TRADES = 'Action not allowed on reversed trades. Please modify your selection to continue.',
    ACTION_ON_MORE_RECORDS = 'Action not allowed on one or more records. Please modify your selection to continue.',
    ACTION_LOCAL_MM_WITH_DIFF_MPID = 'Action not allowed on Local MM trades with different MPID values. Please modify your selection to continue.',
    ACTION_LOCAL_MM = 'Action only allowed on Local MM trades. Please modify your selection to continue.',
    HINT_ACTION_ON_REVERSED_TRADES = 'Action not allowed on reversed trade.',
    HINT_ACTION_ON_MORE_RECORDS = 'Action not allowed on this record.',
    HINT_ACTION_LOCAL_MM = 'Action only allowed on Local MM trade.'
}
