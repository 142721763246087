import downloadJs from 'downloadjs';

import {events} from '../events';

export const downloadsReducer = {
    [events.DOWNLOADS_GET]: (
        state,
        {
            payload: {
                request: {fileName}
            }
        }
    ) => {
        return {
            ...state,
            [fileName]: {
                error: false,
                isDownloading: true,
                progress: 0
            }
        };
    },
    [events.DOWNLOADS_PROGRESS]: (state, {payload: {fileName, progress}}) => {
        return {
            ...state,
            [fileName]: {
                error: false,
                isDownloading: true,
                progress
            }
        };
    },
    [events.DOWNLOADS_SUCCESS]: (state, {payload: {config, data}}) => {
        const {
            fileExtension,
            fileName
        } = config.reduxSourceAction.payload.request;

        downloadJs(data, [fileName, fileExtension].join('.'));

        return {
            ...state,
            [config.reduxSourceAction.payload.request.fileName]: {
                error: false,
                isDownloading: false,
                progress: 0
            }
        };
    },
    [events.DOWNLOADS_ERROR]: (
        state,
        {
            error,
            meta: {
                previousAction: {
                    payload: {
                        request: {fileName}
                    }
                }
            }
        }
    ) => {
        return {
            ...state,
            [fileName]: {
                error,
                isDownloading: false,
                progress: 0
            }
        };
    }
};
