import {clone, get, identity, isEmpty, pickBy} from 'lodash-es';
import {formatNumber} from "nyse-web-tools-common/lib/utils";

export const cleanObject = (object) => {
    return pickBy(clone(object), function pickParams(param) {
        if (typeof param === 'string') {
            return param.trim();
        }

        if (typeof param === 'object') {
            return !isEmpty(pickBy(clone(param), pickParams));
        }

        if (typeof param === 'number') {
            return true;
        }

        return identity(param);
    });
};

export const hasPermissions = (user = {}, permissions, platforms, strict) => {
    if (!user.isJwtValid || platforms.length === 0) {
        return false;
    }

    return (
        Object.keys(user.permissions).length === 0 ||
        platforms.some((mic) => {
            const micPerms = get(
                user.permissions,
                `${mic}.clientResourceNames`,
                []
            ).map((p) => p.toLowerCase());

            return Array.isArray(permissions) && permissions[strict ? 'every' : 'some']((p) =>
                micPerms.includes(p.toLowerCase())
            );
        })
    );
};

export const mapApiValue = (data, fieldName, options) => {
    const filteredChoices = options.filter(
        (choice) => choice.value === data[fieldName]
    );

    return filteredChoices.length > 0
        ? filteredChoices[0].label
        : data[fieldName];
};

export const getRangeLabel = (rangeLow, rangeHigh) => {
    if (rangeLow === undefined && rangeHigh === undefined)
        return '';
    else if (rangeLow === undefined)
        return `\u2264 ${formatNumber(rangeHigh)}`;
    else if (rangeHigh === undefined)
        return `> ${formatNumber(rangeLow)}`;
    else
        return `> ${formatNumber(rangeLow)} and \u2264 ${formatNumber(rangeHigh)}`;
};


export const hasRoles = (user, roles) => Array.isArray(roles) && roles.includes(get(user, 'entitlement', ''));
