import {events} from '../events';

export const symbolGroupsReducer = {
    [events.SYMBOL_GROUPS_GET]: (
        state,
        {
            payload: {
                request: {group}
            }
        }
    ) => ({
        ...state,
        ...(group === 'indexes'
            ? {
                  index1: {
                      options: [],
                      type: 'INDEX_1'
                  },
                  index2: {
                      options: [],
                      type: 'INDEX_2'
                  }
              }
            : {
                  [group]: {
                      options: [],
                      type: group
                  }
              }),
        error: false,
        isLoading: true
    }),
    [events.SYMBOL_GROUPS_SUCCESS]: (
        state,
        {
            payload: {
                data: {data},
                config: {group}
            }
        }
    ) => ({
        ...state,
        ...(group === 'indexes'
            ? {
                  ...data.reduce(
                      (acc, group) => ({
                          ...acc,
                          [group.type]: {
                              options: group.options.map((option) => ({
                                  label: option.label
                                      .replace(/[\u{0080}-\u{FFFF}]/gu, '')
                                      .replace(/""|"/gu, ''),
                                  value: option.value,
                                  type: group.type
                              }))
                          }
                      }),
                      {}
                  )
              }
            : {
                  [data.type]: {
                      ...data,
                      options: data.options.map((option) => ({
                          label: option.label
                              .replace(/[\u{0080}-\u{FFFF}]/gu, '')
                              .replace(/""|"/gu, ''),
                          value: option.value
                      }))
                  }
              }),
        error: false,
        isLoading: false
    }),
    [events.SYMBOL_GROUPS_ERROR]: (state, {error}) => ({
        ...state,
        error,
        isLoading: false
    })
};
