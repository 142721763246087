import {flatten, uniqBy} from 'lodash-es';
import {SelectInput} from 'nyse-web-tools-common/lib/react';
import React, {useMemo} from 'react';
import {useEntitlement} from '../../hooks/useEntitlement';
import useClearingNumberList from '../../hooks/useClearingNumberList';
import useUser from '../../hooks/useUser.hook';
import useUserFirms from '../../hooks/useUserFirms';
import {CustFirm, Entitlement, SideTradeType} from '../../resources/tradeSummary.resource';
import {combineOptionsWithDbValue} from './selectUtils';

type MultiEditClearingNumberSelectProps = {
    data?: any,
    context?: any,
    name: string,
    value?: string,
    onChange?: any
};

const getClearingNumbersFromParticipants = (participants) => uniqBy(participants, 'clearingNumber').map((participant: any) => ({
    label: participant?.clearingNumber,
    value: participant?.clearingNumber
}));

export const MultiEditClearingNumberSelect = React.memo<MultiEditClearingNumberSelectProps>((props) => {
    const {sideCrd, firmId, initialOption} = useMemo(() => {
        const isFloorBroker = props?.data?.custTradeType === SideTradeType.FLOOR_BROKER;
        const isFloorBrokerOnBehalfOfLocalMM = props?.data?.custTradeType === SideTradeType.FLOOR_BROKER_LOCAL_MM;
        const isFloorBrokerOnBehalfOfAwayMM = props?.data?.custTradeType === SideTradeType.FLOOR_BROKER_AWAY_MM;
        const isLocalMM = props?.data?.custFirm === CustFirm.MarketMaker;
        const sideCrd = isFloorBroker || isFloorBrokerOnBehalfOfLocalMM || isFloorBrokerOnBehalfOfAwayMM ? props?.context?.multiEdit?.custOmsCrd : props?.context?.multiEdit?.custCrd;
        const firmId = isFloorBrokerOnBehalfOfLocalMM && !isLocalMM ? props?.data?.custOmsId : props?.data?.custFirmId;
        const initialOption = {clearingNumber: props?.data?.custClearingNumber ?? ''};
        return {sideCrd, firmId, initialOption};
    }, [
        props?.data?.custTradeType,
        props?.data?.custFirm,
        props?.context?.multiEdit?.custOmsCrd,
        props?.context?.multiEdit?.custCrd,
        props?.data?.custOmsId,
        props?.data?.custFirmId,
        props?.data?.custClearingNumber
    ]);

    const clearingNumberList = useClearingNumberList(sideCrd, firmId);

    const userFirms = useUserFirms();
    const entitlement = useEntitlement() || useUser().entitlement;
    const firms = useMemo(() => (userFirms.data ?? [])?.filter(({active}) => active), [userFirms.data]);
    const participants = useMemo(() => firms.find(({crd}) => crd === sideCrd)?.participants ?? [], [firms, sideCrd]);
    const clearingParticipants = useMemo(() => flatten(firms.map(({participants}) => participants)), [participants]);

    const options = useMemo(() => {
        const clearingNumbersFromGBORefData = (clearingNumberList?.data?.clearingDetails ?? []).map(({clearingNumber}) => ({label: clearingNumber, value: clearingNumber}));
        const isClearingFirm = entitlement === Entitlement.CLEARING_FIRM;
        const clearingNumbersFromParticipants = getClearingNumbersFromParticipants(isClearingFirm ? clearingParticipants : participants);
        const entitledOptions = isClearingFirm 
            ? clearingNumbersFromGBORefData.filter((option) => clearingNumbersFromParticipants.some((clearingNumber) => option.value === clearingNumber.value))
            : clearingNumbersFromGBORefData;
        return combineOptionsWithDbValue(initialOption, entitledOptions, 'clearingNumber');
    } ,[
        clearingNumberList.data,
        entitlement,
        props?.data?.custTradeType,
        props?.data?.custFirm,
        props?.context?.multiEdit?.custOmsCrd,
        props?.context?.multiEdit?.custCrd,
        props?.data?.custOmsId,
        props?.data?.custFirmId,
        props?.data?.custClearingNumber
    ]);

    return (
        <SelectInput
            {...props}
            isLoading={clearingNumberList.isLoading}
            options={options}
        />
    );
});
