export const combineOptionsWithDbValue = (props, options, fieldname) => {
    if (props[fieldname]) {
        return options.some((option) => option.value === props[fieldname])
            ? options
            : [
                  {
                      label: props[fieldname],
                      value: props[fieldname]
                  },
                  ...options
              ];
    }

    return options;
};

export const removeDuplicateOptions = (options, otherOptions) => {
    return options.filter(
        (option) => !otherOptions.find(({value}) => option.value === value)
    );
};

export const removeOptionsWithFirmId = (fieldOptions, firmIdOptions) => {
    const firmIds = firmIdOptions.map((firmIdOption) => firmIdOption.value);
    return fieldOptions.filter(
        (fieldOption) => !firmIds.includes(fieldOption.firmId)
    );
};
