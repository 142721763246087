import {get} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridProvider,
    AgGridTimestamp,
    AgGridToggleShowFilters,
    NyDateTimeInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {SymbolGroupSelect} from '../components/selects/symbolGroup.select';
import {SymbolGroupFilterSelect} from '../components/selects/symbolGroupFilter.select';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {getQuoteMetrics} from '../stores/quoteMetrics/quoteMetrics.actions';

@connect(['columnDefs', 'platform', 'quoteMetrics'], {
    setColumns,
    getQuoteMetrics
})
export class QuoteMetricsPage extends React.Component {
    state = {
        filterValues: {
            symbolGroupValue: []
        },
        symbolGroupValues: []
    };

    _dateRange = {
        max: joda.endOfDay(),
        min: joda.startOfDay().minusMonths(6)
    };

    _initialFilters = {
        date: joda.startOfDay()
    };

    _getCustomHeader = () => {
        const {
            filterValues: {date, symbol},
            symbolGroupValues
        } = this.state;

        return `${symbol || symbolGroupValues.join(', ')} @ ${joda.format(
            date,
            'MM/dd/yyyy'
        )}`;
    };

    _onFiltersChanged = (filterValues) => {
        this.setState({
            filterValues
        });
    };

    _onSymbolGroupChange = ({setValue}) => {
        setValue('symbolGroupValue', undefined);
    };

    _onSymbolGroupValueChange = (symbolGroupValues) => {
        this.setState({
            symbolGroupValues
        });
    };

    render() {
        return (
            <AgGridProvider
                autoSize={true}
                columnDefs={this.props.columnDefs.quoteMetrics}
                columnNamespace='quoteMetrics'
                filtersClassName='row space-bottom-1'
                initialFilters={this._initialFilters}
                onColumnsChanged={this.props.setColumns}
                onFiltersChanged={this._onFiltersChanged}
                onLoad={this.props.getQuoteMetrics}
                submitOnLoad={false}
                {...this.props.quoteMetrics}>
                <AgGridProvider.Filters>
                    <div className='form-group col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12'>
                        <label>Date</label>
                        <NyDateTimeInput
                            className='form-control'
                            format='MM/dd/yyyy'
                            name='date'
                            min={this._dateRange.min}
                            max={this._dateRange.max}
                            required={true}
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>Symbol</label>
                        <NyUppercaseInput
                            name='symbol'
                            required={
                                !get(
                                    this.state.filterValues,
                                    'symbolGroupValue',
                                    []
                                ).length
                            }
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>Symbol Group</label>
                        <SymbolGroupFilterSelect
                            onChange={this._onSymbolGroupChange}
                        />
                    </div>
                    <div className='form-group col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <SymbolGroupSelect
                            group={this.state.filterValues.symbolGroupType}
                            isMulti={true}
                            onChange={this._onSymbolGroupValueChange}
                            required={!this.state.filterValues.symbol}
                        />
                    </div>
                </AgGridProvider.Filters>

                <div className='row space-bottom-1'>
                    <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                        <div className='flex-row flex-align-bottom'>
                            <AgGridProvider.SubmitBtn>
                                Search
                            </AgGridProvider.SubmitBtn>
                            <AgGridProvider.ResetBtn>
                                Reset
                            </AgGridProvider.ResetBtn>
                            <div className='btn-circle-grp flex-pull-right'>
                                <AgGridToggleShowFilters />
                                <AgGridExportMenu
                                    exportFileName='quoteMetrics'
                                    exportSheetName={this.props.platform.name}
                                    getCustomHeader={this._getCustomHeader}
                                />
                                <AgGridColumnMenuTrigger />
                            </div>
                        </div>
                    </div>
                </div>

                <AgGrid reactNext={true} />

                <div className='row space-top-1'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <AgGridTimestamp timestampPrefix='Metrics' />
                    </div>
                </div>
            </AgGridProvider>
        );
    }
}
