import {useMemo} from 'react';

import useUserFirms from './useUserFirms';

type UseFirmIdOptions = {
    sideCrd: number;
    firmId: string;
};

export function useFirmIdOptions(props: UseFirmIdOptions) {
    const userFirms = useUserFirms();
    const firms = useMemo(
        () => (userFirms.data ?? []).filter(({active}) => active),
        [userFirms.data]
    );

    const participants = useMemo(
        () => firms.find(({crd}) => crd === props.sideCrd)?.participants ?? [],
        [firms, props.sideCrd]
    );

    const firmIdOptions = useMemo(
        () =>
            participants.map((participant) => ({
                label: participant.id,
                value: participant.id
            })),
        [participants]
    );

    const firmIdOptionsCombinedWithDbValue = useMemo(
        () =>
            firmIdOptions.some((option) => option.value === props.firmId)
                ? firmIdOptions
                : [
                      {
                          label: props.firmId,
                          value: props.firmId
                      },
                      ...firmIdOptions
                  ],
        [firmIdOptions, props.firmId]
    );

    return props.firmId ? firmIdOptionsCombinedWithDbValue : firmIdOptions;
}
