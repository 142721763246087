import {NyAlert} from 'nyse-web-tools-common/lib/react';
import React from 'react';

export type TradeSliceCounterProps = {
    totalTradeSplits: number;
    maxQty: number;
    title: string;
    quantityAllowedForSubmission: number;
};

type alertTypes = 'warning' | 'success' | 'danger';

export const TradeSliceCounter: React.FC<TradeSliceCounterProps> = React.memo(
    (props) => {
        const alertType: alertTypes =
            props.totalTradeSplits >= props.quantityAllowedForSubmission
                ? 'success'
                : props.totalTradeSplits < props.quantityAllowedForSubmission
                ? 'warning'
                : 'danger';

        return (
            <>
                <NyAlert
                    show
                    title={props.title}
                    detail={`${props.totalTradeSplits} / ${props.maxQty}`}
                    type={alertType}
                />
            </>
        );
    }
);
