import {cdmMw} from '../api';
import {SubAccountContact} from './subAccountContact.resource';

export type SubAccount = {
    activeStatus?: boolean;
    contacts?: SubAccountContact[];
    createdBy?: string;
    createdTime?: string;
    crd?: number;
    modifiedBy?: string;
    modifiedTime?: string;
    mpId?: string;
    name?: string;
    subAccountId?: number;
    tradingAccount?: string;
};

export default {
    api: cdmMw,
    url: (params) =>
        params.method === 'get' || !params.subAccountId
            ? 'api/sub-accounts'
            : `api/sub-accounts/${params.subAccountId}`,
    parseResponse: ({data}, config) =>
        config.method === 'get'
            ? data.map((row) => ({...row, contacts: row.contacts || []}))
            : data,
    resetDataOnRequest: false,
    defaultValue: []
};
