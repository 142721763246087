import cn from 'classnames';
import {SelectInput} from 'nyse-web-tools-common/lib/react';
import type {FieldInput} from 'nyse-web-tools-common/lib/react/types';
import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {useEntitlement} from '../../hooks/useEntitlement';
import useResource from '../../hooks/useResource.hook';
import {useRouteEntitlement} from '../../hooks/useRouteEntitlement';
import useUser from '../../hooks/useUser.hook';
import entitlementResource from '../../resources/entitlement.resource';
import {setEntitlement} from '../../stores/entitlement/entitlement.actions';

export const EntitlementSelect = React.memo<Partial<FieldInput>>((props) => {
    const resource = useResource(entitlementResource, true);
    const routeEntitlement = useRouteEntitlement();
    const entitlement = useEntitlement();
    const user = useUser();
    const dispatch = useDispatch();

    const options = useMemo(
        () =>
            resource.data
                .filter(({code}) => routeEntitlement.includes(code))
                .map(({code, name}) => ({label: name, value: code})),
        [resource.data, routeEntitlement]
    );

    const onChange = useCallback(
        (value) => {
            //update and set entitlement based on selection
            dispatch(setEntitlement(value));
        },
        [dispatch]
    );

    return (
        <>
            {routeEntitlement.length > 0 && user.entitlement === 'SUPER_USER' && (
                <div className={cn('form-group', props.className)}>
                    <label>Entitlement</label>
                    <SelectInput
                        value={entitlement}
                        isLoading={resource.pending}
                        readOnly={routeEntitlement.length < 2}
                        name={props.name}
                        onChange={onChange}
                        options={options}
                        required={props.required}
                    />
                </div>
            )}
        </>
    );
});
