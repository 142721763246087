import {Permissions} from '../../../resources/tradeSummary.resource';

const TRADE_CONTRA_APPROVE_TAB = {
    label: 'Approve/Reject',
    value: 'CONTRA_APPROVE',
    permissions: [Permissions.contraApproveTradeSummary]
};

const TRADE_RECALL_TAB = {
    label: 'Recall',
    value: 'RECALL',
    permissions: [Permissions.recallTradeSummary]
};

export const TRADE_APPROVAL_TABS = [TRADE_CONTRA_APPROVE_TAB, TRADE_RECALL_TAB];
