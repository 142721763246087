import {events} from '../events';
export const getPillarStatus = (platform) => ({
    types: [
        events.PILLAR_STATUS_GET,
        events.PILLAR_STATUS_SUCCESS,
        events.PILLAR_STATUS_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: `api/pillarHealth/${platform}`
        }
    }
});
