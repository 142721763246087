import {CircularProgress} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback} from 'react';

import useResource from '../hooks/useResource.hook';
import rdlSettingsResource from '../resources/rdlSettings.resource';

export const RdlSettingsPage = () => {
    const resource = useResource(rdlSettingsResource, true);
    const updateResource = useResource(rdlSettingsResource);

    const onSuccess = useCallback(() => resource.get(), [resource.get]);

    const onSubmit = useCallback(
        () =>
            updateResource.put({
                type: resource.data.type === 'RDL' ? 'LEGACY' : 'RDL'
            }),
        [resource.data.type, updateResource.put]
    );

    return (
        <NyForm onSuccess={onSuccess} onSubmit={onSubmit}>
            Current settings : <strong>{resource.data.type}</strong>
            <Box component={NySubmitAlert} py={1} />
            {resource.pending ? (
                <CircularProgress color='secondary' size={14} />
            ) : (
                <NySubmitBtn>
                    Switch To {resource.data.type === 'RDL' ? 'LEGACY' : 'RDL'}{' '}
                    Setting
                </NySubmitBtn>
            )}
        </NyForm>
    );
};
