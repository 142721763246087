import React from 'react';

export const unAuthorizedError = {
    title: 'Unauthorized Access To Resource',
    detail: (
        <span>
            Please contact <strong>crs@nyse.com</strong> to report this issue.
        </span>
    ),
    type: 'danger'
};
