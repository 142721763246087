import {capitalize} from 'lodash-es';
import {formatNumber} from 'nyse-web-tools-common/lib/utils';
import {capitalizeRole} from '../entitlementRoleMap';
import {riskSettingsTypeMap} from '../riskEntityHelper';
import React from "react";

const GetLimit = (limit, riskType, priceThreshold) => {

    let prefix='', postfix='';

    if (['cred_ord_exec', 'ord_val'].includes(riskType)) {
        //Gross, Order Max Notional
        return limit !== undefined ? `$${formatNumber(limit)}`:'';
    }
    else  if (['rolling_pct', 'ord_qty_adv'].includes(riskType)){
        //Percentage, Order % ADV
        return limit !== undefined ? `${limit}%`:'';
    }
    else if (['lmt_ord_prc_eq', 'lmt_ord_prc_eq_cls_coef', 'lmt_ord_prc_eq_trd_coef', 'lmt_ord_prc_opt_leg', 'lmt_ord_prc_opt_complex'].includes(riskType)) {
        const prefValue = limit !== undefined ? `${limit}%`:'';
        const postValue = priceThreshold !== undefined ? `$${formatNumber(priceThreshold, 0,2)}`:''
        return `${prefValue} / ${postValue}`;
    }
    else
        return limit === undefined ? '' : `${formatNumber(limit)}`;
}

export const riskMonitorDetailColumnDefs = [
    {
        headerName: 'Risk User CRD',
        field: 'limitCrd'
    },
    {
        headerName: 'Type',
        field: 'limitCrdRole',
        valueFormatter: ({value}) =>
            value === 'options_mm' ? 'Market Maker' : capitalizeRole(value)
    },
    {
        headerName: 'Risk Control',
        field: 'riskType',
        valueFormatter: ({value}) => riskSettingsTypeMap[value],
        width: 220
    },
    {
        headerName: 'Breach Action',
        field: 'action',
        valueFormatter: ({value}) => capitalize(value)
    },
    {
        headerName: 'Limit',
        field: 'limit',
        valueGetter: ({data:{limit, riskType, priceThreshold}}) => `${GetLimit(limit, riskType, priceThreshold)}`
    },
    {
        headerName: 'Gross - USD',
        field: 'current',
        type: 'number'
    },
    {
        headerName: 'Gross - Usage',
        field: 'currentPct',
        type: 'percentage',
        mantissa: 0,
        minWidth: 60
    },
    {
        headerName: 'Open - USD',
        field: 'ord',
        type: 'number'
    },
    {
        headerName: 'Executed - USD',
        field: 'exec',
        type: 'number'
    },
    {
        headerName: 'Auc Open - USD',
        field: 'ordAuc',
        type: 'number'
    }
];