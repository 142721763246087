import {OPRAEntryEditBtn} from '../../components/buttons/opraEntry/opraEntryEdit.btn';

export const opraEntriesColumnDefs = [
    {
        field: '_selected_',
        pinned: 'left',
        cellStyle: {
            background: 'white'
        },
        headerCheckboxSelection: true,
        permissions: ['delete opra entry'],
        checkboxSelection: ({data: {lock}}) => !lock,
        suppressToolPanel: true,
        movable: false,
        resizable: false,
        suppressSizeToFit: true,
        sorting: false,
        headerName: '',
        minWidth: 50,
        width: 50
    },
    {
        field: 'lock',
        pinned: 'left',
        permissions: ['update opra entry'],
        suppressToolPanel: true,
        movable: false,
        resizable: false,
        sort: 'asc',
        suppressSizeToFit: true,
        headerName: '',
        cellRendererFramework: OPRAEntryEditBtn,
        valueGetter: ({data: {lock}}) => lock,
        minWidth: 50,
        width: 50
    },
    {
        field: 'opraId',
        headerName: 'Opra Id'
    },
    {
        field: 'dealId',
        headerName: 'Deal Id',
        pinned: 'left'
    },
    {
        field: 'status',
        headerName: 'Status',
        pinned: 'left',
        width: 100
    },
    {
        field: 'symbol',
        headerName: 'Symbol'
    },
    {
        field: 'tradeExecTime',
        headerName: 'Trade Execution',
        type: 'dateTime'
    },
    {
        field: 'price',
        headerName: 'Price',
        type: 'priceLong'
    },
    {
        field: 'crd',
        headerName: 'CRD'
    },
    {
        field: 'opraEntryType',
        headerName: 'Entry Type'
    },
    {
        field: 'opraSeqNumber',
        headerName: 'Sequence Number'
    },
    {
        field: 'priceSpread',
        headerName: 'Price Spread',
        type: 'priceLong'
    },
    {
        field: 'hedgeRatioPercentage',
        headerName: 'Hedge Ratio %',
        type: 'percentage'
    },
    {
        field: 'strategy',
        headerName: 'Strategy'
    },
    {
        field: 'qctType',
        headerName: 'QCT Type'
    },
    {
        field: 'exchange',
        headerName: 'Exchange'
    },
    {
        field: 'printTime',
        headerName: 'Print Time',
        type: 'dateTime'
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration',
        type: 'date'
    },
    {
        field: 'strikePrice',
        headerName: 'Strike Price',
        type: 'priceLong'
    },
    {
        field: 'callPut',
        headerName: 'Call / Put'
    },
    {
        field: 'quantity',
        headerName: 'Qty',
        type: 'number'
    },
    {
        field: 'shortSale',
        headerName: 'SSR Type'
    },
    {
        field: 'createdBy',
        headerName: 'Created By'
    },
    {
        field: 'createdTime',
        headerName: 'Created Time',
        type: 'dateTime'
    },
    {
        field: 'modifiedBy',
        headerName: 'Modified By'
    },
    {
        field: 'modifiedTime',
        headerName: 'Modified Time',
        type: 'dateTime'
    },
    {
        field: 'comments',
        headerName: 'Comments'
    },
    {
        field: 'dealIdOrig',
        headerName: 'Original Deal Id'
    }
];
