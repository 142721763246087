import {cdmMw} from '../api';

export type IdbOriginator = {
    affiliateFlag?: boolean;
    crd?: number;
    createdBy?: string;
    createdTime?: string;
    dailyOrderThresholdValue?: number;
    idbOriginatorId?: number;
    isIdb?: boolean;
    isOriginator?: boolean;
    modifiedBy?: string;
    modifiedTime?: string;
    mpId?: string;
    name?: string;
    oatsId?: string;
    orderThresholdQuantity?: number;
    orderThresholdValue?: number;
    originatorCrd?: number;
    tradingAccount?: string;
    autoPublishEligible?: boolean;
};

export default {
    api: cdmMw,
    url: (params) =>
        params.method === 'get' || !params.idbOriginatorId
            ? 'api/idb-originators'
            : `api/idb-originators/${params.idbOriginatorId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
};
