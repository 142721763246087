import {
    AgGrid,
    AgGridAutoRefresh,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridPagination,
    AgGridProvider,
    AgGridToggleShowFilters,
    NySelectInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {FirmSelect} from '../components/selects/firm.select';
import {FirmParticipantSelect} from '../components/selects/firmParticipant.select';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {getMyFirmMpids} from '../stores/myFirmMpids/myFirmMpids.actions';

@connect(['firms', 'columnDefs', 'myFirmMpids', 'platform', 'route'], {
    setColumns,
    getMyFirmMpids
})
export class MyFirmMPIDs extends React.Component {
    state = {
        filterValues: {}
    };

    _initialPagination = {
        limit: 500,
        offset: 0
    };

    _initialFilters = {
        crd: this.props.route.params.crd || (this.props.firms[0] || {}).crd
    };

    _onFirmChange = ({setFieldValue}) => {
        setFieldValue('active_status', '');
        setFieldValue('sendermpid', '');
        setFieldValue('sendercomp', '');
        setFieldValue('mpid', []);
    };

    _onFiltersChanged = (filterValues) => {
        this.setState({filterValues});
    };

    render() {
        return (
            <AgGridProvider
                columnNamespace='myFirmMpids'
                columnDefs={this.props.columnDefs.myFirmMpids}
                filtersClassName='row space-bottom-1'
                initialFilters={this._initialFilters}
                initialPagination={this._initialPagination}
                onFiltersChanged={this._onFiltersChanged}
                onLoad={this.props.getMyFirmMpids}
                onColumnsChanged={this.props.setColumns}
                submitOnLoad={true}
                {...this.props.myFirmMpids}>
                <AgGridProvider.Filters>
                    {this.props.firms.length > 1 && (
                        <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                            <label>Firm</label>
                            <FirmSelect
                                initialValue={this.props.route.params.crd}
                                name='crd'
                                onChange={this._onFirmChange}
                                required={true}
                            />
                        </div>
                    )}
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>MPID</label>
                        <FirmParticipantSelect
                            crd={this.state.filterValues.crd}
                            disableInactiveControl
                            hideEtpid
                            name='mpid'
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Sender MPID</label>
                        <NyUppercaseInput name='sendermpid' />
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>User Name</label>
                        <NyUppercaseInput name='sendercomp' />
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Status</label>
                        <NySelectInput name='active_status'>
                            <option value=''>-</option>
                            <option value='active'>Active</option>
                            <option value='prospect'>Prospect</option>
                            <option value='inactive'>Inactive</option>
                        </NySelectInput>
                    </div>
                </AgGridProvider.Filters>
                <div className='row space-bottom-1'>
                    <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                        <div className='flex-row flex-align-bottom'>
                            <AgGridProvider.SubmitBtn>
                                Search
                            </AgGridProvider.SubmitBtn>
                            <AgGridProvider.ResetBtn>
                                Reset
                            </AgGridProvider.ResetBtn>
                            <div className='btn-circle-grp flex-pull-right'>
                                <AgGridAutoRefresh />
                                <AgGridToggleShowFilters />
                                <AgGridExportMenu
                                    exportFileName='myFirmMpids'
                                    exportSheetName={this.props.platform.name}
                                />
                                <AgGridColumnMenuTrigger />
                            </div>
                        </div>
                    </div>
                </div>

                <AgGrid reactNext={true} />

                <AgGridPagination>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1,000</option>
                    <option value={5000}>5,000</option>
                    <option value={10000}>10,000</option>
                </AgGridPagination>
            </AgGridProvider>
        );
    }
}
