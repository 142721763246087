import {NyModal} from 'nyse-web-tools-common/lib/react';
import React from 'react';

import {MultiTradeAdjustmentProps} from '../../../resources/tradeSummary.resource';
import {MultiRecallForm} from './forms/multiRecall.form';

export const MultiRecallModal = React.memo(
    (props: MultiTradeAdjustmentProps) => {

        return (
            <NyModal
                className='modal-large'
                hideModal={props.hideModal}
                name='multiTransactionRecall'
                show={props.show}
                parentTitle='Transactions'
                title={'Recall'}>
                <div className='col-xs-12 flex-column'>
                    <MultiRecallForm
                        data={props.data}
                        crd={props.crd}
                        onClose={props.onClose}
                    />
                </div>
            </NyModal>
        );
    }
);
