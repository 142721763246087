import {pick} from 'lodash-es';

import {events} from '../events';

export const myVolumesBrokerRankReducer = {
    [events.MY_VOLUMES_BROKER_RANK_GET]: (state = {}) => ({
        ...state,
        isLoading: true,
        timestamp: null
    }),
    [events.MY_VOLUMES_BROKER_RANK_SUCCESS]: (
        state,
        {
            payload: {
                data: {data},
                config: {params}
            },
            _state
        }
    ) => {
        const firm = _state.firms.find(({crd}) => crd === params.crd);

        const endDate = data.endDate.replace(/-/g, '/');
        const startDate = data.startDate.replace(/-/g, '/');

        return {
            isLoading: false,
            error: false,
            more: false,
            endDate,
            startDate,
            pinnedTopRowData: [
                {
                    group: [firm.name],
                    mic: _state.platform.mic,
                    ...pick(data, [
                        'tapeAMtd',
                        'tapeBMtd',
                        'tapeCMtd',
                        'tapeAPrevDay',
                        'tapeBPrevDay',
                        'tapeCPrevDay',
                        'allTapesMtd',
                        'allTapesPrevDay'
                    ]),
                    ignoreExport: true
                }
            ],
            rowData: [
                ...data.volumes.reduce(
                    (volumes, group) => [
                        ...volumes,
                        {
                            group: [group.name],
                            ignoreExport: true,
                            mic: _state.platform.mic,
                            ...group
                        },
                        ...(group.members
                            ? group.members.reduce(
                                  (members, liqInd) => [
                                      ...members,
                                      {
                                          group: [group.name, liqInd.name],
                                          mic: _state.platform.mic,
                                          ...liqInd
                                      }
                                  ],
                                  []
                              )
                            : [])
                    ],
                    []
                )
            ],
            timestamp: `${startDate} - ${endDate}`
        };
    },
    [events.MY_VOLUMES_BROKER_RANK_ERROR]: (state, {error}) => ({
        error,
        hasData: false,
        isLoading: false,
        more: false,
        rowData: [],
        timestamp: null
    })
};
