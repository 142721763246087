import React from 'react';

export const defaultError = {
    title: 'An unknown server error has occurred.',
    detail: (
        <span>
            Please contact <strong>crs@nyse.com</strong> to report this issue.
        </span>
    ),
    type: 'danger'
};
