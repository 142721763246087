import {LocalDateTime} from '@js-joda/core';
import {chunk, get, isEmpty, sortBy} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import {
    formatNumber,
    getPercentage
} from 'nyse-web-tools-common/lib/utils/number';

import {events} from '../events';
import {v4} from "uuid";

export const coreSessionDefaultState = {
    error: false,
    isLoading: false,
    hasMwCbAlert: false,
    coreSessionDataRowData: [],
    symbolStateRowData: [],
    haltedSymbolsRowData: [],
    symbolStates: {
        add_inf_req: {
            group: 'regHalts',
            label: 'Additional Info Req'
        },
        add_inf_req_A: {
            group: 'regHalts',
            label: 'Additional Info Req'
        },
        add_inf_req_c: {
            group: 'regHalts',
            label: 'Additional Info Req Carryover'
        },
        add_inf_req_c_A: {
            group: 'regHalts',
            label: 'Additional Info Req Carryover'
        },
        closed: {
            group: 'misc',
            label: 'Closed'
        },
        closed_A: {
            group: 'misc',
            label: 'Closed'
        },
        corp_action: {
            group: 'regHalts',
            label: 'Corporate Action'
        },
        corp_action_A: {
            group: 'regHalts',
            label: 'Corporate Action'
        },
        corp_action_c: {
            group: 'regHalts',
            label: 'Corporate Action Carryover'
        },
        corp_action_c_A: {
            group: 'regHalts',
            label: 'Corporate Action Carryover'
        },
        direct: {
            group: 'newListing',
            label: 'Direct Listing'
        },
        direct_A: {
            group: 'newListing',
            label: 'Direct Listing'
        },
        equip_change: {
            group: 'systemIssues',
            label: 'Operational Halt'
        },
        equip_change_A: {
            group: 'systemIssues',
            label: 'Operational Halt'
        },
        etf_comp: {
            group: 'regHalts',
            label: 'ETF Comp Px Not Avail'
        },
        etf_comp_A: {
            group: 'regHalts',
            label: 'ETF Comp Px Not Avail'
        },
        etf_comp_c: {
            group: 'regHalts',
            label: 'ETF Comp Px Not Avail Carryover'
        },
        etf_comp_c_A: {
            group: 'regHalts',
            label: 'ETF Comp Px Not Avail Carryover'
        },
        int_ind: {
            group: 'regHalts',
            label: 'IIV Not Available'
        },
        int_ind_A: {
            group: 'regHalts',
            label: 'IIV Not Available'
        },
        int_ind_c: {
            group: 'regHalts',
            label: 'IIV Not Available Carryover'
        },
        int_ind_c_A: {
            group: 'regHalts',
            label: 'IIV Not Available Carryover'
        },
        ipo: {
            group: 'newListing',
            label: 'IPO Listing'
        },
        ipo_A: {
            group: 'newListing',
            label: 'IPO Listing'
        },
        luld: {
            group: 'luldHalts',
            label: 'LULD Halt'
        },
        luld_A: {
            group: 'luldHalts',
            label: 'LULD Halt'
        },
        luld_cutoff: {
            group: 'luldHalts',
            label: 'LULD Halt Cutoff'
        },
        luld_cutoff_A: {
            group: 'luldHalts',
            label: 'LULD Halt Cutoff'
        },
        merger_eff: {
            group: 'regHalts',
            label: 'Merger Effective'
        },
        merger_eff_A: {
            group: 'regHalts',
            label: 'Merger Effective'
        },
        merger_eff_c: {
            group: 'regHalts',
            label: 'Merger Effective Carryover'
        },
        merger_eff_c_A: {
            group: 'regHalts',
            label: 'Merger Effective Carryover'
        },
        mwcb1: {
            group: 'mwcbHalts',
            label: 'MWCB Halt'
        },
        mwcb1_A: {
            group: 'mwcbHalts',
            label: 'MWCB Halt'
        },
        mwcb1_cutoff: {
            group: 'mwcbHalts',
            label: 'MWCB Halt Cutoff'
        },
        mwcb1_cutoff_A: {
            group: 'mwcbHalts',
            label: 'MWCB Halt Cutoff'
        },
        mwcb2: {
            group: 'mwcbHalts',
            label: 'MWCB Halt'
        },
        mwcb2_A: {
            group: 'mwcbHalts',
            label: 'MWCB Halt Carryover'
        },
        mwcb2_cutoff: {
            group: 'mwcbHalts',
            label: 'MWCB Halt Cutoff'
        },
        mwcb2_cutoff_A: {
            group: 'mwcbHalts',
            label: 'MWCB Halt Cutoff'
        },
        mwcb3: {
            group: 'mwcbHalts',
            label: 'MWCB Halt'
        },
        mwcb3_A: {
            group: 'mwcbHalts',
            label: 'MWCB Halt'
        },
        mwcb3_c: {
            group: 'mwcbHalts',
            label: 'MWCB Halt Carryover'
        },
        mwcb3_c_A: {
            group: 'mwcbHalts',
            label: 'MWCB Halt Carryover'
        },
        news_dis: {
            group: 'regHalts',
            label: 'News Dissemination'
        },
        news_dis_A: {
            group: 'regHalts',
            label: 'News Dissemination'
        },
        news_dis_c: {
            group: 'regHalts',
            label: 'News Dissemination Carryover'
        },
        news_dis_c_A: {
            group: 'regHalts',
            label: 'News Dissemination Carryover'
        },
        news_dis_cutoff: {
            group: 'regHalts',
            label: 'News Dissemination Cutoff'
        },
        news_dis_cutoff_A: {
            group: 'regHalts',
            label: 'News Dissemination Cutoff'
        },
        news_pend: {
            group: 'regHalts',
            label: 'News Pending'
        },
        news_pend_A: {
            group: 'regHalts',
            label: 'News Pending'
        },
        news_pend_c: {
            group: 'regHalts',
            label: 'News Pending Carryover'
        },
        news_pend_c_A: {
            group: 'regHalts',
            label: 'News Pending Carryover'
        },
        news_pend_cutoff: {
            group: 'regHalts',
            label: 'News Pending Cutoff'
        },
        news_pend_cutoff_A: {
            group: 'regHalts',
            label: 'News Pending Cutoff'
        },
        open: {
            group: 'misc',
            label: 'Open'
        },
        open_A: {
            group: 'misc',
            label: 'Open'
        },
        order_imb: {
            group: 'systemIssues',
            label: 'Order Imbalance'
        },
        order_imb_A: {
            group: 'systemIssues',
            label: 'Order Imbalance'
        },
        preopen: {
            group: 'misc',
            label: 'Pre-Open'
        },
        preopen_A: {
            group: 'misc',
            label: 'Pre-Open'
        },
        reg_concern: {
            group: 'regHalts',
            label: 'Regulatory Concern'
        },
        reg_concern_A: {
            group: 'regHalts',
            label: 'Regulatory Concern'
        },
        reg_concern_c: {
            group: 'regHalts',
            label: 'Regulatory Concern Carryover'
        },
        reg_concern_c_A: {
            group: 'regHalts',
            label: 'Regulatory Concern Carryover'
        },
        sec_off: {
            group: 'regHalts',
            label: 'New Sec Offering'
        },
        sec_off_A: {
            group: 'regHalts',
            label: 'New Sec Offering'
        },
        sec_off_c: {
            group: 'regHalts',
            label: 'New Sec Offering Carryover'
        },
        sec_off_c_A: {
            group: 'regHalts',
            label: 'New Sec Offering Carryover'
        },
        suspended: {
            group: 'systemIssues',
            label: 'Suspended'
        },
        suspended_A: {
            group: 'systemIssues',
            label: 'Suspended'
        },
        suspended_DMM: {
            group: 'systemIssues',
            label: 'Suspended DMM'
        },
        suspended_DMM_A: {
            group: 'systemIssues',
            label: 'Suspended DMM'
        }
    },
    haltLabels: [
        {
            label: 'IPO/Direct Listing',
            group: 'newListing'
        },
        {
            label: 'LULD Halt',
            group: 'luldHalts'
        },
        {
            label: 'MWCB Halt',
            group: 'mwcbHalts'
        },
        {
            label: 'Reg Halt',
            group: 'regHalts'
        },
        {
            label: 'System Issues',
            group: 'systemIssues'
        }
    ],
    tapes: ['TapeA', 'TapeB', 'TapeC'],
    timestamp: null,
    totalHalts: '0'
};

export const coreSessionReducer = {
    [events.CORE_SESSION_GET]: (state) => ({
        ...state,
        isLoading:
            isEmpty(state.coreSessionDataRowData) && state.error === false
    }),
    [events.CORE_SESSION_SUCCESS]: (state, {payload: {config, data}}) => {

        if (config.url === '/api/symbolsStates') {
            const tapeAHalted = data.data.TapeA.haltedSymbolStatList.luldHalt +
                data.data.TapeA.haltedSymbolStatList.regHalt +
                data.data.TapeA.haltedSymbolStatList.ipoOrDirectListing +
                data.data.TapeA.haltedSymbolStatList.mwcbHalt +
                data.data.TapeA.haltedSymbolStatList.systemHalt;
            const tapeBHalted = data.data.TapeB.haltedSymbolStatList.luldHalt +
                data.data.TapeB.haltedSymbolStatList.regHalt +
                data.data.TapeB.haltedSymbolStatList.ipoOrDirectListing +
                data.data.TapeB.haltedSymbolStatList.mwcbHalt +
                data.data.TapeB.haltedSymbolStatList.systemHalt;
            const tapeCHalted = data.data.TapeC.haltedSymbolStatList.luldHalt +
                data.data.TapeC.haltedSymbolStatList.regHalt +
                data.data.TapeC.haltedSymbolStatList.ipoOrDirectListing +
                data.data.TapeC.haltedSymbolStatList.mwcbHalt +
                data.data.TapeC.haltedSymbolStatList.systemHalt;
            const tapeASsr = data.data.TapeA.ssrSymbolStatList.ssr1 + data.data.TapeA.ssrSymbolStatList.ssr2;
            const tapeBSsr = data.data.TapeB.ssrSymbolStatList.ssr1 + data.data.TapeB.ssrSymbolStatList.ssr2;
            const tapeCSsr = data.data.TapeC.ssrSymbolStatList.ssr1 + data.data.TapeC.ssrSymbolStatList.ssr2;

            return {
                ...state,
                error: false,
                isLoading: false,
                symbolStateRowData: [
                    {
                        group: ['Open'],
                        tapeA: [
                            data.data.TapeA.openSymbolStat.open,
                            data.meta.totalCounts.TapeA.totalSymbol
                        ],
                        tapeB: [
                            data.data.TapeB.openSymbolStat.open,
                            data.meta.totalCounts.TapeB.totalSymbol
                        ],
                        tapeC: [
                            data.data.TapeC.openSymbolStat.open,
                            data.meta.totalCounts.TapeC.totalSymbol
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.openSymbolStat.open,
                            data.meta.totalCounts.TapeA.totalSymbol
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.openSymbolStat.open,
                            data.meta.totalCounts.TapeB.totalSymbol
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.openSymbolStat.open,
                            data.meta.totalCounts.TapeC.totalSymbol
                        ),
                        total: [
                            (data.data.TapeA.openSymbolStat.open+
                                data.data.TapeB.openSymbolStat.open+
                                data.data.TapeC.openSymbolStat.open),
                            (data.meta.totalCounts.TapeA.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol+
                                data.meta.totalCounts.TapeC.totalSymbol)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.openSymbolStat.open+
                                data.data.TapeB.openSymbolStat.open+
                                data.data.TapeC.openSymbolStat.open),
                            (data.meta.totalCounts.TapeA.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol+
                                data.meta.totalCounts.TapeC.totalSymbol)
                        )
                    },
                    {
                        group: ['Open', 'S&P 500'],
                        tapeA: [
                            data.data.TapeA.openSymbolStat.sp500,
                            data.meta.totalCounts.TapeA.totalSP500Symbol,
                        ],
                        tapeB: [
                            data.data.TapeB.openSymbolStat.sp500,
                            data.meta.totalCounts.TapeB.totalSP500Symbol,
                        ],
                        tapeC: [
                            data.data.TapeC.openSymbolStat.sp500,
                            data.meta.totalCounts.TapeC.totalSP500Symbol,
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.openSymbolStat.sp500,
                            data.meta.totalCounts.TapeA.totalSP500Symbol,
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.openSymbolStat.sp500,
                            data.meta.totalCounts.TapeB.totalSP500Symbol,
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.openSymbolStat.sp500,
                            data.meta.totalCounts.TapeC.totalSP500Symbol,
                        ),
                        total: [
                            (data.data.TapeA.openSymbolStat.sp500+
                                data.data.TapeB.openSymbolStat.sp500+
                                data.data.TapeC.openSymbolStat.sp500),
                            (data.meta.totalCounts.TapeA.totalSP500Symbol+
                                data.meta.totalCounts.TapeB.totalSP500Symbol+
                                data.meta.totalCounts.TapeC.totalSP500Symbol)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.openSymbolStat.sp500+
                                data.data.TapeB.openSymbolStat.sp500+
                                data.data.TapeC.openSymbolStat.sp500),
                            (data.meta.totalCounts.TapeA.totalSP500Symbol+
                                data.meta.totalCounts.TapeB.totalSP500Symbol+
                                data.meta.totalCounts.TapeC.totalSP500Symbol)
                        )
                    },
                    {
                        group: ['Open', 'DJIA'],
                        tapeA: [
                            data.data.TapeA.openSymbolStat.djia,
                            data.meta.totalCounts.TapeA.totalSDjiaSymbol,
                        ],
                        tapeB: [
                            data.data.TapeB.openSymbolStat.djia,
                            data.meta.totalCounts.TapeB.totalSDjiaSymbol,
                        ],
                        tapeC: [
                            data.data.TapeC.openSymbolStat.djia,
                            data.meta.totalCounts.TapeC.totalSDjiaSymbol,
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.openSymbolStat.djia,
                            data.meta.totalCounts.TapeA.totalSDjiaSymbol,
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.openSymbolStat.djia,
                            data.meta.totalCounts.TapeB.totalSDjiaSymbol,
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.openSymbolStat.djia,
                            data.meta.totalCounts.TapeC.totalSDjiaSymbol,
                        ),
                        total: [
                            (data.data.TapeA.openSymbolStat.djia+
                                data.data.TapeB.openSymbolStat.djia+
                                data.data.TapeC.openSymbolStat.djia),
                            (data.meta.totalCounts.TapeA.totalSDjiaSymbol+
                                data.meta.totalCounts.TapeB.totalSDjiaSymbol+
                                data.meta.totalCounts.TapeC.totalSDjiaSymbol)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.openSymbolStat.djia+
                                data.data.TapeB.openSymbolStat.djia+
                                data.data.TapeC.openSymbolStat.djia),
                            (data.meta.totalCounts.TapeA.totalSDjiaSymbol+
                                data.meta.totalCounts.TapeB.totalSDjiaSymbol+
                                data.meta.totalCounts.TapeC.totalSDjiaSymbol)
                        )
                    },
                    {
                        group: ['Closed'],
                        tapeA: [
                            data.data.TapeA.closeSymbolStatList.close,
                            data.meta.totalCounts.TapeA.totalSymbol
                        ],
                        tapeB: [
                            data.data.TapeB.closeSymbolStatList.close,
                            data.meta.totalCounts.TapeB.totalSymbol
                        ],
                        tapeC: [
                            data.data.TapeC.closeSymbolStatList.close,
                            data.meta.totalCounts.TapeC.totalSymbol
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.closeSymbolStatList.close,
                            data.meta.totalCounts.TapeA.totalSymbol
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.closeSymbolStatList.close,
                            data.meta.totalCounts.TapeB.totalSymbol
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.closeSymbolStatList.close,
                            data.meta.totalCounts.TapeC.totalSymbol
                        ),
                        total: [
                            (data.data.TapeA.closeSymbolStatList.close+
                                data.data.TapeB.closeSymbolStatList.close+
                                data.data.TapeC.closeSymbolStatList.close),
                            (data.meta.totalCounts.TapeA.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.closeSymbolStatList.close+
                                data.data.TapeB.closeSymbolStatList.close+
                                data.data.TapeC.closeSymbolStatList.close),
                            (data.meta.totalCounts.TapeA.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol)
                        )
                    },
                    {
                        group: ['Closed', 'S&P 500'],
                        tapeA: [
                            data.data.TapeA.closeSymbolStatList.sp500,
                            data.meta.totalCounts.TapeA.totalSP500Symbol,
                        ],
                        tapeB: [
                            data.data.TapeB.closeSymbolStatList.sp500,
                            data.meta.totalCounts.TapeB.totalSP500Symbol,
                        ],
                        tapeC: [
                            data.data.TapeC.closeSymbolStatList.sp500,
                            data.meta.totalCounts.TapeC.totalSP500Symbol,
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.closeSymbolStatList.sp500,
                            data.meta.totalCounts.TapeA.totalSP500Symbol,
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.closeSymbolStatList.sp500,
                            data.meta.totalCounts.TapeB.totalSP500Symbol,
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.closeSymbolStatList.sp500,
                            data.meta.totalCounts.TapeC.totalSP500Symbol,
                        ),
                        total: [
                            (data.data.TapeA.closeSymbolStatList.sp500+
                                data.data.TapeB.closeSymbolStatList.sp500+
                                data.data.TapeC.closeSymbolStatList.sp500),
                            (data.meta.totalCounts.TapeA.totalSP500Symbol+
                                data.meta.totalCounts.TapeB.totalSP500Symbol+
                                data.meta.totalCounts.TapeC.totalSP500Symbol)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.closeSymbolStatList.sp500+
                                data.data.TapeB.closeSymbolStatList.sp500+
                                data.data.TapeC.closeSymbolStatList.sp500),
                            (data.meta.totalCounts.TapeA.totalSP500Symbol+
                                data.meta.totalCounts.TapeB.totalSP500Symbol+
                                data.meta.totalCounts.TapeC.totalSP500Symbol)
                        )
                    },
                    {
                        group: ['Closed', 'DJIA'],
                        tapeA: [
                            data.data.TapeA.closeSymbolStatList.djia,
                            data.meta.totalCounts.TapeA.totalSDjiaSymbol,
                        ],
                        tapeB: [
                            data.data.TapeB.closeSymbolStatList.djia,
                            data.meta.totalCounts.TapeB.totalSDjiaSymbol,
                        ],
                        tapeC: [
                            data.data.TapeC.closeSymbolStatList.djia,
                            data.meta.totalCounts.TapeC.totalSDjiaSymbol,
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.closeSymbolStatList.djia,
                            data.meta.totalCounts.TapeA.totalSDjiaSymbol,
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.closeSymbolStatList.djia,
                            data.meta.totalCounts.TapeB.totalSDjiaSymbol,
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.closeSymbolStatList.djia,
                            data.meta.totalCounts.TapeC.totalSDjiaSymbol,
                        ),
                        total: [
                            (data.data.TapeA.closeSymbolStatList.djia+
                                data.data.TapeB.closeSymbolStatList.djia+
                                data.data.TapeC.closeSymbolStatList.djia),
                            (data.meta.totalCounts.TapeA.totalSDjiaSymbol+
                                data.meta.totalCounts.TapeB.totalSDjiaSymbol+
                                data.meta.totalCounts.TapeC.totalSDjiaSymbol)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.closeSymbolStatList.djia+
                                data.data.TapeB.closeSymbolStatList.djia+
                                data.data.TapeC.closeSymbolStatList.djia),
                            (data.meta.totalCounts.TapeA.totalSDjiaSymbol+
                                data.meta.totalCounts.TapeB.totalSDjiaSymbol+
                                data.meta.totalCounts.TapeC.totalSDjiaSymbol)
                        )
                    },
                    {
                        group: ['Halted'],
                        tapeA: [
                            tapeAHalted,
                            data.meta.totalCounts.TapeA.totalSymbol
                        ],
                        tapeB: [
                            tapeBHalted,
                            data.meta.totalCounts.TapeB.totalSymbol
                        ],
                        tapeC: [
                            tapeCHalted,
                            data.meta.totalCounts.TapeC.totalSymbol
                        ],
                        tapeAPercent: getPercentage(
                            tapeAHalted,
                            data.meta.totalCounts.TapeA.totalSymbol
                        ),
                        tapeBPercent: getPercentage(
                            tapeBHalted,
                            data.meta.totalCounts.TapeB.totalSymbol
                        ),
                        tapeCPercent: getPercentage(
                            tapeCHalted,
                            data.meta.totalCounts.TapeC.totalSymbol
                        ),
                        total: [
                            (tapeAHalted + tapeBHalted + tapeCHalted),
                            (data.meta.totalCounts.TapeA.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol+
                                data.meta.totalCounts.TapeC.totalSymbol)
                        ],
                        totalPercent: getPercentage(
                            (tapeAHalted + tapeBHalted + tapeCHalted),
                            (data.meta.totalCounts.TapeA.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol+
                                data.meta.totalCounts.TapeC.totalSymbol)
                        )
                    },
                    {
                        group: ['Halted', 'LULD Halt'],
                        tapeA: [
                            data.data.TapeA.haltedSymbolStatList.luldHalt,
                            tapeAHalted
                        ],
                        tapeB: [
                            data.data.TapeB.haltedSymbolStatList.luldHalt,
                            tapeBHalted
                        ],
                        tapeC: [
                            data.data.TapeC.haltedSymbolStatList.luldHalt,
                            tapeCHalted
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.haltedSymbolStatList.luldHalt,
                            tapeAHalted
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.haltedSymbolStatList.luldHalt,
                            tapeBHalted
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.haltedSymbolStatList.luldHalt,
                            tapeCHalted
                        ),
                        total: [
                            (data.data.TapeA.haltedSymbolStatList.luldHalt+
                                data.data.TapeB.haltedSymbolStatList.luldHalt+
                                data.data.TapeC.haltedSymbolStatList.luldHalt),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.haltedSymbolStatList.luldHalt+
                                data.data.TapeB.haltedSymbolStatList.luldHalt+
                                data.data.TapeC.haltedSymbolStatList.luldHalt),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        )
                    },
                    {
                        group: ['Halted', 'Reg Halt'],
                        tapeA: [
                            data.data.TapeA.haltedSymbolStatList.regHalt,
                            tapeAHalted
                        ],
                        tapeB: [
                            data.data.TapeB.haltedSymbolStatList.regHalt,
                            tapeBHalted
                        ],
                        tapeC: [
                            data.data.TapeC.haltedSymbolStatList.regHalt,
                            tapeCHalted
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.haltedSymbolStatList.regHalt,
                            tapeAHalted
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.haltedSymbolStatList.regHalt,
                            tapeBHalted
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.haltedSymbolStatList.regHalt,
                            tapeCHalted
                        ),
                        total: [
                            (data.data.TapeA.haltedSymbolStatList.regHalt+
                                data.data.TapeB.haltedSymbolStatList.regHalt+
                                data.data.TapeC.haltedSymbolStatList.regHalt),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.haltedSymbolStatList.regHalt+
                                data.data.TapeB.haltedSymbolStatList.regHalt+
                                data.data.TapeC.haltedSymbolStatList.regHalt),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        )
                    },
                    {
                        group: ['Halted', 'IPO/Direct Listing'],
                        tapeA: [
                            data.data.TapeA.haltedSymbolStatList.ipoOrDirectListing,
                            tapeAHalted
                        ],
                        tapeB: [
                            data.data.TapeB.haltedSymbolStatList.ipoOrDirectListing,
                            tapeBHalted
                        ],
                        tapeC: [
                            data.data.TapeC.haltedSymbolStatList.ipoOrDirectListing,
                            tapeCHalted
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.haltedSymbolStatList.ipoOrDirectListing,
                            tapeAHalted
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.haltedSymbolStatList.ipoOrDirectListing,
                            tapeBHalted
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.haltedSymbolStatList.ipoOrDirectListing,
                            tapeCHalted
                        ),
                        total: [
                            (data.data.TapeA.haltedSymbolStatList.ipoOrDirectListing+
                                data.data.TapeB.haltedSymbolStatList.ipoOrDirectListing+
                                data.data.TapeC.haltedSymbolStatList.ipoOrDirectListing),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.haltedSymbolStatList.ipoOrDirectListing+
                                data.data.TapeB.haltedSymbolStatList.ipoOrDirectListing+
                                data.data.TapeC.haltedSymbolStatList.ipoOrDirectListing),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        )
                    },
                    {
                        group: ['Halted', 'MWCB Halt'],
                        tapeA: [
                            data.data.TapeA.haltedSymbolStatList.mwcbHalt,
                            tapeAHalted
                        ],
                        tapeB: [
                            data.data.TapeB.haltedSymbolStatList.mwcbHalt,
                            tapeBHalted
                        ],
                        tapeC: [
                            data.data.TapeC.haltedSymbolStatList.mwcbHalt,
                            tapeCHalted
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.haltedSymbolStatList.mwcbHalt,
                            tapeAHalted
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.haltedSymbolStatList.mwcbHalt,
                            tapeBHalted
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.haltedSymbolStatList.mwcbHalt,
                            tapeCHalted
                        ),
                        total: [
                            (data.data.TapeA.haltedSymbolStatList.mwcbHalt+
                                data.data.TapeB.haltedSymbolStatList.mwcbHalt+
                                data.data.TapeC.haltedSymbolStatList.mwcbHalt),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.haltedSymbolStatList.mwcbHalt+
                                data.data.TapeB.haltedSymbolStatList.mwcbHalt+
                                data.data.TapeC.haltedSymbolStatList.mwcbHalt),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        )
                    },
                    {
                        group: ['Halted', 'System Issue'],
                        tapeA: [
                            data.data.TapeA.haltedSymbolStatList.systemHalt,
                            tapeAHalted
                        ],
                        tapeB: [
                            data.data.TapeB.haltedSymbolStatList.systemHalt,
                            tapeBHalted
                        ],
                        tapeC: [
                            data.data.TapeC.haltedSymbolStatList.systemHalt,
                            tapeCHalted
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.haltedSymbolStatList.systemHalt,
                            tapeAHalted
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.haltedSymbolStatList.systemHalt,
                            tapeBHalted
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.haltedSymbolStatList.systemHalt,
                            tapeCHalted
                        ),
                        total: [
                            (data.data.TapeA.haltedSymbolStatList.systemHalt+
                                data.data.TapeB.haltedSymbolStatList.systemHalt+
                                data.data.TapeC.haltedSymbolStatList.systemHalt),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.haltedSymbolStatList.systemHalt+
                                data.data.TapeB.haltedSymbolStatList.systemHalt+
                                data.data.TapeC.haltedSymbolStatList.systemHalt),
                            (tapeAHalted + tapeBHalted + tapeCHalted)
                        )
                    },
                    {
                        group: ['SSR'],
                        tapeA: [
                            tapeASsr,
                            data.meta.totalCounts.TapeA.totalSymbol
                        ],
                        tapeB: [
                            tapeBSsr,
                            data.meta.totalCounts.TapeB.totalSymbol
                        ],
                        tapeC: [
                            tapeCSsr,
                            data.meta.totalCounts.TapeC.totalSymbol
                        ],
                        tapeAPercent: getPercentage(
                            tapeASsr,
                            data.meta.totalCounts.TapeA.totalSymbol
                        ),
                        tapeBPercent: getPercentage(
                            tapeBSsr,
                            data.meta.totalCounts.TapeB.totalSymbol
                        ),
                        tapeCPercent: getPercentage(
                            tapeCSsr,
                            data.meta.totalCounts.TapeC.totalSymbol
                        ),
                        total: [
                            (tapeASsr + tapeBSsr + tapeCSsr ),
                            (data.meta.totalCounts.TapeA.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol+
                                data.meta.totalCounts.TapeC.totalSymbol)
                        ],
                        totalPercent: getPercentage(
                            (tapeASsr + tapeBSsr + tapeCSsr ),
                            (data.meta.totalCounts.TapeA.totalSymbol+
                                data.meta.totalCounts.TapeB.totalSymbol+
                                data.meta.totalCounts.TapeC.totalSymbol)
                        )
                    },
                    {
                        group: ['SSR', 'SSR 1'],
                        tapeA: [
                            data.data.TapeA.ssrSymbolStatList.ssr1,
                            tapeASsr
                        ],
                        tapeB: [
                            data.data.TapeB.ssrSymbolStatList.ssr1,
                            tapeBSsr
                        ],
                        tapeC: [
                            data.data.TapeC.ssrSymbolStatList.ssr1,
                            tapeCSsr
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.ssrSymbolStatList.ssr1,
                            tapeASsr
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.ssrSymbolStatList.ssr1,
                            tapeBSsr
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.ssrSymbolStatList.ssr1,
                            tapeCSsr
                        ),
                        total: [
                            (data.data.TapeA.ssrSymbolStatList.ssr1+
                                data.data.TapeB.ssrSymbolStatList.ssr1+
                                data.data.TapeC.ssrSymbolStatList.ssr1),
                            (tapeASsr + tapeBSsr + tapeCSsr)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.ssrSymbolStatList.ssr1+
                                data.data.TapeB.ssrSymbolStatList.ssr1+
                                data.data.TapeC.ssrSymbolStatList.ssr1),
                            (tapeASsr + tapeBSsr + tapeCSsr)
                        )
                    },
                    {
                        group: ['SSR', 'SSR 2'],
                        tapeA: [
                            data.data.TapeA.ssrSymbolStatList.ssr2,
                            tapeASsr
                        ],
                        tapeB: [
                            data.data.TapeB.ssrSymbolStatList.ssr2,
                            tapeBSsr
                        ],
                        tapeC: [
                            data.data.TapeC.ssrSymbolStatList.ssr2,
                            tapeCSsr
                        ],
                        tapeAPercent: getPercentage(
                            data.data.TapeA.ssrSymbolStatList.ssr2,
                            tapeASsr
                        ),
                        tapeBPercent: getPercentage(
                            data.data.TapeB.ssrSymbolStatList.ssr2,
                            tapeBSsr
                        ),
                        tapeCPercent: getPercentage(
                            data.data.TapeC.ssrSymbolStatList.ssr2,
                            tapeCSsr
                        ),
                        total: [
                            (data.data.TapeA.ssrSymbolStatList.ssr2+
                                data.data.TapeB.ssrSymbolStatList.ssr2+
                                data.data.TapeC.ssrSymbolStatList.ssr2),
                            (tapeASsr + tapeBSsr + tapeCSsr)
                        ],
                        totalPercent: getPercentage(
                            (data.data.TapeA.ssrSymbolStatList.ssr2+
                                data.data.TapeB.ssrSymbolStatList.ssr2+
                                data.data.TapeC.ssrSymbolStatList.ssr2),
                            (tapeASsr + tapeBSsr + tapeCSsr)
                        )
                    }
                ].map((row) => ({
                    ...row,
                    uuid: row.group.join('|')
                })),
                timestamp: LocalDateTime.now()
            }
        }
        else if (config.url === '/api/haltedSymbols') {
            const response = data.data.reduce((response, item) => {
                if (state.symbolStates[item.symbolState].group === 'mwcbHalts') {
                    response.mwcbHalts += 1;
                }
                switch (item.tape) {
                    case 'TapeA':
                        response.tapeA.push(item);
                        response.totalHalts +=1;
                        break;
                    case 'TapeB':
                        response.tapeB.push(item);
                        response.totalHalts +=1;
                        break;
                    case 'TapeC':
                        response.tapeC.push(item);
                        response.totalHalts +=1;
                        break;
                }
                return response;
            }, {tapeA:[], tapeB:[], tapeC:[], totalHalts: 0, mwcbHalts: 0});
            return {
                ...state,
                error: false,
                isLoading: false,
                haltedSymbolsRowData: [
                    {
                        uuid: 'haltedSymbols',
                        tapeA: chunk(
                            sortBy(response.tapeA, [
                                'symbolState'
                            ]).map((symbol) => ({
                                ...symbol,
                                haltTime: symbol.haltTime
                                    ? joda.format(
                                        LocalDateTime.parse(symbol.haltTime),
                                        'MM/dd/yyyy HH:mm:ss.SSS'
                                    )
                                    : 'N/A',
                                resumeTime: symbol.resumeTime
                                    ? joda.format(
                                        LocalDateTime.parse(symbol.resumeTime),
                                        'MM/dd/yyyy HH:mm:ss.SSS'
                                    )
                                    : 'N/A',
                                symbolState: symbol.symbolState,
                                symbolStateLabel:
                                state.symbolStates[symbol.symbolState].label,
                                symbolStateGroup:
                                state.symbolStates[symbol.symbolState].group
                            })),
                            3
                        ),
                        tapeB: chunk(
                            sortBy(response.tapeB, [
                                'symbolState'
                            ]).map((symbol) => ({
                                ...symbol,
                                haltTime: symbol.haltTime
                                    ? joda.format(
                                        LocalDateTime.parse(symbol.haltTime),
                                        'MM/dd/yyyy HH:mm:ss.SSS'
                                    )
                                    : 'N/A',
                                resumeTime: symbol.resumeTime
                                    ? joda.format(
                                        LocalDateTime.parse(symbol.resumeTime),
                                        'MM/dd/yyyy HH:mm:ss.SSS'
                                    )
                                    : 'N/A',
                                symbolState: symbol.symbolState,
                                symbolStateLabel:
                                state.symbolStates[symbol.symbolState].label,
                                symbolStateGroup:
                                state.symbolStates[symbol.symbolState].group
                            })),
                            3
                        ),
                        tapeC: chunk(
                            sortBy(response.tapeC, [
                                'symbolState'
                            ]).map((symbol) => ({
                                ...symbol,
                                haltTime: symbol.haltTime
                                    ? joda.format(
                                        LocalDateTime.parse(symbol.haltTime),
                                        'MM/dd/yyyy HH:mm:ss.SSS'
                                    )
                                    : 'N/A',
                                resumeTime: symbol.resumeTime
                                    ? joda.format(
                                        LocalDateTime.parse(symbol.resumeTime),
                                        'MM/dd/yyyy HH:mm:ss.SSS'
                                    )
                                    : 'N/A',
                                symbolState: symbol.symbolState,
                                symbolStateLabel: state.symbolStates[symbol.symbolState].label,
                                symbolStateGroup: state.symbolStates[symbol.symbolState].group
                            })),
                            3
                        )
                    }
                ],
                timestamp: LocalDateTime.now(),
                hasMwCbAlert: response.mwcbHalts >= 20,
                totalHalts: formatNumber(response.totalHalts)
            }
        }
        return {
            ...state,
            error: false,
            isLoading: false,
            coreSessionDataRowData: data.data.reduce((response, item) => {
                const tape = item.tape.charAt(0).toLowerCase() + item.tape.slice(1);
                response[0] = {
                    ...response[0],
                    [tape]: item.shares
                };
                response[1] = {
                    ...response[1],
                    [tape]: item.trades
                };
                return response;
            }, [{label: 'Shares', 'total': data.meta?.totalShares||0, uuid: v4() },
                {label: 'Trades', 'total': data.meta?.totalTrades||0, uuid: v4() }]),
            timestamp: LocalDateTime.now()
        };
    },
    [events.CORE_SESSION_ERROR]: (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
        coreSessionDataRowData: [],
        symbolStateRowData: [],
        haltedSymbolsRowData: [],
        hasMwCbAlert: false,
        timestamp: LocalDateTime.now(),
        totalHalts: '0'
    }),
    [events.CORE_SESSION_RESET]: (state) => ({
        ...state,
        error: false,
        hasMwCbAlert: false,
        isLoading: false,
        coreSessionDataRowData: [],
        symbolStateRowData: [],
        haltedSymbolsRowData: [],
        timestamp: LocalDateTime.now(),
        totalHalts: '0'
    })
};
