import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DetailsIcon from '@material-ui/icons/Details';
import cn from 'classnames';
import NyModal from 'nyse-web-tools-common/lib/react/generic/NyModal/NyModal';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import React, { useCallback, useState } from 'react';
import { useToggle } from 'react-use';

import { RiskSettingsData } from '../../../resources/riskSettings.resource';
import { Entitlement } from '../../../types';
import { Entitlements } from '../../displays/entitlements';
import { TabMenu } from '../../navigation/tabMenu';
import { DisableSettingsForm } from './forms/disableSettings.form';
import { SettingsForm } from './forms/settings.form';
import { ConsentForm } from './forms/consent.form';
import { OrderRestrictionForm } from './forms/orderRestrication.form';
import { OrderPercentageAdvForm } from './forms/orderPercentageAdv.form';
import { PriceProtectionForm } from './forms/priceProtection.form';

import useOptionsCheck from '../../../hooks/useOptionsCheck.hook';

type SettingDetailsModalProps = {
    data: {
        crd: number;
        displayRiskEntity: string;
        RiskSettingsData: RiskSettingsData;
        limitCrdRole: string;
        limitCrd: number;
        entityType: string;
    };
    context: {
        entitlement: Entitlement;
        onModalClosed(status: boolean): void;
        isOptionsMarket: boolean;
    };
};

const useStyles = makeStyles((theme: any) => ({
    alert: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: `${theme.spacing(2)}px !important`
    },
    controls: {
        paddingBottom: '0 !important'
    },
    detailsBtn: {
        margin: 0
    },
    form: {
        display: 'contents'
    },
    formBody: {
        overflowY: 'auto',
        height: '100%'
    },
    hideForm: {
        display: 'none'
    },
    modal: {
        overflowY: 'auto'
    },
    radioBtns: {
        marginBottom: 24
    },
    tabs: {
        marginTop: `${theme.spacing(1) * -1}px !important`,
        paddingTop: '0 !important'
    }
}));

const GetSettingTabTitle = (entityType, isOptionsMarket) => {
    if (entityType === 'MPID' || entityType === 'MPID_SUBID' || entityType === 'BROKER_MPID_SUBID') {
        return (isOptionsMarket) ? 'Single Order & GRMP' : 'Single Order & Gross Credit';
    }
    else if (entityType === 'MPID_MMID' )
        return 'Single Order & GRMP';
    else if (entityType === 'SYMBOL_MPID')
        return (isOptionsMarket) ? 'Activity' : 'Email';
    else if (entityType === 'SYMBOL_MPID_SUBID')
        return 'Email';
    else if (entityType === 'SYMBOL_MPID_MMID')
        return 'Activity';
    else
        return 'Settings';
};

export const SettingDetailsModal: React.FC<SettingDetailsModalProps> = React.memo(
    (props) => {
        const classes = useStyles(props);
        const isOptionsMarket = useOptionsCheck();

        const isConsentAllowed = (props.data.limitCrdRole === 'floor_broker' && props.data.entityType === 'BROKER_MPID_SUBID') ||
            (props.data.limitCrdRole === 'entering' && (props.data.entityType === 'MPID'
                                                            || props.data.entityType === 'SYMBOL_MPID'
                                                            || props.data.entityType === 'MPID_SUBID'
                                                            || props.data.entityType === 'SYMBOL_MPID_SUBID'));

        const isResetAllowed = (props.data.limitCrdRole === 'entering' && (props.data.entityType === 'MPID'
                || props.data.entityType === 'SYMBOL_MPID'
                || props.data.entityType === 'MPID_SUBID'
                || props.data.entityType === 'SYMBOL_MPID_SUBID')) ||
            (props.data.limitCrdRole === 'options_mm' && (props.data.entityType === 'MPID_MMID'
                ||props.data.entityType === 'SYMBOL_MPID_MMID'));

        const isOrderRestrictionAllowed = (props.data.limitCrdRole === 'entering' && (props.data.entityType === 'MPID'
                || props.data.entityType === 'SYMBOL_MPID'
                || props.data.entityType === 'MPID_SUBID'
                || props.data.entityType === 'SYMBOL_MPID_SUBID'))||
            (props.data.limitCrdRole === 'options_mm' && (props.data.entityType === 'MPID_MMID'
                ||props.data.entityType === 'SYMBOL_MPID_MMID'));

        const isOrderAdvAllowed = props.data.limitCrdRole === 'entering' && !isOptionsMarket &&
            (props.data.entityType === 'MPID'
                || props.data.entityType === 'SYMBOL_MPID'
                || props.data.entityType === 'MPID_SUBID'
                || props.data.entityType === 'SYMBOL_MPID_SUBID');

        const priceProtections = {
            isPriceProtectionsAllowed: props.data.limitCrdRole === 'entering' && !isOptionsMarket &&
                               (props.data.entityType === 'MPID' || props.data.entityType === 'MPID_SUBID'),
            isSingleLegPriceProtections: isOptionsMarket && (
                            (props.data.limitCrdRole === 'entering' && (props.data.entityType === 'MPID' || props.data.entityType === 'MPID_SUBID'))
                            || (props.data.limitCrdRole === 'options_mm' && props.data.entityType === 'MPID_MMID')),
            isComplexPriceProtections: isOptionsMarket && (
                (props.data.limitCrdRole === 'entering' && (props.data.entityType === 'MPID' || props.data.entityType === 'MPID_SUBID'))
                || (props.data.limitCrdRole === 'options_mm' && props.data.entityType === 'MPID_MMID'))
        }

        const [show, toggleShow] = useToggle(false);
        const [status, setStatus] = useState(false);
        const [tab, setTab] = useState('settings');
        const [tabs] = useState([
            ...[{
                    label: GetSettingTabTitle(props.data.entityType, isOptionsMarket),
                    value: 'settings'
                }],
            ...(isOrderRestrictionAllowed
                ? [{
                    label: 'Order Restriction',
                    value: 'orderRestrictionSettings'
                }]
                :[]),
            ...(isOrderAdvAllowed
                ? [{
                    label: 'Order % ADV',
                    value: 'orderAdv'
                }]
                : []),
            ...(priceProtections.isPriceProtectionsAllowed
                ? [{
                    label: 'Price Protection',
                    value: 'eqPriceProtection'
                }]
                : []),
            ...(priceProtections.isSingleLegPriceProtections
                ? [{
                    label: 'Single Leg Price Protection',
                    value: 'sPriceProtection'
                }]
                : []),
            ...(priceProtections.isComplexPriceProtections
                ? [{
                    label: 'Complex Price Protection',
                    value: 'cPriceProtection'
                }]
                : []),
            ...(isConsentAllowed && !isOptionsMarket
                ? [{
                    label: 'Consent',
                    value: 'consent'
                    }]
                : [])
            ]);

        const onSuccess = useCallback(() => {
            setStatus(() => true);
        }, []);

        useMountedEffect(() => {
            if (!show) {
                props.context.onModalClosed(status);
            } else if (status) {
                setStatus(() => false);
            }
        }, [show]);

        return (
            <>
                <Box alignSelf='flex-start'>
                    <IconButton
                        data-e2e-tag='riskSettingDetailsBtn'
                        data-user-role-crd={
                            props.data.limitCrdRole + props.data.limitCrd
                        }
                        data-name='SettingDetail'
                        data-modal-name='detailSettingsModal'
                        color='secondary'
                        className={classes.detailsBtn}
                        onClick={toggleShow}
                        size='small'>
                        <DetailsIcon/>
                    </IconButton>
                </Box>
                <NyModal
                    data-type='modal'
                    className={cn({medium: true}, classes.modal)}
                    hideModal={toggleShow}
                    name='detailSettings'
                    parentTitle='Risk Settings'
                    show={show}
                    title={
                        <>
                            Risk Entity
                            <Box display='inline' color='text.secondary'>
                                &nbsp;/&nbsp;{props.data.displayRiskEntity}
                            </Box>
                        </>
                    }>
                    {tabs.length > 1 &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.tabs}>
                            <Entitlements
                                exclude='clearing'
                                role={props.data.limitCrdRole}>
                                <TabMenu
                                    tabs={tabs}
                                    onChange={setTab}
                                    value={tab}
                                />
                            </Entitlements>
                        </Grid>
                    </Grid>}
                    <SettingsForm
                        className={tab === 'settings' ? '' : classes.hideForm}
                        classes={classes}
                        context={props.context}
                        data={props.data}
                        onSuccess={onSuccess}
                    />
                    {isOrderRestrictionAllowed &&  (
                        <OrderRestrictionForm
                            className={tab === 'orderRestrictionSettings' ? '' : classes.hideForm}
                            classes={classes}
                            context={props.context}
                            data={props.data}
                            onSuccess={onSuccess}
                        />
                    )}
                    {isOrderAdvAllowed && (
                        <OrderPercentageAdvForm
                            className={tab === 'orderAdv' ? '' : classes.hideForm}
                            classes={classes}
                            context={props.context}
                            data={props.data}
                            onSuccess={onSuccess}
                        />
                    )}
                    {priceProtections.isPriceProtectionsAllowed && (
                        <PriceProtectionForm
                            className={tab === 'eqPriceProtection' ? '' : classes.hideForm}
                            classes={classes}
                            context={props.context}
                            data={props.data}
                            onSuccess={onSuccess}
                            riskType={'lmt_ord_prc_eq'}
                        />
                    )}
                    {priceProtections.isSingleLegPriceProtections && (
                        <PriceProtectionForm
                            className={tab === 'sPriceProtection' ? '' : classes.hideForm}
                            classes={classes}
                            context={props.context}
                            data={props.data}
                            onSuccess={onSuccess}
                            riskType={'lmt_ord_prc_opt_leg'}
                        />
                    )}
                    {priceProtections.isComplexPriceProtections && (
                        <PriceProtectionForm
                            className={tab === 'cPriceProtection' ? '' : classes.hideForm}
                            classes={classes}
                            context={props.context}
                            data={props.data}
                            onSuccess={onSuccess}
                            riskType={'lmt_ord_prc_opt_complex'}
                        />
                    )}
                    {(isConsentAllowed && !isOptionsMarket) && (
                        <ConsentForm
                            className={tab === 'consent' ? '' : classes.hideForm}
                            classes={classes}
                            data={props.data}
                            onSuccess={onSuccess}
                        />
                    )}
                </NyModal>
            </>
        );
    }
);
