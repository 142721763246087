import {flatten} from 'lodash-es';
import {useEffect, useMemo} from 'react';

import {submittingBrokerIdResource} from '../resources/submittingBrokerId.resource';
import {Entitlement, SideTradeType} from '../resources/tradeSummary.resource';
import {useEntitlement} from './useEntitlement';
import useResource from './useResource.hook';
import useUser from './useUser.hook';
import useUserFirms from './useUserFirms';

type UseSubmittingBkrIdOptions = {
    sideCrd: number;
    firmId: string;
    tradeType: string;
    submittingBkrId: string;
};

export function useSubmittingBkrIdOptions(props: UseSubmittingBkrIdOptions) {
    const userFirms = useUserFirms();
    const user = useUser();
    const entitlement = useEntitlement() || user.entitlement;

    const firms = useMemo(
        () => (userFirms.data ?? []).filter(({active}) => active),
        [userFirms.data]
    );
    const params = useMemo(
        () => ({
            crd: props.sideCrd,
            mpId: props.firmId
        }),
        [props.sideCrd, props.firmId]
    );
    const participants = useMemo(
        () => firms.find(({crd}) => crd === props.sideCrd)?.participants ?? [],
        [firms, props.sideCrd]
    );
    const participantsWithSameFirmId = participants.filter(
        ({id}) => id == props.firmId
    );
    const badgesFromParticipantsWithSameFirmId = flatten(
        participantsWithSameFirmId.map(({badges}) =>
            badges.map((badge) => ({
                label: badge,
                value: badge
            }))
        )
    );
    const resource = useResource(submittingBrokerIdResource);
    useEffect(() => {
        props.sideCrd && props.firmId && resource.get(params);
    }, [params, props.sideCrd, props.firmId]);

    const isFloorBrokerTradeType =
        props.tradeType === SideTradeType.FLOOR_BROKER;

    const entitledOptions = useMemo(() => {
        if (resource?.data) {
            return entitlement === Entitlement.TRADE_CHECKER &&
                isFloorBrokerTradeType
                ? resource.data.map((badge) => ({
                      label: badge,
                      value: badge.toString()
                  }))
                : isFloorBrokerTradeType
                ? badgesFromParticipantsWithSameFirmId
                : resource.data
                      .filter((option) =>
                          badgesFromParticipantsWithSameFirmId.some(
                              (badge) => option === badge.value
                          )
                      )
                      .map((filteredBadge) => ({
                          label: filteredBadge,
                          value: filteredBadge.toString()
                      }));
        }
        return [];
    }, [
        resource.data,
        isFloorBrokerTradeType,
        badgesFromParticipantsWithSameFirmId
    ]);

    const optionsCombinedWithDbValue = useMemo(() => {
        if (entitledOptions) {
            return entitledOptions.some(
                (option) => option.value === props.submittingBkrId
            )
                ? entitledOptions
                : [
                      {
                          label: props.submittingBkrId,
                          value: props.submittingBkrId
                      },
                      ...entitledOptions
                  ];
        }
        return [];
    }, [entitledOptions, props.submittingBkrId]);

    return props.submittingBkrId ? optionsCombinedWithDbValue : entitledOptions;
}
