import Bowser from 'bowser';
import {NyButton, NyModal} from 'nyse-web-tools-common/lib/react';
import React, {useCallback, useMemo} from 'react';
import {useSessionStorage} from 'react-use';

export default React.memo(() => {
    const [isConfirmed, setIsConfirmed] = useSessionStorage(
        'outdatedBrowserAccepted',
        false
    );

    const isSupported = useMemo(
        () =>
            Bowser.getParser(window.navigator.userAgent).satisfies({
                chrome: '>=60',
                firefox: '>=60',
                safari: '>=12'
            }),
        []
    );

    const onClick = useCallback(() => {
        setIsConfirmed(true);
    }, []);

    return (
        <NyModal
            name='browserUnsupported'
            title='Unsupported Browser'
            show={!isSupported && !isConfirmed}>
            <div className='row'>
                <strong className='col-xs-12 col-sm-12 col-md-12 col-lg-12 space-bottom-2'>
                    This application is designed to work best with the latest
                    versions of Chrome and Firefox.
                </strong>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 space-bottom-2'>
                    If you are using an older version of these browsers or a
                    different browser, please consider downloading the latest
                    versions:
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 flex-row flex-align-center'>
                    <i className='mdi mdi-24px mdi-google-chrome space-right-1' />
                    <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer'>
                        Download Here
                    </a>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 flex-row flex-align-center'>
                    <i className='mdi mdi-24px mdi-firefox space-right-1' />
                    <a
                        href='https://www.mozilla.org/en-US/firefox/download/thanks/'
                        target='_blank' rel='noreferrer'>
                        Download Here
                    </a>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 space-top-2'>
                    <NyButton
                        data-e2e-tag='continueBtn'
                        danger
                        className='width-full'
                        onClick={onClick}>
                        Continue
                    </NyButton>
                </div>
            </div>
        </NyModal>
    );
});
