import {get} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridPagination,
    AgGridProvider,
    AgGridToggleShowFilters,
    NyDateTimeInput,
    NyDateTimeRangeInput,
    NyNumberInput,
    NySelectInput,
    NyTextInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {Access} from '../components/displays/access';
import {DownloadProgress} from '../components/displays/download.progress';
import {NavigationSectionMenu} from '../components/navigation/navigation.section.menu';
import {FirmSelect} from '../components/selects/firm.select';
import {FirmParticipantSelect} from '../components/selects/firmParticipant.select';
import {LiqIndicatorSelect} from '../components/selects/liquidityIndicator.select';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {getTrades, getTradesExport} from '../stores/trades/trades.actions';

@connect(['columnDefs', 'downloads', 'firms', 'platform', 'route', 'trades'], {
    getTrades,
    getTradesExport,
    setColumns
})
export class TradesPage extends React.Component {
    state = {
        exportFileName: this.props.route.title,
        filterValues: {},
        views: [
            {
                title: 'All Trades',
                url: '/:platform/trades/all'
            },
            {
                permissions: ['view opra entry'],
                title: 'QCT Trades',
                url: '/:platform/trades/qct'
            }
        ]
    };

    _initialFilters = {
        crd: this.props.route.params.crd || (this.props.firms[0] || {}).crd,
        matcherTime: {
            start: joda.startOfDay(),
            end: joda.endOfDay(true)
        },
        date: joda.startOfDay()
    };

    _initialPagination = {
        limit: this.props.route.title === 'Trades' ? 500 : 1000,
        offset: 0
    };

    _onExportAll = (data) => {
        this.setState(
            {
                exportFileName: data.fileName
            },
            () => {
                this.props.getTradesExport({
                    fileName: this.state.exportFileName,
                    fileExtension: 'xlsx',
                    data
                });
            }
        );
    };

    _onModalClosed = (isSuccessful) => {
        if (isSuccessful) {
            this._onLoad(this.state.filterValues);
        }
    };

    _onFiltersChanged = (filterValues) => {
        this.setState({filterValues});
    };

    _onFirmSelect = ({setValue}) => {
        setValue('participantId', []);
    };

    _onLoad = (filterValues) => {
        this.props.getTrades({
            ...filterValues,
            qctTrade: this.props.route.title === 'QCT Trades'
        });
    };

    render() {
        const download = get(this.props.downloads, this.state.exportFileName, {
            error: false,
            isDownloading: false,
            progress: 0
        });

        const columnNamespace =
            this.props.route.title === 'Trades' ? 'trades' : 'qctTrades';

        return (
            <>
                <Access permissions={['view opra entry']}>
                    <div className='row'>
                        <div className='col-sm-12 col-xs-12 col-md-12 col-lg-12'>
                            {/* todo:: add logic to hide this if platform !== xchi || views length is 1 */}
                            <NavigationSectionMenu
                                queryParams={this.state.filterValues}
                                views={this.state.views}
                            />
                        </div>
                    </div>
                </Access>
                <AgGridProvider
                    columnNamespace={columnNamespace}
                    columnDefs={this.props.columnDefs[columnNamespace]}
                    filtersClassName='row space-bottom-1'
                    initialPagination={this._initialPagination}
                    initialFilters={this._initialFilters}
                    onFiltersChanged={this._onFiltersChanged}
                    onLoad={this._onLoad}
                    onColumnsChanged={this.props.setColumns}
                    permissions={[this.props.platform.mic]}
                    submitOnLoad={true}
                    submitOnNamespaceChanged={true}
                    {...this.props.trades}>
                    <AgGridProvider.Filters>
                        <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                            <label>Symbol</label>
                            <NyUppercaseInput name='symbol' />
                        </div>
                        {this.props.route.title === 'Trades' ? (
                            <>
                                <div className='form-group col-xs-12 col-sm-5 col-md-4 col-lg-3'>
                                    <label>Matcher Time</label>
                                    <NyDateTimeRangeInput
                                        className='form-control'
                                        name='matcherTime'
                                        required
                                    />
                                </div>
                                {this.props.platform.mic === 'XCHI' && (
                                    <>
                                        <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                                            <label>Srce Exchg</label>
                                            <NySelectInput name='sourceExchange'>
                                                <option value=''>-</option>
                                                <option value='XCHI'>
                                                    XCHI
                                                </option>
                                                <option value='BPLX'>
                                                    BPLX
                                                </option>
                                            </NySelectInput>
                                        </div>
                                        <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                                            <label>Parent</label>
                                            <NySelectInput name='parentFlag'>
                                                <option value=''>-</option>
                                                <option value='Y'>Y</option>
                                                <option value='N'>N</option>
                                            </NySelectInput>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                                <label>Matcher Time</label>
                                <NyDateTimeInput
                                    className='form-control'
                                    format='MM/dd/yyyy'
                                    name='date'
                                    required
                                />
                            </div>
                        )}
                        <div className='form-group col-xs-12 col-sm-5 col-md-3 col-lg-2'>
                            <label>Price</label>
                            <div className='form-group-span'>
                                <NyNumberInput
                                    name='startPrice'
                                    max='endPrice'
                                    precision={6}
                                />
                                <span className='flex-shrink to-span'>TO</span>
                                <NyNumberInput
                                    name='endPrice'
                                    min='startPrice'
                                    precision={6}
                                />
                            </div>
                        </div>
                        <div className='form-group col-xs-12 col-sm-4 col-md-3 col-lg-2'>
                            <label>Qty</label>
                            <div className='form-group-span'>
                                <NyNumberInput
                                    name='startQuantity'
                                    max='endQuantity'
                                />
                                <span className='flex-shrink to-span'>TO</span>
                                <NyNumberInput
                                    name='endQuantity'
                                    min='startQuantity'
                                />
                            </div>
                        </div>
                        <div className='form-group col-xs-12 col-sm-2 col-md-2 col-lg-1'>
                            <label>Side</label>
                            <NySelectInput name='side'>
                                <option value=''>-</option>
                                <option value='1'>Buy</option>
                                <option value='2'>Sell</option>
                                <option value='5'>SSH</option>
                                <option value='6'>SSX</option>
                                <option value='3'>Buy Minus</option>
                                <option value='4'>Sell Plus</option>
                                <option value='8'>Cross</option>
                                <option value='9'>Cross SSH</option>
                                <option value='A'>Cross SSX</option>
                                <option value='S'>Short Sell w/ Slide</option>
                            </NySelectInput>
                        </div>
                        {this.props.firms.length > 1 && (
                            <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                                <label>Firm</label>
                                <FirmSelect
                                    initialValue={this.props.route.params.crd}
                                    name='crd'
                                    onChange={this._onFirmSelect}
                                    required
                                    showInactive
                                />
                            </div>
                        )}
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-1'>
                            <label>MPID</label>
                            <FirmParticipantSelect
                                crd={this.state.filterValues.crd}
                                isMulti
                                name='participantId'
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Original Deal Id</label>
                            <NyNumberInput
                                includeThousandsSeparator={false}
                                name='dealIdOrig'
                                valueAsString
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Deal Id</label>
                            <NyNumberInput
                                className='form-control'
                                includeThousandsSeparator={false}
                                name='dealId'
                                valueAsString
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Account Number</label>
                            <NyTextInput name='accountNumber' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Domain</label>
                            <NyTextInput name='domain' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Client Order Id</label>
                            <NyTextInput name='clientOrderId' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <label>Liquidity Indicator</label>
                            <LiqIndicatorSelect
                                isMulti={true}
                                name='liquidityIndicator'
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Sender Sub Id</label>
                            <NyTextInput name='senderSubId' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Session Id</label>
                            <NyTextInput name='sessionId' />
                        </div>
                    </AgGridProvider.Filters>
                    <div className='row space-bottom-1'>
                        <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                            <div className='flex-row flex-align-bottom'>
                                <AgGridProvider.SubmitBtn>
                                    Search
                                </AgGridProvider.SubmitBtn>
                                <AgGridProvider.ResetBtn>
                                    Reset
                                </AgGridProvider.ResetBtn>
                                <div className='btn-circle-grp flex-pull-right'>
                                    <AgGridToggleShowFilters />
                                    <AgGridExportMenu
                                        exportFileName={
                                            this.state.exportFileName
                                        }
                                        exportSheetName={
                                            this.props.platform.name
                                        }
                                        has100kExport={
                                            this.props.route.title === 'Trades'
                                        }
                                        has500kExport={false}
                                        isExportingAll={download.isDownloading}
                                        onExportAll={this._onExportAll}
                                        progressBar={
                                            <DownloadProgress
                                                fileName={
                                                    this.state.exportFileName
                                                }
                                            />
                                        }
                                    />
                                    <AgGridColumnMenuTrigger />
                                </div>
                            </div>
                        </div>
                    </div>

                    <AgGrid
                        context={{
                            crd: this.state.filterValues.crd,
                            onModalClosed: this._onModalClosed
                        }}
                        reactNext={true}
                    />

                    <AgGridPagination>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1,000</option>
                        <option value={5000}>5,000</option>
                        <option value={10000}>10,000</option>
                    </AgGridPagination>
                </AgGridProvider>
            </>
        );
    }
}
