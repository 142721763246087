import {QueryFunctionContext, UseQueryResult, useQuery} from 'react-query';

import {sentinelMw} from '../api';

export const useGboRefData = (): UseQueryResult<string, any> => {
    return useQuery(
        ['firms/cache-time'],
        async (context: QueryFunctionContext): Promise<any> => {
            const {data} = await sentinelMw.get(context.queryKey[0] as string);

            return data;
        }
    );
};
