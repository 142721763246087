import React, {useMemo} from 'react';
import {TextInput} from 'nyse-web-tools-common/lib/react';
import useNyField from 'nyse-web-tools-common/lib/react/hooks/useNyField/useNyField';
import * as yup from 'yup';

const testIsRequired = (props) => {
    return function (this, value: any) {
        if (!props.required || props.disabled) {
            return true;
        }

        if (props.required && props.isLoading) {
            return this.createError({
                path: this.path,
                message: 'Required'
            });
        }

        if (Array.isArray(value)) {
            return (
                value.length > 0 ||
                this.createError({
                    path: this.path,
                    message: 'Required'
                })
            );
        }

        const isDefined =
            value !== '' && value !== undefined && value !== false;

        return (
            isDefined ||
            this.createError({
                path: this.path,
                message: 'Required'
            })
        );
    };
};

type NyTextInputProps = {
    autoFocus?: boolean;
    disabled?: boolean;
    flex?: number | string;
    fullWidth?: boolean;
    maxLength?: number;
    name?: string;
    onChange(value: any, option?: any): void;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    valid?: boolean;
    validation?: string;
    value?: any;
};

export const NyMultiEditInputSelect: React.FC<NyTextInputProps> = React.memo((props) => {
    const fieldCtx = useNyField(
        props,
        useMemo(
            () =>
                yup.mixed().test({
                    test: testIsRequired({
                        disabled: props.disabled,
                        required: props.required,
                        readOnly: props.readOnly
                    })
                }),
            [props.disabled, props.readOnly, props.required]
        )
    );

    return (
        <TextInput
            data-e2e-tag={props['data-e2e-tag']}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            flex={props.flex}
            fullWidth={props.fullWidth}
            maxLength={props.maxLength}
            name={props.name}
            onChange={fieldCtx.onChange}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            required={props.required}
            valid={fieldCtx.valid}
            validation={fieldCtx.validation}
            value={fieldCtx.value}
        />
    );
});

NyMultiEditInputSelect.defaultProps = {
    disabled: false,
    maxLength: 50,
    readOnly: false,
    required: false
};
