import Typography from '@material-ui/core/Typography';
import {NyLoader} from 'nyse-web-tools-common/lib/react';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router';
import {useMount} from 'react-use';

import useRoute from '../hooks/useRoute.hook';
import useUser from '../hooks/useUser.hook';
import {getUser, resetUser, setUser} from '../stores/user/user.actions';

export const LoginPage = () => {
    const route = useRoute();
    const user = useUser();
    const dispatch = useDispatch();

    useMount(() => {
        if (user.isJwtValid || user.jwt) {
            return;
        }

        if (route.params.jwt) {
            dispatch(setUser({jwt: route.params.jwt}));
            dispatch(getUser()).catch(resetUser());
        } else {
            window.location.href = `/auth/login`;
        }
    });

    if (user.isJwtValid) {
        return <Redirect to='/' />;
    }

    return (
        <div className='text-center mt-2'>
            <Typography variant='h6'>
                {route.params.jwt ? 'Logging in...' : 'Redirecting...'}
            </Typography>
            <NyLoader show />
        </div>
    );
};
