import {SelectInput, UppercaseInput} from 'nyse-web-tools-common/lib/react';
import React, {useCallback, useMemo} from 'react';
import {CustFirm, stripSpecialChars} from '../../resources/tradeSummary.resource';

type MultiEditMmIdSelectProps = {
    data?: any,
    context?: any,
    name: string,
    value?: string,
    onChange?: any
};

export const MultiEditMmIdSelect = React.memo<MultiEditMmIdSelectProps>((props) => {
    const {isLocalMM, isAwayMM} = useMemo(() => ({
        isLocalMM: props?.data?.custFirm === CustFirm.MarketMaker,
        isAwayMM: props?.data?.custFirm === CustFirm.AwayMarketMaker
    }), [props?.data?.custFirm]);

    const onUppercaseInputChange = useCallback((value) => {
        props.onChange(stripSpecialChars(value));
    }, []);

    return (
        isLocalMM ? (
            <SelectInput
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                options={props?.context?.multiEdit?.custSndrSubIdOptions || []}
            />
        ) : (
            <UppercaseInput
                name={props.name}
                value={props.value}
                onChange={onUppercaseInputChange}
                disabled={!isAwayMM}
                readOnly={!isAwayMM}
                required={isAwayMM}
            />
        )
    );
});
