import {
    NyAlert,
    NyCheckboxInput,
    NyForm,
    NyRadioButtonInput,
    NyResetBtn,
    NySubmitBtn
} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {
    postBulkAction,
    resetBulkActionResults
} from '../../../stores/riskMonitor/riskMonitor.bulkAction.actions';

@connect(['platform', 'riskMonitorBulkAction'], {
    postBulkAction,
    resetBulkActionResults
})
export class RiskMonitorBulkAction extends React.Component {
    static propTypes = {
        crd: PropTypes.number.isRequired,
        mpid: PropTypes.string.isRequired
    };

    state = {
        values: {
            bulkAction: '',
            confirmDelete: false
        }
    };

    componentWillUnmount() {
        this.props.resetBulkActionResults();
    }

    _onChange = (values, {setFieldValue}) => {
        const isBulk =
            values.bulkAction &&
            !['BLOCK', 'UNBLOCK'].includes(values.bulkAction);

        if (this.state.values.bulkAction !== values.bulkAction) {
            if (values.confirmDelete) {
                setFieldValue('confirmDelete', false);
            }

            this.setState(
                {
                    values: {
                        ...values,
                        bulkAction: values.bulkAction
                    }
                },
                () => {
                    if (isBulk) {
                        this.props.resetBulkActionResults();
                        this._onSelectCancelAllOrders();
                    } else {
                        this.props.resetBulkActionResults();
                    }
                }
            );
        }
    };

    _onReset = () => {
        this.props.resetBulkActionResults();
    };

    _onSelectCancelAllOrders = () => {
        this.props.postBulkAction({
            mpid: this.props.mpid,
            bulkAction: this.state.values.bulkAction
        });
    };

    _onSubmit = (values) => {
        this.props.postBulkAction({
            mpid: this.props.mpid,
            ...values
        });
    };

    render() {
        const {
            error,
            isLoading,
            success,
            warning
        } = this.props.riskMonitorBulkAction;

        return (
            <NyForm
                initialValues={{
                    bulkAction: ''
                }}
                onChange={this._onChange}
                onReset={this._onReset}
                onSubmit={this._onSubmit}>
                <div className='form-group'>
                    <NyRadioButtonInput
                        name='bulkAction'
                        selectedValue='BLOCK'
                        required={true}>
                        Block
                    </NyRadioButtonInput>
                    <NyRadioButtonInput
                        name='bulkAction'
                        selectedValue='UNBLOCK'
                        required={true}>
                        Unblock
                    </NyRadioButtonInput>
                    <NyRadioButtonInput
                        name='bulkAction'
                        selectedValue='CANCEL_ALL_ORDERS_EXCLUDE_AUCTION_ORDERS'
                        required={true}>
                        Bulk Cancel&nbsp;
                        <span className='disclaimer'>
                            ( Exclude Auction Orders )
                        </span>
                    </NyRadioButtonInput>
                    <NyRadioButtonInput
                        name='bulkAction'
                        selectedValue='CANCEL_ALL_ORDERS_EXCLUDE_AUCTION_ORDERS_AND_BLOCK'
                        required={true}>
                        Bulk Cancel & Block&nbsp;
                        <span className='disclaimer'>
                            ( Exclude Auction Orders )
                        </span>
                    </NyRadioButtonInput>
                    <NyRadioButtonInput
                        name='bulkAction'
                        selectedValue='CANCEL_ALL_ORDERS'
                        required={true}>
                        Bulk Cancel All
                    </NyRadioButtonInput>
                    <NyRadioButtonInput
                        name='bulkAction'
                        selectedValue='CANCEL_ALL_ORDERS_AND_BLOCK'
                        required={true}>
                        Bulk Cancel All & Block
                    </NyRadioButtonInput>
                </div>

                <NyAlert show={!!success} title={success} type='success' />

                <NyAlert show={!!warning} detail={warning} type='warning' />

                {error && (
                    <NyAlert
                        show={true}
                        title={error.title}
                        detail={error.detail}
                        type='danger'
                    />
                )}

                <div className='form-group'>
                    <NyCheckboxInput name='confirmDelete' required={true}>
                        Confirm your selection
                    </NyCheckboxInput>
                </div>

                <hr className='flex-row space-top-2 space-bottom-2' />

                <div className='flex-row flex-align-center'>
                    <NySubmitBtn
                        danger
                        className='space-right-1'
                        disabled={error || isLoading}>
                        Submit
                    </NySubmitBtn>
                    <NyResetBtn>Reset</NyResetBtn>
                </div>
            </NyForm>
        );
    }
}
