import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridProvider,
    AgGridTimestamp,
    AgGridToggleShowFilters,
    NyDateTimeInput,
    NyNumberInput,
    NySelectInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {OPRAEntryRemoveBtn} from '../components/buttons/opraEntry/opraEntryRemove.btn';
import {OPRAEntryModal} from '../components/modals/opraEntry/opraEntry.modal';
import {FirmSelect} from '../components/selects/firm.select';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {getOpraEntries} from '../stores/opraEntries/opraEntries.actions';

@connect(
    ['columnDefs', 'firms', 'opraEntries', 'permissions', 'platform', 'route'],
    {setColumns, getOpraEntries}
)
export class OpraEntriesPage extends React.Component {
    state = {
        selected: [],
        values: {}
    };

    _dateRange = {
        max: joda.endOfDay(),
        min: joda.startOfDay().minusMonths(6)
    };

    _initialFilters = {
        crd: (this.props.firms[0] || {}).crd,
        date: joda.startOfDay()
    };

    _initialPagination = {
        limit: 100000,
        offset: 0
    };

    _onFiltersChanged = (values) => {
        this.setState({
            values
        });
    };

    _onLoad = (values) => {
        this.props.getOpraEntries(values);
    };

    _onModalClosed = (isSuccesful) => {
        if (isSuccesful) {
            this.props.getOpraEntries(this.state.values);
        }
    };

    _onSelectionChanged = (selected) => {
        this.setState({
            selected: selected.filter(({lock}) => !lock)
        });
    };

    render() {
        return (
            <AgGridProvider
                autoSize
                columnDefs={this.props.columnDefs.opraEntries}
                columnNamespace='opraEntries'
                initialFilters={this._initialFilters}
                initialPagination={this._initialPagination}
                onColumnsChanged={this.props.setColumns}
                onDetailsClosed={this._onModalClosed}
                onFiltersChanged={this._onFiltersChanged}
                onLoad={this._onLoad}
                onSelectionChanged={this._onSelectionChanged}
                permissions={this.props.permissions}
                rowSelection='multiple'
                submitOnLoad={true}
                {...this.props.opraEntries}>
                <AgGridProvider.Filters>
                    <div className='form-group col-lg-2 col-md-2 col-sm-4 col-xs-12'>
                        <label>OPRA Date</label>
                        <NyDateTimeInput
                            className='form-control'
                            format='MM/dd/yyyy'
                            name='date'
                            min={this._dateRange.min}
                            max={this._dateRange.max}
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-4 col-xs-12'>
                        <label>Execution Date</label>
                        <NyDateTimeInput
                            className='form-control'
                            format='MM/dd/yyyy'
                            name='tradeExecDate'
                            min={this._dateRange.min}
                            max={this._dateRange.max}
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>Deal Id</label>
                        <NyNumberInput name='dealId' valueAsString />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>Original Deal Id</label>
                        <NyNumberInput name='dealIdOrig' valueAsString />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>Firm</label>
                        <FirmSelect
                            initialValue={this.props.route.params.crd}
                            name='crd'
                            required
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>Status</label>
                        <NySelectInput name='status'>
                            <option value=''>-</option>
                            <option value='OPEN'>OPEN</option>
                            <option value='COMPLETED'>COMPLETED</option>
                            <option value='ACCEPTED'>ACCEPTED</option>
                            <option value='REJECTED_NO_MATCH'>
                                REJECTED_NO_MATCH
                            </option>
                            <option value='REJECTED_MULTIPLE_MATCHES'>
                                REJECTED_MULTIPLE_MATCHES
                            </option>
                        </NySelectInput>
                    </div>
                </AgGridProvider.Filters>
                <div className='row space-bottom-1'>
                    <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                        <div className='flex-row flex-align-bottom'>
                            <AgGridProvider.SubmitBtn>
                                Search
                            </AgGridProvider.SubmitBtn>
                            <AgGridProvider.ResetBtn>
                                Reset
                            </AgGridProvider.ResetBtn>
                            <div className='btn-circle-grp flex-pull-right flex-row'>
                                <OPRAEntryRemoveBtn
                                    onClose={this._onModalClosed}
                                    opraEntries={this.state.selected}
                                />
                                <div className='divider' />
                                <AgGridToggleShowFilters />
                                <AgGridExportMenu
                                    exportFileName='opraEntry'
                                    exportSheetName={this.props.platform.name}
                                />
                                <AgGridColumnMenuTrigger />
                            </div>
                        </div>
                    </div>
                </div>

                <AgGrid
                    DetailsModal={OPRAEntryModal}
                    submitOnDetailsClosed
                    reactNext={true}
                />

                <div className='row space-top-1'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <AgGridTimestamp />
                    </div>
                </div>
            </AgGridProvider>
        );
    }
}
