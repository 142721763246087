import numbro from 'numbro';
export const tradeSummaryTransactionHistoryColumnDefs = [
    {
        headerName: 'User Tx Id',
        field: 'userTxId',
        sortable: false
    },
    {
        headerName: 'Group Id',
        field: 'groupId',
        type: 'numericString'
    },
    {
        headerName: 'Status',
        field: 'status'
    },
    {
        headerName: 'User Action',
        field: 'userAction'
    },
    {
        headerName: 'Action',
        field: 'action'
    },
    {
        headerName: 'Updated Time',
        field: 'updatedTs',
        type: 'dateTime'
    },
    {
        headerName: 'Updated By',
        field: 'updatedBy'
    },
    {
        headerName: 'Reason',
        field: 'reason'
    },
    {
        headerName: 'Original Trade Date',
        field: 'origTdate',
        type: 'date'
    },
    {
        headerName: 'Event Type',
        field: 'evtTyp'
    },
    {
        headerName: 'Ref Exec Time',
        field: 'refExecTs',
        type: 'dateTime'
    },
    {
        headerName: 'Event Time',
        field: 'evtTs',
        type: 'dateTime'
    },
    {
        headerName: 'Common',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Deal Id',
                field: 'dealNum',
                type: 'numericString'
            },
            {
                headerName: 'Original Deal Id',
                field: 'dealIdOrig',
                type: 'numericString'
            },
            {
                headerName: 'Ref Deal Id',
                field: 'dealIdRef',
                type: 'numericString'
            },
            {
                field: 'symb',
                headerName: 'OSI Symbol'
            },
            {
                field: 'osiRootSymb',
                headerName: 'OSI Root Symbol'
            },
            {
                field: 'expirationDate',
                headerName: 'Expiration Date',
                type: 'date'
            },
            {
                field: 'putCall',
                headerName: 'Put/Call'
            },
            {
                field: 'strikePrice',
                headerName: 'Strike Price',
                type: 'price',
                cellRenderer: ({data}) =>
                    data.strikePrice === undefined
                        ? ''
                        : numbro(data.strikePrice).format({
                              mantissa: 8,
                              thousandSeparated: true
                          })
            },
            {
                field: 'execPrc',
                headerName: 'Price',
                type: 'price',
                cellRenderer: ({data}) =>
                    data.execPrc === undefined
                        ? ''
                        : numbro(data.execPrc).format({
                              mantissa: 8,
                              thousandSeparated: true
                          })
            },
            {
                field: 'execQty',
                headerName: 'Qty',
                type: 'number'
            },
            {
                field: 'tradeSequenceNumber',
                headerName: 'OCC Seq #'
            },
            {
                field: 'underlyingSymbol',
                headerName: 'Underlying Symbol'
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Customer Side',
        headerClass: 'success text-center',
        children: [
            {
                field: 'custClOrdId',
                headerName: 'Client Order Id'
            },
            {
                field: 'custFirmId',
                headerName: 'MPID'
            },
            {
                field: 'custIdPub',
                headerName: 'Public Order Id'
            },
            {
                field: 'custLqdtyInd',
                headerName: 'Liq Ind'
            },
            {
                field: 'custSessionId',
                headerName: 'Session Id'
            },
            {
                field: 'custSide',
                headerName: 'Side'
            },
            {
                field: 'custSndrSubId',
                headerName: 'MMID'
            },
            {
                field: 'custSubmittingBkrId',
                headerName: 'Badge'
            },
            {
                field: 'custClearingNumber',
                headerName: 'Clearing #'
            },
            {
                field: 'custOpenCloseDisplay',
                headerName: 'Open/Close'
            },
            {
                field: 'custFirmDisplay',
                headerName: 'Customer or Firm'
            },
            {
                field: 'custCmta',
                headerName: 'CMTA'
            },
            {
                field: 'custAccount',
                headerName: 'Account'
            },
            {
                field: 'custTif',
                headerName: 'Time In Force'
            },
            {
                field: 'custCrossId',
                headerName: 'Cross Id'
            },
            {
                field: 'custCapStrategyId',
                headerName: 'Cap Strategy'
            },
            {
                field: 'custOptnlData',
                headerName: 'Optional Data'
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Contra Side',
        headerClass: 'danger text-center',
        children: [
            {
                field: 'contraOrdFirmId',
                headerName: 'MPID'
            },
            {
                field: 'contraOrdSndrSubId',
                headerName: 'MMID'
            },
            {
                field: 'contraOrdSubmittingBkrId',
                headerName: 'Badge'
            },
            {
                field: 'contraOrdClearingNumber',
                headerName: 'Clearing #'
            },
            {
                field: 'contraOrdCrossId',
                headerName: 'Cross Id'
            },
            {
                field: 'contraOrdCapStrategyId',
                headerName: 'Cap Strategy'
            },
            {
                field: 'contraOrdOptnlData',
                headerName: 'Optional Data'
            }
        ],
        marryChildren: true
    }
];
