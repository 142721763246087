export const glossaryConfig = {
    activityReport: {
        title: 'Activity Report',
        description: '',
        terms: [
            {
                term: 'Action',
                description: 'Type of action taken'
            },
            {
                term: 'Client',
                description: 'Application used'
            },
            {
                term: 'IB',
                description: 'Institutional broker'
            },
            {
                term: 'Time of Action',
                description: 'Timestamp of the action event'
            },
            {
                term: 'User Id',
                description: 'The unique id for the user'
            },
            {
                term: 'User Name',
                description: 'The first and last name for the user'
            },
            {
                term: 'Result',
                description: 'The result for the action event'
            }
        ]
    },
    auctions: {
        title: 'Auctions',
        description: '',
        terms: [
            {
                term: 'Auction Collar',
                description:
                    'The price threshold for the Indicative Match Price for the Core Open Auction, Trading Halt Auction, or Closing Auction. Auctions will not trade at a price higher(less) than the upper(lower) collar but can trade at the collar price.'
            },
            {
                term: 'Auction Type',
                description: 'Early, Core, Close, Reopening, or IPO Auction'
            },
            {
                term: 'Primary Exchange',
                description: 'Primary Exchange of Symbol'
            },
            {
                term: 'Indicative Matched Price',
                description:
                    'Price at which the auction is indicated to match the most amount of shares possible.'
            },
            {
                term: 'Market Imbalance',
                description: 'Amount of shares on the imbalance side'
            },
            {
                term: 'Market Imbalance Side',
                description: 'Side of the imbalance'
            },
            {
                term: 'Matched Volume',
                description: 'Amount of shares matched in the auction'
            },
            {
                term: 'Net Change from Last Sale',
                description: 'Dollar amount change from last sale'
            },
            {
                term: 'Symbol',
                description: 'Symbol'
            },
            {
                term: 'Total Imbalance Side',
                description: 'Buy, sell, or none'
            }
        ]
    },
    idbOriginators: {
        title: 'IDB Originators',
        description: '',
        terms: [
            {
                term: 'Created By',
                description: 'The user id of whom created the record'
            },
            {
                term: 'Created Time',
                description: 'The timestamp of when the record was created'
            },
            {
                term: 'Daily Order Threshold',
                description: ''
            },
            {
                term: 'IDB',
                description: 'Interdealer broker'
            },
            {
                term: 'IDB/Originator Id',
                description: ''
            },
            {
                term: 'MPID',
                description: 'Market participant identifier'
            },
            {
                term: 'Modified By',
                description: 'The user id of whom last modified the record'
            },
            {
                term: 'Modified Time',
                description:
                    'The last timestamp of when the record was modified'
            },
            {
                term: 'OATS Id',
                description: 'Unique OATS record identifier'
            },
            {
                term: 'Order Threshold',
                description: ''
            },
            {
                term: 'Originator',
                description: ''
            },
            {
                term: 'Threshold Qty',
                description: ''
            },
            {
                term: 'Trading Account',
                description: ''
            }
        ]
    },
    liquidityMetrics: {
        // todo:: add in descriptions when tobin provides them.
        title: 'Liquidity Metrics',
        description: '',
        terms: [
            {
                term: 'ETPID / MPID',
                description:
                    'Exchange Trading Permit ID/ Market Participant ID assigned to firm'
            },
            {
                term: 'MMID',
                description: 'Market Maker ID'
            },
            {
                term: 'MM Type',
                description: 'Market Maker Type'
            },
            {
                term: 'Total',
                description:
                    'Total of Posted, Price Improved, and Auction shares traded'
            },
            {
                term: 'Posted',
                description: 'Posted Shares Traded'
            },
            {
                term: 'Posted Part. %',
                description: 'Percentage of Posted shares traded on venue'
            },
            {
                term: 'Posted Part. % of CADV',
                description: 'Percentage of Posted shares traded of the CADV'
            },
            {
                term: 'Exchange Part. %',
                description: 'Percentage of shares traded on the Exchange'
            },
            {
                term: 'Buy',
                description: 'Shares bought'
            },
            {
                term: 'Buy %',
                description: 'Percentage of shares bought on the Exchange'
            },
            {
                term: 'Sell',
                description: 'Shares sold'
            },
            {
                term: 'Sell %',
                description: 'Percentage of shares sold on the Exchange'
            },
            {
                term: 'SSH',
                description: 'Shares Sold Short'
            },
            {
                term: 'SSHE',
                description: 'Shares Sold Short Exempt'
            },
            {
                term: 'SSH & SSHE %',
                description: 'Percentage of Exchange shares traded as SSH & SSE'
            },
            {
                term: 'Opening Auction',
                description: 'Shares traded in Opening Auctions'
            },
            {
                term: 'Opening Auction %',
                description:
                    'Percentage of Opening Auction shares traded on the Exchange'
            },
            {
                term: 'Closing Auction',
                description: 'Shares traded in Closing Auctions'
            },
            {
                term: 'Closing Auction %',
                description:
                    'Percentage of Closing Auction shares traded on the Exchange'
            },
            {
                term: 'Close Price',
                description: 'Closing Price'
            },
            {
                term: 'Spread Cents',
                description: 'Average bid/ask spread in cents'
            },
            {
                term: 'Spread BPs',
                description: 'Average bid/ask spread in basis points'
            },
            {
                term: 'NBB',
                description: 'Shares Posted on the National Best Bid'
            },
            {
                term: 'NBB %',
                description:
                    'Percentage of shares posted on the Exchange at the National Best Bid'
            },
            {
                term: 'NBO',
                description: 'Shares Posted at the National Best Offer'
            },
            {
                term: 'NBO %',
                description:
                    'Percentage of shares posted on the Exchange at the National Best Offer'
            },
            {
                term: 'NBB -1 MPV',
                description:
                    'Shares Posted 1 MPV (Minimum Price Variation) below the NBB'
            },
            {
                term: 'NBO +1 MPV',
                description:
                    'Shares Posted 1 MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: 'NBB -2 MPV',
                description:
                    'Shares Posted 2 MPV (Minimum Price Variation) below the NBB'
            },
            {
                term: 'NBO +2 MPV',
                description:
                    'Shares Posted 2 MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: 'NBB -3 MPV',
                description:
                    'Shares Posted 3 MPV (Minimum Price Variation) below the NBB'
            },
            {
                term: 'NBO +3 MPV',
                description:
                    'Shares Posted 3 MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: 'NBB -1/2% MPV',
                description:
                    'Shares posted ½% of MPV (Minimum Price Variation) below the NBO'
            },
            {
                term: 'NBO +1/2% MPV',
                description:
                    'Shares posted ½% of MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: 'NBB -1% MPV',
                description:
                    'Shares posted 1% of MPV (Minimum Price Variation) below the NBB'
            },
            {
                term: 'NBO +1% MPV',
                description:
                    'Shares posted 1% of MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: 'NBB -2% MPV',
                description:
                    'Shares posted 2% of MPV (Minimum Price Variation) below the NBB'
            },
            {
                term: 'NBO +2% MPV',
                description:
                    'Shares Posted 2% of MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: 'NBB -3% MPV',
                description:
                    'Shares posted 3% of MPV (Minimum Price Variation) below the NBB'
            },
            {
                term: 'NBO +3% MPV',
                description:
                    'Shares Posted 3% of MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: 'NBB -5bp',
                description: 'Shares Posted 5 basis points below the NBB'
            },
            {
                term: 'NBO +5bp',
                description: 'Shares Posted 5 basis points above the NBO'
            },
            {
                term: 'NBB -10bp',
                description: 'Shares Posted 10 basis points below the NBB'
            },
            {
                term: 'NBO +10bp',
                description: 'Shares Posted 10 basis points above the NBO'
            },
            {
                term: '% NBB -5bp',
                description:
                    'Percentage of shares posted 5 basis points below the NBB'
            },
            {
                term: '% NBO +5bp',
                description:
                    'Percentage of shares posted 5 basis points above the NBO'
            },
            {
                term: '% NBB -10bp',
                description:
                    'Percentage of shares posted 10 basis points below the NBB'
            },
            {
                term: '% NBO +10bp',
                description:
                    'Percentage of shares posted 2 MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: '1000+ Shares ≤ 1% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 1000 shares posted ≤ 1% below the NBB'
            },
            {
                term: '1000+ Shares ≤ 1% - NBO',
                description:
                    'Shares Posted 2 MPV (Minimum Price Variation) above the NBO'
            },
            {
                term: '2500+ Shares ≤ 1% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 2500 shares posted ≤ 1% below the NBB'
            },
            {
                term: '2500+ Shares ≤ 1% - NBO',
                description:
                    'Time, in nanoseconds, spent with at least 2500 shares posted ≤ 1% above the NBO'
            },
            {
                term: '5000+ Shares ≤ 1% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 5000 shares posted ≤ 1% below the NBB'
            },
            {
                term: '5000+ Shares ≤ 1% - NBO',
                description:
                    'Time, in nanoseconds, spent with at least 5000 shares posted ≤ 1% above the NBO'
            },
            {
                term: '1000+ Shares ≤ 2% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 1000 shares posted ≤ 2% below the NBB'
            },
            {
                term: '1000+ Shares ≤ 2% - NBO',
                description:
                    'Time, in nanoseconds, spent with at least 1000 shares posted ≤ 2% above the NBO'
            },
            {
                term: '2500+ Shares ≤ 2% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 2500 shares posted ≤ 2% below the NBB'
            },
            {
                term: '2500+ Shares ≤ 2% - NBO',
                description:
                    'Time, in nanoseconds, spent with at least 2500 shares posted ≤ 2% above the NBO'
            },
            {
                term: '5000+ Shares ≤ 2% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 5000 shares posted ≤ 2% below the NBB'
            },
            {
                term: '5000+ Shares ≤ 2% - NBO',
                description:
                    'Time, in nanoseconds, spent with at least 5000 shares posted ≤ 2% above the NBO'
            },
            {
                term: '1000+ Shares ≤ 3% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 1000 shares posted ≤ 3% below the NBB'
            },
            {
                term: '1000+ Shares ≤ 3% - NBO',
                description:
                    'Time, in nanoseconds, spent with at least 1000 shares posted ≤ 3% above the NBO'
            },
            {
                term: '2500+ Shares ≤ 3% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 2500 shares posted ≤ 3% below the NBB'
            },
            {
                term: '2500+ Shares ≤ 3% - NBO',
                description:
                    'Time, in nanoseconds, spent with at least 2500 shares posted ≤ 3% above the NBO'
            },
            {
                term: '5000+ Shares ≤ 3% - NBB',
                description:
                    'Time, in nanoseconds, spent with at least 5000 shares posted ≤ 3% below the NBB'
            },
            {
                term: '5000+ Shares ≤ 3% - NBO',
                description:
                    'Time, in nanoseconds, spent with at least 5000 shares posted ≤ 3% above the NBO'
            }
        ]
    },
    liveOrders: {
        title: 'Live Orders',
        description: '',
        terms: [
            {
                term: 'Time',
                description:
                    'Time of lastest activity for the order (initial ack, reprice, execution, etc.)'
            },
            {
                term: 'ClordID',
                description:
                    'Client Order ID assigned to the order by the firm that placed the order'
            },
            {
                term: 'OSI Symbol',
                description:
                    'OSI symbology of the series that the firm placed the order in. For example, "AAPL  210827C00121000" represents the AAPL 121.00 Calls expiring 8-27-2021'
            },
            {
                term: 'OSI Root',
                description:
                    'Option Root Symbol of the series that the firm placed the order in'
            },
            {
                term: 'Underlying Symbol',
                description:
                    'Underlying Symbol of the series that the firm placed the order in'
            },
            {
                term: 'SIDE',
                description: 'Buy, Sell'
            },
            {
                term: 'Price',
                description:
                    'The price specified by the firm that placed the order'
            },
            {
                term: 'Quantity',
                description:
                    'The quantity specified by the firm that placed the order, or the leaves quantity after an execution,  replacement, or modification to the order'
            },
            {
                term: 'MPID',
                description:
                    'Market Particpant Identifier of the firm that placed the order'
            },
            {
                term: 'MMID',
                description:
                    'Market Maker Identifer of the firm that placed the order'
            },
            {
                term: 'SenderCompID',
                description:
                    'Individual FIX or Binary Connection (session identifier) used to place the order'
            },
            {
                term: 'CustomerFirm',
                description:
                    'The clearing range provided by the firm that placed the order (i.e. firm vs. market maker vs. customer)'
            },
            {
                term: 'CMTA',
                description:
                    'The CMTA (OCC Account number) specified by the firm that placed the order'
            },
            {
                term: 'Clearing Number',
                description:
                    'The OCC clearing number associated with the MPID of the firm that placed the order (could be default for the MPID, or an alternative clearing number that the firm is permissioned to use on the exchange)'
            },
            {
                term: 'Open/Close',
                description:
                    'Indicator as to whether or not the firm that placed the order is opening or closing a position'
            },
            {
                term: 'Optional Data',
                description:
                    'Freeform field. Only supports ASCII printable characters, excluding comma, semi-colon, pipe delimiter, "at" symbol (@), greater than/less than, ampersand (&), and single/double quotation mark'
            },
            {
                term: 'Order Type',
                description:
                    'Order Type specified by the firm that placed the order'
            },
            {
                term: 'TIF',
                description:
                    'Time in Force (TIF) specified by the firm that placed the order'
            },
            {
                term: 'Exec Instruction',
                description:
                    'Exec Instructions specified by the firm that placed the order'
            },
            {
                term: 'Strike Price',
                description:
                    'The price at which a put or call option can be exercised'
            },
            {
                term: 'Expiration Date',
                description: 'Date of expiration for the option'
            },
            {
                term: 'Call/Put',
                description: 'Put, Call'
            },
            {
                term: 'Security Type',
                description:
                    'Identifier that distinguishes complex from single legged orders placed by the firm'
            }
        ]
    },
    marketAccessExceptions: {
        title: 'Market Access Exceptions',
        description: '',
        terms: [
            {
                term: 'Daily Total Value',
                description: ''
            },
            {
                term: 'Daily Total Value Percentage',
                description: ''
            },
            {
                term: 'Daily Total Value Threshold',
                description: ''
            },
            {
                term: 'Exception',
                description: 'Exception provided for the given order'
            },
            {
                term: 'Limit Price',
                description: ''
            },
            {
                term: 'Order Quantity',
                description: 'Quantity of the order'
            },
            {
                term: 'Order Quantity Percentage',
                description: ''
            },
            {
                term: 'Order Quantity Threshold',
                description: ''
            },
            {
                term: 'Order Value',
                description: ''
            },
            {
                term: 'Order Value Percentage',
                description: ''
            },
            {
                term: 'Order Value Threshold',
                description: ''
            },
            {
                term: 'Originator',
                description: ''
            },
            {
                term: 'Override Reason',
                description: ''
            },
            {
                term: 'Parent Order',
                description: ''
            },
            {
                term: 'Pop-up Alert',
                description: ''
            },
            {
                term: 'Symbol',
                description: 'Symbol'
            },
            {
                term: 'Time',
                description: 'Timestamp of the exception'
            },
            {
                term: 'Init Person ID',
                description: ''
            }
        ]
    },
    mySessions: {
        title: 'My Sessions',
        description: '',
        terms: [
            {
                term: 'Company Name',
                description: 'Firm Name Associated to CRD#'
            },
            {
                term: 'Connection Status',
                description:
                    'Connected, Primary, Secondary, Disconnected, and None'
            },
            {
                term: 'CRD',
                description:
                    'Central Registration Depository Number for a Broker Dealer'
            },
            {
                term: 'GT Login SEQ',
                description: 'Gateway to Trader current Login Sequence #'
            },
            {
                term: 'GT SEQ',
                description: 'Gateway to Trader current sequence #'
            },
            {
                term: 'GT Stream',
                description:
                    'Gateway to Trader current stream name/ID - individualized stream to which Pillar publishes all outbound Sequenced and Unsequenced messages to a particular firm, including both transactional and Reference Data messages'
            },
            {
                term: 'Last Connection',
                description: 'Last Time the session was connected'
            },
            {
                term: 'N Connect',
                description: '# of connections for the session'
            },
            {
                term: 'Port',
                description:
                    'Identifies a specific process to which other network messages are to be forwarded (end point of customer connection)'
            },
            {
                term: 'PRI CG',
                description: 'Primary GateWay number (e.g. CG123)'
            },
            {
                term: 'Pri Ip',
                description: 'Primary GW Connection IP Address'
            },
            {
                term: 'Ref Login SEQ',
                description: 'Reference Data Login Sequence #'
            },
            {
                term: 'Ref SEQ',
                description: 'Reference Data current Sequence #'
            },
            {
                term: 'Ref Stream',
                description:
                    'Reference Data current stream name/id - individualized stream to which Pillar publishes Symbol, MPV Class, MPV Level, and Session Configuration Acknowledgment reference data.'
            },
            {
                term: 'SEC CG',
                description: 'Secondary GW number (e.g CG123)'
            },
            {
                term: 'SEC Ip',
                description: 'Secondary GW Connection IP Address'
            },
            {
                term: 'Sender Type',
                description:
                    'Specific Sender type assigned to connection (Maps to User Session Type)'
            },
            {
                term: 'Session Status',
                description:
                    'Maps to Status of SenderCompID (Active, Prospect, or Inactive)'
            },
            {
                term: 'Source IP',
                description: 'FIRM level source IP range to Username'
            },
            {
                term: 'Source Port',
                description: 'FIRM level Port to Username'
            },
            {
                term: 'TG Login SEQ',
                description: 'Trader to Gateway Login sequence #'
            },
            {
                term: 'TG SEQ',
                description: 'Trader to Gateway current sequence #'
            },
            {
                term: 'TG Stream',
                description:
                    'Trader to Gateway current stream name/id - individualized stream to which a particular firm routes all their Sequenced and Unsequenced messages destined for the Pillar trading platform.'
            },
            {
                term: 'User Id',
                description:
                    'Integer Values that maps to a designated Port, Primary IP, Secondary IP, DR Primary IP and DR Secondary IP'
            },
            {
                term: 'User Name',
                description:
                    'Individual Connection / Session Identifier "My Session ID" (User Name)'
            },
            {
                term: 'User Type',
                description:
                    'Protocol Type, Binary or FIX, of the session (Maps to Protocol Type)'
            }
        ]
    },
    myFirmMpids: {
        title: 'My Firm MPIDs',
        description: '',
        terms: [
            {
                term: 'Company Name',
                description: 'Firm Name Associated to CRD#'
            },
            {
                term: 'MPID',
                description: 'Market Participant ID Assigned to firm'
            },
            {
                term: 'MPID Status (Active or Inactive)',
                description: 'Market Maker id for MPID'
            },
            {
                term: 'SENDER MPID',
                description: 'Username ID and MPID combined'
            },
            {
                term: 'Status',
                description: 'MPID Status (Active or Inactive)'
            },
            {
                term: 'User Name',
                description:
                    'Individual Connection / Session Identifier "My Session ID" (User Name)'
            }
        ]
    },
    myVolumes: {
        title: 'My Volumes',
        description: '',
        terms: [
            {
                term: 'ADV',
                description:
                    'The Average Daily Volume for the month attributable to a firm and any order types the firm may have submitted.'
            },
            {
                term: 'Broker Rank',
                description:
                    'Rank of the broker’s, by CRD, trade volume versus all other brokers.'
            },
            {
                term: 'CADV',
                description:
                    'The Average Daily Volume for the month reported to the Consolidated Tape Plans by all Exchanges and TRF’s. Trades that are not Last Sale eligible are not included.'
            },
            {
                term: 'CADV (%)',
                description: 'The percentage the Firm ADV is of the CADV.'
            },
            {
                term: 'MTD',
                description:
                    'The cumulative month to date shares attributable to a firm and any order types the firm may have submitted.'
            },
            {
                term: 'T+1',
                description:
                    'The previous trading day volume attributable to a firm and any order types the firm may have submitted.'
            },
            {
                term: 'Trade Count',
                description: 'Count of all trades executed by firm CRD.'
            }
        ]
    },
    opraEntries: {
        title: 'OPRA Entries',
        description: '',
        terms: [
            {
                term: 'CRD',
                description:
                    'Central Registration Depository Number for a Broker Dealer'
            },
            {
                term: 'Call / Put',
                description: 'The option strategy used eg, Call, Put, or Both'
            },
            {
                term: 'Comments',
                description:
                    'Comments entered while creating or editing an entry'
            },
            {
                term: 'Created By',
                description: 'Username who created the entry'
            },
            {
                term: 'Created Time',
                description: 'Date/Time of when the entry was created'
            },
            {
                term: 'Deal Id',
                description: 'Exchange generated Deal ID'
            },
            {
                term: 'Entry Type',
                description: 'The type of OPRA entry, eg. Manual or Sequence'
            },
            {
                term: 'Exchange',
                description: 'Exchange where the option was executed'
            },
            {
                term: 'Expiration',
                description: 'Date of expiration for the option'
            },
            {
                term: 'Hedge Ratio %',
                description: 'The hedged position divided by the total position'
            },
            {
                term: 'Modified By',
                description: 'Username who last modified the entry'
            },
            {
                term: 'Modified Time',
                description: 'Last Date/Time of when the entry was modified'
            },
            {
                term: 'OPRA Id',
                description: 'Unique identifier for an entry'
            },
            {
                term: 'Price Spread',
                description: 'Price spread of the option at time of trade'
            },
            {
                term: 'Print Time',
                description: 'The print time of the option trade'
            },
            {
                term: 'QCT Type',
                description:
                    'The type of QCT trade, eg. ETO, OTO, FLEX, EQTY, FUTR, OTHR'
            },
            {
                term: 'Qty',
                description: 'Quantity of shares traded'
            },
            {
                term: 'SSR Type',
                description:
                    'Short Sale Type, eg. Long Sale, Short Sale, Short Sale Exempt, Unknown'
            },
            {
                term: 'Sequence Number',
                description: 'OPRA Sequence Number'
            },
            {
                term: 'Status',
                description:
                    'The current status of the entry, eg. OPEN, COMPLETED, ACCEPTED, REJECTED_NO_MATCH, REJECTED_MULTIPLE_MATCHES'
            },
            {
                term: 'Strategy',
                description: 'Option strategy used'
            },
            {
                term: 'Strike Price',
                description:
                    'The price at which a put or call option can be exercised'
            },
            {
                term: 'Symbol',
                description: 'Symbol used in trade execution'
            },
            {
                term: 'Trade Execution',
                description:
                    'Date/Time of Trade Execution, eg. YYYY-MM-DD HH:mm:ss.SSSSSSSSS'
            }
        ]
    },
    qctTrades: {
        title: 'QCT Trades',
        description: '',
        terms: [
            {
                term: 'account number',
                description:
                    'Customer provided alphanumeric value, up to 32 characters, used to identify their account.  This field can be used for miscellaneous information by the customer'
            },
            {
                term: 'client order id',
                description: 'Client order id'
            },
            {
                term: 'date',
                description:
                    'Date/Time Execution, eg. YYYY-MM-DD 12:00:00.123456789'
            },
            {
                term: 'deal id',
                description: 'Exchange generated Deal ID'
            },
            {
                term: 'domain',
                description:
                    'Sub-group of MPID/ETPID.  There can be multiple domains per MPID/ETPID but only one MPID/ETPID for domains.'
            },
            {
                term: 'etpid/mpid',
                description:
                    'Market Participant ID Assigned to firm. ETPID will eventually be phased out'
            },
            {
                term: 'liquidity ind.',
                description: 'Liquidity Indicator Codes'
            },
            {
                term: 'price',
                description: 'The price of the security at trade execution'
            },
            {
                term: 'quantity',
                description: 'Quantity of shares traded'
            },
            {
                term: 'sender sub id',
                description:
                    "Binary 'username' field.  Represents the unique identifier of the originating binary order entry session"
            },
            {
                term: 'session id',
                description:
                    "Individual Connection / Session Identifier 'My Session ID' (UserName)"
            },
            {
                term: 'side',
                description: 'Buy, Sell, or Cross'
            },
            {
                term: 'source exchange',
                description: 'The exchange the trade was executed on'
            },
            {
                term: 'symbol',
                description: 'Symbol used in trade execution'
            }
        ]
    },
    quoteMetrics: {
        title: 'Quote Metrics',
        description: '',
        terms: [
            {
                term: 'Exchange Group',
                description: 'Parent company of the exchange.'
            },
            {
                term: 'NBBO Quote Size',
                description: 'Average number of shares quoted at the NBBO.'
            },
            {
                term: 'Spread BPS',
                description: 'Average spread in basis points.'
            },
            {
                term: 'Spread Cents',
                description: 'Average spread in cents.'
            },
            {
                term: 'Time at Inside %',
                description:
                    'Percentage of time spent quoting at the inside market'
            }
        ]
    },
    riskMonitor: {
        title: 'Risk Monitor',
        description: '',
        terms: [
            {
                term: 'MPID',
                description: 'Market participant identifier'
            },
            {
                term: 'Clearing #',
                description: ''
            },
            {
                term: 'Alert Limit',
                description: ''
            },
            {
                term: 'Usage',
                description: ''
            },
            {
                term: 'Net Notional Executed',
                description: ''
            }
        ]
    },
    subAccounts: {
        title: 'Sub Accounts',
        description: '',
        terms: [
            {
                term: 'Created By',
                description: 'User id of whom created the sub account'
            },
            {
                term: 'Created Time',
                description: 'Timestamp of when the sub account was created'
            },
            {
                term: 'Modified By',
                description: 'User id of whom last modified the sub account'
            },
            {
                term: 'Modified Time',
                description:
                    'Timestamp of when the sub account was last modified'
            },
            {
                term: 'MPID',
                description: 'Market participant identifier'
            },
            {
                term: 'Sub Account',
                description: 'Name of the sub account'
            },
            {
                term: 'Trading Account',
                description: 'Name of the trading account'
            }
        ]
    },
    trades: {
        title: 'Trades',
        description: '',
        terms: [
            {
                term: 'account number',
                description:
                    'Customer provided alphanumeric value, up to 32 characters, used to identify their account.  This field can be used for miscellaneous information by the customer'
            },
            {
                term: 'client order id',
                description: 'Client order id'
            },
            {
                term: 'date',
                description:
                    'Date/Time Execution, eg. YYYY-MM-DD 12:00:00.123456789'
            },
            {
                term: 'deal id',
                description: 'Exchange generated Deal ID'
            },
            {
                term: 'domain',
                description:
                    'Sub-group of MPID/ETPID.  There can be multiple domains per MPID/ETPID but only one MPID/ETPID for domains.'
            },
            {
                term: 'etpid/mpid',
                description:
                    'Market Participant ID Assigned to firm. ETPID will eventually be phased out'
            },
            {
                term: 'liquidity ind.',
                description: 'Liquidity Indicator Codes'
            },
            {
                term: 'price',
                description: 'The price of the security at trade execution'
            },
            {
                term: 'quantity',
                description: 'Quantity of shares traded'
            },
            {
                term: 'sender sub id',
                description:
                    "Binary 'username' field.  Represents the unique identifier of the originating binary order entry session"
            },
            {
                term: 'session id',
                description:
                    "Individual Connection / Session Identifier 'My Session ID' (UserName)"
            },
            {
                term: 'side',
                description: 'Buy, Sell, or Cross'
            },
            {
                term: 'symbol',
                description: 'Symbol used in trade execution'
            }
        ]
    },
    tradeSummary: {
        title: (route) =>
            route.params.view.includes('transaction')
                ? 'Trade Summary Transactions'
                : 'Trade Summary',
        description: '',
        terms: (route) => {
            const transactionTerms = [
                {
                    term: 'Group Id',
                    description:
                        'Identifier that ties all allocated and unallocated actions together ' +
                        'for any given user action',
                    example: '12456'
                },
                {
                    term: 'Action',
                    description:
                        'Indicates whether the particular line item for a given transaction had to be ' +
                        'allocated (sent to clearing) or unallocated (reversed/cancelled in clearing)',
                    example: 'ALLOCATE'
                },
                {
                    term: 'User Action',
                    description:
                        'Identifies user action of a given transaction',
                    example: 'ADJUST'
                },
                {
                    term: 'Updated Time',
                    description:
                        'Time that a post trade action was performed on an execution',
                    example: '2021-09-14T14:12:51.628108'
                },
                {
                    term: 'Status',
                    description: 'Status of the request / transaction \n' +
                        '\n' +
                        'Submitted - request initially submitted by initiator\n' +
                        'Pending  Contra Approval - indicates that contra approval is required\n' +
                        'Contra Approved - indicates that contra approval has been received\n' +
                        'Contra Rejected - indicates that the contra has rejected the request\n' +
                        'Recalled - indicates that the initiator has recalled the trade\n' +
                        'Ready to Process - indicates that the request is being prepared by TOP\n' +
                        'System processing - indicates that the request has been processed by TOP\n' +
                        'Completed - indicates that the request has been completed\n' +
                        'Error - indicates that there was an issue with the request'
                },
                {
                    term: 'Updated By',
                    description:
                        'Indicates the system or individual responsible for each state change for a ' +
                        'post trade edit (e.g. a username would be visible for a request to change a CMTA, but ' +
                        'when an action is completed successfully, this field will reflect the system that ' +
                        'completed to update',
                    example: 'CSG'
                },
                {
                    term: 'Reason',
                    description:
                        'User or system specified reason code for a given post trade action',
                    example: 'Non-Contractual Field Change'
                }
            ];
            const terms = [
                ...(route.params.view.includes('transaction')
                    ? transactionTerms
                    : []),
                {
                    term: 'MPID',
                    description: 'Market Participant Identifier',
                    example: 'PILR'
                },
                {
                    term: 'OSI Symbol',
                    description:
                        'OSI symbology of the series. For example "AAPL 210827C00121000" represents the AAPL 121.00 Calls expiring 8-27-2001',
                    example: 'AAPL  220121C00150000'
                },
                {
                    term: 'Original Trade Date',
                    description: 'The date of the original execution',
                    example: '2021-09-15'
                },
                {
                    term: 'Deal Id',
                    description: 'Exchange generated Deal ID',
                    example: '14918173765664835'
                },
                {
                    term: 'Original Deal Id',
                    description:
                        'Original exchange generated Deal ID that will match up with universal trade ID reported to OCC',
                    example: '14918173765664835'
                },
                {
                    term: 'Side',
                    description: 'Buy, Sell',
                    example: 'SELL'
                },
                {
                    term: 'Price',
                    description: 'Execution price',
                    example: '1.90000000'
                },
                {
                    term: 'Qty',
                    description: 'Execution quantity',
                    example: '100'
                },
                {
                    term: 'Liq Ind',
                    description:
                        'LiquidityIndicator code received for the execution',
                    example: 'ASB'
                },
                {
                    term: 'Client Order Id',
                    description: 'Client Order ID assigned by the firm',
                    example: '279615780'
                },
                {
                    term: 'Public Order Id',
                    description: 'Exchange assigned Order ID',
                    example: '14918173766188217'
                },
                {
                    term: 'MMID',
                    description: 'Market Maker Identifier',
                    example: 'TM01'
                },
                {
                    term: 'Session Id',
                    description:
                        'Individual fix or binary connection (sesion indentifier) used to place the order',
                    example: 'NGP-UEAGLE'
                },
                {
                    term: 'Put / Call',
                    description: 'put, call',
                    example: 'Call'
                },
                {
                    term: 'Expiration Date',
                    description: 'Date of expiration for the option',
                    example: '2022-01-21'
                },
                {
                    term: 'Strike Price',
                    description:
                        'The price at which a put or call option can be exercised',
                    example: '150.00000000'
                },
                {
                    term: 'Clearing #',
                    description:
                        'The OCC clearing number associated with the MPID (could be default for the MPID, ' +
                        'or an alternative clearing number that the firm is permissioned to use on the exchange)',
                    example: '9981'
                },
                {
                    term: 'Badge',
                    description: 'Floor Broker Badge'
                },
                {
                    term: 'Account',
                    description:
                        'Actionable Identifier sent to OCC. Freeform field. Only supports ASCII printable ' +
                        'characters, excluding comma, semi-colon, pipe delimiter, "at" symbol (@), greater ' +
                        'than/less than, ampersand (&), and single/double quotation mark'
                },
                {
                    term: 'Customer or Firm',
                    description:
                        'The clearing range provided by the firm (i.e. firm vs. market maker vs. customer)',
                    example: 'Market Maker'
                },
                {
                    term: 'CMTA',
                    description:
                        'The CMTA (OCC Account number) specified by the firm'
                },
                {
                    term: 'Optional Data',
                    description:
                        'Freeform field. Only supports ASCII printable characters, ' +
                        'excluding comma, semi-colon, pipe delimiter, "at" symbol (@), ' +
                        'greater than/less than, ampersand (&), and single/double quotation mark'
                },
                {
                    term: 'Event Time',
                    description:
                        'The time of the original execution or the time of the latest post trade action ' +
                        'performed on the execution (e.g. if a trade happens at 10:05 AM, but is edited at 12:00 PM, ' +
                        'the ref exec time will reflect 10:05 AM but this field will reflect 12:00 PM.'
                },
                {
                    term: 'Ref Exec Time',
                    description: 'The time of the original execution',
                    example: '2021-09-15T12:28:21.889822976'
                },
                {
                    term: 'OSI Root Symbol',
                    description: 'Option Root Symbol of the series',
                    example: 'AAPL'
                },
                {
                    term: 'OCC Seq #',
                    description:
                        'The Sequence number of the execution reported to OCC',
                    example: '5000075'
                },
                {
                    term: 'Event Type',
                    description:
                        'Displays the internal pillar event type that corresponds with the data displayed ' +
                        'to the User. INF = Internal Fill message, AWF = Routed Execution. ALC = allocated trade, ' +
                        'and reflects what is sent to clearing. RALC = reversal or cancel of what was sent to clearing',
                    example: 'ALC'
                },
                {
                    term: 'Underlying Symbol',
                    description: 'Underlying Symbol of the series',
                    example: 'ASB'
                },
                {
                    term: 'Open/Close',
                    description:
                        'Indicator as to whether or not the firm is opening or closing a position',
                    example: 'Open'
                },
                {
                    term: 'Time In Force',
                    description:
                        'Time in Force (TIF) specified by the firm that placed the order',
                    example: 'Day'
                },
                {
                    term: 'Cross Id',
                    description:
                        'Identifier that ties to the buy and sell side of a cross order together'
                },
                {
                    term: 'Cap Strategy',
                    description:
                        'Identifer that represents a Cap Stategy Participant ID'
                }
            ].sort((a, b) => a.term.localeCompare(b.term));
            return terms;
        }
    }
};
