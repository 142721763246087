import {events} from '../events';

export const getTopAuctions = () => ({
    types: [
        events.TOP_AUCTIONS_GET,
        events.TOP_AUCTIONS_SUCCESS,
        events.TOP_AUCTIONS_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: '/api/auction/top/ten',
            isPillarSource: true
        }
    }
});

export const resetTopAuctions = () => ({
    type: events.TOP_AUCTIONS_RESET
});
