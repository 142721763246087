export const events = {
    _INIT_: '@@INIT',
    _SYNC_ROUTE_: '@@sync/ROUTE',
    _SYNC_FLUSH_: '@@sync/FLUSH',
    _SYNC_RECEIVE_: '@@sync/RECEIVE',

    AUCTIONS_GET: '@@auctions/GET',
    AUCTIONS_SUCCESS: '@@auctions/SUCCESS',
    AUCTIONS_ERROR: '@@auctions/ERROR',

    ACTIVITY_REPORT_GET: '@@activityReport/GET',
    ACTIVITY_REPORT_SUCCESS: '@@activityReport/SUCCESS',
    ACTIVITY_REPORT_ERROR: '@@activityReport/ERROR',

    COLUMN_DEFS_SAVE: '@@columnDefs/SAVE',

    CORE_SESSION_GET: '@@coreSession/GET',
    CORE_SESSION_SUCCESS: '@@coreSession/SET',
    CORE_SESSION_ERROR: '@@coreSession/ERROR',
    CORE_SESSION_RESET: '@@coreSession/RESET',

    DOWNLOADS_GET: '@@downloads/GET',
    DOWNLOADS_SUCCESS: '@@downloads/SUCCESS',
    DOWNLOADS_ERROR: '@@downloads/ERROR',
    DOWNLOADS_PROGRESS: '@@downloads/PROGRESS',

    ENTITLEMENT_SET: '@@user/Entitlement/SET',

    GRID_STATE_RESET: '@@gridState/RESET',
    GRID_STATE_RESET_FILTERS: '@@gridState/RESET/FILTERS',
    GRID_STATE_SET: '@@gridState/SET',
    GRID_STATE_SET_FILTERS: '@@gridState/SET/FILTERS',

    LIQUIDITY_METRICS_GET: '@@liquidityMetrics/GET',
    LIQUIDITY_METRICS_SUCCESS: '@@liquidityMetrics/SUCCESS',
    LIQUIDITY_METRICS_ERROR: '@@liquidityMetrics/ERROR',

    MARKET_ACCESS_EXCEPTIONS_GET: '@@marketAccessExceptions/GET',
    MARKET_ACCESS_EXCEPTIONS_SUCCESS: '@@marketAccessExceptions/SUCCESS',
    MARKET_ACCESS_EXCEPTIONS_ERROR: '@@marketAccessExceptions/ERROR',

    MM_TYPES_GET: '@@mmTypes/GET',
    MM_TYPES_SUCCESS: '@@mmTypes/SUCCESS',
    MM_TYPES_ERROR: '@@mmTypes/ERROR',

    MY_FIRM_MPIDS_GET: '@@myFirmMpids/GET',
    MY_FIRM_MPIDS_SUCCESS: '@@myFirmMpids/SUCCESS',
    MY_FIRM_MPIDS_ERROR: '@@myFirmMpids/ERROR',

    MY_SESSIONS_GET: '@@mySessions/GET',
    MY_SESSIONS_SUCCESS: '@@mySessions/SUCCESS',
    MY_SESSIONS_ERROR: '@@mySessions/ERROR',

    MY_VOLUMES_SEARCH_GET: '@@myVolumesSearch/GET',
    MY_VOLUMES_SEARCH_SUCCESS: '@@myVolumesSearch/SUCCESS',
    MY_VOLUMES_SEARCH_ERROR: '@@myVolumesSearch/ERROR',

    MY_VOLUMES_BROKER_RANK_GET: '@@myVolumesBrokerRank/GET',
    MY_VOLUMES_BROKER_RANK_SUCCESS: '@@myVolumesBrokerRank/SUCCESS',
    MY_VOLUMES_BROKER_RANK_ERROR: '@@myVolumesBrokerRank/ERROR',

    MY_VOLUMES_CADV_GET: '@@myVolumesCadv/GET',
    MY_VOLUMES_CADV_SUCCESS: '@@myVolumesCadv/SUCCESS',
    MY_VOLUMES_CADV_ERROR: '@@myVolumesCadv/ERROR',

    MY_VOLUMES_FILTERS_GET: '@@myVolumesFilters/GET',
    MY_VOLUMES_FILTERS_SUCCESS: '@@myVolumesFilters/SUCCESS',
    MY_VOLUMES_FILTERS_ERROR: '@@myVolumesFilters/ERROR',

    MY_VOLUMES_TRADE_COUNT_GET: '@@myVolumesTradeCounts/GET',
    MY_VOLUMES_TRADE_COUNT_SUCCESS: '@@myVolumesTradeCounts/SUCCESS',
    MY_VOLUMES_TRADE_COUNT_ERROR: '@@myVolumesTradeCounts/ERROR',

    OPRA_ENTRIES_GET: '@@opraEntries/GET',
    OPRA_ENTRIES_SUCCESS: '@@opraEntries/SUCCESS',
    OPRA_ENTRIES_ERROR: '@@opraEntries/ERROR',

    OPRA_ENTRY_CREATE_POST: '@@opraEntryCreate/POST',
    OPRA_ENTRY_CREATE_SUCCESS: '@@opraEntryCreate/SUCCESS',
    OPRA_ENTRY_CREATE_ERROR: '@@opraEntryCreate/ERROR',

    OPRA_ENTRY_REMOVE_DELETE: '@@opraEntryRemove/DELETE',
    OPRA_ENTRY_REMOVE_SUCCESS: '@@opraEntryRemove/SUCCESS',
    OPRA_ENTRY_REMOVE_ERROR: '@@opraEntryRemove/ERROR',

    OPRA_ENTRY_UPDATE_PUT: '@@opraEntryUpdate/PUT',
    OPRA_ENTRY_UPDATE_SUCCESS: '@@opraEntryUpdate/SUCCESS',
    OPRA_ENTRY_UPDATE_ERROR: '@@opraEntryUpdate/ERROR',

    PILLAR_STATUS_GET: '@@pillarStatus/GET',
    PILLAR_STATUS_SUCCESS: '@@pillarStatus/SUCCESS',
    PILLAR_STATUS_ERROR: '@@pillarStatus/ERROR',

    RISK_MONITOR_GET: '@@riskMonitor/GET',
    RISK_MONITOR_SUCCESS: '@@riskMonitor/SUCCESS',
    RISK_MONITOR_ERROR: '@@riskMonitor/ERROR',

    RISK_MONITOR_BULK_ACTION_POST: '@@riskMonitor/bulkAction/POST',
    RISK_MONITOR_BULK_ACTION_SUCCESS: '@@riskMonitor/bulkAction/SUCCESS',
    RISK_MONITOR_BULK_ACTION_ERROR: '@@riskMonitor/bulkAction/ERROR',
    RISK_MONITOR_BULK_ACTION_RESET_RESULTS:
        '@@riskMonitor/bulkAction/RESET/RESULTS',

    RISK_MONITOR_ALERT_LIMIT_POST: '@@riskMonitor/alertLimit/POST',
    RISK_MONITOR_ALERT_LIMIT_RESET: '@@riskMonitor/alertLimit/RESET',
    RISK_MONITOR_ALERT_LIMIT_SUCCESS: '@@riskMonitor/alertLimit/SUCCESS',
    RISK_MONITOR_ALERT_LIMIT_ERROR: '@@riskMonitor/alertLimit/ERROR',

    SYMBOL_GROUPS_GET: '@@symbolGroups/GET',
    SYMBOL_GROUPS_SUCCESS: '@@symbolGroups/SUCCESS',
    SYMBOL_GROUPS_ERROR: '@@symbolGroups/ERROR',

    QUOTE_METRICS_GET: '@@quoteMetrics/GET',
    QUOTE_METRICS_SUCCESS: '@@quoteMetrics/SUCCESS',
    QUOTE_METRICS_ERROR: '@@quoteMetrics/ERROR',

    TERMS_AND_CONDITIONS_SET: '@@termsAndConditions/SET',

    TRADES_GET: '@@trades/GET',
    TRADES_SUCCESS: '@@trades/SUCCESS',
    TRADES_ERROR: '@@trades/ERROR',

    TRADE_SUMMARY_GET: '@@tradeSummary/GET',
    TRADE_SUMMARY_SUCCESS: '@@tradeSummary/SUCCESS',
    TRADE_SUMMARY_ERROR: '@@tradeSummary/ERROR',

    TOP_AUCTIONS_GET: '@@topAuctions/GET',
    TOP_AUCTIONS_SUCCESS: '@@topAuctions/SUCCESS',
    TOP_AUCTIONS_ERROR: '@@topAuctions/ERROR',
    TOP_AUCTIONS_RESET: '@@topAuctions/RESET',

    USER_GET: '@@user/GET',
    USER_SUCCESS: '@@user/SUCCESS',
    USER_ERROR: '@@user/ERROR',

    USER_LOGOUT_POST: '@@user/logout/POST',
    USER_LOGOUT_SUCCESS: '@@user/logout/SUCCESS',
    USER_LOGOUT_ERROR: '@@user/logout/ERROR',

    USER_REFRESH_TOKEN_GET: '@@@user/refreshToken/GET',
    USER_REFRESH_TOKEN_SUCCESS: '@@@user/refreshToken/SUCCESS',
    USER_REFRESH_TOKEN_ERROR: '@@user/refreshToken/ERROR',

    USER_FIRM_ATTRIBUTES_GET: '@@user/firmAttributes/GET',
    USER_FIRM_ATTRIBUTES_SUCCESS: '@@user/firmAttributes/SUCCESS',
    USER_FIRM_ATTRIBUTES_ERROR: '@@user/firmAttributes/ERROR',

    USER_SET: '@@user/SET',
    USER_UPDATE: '@@user/UPDATE'
};
