import {DateTimeFormatter, LocalDate, LocalDateTime, LocalTime} from '@js-joda/core';
import {useSnackbar} from 'notistack';
import {Badge} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {debounce} from 'lodash-es';
import {get, isEqual} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridPagination,
    AgGridProvider,
    AgGridToggleShowFilters,
    NyButton,
    NyDateTimeInput,
    NyDateTimeRangeInput,
    NyNumberInput,
    NySelectInput,
    NyTextInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import qs from 'qs';
import React, {useEffect} from 'react';

import {Access} from '../components/displays/access';
import {DownloadProgress} from '../components/displays/download.progress';
import {MultiApprovalModal} from '../components/modals/tradeSummary/multiApproval.modal';
import {MultiRecallModal} from '../components/modals/tradeSummary/multiRecall.modal';
import {MultiTradeActionModal} from '../components/modals/tradeSummary/multiTradeAction.modal';
import {NavigationSectionMenu} from '../components/navigation/navigation.section.menu';
import {BadgeIdSelect} from '../components/selects/badgeId.select';
import {ClearingNumberFilterSelect} from '../components/selects/clearingNumberFilter.select';
import {EntitlementSelect} from '../components/selects/entitlement.select';
import {FirmSelect} from '../components/selects/firm.select';
import {FirmParticipantSelect} from '../components/selects/firmParticipant.select';
import {LiqIndicatorSelect} from '../components/selects/liquidityIndicator.select';
import {MmIdSelect} from '../components/selects/mmid.select';
import environment from '../configs/environment.config';
import {
    Columndefs,
    custFirmChoices,
    fieldsToOmitFromUrlQueryParams,
    NotificationMessage,
    openCloseChoices,
    Permissions,
    Views
} from '../resources/tradeSummary.resource';
import {
    getTradeSummary,
    getTradeSummaryExport,
    getTradeSummaryTransactionExport,
    getTradeSummaryTransactionHistoryExport
} from '../stores/tradeSummary/tradeSummary.actions';
import {OptnlDataWildcardInfoTooltip} from '../components/tooltips/OptnlDataWildcardInfoTooltip';

function parseDateTimeString(dateTime) {
    try {
        const dateTimeParts = dateTime.split('T');
        const dateString = dateTimeParts[0];
        const timeString = dateTimeParts.length > 1 ? dateTimeParts[1] : '00:00:00.000';

        return LocalDateTime.of(
            LocalDate.parse(dateString, DateTimeFormatter.ISO_LOCAL_DATE),
            LocalTime.parse(timeString.replace(/Z/, ''))
        );
    } catch (e) {
        return undefined;
    }
}

function HintSnackbar({content}) {
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        const message = content?.message;
        const timeStamp = content?.timeStamp;
        message && timeStamp && enqueueSnackbar(
            (
                <span style={{
                    maxWidth: '400px',
                    display: 'flex',
                    alignItems: 'center',
                    wordBreak: 'break-word',
                    wordWrap: 'break-word'
                }}>
                    <InfoIcon style={{marginInlineEnd: '8px'}}/>
                    <span>
                        {message}
                    </span>
                </span>
            ),
            {
                variant: 'info',
                style: {
                    top: -50
                }
            }
        );
    }, [content]);
    return <span hidden data-e2e-tag='hintMessageBox'>{content?.message}</span>;
}

@connect(
    [
        'columnDefs',
        'downloads',
        'firms',
        'permissions',
        'platform',
        'route',
        'tradeSummary',
        'user'
    ],
    {
        getTradeSummary,
        getTradeSummaryExport,
        getTradeSummaryTransactionExport,
        getTradeSummaryTransactionHistoryExport
    }
)
export class TradeSummaryPage extends React.Component {
    state = {
        exportFileName: this.props.route.title,
        filterValues: {},
        selected: [],
        initialized: false,
        showMultiEdit: false,
        showMultiRecall: false,
        showMultiApprove: false,
        showMultiReject: false,
        hintSnackbarMessage: {},
        views: [
            {
                permissions: [Permissions.viewAllocatedTradeSummary],
                title: 'Allocated',
                url: '/:platform/trade-summary/:view',
                params: {
                    view: Views.allocated
                },
                omit: fieldsToOmitFromUrlQueryParams
            },
            {
                permissions: [Permissions.viewFloorTradeSummary],
                title: 'Floor Trades',
                url: '/:platform/trade-summary/:view',
                hideWhenDisabled: true,
                params: {
                    view: Views.floorTrades
                },
                omit: fieldsToOmitFromUrlQueryParams
            },
            {
                permissions: [Permissions.viewTransactions],
                title: 'Transactions',
                url: '/:platform/trade-summary/:view',
                params: {
                    view: Views.transactions
                },
                omit: fieldsToOmitFromUrlQueryParams
            },
            {
                permissions: [Permissions.viewTransactionHistory],
                title: 'Transaction History',
                url: '/:platform/trade-summary/:view',
                params: {
                    view: Views.transactionHistory
                },
                omit: fieldsToOmitFromUrlQueryParams
            }
        ]
    };

    _today = LocalDateTime.now();
    _minOrigTdate = this._today.minusMonths(6);
    _minExpirationDate = this._today.minusMonths(6);
    _maxExpirationDate = this._today.plusYears(5);

    _isAllocatedTradesPage = [Views.allocated].includes(
        this.props.route.params.view
    );
    _isFloorTradesPage = [Views.floorTrades].includes(
        this.props.route.params.view
    );
    _isTransactionsPage = [Views.transactions].includes(
        this.props.route.params.view
    );
    _isTransactionsHistoryPage = [Views.transactionHistory].includes(
        this.props.route.params.view
    );
    _isTransactionsOrTransactionsHistoryPage =
        this._isTransactionsPage || this._isTransactionsHistoryPage;

    _initialFilters = {
        allocatedTradeSummary: [
            Views.allocated,
            Views.floorTrades,
            Views.transactions,
            Views.transactionHistory
        ].includes(this.props.route.params.view),
        crd: this.props.route.params.crd || (this.props.firms[0] || {}).crd,
        custFirm: this.props.route.params.custFirm,
        custLqdtyInd: this.props.route.params.custLqdtyInd,
        custOpenClose: this.props.route.params.custOpenClose,
        dealIdOrig: this.props.route.params.dealIdOrig,
        custSide: this.props.route.params.custSide,
        mpid: this.props.route.params.mpid,
        matchFor: this._isFloorTradesPage ? 'all' : null,
        origTdate: this._isTransactionsPage
            ? null
            : this._isTransactionsHistoryPage
                ? parseDateTimeString(this.props.route.params.origTdate)
                : this._today,
        putCall: this.props.route.params.putCall,
        status: this.props.route.params.status,
        updatedDate: null,
        view: this.props.route.params.view
    };

    _initialPagination = {
        limit: 500,
        offset: 0
    };

    _onFiltersChanged = (filterValues) => {
        this.setState({filterValues});
    };

    _onFirmSelect = ({setValue}) => {
        setValue('participantId', []);
    };

    _onLoad = (filterValues) => {
        let hasHintError = false;
        this._agGrid.forEachNode((e) => {
            const hintErrorMessage = get(e, 'data.hintErrorMessage', '');
            if (hintErrorMessage && Array.isArray(hintErrorMessage) && hintErrorMessage.length !== 0) {
                hasHintError = true;
            }
        });
        if (hasHintError) {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], false);
        }

        this.props.getTradeSummary({
            ...filterValues
        });
        this.setState({initialized: true});

        const paramsCrd = get(this.props.route, 'params.crd', '');
        const filterCrd = get(filterValues, 'crd', '');
        if (paramsCrd && paramsCrd !== filterCrd) {
            const url = get(this.props.route, 'url', '');
            const params = get(this.props.route, 'params', {});
            window.location.hash = `#${url}?${qs.stringify({...params, crd: filterCrd})}`;
        }
    };

    _debounceOnLoad = debounce(this._onLoad, 2000);

    _onModalClosed = () => {
        this._agGrid.deselectAll();
        this.props.getTradeSummary({view: Views.transactions, ...this.state.filterValues});
    };

    componentDidUpdate(prevProps, prevState) {
        if (this._isTransactionsPage) {
            const hasCrdChanged =
                prevState.filterValues.crd !== this.state.filterValues.crd;

            if (hasCrdChanged && !this.state.initialized) {
                this._debounceOnLoad({
                    ...this.state.filterValues,
                    crd: this.state.filterValues.crd
                });
            }
        }
        if (this._agGrid !== undefined && this._gridRowIndex !== undefined) {
            if (this._agGrid.getRowNode(this._gridRowIndex).isSelected())
                this._agGrid.getRowNode(this._gridRowIndex).setSelected(false);
            else
                this._agGrid.getRowNode(this._gridRowIndex).setSelected(true);
            this._gridRowIndex = undefined;
        }
    }

    _InvokeExportAllForCurrentTab = (data) => {
        if (this._isTransactionsHistoryPage) {
            this._onExportAllTransactionHist(data);
        } else if (this._isTransactionsPage) {
            this._onExportAllTransaction(data);
        } else {
            this._onExportAll(data);
        }
    };

    _onExportAll = (data) => {
        this.setState(
            {
                exportFileName: data.fileName
            },
            () => {
                this.props.getTradeSummaryExport({
                    fileName: this.state.exportFileName,
                    fileExtension: 'xlsx',
                    data
                });
            }
        );
    };

    _onExportAllTransaction = (data) => {
        this.setState(
            {
                exportFileName: data.fileName
            },
            () => {
                this.props.getTradeSummaryTransactionExport({
                    fileName: this.state.exportFileName,
                    fileExtension: 'xlsx',
                    data
                });
            }
        );
    };

    _onExportAllTransactionHist = (data) => {
        this.setState(
            {
                exportFileName: data.fileName
            },
            () => {
                this.props.getTradeSummaryTransactionHistoryExport({
                    fileName: this.state.exportFileName,
                    fileExtension: 'xlsx',
                    data
                });
            }
        );
    };

    _onSelectionChanged = (selected) => {
        this.setState({
            selected: selected
        });
    };

    _onMultiEditClick = () => {
        const maxRow = this._today.toLocalDate().equals(this.state.filterValues?.origTdate?.toLocalDate()) ? 50 : 5;
        const isEqualTo1 = this.state.selected?.length === 1;
        const isMoreThanMax = this.state.selected?.length > maxRow;
        let isAllowedTradeActionsValid = true;
        let isEvtTypeValid = true;

        const map = new Map<string, Set>();
        this.state.selected.forEach((data) => {
            const allowedTradeActions = get(data, 'allowedTradeActions', []);
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            if (!map.has(uniqueKey)) {
                map.set(uniqueKey, new Set<string>([]));
            }
            if (allowedTradeActions.length === 0) {
                isAllowedTradeActionsValid = false;
                map.get(uniqueKey).add(NotificationMessage.HINT_ACTION_ON_MORE_RECORDS);
            }
            if (evtTyp === 'RALC') {
                isEvtTypeValid = false;
                map.get(uniqueKey).add(NotificationMessage.HINT_ACTION_ON_REVERSED_TRADES);
            }
        });

        this._agGrid.forEachNode((e) => {
            const data = get(e, 'data', {});
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            e.setData({
                ...data,
                hintErrorMessage: e.selected && map.has(uniqueKey)
                    && map.get(uniqueKey).size !== 0 ? Array.from(map.get(uniqueKey)) : null
            });
            e.setSelected(e.selected, false);
        });
        this._agGrid.refreshCells({force: true});

        if (!isAllowedTradeActionsValid || !isEvtTypeValid) {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], true);
            this._agGrid.gridPanel.columnApi.setColumnWidth('hintError', 40);
        } else {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], false);
        }

        let message = '';
        if (isEqualTo1) {
            message = NotificationMessage.EQUAL_TO_1;
        } else if (isMoreThanMax) {
            message = maxRow === 5 ? NotificationMessage.MORE_THAN_MAX_5 : NotificationMessage.MORE_THAN_MAX_50;
        } else if (!isEvtTypeValid) {
            message = NotificationMessage.ACTION_ON_REVERSED_TRADES;
        } else if (!isAllowedTradeActionsValid) {
            message = NotificationMessage.ACTION_ON_MORE_RECORDS;
        }

        if (message === '') {
            this.setState({
                showMultiEdit: !this.state.showMultiEdit
            });
        }
        this._setHintSnackbar(message);
    };

    _onMultiEditClose = () => {
        this.setState({
            showMultiEdit: false
        });
    };

    _onMultiRecallClick = () => {
        const isEqualTo1 = this.state.selected?.length === 1;
        const isMoreThanMax = this.state.selected?.length > 20;
        let isSelectedValid = true;

        const map = new Map<string, Set>();
        this.state.selected.forEach((data) => {
            const allowedTradeActions = get(data, 'allowedTradeActions', []);
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const status = get(data, 'status', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            if (!map.has(uniqueKey)) {
                map.set(uniqueKey, new Set<string>([]));
            }
            if (allowedTradeActions.length === 0 || !allowedTradeActions.includes('RECALL')
                || status !== 'CO_APPROVAL_PENDING') {
                isSelectedValid = false;
                map.get(uniqueKey).add(NotificationMessage.HINT_ACTION_ON_MORE_RECORDS);
            }
        });

        this._agGrid.forEachNode((e) => {
            const data = get(e, 'data', {});
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            e.setData({
                ...data,
                hintErrorMessage: e.selected && map.has(uniqueKey)
                    && map.get(uniqueKey).size !== 0 ? Array.from(map.get(uniqueKey)) : null
            });
            e.setSelected(e.selected, false);
        });
        this._agGrid.refreshCells({force: true});

        if (!isSelectedValid) {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], true);
            this._agGrid.gridPanel.columnApi.setColumnWidth('hintError', 40);
        } else {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], false);
        }

        let message = '';
        if (isEqualTo1) {
            message = NotificationMessage.EQUAL_TO_1;
        } else if (isMoreThanMax) {
            message = NotificationMessage.MORE_THAN_MAX_20;
        } else if (!isSelectedValid) {
            message = NotificationMessage.ACTION_ON_MORE_RECORDS;
        }

        if (message === '') {
            this.setState({
                showMultiRecall: !this.state.showMultiRecall
            });
        }
        this._setHintSnackbar(message);
    };

    _onMultiApproveClick = () => {
        const isEqualTo1 = this.state.selected?.length === 1;
        const isMoreThanMax = this.state.selected?.length > 20;
        const isCustFirmIdsUnique = new Set(this.state.selected.map((data) => data.custFirmId)).size === 1;
        let isSelectedValid = true;
        let isLocalMMTrade = true;

        const map = new Map<string, Set>();
        this.state.selected.forEach((data) => {
            const allowedTradeActions = get(data, 'allowedTradeActions', []);
            const custTradeType = get(data, 'custTradeType', '');
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const status = get(data, 'status', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            if (!map.has(uniqueKey)) {
                map.set(uniqueKey, new Set<string>([]));
            }
            if (allowedTradeActions.length === 0 || !allowedTradeActions.includes('CONTRA_APPROVE')
                || status !== 'CO_APPROVAL_PENDING') {
                isSelectedValid = false;
                map.get(uniqueKey).add(NotificationMessage.HINT_ACTION_ON_MORE_RECORDS);
            }
            if (custTradeType !== 'LOCAL_MM') {
                isLocalMMTrade = false;
                map.get(uniqueKey).add(NotificationMessage.HINT_ACTION_LOCAL_MM);
            }
        });

        this._agGrid.forEachNode((e) => {
            const data = get(e, 'data', {});
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            e.setData({
                ...data,
                hintErrorMessage: e.selected && map.has(uniqueKey)
                    && map.get(uniqueKey).size !== 0 ? Array.from(map.get(uniqueKey)) : null
            });
            e.setSelected(e.selected, false);
        });
        this._agGrid.refreshCells({force: true});

        if (!isSelectedValid || !isLocalMMTrade) {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], true);
            this._agGrid.gridPanel.columnApi.setColumnWidth('hintError', 40);
        } else {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], false);
        }

        let message = '';
        if (isEqualTo1) {
            message = NotificationMessage.EQUAL_TO_1;
        } else if (isMoreThanMax) {
            message = NotificationMessage.MORE_THAN_MAX_20;
        } else if (!isSelectedValid) {
            message = NotificationMessage.ACTION_ON_MORE_RECORDS;
        } else if (!isCustFirmIdsUnique) {
            message = NotificationMessage.ACTION_LOCAL_MM_WITH_DIFF_MPID;
        } else if (!isLocalMMTrade) {
            message = NotificationMessage.ACTION_LOCAL_MM;
        }

        if (message === '') {
            this.setState({
                showMultiApprove: !this.state.showMultiApprove
            });
        }
        this._setHintSnackbar(message);
    };

    _onMultiRejectClick = () => {
        const isEqualTo1 = this.state.selected?.length === 1;
        const isMoreThanMax = this.state.selected?.length > 20;
        const isCustFirmIdsUnique = new Set(this.state.selected.map((data) => data.custFirmId)).size === 1;
        let isSelectedValid = true;
        let isLocalMMTrade = true;

        const map = new Map<string, Set>();
        this.state.selected.forEach((data) => {
            const allowedTradeActions = get(data, 'allowedTradeActions', []);
            const custTradeType = get(data, 'custTradeType', '');
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const status = get(data, 'status', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            if (!map.has(uniqueKey)) {
                map.set(uniqueKey, new Set<string>([]));
            }
            if (allowedTradeActions.length === 0 || !allowedTradeActions.includes('CONTRA_APPROVE')
                || status !== 'CO_APPROVAL_PENDING') {
                isSelectedValid = false;
                map.get(uniqueKey).add(NotificationMessage.HINT_ACTION_ON_MORE_RECORDS);
            }
            if (custTradeType !== 'LOCAL_MM') {
                isLocalMMTrade = false;
                map.get(uniqueKey).add(NotificationMessage.HINT_ACTION_LOCAL_MM);
            }
        });

        this._agGrid.forEachNode((e) => {
            const data = get(e, 'data', {});
            const dealNum = get(data, 'dealNum', '');
            const evtTyp = get(data, 'evtTyp', '');
            const origTdate = get(data, 'origTdate', '');
            const uniqueKey = [origTdate, evtTyp, dealNum].join('_');
            e.setData({
                ...data,
                hintErrorMessage: e.selected && map.has(uniqueKey)
                    && map.get(uniqueKey).size !== 0 ? Array.from(map.get(uniqueKey)) : null
            });
            e.setSelected(e.selected, false);
        });
        this._agGrid.refreshCells({force: true});

        if (!isSelectedValid || !isLocalMMTrade) {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], true);
            this._agGrid.gridPanel.columnApi.setColumnWidth('hintError', 40);
        } else {
            this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], false);
        }

        let message = '';
        if (isEqualTo1) {
            message = NotificationMessage.EQUAL_TO_1;
        } else if (isMoreThanMax) {
            message = NotificationMessage.MORE_THAN_MAX_20;
        } else if (!isSelectedValid) {
            message = NotificationMessage.ACTION_ON_MORE_RECORDS;
        } else if (!isCustFirmIdsUnique) {
            message = NotificationMessage.ACTION_LOCAL_MM_WITH_DIFF_MPID;
        } else if (!isLocalMMTrade) {
            message = NotificationMessage.ACTION_LOCAL_MM;
        }

        if (message === '') {
            this.setState({
                showMultiReject: !this.state.showMultiReject
            });
        }
        this._setHintSnackbar(message);
    };

    _onMultiReCallClose = () => {
        this.setState({
            showMultiRecall: false
        });
    };

    _onMultiApproveClose = () => {
        this.setState({
            showMultiApprove: false
        });
    };

    _onMultiRejectClose = () => {
        this.setState({
            showMultiReject: false
        });
    };

    _agGrid = undefined;
    _gridRowIndex = undefined;

    _onGridReady = (grid) => {
        this._agGrid = grid;
        this._gridRowIndex = undefined;

        // force agGrid to set pinned column at the beginning with order
        // pinned column, not at the beginning or not in order, breaks agGrid column swap function
        if (environment.integrationEnv) {
            return;
        }
        if (!this._isAllocatedTradesPage && !this._isTransactionsPage) {
            return;
        }
        this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], false);
        const pinnedColumnObj = {
            localStorageKey: this._isAllocatedTradesPage ? 'columns.tradeSummaryAllocated'
                : 'columns.tradeSummaryTransaction',
            columnArray: this._isAllocatedTradesPage ? ['hintError', '_selected_', '_details_']
                : ['hintError', '_selected_', '_details_', 'recallApprove'],
            length: this._isAllocatedTradesPage ? 3 : 4
        };
        const value = window?.localStorage?.getItem(pinnedColumnObj.localStorageKey);
        if (value === null) {
            return;
        }
        const obj = value ? JSON.parse(value) : {};
        const columnGroupState = get(obj, 'columnGroupState', []);
        const columnState = get(obj, 'columnState', []);
        if ((columnState.length === 0 && columnGroupState.length === 0)
            || isEqual(columnState.slice(0, pinnedColumnObj.length)
                .map((e) => get(e, 'colId', '')), pinnedColumnObj.columnArray)) {
            return;
        }
        let columnArrayIdx = 0;
        const pinnedColumnArray = [];
        const unPinnedColumnArray = [];
        columnState.forEach((column) => {
            const colId = get(column, 'colId', '');
            if (columnArrayIdx < pinnedColumnObj.length && colId === pinnedColumnObj.columnArray[columnArrayIdx]) {
                pinnedColumnArray.push(column);
                columnArrayIdx += 1;
            } else {
                unPinnedColumnArray.push(column);
            }
        });
        this._agGrid.gridPanel.columnApi.setColumnState([...pinnedColumnArray, ...unPinnedColumnArray]);
        this._agGrid.gridPanel.columnApi.setColumnGroupState(columnGroupState);
        this._agGrid.gridPanel.columnApi.setColumnsVisible(['hintError'], false);
    };

    agGridOverride = (this._isAllocatedTradesPage || this._isTransactionsPage
        ? {
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: false,
            suppressCopyRowsToClipboard: true,
            onCellClicked: (e) => {
                if (e.column.colId === '_details_') {
                    this._gridRowIndex = e.rowIndex;
                }
            }
        }
        : {
            rowSelection: 'single',
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: false,
            suppressCopyRowsToClipboard: true
        });

    _setHintSnackbar = (message) => {
        this.setState({
            hintSnackbarMessage: {
                message,
                timeStamp: LocalDateTime.now().toString()
            }
        });
    };

    render() {
        const download = get(this.props.downloads, this.state.exportFileName, {
            error: false,
            isDownloading: false,
            progress: 0
        });
        const isSelected = this.state.selected?.length > 0;

        return (
            <>
                <div className='row'>
                    <div className='col-sm-12 col-xs-12 col-md-12 col-lg-12'>
                        {/* todo:: add logic to hide this if platform !== xchi || views length is 1 */}
                        <NavigationSectionMenu
                            queryParams={this.state.filterValues}
                            views={this.state.views}
                        />
                    </div>
                </div>
                <AgGridProvider
                    columnNamespace={Columndefs[this.props.route.params.view]}
                    columnDefs={
                        this.props.columnDefs[
                            Columndefs[this.props.route.params.view]
                        ]
                    }
                    filtersClassName='row space-bottom-1'
                    initialPagination={this._initialPagination}
                    initialFilters={this._initialFilters}
                    onFiltersChanged={this._onFiltersChanged}
                    onGridReady={this._onGridReady}
                    onSelectionChanged={this._onSelectionChanged}
                    onLoad={this._onLoad}
                    hasServerSort
                    permissions={this.props.permissions}
                    permissionCheckType='some'
                    submitOnLoad={false}
                    submitOnNamespaceChanged
                    {...this.props.tradeSummary}>
                    <AgGridProvider.Filters>
                        <EntitlementSelect
                            className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'
                            name='entitlement'
                        />
                        {this.props.firms.length > 1 && (
                            <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                                <label>Firm</label>
                                <FirmSelect
                                    initialValue={this.state.filterValues.crd || this.props.route.params.crd}
                                    name='crd'
                                    onChange={this._onFirmSelect}
                                    required
                                    showInactive
                                />
                            </div>
                        )}
                        {this._isTransactionsOrTransactionsHistoryPage && (
                            <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                                <label>Status</label>
                                <NySelectInput name='status'>
                                    <option value=''>-</option>
                                    <option value='SUBMITTED'>Submitted</option>
                                    <option value='CO_APPROVAL_PENDING'>
                                        Pending Contra Approval
                                    </option>
                                    <option value='CO_APPROVED'>
                                        Contra Approved
                                    </option>
                                    <option value='CO_REJECTED'>
                                        Contra Rejected
                                    </option>
                                    <option value='RECALLED'>Recalled</option>
                                    <option value='READY_TO_PROCESS'>
                                        Ready to Process
                                    </option>
                                    <option value='PROCESSING'>
                                        System processing
                                    </option>
                                    <option value='COMPLETED'>Completed</option>
                                    <option value='ERROR'>Error</option>
                                </NySelectInput>
                            </div>
                        )}
                        {this._isTransactionsPage && (
                            <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                                <label>Updated Time</label>
                                <NyDateTimeRangeInput
                                    className='form-control'
                                    format='HH:mm:ss.SSSSSSSSS'
                                    required={
                                        this.state.filterValues
                                            .startUpdatedTs ||
                                        this.state.filterValues.endUpdatedTs
                                    }
                                    startName='startUpdatedTs'
                                    endName='endUpdatedTs'
                                />
                            </div>
                        )}
                        {this._isTransactionsHistoryPage && (
                            <div className='form-group col-xs-12 col-sm-5 col-md-4 col-lg-4'>
                                <label>Updated Date</label>
                                <NyDateTimeRangeInput
                                    className='form-control'
                                    required={
                                        this.state.filterValues
                                            .startUpdatedTs ||
                                        this.state.filterValues.endUpdatedTs
                                    }
                                    startName='startUpdatedTs'
                                    endName='endUpdatedTs'
                                />
                            </div>
                        )}
                        {this._isFloorTradesPage && (
                            <>
                                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                                    <label>Match For</label>
                                    <NySelectInput name='matchFor'>
                                        <option value='all'>All INFs</option>
                                        <option value='partialAllocation'>
                                            Partial Allocation
                                        </option>
                                        <option value='zeroAllocation'>
                                            Zero Allocation
                                        </option>
                                    </NySelectInput>
                                </div>
                            </>
                        )}
                        <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                            <label>Original Trade Date</label>
                            <NyDateTimeInput
                                className='form-control'
                                format='MM/dd/yyyy'
                                initialValue={this.props.route.params.origTdate}
                                name='origTdate'
                                min={this._minOrigTdate}
                                minView='months'
                                required={
                                    !this
                                        ._isTransactionsOrTransactionsHistoryPage ||
                                    this.state.filterValues.startRefExecTs ||
                                    this.state.filterValues.endRefExecTs
                                }
                            />
                        </div>
                        <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                            <label>Ref Exec Time</label>
                            <NyDateTimeRangeInput
                                className='form-control'
                                format='HH:mm:ss.SSSSSSSSS'
                                date='origTdate'
                                startName='startRefExecTs'
                                required={
                                    this.state.filterValues.startRefExecTs ||
                                    this.state.filterValues.endRefExecTs
                                }
                                endName='endRefExecTs'
                                name='refExecTs'
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Underlying Symbol</label>
                            <NyUppercaseInput name='underlyingSymbol' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>OSI Root Symbol</label>
                            <NyUppercaseInput name='osiRootSymb' />
                        </div>
                        <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                            <label>Expiration Date</label>
                            <NyDateTimeInput
                                className='form-control'
                                format='MM/dd/yyyy'
                                name='expirationDate'
                                min={this._minExpirationDate}
                                max={this._maxExpirationDate}
                                minView='months'
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-5 col-md-3 col-lg-2'>
                            <label>Strike Price</label>
                            <NyNumberInput name='strikePrice' precision={8} />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Put/Call</label>
                            <NySelectInput name='putCall'>
                                <option value=''>-</option>
                                <option value='0'>Put</option>
                                <option value='1'>Call</option>
                            </NySelectInput>
                        </div>
                        <div className='form-group col-xs-12 col-sm-4 col-md-3 col-lg-2'>
                            <label>Qty</label>
                            <div className='form-group-span'>
                                <NyNumberInput
                                    name='startExecQty'
                                    max='endExecQty'
                                />
                                <span className='flex-shrink to-span'>TO</span>
                                <NyNumberInput
                                    name='endExecQty'
                                    min='startExecQty'
                                />
                            </div>
                        </div>
                        <div className='form-group col-xs-12 col-sm-5 col-md-3 col-lg-2'>
                            <label>Price</label>
                            <div className='form-group-span'>
                                <NyNumberInput
                                    name='startOrdPrc'
                                    max='endOrdPrc'
                                    precision={8}
                                />
                                <span className='flex-shrink to-span'>TO</span>
                                <NyNumberInput
                                    name='endOrdPrc'
                                    min='startOrdPrc'
                                    precision={8}
                                />
                            </div>
                        </div>
                        <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                            <label>MPID</label>
                            <FirmParticipantSelect
                                crd={parseInt(this.state.filterValues.crd)}
                                isMulti
                                name='custFirmId'
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Clearing #</label>
                            <ClearingNumberFilterSelect
                                name='custClearingNumber'
                                crd={this.state.filterValues.crd}
                                valueAsString
                                isMulti
                                maxLength={5}
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>MMID</label>
                            <MmIdSelect
                                name='custSndrSubId'
                                isMulti
                                crd={this.state.filterValues.crd}
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>CMTA</label>
                            <NyTextInput name='custCmta' maxLength={5} />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Badge</label>
                            <BadgeIdSelect
                                name='custSubmittingBkrId'
                                isMulti
                                crd={this.state.filterValues.crd}
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Open/Close</label>
                            <NySelectInput name='custOpenClose'>
                                <option value=''>-</option>
                                {openCloseChoices.map((obj, i) => (
                                    <option key={i} value={obj.value}>
                                        {obj.label}
                                    </option>
                                ))}
                            </NySelectInput>
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Customer or Firm</label>
                            <NySelectInput name='custFirm'>
                                <option value=''>-</option>
                                {custFirmChoices.map((obj, i) => (
                                    <option key={i} value={obj.value}>
                                        {obj.label}
                                    </option>
                                ))}
                            </NySelectInput>
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>OCC Seq #</label>
                            <NyNumberInput
                                name='tradeSequenceNumber'
                                valueAsString
                                maxLength={20}
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Side</label>
                            <NySelectInput name='custSide'>
                                <option value=''>-</option>
                                <option value='1'>Buy</option>
                                <option value='2'>Sell</option>
                            </NySelectInput>
                        </div>

                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Client Order Id</label>
                            <NyTextInput name='custClOrdId' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Account</label>
                            <NyTextInput name='custAccount' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Optional Data
                                <OptnlDataWildcardInfoTooltip />
                            </label>
                            <NyTextInput name='custOptnlData' />
                        </div>
                        {this._isTransactionsOrTransactionsHistoryPage && (
                            <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                                <label>User Tx Id</label>
                                <NyTextInput name='userTxId' />
                            </div>
                        )}
                    </AgGridProvider.Filters>
                    <AgGridProvider.AdvancedFilters>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>OSI Symbol</label>
                            <NyUppercaseInput name='symb' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <label>Liquidity Indicator</label>
                            <LiqIndicatorSelect isMulti name='custLqdtyInd' />
                        </div>
                        <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                            <label>Deal Id</label>
                            <NyNumberInput name='dealNum' valueAsString />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Original Deal Id</label>
                            <NyNumberInput name='dealIdOrig' valueAsString />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Ref Deal Id</label>
                            <NyNumberInput name='dealIdRef' valueAsString />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Cross ID</label>
                            <NyTextInput name='crossId' maxLength={20} />
                        </div>
                        <div className='form-group col-xs-12 col-sm-5 col-md-3 col-lg-2'>
                            <label>Public Order Id</label>
                            <NyTextInput name='custIdPub' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Session Id</label>
                            <NyTextInput name='custSessionId' />
                        </div>
                    </AgGridProvider.AdvancedFilters>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <div className='flex-row flex-align-bottom'>
                                <AgGridProvider.SubmitBtn />
                                <AgGridProvider.ResetBtn />
                                <AgGridProvider.AdvancedFiltersBtn />

                                <div className='btn-circle-grp flex-pull-right flex-row'>
                                    {this._isAllocatedTradesPage && (
                                        <Access
                                            permissions={[
                                                Permissions.adjustTradeSummary
                                            ]}>
                                            <Badge
                                                badgeContent={
                                                    this.state.selected?.length
                                                        ? this.state.selected.length
                                                        : undefined
                                                }
                                                color='secondary'
                                                max={this.state.selected?.length}>
                                                <NyButton
                                                    primary
                                                    className='space-right-1'
                                                    data-e2e-tag='multiEditBtn'
                                                    onClick={this._onMultiEditClick}
                                                    disabled={!isSelected}
                                                >
                                                    MULTI-EDIT
                                                </NyButton>
                                            </Badge>
                                            <MultiTradeActionModal
                                                hideModal={
                                                    this._onMultiEditClose
                                                }
                                                data={this.state.selected}
                                                crd={this.state.filterValues.crd}
                                                show={this.state.showMultiEdit}
                                            />
                                        </Access>
                                    )}
                                    {this._isTransactionsPage && (
                                        <Access
                                            permissions={[
                                                Permissions.recallTradeSummary
                                            ]}>
                                            <Badge
                                                badgeContent={
                                                    this.state.selected?.length
                                                        ? this.state.selected.length
                                                        : undefined
                                                }
                                                color='secondary'
                                                max={this.state.selected?.length}>
                                                <NyButton
                                                    primary
                                                    className='space-right-1'
                                                    data-e2e-tag='multiRecallBtn'
                                                    onClick={this._onMultiRecallClick}
                                                    disabled={!isSelected}
                                                >
                                                    RECALL
                                                </NyButton>
                                            </Badge>

                                            <MultiRecallModal
                                                onClose={this._onModalClosed}
                                                hideModal={
                                                    this._onMultiReCallClose
                                                }
                                                data={this.state.selected}
                                                crd={this.state.filterValues.crd}
                                                show={this.state.showMultiRecall}
                                            />
                                        </Access>
                                    )}

                                    {this._isTransactionsPage && (
                                        <Access
                                            permissions={[
                                                Permissions.contraApproveTradeSummary
                                            ]}>
                                            <Badge
                                                badgeContent={
                                                    this.state.selected?.length
                                                        ? this.state.selected.length
                                                        : undefined
                                                }
                                                color='secondary'
                                                max={this.state.selected?.length}>
                                                <NyButton
                                                    primary
                                                    className='space-right-1'
                                                    data-e2e-tag='multiApproveBtn'
                                                    onClick={this._onMultiApproveClick}
                                                    disabled={!isSelected}
                                                >
                                                    Approve
                                                </NyButton>
                                            </Badge>

                                            <MultiApprovalModal
                                                onClose={this._onModalClosed}
                                                hideModal={
                                                    this._onMultiApproveClose
                                                }
                                                data={this.state.selected}
                                                crd={this.state.filterValues.crd}
                                                show={this.state.showMultiApprove}
                                                isApproveAction={true}
                                            />
                                        </Access>
                                    )}
                                    {this._isTransactionsPage && (
                                        <Access
                                            permissions={[
                                                Permissions.contraApproveTradeSummary
                                            ]}>
                                            <Badge
                                                badgeContent={
                                                    this.state.selected?.length
                                                        ? this.state.selected.length
                                                        : undefined
                                                }
                                                color='secondary'
                                                max={this.state.selected?.length}>
                                                <NyButton
                                                    danger
                                                    className='space-right-1'
                                                    data-e2e-tag='multiRejectBtn'
                                                    onClick={this._onMultiRejectClick}
                                                    disabled={!isSelected}
                                                >
                                                    Reject
                                                </NyButton>
                                            </Badge>
                                            <MultiApprovalModal
                                                onClose={this._onModalClosed}
                                                hideModal={
                                                    this._onMultiRejectClose
                                                }
                                                data={this.state.selected}
                                                crd={this.state.filterValues.crd}
                                                show={this.state.showMultiReject}
                                                isApproveAction={false}
                                            />
                                        </Access>
                                    )}
                                    <AgGridToggleShowFilters />
                                    <AgGridExportMenu
                                        exportFileName={
                                            this.state.exportFileName
                                        }
                                        exportSheetName={
                                            this.props.platform.name
                                        }
                                        has100kExport={
                                            this.props.user.entitlement ===
                                            'SUPER_USER'
                                        }
                                        has500kExport={false}
                                        isExportingAll={download.isDownloading}
                                        onExportAll={
                                            this._InvokeExportAllForCurrentTab
                                        }
                                        progressBar={
                                            <DownloadProgress
                                                fileName={
                                                    this.state.exportFileName
                                                }
                                            />
                                        }
                                    />
                                    <AgGridColumnMenuTrigger />
                                </div>
                            </div>
                        </div>
                    </div>

                    <AgGrid
                        highlightSelectedRow={true}
                        agGridOverride={this.agGridOverride}
                        reactNext />

                    <AgGridPagination>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1,000</option>
                        <option value={5000}>5,000</option>
                        <option value={10000}>10,000</option>
                    </AgGridPagination>
                </AgGridProvider>
                <HintSnackbar content={this.state.hintSnackbarMessage} />
            </>
        );
    }
}
