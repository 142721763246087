import {omit} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {createDownloadAction} from '../downloads/downloads.actions';
import {events} from '../events';

export const getLiquidityMetrics = (params) => ({
    types: [
        events.LIQUIDITY_METRICS_GET,
        events.LIQUIDITY_METRICS_SUCCESS,
        events.LIQUIDITY_METRICS_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: `api/liquidity-metrics/${params.view}`,
            params: cleanObject({
                ...omit(params, ['date', 'view']),
                symbol: params.symbol ? params.symbol.split('|') : undefined,
                startTime: joda.toStartOfDay(params.date).toString(),
                endTime: joda.toEndOfDay(params.date).toString()
            })
        }
    }
});

export const getSymbolsDownload: CallableFunction = createDownloadAction({
    client: 'cdmMw',
    url: 'api/firms/symbols/download',
    method: 'GET',
    parseParams: (params) =>
        cleanObject({
            ...omit(params, ['date']),
            symbol: params.symbol ? params.symbol.split('|') : undefined,
            startTime: joda.toStartOfDay(params.date).toString(),
            endTime: joda.toEndOfDay(params.date).toString()
        }),
    parseData: null
});
