import {flatten} from 'lodash-es';

import {sentinelMw} from '../api';
import environment from '../configs/environment.config';
import {Option} from './tradeSummary.resource';

type mm_id = {
    id: string;
    status: string;
};

type MarketMakerFirms = {
    id: string;
    mm_ids: mm_id[];
    clearingNumber: string;
};

type BrokerFirms = {
    id: string;
    badges: string[];
};

export const viewGBOFirmsResource = {
    api: sentinelMw,
    url: 'viewgbofirms',
    useCache: !(environment.testEnv || environment.integrationEnv),
    parseResponse: (data) => {
        const firms = data.responseData.firms;
        const firmIdOptions: Option[] = flatten(
            firms.map(({crd, participants}) =>
                participants.map(({id}) => ({
                    label: id,
                    value: id,
                    crd: crd
                }))
            )
        );
        return firmIdOptions;
    },
    defaultValue: []
};

export const viewGBOMarketMakerFirmsResource = {
    api: sentinelMw,
    url: 'viewgbomarketmakerfirms',
    useCache: !(environment.testEnv || environment.integrationEnv),
    parseResponse: (data) => {
        const firms = data.responseData.firms;
        const marketMakerFirms: MarketMakerFirms[] = flatten(
            firms.map(({participants}) =>
                participants.map((participant) => ({
                    id: participant.id,
                    mm_ids: participant.mm_ids,
                    clearingNumber: participant.clearingNumber
                }))
            )
        );
        const sndrSubIdOptions: Option[] = flatten(
            marketMakerFirms.map((sndrSubId) =>
                sndrSubId.mm_ids
                    .filter((mmid) => mmid.status === 'Active')
                    .map((mmid) => ({
                        value: mmid.id,
                        label: mmid.id,
                        firmId: sndrSubId.id,
                        clearingNumber: sndrSubId.clearingNumber
                    }))
            )
        );
        return sndrSubIdOptions;
    },
    defaultValue: []
};

export const viewGBOBrokerFirmsResource = {
    api: sentinelMw,
    url: 'viewgbobrokerfirms',
    useCache: !(environment.testEnv || environment.integrationEnv),
    parseResponse: (data) => {
        const firms = data.responseData.firms;
        const brokerFirms: BrokerFirms[] = flatten(
            firms.map(({participants}) =>
                participants
                    .filter((participant) => participant.status === 'Active')
                    .map((participant) => ({
                        id: participant.id,
                        badges: participant.badges
                    }))
            )
        );
        const brokerOptions: Option[] = flatten(
            brokerFirms.map((firm) =>
                firm.badges.map((badge) => ({
                    value: badge,
                    label: badge,
                    firmId: firm.id
                }))
            )
        );
        return brokerOptions;
    },
    defaultValue: []
};
