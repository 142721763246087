export const mySessionsColumnDefs = [
    {
        headerName: 'Username',
        field: 'sendercomp',
        pinned: 'left',
        width: 75
    },
    {
        headerName: 'Company Name',
        field: 'companyname',
        width: 270
    },
    {
        headerName: 'Connection Status',
        field: 'connection_status',
        cellRenderer: ({data: {connection_status}}) => {
            const isDanger = ['disconnected', 'none'].includes(
                connection_status
            );
            const isSuccess = ['primary', 'secondary'].includes(
                connection_status
            );

            return (
                `<div class="flex-row flex-grow flex-align-center">
                    ${ isSuccess ? '<i class="mdi mdi-18px mdi-check num-positive"></i>' : '' }
                    ${ isDanger ? '<i class="mdi mdi-18px mdi-alert num-negative"></i>' : '' }
                    &nbsp;&nbsp;${connection_status}
                </div>`
            );
        },
        width: 200
    },
    {
        headerName: 'Sender Type',
        field: 'sender_type',
        width: 150
    },
    {
        headerName: 'User Type',
        field: 'user_type',
        width: 130
    },
    {
        headerName: 'User Id',
        field: 'user_id',
        width: 110
    },
    {
        headerName: 'Pri CG',
        field: 'pri_cg',
        width: 110
    },
    {
        headerName: 'Sec CG',
        field: 'sec_cg',
        width: 110
    },
    {
        headerName: 'Session Status',
        field: 'session_status',
        cellRenderer: ({data: {session_status}}) => {
            const isActive = ['active'].includes(session_status);
            const isProspect = ['prospect'].includes(session_status);
            const isInactive = ['inactive'].includes(session_status);

            return (
                `<div class="flex-row flex-grow flex-align-center">
                    ${ isActive ?
                    '<i class="mdi mdi-18px mdi-check num-positive" style="line-height:1;font-weight:700;"></i>' : '' }
                    ${ isInactive ?
                    '<i class="mdi mdi-18px mdi-alert num-negative" style="line-height:1;font-weight:700;"></i>' : '' }
                    ${ isProspect ?
                    '<i class="mdi mdi-18px mdi-alert-circle-outline"' +
                    ' style="line-height:1;font-weight:700;color:#5bc0de;"></i>' : '' }
                    &nbsp;&nbsp;${session_status}
                </div>`
            );
        },
        width: 180
    },
    {
        headerName: 'Port',
        field: 'port',
        width: 110
    },
    {
        headerName: 'Source IP',
        field: 'source_ip',
        width: 190
    },
    {
        field: 'source_port',
        headerName: 'Source Port',
        width: 170
    },
    {
        field: 'pri_ip',
        headerName: 'Pri IP',
        width: 190
    },
    {
        field: 'sec_ip',
        headerName: 'Sec IP',
        width: 190
    },
    {
        field: 'n_connect',
        headerName: 'N Connect',
        type: 'number',
        width: 140
    },
    {
        field: 'last_connection',
        headerName: 'Last Connection',
        width: 260
    },
    {
        field: 'gt_stream',
        headerName: 'GT Stream',
        width: 250
    },
    {
        field: 'gt_seq',
        headerName: 'GT Seq',
        width: 110
    },
    {
        field: 'gt_login_seq',
        headerName: 'GT Login Seq',
        width: 150
    },
    {
        field: 'tg_stream',
        headerName: 'TG Stream',
        width: 250
    },
    {
        field: 'tg_seq',
        headerName: 'TG Seq',
        width: 110
    },
    {
        field: 'tg_login_seq',
        headerName: 'TG Login Seq',
        width: 150
    },
    {
        field: 'ref_stream',
        headerName: 'Ref Stream',
        width: 260
    },
    {
        field: 'ref_seq',
        headerName: 'Ref Seq',
        width: 110
    },
    {
        field: 'ref_login_seq',
        headerName: 'Ref Login Seq',
        width: 165
    },
    {
        field: 'crd',
        type: 'numericString',
        headerName: 'CRD',
        width: 100
    }
];
