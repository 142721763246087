import Button from '@material-ui/core/Button';
import Decimal from 'decimal.js';
import React from 'react';

export const opraSeqNumberColumnDefs = [
    {
        field: '_details_',
        headerName: '',
        cellRendererFramework: ({context, data}) => (
            <Button
                color='secondary'
                onClick={() => context.onSelect(data)}
                variant='text'>
                Select
            </Button>
        ),
        width: 75,
        maxWidth: 75,
        minWidth: 75,
        pinned: 'left'
    },
    {
        field: 'symbol',
        headerName: 'Symbol',
        width: 70
    },
    {
        field: 'tradeSequence',
        headerName: 'Sequence Number',
        type: 'numericString'
    },
    {
        field: 'optionType',
        headerName: 'Call / Put'
    },
    {
        field: 'exchange',
        headerName: 'Exchange',
        filter: 'agTextColumnFilter',
        filterParams: {
            newRowsAction: 'keep',
            defaultOption: 'equals'
        }
    },
    {
        field: 'tradeSize',
        headerName: 'Qty',
        type: 'number',
        filter: 'agNumberColumnFilter',
        filterParams: {
            newRowsAction: 'keep'
        }
    },
    {
        field: 'tradePrice',
        headerName: 'Price',
        type: 'priceLong',
        filter: 'agNumberColumnFilter',
        filterParams: {
            newRowsAction: 'keep'
        }
    },
    {
        field: 'bestBidPrice',
        headerName: 'Best Bid Price',
        type: 'priceLong'
    },
    {
        field: 'bestAskPrice',
        headerName: 'Best Ask Price',
        type: 'priceLong'
    },
    {
        field: 'strikePrice',
        headerName: 'Strike Price',
        type: 'priceLong'
    },
    {
        field: 'underlyingAskPrice',
        headerName: 'Underlying Ask Price',
        type: 'priceLong'
    },
    {
        field: 'underlyingBidPrice',
        headerName: 'Underlying Bid Price',
        type: 'priceLong'
    },
    {
        field: 'tradeDate',
        headerName: 'Print Time',
        type: 'dateTime'
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        type: 'date'
    },
    {
        field: 'tradeIv',
        headerName: 'Trade IV',
        type: 'percentage',
        valueGetter: ({data}) =>
            new Decimal(data.tradeIv || '0').times(100).toNumber()
    },
    {
        field: 'tradeDelta',
        headerName: 'Trade Delta',
        type: 'percentage',
        valueGetter: ({data}) =>
            new Decimal(data.tradeDelta || '0').times(100).toNumber()
    },
    {
        field: 'tradeConditionId',
        headerName: 'Trade Condition Id'
    },
    {
        field: 'cancTrdCondId',
        headerName: 'Cancel Trade Condition Id'
    }
];
