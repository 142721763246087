export const marketAccessExceptionsColumnDefs = [
    {
        field: 'entityName',
        headerName: 'Originator',
        width: 90
    },
    {
        field: 'parentOrderId',
        headerName: 'Parent Order',
        type: 'numericString',
        width: 120
    },
    {
        field: 'symbol',
        headerName: 'Symbol',
        width: 80
    },
    {
        field: 'orderActivityDateTime',
        headerName: 'Time',
        width: 150
    },
    {
        field: 'price',
        headerName: 'Limit Price',
        type: 'price',
        width: 100
    },
    {
        field: 'orderQuantityValue',
        headerName: 'Order Quantity',
        type: 'number',
        width: 115
    },
    {
        field: 'orderQuantityThreshold',
        headerName: 'Order Quantity Threshold',
        type: 'number',
        width: 175
    },
    {
        field: 'orderQuantityPercentage',
        headerName: 'Order Quantity Percentage',
        type: 'percentage',
        width: 180
    },
    {
        field: 'orderValue',
        headerName: 'Order Value',
        type: 'price',
        width: 95
    },
    {
        field: 'orderValueThreshold',
        headerName: 'Order Value Threshold',
        type: 'price',
        width: 155
    },
    {
        field: 'orderValuePercentage',
        headerName: 'Order Value Percentage',
        type: 'percentage',
        width: 165
    },
    {
        field: 'dailyTotalValue',
        headerName: 'Daily Total Value',
        type: 'price',
        width: 130
    },
    {
        field: 'dailyTotalValueThreshold',
        headerName: 'Daily Total Value Threshold',
        type: 'price',
        width: 185
    },
    {
        field: 'dailyTotalValuePercentage',
        headerName: 'Daily Total Value Percentage',
        type: 'percentage',
        width: 190
    },
    {
        field: 'initPerson',
        headerName: 'Init Person Id',
        width: 105
    },
    {
        field: 'overrideReason',
        headerName: 'Override Reason',
        width: 130,
        autoHeight: true,
        cellStyle: {'white-space': 'normal'}
    },
    {
        field: 'popupAlert',
        headerName: 'Pop-up Alert',
        width: 150,
        autoHeight: true,
        cellStyle: {'white-space': 'normal'}
    },
    {
        field: 'exception',
        headerName: 'Exception',
        width: 150,
        autoHeight: true,
        cellStyle: {'white-space': 'normal'}
    }
];
