import Box from '@material-ui/core/Box';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridProvider
} from 'nyse-web-tools-common/lib/react';
import React, {useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useMount, useUnmount} from 'react-use';

import {coreSessionDataColumnDefs} from '../../configs/columnDefs/coreSessionData.columnDefs';
import useStore from '../../hooks/useStore.hook';
import {getCoreSession} from '../../stores/coreSession/coreSession.actions';

export function CoreSessionDataPanel(): JSX.Element {
    const dispatch = useDispatch();
    const coreSession = useStore('coreSession');

    const interval = useRef(null);
    const columnDefs = useRef({default: coreSessionDataColumnDefs});

    const onLoad = useCallback(() => {
        dispatch(getCoreSession('state'));
        dispatch(getCoreSession('halted'));
        dispatch(getCoreSession('tradevolume'));
    }, []);

    useMount(() => {
        interval.current = setInterval(() => onLoad(), 10000);
    });

    useUnmount(() => {
        clearInterval(interval.current);
    });

    return (
        <AgGridProvider
            columnNamespace='coreSessionData'
            columnDefs={columnDefs.current}
            error={coreSession.error}
            isLoading={coreSession.isLoading}
            onLoad={onLoad}
            rowData={coreSession.coreSessionDataRowData}
            submitOnLoad
            timestamp={coreSession.timestamp}>
            <Box alignItems='center' className='panel-heading' display='flex'>
                <span className='panel-title'>Core Session Data</span>
                <AgGridColumnMenuTrigger
                    className='mdi mdi-settings mdi-18px flex-pull-right'
                    component={<a />}
                />
            </Box>

            <div className='panel-body'>
                <AgGrid autoHeight autoSizeColumns noBoxShadow />
            </div>
        </AgGridProvider>
    );
}
