import buttons from 'nyse-web-tools-common/lib/react/generic/StaticButtons/StaticButtons';
import React from 'react';

import {IdbOriginatorModal} from '../../components/modals/idbOriginators/idbOriginators.modal';
import idbOriginatorResource from '../../resources/idbOriginators.resource';

export const idbOriginatorsColumnDefs = [
    {
        cellRendererParams: {
            Form: IdbOriginatorModal,
            Button: buttons.EditOutlined,
            name: 'editIdbOriginator',
            omitInitialValueFields: [
                'createdBy',
                'createdTime',
                'modifiedBy',
                'modifiedTime'
            ],
            parentTitle: 'IDB / Originators',
            refreshValueFields: ['crd'],
            // this is only required since the server sorts the response by modified date
            resetScrollToTop: true,
            resource: idbOriginatorResource,
            resourceMethod: 'put',
            size: 30,
            title: 'Update'
        },
        field: 'editIdbOriginator',
        permissions: ['update idb-originators'],
        pinned: 'left',
        type: 'modal'
    },
    {
        cellRendererParams: {
            Button: buttons.DeleteOutlineOutlined,
            message: (data) => (
                <>
                    Do you want to delete IDB / Originator:{' '}
                    <strong>{data.name}</strong>?
                </>
            ),
            resource: idbOriginatorResource,
            warning: true
        },
        field: 'removeIdbOriginator',
        permissions: ['delete idb-originators'],
        pinned: 'right',
        type: 'remove'
    },
    {
        field: 'mpId',
        headerName: 'MPID'
    },
    {
        field: 'tradingAccount',
        headerName: 'Trading Account'
    },
    {
        field: 'name',
        headerName: 'IDB/Originator Id'
    },
    {
        field: 'isIdb',
        headerName: 'IDB',
        type: 'checkMark'
    },
    {
        field: 'isOriginator',
        headerName: 'Originator',
        type: 'checkMark'
    },
    {
        field: 'oatsId',
        headerName: 'Oats Id'
    },
    {
        field: 'createdBy',
        headerName: 'Created By'
    },
    {
        field: 'createdTime',
        headerName: 'Created Time',
        type: 'dateTime'
    },
    {
        field: 'modifiedBy',
        headerName: 'Modified By'
    },
    {
        field: 'modifiedTime',
        headerName: 'Modified Time',
        type: 'dateTime'
    },
    {
        field: 'orderThresholdQuantity',
        headerName: 'Threshold Qty',
        type: 'number'
    },
    {
        field: 'orderThresholdValue',
        headerName: 'Order Threshold',
        type: 'number'
    },
    {
        field: 'dailyOrderThresholdValue',
        headerName: 'Daily Order Threshold',
        type: 'number'
    },
    {
        field: 'originatorCrd',
        headerName: 'Originator CRD',
        type: 'numericString'
    },
    {
        field: 'affiliateFlag',
        headerName: 'Affiliate Flag',
        type: 'checkMark'
    },
    {
        field: 'autoPublishEligible',
        headerName: 'Auto Publish',
        type: 'checkMark'
    }
];
