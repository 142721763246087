import {cdmMw} from '../api';
import {EntityType} from '../configs/entitlementRoleMap';

export type RiskMonitorData = {
    userCrd: number;
    userCrdRole: string;
    riskEntity: string;
    entityId: number;
    entityCrd?: number;
    entityMpid: string;
    entitySubid: string;
    entityType: EntityType;
    blocked: string;
    blockedKs: string;
    blockedKsCrd?: number;
    blockedKsCrdRole?: string;
    settings: {
        limitCrd: number;
        limitCrdRole: string;
        riskType: string;
        action: string;
        limit: string;
        ord: string;
        ordPct: string;
        ordAuc: string;
        ordAucPct: string;
        exec: string;
        execPct: string;
        execAuc: string;
        execAucPct: string;
        ordExec: string;
        ordExecPct: string;
        ordExecAuc: string;
        ordExecAucPct: string;
        current: string;
        currentPct: string;
        currentAuc: string;
        currentAucPct: string;
    }[];
    displayBlock: boolean;
    displayRiskEntity: string;
    displayReinstateStatus: string;
};

export const riskMonitorResource = {
    api: cdmMw,
    url: 'api/v2/risk-entities/monitor',
    parseResponse: (param) =>
        param.data.map((item) => ({
            ...item,
            limits: [
                {
                    data: {}
                }
            ],
            myLimits: param.meta.myLimits
        })),
    resetDataOnRequest: false,
    defaultValue: []
};

export const riskMonitorDetailsResource = {
    api: cdmMw,
    url: (params) => `api/risk-entities/monitor/${params.entityId}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false
};

export const reinstatementResource = {
    api: cdmMw,
    url: (param) => `api/v2/risk-entities/${param.entityId}/reinstatement`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
};

export const approveReinstatementResource = {
    api: cdmMw,
    url: (param) =>
        `api/v2/risk-entities/${param.entityId}/${
            param.breachType === 'GRMP'
                ? 'grmp-reinstatement'
                : 'non-grmp-reinstatement'
        }`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
};

export const cancelAndBlockResource = {
    api: cdmMw,
    url: (param) => `api/v2/risk-entities/${param.entityId}/${param.action}`,
    parseResponse: ({data}) => data,
    resetDataOnRequest: false,
    defaultValue: []
};
