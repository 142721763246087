import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {noop, pick} from 'lodash-es';
import {AgGrid, AgGridProvider, NyAlert, NyForm, NySelectInput, NyTextInput} from 'nyse-web-tools-common/lib/react';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, {useCallback, useState} from 'react';
import {useQueries} from 'react-query';
import {useUnmount} from 'react-use';
import useResource from '../../../../hooks/useResource.hook';
import {
    contraSideFields,
    customerSideFields,
    requiredFields,
    tradeApprovalResource,
    tradeRejectionResource,
    TransactionProps,
    stripCharsForAccountOptionalData
} from '../../../../resources/tradeSummary.resource';
import {cleanObject} from '../../../../utils/utilities';
import {multiApprovalTransactionsColumnDef} from './multiApproval.form';

const useStyles = makeStyles(() => ({
    rejectButton: {
        background: '#b00017',
        '&:hover': {
            backgroundColor: '#b00017'
        }
    }
}));

export const MultiApprovalFormFields: React.FC<TransactionProps> = React.memo(
    (props) => {

        const classes = useStyles(props);

        const approveResource = useResource(tradeApprovalResource);
        const rejectResource = useResource(tradeRejectionResource);
        const[ optionData , setOptionData] = useState('');
        const[ reasonCode , setReasonCode] = useState();
        const[ cmta , setCmta] = useState('');
        const[ account , setAccount] = useState('');
        const[ otherReason , setOtherReason] = useState();
        const [agGrid, setAgGrid] = useState(null);
        const [refetchData, setRefetchData] = useState(false);
        const [formSubmitted, setFormSubmitted] = useState(false);
        const [failedRequest, setFailedRequest] = useState(false);

        const initialValues = {
            reasonCode: '-Keep as is-'
        };

        const rowNode = (txId) => {
            return agGrid.getRowNode(txId);
        };

        const txId = (url) => {
            const subSubString = url.substring(url.indexOf('transactions') + 1 + 'transactions'.length);
            return subSubString.substring(0 ,subSubString.indexOf('/'));
        };

        const approveTrade = (trade) => {
            const row = rowNode(trade.txId);
            row.setDataValue('processStatus','loading');
            return approveResource.put(
                cleanObject({
                    crd: props.crd,
                    ...pick(trade, [
                        ...requiredFields,
                        ...customerSideFields,
                        ...contraSideFields
                    ]),
                    ...(reasonCode === 'Other' ? {reasonCode: otherReason}
                        : reasonCode === '-Keep as is-'
                            ? {reasonCode: trade.reason} : {reasonCode: reasonCode}),
                    ...(optionData !== '' && {custOptnlData: optionData}),
                    ...(account !== '' && {custAccount: account}) ,
                    ...(cmta !== '' && {custCmta: cmta})
                }),
                {
                    origTdate: trade.origTdate,
                    evtTyp: trade.evtTyp,
                    dealIdRef: trade.dealIdRef,
                    txId: trade.txId
                }
            );
        };

        const rejectTrade = (trade) => {
            const row = rowNode(trade.txId);
            row.setDataValue('processStatus','loading');
            return rejectResource.put(
                cleanObject({
                    crd: props.crd,
                    ...(reasonCode === 'Other' ? {reasonCode: otherReason}
                        : reasonCode === '-Keep as is-'
                            ? {reasonCode: trade.reason} : {reasonCode: reasonCode})
                }),
                {
                    origTdate: trade.origTdate,
                    evtTyp: trade.evtTyp,
                    dealIdRef: trade.dealIdRef,
                    txId: trade.txId
                }
            );
        };

        const approveQueries = useQueries(
            props.data.map((trade) => {
                return {
                    queryKey: ['id', trade.txId],
                    queryFn: () => approveTrade(trade),
                    enabled: false
                };
            })
        );

        const rejectQueries = useQueries(
            props.data.map((trade) => {
                return {
                    queryKey: ['id', trade.txId],
                    queryFn: () => rejectTrade(trade),
                    enabled: false
                };
            })
        );

        const triggerQuery = (approveQueries,agGrid) => {
            return approveQueries.map(async (approveQuery) => {
                await approveQuery.refetch().then((response) => {
                    if (response.isSuccess) {
                        const aRow = agGrid.getRowNode(response.data.data.meta.txId);
                        if (aRow) {
                            aRow.setDataValue('processStatus', response.status);
                            aRow.setDataValue('transactionStatus','CO_APPROVED');
                        }
                    } else if (response.isError) {
                        setFailedRequest(true);
                        const aRow = agGrid.getRowNode(txId(response.error.request.responseURL));
                        if (aRow) {
                            aRow.setDataValue('processStatus', response.status);
                            aRow.setDataValue('error', response.error.detail);
                        }
                    }
                });
                return approveQuery;
            });
        };

        const triggerRejectQuery = (rejectQueries,agGrid) => {
            return rejectQueries.map(async (rejectQuery) => {
                await rejectQuery.refetch().then((response) => {
                    if (response.isSuccess) {
                        const aRow = agGrid.getRowNode(response.data.data.meta.txId);
                        if (aRow) {
                            aRow.setDataValue('processStatus', response.status);
                            aRow.setDataValue('transactionStatus','CO_REJECTED');
                        }
                    } else if (response.isError) {
                        setFailedRequest(true);
                        const aRow = agGrid.getRowNode(txId(response.error.request.responseURL));
                        if (aRow) {
                            aRow.setDataValue('processStatus', response.status);
                            aRow.setDataValue('error', response.error.detail);
                        }
                    }
                });
                return rejectQuery;
            });
        };

        const onCustCmtaChange = useCallback(({value}) => {
            setCmta(value);
        }, []);
        const onCustAccountChange = useCallback(({value}) => {
            setAccount(stripCharsForAccountOptionalData(value));
        }, []);
        const onCustOptnlDataChange = useCallback(({value}) => {
            setOptionData(stripCharsForAccountOptionalData(value));
        }, []);
        const onReasonCodeChange = useCallback(({value}) => {
            setReasonCode(value);
        }, []);

        const onOtherReasonChange = useCallback(({value}) => {
            setOtherReason(value);
        }, []);

        const onGridReady = useCallback((grid) => {
            setAgGrid(grid);
        }, []);

        const onConfirm = useCallback(async () => {
            if(props.isApproveAction) {
                await Promise.all(triggerQuery(approveQueries,agGrid));
            }else {
                await Promise.all(triggerRejectQuery(rejectQueries,agGrid));
            }
            setRefetchData(true);
        } , [agGrid]);

        useUnmount(() => {
            if(refetchData) {
                props.onClose();
            }
        });

        const onSuccess = useCallback(() => {
            setFormSubmitted(() => true);
        }, []);

        return (
            <div>
                <NyForm
                    initialValues={initialValues}
                    onSubmit={onConfirm}
                    onSuccess={onSuccess}>
                    <div className='row flex-0-0 space-top-1'>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>(Change) Reason Code</label>
                            <NySelectInput
                                name='reasonCode'
                                options={props.reasonCodeOptions}
                                resetFields={['otherReason']}
                                onChange={onReasonCodeChange}
                            />
                        </div>
                        {reasonCode === 'Other' && (
                            <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                                <label>Other Reason</label>
                                <NyTextInput
                                    name='otherReason'
                                    onChange={onOtherReasonChange}
                                    required
                                />
                            </div>
                        )}
                    </div>
                    <div className='row'>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>CMTA</label>
                            <NyTextInput
                                name='custCmta'
                                maxLength={5}
                                onChange={onCustCmtaChange}
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>Account</label>
                            <NyTextInput
                                name='custAccount'
                                maxLength={20}
                                onChange={onCustAccountChange}
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                            <label>Optional Data</label>
                            <NyTextInput
                                name='custOptnlData'
                                maxLength={16}
                                onChange={onCustOptnlDataChange}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            { formSubmitted && (
                                <NyAlert

                                    title={`${
                                        failedRequest
                                            ? 'Error encountered for some transaction(s). Please review below.'
                                            : 'Your request was successful!'
                                    }`}
                                    type={failedRequest ? 'warning' : 'success'}
                                    show={formSubmitted}
                                />
                            )}
                        </div>
                    </div>
                    <Divider className='space-bottom-1' />
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            {props.isApproveAction && (
                                <NySubmitBtn
                                    color={'primary'}
                                    disabled={formSubmitted}
                                    className='space-right-1'>
                                    Approve
                                </NySubmitBtn>
                            )}
                            {!props.isApproveAction && (
                                <NySubmitBtn
                                    disabled={formSubmitted}
                                    className={classes.rejectButton}>
                                    Reject
                                </NySubmitBtn>
                            )}

                        </div>
                    </div>
                </NyForm>
                <Divider className='space-bottom-1'/>
                <Grid container alignItems='flex-end' spacing={2}>
                    <Grid item xs={12}>
                        <AgGridProvider
                            onGridReady={onGridReady}
                            autoSize={true}
                            onLoad={noop}
                            columnNamespace='multiApprovalTransaction'
                            columnDefs={multiApprovalTransactionsColumnDef}
                            primaryKey='txId'
                            rowData={props.data}
                            suppressRowClickSelection={true}
                            submitOnLoad={false}>
                            <AgGrid height={700} />
                        </AgGridProvider>
                    </Grid>
                </Grid>

            </div>

        );
    });
