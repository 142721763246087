import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridProvider,
    AgGridTimestamp
} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {setColumns} from '../../stores/columnDefs/columnDefs.actions';
import {
    getTopAuctions,
    resetTopAuctions
} from '../../stores/topAuctions/topAuctions.actions';
import {Link} from '../navigation/navigation.link';

@connect(['columnDefs', 'topAuctions'], {
    getTopAuctions,
    resetTopAuctions,
    setColumns
})
export class TopAuctionsPanel extends React.Component {
    interval = null;

    componentDidMount() {
        this.interval = setInterval(() => this._getTopAuctions(), 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.props.resetTopAuctions();
    }

    _getTopAuctions = () => {
        this.props.getTopAuctions({top: 10});
    };

    render() {
        return (
            <AgGridProvider
                autoSize={true}
                columnNamespace='topAuctions'
                columnDefs={this.props.columnDefs.topAuctions}
                height={313}
                onColumnsChanged={this.props.setColumns}
                onLoad={this._getTopAuctions}
                submitOnLoad={true}
                {...this.props.topAuctions}>
                <div className='panel-primary panel height-full'>
                    <div className='panel-heading flex-row flex-grow flex-align-center'>
                        <span className='panel-title'>Top Auction Data</span>

                        <AgGridColumnMenuTrigger
                            className='mdi mdi-settings mdi-18px flex-pull-right'
                            component={<a />}
                        />
                    </div>

                    <div className='panel-body' style={{height: 315}}>
                        <AgGrid reactNext={true} />
                    </div>
                    <div className='panel-footer flex-row flex-align-center'>
                        <AgGridTimestamp className='panel-footer-timestamp' />
                        <div className='flex-pull-right flex-column flex-align-center'>
                            <Link to='/:platform/auctions'>All Auctions</Link>
                        </div>
                    </div>
                </div>
            </AgGridProvider>
        );
    }
}
