import cn from 'classnames';
import {push} from 'connected-react-router';
import {CheckboxInput, NyButton} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {setTermsAndConditions} from '../stores/termsAndConditions/termsAndConditions.actions';

@connect(['termsAndConditions', 'route'], {push, setTermsAndConditions})
export class TermsAndConditionsPage extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                file: PropTypes.string.isRequired,
                pageTitle: PropTypes.string.isRequired,
                to: PropTypes.string.isRequired
            })
        })
    };

    state = {
        iAgree: false
    };

    _onClose = () => {
        const isSame =
            this.props.route.params.from === this.props.route.params.to;

        this.props.push(isSame ? '/' : this.props.route.params.from);
    };

    _onContinue = () => {
        this.props.setTermsAndConditions({
            path: this.props.route.params.to,
            iAgree: true
        });

        this.props.push(this.props.route.params.to);
    };

    _onToggle = (iAgree) => {
        this.setState({
            iAgree
        });
    };

    render() {
        return (
            <div
                data-open='true'
                data-e2e-tag='termsAndConditionsModal'
                className='modal in'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <div
                                className={cn('close', {
                                    disabled: this.state.locked
                                })}
                                onClick={this._onClose}>
                                &times
                            </div>
                            <div className='modal-title-content'>
                                <div className='page-header'>
                                    <div className='page-header-parent modal-parent-title'>
                                        {this.props.route.params.pageTitle}
                                    </div>
                                    <div className='flex-row flex-align-center modal-title'>
                                        <h1>Terms & Conditions</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row flex-align-baseline'>
                            <div className='col-md-12 col-sm-12 col-xs-12 space-top-1 space-bottom-1'>
                                <CheckboxInput
                                    name='iAgreeCheckbox'
                                    onChange={this._onToggle}
                                    value={this.state.iAgree}>
                                    <span>
                                        By clicking on this checkbox you agree
                                        to the&nbsp;
                                        <br />
                                        <a
                                            href={'https://www.theice.com/publicdocs/nyse/markets/nyse' +
                                            '/TOP_Terms_of_Use.pdf'}
                                            data-e2e-tag='link'
                                            target='_blank' rel='noreferrer'>
                                            Terms of use
                                        </a>
                                    </span>
                                </CheckboxInput>
                            </div>
                        </div>
                        <div className='row space-top-2'>
                            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                <NyButton
                                    primary
                                    data-e2e-tag='iAgreeBtn'
                                    disabled={!this.state.iAgree}
                                    onClick={this._onContinue}>
                                    I Agree
                                </NyButton>
                                <NyButton
                                    danger
                                    className='space-left-1'
                                    data-e2e-tag='iDisagreeBtn'
                                    type='button'
                                    onClick={this._onClose}>
                                    I Disagree
                                </NyButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
