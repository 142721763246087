import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import React, {useMemo} from 'react';

import useResource from '../../hooks/useResource.hook';
import {reasonCodeResource} from '../../resources/reasonCode.resource';

type ReasonCodeSelectProps = {
    disabled?: boolean;
    name: string;
    isMulti?: boolean;
    required?: boolean;
    onChange?: any;
    resetFields?: string[];
};

export const ReasonCodeSelect: React.FC<ReasonCodeSelectProps> = React.memo(
    (props) => {
        const resource = useResource<{label: string; value: string}[]>(
            reasonCodeResource,
            true
        );
        const options = useMemo(
            () => [
                {label: '-', value: ''},
                {label: '\u00A0Other', value: 'Other'},
                ...resource.data
            ],
            [resource.data]
        );

        return (
            <NySelectInput
                disabled={props.disabled}
                isLoading={resource.pending}
                onChange={props.onChange}
                name={props.name}
                options={options}
                resetFields={props.resetFields}
                required={props.required}
            />
        );
    }
);
