import {omit} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getMarketAccessExceptions = (params) => ({
    types: [
        events.MARKET_ACCESS_EXCEPTIONS_GET,
        events.MARKET_ACCESS_EXCEPTIONS_SUCCESS,
        events.MARKET_ACCESS_EXCEPTIONS_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/market-access-exceptions',
            method: 'GET',
            params: cleanObject({
                ...omit(params, ['crd', 'date']),
                startDate: joda.format(params.startDate, 'yyyy-MM-dd'),
                endDate: joda.format(params.endDate, 'yyyy-MM-dd')
            })
        }
    }
});
