import {AxiosError, AxiosResponse} from 'axios';
import createError from 'axios/lib/core/createError';
import {get} from 'lodash-es';
import {Store} from 'redux';

import * as enums from '../enums';
import {middlewareResponseErrorInterceptor} from './middleware.responseErrorInterceptor';

/**  Besides the isPillarDown check, the rest of the logic is explicitly used for TdmMw and SentinelMw Legacy Api EC_XXXX codes */
export function middlewareResponseInterceptor(
    store: Store,
    response: AxiosResponse
): Promise<AxiosResponse> | Promise<AxiosError> {
    const state = store.getState();

    const errorDetails = get(response, 'data.errorDetails', []);
    const isAuthError = errorDetails.some(({errorCode}) =>
        ['EC_2002', 'EC_2003'].includes(errorCode)
    );
    const isUnAuthorizedError = errorDetails.some(
        ({errorCode}) => errorCode === 'EC_2004'
    );
    // @ts-ignore
    const isPillarDown =
        response.config.isPillarSource &&
        !state.pillarStatus[state.platform.mic];

    if (isPillarDown) {
        return middlewareResponseErrorInterceptor(
            store,
            createError(
                enums.PILLAR_OFFLINE,
                response.config,
                'ECONNABORTED',
                response.request,
                response
            ) as AxiosError
        );
    }

    if (isAuthError) {
        return middlewareResponseErrorInterceptor(
            store,
            createError(
                enums.INVALID_ACCESS_TOKEN,
                response.config,
                'ECONNABORTED',
                response.request,
                {
                    ...response,
                    status: 401
                }
            ) as AxiosError
        );
    }

    if (isUnAuthorizedError) {
        return middlewareResponseErrorInterceptor(
            store,
            createError(
                enums.UNAUTHORIZED_ACCESS,
                response.config,
                'ECONNABORTED',
                response.request,
                {
                    ...response,
                    status: 403
                }
            ) as AxiosError
        );
    }

    if (errorDetails.length > 0) {
        return middlewareResponseErrorInterceptor(
            store,
            createError(
                enums.DEFAULT,
                response.config,
                'ECONNABORTED',
                response.request,
                response
            ) as AxiosError
        );
    }

    return Promise.resolve(response);
}
