import {agGridCellRenderer, NyButton} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React from 'react';

import {OPRAEntriesModal} from '../../modals/opraEntry/opraEntries.modal';

@agGridCellRenderer
export class OPRAEntryDetailsBtn extends React.PureComponent {
    static propTypes = {
        context: PropTypes.shape({
            crd: PropTypes.number.isRequired,
            onModalClosed: PropTypes.func.isRequired
        }),
        data: PropTypes.shape({
            dealId: PropTypes.string.isRequired,
            executionTime: PropTypes.string.isRequired,
            symbol: PropTypes.string.isRequired,
            lock: PropTypes.bool
        })
    };

    state = {
        show: false
    };

    _onClick = () => {
        this.setState({
            show: true
        });
    };

    _onClose = (isSuccessful) => {
        this.setState(
            {
                show: false
            },
            () => {
                this.props.context.onModalClosed(isSuccessful);
            }
        );
    };

    render() {
        return (
            <>
                <NyButton
                    circle
                    data-modal-name='opraEntryDetailsModal'
                    data-e2e-tag='opraEntryDetailsBtn'
                    data-deal-id={this.props.data.dealId}
                    data-symbol={this.props.data.symbol}
                    disabled={this.props.data.lock}
                    className='mdi mdi-details mdi-18px align-self-center'
                    onClick={this._onClick}
                />
                <OPRAEntriesModal
                    crd={this.props.context.crd}
                    name='opraEntryDetails'
                    onClose={this._onClose}
                    show={this.state.show}
                    trade={this.props.data}
                />
            </>
        );
    }
}
