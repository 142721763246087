import {events} from '../events';

export const getSymbolGroup = (group: string) => ({
    types: [
        events.SYMBOL_GROUPS_GET,
        events.SYMBOL_GROUPS_SUCCESS,
        events.SYMBOL_GROUPS_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: `api/symbol-groups/${group}`,
            cache: true,
            group
        }
    }
});
