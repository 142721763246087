import '@mdi/font/css/materialdesignicons.css';
import 'typeface-open-sans';
import 'typeface-roboto';

import './assets/index.less';

import React from 'react';
import ReactDOM from 'react-dom';

import Root from './root';

if (
    navigator.userAgent.match(/Trident/) ||
    navigator.userAgent.match(/rv:11/)
) {
    document.body.classList.add('ie11');
}

//eslint-disable-next-line no-console
const originalError = console.error;

//eslint-disable-next-line no-console
console.error = (...args) => {
    // this is to avoid console error this: https://github.com/ag-grid/ag-grid/issues/3680
    if (
        !/^Warning: unstable_flushDiscreteUpdates: Cannot flush updates when React is already rendering/.test(
            args[0]
        )
    ) {
        originalError.call(console, ...args);
    }
};

ReactDOM.render(<Root />, document.getElementById('root'));
