import {agGridCellRenderer, NyButton} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React from 'react';

import {RiskMonitorActionsModal} from './riskMonitorActions.modal';

@agGridCellRenderer
export class RiskMonitorActionsBtn extends React.PureComponent {
    static propTypes = {
        context: PropTypes.shape({
            onModalClosed: PropTypes.func.isRequired
        }),
        data: PropTypes.shape({
            crd: PropTypes.number.isRequired,
            mpid: PropTypes.string.isRequired,
            clearingNum: PropTypes.string.isRequired,
            alertLimit: PropTypes.number.isRequired,
            blocked: PropTypes.bool.isRequired,
            selected: PropTypes.string.isRequired,
            percentUsage: PropTypes.number.isRequired,
            netNotionalExecuted: PropTypes.number.isRequired
        })
    };

    state = {
        show: false
    };

    _onClick = () => {
        this.setState({
            show: true
        });
    };

    _onClose = () => {
        this.setState(
            {
                show: false
            },
            () => {
                this.props.context.onModalClosed();
            }
        );
    };

    render() {
        return (
            <>
                <NyButton
                    circle
                    data-e2e-tag='rmtModalTrigger'
                    className='mdi mdi-details mdi-18px align-self-center'
                    onClick={this._onClick}
                    title='Edit'
                />
                <RiskMonitorActionsModal
                    {...this.props.data}
                    hideModal={this._onClose}
                    show={this.state.show}
                />
            </>
        );
    }
}
