import {NyModal, SelectInput} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {resetAlertLimit} from '../../../stores/riskMonitor/riskMonitor.AlertLimit.actions';
import {resetBulkActionResults} from '../../../stores/riskMonitor/riskMonitor.bulkAction.actions';
import {hasPermissions} from '../../../utils/utilities';
import {RiskMonitorAlertLimit} from './riskMonitorAlertLimit.modal';
import {RiskMonitorBulkAction} from './riskMonitorBulkAction.modal';

@connect(['user', 'platform'], {resetAlertLimit, resetBulkActionResults})
export class RiskMonitorActionsModal extends React.Component {
    static propTypes = {
        crd: PropTypes.number.isRequired,
        mpid: PropTypes.string.isRequired,
        clearingNum: PropTypes.string.isRequired,
        alertLimit: PropTypes.number.isRequired,
        blocked: PropTypes.bool.isRequired,
        selected: PropTypes.string.isRequired,
        percentUsage: PropTypes.number.isRequired,
        netNotionalExecuted: PropTypes.number.isRequired,
        hideModal: PropTypes.func,
        show: PropTypes.bool
    };

    static defaultProps = {
        alertLimit: 0,
        crd: 0,
        mpid: '',
        show: false
    };

    state = {
        action: ''
    };

    componentDidUpdate({show}) {
        if (show && !this.props.show) {
            this.setState(
                {
                    action: ''
                },
                () => {
                    this.props.resetAlertLimit();
                    this.props.resetBulkActionResults();
                }
            );
        }
    }

    _onSelectAction = (action) => {
        this.setState({action});
    };

    render() {
        const canPerformBulkAction = hasPermissions(
            this.props.user,
            ['bulk risk actions - cancel | block | unblock'],
            [this.props.platform.mic]
        );

        const canUpdateAlertLimit = hasPermissions(
            this.props.user,
            ['update user risk alert limit'],
            [this.props.platform.mic]
        );

        return (
            <NyModal
                parentTitle='Risk Group Actions'
                title={this.props.mpid}
                hideModal={this.props.hideModal}
                show={this.props.show}>
                <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <div className='form-group'>
                            <SelectInput
                                name='action'
                                data-e2e-tag='rmtAction'
                                onChange={this._onSelectAction}
                                value={this.state.action}>
                                <option value=''>-- Select Actions --</option>
                                {canPerformBulkAction && (
                                    <option value='perform-bulk-action'>
                                        Perform Bulk Action
                                    </option>
                                )}
                                {canUpdateAlertLimit && (
                                    <option value='update-alert-limit'>
                                        Update Alert Limit
                                    </option>
                                )}
                            </SelectInput>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        {this.state.action === 'update-alert-limit' && (
                            <RiskMonitorAlertLimit
                                alertLimit={this.props.alertLimit}
                                crd={this.props.crd}
                                mpid={this.props.mpid}
                            />
                        )}

                        {this.state.action === 'perform-bulk-action' && (
                            <RiskMonitorBulkAction
                                crd={this.props.crd}
                                mpid={this.props.mpid}
                            />
                        )}
                    </div>
                </div>
            </NyModal>
        );
    }
}
