import {events} from '../events';

export const getUser = () => ({
    types: [events.USER_GET, events.USER_SUCCESS, events.USER_ERROR],
    payload: {
        client: 'node',
        request: {
            url: '/api/users/me'
        }
    }
});

export const getFirmAttributes = () => ({
    types: [
        events.USER_FIRM_ATTRIBUTES_GET,
        events.USER_FIRM_ATTRIBUTES_SUCCESS,
        events.USER_FIRM_ATTRIBUTES_ERROR
    ],
    payload: {
        client: 'sentinel',
        request: {
            url: 'firmAttributes'
        }
    }
});

export const userLogout = (accessToken) => ({
    types: [
        events.USER_LOGOUT_POST,
        events.USER_LOGOUT_SUCCESS,
        events.USER_LOGOUT_ERROR
    ],
    payload: {
        client: 'sentinel',
        request: {
            url: '/oauth/logout',
            method: 'POST',
            data: `accessToken=${accessToken}`
        }
    }
});

export const refreshToken = () => ({
    types: [
        events.USER_REFRESH_TOKEN_GET,
        events.USER_REFRESH_TOKEN_SUCCESS,
        events.USER_REFRESH_TOKEN_ERROR
    ],
    payload: {
        client: 'node',
        request: {
            url: '/auth/refreshToken'
        }
    }
});

export const resetUser = () => ({
    type: events._SYNC_FLUSH_
});

export const setUser = (props) => ({
    type: events.USER_SET,
    payload: {
        ...props
    }
});
