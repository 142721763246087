export const myVolumesTradeCountsColumnDefs = [
    {
        headerName: 'All Tapes',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'T+1',
                field: 'allTapesPrevDay',
                type: 'number',
                width: 100
            },
            {
                headerName: 'MTD',
                field: 'allTapesMtd',
                type: 'number',
                width: 125
            },
            {
                headerName: 'ADV',
                field: 'allTapesAdv',
                type: 'number',
                width: 125
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Tape A',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'T+1',
                field: 'tapeAPrevDay',
                type: 'number',
                width: 100
            },
            {
                headerName: 'MTD',
                field: 'tapeAMtd',
                type: 'number',
                width: 100
            },
            {
                headerName: 'ADV',
                field: 'tapeAAdv',
                type: 'number',
                width: 100
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Tape B',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'T+1',
                field: 'tapeBPrevDay',
                type: 'number',
                width: 100
            },
            {
                headerName: 'MTD',
                field: 'tapeBMtd',
                type: 'number',
                width: 100
            },
            {
                headerName: 'ADV',
                field: 'tapeBAdv',
                type: 'number',
                width: 100
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Tape C',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'T+1',
                field: 'tapeCPrevDay',
                type: 'number',
                width: 100
            },
            {
                headerName: 'MTD',
                field: 'tapeCMtd',
                type: 'number',
                width: 100
            },
            {
                headerName: 'ADV',
                field: 'tapeCAdv',
                type: 'number',
                width: 100
            }
        ],
        marryChildren: true
    }
];
