import pathToRegex from 'path-to-regexp';
import qs from 'qs';
import {useMemo} from 'react';
import {matchPath} from 'react-router';
import {useHash} from 'react-use';

import {routesConfig} from '../configs/routes.config';

type Route = {
    glossary: {
        title: string;
        description: string;
        terms: {
            term: string;
            description: string;
            example?: string;
        }[];
    };
    params: {
        [name: string]: any;
    };
    path: string;
    platform: string;
    showBanner: boolean;
    title: string;
    url: string;
};

export default function useRoute(): Route {
    const [hash] = useHash();

    const path = useMemo(() => hash.replace(/#/, '').split('?')[0], [hash]);

    const routeParams = useMemo(
        () => hash.replace(/#/, '').split('?')[1] || '',
        [hash]
    );

    const route = useMemo(
        () =>
            routesConfig.find((r) => matchPath(path, {...r, path: r.url})) || {
                url: path
            },
        [path]
    );

    const pathParams = useMemo(
        () =>
            pathToRegex
                .parse(route.url)
                .filter((part) => typeof part === 'object') as {name: string}[],
        [route.url]
    );

    const pathParamValues = useMemo(
        () =>
            Array.from(pathToRegex(route.url).exec(path)).filter(
                (p, i) => i !== 0
            ),
        [path, route.url]
    );

    const queryParams = useMemo(
        () => (routeParams ? qs.parse(routeParams) : {}),
        [routeParams]
    );

    const params = useMemo(
        () => ({
            ...(route.parseParams
                ? route.parseParams(queryParams)
                : queryParams),
            ...pathParams.reduce(
                (acc, {name}, i) => ({
                    ...acc,
                    [name]: pathParamValues[i]
                }),
                {}
            )
        }),
        [pathParams, pathParamValues, queryParams, route.parseParams]
    );

    return useMemo(
        () => ({
            ...route,
            glossary:
                typeof route.glossary === 'function'
                    ? route.glossary(params)
                    : route.glossary,
            title:
                typeof route.title === 'function'
                    ? route.title(params)
                    : route.title,
            url: path,
            path: route ? route.url : path,
            platform:
                route && route.url.includes(':platform')
                    ? pathToRegex(route.url).exec(path)[1]
                    : '',
            params
        }),
        [route.glossary, route.title, route.url, params, path]
    );
}
