import React from 'react';
import { useFormikContext } from 'formik';
import Button, { ButtonProps } from '@material-ui/core/Button';
import isEqual from 'react-fast-compare';

import useDebouncedMemo from 'nyse-web-tools-common/lib/react/hooks/useDebouncedMemo/useDebouncedMemo';
import {cleanObject} from '../../../utils/utilities';

const ClearPPButton: React.FC<Pick<ButtonProps, 'className' | 'color' | 'variant' | 'children'>> = React.memo((props) => {

    const formCtx = useFormikContext<any>();

    const hasContent = useDebouncedMemo(
        () => {
            if (formCtx.values.creditLimits[0]?.earlyLateMultiplier || formCtx.values.creditLimits[0]?.onCloseMultiplier )
                return true;
            if (formCtx.values.creditLimits.slice(1).find(e=>((e.limit !== undefined && e.limit !== '') ||
                (e.priceThreshold !== undefined && e.priceThreshold !==''))))
                return true;
            return false;
        }
        ,
        [
            formCtx.values
        ],
        500
    );

    const onClick = (event)=> {
        const creditLimits = formCtx.values.creditLimits.map((e) => {
           return Object.keys(e).reduce((acc, key) =>{
               let value = undefined;
               if (key === 'rangeId')
                   value = e.rangeId;
                return { ...acc, [key]: value};
           },{})
        });
        formCtx.setValues({
            ...formCtx.values,
            creditLimits
        })
        if (props.onClick)
            props.onnClick(event);
    };

    return (
        <Button
            data-e2e-tag='clearSettings'
            className={props.className}
            color={props.color}
            disabled={!hasContent}
            onClick={onClick}
            variant={props.variant}>
            {props.children}
        </Button>
    );
});

ClearPPButton.defaultProps = {
    children: 'Clear Settings',
    color: 'secondary',
    variant: 'contained'
};

export default ClearPPButton;
