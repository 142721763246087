import {Permissions} from '../../../resources/tradeSummary.resource';

const TRADE_ADJUST_TAB = {
    label: 'Adjust',
    value: 'ADJUST',
    permissions: [Permissions.adjustTradeSummary]
};
const TRADE_SPLIT_TAB = {
    label: 'Split',
    value: 'SPLIT',
    permissions: [Permissions.splitTradeSummary]
};
const TRADE_ALLOCATE_TAB = {
    label: 'Allocate',
    value: 'ALLOCATE',
    permissions: [Permissions.allocateTradeSummary]
};
const TRADE_UNALLOCATE_TAB = {
    label: 'Unallocate',
    value: 'UNALLOCATE',
    permissions: [Permissions.unallocateTradeSummary]
};

export const TRADE_ACTION_TABS = [
    TRADE_ADJUST_TAB,
    TRADE_SPLIT_TAB,
    TRADE_ALLOCATE_TAB,
    TRADE_UNALLOCATE_TAB
];
