import {LocalDateTime} from '@js-joda/core';

import {events} from '../events';

export const riskMonitorReducer = {
    [events.RISK_MONITOR_GET]: (state = {}) => ({
        ...state,
        isLoading: true
    }),
    [events.RISK_MONITOR_SUCCESS]: (
        state,
        {
            payload: {
                data,
                config: {
                    headers: {crds}
                }
            }
        }
    ) => ({
        isLoading: false,
        error: false,
        hasData: (data.data || []).length > 0,
        more: false,
        rowData: (data.data || []).map((row) => ({
            ...row,
            alertLimit: parseFloat(row.alertLimit),
            blocked: row.blocked === 'true',
            crd: crds[0],
            netNotionalExecuted: parseFloat(row.netNotionalExecuted),
            percentUsage: parseFloat(row.percentUsage)
        })),
        timestamp: LocalDateTime.now()
    }),
    [events.RISK_MONITOR_ERROR]: (state, {error}) => ({
        error,
        hasData: false,
        isLoading: false,
        more: false,
        rowData: [],
        timestamp: LocalDateTime.now()
    })
};
