import HowToRegSharp from '@material-ui/icons/HowToRegSharp';
import UndoTwoTone from '@material-ui/icons/UndoTwoTone';
import numbro from 'numbro';
import React from 'react';

import {TradeApprovalModal} from '../../components/modals/tradeSummary/tradeApproval.modal';
import {HintErrorTooltip} from '../../components/tooltips/HintErrorTooltip';
import {Permissions} from '../../resources/tradeSummary.resource';

export const tradeSummaryTransactionColumnDefs = [
    {
        cellRendererFramework: ({data}) => !!data?.hintErrorMessage ? <HintErrorTooltip hintErrorMessage={data?.hintErrorMessage} /> : <div />,
        field: 'hintError',
        headerName: '',
        pinned: 'left',
        sortable: false,
        lockPosition: true,
        width: 40
    },
    {
        field: '_selected_',
        pinned: 'left',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        permissions: [
            Permissions.contraApproveTradeSummary,
            Permissions.recallTradeSummary
        ],
        resizable: false,
        sorting: false,
        headerName: '',
        lockPosition: true,
        minWidth: 50,
        width: 50
    },
    {
        cellRendererParams: {
            Body: TradeApprovalModal,
            name: 'tradeApprovalModal',
            parentTitle: 'Trade Summary',
            title: 'Approval',
            size: 95
        },
        cellStyle: ({data}) => ({...(data?.allowedTradeActions?.length === 0 || data.status !== 'CO_APPROVAL_PENDING') && {display: 'none'}}),
        field: '_details_',
        permissions: [
            Permissions.adjustTradeSummary,
            Permissions.splitTradeSummary
        ],
        pinned: 'left',
        type: 'modal',
        sortable: false,
        headerName: '',
        lockPosition: true,
        minWidth: 50,
        width: 50
    },
    {
        field: 'recallApprove',
        pinned: 'left',
        resizable: false,
        headerName: '',
        lockPosition: true,
        sortable: false,
        minWidth: 50,
        width: 50,
        cellRendererFramework: ({data}) => {
            return data.allowedTradeActions && data.allowedTradeActions.includes('RECALL') ?
                <UndoTwoTone style={{color: '#00b9e4'}}/>
                // eslint-disable-next-line max-len
                : data.allowedTradeActions && data.allowedTradeActions.includes('CONTRA_APPROVE') ?
                    <HowToRegSharp style={{color: '#00b9e4'}}/> : <div></div>;
        }
    },
    {
        headerName: 'User Tx Id',
        field: 'userTxId',
        sortable: false
    },
    {
        headerName: 'Group Id',
        field: 'groupId',
        type: 'numericString'
    },
    {
        headerName: 'Status',
        field: 'status'
    },
    {
        headerName: 'User Action',
        field: 'userAction'
    },
    {
        headerName: 'Action',
        field: 'action'
    },
    {
        headerName: 'Updated Time',
        field: 'updatedTs',
        type: 'dateTime'
    },
    {
        headerName: 'Updated By',
        field: 'updatedBy'
    },
    {
        headerName: 'Reason',
        field: 'reason'
    },
    {
        headerName: 'Original Trade Date',
        field: 'origTdate',
        type: 'date'
    },
    {
        headerName: 'Event Type',
        field: 'evtTyp'
    },
    {
        headerName: 'Ref Exec Time',
        field: 'refExecTs',
        type: 'dateTime'
    },
    {
        headerName: 'Event Time',
        field: 'evtTs',
        type: 'dateTime'
    },
    {
        headerName: 'Common',
        headerClass: 'text-center',
        children: [
            {
                headerName: 'Deal Id',
                field: 'dealNum',
                type: 'numericString'
            },
            {
                headerName: 'Original Deal Id',
                field: 'dealIdOrig',
                type: 'numericString'
            },
            {
                headerName: 'Ref Deal Id',
                field: 'dealIdRef',
                type: 'numericString'
            },
            {
                field: 'symb',
                headerName: 'OSI Symbol'
            },
            {
                field: 'osiRootSymb',
                headerName: 'OSI Root Symbol'
            },
            {
                field: 'expirationDate',
                headerName: 'Expiration Date',
                type: 'date'
            },
            {
                field: 'putCall',
                headerName: 'Put/Call'
            },
            {
                field: 'strikePrice',
                headerName: 'Strike Price',
                type: 'price',
                cellRenderer: ({data}) =>
                    data.strikePrice === undefined
                        ? ''
                        : numbro(data.strikePrice).format({
                            mantissa: 8,
                            thousandSeparated: true
                        })
            },
            {
                field: 'execPrc',
                headerName: 'Price',
                type: 'price',
                cellRenderer: ({data}) =>
                    data.execPrc === undefined
                        ? ''
                        : numbro(data.execPrc).format({
                            mantissa: 8,
                            thousandSeparated: true
                        })
            },
            {
                field: 'execQty',
                headerName: 'Qty',
                type: 'number'
            },
            {
                field: 'tradeSequenceNumber',
                headerName: 'OCC Seq #'
            },
            {
                field: 'underlyingSymbol',
                headerName: 'Underlying Symbol'
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Customer Side',
        headerClass: 'success text-center',
        children: [
            {
                field: 'custClOrdId',
                headerName: 'Client Order Id'
            },
            {
                field: 'custFirmId',
                headerName: 'MPID'
            },
            {
                field: 'custIdPub',
                headerName: 'Public Order Id'
            },
            {
                field: 'custLqdtyInd',
                headerName: 'Liq Ind'
            },
            {
                field: 'custSessionId',
                headerName: 'Session Id'
            },
            {
                field: 'custSide',
                headerName: 'Side'
            },
            {
                field: 'custSndrSubId',
                headerName: 'MMID'
            },
            {
                field: 'custSubmittingBkrId',
                headerName: 'Badge'
            },
            {
                field: 'custClearingNumber',
                headerName: 'Clearing #'
            },
            {
                field: 'custOpenCloseDisplay',
                headerName: 'Open/Close'
            },
            {
                field: 'custFirmDisplay',
                headerName: 'Customer or Firm'
            },
            {
                field: 'custCmta',
                headerName: 'CMTA'
            },
            {
                field: 'custAccount',
                headerName: 'Account'
            },
            {
                field: 'custTif',
                headerName: 'Time In Force'
            },
            {
                field: 'custCrossId',
                headerName: 'Cross Id'
            },
            {
                field: 'custCapStrategyId',
                headerName: 'Cap Strategy'
            },
            {
                field: 'custOptnlData',
                headerName: 'Optional Data'
            }
        ],
        marryChildren: true
    },
    {
        headerName: 'Contra Side',
        headerClass: 'danger text-center',
        children: [
            {
                field: 'contraOrdFirmId',
                headerName: 'MPID'
            },
            {
                field: 'contraOrdSndrSubId',
                headerName: 'MMID'
            },
            {
                field: 'contraOrdSubmittingBkrId',
                headerName: 'Badge'
            },
            {
                field: 'contraOrdClearingNumber',
                headerName: 'Clearing #'
            },
            {
                field: 'contraOrdCrossId',
                headerName: 'Cross Id'
            },
            {
                field: 'contraOrdCapStrategyId',
                headerName: 'Cap Strategy'
            },
            {
                field: 'contraOrdOptnlData',
                headerName: 'Optional Data'
            }
        ],
        marryChildren: true
    }
];
