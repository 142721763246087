import {Box} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {
    CheckboxInput,
    NySelectInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import type {NyFieldInput} from 'nyse-web-tools-common/lib/react/types';
import React, {useCallback, useMemo, useState} from 'react';
import {useToggle} from 'react-use';

import {useEntitlement} from '../../hooks/useEntitlement';
import {useRouteEntitlement} from '../../hooks/useRouteEntitlement';
import useUser from '../../hooks/useUser.hook';
import useUserFirms from '../../hooks/useUserFirms';
import type {Entitlement} from '../../types';

type FirmParticipantSelectProps = {
    crd: number;
    entitlements?: Entitlement[];
    mpidMust?: Entitlement[];
    disableInactiveControl?: boolean;
    hideEtpid?: boolean;
    isMulti?: boolean;
    showAll?: boolean;
    showInactive?: boolean;
} & Partial<NyFieldInput>;

export const FirmParticipantSelect = React.memo<FirmParticipantSelectProps>(
    (props) => {
        const userFirms = useUserFirms();
        const formik = useFormikContext();
        const entitlement = useEntitlement();
        const routeEntitlement = useRouteEntitlement();
        const user = useUser();

        const [hideInactive, toggleHideInactive] = useToggle(
            !props.showInactive || true
        );

        const firms = useMemo(
            () =>
                (userFirms.data ?? []).filter(
                    ({active}) => props.showInactive || active
                ),
            [userFirms.data, props.showInactive]
        );

        const entitlements = useMemo(
            () =>
                props.entitlements || [
                    'MEMBER_FIRM',
                    'IB_FIRM',
                    'TRADE_CHECKER'
                ],
            [props.entitlements]
        );

        const hasEntitlement = useMemo(
            () =>
                !routeEntitlement.length ||
                entitlements.includes(entitlement) ||
                entitlements.includes(user.entitlement),
            [entitlement, entitlements, user.entitlement, routeEntitlement]
        );

        const mpidRequired = useMemo(
            () => ((props.required === true)
                ? true
                :props.mpidMust !== undefined
                    && (props.mpidMust.includes(user.entitlement)
                        ||props.mpidMust.includes(entitlement))
            ),
            [entitlement, user.entitlement, props.required, props.mpidMust]
        );

        const [sortBy] = useState([
            ({label}) => label.includes('Inactive'),
            ({label}) => label.toLowerCase()
        ]);

        const filterOptions = useCallback(
            ({props: selectProps}) =>
                !hideInactive ||
                !props ||
                (selectProps.active &&
                    (!props.hideEtpid ||
                        !selectProps.type ||
                        selectProps.type === 'MPID')),
            [hideInactive, props]
        );

        const renderChildOption = useCallback(
            ({label, props}) => (
                <div className='flex-row flex-align-center flex-grow'>
                    <span className='ellipsis flex-grow'>
                        {label}&nbsp;&nbsp;
                    </span>
                    {!props.active && (
                        <small className='flex-pull-right text-warning ellipsis'>
                            Inactive
                        </small>
                    )}
                </div>
            ),
            []
        );

        const renderControls = useCallback(
            () => (
                <Box
                    borderTop='1px solid #cccccc'
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    pl={0.25}>
                    <CheckboxInput
                        className='warning'
                        name='hideInactive'
                        onChange={toggleHideInactive}
                        value={hideInactive}>
                        Hide Inactive
                    </CheckboxInput>
                </Box>
            ),
            [hideInactive, toggleHideInactive]
        );

        const renderParentOption = useCallback(
            ({label, props}) => (
                <div className='flex-row flex-align-center'>
                    <span className='ellipsis flex-1'>{label}&nbsp;&nbsp;</span>
                    {!props.active && (
                        <small className='flex-pull-right text-warning padding-right-1 flex-0'>
                            Inactive
                        </small>
                    )}
                </div>
            ),
            []
        );

        const participants = useMemo(
            () => firms.find(({crd}) => crd === props.crd)?.participants ?? [],
            [firms, props.crd]
        );

        const options = useMemo(
            () => [
                ...(props.isMulti || props.required
                    ? []
                    : [
                          {
                              label: '-',
                              value: '',
                              props: {
                                  active: true
                              }
                          }
                      ]),
                ...participants.map((participant) => ({
                    label: participant.id,
                    value: participant.id,
                    props: participant
                }))
            ],
            [participants, props.isMulti, props.required]
        );

        useMountedEffect(() => {
            formik.setFieldValue(
                props.name,
                props.initialValue || (props.isMulti ? [] : '')
            );
        }, [hasEntitlement, props.crd, props.isMulti]);

        return hasEntitlement ? (
            <NySelectInput
                controls={
                    props.disableInactiveControl ? undefined : renderControls
                }
                disabled={props.disabled}
                filter={filterOptions}
                initialValue={props.initialValue}
                isLoading={userFirms.isFetching}
                isMulti={props.isMulti}
                name={props.name}
                onChange={props.onChange}
                options={options}
                renderChildOption={renderChildOption}
                renderParentOption={renderParentOption}
                required={mpidRequired}
                sortBy={sortBy}
            />
        ) : (
            <NyUppercaseInput {...props} required={mpidRequired} name={props.name} />
        );
    }
);
