import {get, isNumber} from 'lodash-es';
import numbro from 'numbro';
import React from 'react';

import {events} from '../events';

export const riskMonitorBulkActionReducer = {
    [events.RISK_MONITOR_BULK_ACTION_POST]: (state = {}) => ({
        ...state,
        error: false,
        isLoading: true,
        warning: false,
        success: false
    }),
    [events.RISK_MONITOR_BULK_ACTION_SUCCESS]: (
        state,
        {
            payload: {
                data: {data},
                config: {
                    params: {action, confirmDelete}
                }
            },
            _state
        }
    ) => {
        const deletedOrders = get(
            data,
            'orderCancelResponse.deletedOrders',
            undefined
        );
        const isBulk = !['BLOCK', 'UNBLOCK'].includes(action);
        const hasDeletedOrdersCount = isNumber(deletedOrders);
        const isCountQuery = isBulk && !confirmDelete;

        return {
            ...state,
            error: false,
            isLoading: false,
            warning: isCountQuery ? (
                <>
                    This action will cancel an <em>estimated</em>&nbsp;
                    <strong>
                        {numbro(deletedOrders).format({
                            thousandSeparated: true
                        })}
                    </strong>
                    &nbsp; live orders on {_state.platform.name}
                </>
            ) : (
                false
            ),
            success: confirmDelete
                ? hasDeletedOrdersCount
                    ? `Successfully cancelled ${deletedOrders} orders on ${_state.platform.name}.`
                    : 'Your request was submitted successfully.'
                : false
        };
    },
    [events.RISK_MONITOR_BULK_ACTION_ERROR]: (
        state,
        {
            meta: {
                previousAction: {
                    payload: {
                        request: {
                            params: {action}
                        }
                    }
                }
            }
        }
    ) => {
        return {
            ...state,
            error: {
                title: '',
                detail: (
                    <>
                        <span>
                            {!['BLOCK', 'UNBLOCK'].includes(action)
                                ? 'An error has occurred trying to determine the number of orders impacted by this action.'
                                : 'An error has occurred while trying to submit your request.'}
                        </span>
                        <span>
                            Please contact <strong>crs@nyse.com</strong> to
                            report this issue.
                        </span>
                    </>
                )
            },
            warning: false,
            success: false,
            isLoading: false
        };
    },
    [events.RISK_MONITOR_BULK_ACTION_RESET_RESULTS]: (state) => ({
        ...state,
        error: false,
        isLoading: false,
        warning: false,
        success: false
    })
};
