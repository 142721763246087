import {TemporalAdjusters} from '@js-joda/core';
import {omit} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

const callMyVolumesFilters = (params, dispatch) => {
    dispatch({
        types: [
            events.MY_VOLUMES_FILTERS_GET,
            events.MY_VOLUMES_FILTERS_SUCCESS,
            events.MY_VOLUMES_FILTERS_ERROR
        ],
        payload: {
            client: 'cdmMw',
            request: {
                url: 'api/volume/filters',
                params: cleanObject({
                    ...omit(params, ['date']),
                    endDate:
                        params.date && !params.date.end
                            ? joda
                                  .toEndOfDay(params.date, true)
                                  .with(TemporalAdjusters.lastDayOfMonth())
                                  .toString() + 'Z'
                            : params.date && params.date.end
                            ? params.date.end
                                  .with(TemporalAdjusters.lastDayOfMonth())
                                  .toString() + 'Z'
                            : undefined,
                    startDate:
                        params.date && !params.date.start
                            ? joda
                                  .toStartOfDay(params.date)
                                  .with(TemporalAdjusters.firstDayOfMonth())
                                  .toString() + 'Z'
                            : params.date && params.date.start
                            ? params.date.start
                                  .with(TemporalAdjusters.firstDayOfMonth())
                                  .toString() + 'Z'
                            : undefined
                })
            }
        }
    });
};

export const getMyVolumesFilters = (params) => (dispatch) =>
    callMyVolumesFilters(params, dispatch);
