import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

@connect(['route'])
export class BannerHeader extends React.Component {
    render() {
        return (
            <>
                {this.props.route.showBanner ? (
                    <div className='home-banner img-fill' />
                ) : (
                    <span />
                )}
            </>
        );
    }
}
