import Grid from '@material-ui/core/Grid';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import NyTextInput from 'nyse-web-tools-common/lib/react/form/NyTextInput/NyTextInput';
import GridDivider from 'nyse-web-tools-common/lib/react/generic/GridDivider/GridDivider';
import React from 'react';

import {SubAccountContact} from '../../../resources/subAccountContact.resource';

export const SubAccountContactModal: React.FC<{
    values: SubAccountContact;
}> = React.memo(() => {
    return (
        <Grid container alignItems='flex-end' spacing={2}>
            <Grid item xs={12}>
                <label>First Name</label>
                <NyTextInput autoFocus name='firstName' required />
            </Grid>
            <Grid item xs={12}>
                <label>Last Name</label>
                <NyTextInput name='lastName' required />
            </Grid>
            <Grid item xs={12} component={NySubmitAlert} hasCloseBtn />
            <GridDivider />
            <Grid item xs={12}>
                <NySubmitBtn />
                <NyResetBtn />
            </Grid>
        </Grid>
    );
});
