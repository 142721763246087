import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { capitalize } from 'lodash-es';
import NyConfirmSubmitBtn from 'nyse-web-tools-common/lib/react/form/NyConfirmSubmitBtn/NyConfirmSubmitBtn';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyAlert from 'nyse-web-tools-common/lib/react/generic/NyAlert/NyAlert';
import GridDivider from 'nyse-web-tools-common/lib/react/generic/GridDivider/GridDivider';
import NyModal from 'nyse-web-tools-common/lib/react/generic/NyModal/NyModal';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import React, { useCallback, useState } from 'react';
import { useToggle } from 'react-use';

import { capitalizeRole, Entitlement, getRoleByEntitlement, SUPER_USER } from '../../../configs/entitlementRoleMap';
import useResource from '../../../hooks/useResource.hook';
import {
    approveReinstatementResource,
    reinstatementResource,
    RiskMonitorData
} from '../../../resources/riskMonitor.resource';

type ReinstatementModalProps = {
    context: {
        isOptionsMarket: boolean;
        userCrd: number;
        entitlement: Entitlement;
        onModalClosed(status: boolean): void;
    };
    data: RiskMonitorData;
};

export const ReinstatementModal: React.FC<ReinstatementModalProps> = (
    props
) => {
    const approveResource = useResource(approveReinstatementResource);
    const getResource = useResource(reinstatementResource);

    const [status, setStatus] = useState(false);
    const [show, toggleShow] = useToggle(false);

    const userCrdRole = getRoleByEntitlement(
        props.context.entitlement,
        props.context.isOptionsMarket,
        props.data.entityType
    );

    const isEntityCrdUsed = props.context.entitlement === SUPER_USER
        && props.data.entityType === 'BROKER_MPID_SUBID';

    const onSuccess = useCallback(
        (response, actions, values) => {
            setStatus(() => true);
            return getResource.get({
                userCrdRole,
                userCrd: isEntityCrdUsed ? props.data.entityCrd : props.data.userCrd,
                entityId: values.entityId
            });
        },
        [userCrdRole, getResource, props.data.userCrd]
    );

    const onSubmit = useCallback(
        ({breachType, entityId, ...values}) =>
            approveResource.put(
                {
                    ...values,
                    entityId
                },
                {
                    breachType
                }
            ),
        [approveResource]
    );

    useMountedEffect(() => {
        if (show) {
            setStatus(() => false);
            getResource.get({
                userCrdRole,
                userCrd: isEntityCrdUsed ? props.data.entityCrd : props.data.userCrd,
                entityId: props.data.entityId
            });
        } else {
            props.context.onModalClosed(status);
        }
    }, [show]);

    return (
        <>
            {props.data.displayReinstateStatus !== 'no' && (
                <Button
                    data-e2e-tag='preTradeReinstatementBtn'
                    data-display-risk-entity={props.data.displayRiskEntity}
                    data-name='reinstatement'
                    data-modal-name='reinstatementModal'
                    className='width-full'
                    color='primary'
                    onClick={toggleShow}
                    variant='contained'>
                    {props.data.displayReinstateStatus === 'approved'
                        ? 'STATUS'
                        : 'APPROVE'}
                </Button>
            )}
            <NyModal
                data-type='modal'
                className='small-medium'
                hideModal={toggleShow}
                name='reinstatement'
                show={show}
                parentTitle='Reinstatement'
                title={props.data.displayRiskEntity}>
                {getResource.pending &&
                <Typography>
                    Loading...
                </Typography>}
                {getResource.error ?
                    <NyAlert
                        data-e2e-tag='loadingAlert'
                        className='padding-0 space-0'
                        detail={getResource.error.detail}
                        show
                        title={getResource.error.title}
                        type='danger'
                    />
                    : (getResource.data.length > 0 ?
                            (<Grid container className='modal-body'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <strong>Risk User CRD</strong>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <strong>Type</strong>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <strong>Status</strong>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <strong>Action</strong>
                                        </Grid>
                                    </Grid>
                                    {getResource.data.map(
                                        ({
                                             breachType,
                                             riskEntity,
                                             entityId,
                                             displayReinstate,
                                             reinstateCrd,
                                             reinstateCrdRole,
                                             reinstateStatus
                                         }) => (
                                            <Grid
                                                container
                                                data-e2e-tag='reinstatementRow'
                                                key={reinstateCrd + reinstateCrdRole}
                                                spacing={2}>
                                                <GridDivider pt={0}/>
                                                <Grid item xs={12} md={3}>
                                                    {capitalize(reinstateCrd)}
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    {capitalizeRole(reinstateCrdRole)}
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    {capitalize(reinstateStatus)}
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    {displayReinstate && (
                                                        <NyForm
                                                            confirmMessage={`Do you want to approve ${riskEntity}`}
                                                            confirmText='APPROVE'
                                                            initialValues={{
                                                                breachType,
                                                                userCrd: reinstateCrd,
                                                                userCrdRole: reinstateCrdRole,
                                                                entityId
                                                            }}
                                                            onSubmit={onSubmit}
                                                            onSuccess={onSuccess}>
                                                            <NyConfirmSubmitBtn
                                                                data-e2e-tag={
                                                                    reinstateCrd +
                                                                    reinstateCrdRole
                                                                }>
                                                                Approve
                                                            </NyConfirmSubmitBtn>
                                                        </NyForm>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            ) : (
                                (!getResource.pending && <Grid>
                                    There are no pending approvals for this Risk Entity.
                                </Grid>)
                            )
                    )
                }
            </NyModal>
        </>
    );
};
