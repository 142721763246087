import { capitalize } from 'lodash-es';

export const MEMBER_FIRM = 'MEMBER_FIRM';
export const IB_FIRM = 'IB_FIRM';
export const SUPER_USER = 'SUPER_USER';
export const CLEARING_FIRM = 'CLEARING_FIRM';
export const BROKER_FIRM = 'BROKER_FIRM';
export const MM_FIRM = 'MM_FIRM';

export const ENTERING = 'entering';
export const CLEARING = 'clearing';
export const FLOOR_BROKER = 'floor_broker';
export const OPTIONS_MARKET_MAKER = 'options_mm';

export type Entitlement =
    | 'MEMBER_FIRM'
    | 'IB_FIRM'
    | 'SUPER_USER'
    | 'CLEARING_FIRM'
    | 'BROKER_FIRM'
    | 'MM_FIRM';

export type CrdRole = 'entering' | 'floor_broker' | 'clearing' | 'options_mm';

export type EntityType =
    | 'MPID'
    | 'SYMBOL_MPID'
    | 'MPID_SUBID'
    | 'SYMBOL_MPID_SUBID'
    | 'BROKER_MPID_SUBID'
    | 'MPID_MMID'
    | 'SYMBOL_MPID_MMID';

export const EntityTypeCrdRoleMap = {
    MPID: ENTERING,
    MPID_SUBID: ENTERING,
    SYMBOL_MPID: ENTERING,
    BROKER_MPID_SUBID: FLOOR_BROKER,
    MPID_MMID: OPTIONS_MARKET_MAKER,
    SYMBOL_MPID_MMID: OPTIONS_MARKET_MAKER
};

export const OptionsMarketMics = ['ARCO', 'AMXO'];

export default {
    MEMBER_FIRM: ENTERING,
    IB_FIRM: ENTERING,
    SUPER_USER: ENTERING,
    CLEARING_FIRM: CLEARING,
    BROKER_FIRM: FLOOR_BROKER,
    MM_FIRM: OPTIONS_MARKET_MAKER
};

export const mapEntitlementToCrdRole = (
    entitlement: Entitlement
): CrdRole | string =>
    entitlement === CLEARING_FIRM
        ? CLEARING
        : entitlement === BROKER_FIRM
        ? FLOOR_BROKER
        : entitlement === MM_FIRM
            ? OPTIONS_MARKET_MAKER
            : [MEMBER_FIRM, IB_FIRM, SUPER_USER].includes(entitlement)
                ? ENTERING
                : 'unknown entitlement';

export const getSuperUserRoleByEntityType = (
    entityType: EntityType, isOptionMarket: boolean
): CrdRole | string =>
    entityType === 'BROKER_MPID_SUBID'
        ? isOptionMarket ? ENTERING : FLOOR_BROKER
        : ['MPID_MMID', 'SYMBOL_MPID_MMID'].includes(entityType)
        ? OPTIONS_MARKET_MAKER
        : ['MPID', 'MPID_SUBID', 'SYMBOL_MPID'].includes(entityType)
            ? ENTERING
            : 'unknown entityType';

export const capitalizeRole = (role: string): string =>
    role === OPTIONS_MARKET_MAKER
        ? 'Market Maker'
        : role
            .split('_')
            .map((str) => capitalize(str))
            .join(' ');

export const getRoleByEntitlement = (
    entitlement: Entitlement,
    isOptionMarket?: boolean,
    entityType?: EntityType
): CrdRole | string =>
    isOptionMarket && entitlement === BROKER_FIRM
        ? ENTERING
        : entitlement === SUPER_USER && entityType
        ? getSuperUserRoleByEntityType(entityType, isOptionMarket)
        : mapEntitlementToCrdRole(entitlement);
