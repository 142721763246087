import {get} from 'lodash-es';
import {NyButton} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {DownloadProgress} from './download.progress';

@connect(['downloads'])
export class DownloadBtn extends React.PureComponent {
    static propTypes = {
        'data-e2e-tag': PropTypes.string,
        fileExtension: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        type: PropTypes.oneOf(['bar', 'circle'])
    };

    static defaultProps = {
        type: 'bar'
    };

    _onClick = () => {
        this.props.onClick({
            fileName: this.props.fileName,
            fileExtension: this.props.fileExtension
        });
    };

    render() {
        const download = get(this.props.downloads, this.props.fileName, {
            isDownloading: false,
            error: false,
            progress: 0
        });

        return (
            <>
                <NyButton
                    link
                    className='width-full'
                    data-e2e-tag={this.props['data-e2e-tag']}
                    disabled={download.isDownloading}
                    onClick={this._onClick}>
                    {this.props.children}
                </NyButton>
                <DownloadProgress
                    fileName={this.props.fileName}
                    type={this.props.type}
                />
            </>
        );
    }
}
