import {flatten} from 'lodash-es';
import {useEffect, useMemo} from 'react';

import {sndrSubIdResource} from '../resources/sndrSubId.resource';
import {
    AWAY_MARKET_MAKER,
    Entitlement
} from '../resources/tradeSummary.resource';
import {useEntitlement} from './useEntitlement';
import useResource from './useResource.hook';
import useUser from './useUser.hook';
import useUserFirms from './useUserFirms';

type UseSndrSubIdOptions = {
    sideCrd: number;
    firmId: string;
    tradeType: string;
    sndrSubId: string;
    csgCustFirm: string;
};

export function useSndrSubIdOptions(props: UseSndrSubIdOptions) {
    const userFirms = useUserFirms();
    const user = useUser();
    const entitlement = useEntitlement() || user.entitlement;

    const firms = useMemo(
        () => (userFirms.data ?? []).filter(({active}) => active),
        [userFirms.data]
    );
    const params = useMemo(
        () => ({
            crd: props.sideCrd,
            mpId: props.firmId
        }),
        [props.sideCrd, props.firmId]
    );
    const participants = useMemo(
        () => firms.find(({crd}) => crd === props.sideCrd)?.participants ?? [],
        [firms, props.sideCrd]
    );
    const resource = useResource(sndrSubIdResource);
    useEffect(() => {
        props.sideCrd && props.firmId && resource.get(params);
    }, [params, props.sideCrd, props.firmId]);
    const participantsWithSameFirmId = participants.filter(
        ({id}) => id == props.firmId
    );
    const mmIdsFromParticipantsWithSameFirmId = participantsWithSameFirmId.map(
        ({mmIds}) =>
            mmIds.map(({id}) => ({
                label: id,
                value: id
            }))
    );

    const entitledOptions = useMemo(
        () =>
            entitlement === Entitlement.MM_FIRM && resource.data
                ? resource.data.filter((option) =>
                      flatten(mmIdsFromParticipantsWithSameFirmId).some(
                          (mmId) => option.value === mmId.value
                      )
                  )
                : resource.data,
        [resource.data, entitlement, mmIdsFromParticipantsWithSameFirmId]
    );

    const options = useMemo(
        () =>
            props.sndrSubId
                ? entitledOptions.some(
                      (option) => option.value === props.sndrSubId
                  )
                    ? entitledOptions
                    : [
                          {
                              label: props.sndrSubId,
                              value: props.sndrSubId
                          },
                          ...entitledOptions
                      ]
                : entitledOptions,
        [entitledOptions, props.sndrSubId]
    );

    const optionsHandleLocalMm = useMemo(
        () =>
            props.csgCustFirm === AWAY_MARKET_MAKER ? entitledOptions : options,
        [options, entitledOptions, props]
    );
    return optionsHandleLocalMm;
}
