import {get, noop} from 'lodash-es';
import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import useStore from '../../hooks/useStore.hook';
import {getSymbolGroup} from '../../stores/symbolGroups/symbolGroups.actions';
import {RequestState} from '../../types';

interface SymbolGroup extends RequestState {
    ADVISOR: Options;
    FUND_NAME: Options;
    INDEX_1: Options;
    INDEX_2: Options;
    ARCA_ISSUER: Options;
    TAPE: Options;
    TRUST_NAME: Options;
}

type Options = {
    options: {
        label: string;
        value: string;
    }[];
};

export type Group =
    | ''
    | 'ADVISOR'
    | 'FUND_NAME'
    | 'INDEX_1'
    | 'INDEX_2'
    | 'ARCA_ISSUER'
    | 'TAPE'
    | 'TRUST_NAME';

export type SymbolGroupSelectProps = {
    disabled?: boolean;
    group: Group;
    isMulti?: boolean;
    onChange?: (params: any) => unknown;
    required?: boolean;
};

export const SymbolGroupSelect: FunctionComponent<SymbolGroupSelectProps> = ({
    group,
    disabled = false,
    onChange = noop,
    required = false,
    isMulti = false
}) => {
    const symbolGroups: SymbolGroup = useStore('symbolGroups');
    const dispatch = useDispatch();
    const labels = {
        ADVISOR: 'Advisors',
        FUND_NAME: 'Funds / Issue',
        INDEX_1: 'Additional Indices',
        INDEX_2: 'NYSE Equity Indices',
        ARCA_ISSUER: 'Issuers',
        TAPE: 'Tapes',
        TRUST_NAME: 'Trusts'
    };

    const uri = {
        ADVISOR: 'advisors',
        FUND_NAME: 'funds',
        INDEX_1: 'indexes',
        INDEX_2: 'indexes',
        ARCA_ISSUER: 'issuers',
        TAPE: 'tapes',
        TRUST_NAME: 'trusts'
    };

    useEffect(() => {
        group && dispatch(getSymbolGroup(uri[group]));
    }, [group]);

    const handleChange = ({value}) => {
        const options = symbolGroups[group].options.filter((option) =>
            value.includes(option.value)
        );

        onChange(options.map(({label}) => label));
    };

    const options = get(symbolGroups, `${group}.options`, []);

    return group ? (
        <>
            <label>{labels[group]}</label>
            <NySelectInput
                disabled={disabled || !options.length}
                isLoading={symbolGroups.isLoading}
                isMulti={isMulti}
                maxValues={5}
                name='symbolGroupValue'
                onChange={handleChange}
                options={options}
                required={required}
                showLabelAsValue
            />
        </>
    ) : null;
};
