import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {useDeskLogActions} from '../../hooks/useDeskLogActions.hook';

export const DeskLogActionsSelect = React.memo((props) => {
    const [actions, isLoading] = useDeskLogActions({client: props.client});

    const options = useMemo(
        () => [
            {
                label: '-',
                value: ''
            },
            ...actions
                // this is to avoid having to mock for each client
                .filter(
                    ({clientId}) =>
                        !clientId || clientId.replace(/-/, '_') === props.client
                )
                .map(({group, actions}) => ({
                    label: group,
                    options: actions.map((item) => ({
                        label: item,
                        value: item
                    }))
                }))
        ],
        [actions, props.client]
    );

    return (
        <NySelectInput
            initialValue={props.initialValue}
            name={props.name}
            isLoading={isLoading}
            onChange={props.onChange}
            options={options}
            readOnly={props.readOnly}
            required={props.required}
        />
    );
});

DeskLogActionsSelect.propTypes = {
    crd: PropTypes.number,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool
};
