import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import NyCheckboxInput from 'nyse-web-tools-common/lib/react/form/NyCheckboxInput/NyCheckboxInput';
import NyForm from 'nyse-web-tools-common/lib/react/form/NyForm/NyForm';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import React, { useCallback, useMemo, useState } from 'react';

import { capitalizeRole, ENTERING, Entitlement, FLOOR_BROKER } from '../../../../configs/entitlementRoleMap';
import useResource from '../../../../hooks/useResource.hook';
import {orderRestrictionResource} from '../../../../resources/riskSettings.resource';
import {riskSettingsTypeMap} from '../../../../configs/riskEntityHelper';
import isEqual from "react-fast-compare";
import {cleanObject} from "../../../../utils/utilities";


type OrderRestrictionFormProps = {
    classes: {
        alert: string;
        controls: string;
        form: string;
        formBody: string;
        radioBtns: string;
    };
    context: {
        entitlement: Entitlement;
        onModalClosed(status: boolean): void;
        isOptionsMarket: boolean;
    };
    className: string;
    data: any;
    onSuccess(isSuccessful: boolean);
};

export const OrderRestrictionForm: React.FC<OrderRestrictionFormProps> = React.memo((props) => {
    const resources = useResource(orderRestrictionResource);
    const { entityType, limitCrdRole } = props.data;

    const shouldInputRender = {
        maxDuplicativeOrders: entityType === 'MPID' || entityType === 'MPID_SUBID' ||
            (entityType === 'BROKER_MPID_SUBID') ||
            (limitCrdRole === 'options_mm' && entityType === 'MPID_MMID'),
        orderRateThresholdMaxOrders: entityType === 'MPID' || entityType === 'MPID_SUBID' ||
            (entityType === 'BROKER_MPID_SUBID') ||
            (limitCrdRole === 'options_mm' && entityType === 'MPID_MMID'),
        rejectSection: (entityType === 'MPID' || entityType === 'MPID_SUBID' || entityType === 'BROKER_MPID_SUBID')
            ? (props.context.isOptionsMarket ? ['rej_iso', 'rej_iso_day', 'rej_iso_ioc', 'rej_mkt_day','rej_moo_moc']
                : ['rej_iso', 'rej_iso_day', 'rej_iso_ioc', 'rej_mkt_day', 'rej_moo_moc', 'locate_broker', 'rej_early_sess', 'rej_late_sess','rej_sell_short','rej_sell_short_ex'])
            : (entityType === 'MPID_MMID' && limitCrdRole === 'options_mm')
                ? ['rej_iso', 'rej_iso_day', 'rej_iso_ioc', 'rej_mkt_day','rej_moo_moc']
                : (entityType === 'SYMBOL_MPID' || entityType === 'SYMBOL_MPID_SUBID')
                    ? props.context.isOptionsMarket
                        ? ['rej_rst_sym']
                        : ['rej_rst_sym','rej_sell_short','rej_sell_short_ex']
                    : ((entityType === 'SYMBOL_MPID_MMID' && limitCrdRole === 'options_mm') ? ['rej_rst_sym']:[])
    };

    const rejectDefValues = useMemo(() => {
            return shouldInputRender.rejectSection.reduce((a, riskType) => {
                let value = undefined;
                props.data.limits.some((setting) => {
                    if (setting.riskType === riskType) {
                        value = setting.activation === 'Y'
                    }
                });
                return { ...a, [riskType]: value};
            }, {});},
        [shouldInputRender.rejectSection, props.data.limits]);

    const maxDupOrdersDefValue = useMemo(()=> {
        let value = { window: undefined, limit: undefined};
        if (shouldInputRender.maxDuplicativeOrders) {
            props.data.limits.some((setting) => {
                if (setting.riskType === 'dupe_ord') {
                    value.window = setting.window !== undefined? setting.window : undefined;
                    value.limit = setting.limit !== undefined? setting.limit : undefined;
                }
            });
        }
        return {dupe_ord: value};
    }, [shouldInputRender.rejectSection, props.data.limits]);

    const orderRateThresholdMaxOrdersDefValue = useMemo(()=> {
        let value = { window: undefined, limit: undefined};
        if (shouldInputRender.orderRateThresholdMaxOrders) {
            props.data.limits.some((setting) => {
                if (setting.riskType === 'ord_rate') {
                    value.window = setting.window !== undefined? setting.window : undefined;
                    value.limit = setting.limit !== undefined? setting.limit : undefined;
                }
            });
        }
        return {ord_rate: value};
    }, [shouldInputRender.rejectSection, props.data.limits]);

    const rejectMenuItems = useMemo(() => (Array.isArray(shouldInputRender.rejectSection) ?
        shouldInputRender.rejectSection.map((e, idx) => (
            <div className='form-group-span' key={`reject_section_${idx}`} style={{width: '33.333333%'}}>
                <NyCheckboxInput name={`creditLimits.${e}`}>{riskSettingsTypeMap[e]}</NyCheckboxInput>
            </div>
        )) : []
    ), [shouldInputRender.rejectSection]);

    const [preValue, setPrevalue] = useState({
        ...maxDupOrdersDefValue,
        ...orderRateThresholdMaxOrdersDefValue,
        ...rejectDefValues
    });

    const [values, setValues] = useState({
        email: props.data.email,
        entityId: props.data.entityId,
        limitCrdRole:
            props.data.limitCrdRole === FLOOR_BROKER &&
            props.context.isOptionsMarket
                ? ENTERING
                : props.data.limitCrdRole,
        limitCrd: props.data.limitCrd,
        creditLimits: {
            ...maxDupOrdersDefValue,
            ...orderRateThresholdMaxOrdersDefValue,
            ...rejectDefValues
        }
    });

    const onChange = (values) => setValues(values);

    const generateResetRiskTypes = (newVal, oldVal) => {
        const resetSettings = [];
        Object.entries(newVal).map((field) => {
            const [key, value] = field;
            if (key === 'dupe_ord')      {
                // @ts-ignore
                if ((!value?.window || !value?.limit )
                    && (oldVal.dupe_ord?.window||oldVal.dupe_ord?.limit)) {
                    resetSettings.push(key);
                }
            } else if (key === 'ord_rate') {
                // @ts-ignore
                if ((!value?.window || !value?.limit) && (oldVal.ord_rate?.window || oldVal.ord_rate?.limit)) {
                    resetSettings.push(key);
                }
            } else if (!value && oldVal[key] === true) {
                resetSettings.push(key);

            }});
        return resetSettings;
    };

    const createCreditLimit = (creditLimits) =>
        Object.entries(creditLimits)
            .map((limit) => {
                const [key, value] = limit;
                return key === 'dupe_ord'
                    ? {
                        // @ts-ignore
                        riskType: !value?.window ? undefined : key,
                        // @ts-ignore
                        ...value
                    } : (
                        key === 'ord_rate' ? {
                            // @ts-ignore
                            riskType: !value?.window ? undefined : key,
                            // @ts-ignore
                            ...value
                        } : {
                            riskType: !value ? undefined: key,
                            // @ts-ignore
                            ...value
                        }
                    );
            })
            .filter(({riskType}) => !!riskType);


    const onSubmit = useCallback(
        (values) => {
            const resetRiskTypes = generateResetRiskTypes(
                values.creditLimits,
                {
                    ...preValue
                }
            );
            const creditLimits = createCreditLimit(values.creditLimits);
            return resources.put(
                {
                    ...values,
                    creditLimits,
                    resetRiskTypes
                },
                values.entityId
            );
        },
        [resources, values]
    );

    const onSuccess = useCallback((response, context, values) => {
        setPrevalue(values.creditLimits);
        if (props.onSuccess)
            props.onSuccess(response);

    }, [props, values]);

    const isChanged = useMemo(
        () => {
            return !isEqual(
                cleanObject({
                    ...preValue,
                    dupe_ord: cleanObject(preValue.dupe_ord),
                    ord_rate: cleanObject(preValue.ord_rate)
                }),
                cleanObject({
                    ...values.creditLimits,
                    dupe_ord: cleanObject(values.creditLimits.dupe_ord),
                    ord_rate: cleanObject(values.creditLimits.ord_rate)
                })
            );
        }, [values, preValue]);

    return (
        <NyForm
            data-e2e-tag='updateOrderRestrictionSettings'
            className={`${props.classes.form} ${props.className}`}
            initialValues={values}
            onChange={onChange}
            onSuccess={onSuccess}
            onSubmit={onSubmit}>
            <Grid className={props.classes.formBody} container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Box display='inline' fontWeight='fontWeightMedium'>
                        {capitalizeRole(props.data.limitCrdRole)} Firm:
                    </Box>
                    &nbsp;{props.data.limitCrd}
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {props.data.write && shouldInputRender.rejectSection.length != 0 && (
                    <>
                        <Grid item xs={12} className='flex-column flex-wrap' style={{height: '140px', alignContent: 'flex-start'}}>
                            {rejectMenuItems}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
                )}
                {props.data.write && shouldInputRender.maxDuplicativeOrders && (
                    <>
                        <Grid item xs={12}>
                            <div className='fontWeightRegular'>Max Duplicative Orders</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className='form-group'>
                                <label>Limit</label>
                                <NyNumberInput
                                    name='creditLimits.dupe_ord.limit'
                                    min={1}
                                    max={999}
                                    required = {!!values.creditLimits.dupe_ord.window}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={3}>
                            <div className='form-group'>
                                <label>Time Window (μs)</label>
                                <NyNumberInput
                                    name='creditLimits.dupe_ord.window'
                                    min={1}
                                    max={999999999}
                                    required = {!!values.creditLimits.dupe_ord.limit}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={5}/>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
                )}
                {props.data.write && shouldInputRender.orderRateThresholdMaxOrders && (
                    <>
                        <Grid item xs={12}>
                            <div className='fontWeightRegular'>Order Rate Threshold - Max Orders</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className='form-group'>
                                <label>Limit</label>
                                <NyNumberInput
                                    name='creditLimits.ord_rate.limit'
                                    min={1}
                                    max={999999999999}
                                    required = {!!values.creditLimits.ord_rate.window}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={3}>
                            <div className='form-group'>
                                <label>Time Window (μs)</label>
                                <NyNumberInput
                                    name='creditLimits.ord_rate.window'
                                    min={1}
                                    max={999999999}
                                    required = {!!values.creditLimits.ord_rate.limit}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={5}/>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    component={NySubmitAlert}
                    hasCloseBtn
                    className={props.classes.alert}
                />
                <Grid item xs={12} className={props.classes.controls}>
                    <NySubmitBtn
                        disabled={!isChanged}
                        className='space-right-1'/>
                </Grid>
            </Grid>
        </NyForm>
    );
});
