import {LocalDateTime} from '@js-joda/core';
import Grid from '@material-ui/core/Grid';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridProvider,
    AgGridTimestamp,
    NyModal,
    NyNumberInput
} from 'nyse-web-tools-common/lib/react';
import * as PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import useColumnDefs from '../../../hooks/useColumnDefs.hook';
import useResource from '../../../hooks/useResource.hook';
import liveVolResource from '../../../resources/liveVol.resource';
import {setColumns} from '../../../stores/columnDefs/columnDefs.actions';
import {OPRAExchangeSelect} from '../../selects/opraExchange.select';

const OPRASeqNumGrid = React.memo((props) => {
    const dispatch = useDispatch();
    const resource = useResource(liveVolResource);
    const columnDefs = useColumnDefs('opraSeqNumber');

    const gridCtx = useMemo(
        () => ({
            onSelect: props.onClose
        }),
        [props.onClose]
    );

    const initialFilters = useMemo(
        () => ({
            opraSeqNumber: props.opraSeqNumber
        }),
        [props.opraSeqNumber]
    );

    const timestamp = useMemo(() => LocalDateTime.now(), [
        resource.pending,
        resource.data
    ]);

    const onColumnsChange = useCallback(
        (columnDefs) => {
            dispatch(setColumns(columnDefs));
        },
        [dispatch]
    );

    const onClose = useCallback(() => {
        props.onClose();
    }, [props.onClose]);

    const onLoad = useCallback(
        (values) => {
            resource.get({
                opraId: props.opraId,
                opraSeqNumber: values.opraSeqNumber
            });
        },
        [props.opraId, resource.get]
    );

    return (
        <NyModal
            className='medium-large'
            hideModal={onClose}
            show={props.show}
            parentTitle='OPRA Entry'
            title='Select LiveVol Record'>
            <AgGridProvider
                autoSize
                columnDefs={columnDefs}
                columnNamespace='opraSeqNumber'
                enableFilters
                filtersClassName='space-bottom-1'
                initialFilters={initialFilters}
                isLoading={resource.pending}
                onColumnsChanged={onColumnsChange}
                onLoad={onLoad}
                rowData={resource.data}
                submitOnLoad
                timestamp={timestamp}>
                <AgGridProvider.Filters>
                    <Grid container alignItems='flex-end' spacing={2}>
                        <Grid item lg={2} md={3} sm={3} xs={6}>
                            <label>Sequence Number</label>
                            <NyNumberInput
                                includeThousandsSeparator={false}
                                maxLength={10}
                                name='opraSeqNumber'
                                required
                            />
                        </Grid>
                        <Grid item lg={2} md={3} sm={3} xs={6}>
                            <label>Qty</label>
                            <NyNumberInput name='tradeSize' />
                        </Grid>
                        <Grid item lg={2} md={3} sm={3} xs={6}>
                            <label>Exchange</label>
                            <OPRAExchangeSelect name='exchange' />
                        </Grid>
                        <Grid item lg={2} md={3} sm={3} xs={6}>
                            <label>Price</label>
                            <NyNumberInput
                                includeThousandsSeparator
                                name='tradePrice'
                                precision={6}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={12}
                            sm={6}
                            xs={6}
                            className='flex-row flex-align-bottom'>
                            <AgGridProvider.SubmitBtn className='space-right-1' />
                            <AgGridProvider.ResetBtn />
                            <AgGridColumnMenuTrigger className='flex-pull-right' />
                        </Grid>
                    </Grid>
                </AgGridProvider.Filters>

                <AgGrid context={gridCtx} />

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AgGridTimestamp />
                    </Grid>
                </Grid>
            </AgGridProvider>
        </NyModal>
    );
});

OPRASeqNumGrid.propTypes = {
    opraId: PropTypes.number.isRequired,
    opraSeqNumber: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

export default OPRASeqNumGrid;
