import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {formatNumber} from 'nyse-web-tools-common/lib/utils';
import React from 'react';

import Icons from '../../components/displays/icons';
import useDownload from '../../hooks/useDownload';

type DownloadLinkProps = {
    fileName?: string;
    title: string;
    type: string;
    url?: string;
};

const useStyles = makeStyles(() => ({
    link: {
        textTransform: 'none',
        // todo:: once css for anchor tags is removed; these can be removed
        '&:active': {
            textDecoration: 'none'
        },
        '&:focused': {
            textDecoration: 'none'
        },
        '&:visited': {
            textDecoration: 'none'
        }
    }
}));

export const DownloadLink: React.FC<DownloadLinkProps> = React.memo((props) => {
    const classes = useStyles(props);

    const download = useDownload(props.fileName, props.type);

    return (
        <Box
            display='flex'
            alignItems='center'
            mt={1}
            data-e2e-tag='downloadLink'
            data-file-name={props.fileName}
            data-href={props.url}
            data-name={props.title}
            data-type={props.url ? 'link' : props.type}>
            <Icons type={props.type} />
            <Button
                className={classes.link}
                color='secondary'
                disabled={download.pending}
                href={props.url}
                onClick={props.url ? undefined : download.start}
                target={props.url ? '_blank' : undefined}
                variant='text'>
                {props.title}
            </Button>
            {download.pending && (
                <Box
                    data-e2e-tag='documentLinkProgress'
                    data-progress={download.progress}
                    display='flex'
                    alignItems='center'
                    position='relative'>
                    <Box
                        position='absolute'
                        display='flex'
                        alignItems='center'
                        lineHeight={1}
                        fontSize='80%'
                        justifyContent='center'
                        width={22}
                        height={22}>
                        {formatNumber(download.progress)}
                    </Box>
                    <CircularProgress
                        color='primary'
                        size={22}
                        variant='static'
                        value={download.progress}
                    />
                </Box>
            )}
        </Box>
    );
});
