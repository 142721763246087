import {connect} from 'nyse-web-tools-common/lib/utils';
import React from 'react';

@connect(['coreSession'])
export class MarketWideCircuitBreaker extends React.PureComponent {
    render() {
        return (
            <>
                {this.props.coreSession.hasMwCbAlert && (
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 space-bottom-1'>
                        <div
                            className='alert-business space-0'
                            data-e2e-tag='coreSessionMWCBAlert'>
                            A Market Wide Circuit Breaker event is in effect.
                            Trading has been halted for{' '}
                            {this.props.coreSession.totalHalts} symbols.
                        </div>
                    </div>
                )}
            </>
        );
    }
}
