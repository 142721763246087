import React from 'react';

export const authThresholdError = {
    title: 'Session Expiration Pending',
    detail: (
        <span>
            Your session is about to expire, please click refresh button to
            continue.
        </span>
    ),
    type: 'info'
};
