const origins = {
    localhost: 'dev',
    '127.0.0.1': 'dev',
    'top.dv1.ckd.intcx.net': 'dev',
    'top2.dv1.ckd.intcx.net': 'dev',
    'top.dv2.ckd.intcx.net': 'dev',
    'top2.dv2.ckd.intcx.net': 'dev',
    'top.qa1.ckt.intcx.net': 'qa',
    'top2.qa1.ckt.intcx.net': 'qa',
    'top.qa2.ckt.intcx.net': 'qa',
    'top2.qa2.ckt.intcx.net': 'qa',
    'top-qa3.ckd.intcx.net': 'qa',
    'top2-qa3.ckd.intcx.net': 'qa',
    'top.qa4.ckd.intcx.net': 'qa',
    'top2.qa4.ckd.intcx.net': 'qa',
    'top.qa5.ckd.intcx.net': 'qa',
    'top2.qa5.ckd.intcx.net': 'qa',
    'top-qa6.ckd.intcx.net': 'qa',
    'top2-qa6.ckd.intcx.net': 'qa',
    'top-qa7.ckd.intcx.net': 'qa',
    'top2-qa7.ckd.intcx.net': 'qa',
    'top-qa8.ckd.intcx.net': 'qa',
    'top2-qa8.ckd.intcx.net': 'qa',
    'top.pt.ckt.intcx.net': 'qa',
    'top2.pt.ckt.intcx.net': 'qa',
    'top.ut.intcx.net': 'cert',
    'top.uat.nyse.com': 'cert',
    'top2.uat.nyse.com': 'cert',
    'top.nyse.com': 'prod',
    'top2.nyse.com': 'prod'
};

const UIDS = {
    dev: 'G-K8K8MZZCGB',
    qa: 'G-H7GLSQFW6J',
    cert: 'G-25G93EJK6S',
    prod: 'G-YLDXTHDZH7'
};

const environmentType =
    origins[
        Object.keys(origins).find((key) => window.location.origin.includes(key))
    ];

const {
    location: {hostname, port, protocol}
} = window;

export default Object.freeze({
    baseURL: `${protocol}//${hostname}${port && ':' + port}`,
    // @ts-ignore
    buildTime: window.BUILD_TIME || new Date().toUTCString(),
    cacheMaxAge: 1000 * 60 * 60 * 2,
    // @ts-ignore
    versionNumber: window.VERSION_NUMBER,
    devEnv: process.env.NODE_ENV === 'development',
    environmentType,
    environmentUID: UIDS[environmentType],
    integrationEnv:
        process.env.NODE_ENV === 'test' && process.env.INTEGRATION_ENV,
    testEnv: process.env.NODE_ENV === 'test' && !process.env.INTEGRATION_ENV,
    websocketNotifications: ['localhost', '127.0.0.1'].includes(hostname)
        ? 'wss://top.dv2.ckd.intcx.net/cdmmw/notifications'
        : `wss://${hostname}${port ? ':' + port : ''}/cdmmw/notifications`
});
