import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridProvider,
    AgGridTimestamp
} from 'nyse-web-tools-common/lib/react';
import AgGridAutoRefresh from 'nyse-web-tools-common/lib/react/grid/AgGridAutoRefresh/AgGridAutoRefresh';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {EntitlementSelect} from '../../components/selects/entitlement.select';
import {FirmSelect} from '../../components/selects/firm.select';
import {transactionDashboardColumnDefs} from '../../configs/columnDefs/transactionDashboard.columnDefs';
import {useEntitlement} from '../../hooks/useEntitlement';
import useResource from '../../hooks/useResource.hook';
import useUserFirms from '../../hooks/useUserFirms';
import {transactionActivityResource} from '../../resources/transactionActivity.resource';
import {Link} from '../navigation/navigation.link';

const useStyles = makeStyles(() => ({
    hideAutoRefresh: {
        display: 'none'
    }
}));

export const TransactionDashboardPanel = React.memo((props) => {
    const classes = useStyles(props);
    const userFirms = useUserFirms();
    const entitlement = useEntitlement();
    const firms = useMemo(
        () => (userFirms.data ?? []).filter(({active}) => active),
        [userFirms.data]
    );
    const [selectedCrd, setSelectedCrd] = useState(null);

    const params = useMemo(
        () => ({
            crd: selectedCrd,
            entitlement: entitlement
        }),
        [selectedCrd, entitlement]
    );
    const resource = useResource(transactionActivityResource);

    const onFirmSelect = ({values}) => {
        setSelectedCrd(values.crd);
    };

    const onLoad = useCallback(() => {
        if (selectedCrd) {
            resource.get(params);
        }
        if (firms.length === 1) {
            setSelectedCrd(firms[0]?.crd);
        }
    }, [selectedCrd, firms, resource]);
    useEffect(() => {
        if (selectedCrd) {
            resource.get(params);
        }
    }, [selectedCrd]);

    return (
        <>
            {firms && (
                <AgGridProvider
                    columnNamespace='transactionDashboardData'
                    columnDefs={transactionDashboardColumnDefs}
                    error={resource.error}
                    isLoading={resource.pending}
                    onLoad={onLoad}
                    rowData={resource.data.rowData}
                    autoRefreshInterval={10000}
                    submitOnLoad
                    timestamp={resource.data.timeStamp}>
                    <div className='panel-primary panel height-full'>
                        <div className='panel-heading flex-row flex-grow flex-align-center'>
                            <span className='panel-title'>
                                Transaction Dashboard
                            </span>

                            <AgGridColumnMenuTrigger
                                className='mdi mdi-settings mdi-18px flex-pull-right'
                                component={<a />}
                            />
                        </div>

                        <div className='row flex-0-0 space-top-1 space-bottom-1 space-left-1 space-right-1'>
                            <EntitlementSelect
                                className='form-group col-xs-12 col-sm-6 col-md-6 col-lg-6'
                                name='entitlement'
                            />
                            {firms.length > 1 && (
                                <div className='form-group col-xs-12 col-sm-6 col-md-6 col-lg-6'>
                                    <label>Firm</label>
                                    <FirmSelect
                                        name='crd'
                                        onChange={onFirmSelect}
                                        showInactive
                                    />
                                </div>
                            )}
                        </div>

                        <div className='panel-body'>
                            <AgGrid noRecordsMessage={'No records found.'} />
                        </div>
                        <div className='panel-footer flex-row flex-align-center'>
                            <AgGridTimestamp className='panel-footer-timestamp' />
                            <div className={classes.hideAutoRefresh}>
                                <AgGridAutoRefresh
                                    initialAutoRefresh={true}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </AgGridProvider>
            )}
        </>
    );
});
