import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridPagination,
    AgGridProvider,
    AgGridToggleShowFilters,
    NyDateTimeRangeInput,
    NySelectInput,
    NyTextInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {DeskLogActionsSelect} from '../components/selects/deskLogActions.select';
import {FirmSelect} from '../components/selects/firm.select';
import {getActivityReport} from '../stores/activityReport/activityReport.actions';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';

@connect(['activityReport', 'columnDefs', 'firms', 'platform', 'route'], {
    setColumns,
    getActivityReport
})
export class ActivityReportPage extends React.PureComponent {
    state = {
        filterValues: {}
    };

    _initialFilters = {
        crd: this.props.route.params.crd || (this.props.firms[0] || {}).crd,
        startTime: joda.startOfDay(),
        endTime: joda.endOfDay(true),
        client: ''
    };

    _initialPagination = {
        limit: 100,
        offset: 0
    };

    _onFiltersChanged = (filterValues) => {
        this.setState({
            filterValues
        });
    };

    render() {
        return (
            <AgGridProvider
                columnNamespace='activityReport'
                columnDefs={this.props.columnDefs.activityReport}
                initialFilters={this._initialFilters}
                initialPagination={this._initialPagination}
                onFiltersChanged={this._onFiltersChanged}
                onLoad={this.props.getActivityReport}
                onColumnsChanged={this.props.setColumns}
                submitOnLoad={false}
                {...this.props.activityReport}>
                <AgGridProvider.Filters>
                    {this.props.firms.length > 1 && (
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Firm</label>
                            <FirmSelect
                                name='crd'
                                required
                                showInactive={true}
                            />
                        </div>
                    )}
                    <div className='form-group col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                        <label>Client</label>
                        <NySelectInput name='client'>
                            <option value=''>-</option>
                            <option value='BPLX_01'>BPLEX</option>
                            <option value='CDM_01'>TOP</option>
                        </NySelectInput>
                    </div>
                    <div className='form-group col-xs-12 col-sm-6 col-md-6 col-lg-4'>
                        <label>Time of Action</label>
                        <NyDateTimeRangeInput
                            className='form-control'
                            startName='startTime'
                            endName='endTime'
                            required
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                        <label>User Id</label>
                        <NyTextInput name='userId' />
                    </div>
                    <div className='form-group col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                        <label>User Name</label>
                        <NyTextInput name='userName' />
                    </div>
                    <div className='form-group col-xs-12 col-sm-8 col-md-5 col-lg-4'>
                        <label>Action</label>
                        <DeskLogActionsSelect
                            client={this.state.filterValues.client}
                            name='action'
                        />
                    </div>
                </AgGridProvider.Filters>
                <div className='row space-bottom-1'>
                    <div className='col-md-12 col-lg-122 col-sm-12 col-xs-12'>
                        <div className='flex-row flex-align-bottom'>
                            <AgGridProvider.SubmitBtn>
                                Search
                            </AgGridProvider.SubmitBtn>
                            <AgGridProvider.ResetBtn>
                                Reset
                            </AgGridProvider.ResetBtn>
                            <div className='btn-circle-grp flex-pull-right'>
                                <AgGridToggleShowFilters />
                                <AgGridExportMenu
                                    exportFileName='activityReport'
                                    exportSheetName={this.props.platform.name}
                                />
                                <AgGridColumnMenuTrigger />
                            </div>
                        </div>
                    </div>
                </div>

                <AgGrid />

                <AgGridPagination>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={250}>250</option>
                </AgGridPagination>
            </AgGridProvider>
        );
    }
}
