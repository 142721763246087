import {events} from '../events';

export const getCoreSession = (cmd) => ({
    types: [
        events.CORE_SESSION_GET,
        events.CORE_SESSION_SUCCESS,
        events.CORE_SESSION_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: cmd === 'state'
                    ? '/api/symbolsStates'
                    : cmd === 'halted' ? '/api/haltedSymbols':'/api/coreTradingVolumeAndTrades',
            isPillarSource: true
        }
    }
});

export const resetCoreSession = () => ({
    type: events.CORE_SESSION_RESET
});
