import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getActivityReport = (params) => ({
    types: [
        events.ACTIVITY_REPORT_GET,
        events.ACTIVITY_REPORT_SUCCESS,
        events.ACTIVITY_REPORT_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/account-activities',
            method: 'GET',
            params: cleanObject({
                ...params,
                startTime: params.startTime.toString(),
                endTime: params.endTime.toString()
            })
        }
    }
});
