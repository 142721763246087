import {getRangeLabel} from "../../../../utils/utilities";

export const formatPriceProtectionCreditLimit = (limits, labels, type) => {

    const creditLimits = [];
    const count = labels === undefined || labels.length == 0 ? 1 : labels.length;
    for (let c = 0; c != count; c++)
        creditLimits[c] = {
            rangeId: c,
            limit: undefined,
            priceThreshold: undefined
        };

    limits.filter(({riskType}) => riskType === type).forEach((limit) => {
        if (limit.rangeId === undefined && limit.rangeLow === undefined && limit.rangeHigh === undefined) {
            creditLimits[0] = {
                earlyLateMultiplier:  limit.earlyLateMultiplier !== undefined ? +limit.earlyLateMultiplier : undefined,
                onCloseMultiplier:  limit.onCloseMultiplier !== undefined ? +limit.onCloseMultiplier : undefined
            }
        } else {
            creditLimits[limit.rangeId] = {
                ...creditLimits[limit.rangeId],
                rangeId: limit.rangeId,
                limit: limit?.limit,
                priceThreshold: limit?.priceThreshold !== undefined ? +limit?.priceThreshold : undefined
            };
            if (limit?.earlyLateMultiplier !== undefined) {
                creditLimits[0].earlyLateMultiplier = +limit.earlyLateMultiplier;
            }
            if (limit?.onCloseMultiplier !== undefined) {
                creditLimits[0].onCloseMultiplier = +limit.onCloseMultiplier;
            }
        }
    });
    return creditLimits;

};

export const formatPriceProtectionRangeLabels = (ranges) => {

    const labels = [];
    (ranges || []).forEach((range) => {
        labels[range.id] = {
            id: range.id,
            label: getRangeLabel(range.low, range.high),
            percentage: range.percentage,
            priceThreshold: range.priceThreshold
        };
    });

    return labels;

};

export const formatOrderAdvCreditLimit = (limits, labels) => {

    const creditLimits = [];
    const count = labels === undefined || labels.length == 0? 1 : labels.length;
    for (let c = 0; c != count; c++)
        creditLimits[c] = {
            rangeId: c,
            limit: undefined,
            limitLow : undefined
        };

    limits.filter(({riskType})=>riskType ==='ord_qty_adv').forEach((limit)=>{
        if (limit.rangeId === undefined && limit.rangeLow === undefined && limit.rangeHigh === undefined) {
            creditLimits[0] = {
                ...creditLimits[0],
                limit: limit?.limit !== undefined ? +limit?.limit : undefined,
                limitLow :limit?.limitLow !== undefined ?  +limit?.limitLow : undefined
            }
        }
        else {
            creditLimits[limit.rangeId] = {
                ...creditLimits[limit.rangeId],
                rangeId: limit.rangeId,
                limit: limit?.limit !== undefined ? +limit?.limit : undefined,
                limitLow: limit?.limitLow !== undefined ? +limit?.limitLow : undefined
            };
            if (limit?.limitLow !== undefined) {
                creditLimits[0].limit = +limit.limitLow;
            }
        }
    });
    return creditLimits;

};

export const formatOrderAdvRangeLabels = (ranges) => {

    const labels = [];

    (ranges ||[]).forEach((range)=> {
        labels[range.id] = {
            id: range.id,
            label: getRangeLabel(range.low, range.high)
        };
    });

    return labels;

};

