import {events} from '../events';

export const getMMTypes = () => (dispatch) => {
    dispatch({
        types: [
            events.MM_TYPES_GET,
            events.MM_TYPES_SUCCESS,
            events.MM_TYPES_ERROR
        ],
        payload: {
            client: 'cdmMw',
            request: {
                url: 'api/mm-types'
            }
        }
    });
};
