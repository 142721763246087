import React from 'react';
import {Link} from 'react-router-dom';

export class LandingPage extends React.PureComponent {
    render() {
        return (
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <Link className='btn btn-info btn-lg' to='/login'>
                        Login
                    </Link>
                </div>
            </div>
        );
    }
}
