import {useEffect} from 'react';

import deskLogActionsResource from '../resources/deskLogActions.resource';
import useResource from './useResource.hook';

export function useDeskLogActions(params) {
    const resource = useResource(deskLogActionsResource);

    useEffect(() => {
        resource.get(params);
    }, [params.client]);

    return [resource.data, resource.pending];
}
