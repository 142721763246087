import {NyForm, NyModal} from 'nyse-web-tools-common/lib/react';
import * as PropTypes from 'prop-types';
import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import {
    createOpraEntry,
    updateOpraEntry
} from '../../../stores/opraEntries/opraEntries.actions';
import OPRAEntryForm from './opraEntryForm';

export const OPRAEntryModal = React.memo((props) => {
    const dispatch = useDispatch();

    const [isSuccessful, setIsSuccessful] = useState(false);

    const initialValues = useMemo(
        () => ({
            opraEntryType: 'SEQUENCE',
            ...props.data
        }),
        [props.data]
    );

    const onClose = useCallback(() => {
        props.hideModal(isSuccessful);

        setIsSuccessful(() => false);
    }, [isSuccessful, props.hideModal]);

    const onSubmit = useCallback(
        (values) =>
            values.opraId
                ? dispatch(updateOpraEntry(values))
                : dispatch(createOpraEntry(values)),
        [dispatch]
    );

    const onSuccess = useCallback(() => {
        setIsSuccessful(() => true);
    }, []);

    return (
        <NyModal
            className='medium'
            hideModal={onClose}
            name='opraEntry'
            show={props.show}
            parentTitle='QCT Trades'
            title={`OPRA Entry${
                props.data.status ? ` / ${props.data.status}` : ''
            }`}>
            <NyForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                onSuccess={onSuccess}
                reset={props.show}
                style={{padding: 1}}>
                <OPRAEntryForm show={props.show} />
            </NyForm>
        </NyModal>
    );
});

OPRAEntryModal.propTypes = {
    data: PropTypes.shape({
        opraId: PropTypes.number,
        dealId: PropTypes.string,
        tradeExecTime: PropTypes.string,
        crd: PropTypes.number,
        opraEntryType: PropTypes.oneOf(['MANUAL', 'SEQUENCE']),
        opraSeqNumber: PropTypes.number,
        symbol: PropTypes.string,
        priceSpread: PropTypes.number,
        hedgeRatioPercentage: PropTypes.number,
        strategy: PropTypes.oneOf([
            'BW',
            'SPRD',
            'STDL',
            'RVSL',
            'CONV',
            'OTHR',
            'MA',
            'RA',
            'CST'
        ]),
        qctType: PropTypes.oneOf([
            'ETO',
            'OTO',
            'FLEX',
            'EQTY',
            'FUTR',
            'OTHR'
        ]),
        exchange: PropTypes.string,
        printTime: PropTypes.string,
        expirationDate: PropTypes.string,
        strikePrice: PropTypes.number,
        callPut: PropTypes.oneOf(['CALL', 'PUT', 'BOTH']),
        quantity: PropTypes.number,
        shortSale: PropTypes.oneOf(['UNK', 'LS', 'SSH', 'SSE']),
        status: PropTypes.oneOf([
            'OPEN',
            'COMPLETED',
            'MATCHING',
            'ACCEPTED',
            'REJECTED_NO_MATCH',
            'REJECTED_MULTIPLE_MATCHES'
        ]),
        comments: PropTypes.string,
        tplusNReconciliationAllowed: PropTypes.bool,
        dealIdOrig: PropTypes.string
    }),
    hideModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

OPRAEntryModal.defaultProps = {
    data: {}
};
