import Box from '@material-ui/core/Box';
import {useFormikContext} from 'formik';
import {
    NySelectInput,
    NyUppercaseInput,
    TextInput
} from 'nyse-web-tools-common/lib/react';
import React, {useCallback} from 'react';

import {
    CustFirm,
    custFirmChoices,
    CustomerSideSectionObject,
    keepAsIs,
    MultiEditCustomerSideSectionObject,
    openCloseChoices,
    stripCharsForAccountOptionalData,
    stripSpecialChars
} from '../../../../resources/tradeSummary.resource';
import {CapStrategyIdSelect} from '../../../selects/capStrategyId.select';
import {ClearingNumberSelect} from '../../../selects/clearingNumber.select';
import {NyMultiEditInputSelect} from '../../../selects/multiEditInputSelect.select';

export const MultiEditCustomerSideSection: React.FC<MultiEditCustomerSideSectionObject> = React.memo((props) => {
    const formik = useFormikContext<CustomerSideSectionObject>();

    const isLocalMM = formik.values.custFirm === CustFirm.MarketMaker;
    const isAwayMM = formik.values.custFirm === CustFirm.AwayMarketMaker;
    const isLocalOrAwayMM = isLocalMM || isAwayMM;
    const isInputDisabled = props.disabled || !props.isMultiEditForAllFields;

    const custFirmOptions = [...custFirmChoices, ...keepAsIs];
    const custOpenCloseOptions = [
        ...(isLocalOrAwayMM ? [{label: '-', value: ''}] : []),
        ...keepAsIs,
        ...openCloseChoices,
    ];
    const custSubmittingBkrIdOptions = [...props.custSubmittingBkrIdOptions, ...keepAsIs];

    const onCustSndrSubIdChange = useCallback(({value, setFieldValue}) => {
        setFieldValue('custSndrSubId', stripSpecialChars(value));
    }, []);

    const onCustCmtaChange = useCallback(({value, setFieldValue}) => {
        setFieldValue('custCmta', stripSpecialChars(value));
    }, []);

    const onCustAccountChange = useCallback(({value, setFieldValue}) => {
        setFieldValue('custAccount', stripCharsForAccountOptionalData(value));
    }, []);

    const onCustOptnlDataChange = useCallback(({value, setFieldValue}) => {
        setFieldValue('custOptnlData', stripCharsForAccountOptionalData(value));
    }, []);

    return (
        <div className='row' style={{width: '100%'}}>
            <Box className='col-xs-12 col-sm-12 col-md-12 col-lg-12' fontWeight='fontWeightMedium'>Customer Side (-)</Box>
            <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                <label>Customer or Firm</label>
                <NySelectInput
                    name='custFirm'
                    options={custFirmOptions}
                    disabled={isInputDisabled}
                />
            </div>
            <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                <label>MPID</label>
                <TextInput
                    name='custFirmId'
                    placeholder='- Keep as is -'
                    disabled={isInputDisabled}
                    readOnly
                    value=''
                />
            </div>
            <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                <label>MMID</label>
                {
                    formik.values.custFirm === 'Keep as is' ? (
                        <TextInput
                            name='custSndrSubId'
                            placeholder='- Keep as is -'
                            readOnly
                            value=''
                        />
                    ) : (
                        !props.disabled && isLocalMM ? (
                            <NySelectInput
                                name='custSndrSubId'
                                disabled={isInputDisabled}
                                options={props.custSndrSubIdOptions}
                                required
                            />
                        ) : (
                            <NyUppercaseInput
                                name='custSndrSubId'
                                disabled={isInputDisabled}
                                readOnly={!isAwayMM}
                                required={isAwayMM}
                                onChange={onCustSndrSubIdChange}
                            />
                        )
                    )
                }
            </div>
            <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                <label>Clearing #</label>
                {
                    !isInputDisabled ? (
                        <ClearingNumberSelect
                            name='custClearingNumber'
                            sideCrd={props.clearingNumberSideCrd}
                            firmId={props.custFirmId}
                            custFirm={formik.values.custFirm}
                            clearingNumber={props.custClearingNumber}
                            isLocalMM={isLocalMM}
                            isFloorBrokerOnBehalfOfLocalMM={false}
                            required={false}
                            isMultiAdjustment
                        />
                    ) : (
                        <TextInput
                            name='custClearingNumber'
                            placeholder='- Keep as is -'
                            readOnly={isLocalMM}
                            disabled
                            value=''
                        />
                    )
                }
            </div>
            <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                <label>Open/Close</label>
                <NySelectInput
                    name='custOpenClose'
                    disabled={isInputDisabled}
                    options={custOpenCloseOptions}
                />
            </div>
            <div className='form-group col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                <label>Badge</label>
                {
                    props.isFloorBroker ? (
                        <NySelectInput
                            name='custSubmittingBkrId'
                            disabled={isInputDisabled}
                            options={custSubmittingBkrIdOptions}
                        />
                    ) : (
                        <TextInput
                            name='custSubmittingBkrId'
                            placeholder='- Keep as is -'
                            disabled={isInputDisabled}
                            readOnly
                            value=''
                        />
                    )
                }
            </div>
            {props.isMultiAdjustmentCapStrategyIdValid ? (
                <>
                    <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                        <label>CMTA</label>
                        <NyMultiEditInputSelect
                            name='custCmta'
                            placeholder='- Keep as is -'
                            disabled={props.disabled}
                            maxLength={5}
                            onChange={onCustCmtaChange}
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                        <label>Account</label>
                        <NyMultiEditInputSelect
                            name='custAccount'
                            placeholder='- Keep as is -'
                            disabled={props.disabled}
                            maxLength={20}
                            onChange={onCustAccountChange}
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                        <label>Optional Data</label>
                        <NyMultiEditInputSelect
                            name='custOptnlData'
                            placeholder='- Keep as is -'
                            disabled={props.disabled}
                            maxLength={16}
                            onChange={onCustOptnlDataChange}
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                        <label>Cap Strategy Id</label>
                        <CapStrategyIdSelect
                            name='custCapStrategyId'
                            disabled={props.disabled}
                            isMultiAdjustment
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                        <label>CMTA</label>
                        <NyMultiEditInputSelect
                            name='custCmta'
                            placeholder='- Keep as is -'
                            disabled={props.disabled}
                            maxLength={5}
                            onChange={onCustCmtaChange}
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                        <label>Account</label>
                        <NyMultiEditInputSelect
                            name='custAccount'
                            placeholder='- Keep as is -'
                            disabled={props.disabled}
                            maxLength={20}
                            onChange={onCustAccountChange}
                        />
                    </div>
                    <div className='form-group col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                        <label>Optional Data</label>
                        <NyMultiEditInputSelect
                            name='custOptnlData'
                            placeholder='- Keep as is -'
                            disabled={props.disabled}
                            maxLength={16}
                            onChange={onCustOptnlDataChange}
                        />
                    </div>
                </>
            )}
        </div>
    );
});
