import {events} from '../events';

export const columnDefsReducer = {
    [events.COLUMN_DEFS_SAVE]: (state, action) => ({
        ...state,
        [action.payload.namespace]: {
            ...state[action.payload.namespace],
            saved: action.payload.columnDefs
        }
    })
};
