import {events} from '../events';

export const postBulkAction = ({mpid, bulkAction, confirmDelete = false}) => ({
    types: [
        events.RISK_MONITOR_BULK_ACTION_POST,
        events.RISK_MONITOR_BULK_ACTION_SUCCESS,
        events.RISK_MONITOR_BULK_ACTION_ERROR
    ],
    payload: {
        client: 'tdmMw',
        request: {
            url: 'risk-monitor/bulk-action',
            method: 'POST',
            params: {
                mpId: mpid,
                action: bulkAction,
                confirmDelete
            }
        }
    }
});

export const resetBulkActionResults = () => ({
    type: events.RISK_MONITOR_BULK_ACTION_RESET_RESULTS
});
