import cn from 'classnames';
import React from 'react';

import {ActivityReportModal} from '../../components/modals/activityReport/activityReport.modal';

export const activityReportColumnDefs = [
    {
        field: '_details_',
        headerName: '',
        pinned: 'left',
        resizable: false,
        width: 50,
        cellRendererFramework: ({data}) => <ActivityReportModal {...data} />,
        maxWidth: 50,
        minWidth: 50
    },
    {
        field: 'createdTime',
        headerName: 'Time of Action',
        sort: 'desc',
        type: 'dateTime'
    },
    {
        field: 'userName',
        headerName: 'User Name',
        width: 140
    },
    {
        field: 'userId',
        headerName: 'User Id',
        width: 200
    },
    {
        field: 'client',
        headerName: 'Client',
        width: 75
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 250
    },
    {
        field: 'status',
        headerName: 'Result',
        cellRendererFramework: ({value}) => (
            <>
                <i
                    className={cn('mdi mdi-18px', {
                        'mdi-checkbox-marked-circle-outline text-success':
                            value === 'ok',
                        'mdi-alert-circle-outline text-danger': value !== 'ok'
                    })}
                />
                &nbsp;{value}
            </>
        ),
        valueGetter: ({data: {status}}) => (status || '').trim().toLowerCase(),
        width: 100
    }
];
