import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import React, {useMemo} from 'react';

import useResource from '../../hooks/useResource.hook';
import {capStrategyIdResource} from '../../resources/capStrategyId.resource';
import {keepAsIs} from '../../resources/tradeSummary.resource';

type CapStrategyIdSelectProps = {
    disabled?: boolean;
    isMultiAdjustment?: boolean;
    name: string;
    required?: boolean;
    onChange?: any;
    resetFields?: string[];
};

export const CapStrategyIdSelect = React.memo<CapStrategyIdSelectProps>(
    (props) => {
        const resource = useResource<{label: string; value: string}[]>(
            capStrategyIdResource,
            true
        );
        const options = useMemo(
            () => [{label: '-', value: ''}, ...resource.data, ...(!!props.isMultiAdjustment ? keepAsIs : [])],
            [resource.data]
        );
        return (
            <NySelectInput
                disabled={props.disabled}
                isLoading={resource.pending}
                onChange={props.onChange}
                name={props.name}
                options={options}
                resetFields={props.resetFields}
                required={props.required}
            />
        );
    }
);
