import Box from '@material-ui/core/Box';
import {noop} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridProvider
} from 'nyse-web-tools-common/lib/react';
import React, {useMemo, useRef} from 'react';

import {symbolStatesColumnDefs} from '../../configs/columnDefs/symbolStates.columnDefs';
import useStore from '../../hooks/useStore.hook';

export function SymbolStatesPanel(): JSX.Element {
    const coreSession = useStore('coreSession');

    const columnDefs = useRef({default: symbolStatesColumnDefs});

    const autoGroupColumnDefProps = useMemo(
        () => ({
            minWidth: 100
        }),
        []
    );

    return (
        <AgGridProvider
            columnNamespace='symbolStates'
            columnDefs={columnDefs.current}
            error={coreSession.error}
            isLoading={coreSession.isLoading}
            onLoad={noop}
            primaryKey='uuid'
            rowData={coreSession.symbolStateRowData}
            timestamp={coreSession.timestamp}>
            <Box alignItems='center' className='panel-heading' display='flex'>
                <span className='panel-title'>Symbol States</span>
                <AgGridColumnMenuTrigger
                    className='mdi mdi-settings mdi-18px flex-pull-right'
                    component={<a />}
                />
            </Box>

            <div className='panel-body'>
                <AgGrid
                    autoHeight
                    autoGroupColumnDefProps={autoGroupColumnDefProps}
                    autoSizeColumns
                    noBoxShadow
                    treeData
                />
            </div>
        </AgGridProvider>
    );
}
