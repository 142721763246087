import Box from '@material-ui/core/Box';
import cn from 'classnames';
import {NyPopUp} from 'nyse-web-tools-common/lib/react';
import React, {useCallback, useMemo} from 'react';
import {FixedSizeList} from 'react-window';

type HaltedSymbolsListProps = {
    height: number;
    rowHeight: number;
    symbols: {
        symbol: string;
        symbol_state: string;
        symbolStateGroup: string;
        symbolStateLabel: string;
        haltTime: string;
        resumeTime: string;
    }[][];
    tape: string;
    classes: {
        list: string;
        symbol: string;
        luldHalts: string;
        mwcbHalts: string;
        newListing: string;
        regHalts: string;
        systemIssues: string;
    };
};

export const HaltedSymbolsList: React.FC<HaltedSymbolsListProps> = React.memo(
    (props) => {
        const Symbol = useCallback(
            (props) => (
                <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    style={props.style}>
                    {props.data.symbols[props.index].map((symbol) => (
                        <NyPopUp
                            key={symbol.symbol}
                            trigger={
                                <Box
                                    data-symbol={symbol.symbol}
                                    data-symbol-state={symbol.symbol_state}
                                    data-symbol-state-group={
                                        symbol.symbolStateGroup
                                    }
                                    data-tape={props.data.tape}
                                    alignItems='center'
                                    className={cn(
                                        props.data.classes.symbol,
                                        props.data.classes[
                                            symbol.symbolStateGroup
                                        ]
                                    )}
                                    display='flex'
                                    flex={1}
                                    justifyContent='center'
                                    m={0.25}
                                    overflow='hidden'>
                                    {symbol.symbol}
                                </Box>
                            }>
                            <Box padding={1}>
                                <section>
                                    <strong>Symbol:&nbsp;&nbsp;</strong>
                                    <span>{symbol.symbol}</span>
                                </section>
                                <section>
                                    <strong>Type:&nbsp;&nbsp;</strong>
                                    <span>{symbol.symbolStateLabel}</span>
                                </section>
                                <section>
                                    <strong>Halted:&nbsp;&nbsp;</strong>
                                    <span>{symbol.haltTime}</span>
                                </section>
                                <section>
                                    <strong>Resume:&nbsp;&nbsp;</strong>
                                    <span>{symbol.resumeTime}</span>
                                </section>
                            </Box>
                        </NyPopUp>
                    ))}
                </Box>
            ),
            []
        );

        const data = useMemo(
            () => ({
                classes: props.classes,
                symbols: props.symbols,
                tape: props.tape
            }),
            [props.classes, props.symbols, props.tape]
        );

        return (
            <FixedSizeList
                className={props.classes.list}
                height={props.height}
                itemCount={props.symbols.length}
                itemData={data}
                itemSize={props.rowHeight}
                width='100%'>
                {Symbol}
            </FixedSizeList>
        );
    }
);
