import {
    NyAlert,
    NyForm,
    NyNumberInput,
    NyResetBtn,
    NySubmitBtn
} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {setAlertLimit} from '../../../stores/riskMonitor/riskMonitor.AlertLimit.actions';

@connect(['riskMonitorAlertLimit'], {setAlertLimit})
export class RiskMonitorAlertLimit extends React.Component {
    static propTypes = {
        alertLimit: PropTypes.number.isRequired,
        crd: PropTypes.number.isRequired,
        mpid: PropTypes.string.isRequired
    };

    _initialValues = {
        alertLimit: this.props.alertLimit,
        crd: this.props.crd,
        mpid: this.props.mpid
    };

    _onSubmit = ({alertLimit}) => {
        this.props.setAlertLimit({
            crd: this.props.crd,
            mpid: this.props.mpid,
            alertLimit
        });
    };

    render() {
        return (
            <NyForm
                initialValues={this._initialValues}
                onSubmit={this._onSubmit}>
                <div className='form-group'>
                    <label>Alert Limit</label>
                    <NyNumberInput name='alertLimit' required={true} />
                </div>

                <NyAlert
                    show={!!this.props.riskMonitorAlertLimit.success}
                    title={this.props.riskMonitorAlertLimit.success}
                    type='success'
                />

                {this.props.riskMonitorAlertLimit.error && (
                    <NyAlert
                        show
                        title={this.props.riskMonitorAlertLimit.error.title}
                        detail={this.props.riskMonitorAlertLimit.error.detail}
                        type='danger'
                    />
                )}

                <hr className='flex-row space-top-2 space-bottom-2' />

                <div className='flex-row flex-align-center'>
                    <NySubmitBtn
                        danger
                        className='space-right-1'
                        disabled={this.props.riskMonitorAlertLimit.isLoading}>
                        Submit
                    </NySubmitBtn>
                    <NyResetBtn>Reset</NyResetBtn>
                </div>
            </NyForm>
        );
    }
}
