import {cdmMw} from '../api';

export const tradeActionBlockTimeResource = {
    api: cdmMw,
    url: 'api/eod/trade-action-block-time',
    useCache: false,
    parseResponse: ({data}) => data,
    defaultValue: {}
};

export const reScheduleResource = {
    api: cdmMw,
    url: 'api/eod/jobs/re-schedule',
    useCache: false,
    parseResponse: ({data}) => data,
    defaultValue: {}
};

export const cleanUpJobRunResource = {
    api: cdmMw,
    url: 'api/eod/jobs/clean-up-job/run',
    useCache: false,
    parseResponse: ({data}) => data,
    defaultValue: {}
};

export const goodNightJobRunResource = {
    api: cdmMw,
    url: 'api/eod/jobs/good-night-job/run',
    useCache: false,
    parseResponse: ({data}) => data,
    defaultValue: {}
};
