import axios, {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useMount, useMountedState, useUnmount} from 'react-use';

import {CANCELLED} from '../api/enums';
import {cleanObject} from '../utils/utilities';

type UrlParser = (params: Record<string, unknown>) => string;

interface Resource<T> {
    data: T;
    error?: {
        title: React.ReactNode;
        detail: React.ReactNode;
        type: 'danger' | 'info' | 'warning' | 'success';
    };
    pending: boolean;

    get(
        params?: Record<string, unknown>,
        urlParams?: Record<string, unknown>
    ): AxiosPromise<T>;

    put(
        data?: Record<string, unknown>,
        urlParams?: Record<string, unknown>
    ): AxiosPromise<T>;

    post(
        data?: Record<string, unknown>,
        urlParams?: Record<string, unknown>
    ): AxiosPromise<T>;

    delete(
        data?: Record<string, unknown>,
        urlParams?: Record<string, unknown>
    ): AxiosPromise<T>;
}

interface ResourceConfig<T> {
    api: AxiosInstance;
    url: string | UrlParser;
    headers?: any;
    defaultValue: T;
    helpers?: {
        [name: string]: (data: T) => any;
    };
    useCache?: boolean;

    parseResponse?(data: any, requestConfig: AxiosRequestConfig): T;
}

export default function useResource<T = []>(
    resource: ResourceConfig<T>,
    initParams?: Record<string, unknown> | true
): Resource<T> {
    const isMounted = useMountedState();

    const source = useRef(null);

    const [config] = useState({
        useCache: resource.useCache,
        url: resource.url,
        ...(resource.headers && {headers: resource.headers})
    });

    const defaultValue = useMemo(() => resource.defaultValue, []);

    const [data, setData] = useState(defaultValue);
    const [error, setError] = useState();
    const [pending, setPending] = useState(false);

    const fetch = useCallback(
        /**
         *
         * @param {object} options
         * @param {object} options.data - request body
         * @param {string | (option.params) => string} options.url - request url or callback that accepts options.params to generate url
         * @param {'GET' | 'POST' | 'PUT' | 'DELETE'} options.method - request body
         * @param {object} options.params - request query parameters
         *
         * @return {Promise | undefined}
         */
        (options) => {
            if (isMounted()) {
                source.current = axios.CancelToken.source();

                setError(() => false);

                if (resource.resetDataOnRequest !== false) {
                    setData(() => defaultValue);
                }

                setPending(() => true);

                const requestConfig = cleanObject({
                    ...config,
                    data: options.data,
                    method: options.method,
                    params: cleanObject({
                        ...(options.params || {}),
                        request:
                            options.params && options.params.request
                                ? JSON.stringify(
                                      cleanObject(options.params.request)
                                  )
                                : {}
                    }),
                    url:
                        typeof config.url === 'function'
                            ? config.url({
                                  ...(options.params || {}),
                                  ...(options.data || {}),
                                  ...(options.urlParams || {})
                              })
                            : config.url
                });

                return resource
                    .api({
                        ...requestConfig,
                        useCache:
                            requestConfig.method === 'get'
                                ? config.useCache || false
                                : false,
                        cancelToken: source.current.token
                    })
                    .then((response) => {
                        if (isMounted()) {
                            setPending(() => false);

                            try {
                                const parsedData = resource.parseResponse
                                    ? resource.parseResponse(
                                          response.data,
                                          requestConfig
                                      )
                                    : response.data;

                                setData(() => parsedData);

                                return Promise.resolve(response);
                            } catch (error) {
                                return Promise.reject({
                                    title:
                                        'Something went wrong while processing the response',
                                    detail: error.toString()
                                });
                            }
                        }
                    })
                    .catch((response) => {
                        if (isMounted()) {
                            setPending(() => false);
                            setData(() => defaultValue);
                            setError(() =>
                                typeof response === 'object' && response.error
                                    ? response.error
                                    : typeof response === 'string'
                                    ? {error: response}
                                    : response
                            );
                        }

                        return Promise.reject(
                            typeof response === 'object' && response.error
                                ? response.error
                                : typeof response === 'string'
                                ? {error: response}
                                : response
                        );
                    });
            }
        },
        [config, defaultValue, resource]
    );

    const request = useMemo(
        () => ({
            get: (params, urlParams?: Record<string, unknown>) =>
                fetch({method: 'get', params, urlParams}),
            post: (data, urlParams?: Record<string, unknown>) =>
                fetch({method: 'post', data, urlParams}),
            put: (data, urlParams?: Record<string, unknown>) =>
                fetch({method: 'put', data, urlParams}),
            delete: (data, urlParams?: Record<string, unknown>) =>
                fetch({method: 'delete', data, urlParams}),
            data,
            error,
            pending,
            ...Object.entries(resource.helpers || {}).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: value(data)
                }),
                {}
            )
        }),
        [data, error, fetch, pending, resource.helpers]
    );

    useMount(() => {
        if (initParams) {
            request.get(
                typeof initParams === 'object' ? initParams : undefined
            );
        }
    });

    useUnmount(() => {
        if (source.current) {
            source.current.cancel(CANCELLED);
        }
    });

    return request;
}
