import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {useGiveUps} from '../../hooks/useGiveUps.hook';

export const IbMpidSelect = React.memo((props) => {
    const [giveUps, isLoading] = useGiveUps({crd: props.crd});

    const options = useMemo(
        () =>
            giveUps.reduce(
                (acc, {name}) => [
                    ...acc,
                    {
                        label: name,
                        value: name
                    }
                ],
                [{label: '-', value: ''}]
            ),
        [giveUps]
    );

    return (
        <NySelectInput
            disabled={props.disabled}
            initialValue={props.initialValue}
            name={props.name}
            isLoading={isLoading}
            onChange={props.onChange}
            options={options}
            readOnly={props.readOnly}
            required={props.required}
            resetFields={props.resetFields}
        />
    );
});

IbMpidSelect.propTypes = {
    crd: PropTypes.number,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    resetFields: PropTypes.arrayOf(PropTypes.string)
};
