import {LocalDateTime} from '@js-joda/core';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {createDownloadAction} from '../downloads/downloads.actions';
import {events} from '../events';

const getDate = (date) => {
    if (date) {
        return joda.format(LocalDateTime.parse(date.toString()), 'yyyy-MM-dd');
    }
};

const getStartTs = (startTs, date) => {
    return startTs
        ? startTs.toString()
        : date ? joda.toStartOfDay(date).toString()
            : undefined;
};

const getEndTs = (endTs, date) => {
    return endTs
        ? endTs.toString()
        : date ? joda.toEndOfDay(date).toString()
            : undefined;
};

const parseMatcherTime = ({
    endUpdatedTs,
    origTdate,
    startUpdatedTs,
    updatedDate,
    expirationDate,
    startRefExecTs,
    endRefExecTs,
    ...params
}) => {
    return cleanObject({
        endRefExecTs: getEndTs(endRefExecTs, origTdate),
        endUpdatedTs: getEndTs(endUpdatedTs, updatedDate),
        expirationDate: getDate(expirationDate),
        startRefExecTs: getStartTs(startRefExecTs, origTdate),
        startUpdatedTs: getStartTs(startUpdatedTs, updatedDate),
        ...params
    });
};

const sortFieldsForGet = (sort) => {
    return sort.reduce(
        (acc, {field, dir}, index) => ({
            ...acc,
            [`sortFields[${index}].field`]: field,
            [`sortFields[${index}].direction`]: dir.toUpperCase()
        }),
        {}
    );
};

const sortFieldsForPost = (sort) => ({
    sort: sort.reduce(
        (acc, {field, dir}) => [
            ...acc,
            {
                field,
                dir: dir.toUpperCase()
            }
        ],
        []
    )
});

export const getTradeSummary = ({view, ...params}) => ({
    types: [
        events.TRADE_SUMMARY_GET,
        events.TRADE_SUMMARY_SUCCESS,
        events.TRADE_SUMMARY_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: `/api/options-trades/${view}`,
            method: 'GET',
            params: cleanObject({
                ...parseMatcherTime(params),
                ...sortFieldsForGet(params?.sort ?? [])
            })
        }
    }
});

export const getTradeSummaryExport = createDownloadAction({
    client: 'cdmMw',
    url: 'api/options-trades/download',
    method: 'POST',
    parseData: (params) =>
        cleanObject({
            ...parseMatcherTime(params),
            ...sortFieldsForPost(params?.sort ?? [])
        })
});

export const getTradeSummaryTransactionExport = createDownloadAction({
    client: 'cdmMw',
    url: 'api/options-trades/transactions/download',
    method: 'POST',
    parseData: (params) =>
        cleanObject({
            ...parseMatcherTime(params),
            ...sortFieldsForPost(params?.sort ?? [])
        })
});

export const getTradeSummaryTransactionHistoryExport = createDownloadAction({
    client: 'cdmMw',
    url: 'api/options-trades/transaction-history/download',
    method: 'POST',
    parseData: (params) =>
        cleanObject({
            ...parseMatcherTime(params),
            ...sortFieldsForPost(params?.sort ?? [])
        })
});
