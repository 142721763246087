import {CircularProgress} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import {isEmpty} from 'lodash-es';
import numbro from 'numbro';
import {NyAlert, NyLoader} from 'nyse-web-tools-common/lib/react';
import React, {useEffect, useState} from 'react';
import {useQueries} from 'react-query';
import useResource from '../../../../hooks/useResource.hook';
import {tradeApprovalDetailResource, TransactionProps} from '../../../../resources/tradeSummary.resource';
import {MultiRecallFormFields} from './multiRecallFields.form';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%'
    }
}));

export const multiRecallColumnDef = [
    {
        field: 'transactionStatus',
        headerName: 'Status',
        width: 150
    },
    {
        field: 'refExecTs',
        headerName: 'Ref Exec Time',
        type: 'dateTime',
        width: 100
    },
    {
        field: 'custSideValue',
        headerName: 'Side',
        width: 100
    },
    {
        field: 'execQty',
        headerName: 'Qty',
        type: 'number',
        width: 100
    },
    {
        field: 'osiRootSymb',
        headerName: 'OSI Root Symbol',
        width: 100
    },
    {
        field: 'underlyingSymbol',
        headerName: 'Underlying Symbol',
        width: 100
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        type: 'date',
        width: 100
    },
    {
        field: 'strikePrice',
        headerName: 'Strike Price',
        type: 'price',
        cellRenderer: ({data}) =>
            data.strikePrice === undefined
                ? ''
                : numbro(data.strikePrice).format({
                    mantissa: 8,
                    thousandSeparated: true
                })
    },
    {
        field: 'putCallValue',
        headerName: 'Put/Call',
        width: 100
    },
    {
        field: 'execPrc',
        headerName: 'Price',
        type: 'price',
        cellRenderer: ({data}) =>
            data.execPrc === undefined
                ? ''
                : numbro(data.execPrc).format({
                    mantissa: 8,
                    thousandSeparated: true
                })
    },
    {
        field: 'custClordId',
        headerName: 'Client Order Id',
        width: 100
    },
    {
        field: 'processStatus',
        headerName: 'Action Status',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellRendererFramework: (params) => params.data.processStatus === 'loading' ? (
            <CircularProgress color='secondary' thickness={5} size={20} />
        ) : params.data.processStatus === 'success' ? (
            <DoneIcon style={{color: 'green'}} fontSize='large' />
        ) : params.data.processStatus === 'error' ? (
            <CloseIcon style={{color: 'red'}} fontSize='large' />
        ) : (
            <div></div>
        ),
        width: 100
    },
    {
        headerName: 'Error',
        field: 'error',
        cellRendererFramework: (params) => {
            if (params.data.error) {
                return (
                    <div style={{color: 'red'}}>
                        {params.data.error}
                    </div>
                );
            }else {
                return (<div></div>);
            }
        }
    }
];

export const MultiRecallForm: React.FC<TransactionProps> = React.memo(
    (props) => {
        const classes = useStyles(props);
        const [rowData, setRowData] = useState([]);

        const tradeApprovalDetailsResource = useResource(tradeApprovalDetailResource);
        const fetchedTransactions = useQueries(props?.data?.map(aTransaction => ({
            queryKey: ['transaction', aTransaction?.txId],
            queryFn: () => tradeApprovalDetailsResource.get(
                    {
                        crd: props.crd
                    },
                    {
                        origTdate: aTransaction?.origTdate,
                        evtTyp: aTransaction?.evtTyp,
                        dealIdRef: aTransaction?.dealIdRef,
                        txId: aTransaction?.txId
                    }
                )
        })));
        const isLoading = fetchedTransactions.some(fetchedTransaction => fetchedTransaction.isLoading);
        const isSuccess = fetchedTransactions.every(fetchedTransaction => fetchedTransaction.isSuccess);

        useEffect(() => {
            if (!isLoading && isSuccess) {
                setRowData(fetchedTransactions.map(fetchedTransaction => fetchedTransaction?.data?.data?.data));
            }
        }, [isLoading, isSuccess]);

        return (
            <div className={classes.root}>
                {
                    isLoading && <NyLoader show />
                }
                {
                    !isLoading && isSuccess && (
                        <MultiRecallFormFields
                            data={rowData}
                            crd={props.crd}
                            onClose={props.onClose}
                        />
                    )
                }
                {
                    !isLoading && !isSuccess && (
                        <NyAlert
                            title='Error'
                            detail={tradeApprovalDetailsResource?.error?.detail}
                            type='danger'
                            show={!isEmpty(tradeApprovalDetailsResource?.error)}
                        />
                    )
                }
            </div>
        );
    }
);
