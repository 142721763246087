import buttons from 'nyse-web-tools-common/lib/react/generic/StaticButtons/StaticButtons';
import React from 'react';

import {SubAccountContactModal} from '../../components/modals/subAccounts/subAccountContact.modal';
import subAccountContactsResource from '../../resources/subAccountContact.resource';

export const subAccountContactsColumnDefs = [
    {
        cellRendererParams: {
            Form: SubAccountContactModal,
            Button: buttons.EditOutlined,
            name: 'editSubAccountContact',
            omitInitialValueFields: [
                'createdBy',
                'createdTime',
                'modifiedBy',
                'modifiedTime'
            ],
            parentTitle: 'Sub Accounts',
            resource: subAccountContactsResource,
            resourceMethod: 'put',
            size: 25,
            title: 'Update Contact'
        },
        field: 'editSubAccountContact',
        permissions: ['update sub accounts'],
        pinned: 'left',
        type: 'modal'
    },
    {
        cellRendererParams: {
            Button: buttons.DeleteOutlineOutlined,
            message: (data) => (
                <>
                    Do you want to delete Contact:{' '}
                    <strong>
                        {data.firstName} {data.lastName}
                    </strong>
                    ?
                </>
            ),
            resetRowHeights: true,
            resource: subAccountContactsResource,
            warning: true
        },
        field: 'removeSubAccountContact',
        permissions: ['delete sub accounts'],
        pinned: 'right',
        type: 'remove'
    },
    {
        field: 'contactId',
        headerName: 'Contact Id'
    },
    {
        field: 'firstName',
        headerName: 'First Name'
    },
    {
        field: 'lastName',
        headerName: 'Last Name'
    },
    {
        field: 'createdBy',
        headerName: 'Created By'
    },
    {
        field: 'createdTime',
        headerName: 'Created Time',
        type: 'dateTime'
    },
    {
        field: 'modifiedBy',
        headerName: 'Modified By'
    },
    {
        field: 'modifiedTime',
        headerName: 'Modified Time',
        type: 'dateTime'
    }
];
