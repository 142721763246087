import React from 'react';

import { getRoleByEntitlement } from '../../configs/entitlementRoleMap';
import useOptionsCheck from '../../hooks/useOptionsCheck.hook';
import useUser from '../../hooks/useUser.hook';

type EntitlementProps = {
    role: string;
    exclude?: string;
    checkOptionsMarket?: boolean;
};

export const Entitlements: React.FC<EntitlementProps> = React.memo((props) => {
    const user = useUser();
    const isOptionsMarket = useOptionsCheck();

    const hasEntitlement =
        (user.entitlement === 'SUPER_USER' && props.role !== props.exclude) ||
        (getRoleByEntitlement(
            user.entitlement,
            props.checkOptionsMarket && isOptionsMarket
            ) === props.role &&
            (!props.exclude || props.role !== props.exclude));

    return <>{hasEntitlement && props.children}</>;
});
