import {pick} from 'lodash-es';

import {events} from '../events';

export const myVolumesCadvReducer = {
    [events.MY_VOLUMES_CADV_GET]: (state = {}) => ({
        ...state,
        isLoading: true,
        timestamp: false
    }),
    [events.MY_VOLUMES_CADV_SUCCESS]: (
        state,
        {
            payload: {
                data: {data},
                config: {params}
            },
            _state
        }
    ) => {
        const firm = _state.firms.find(({crd}) => crd === params.crd);

        const endDate = data.endDate.replace(/-/g, '/');
        const startDate = data.startDate.replace(/-/g, '/');

        return {
            isLoading: false,
            error: false,
            more: false,
            startDate,
            endDate,
            pinnedTopRowData: [
                {
                    group: ['CADV'],
                    mic: _state.platform.mic,
                    allTapesAdv: data.cadv.allTapeVolume,
                    tapeAAdv: data.cadv.tapeAVolume,
                    tapeBAdv: data.cadv.tapeBVolume,
                    tapeCAdv: data.cadv.tapeCVolume,
                    ignoreExport: true
                },
                {
                    group: [_state.platform.name],
                    mic: _state.platform.mic,
                    allTapesAdv: data.cadvExchange.allTapeVolume,
                    tapeAAdv: data.cadvExchange.tapeAVolume,
                    tapeBAdv: data.cadvExchange.tapeBVolume,
                    tapeCAdv: data.cadvExchange.tapeCVolume,
                    ignoreExport: true
                },
                {
                    group: [
                        `${firm.name} ${
                            params.participantId
                                ? ` [ ${params.participantId} ]`
                                : ''
                        }`
                    ],
                    mic: _state.platform.mic,
                    ...pick(data, [
                        'tapeAMtd',
                        'tapeBMtd',
                        'tapeCMtd',
                        'tapeAAdv',
                        'tapeBAdv',
                        'tapeCAdv',
                        'tapeACadvPtg',
                        'tapeBCadvPtg',
                        'tapeCCadvPtg',
                        'tapeAPrevDay',
                        'tapeBPrevDay',
                        'tapeCPrevDay',
                        'allTapesAdv',
                        'allTapesMtd',
                        'allTapesPrevDay',
                        'allTapeCadvPtg'
                    ]),
                    ignoreExport: true
                }
            ],
            rowData: [
                ...data.volumes.reduce(
                    (volumes, group) => [
                        ...volumes,
                        {
                            group: [group.name],
                            ignoreExport: true,
                            mic: _state.platform.mic,
                            ...group
                        },
                        ...(group.members
                            ? group.members.reduce(
                                  (members, liqInd) => [
                                      ...members,
                                      {
                                          group: [group.name, liqInd.name],
                                          mic: _state.platform.mic,
                                          ...liqInd
                                      }
                                  ],
                                  []
                              )
                            : [])
                    ],
                    []
                )
            ],
            timestamp: `${startDate} - ${endDate}`
        };
    },
    [events.MY_VOLUMES_CADV_ERROR]: (state, {error}) => ({
        error,
        hasData: false,
        isLoading: false,
        more: false,
        rowData: [],
        timestamp: null
    })
};
