import {ColumnState} from 'ag-grid-community/dist/lib/columnController/columnController';
import {
    AgGrid,
    AgGridAutoRefresh,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridPagination,
    AgGridProvider,
    AgGridToggleShowFilters,
    NySelectInput,
    NyTextInput
} from 'nyse-web-tools-common/lib/react';
import React, {FunctionComponent, useState} from 'react';
import {useDispatch} from 'react-redux';

import {FirmSelect} from '../components/selects/firm.select';
import useStore from '../hooks/useStore.hook';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {getMySessions} from '../stores/mySessions/mySessions.actions';

type Filter = {
    cg: string;
    crd: number;
    limit: number;
    offset: number;
    sender_type: string;
    sendercomp: string;
    sessconn_status: string;
    session_status: string;
    source_ip: string;
    user_type: string;
};

export const MySessionsPage: FunctionComponent = (props) => {
    const [firms, columnDefs, mySessions, platform, route] = [
        useStore('firms'),
        useStore('columnDefs'),
        useStore('mySessions'),
        useStore('platform'),
        useStore('route')
    ];
    const dispatch = useDispatch();
    const [filterValues, setFilterValues] = useState<{filterValues: Filter}>({
        filterValues: {} as Filter
    });
    const initialFilters = {
        crd: route.params.crd || (firms[0] || {}).crd
    };
    const initialPagination = {
        limit: 500,
        offset: 0
    };

    const handleFiltersChanged = (filterValues: Filter): void => {
        setFilterValues({filterValues});
    };

    const handleLoad = (params: Record<string, unknown>): void => {
        dispatch(getMySessions(params));
    };

    const handleColumnsChanged = (params: {
        namespace: string;
        columnDefs: ColumnState;
    }): void => {
        dispatch(setColumns(params));
    };

    return (
        <AgGridProvider
            columnNamespace='mySessions'
            columnDefs={columnDefs.mySessions}
            filtersClassName='row space-bottom-1'
            initialFilters={initialFilters}
            initialPagination={initialPagination}
            onLoad={handleLoad}
            onFiltersChanged={handleFiltersChanged}
            onColumnsChanged={handleColumnsChanged}
            submitOnLoad
            {...mySessions}>
            <AgGridProvider.Filters>
                {firms.length > 1 && (
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>Firm</label>
                        <FirmSelect
                            initialValue={route.params.crd}
                            name='crd'
                            required
                        />
                    </div>
                )}
                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                    <label>Username</label>
                    <NyTextInput name='sendercomp' />
                </div>
                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                    <label>Sender Type</label>
                    <NySelectInput name='sender_type'>
                        <option value=''>-</option>
                        <option value='algo'>Algo</option>
                        <option value='broker_child'>Broker Child</option>
                        <option value='cust_equity_mm'>
                            Customer Equity MM
                        </option>
                        <option value='direct_to_broker'>
                            Direct To Broker
                        </option>
                        <option value='dmm'>DMM</option>
                        {platform.mic === 'XNYS' && (<option value='floor_broker_fba'>Floor Broker FBA</option>)}
                        {platform.mic === 'XNYS' && (<option value='floor_broker_oms'>Floor Broker OMS</option>)}
                        <option value='options_mm'>Options MM</option>
                        <option value='svc_bureau'>Service Bureau</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                    <label>User Type</label>
                    <NySelectInput name='user_type'>
                        <option value=''>-</option>
                        <option value='bin'>Binary</option>
                        <option value='bin_drc'>Binary Drop Copy</option>
                        <option value='fix'>Fix</option>
                        <option value='fix_drc'>Fix Drop Copy</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                    <label>Session Status</label>
                    <NySelectInput name='session_status'>
                        <option value=''>-</option>
                        <option value='active'>Active</option>
                        <option value='inactive'>Inactive</option>
                        <option value='prospect'>Prospect</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                    <label>Source IP</label>
                    <NyTextInput name='source_ip' />
                </div>
                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                    <label>CG</label>
                    <NyTextInput name='cg' />
                </div>
                <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                    <label>Connection Status</label>
                    <NySelectInput name='sessconn_status'>
                        <option value=''>-</option>
                        <option value='connected'>Connected</option>
                        <option value='primary'>Primary</option>
                        <option value='secondary'>Secondary</option>
                        <option value='disconnected'>Disconnected</option>
                        <option value='none'>None</option>
                    </NySelectInput>
                </div>
            </AgGridProvider.Filters>

            <div className='row space-bottom-1'>
                <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                    <div className='flex-row flex-align-bottom'>
                        <AgGridProvider.SubmitBtn>
                            Search
                        </AgGridProvider.SubmitBtn>
                        <AgGridProvider.ResetBtn>Reset</AgGridProvider.ResetBtn>
                        <div className='btn-circle-grp flex-pull-right'>
                            <AgGridAutoRefresh />
                            <AgGridToggleShowFilters />
                            <AgGridExportMenu
                                exportFileName='mySessions'
                                exportSheetName={platform.name}
                            />
                            <AgGridColumnMenuTrigger />
                        </div>
                    </div>
                </div>
            </div>

            <AgGrid reactNext />

            <AgGridPagination>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1,000</option>
                <option value={5000}>5,000</option>
                <option value={10000}>10,000</option>
            </AgGridPagination>
        </AgGridProvider>
    );
};
