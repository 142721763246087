import {scale} from 'chroma-js';
import React from 'react';

import {RiskMonitorActionsBtn} from '../../components/modals/riskMonitor/riskMonitorActions.btn';

const dangerScale = scale(['white', 'yellow', 'orange', 'red']);

export const riskMonitorColumnDefs = [
    {
        field: '_details_',
        pinned: 'left',
        suppressToolPanel: true,
        movable: false,
        resizable: false,
        suppressSizeToFit: true,
        sorting: false,
        headerName: '',
        cellRendererFramework: RiskMonitorActionsBtn,
        valueGetter: ({data}) => data,
        minWidth: 50,
        width: 50
    },
    {
        field: 'mpid',
        headerName: 'MPID',
        pinned: 'left',
        maxWidth: 150,
        width: 150
    },
    {
        field: 'clearingNum',
        headerName: 'Clearing #',
        maxWidth: 200,
        width: 150
    },
    {
        field: 'alertLimit',
        headerName: 'Alert Limit',
        type: 'price',
        maxWidth: 200,
        width: 150
    },
    {
        field: 'percentUsage',
        headerName: 'Usage',
        cellStyle: ({data}) => ({
            backgroundColor:
                data.percentUsage >= 50
                    ? dangerScale(data.percentUsage / 100)
                    : undefined,
            color: data.percentUsage / 100 >= 0.8 ? '#ffffff' : undefined
        }),
        type: 'percentage',
        maxWidth: 200,
        width: 150
    },
    {
        headerName: 'Net Notional Executed',
        headerComponentFramework: () => (
            <>
                Net Notional Executed <small>*</small>
            </>
        ),
        field: 'netNotionalExecuted',
        type: 'price',
        width: 200
    }
];
