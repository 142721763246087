import {get} from 'lodash-es';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {GlossaryBtn} from '../modals/glossary/glossary.btn';

@connect(['platform', 'route'])
export class PageTitle extends React.Component {
    render() {
        const hasPageTitle = this.props.route.title || this.props.route.group;
        const mic = this.props.platform.mic ?? '';
        const platformMic = this.props.route.url === '/system/broadcast-message' ? 'SYSTEM' : mic;
        const documentTitle = `${
            platformMic ? `${platformMic}:` : ''
        } ${this.props.route.title}`.trim();

        const glossaryTerms = get(this.props.route, 'glossary.terms', []);

        window.document.title = hasPageTitle
            ? documentTitle
            : 'Trade Ops Portal';

        return (
            <>
                {hasPageTitle ? (
                    <header className='page-header'>
                        {this.props.route.group && (
                            <div
                                className='page-header-parent'
                                data-e2e-tag='pageParentTitle'>
                                {this.props.route.group}
                            </div>
                        )}
                        {this.props.route.title && (
                            <div className='flex-row flex-align-bottom'>
                                <h1
                                    className='flex-grow'
                                    data-e2e-tag='pageTitle'>
                                    {this.props.route.title}
                                </h1>
                                {glossaryTerms.length > 0 && <GlossaryBtn />}
                            </div>
                        )}
                    </header>
                ) : null}
            </>
        );
    }
}
