import {LocalDateTime} from '@js-joda/core';
import {v4} from 'uuid';

import {cdmMw} from '../api';

export default {
    api: cdmMw,
    url: (params) => 'api/live-orders',
    useCache: false,
    defaultValue: {
        more: false,
        rowData: [],
        timestamp: LocalDateTime.now()
    },
    parseResponse: (data) => ({
        more: data?.meta?.more ?? false,
        count: data?.meta?.totalRecords ?? 0,
        rowData: (data?.data ?? []).map((item) => ({
            ...item,
            uuid: v4()
        })),
        timestamp: LocalDateTime.now()
    }),
    resetDataOnRequest: false
};
