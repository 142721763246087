import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';

type MultiEditStatusIcon = {
    value?: string,
    context?: any
};

export const MultiEditStatusIcon = React.memo<MultiEditStatusIcon>((props) => 
    <Grid container alignItems='center'>
        {
            !!props?.context?.multiEdit?.isPending ? (
                <span className='nyse-dot-loader'></span>
            ) : (
                props?.value === 'Successfully processed' ? (
                    <CheckIcon style={{fill: '#509C31'}}/>
                ) : (
                    <span style={{color: props?.value.indexOf('Error') >= 0 ? 'red' : 'darkgreen'}}>{props?.value}</span>
                )
            )
        }
    </Grid>
);
