import {Box} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {
    CheckboxInput,
    NySelectInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import type {NyFieldInput} from 'nyse-web-tools-common/lib/react/types';
import React, {useCallback, useMemo} from 'react';
import {useToggle} from 'react-use';

import {useEntitlement} from '../../hooks/useEntitlement';
import {useRouteEntitlement} from '../../hooks/useRouteEntitlement';
import useUser from '../../hooks/useUser.hook';
import useUserFirms from '../../hooks/useUserFirms';
import type {Entitlement} from '../../types';

type MmIdSelectProps = {
    crd: number;
    entitlements?: Entitlement[];
    disableInactiveControl?: boolean;
    hideEtpid?: boolean;
    initialValue?: string;
    isMulti?: boolean;
    showAll?: boolean;
    showInactive?: boolean;
} & Partial<NyFieldInput>;

export const MmIdSelect = React.memo<MmIdSelectProps>((props) => {
    const userFirms = useUserFirms();
    const formik = useFormikContext();
    const entitlement = useEntitlement();
    const routeEntitlement = useRouteEntitlement();
    const user = useUser();

    const firms = useMemo(
        () =>
            (userFirms.data ?? []).filter(
                ({active}) => props.showInactive || active
            ),
        [userFirms.data, props.showInactive]
    );

    const [hideInactive, toggleHideInactive] = useToggle(
        !props.showInactive || true
    );

    const entitlements = useMemo(() => props.entitlements || ['MM_FIRM'], [
        props.entitlements
    ]);

    const hasEntitlement = useMemo(
        () =>
            !routeEntitlement.length ||
            entitlements.includes(entitlement) ||
            entitlements.includes(user.entitlement),
        [entitlement, entitlements, user.entitlement, routeEntitlement]
    );

    const sortBy = [
        ({label}) => label.includes('Inactive'),
        ({label}) => label.toLowerCase()
    ];

    const filterOptions = useCallback(
        ({props: selectProps}) =>
            !hideInactive ||
            !props ||
            (selectProps.active &&
                (!props.hideEtpid ||
                    !selectProps.type ||
                    selectProps.type === 'MPID')),
        [hideInactive, props]
    );

    const renderChildOption = useCallback(
        ({label, props}) => (
            <div className='flex-row flex-align-center flex-grow'>
                <span className='ellipsis flex-grow'>{label}&nbsp;&nbsp;</span>
                {!props.active && (
                    <small className='flex-pull-right text-warning ellipsis'>
                        Inactive
                    </small>
                )}
            </div>
        ),
        []
    );

    const renderControls = useCallback(
        () => (
            <Box
                borderTop='1px solid #cccccc'
                display='flex'
                flexDirection='row'
                alignItems='center'
                pl={0.25}>
                <CheckboxInput
                    className='warning'
                    name='hideInactive'
                    onChange={toggleHideInactive}
                    value={hideInactive}>
                    Hide Inactive
                </CheckboxInput>
            </Box>
        ),
        [hideInactive, toggleHideInactive]
    );

    const renderParentOption = useCallback(
        ({label, props}) => (
            <div className='flex-row flex-align-center'>
                <span className='ellipsis flex-1'>{label}&nbsp;&nbsp;</span>
                {!props.active && (
                    <small className='flex-pull-right text-warning padding-right-1 flex-0'>
                        Inactive
                    </small>
                )}
            </div>
        ),
        []
    );

    const participants = useMemo(
        () => firms.find(({crd}) => crd === props.crd)?.participants ?? [],
        [firms, props.crd]
    );

    const options = useMemo(
        () => [
            ...(props.isMulti || props.required
                ? []
                : [
                      {
                          label: '-',
                          value: '',
                          props: {
                              active: true
                          }
                      }
                  ]),
            ...participants.reduce(
                (acc, {mmIds = []}) => [
                    ...acc,
                    ...mmIds.map((mmId) => ({
                        label: mmId.id,
                        value: mmId.id,
                        props: mmId
                    }))
                ],
                []
            )
        ],
        [participants, props.isMulti, props.required]
    );

    useMountedEffect(() => {
        formik.setFieldValue(
            props.name,
            props.initialValue || (props.isMulti && hasEntitlement ? [] : '')
        );
    }, [hasEntitlement, props.crd, props.isMulti]);

    if (hasEntitlement || Array.isArray(formik.values[props.name])) {
        return (
            <NySelectInput
                controls={
                    props.disableInactiveControl ? undefined : renderControls
                }
                disabled={props.disabled || options.length === 0}
                filter={filterOptions}
                initialValue={props.initialValue}
                isMulti={props.isMulti}
                isLoading={userFirms.isFetching}
                name={props.name}
                onChange={props.onChange}
                options={options}
                renderChildOption={renderChildOption}
                renderParentOption={renderParentOption}
                required={props.required}
                sortBy={sortBy}
            />
        );
    }

    return <NyUppercaseInput {...props} name={props.name} />;
});
