import {LocalDateTime} from '@js-joda/core';
import {get} from 'lodash-es';

import {events} from '../events';

export const myFirmMpidsReducer = {
    [events.MY_FIRM_MPIDS_GET]: (state = {}) => ({
        ...state,
        isLoading: true
    }),
    [events.MY_FIRM_MPIDS_SUCCESS]: (state, {payload: {data}}) => ({
        isLoading: false,
        error: false,
        more: get(data, 'meta.more', false),
        rowData: get(data, 'data', []),
        timestamp: LocalDateTime.now()
    }),
    [events.MY_FIRM_MPIDS_ERROR]: (state, {error}) => ({
        error,
        isLoading: false,
        more: false,
        rowData: [],
        timestamp: LocalDateTime.now()
    })
};
