import React from "react";
import buttons from "nyse-web-tools-common/lib/react/generic/StaticButtons/StaticButtons";
import {deleteRiskEntityResource,
        addRiskEntitySymbolResource
        } from "../../resources/riskSettings.resource";
import {AddSymbolRiskEntityModal} from "../../components/modals/risk/addSymbolRiskEntity.modal";

export const riskSettingsOptionColumnDefs = [
    {
        cellRendererParams: {
            Form: AddSymbolRiskEntityModal,
            Button: buttons.AddOutlined,
            initialValueFields: [
                'displayRiskEntity',
                'entityId',
                'userCrd',
                'userCrdRole'
            ],
            name: 'addSymbolRiskEntity',
            parentTitle: 'Risk Settings',
            resetScrollToTop: true,
            resource: addRiskEntitySymbolResource,
            resourceMethod: 'post',
            size: 30,
            title: 'Add Risk Entity'
        },
        cellStyle: ({data}) => ({
            display: data.entityType !== 'MPID_SUBID' && 'none'
        }),
        pinned: 'left',
        permissions: [
            'create risk entity',
            'ENTERING'
        ],
        permissionCheckType: 'every',
        field: 'createRiskEntity',
        lockPosition: true,
        suppressToolPanel: true,
        type: 'modal',
        width: 40,
        minWidth: 40,
        maxWidth: 40
    },
    {
        cellRendererParams: {
            Button: buttons.DeleteOutlineOutlined,
            message: (data) => (
                <>
                    Do you want to delete risk entity:{' '}
                    <strong>{data.displayRiskEntity}</strong>?
                </>
            ),
            resource: deleteRiskEntityResource,
            resourceMethod: 'delete',
            warning: true
        },
        pinned: 'right',
        cellStyle: ({data}) => ({
            display: data.entityType !== 'SYMBOL_MPID_SUBID'  && 'none'
        }),
        permissions: [
            'delete risk entity',
            'ENTERING'
        ],
        permissionCheckType: 'every',
        field: 'deleteRiskEntity',
        lockPosition: true,
        suppressToolPanel: true,
        type: 'remove',
        width: 40,
        minWidth: 40,
        maxWidth: 40
    },
    {
        headerName: 'Risk Entity',
        field: 'displayRiskEntity',
        lockPosition: true,
        suppressToolPanel: true,
        type: 'agTreeBtn'
    }
];
