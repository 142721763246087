import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import PropTypes from 'prop-types';
import React from 'react';

export class LiqIndicatorSelect extends React.PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        name: PropTypes.string.isRequired,
        isMulti: PropTypes.bool,
        required: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        isMulti: false,
        required: false
    };

    render() {
        return (
            <NySelectInput
                disabled={this.props.disabled}
                isMulti={this.props.isMulti}
                initialValue={this.props.initialValue}
                name={this.props.name}
                required={this.props.required}>
                {!this.props.isMulti && <option value=''>-</option>}
                <option value='A'>A - Add Regular Limit Order</option>
                <option value='AB'>
                    AB - Add Non-routable Limit Order (Arca Only Order)
                </option>
                <option value='AC'>AC - Capital Commitment</option>
                <option value='AD'>AD - Add Directed Market Maker quote	</option>
                <option value='ADB'>
                    ADB - Add Discretionary Peg Order - at prices between the
                    same side PBBO and midpoint
                </option>
                <option value='ADM'>
                    ADM - Add Discretionary Peg Order - at the midpoint
                </option>
                <option value='ADR'>ADR - Add d-Quote with Discretion</option>
                <option value='ADZ'>
                    ADZ - Add Discretionary Peg Order - at the same side PBBO or
                    below same side PBBO
                </option>
                <option value='AJP'>
                    AJP - Add Limit Joining NBBO with Priority
                </option>
                <option value='AJPD'>
                    AJPD - Add Directed Market Maker Quote Joining NBBO with Priority
                </option>
                <option value='AJPS'>
                    AJPS - Add Specialist/e-Specialist Market Maker Quote Joining NBBO with Priority
                </option>
                <option value='AJPZ'>
                    AJPZ - Add Limit Order Joining NBBO with Priority Sub Dollar
                </option>
                <option value='AML'>AML - Add MPL Order</option>
                <option value='AMZ'>AMZ - Add MPL Sub Dollar Execution</option>
                <option value='AND'>AND - Add Non-Displayed Order</option>
                <option value='ANZ'>
                    ANZ - Add Non-Displayed Sub Dollar Execution
                </option>
                <option value='ARE'>
                    ARE - Add Retail Provider (Tag 57 = RET)
                </option>
                <option value='AREZ'>
                    AREZ - Add Retail Provider Sub Dollar
                </option>
                <option value='ARM'>ARM - Add MPL Retail Provider</option>
                <option value='ARP'>ARP - Add Retail Provider RPI Order</option>
                <option value='AS'>AS - Add Specialist/e-Specialist Market Maker Quote</option>
                <option value='ASB'>
                    ASB - Add Limit Order Setting New BBO
                </option>
                <option value='ASBD'>
                    ASBD - Add Directed Market Maker Quote Setting New BBO
                </option>
                <option value='ASBS'>
                    ASBS - Add Specialist/e-Specialist Market Maker Quote Setting New BBO
                </option>
                <option value='ASP'>
                    ASP - Add Limit Setting NBBO with Priority
                </option>
                <option value='ASPD'>
                    ASPD - Add Directed Market Maker Quote Setting New NBBO with Priority
                </option>
                <option value='ASPS'>
                    ASPS - Add Specialist/e-Specialist Market Maker Quote Setting New NBBO with Priority
                </option>
                <option value='ASPZ'>
                    ASPZ - Add Limit Order Setting New NBBO with Priority Sub
                    Dollar
                </option>
                <option value='AT'>AT - Add Tracking Order</option>
                <option value='AZ'>AZ - Add Sub Dollar Execution</option>
                <option value='BA'>BA - Bold Add</option>
                <option value='BR'>BR - Bold Remove</option>
                <option value='C'>C - Market Day MOC/LOC Executions</option>

                <option value='CED'>
                    CED - d-Order Executed in the Close when received prior to
                    25 minutes before the close
                </option>
                <option value='CEDZ'>
                    CEDZ - d-Order Executed in the Close when received prior to
                    25 minutes before the close - Subdollar
                </option>
                <option value='CIO'>CIO - Imbalance Offset on Close</option>
                <option value='CIOZ'>
                    CIOZ - Imbalance Offset on Close Subdollar
                </option>
                <option value='CL'>CL - Limit orders on Close</option>
                <option value='CLD'>
                    CLD - d-Order Executed in the Close when received after 3
                    minutes before the close
                </option>
                <option value='CLDZ'>
                    CLDZ - d-Order Executed in the Close when received after 3
                    minutes before the close - Subdollar
                </option>
                <option value='CLZ'>
                    CLZ - Limit and AOC @ Close Subdollar
                </option>
                <option value='CM'>CM - Market Day and MOC</option>
                <option value='CMD'>
                    CMD - d-Order Executed when received in between 25 minutes
                    and 3 minutes before the close
                </option>
                <option value='CMDZ'>
                    CMDZ - d-Order Executed when received in between 25 minutes
                    and 3 minutes before the close - Subdollar
                </option>
                <option value='CMZ'>CMZ - Market Day and MOC Subdollar</option>
                <option value='CZ'>CZ - Sub Dollar All Executions</option>
                <option value='O'>O - Market Day MOO/LOO Executions</option>
                <option value='OIO'>OIO - Imbalance Offset</option>
                <option value='OIOZ'>OIOZ - Imbalance Offset Sub Dollar</option>
                <option value='OL'>OL - Limit orders at Opening</option>
                <option value='OLZ'>
                    OLZ - Limit Orders and AOC Orders (PRIN) Subdollar
                </option>
                <option value='OM'>OM - Market Day and MOO</option>
                <option value='OMZ'>OMZ - Market Day and MOO Subdollar</option>
                <option value='OZ'>OZ - Opening Sub-Dollar Executions</option>
                <option value='R'>R - Remove Regular Limit or Market</option>
                <option value='RBN'>
                    RBN - Remove when executing at prices better than the
                    contra-side NBBO - for orders qualifying as R, RI or RND
                </option>
                <option value='RBNZ'>
                    RBNZ - Remove when executing at prices better than the
                    contra-side NBBO Sub Dollar - for orders qualifying as RZ,
                    RIZ or RNZ
                </option>
                <option value='RDA'>RDA - Remove d-Quote on Arrival</option>
                <option value='RDB'>
                    RDB - Remove Discretionary Peg Order - on
                    arrival/re-sweeping at prices other than midpoint
                </option>
                <option value='RDM'>
                    RDM - Remove Discretionary Peg Order - on
                    arrival/re-sweeping at the midpoint
                </option>
                <option value='RDZ'>
                    RDZ - Remove Discretionary Peg Order - on
                    arrival/re-sweeping at same side PBBO or below same side
                    PBBO
                </option>
                <option value='RI'>RI - Remove Regular Limit IOC</option>
                <option value='RIZ'>RIZ - Remove Sub Dollar IOC</option>
                <option value='RL'>RL - Remove Complex order from Leg markets</option>
                <option value='RML'>RML - Remove MPL Order</option>
                <option value='RMZ'>RMZ - Remove Sub Dollar MPL Order</option>
                <option value='RND'>RND - Remove Non-Displayed Order</option>
                <option value='RNZ'>
                    RNZ - Remove Non-Displayed Sub Dollar Execution
                </option>
                <option value='RRM'>
                    RRM - Remove MPL Retail Taker (Tag 57 = RET)
                </option>
                <option value='RRT'>
                    RRT - Remove Retail Taker Order (Retail Order Type 1/Type 2)
                </option>
                <option value='RZ'>RZ - Remove Sub Dollar</option>
                <option value='X'>
                    X - Routed - Away Market Execution/Non- NYSE Group
                </option>
                <option value='XA'>
                    XA - Routed - NYSE American Execution
                </option>
                <option value='XAA'>
                    XAA - Primary Only American Adding Liquidity
                </option>
                <option value='XAC'>XAC - Primary Only American MOC/LOC</option>
                <option value='XAO'>
                    XAO - Routed to NYSE American Opening/ Reopening Auction
                </option>
                <option value='XART'>
                    XART - 945/355 executed on NYSE American Retail
                </option>
                <option value='XAT'>
                    XAT - 945/355 Executed on Primary American
                </option>
                <option value='XAW'>
                    XAW - Primary Only Routed from Primary American
                </option>
                <option value='XAZ'>
                    XAZ - Routed - NYSE American Sub Dollar Execution
                </option>
                <option value='XB'>XB - Routed - NASDAQ BX</option>
                <option value='XBZ'>XBZ - Routed - NASDAQ BX Sub Dollar</option>
                <option value='XC'>
                    XC - Routed - National Stock Exchange
                </option>
                <option value='XCZ'>
                    XCZ - Routed - National Stock Exchange Sub Dollar
                </option>
                <option value='XD'>XD - Routed - FINRA ADF</option>
                <option value='XDA'>
                    XDA - Primary Only Adding/Removing Liquidity
                </option>
                <option value='XDRT'>XDRT - 945/355 Routed Retail</option>
                <option value='XDT'>
                    XDT - 945/355 Executed on Primary FINRA ADF
                </option>
                <option value='XDZ'>XDZ - Routed - FINRA ADF Sub Dollar</option>
                <option value='XH'>XH - Routed - MIAX</option>
                <option value='XHZ'>XHZ - Routed - MIAX Sub Dollar</option>
                <option value='XI'>XI - Routed - IOI Ping</option>
                <option value='XIZ'>XIZ - Routed - IOI Ping Sub Dollar</option>
                <option value='XJ'>XJ - Routed - EDGA</option>
                <option value='XJZ'>XJZ - Routed - EDGA Sub Dollar</option>
                <option value='XK'>XK - Routed - EDGX</option>
                <option value='XKZ'>XKZ - Routed - EDGX Sub Dollar</option>
                <option value='XL'>XL - Routed – LTSE</option>
                <option value='XLA'>
                    XLA - Primary Only Adding/Removing Liquidity
                </option>
                <option value='XLRT'>
                    XLRT - 945/355 executed on Primary - Retail
                </option>
                <option value='XLT'>XLT - 945/355 executed on Primary</option>
                <option value='XLZ'>XLZ - Routed – LTSE Sub Dollar</option>
                <option value='XLZD'>XLZD - Primary Only Sub Dollar</option>
                <option value='XM'>XM - Routed - Chicago Stock Exchange</option>
                <option value='XMZ'>
                    XMZ - Routed - Chicago Stock Exchange Sub Dollar
                </option>
                <option value='XN'>XN - Routed - NYSE Execution</option>
                <option value='XNA'>XNA - Primary Only Adding Liquidity</option>
                <option value='XNC'>XNC - Primary Only MOC/LOC</option>
                <option value='XNO'>
                    XNO - Routed to NYSE Opening/Reopening Auction
                </option>
                <option value='XNRT'>
                    XNRT - 945/355 executed on NYSE Retail
                </option>
                <option value='XNT'>XNT - 945/355 Executed on Primary</option>
                <option value='XNW'>
                    XNW - Primary Only Routed from Primary
                </option>
                <option value='XNZ'>
                    XNZ - Routed - NYSE Sub Dollar Execution
                </option>
                <option value='XOA'>
                    XOA - Routed - NYSE American Options Execution
                </option>
                <option value='XOB'>XOB - Routed - BOX</option>
                <option value='XOC'>XOC - Routed - CBOE</option>
                <option value='XOD'>XOD - Routed - MIAX Emerald</option>
                <option value='XOE'>XOE - Routed - CBOE EDGX</option>
                <option value='XOH'>XOH - Routed - NASDAQ GEMX</option>
                <option value='XOI'>XOI - Routed - NASDAQ ISE</option>
                <option value='XOJ'>XOJ - Routed - NASDAQ MRX</option>
                <option value='XOM'>XOM - Routed - NASDAQ MIAX</option>
                <option value='XOP'>
                    XOP - Routed - NYSE Arca Options Execution
                </option>
                <option value='XOQ'>XOQ - Routed - NASDAQ</option>
                <option value='XOR'>XOR - Routed - MIAX Pearl</option>
                <option value='XOS'>XOS - Routed - MIAX Sapphire</option>
                <option value='XOT'>XOT - Routed - NASDAQ BX</option>
                <option value='XOU'>XOT - Routed - MEMX Options</option>
                <option value='XOW'>XOW - Routed - CBOE C2</option>
                <option value='XOX'>XOX - Routed - NASDAQ PHLX</option>
                <option value='XOZ'>XOZ - Routed - CBOE BZX</option>
                <option value='XP'>
                    XP - Routed - NYSE Arca Equities Execution
                </option>
                <option value='XPA'>
                    XPA - Primary Only ARCA Adding Liquidity
                </option>
                <option value='XPC'>XPC - Primary Only ARCA MOC/LOC</option>
                <option value='XPO'>
                    XPO - Routed to NYSE Arca Equities Opening/Reopening
                    Execution
                </option>
                <option value='XPRT'>
                    XPRT - 945/355 executed on NYSE Arca Retail
                </option>
                <option value='XPT'>
                    XPT - 945/355 Executed on Primary ARCA
                </option>
                <option value='XPW'>
                    XPW - Primary Only Routed from Primary ARCA
                </option>
                <option value='XPZ'>
                    XPZ - Routed - NYSE Arca Equities Sub Dollar Execution
                </option>
                <option value='XQ'>XQ - Routed - NASDAQ Execution</option>
                <option value='XQA'>
                    XQA - Primary Only to Nasdaq Adding/Removing
                </option>
                <option value='XQRT'>
                    XQRT - 945/355 executed on Nasdaq Retail
                </option>
                <option value='XQT'>
                    XQT - Primary Only to Nasdaqq945/345
                </option>
                <option value='XQZ'>
                    XQZ - Routed - NASDAQ Execution Sub Dollar
                </option>
                <option value='XQZD'>
                    XQZD - Primary Only to Nasdaq Subdollar
                </option>
                <option value='XU'>XU - Routed – MEMX</option>
                <option value='XUZ'>XUZ - Routed – MEMX Sub Dollar</option>
                <option value='XV'>XV - Routed - IEX</option>
                <option value='XVA'>
                    XVA - Primary Only to IEX Adding/Removing
                </option>
                <option value='XVRT'>
                    XVRT - 945/355 executed on IEX Retail
                </option>
                <option value='XVT'>XVT - Primary Only to IEX 945/345</option>
                <option value='XVZ'>XVZ - Routed - IEX Sub Dollar</option>
                <option value='XVZD'>
                    XVZD - Primary Only to IEX Subdollar
                </option>
                <option value='XWA'>XWA - Routed - ATS</option>
                <option value='XWZD'>XWZD - Routed - ATS Sub-Dollar</option>
                <option value='XX'>XX - Routed - NASDAQ PSX</option>
                <option value='XXZ'>
                    XXZ - Routed - NASDAQ PSX Sub Dollar
                </option>
                <option value='XY'>XY - Routed - BATY</option>
                <option value='XYZ'>XYZ - Routed - BATY Sub Dollar</option>
                <option value='XZ'>XZ - Routed - BATZ</option>
                <option value='XZA'>
                    XZA - Primary Only to BATZ Adding/Removing
                </option>
                <option value='XZRT'>
                    XZRT - 945/355 executed on BATZ Retail
                </option>
                <option value='XZT'>XZT - Primary Only to BATZ 945/345</option>
                <option value='XZZ'>XZZ - Routed - BATZ Sub Dollar</option>
                <option value='XZZD'>
                    XZZD - Primary Only to BATZ Subdollar
                </option>
                <option value='Z'>
                    Z - Limit IOC Cross (Cross Execution only)
                </option>
                <option value='ZB'>ZB - Broker Cross</option>
                <option value='ZC'>ZC - Customer to Customer Cross</option>
                <option value='ZFC'>ZFC - CUBE Facilitation Contra</option>
                <option value='ZFI'>ZFI - CUBE Facilitation Initiating</option>
                <option value='ZFR'>ZFR - CUBE Facilitation Response</option>
                <option value='ZBZ'>ZBZ - Broker Cross Subdollar</option>
                <option value='ZOC'>ZOC - Open Outry Complex</option>
                <option value='ZOS'>ZOS - Open Outcry Single Leg</option>
                <option value='ZPC'>ZPC - CUBE PI Contra</option>
                <option value='ZPI'>ZPI - CUBE PI Initiating</option>
                <option value='ZPR'>ZPR - CUBE PI Response</option>
                <option value='ZQ'>
                    ZQ - Qualified Contingent Cross (QCC)
                </option>
                <option value='ZSC'>ZSC - AON CUBE Contra</option>
                <option value='ZSI'>ZSI - AON CUBE Initiating</option>
                <option value='ZSR'>ZSR - AON CUBE Response</option>
                <option value='ZT'>ZT - Limit QCT Cross</option>
                <option value='ZTZ'>ZTZ - Limit QCT Cross Sub Dollar</option>
            </NySelectInput>
        );
    }
}
