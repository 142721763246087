import makeStyles from '@material-ui/core/styles/makeStyles';
import {isEmpty} from 'lodash-es';
import {
    NyAlert,
    NyLoader,
    NyPropertyList
} from 'nyse-web-tools-common/lib/react';
import React, {useEffect, useMemo, useState} from 'react';

import {
    tradeActionBothSideColumnDefs,
    tradeActionOneSideColumnDefs
} from '../../../configs/columnDefs/tradeActionDetails.columnDefs';
import useResource from '../../../hooks/useResource.hook';
import {
    TradeSummaryProps,
    myOwnership,
    tradeDetailResource
} from '../../../resources/tradeSummary.resource';
import {TabMenu} from '../../navigation/tabMenu';
import {TradeAdjustmentForm} from './forms/tradeAdjustment.form';
import {TradeAllocateForm} from './forms/tradeAllocate.form';
import {TradeSplitForm} from './forms/tradeSplit.form';
import {TradeUnallocateForm} from './forms/tradeUnallocate.form';
import {TRADE_ACTION_TABS} from './tradeAction.tabs';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100% - 60px)',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    tabMenu: {
        minHeight: 25
    },
    nyPropertyList: {
        height: '80%'
    }
}));

export const TradeActionModal: React.FC<TradeSummaryProps> = React.memo(
    (props) => {
        const modalGetResource = useResource(tradeDetailResource);
        const classes = useStyles(props);
        const [permissionedTabs, setPermissionedTabs] = useState(
            TRADE_ACTION_TABS
        );

        const tradeAdjustmentTabs = useMemo(
            () => [
                ...TRADE_ACTION_TABS.filter((current) =>
                    props.data.allowedTradeActions.includes(current.value)
                )
            ],
            [props]
        );

        const [currentTab, setCurrentTab] = useState(
            tradeAdjustmentTabs[0].value
        );

        useEffect(() => {
            modalGetResource.get(
                {
                    crd: props.data.crd,
                    selectedSide: props.data.selectedSide
                },
                {
                    dealNum: props.data.dealNum,
                    origTdate: props.data.origTdate,
                    evtTyp: props.data.evtTyp
                }
            );
            setPermissionedTabs(tradeAdjustmentTabs);
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <div className={classes.root}>
                <TabMenu
                    className={classes.tabMenu}
                    tabs={permissionedTabs}
                    onChange={setCurrentTab}
                    value={currentTab}
                />
                <NyAlert
                    title={`Unable to get trade summary details for Deal Id ${props.data.dealNum}.`}
                    type='danger'
                    show={
                        !modalGetResource.pending &&
                        !isEmpty(modalGetResource.error)
                    }
                />
                <NyLoader
                    show={modalGetResource.pending && !modalGetResource.error}
                />
                <NyAlert
                    title={
                        'An update to a related deal is in progress and modifications are not allowed at this time.'
                    }
                    type='warning'
                    show={modalGetResource.data.updateToTradeIsInProgress}
                />
                <NyAlert
                    title={
                        'Trade cannot be edited, as one of the MPIDs / Clearing Numbers and/or MMID on this trade is inactive.'
                    }
                    type='warning'
                    show={modalGetResource.data.tradeCanBeUpdated === false}
                />
                {!modalGetResource.pending && !isEmpty(modalGetResource.data) && (
                    <div className='row'>
                        <div className='col-xs-9 col-sm-9 col-md-9 col-lg-9 flex-column'>
                            {currentTab === 'ADJUST' && (
                                <TradeAdjustmentForm
                                    data={modalGetResource.data}
                                    csgData={props.data}
                                    crd={props.data.crd}
                                />
                            )}
                            {currentTab === 'SPLIT' && (
                                <TradeSplitForm
                                    data={modalGetResource.data}
                                    csgData={props.data}
                                    crd={props.data.crd}
                                />
                            )}
                            {currentTab === 'ALLOCATE' && (
                                <TradeAllocateForm
                                    data={modalGetResource.data}
                                    csgData={props.data}
                                    crd={props.data.crd}
                                />
                            )}
                            {currentTab === 'UNALLOCATE' && (
                                <TradeUnallocateForm
                                    data={modalGetResource.data}
                                    csgData={props.data}
                                    crd={props.data.crd}
                                />
                            )}
                        </div>
                        <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 flex-column'>
                            <NyPropertyList
                                className={`flex-column ${classes.nyPropertyList}`}
                                columnDefs={
                                    modalGetResource.data.selectedSide ===
                                    myOwnership.BOTH
                                        ? tradeActionBothSideColumnDefs
                                        : tradeActionOneSideColumnDefs
                                }
                                object={modalGetResource.data}
                                isLoading={modalGetResource.pending}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
);
