import Decimal from 'decimal.js';
import {omit} from 'lodash-es';
import {Joda} from 'nyse-web-tools-common/lib/utils';

import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getOpraEntries = (params) => ({
    types: [
        events.OPRA_ENTRIES_GET,
        events.OPRA_ENTRIES_SUCCESS,
        events.OPRA_ENTRIES_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/opra-entries',
            method: 'GET',
            params: cleanObject({
                ...omit(params, ['matcherTime']),
                date: params.date
                    ? Joda.format(params.date, 'yyyy-MM-dd')
                    : undefined,
                tradeExecDate: params.tradeExecDate
                    ? Joda.format(params.tradeExecDate, 'yyyy-MM-dd')
                    : undefined
            })
        }
    }
});

export const createOpraEntry = (params) => ({
    types: [
        events.OPRA_ENTRY_CREATE_POST,
        events.OPRA_ENTRY_CREATE_SUCCESS,
        events.OPRA_ENTRY_CREATE_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/opra-entries',
            method: 'POST',
            data: cleanObject({
                ...omit(params, ['tradeDate']),
                opraSeqNumber:
                    params.opraEntryType === 'SEQUENCE' && params.opraSeqNumber
                        ? new Decimal(params.opraSeqNumber).toNumber()
                        : undefined,
                expirationDate: params.expirationDate
                    ? Joda.format(params.expirationDate, 'yyyy-MM-dd')
                    : undefined,
                printTime: params.printTime
                    ? params.printTime.toString()
                    : undefined
            })
        }
    }
});

export const removeOpraEntry = (opraId) => ({
    types: [
        events.OPRA_ENTRY_REMOVE_DELETE,
        events.OPRA_ENTRY_REMOVE_SUCCESS,
        events.OPRA_ENTRY_REMOVE_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: `api/opra-entries/${opraId}`,
            method: 'DELETE'
        }
    }
});

export const updateOpraEntry = (params) => ({
    types: [
        events.OPRA_ENTRY_UPDATE_PUT,
        events.OPRA_ENTRY_UPDATE_SUCCESS,
        events.OPRA_ENTRY_UPDATE_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: `api/opra-entries/${params.opraId}`,
            method: 'PUT',
            data: cleanObject({
                ...params,
                status: params.tplusNReconciliationAllowed
                    ? 'COMPLETED'
                    : params.status,
                expirationDate: params.expirationDate
                    ? Joda.format(params.expirationDate, 'yyyy-MM-dd')
                    : undefined,
                price: params.price
                    ? new Decimal(params.price).toNumber()
                    : undefined,
                strikePrice: params.strikePrice
                    ? new Decimal(params.strikePrice).toNumber()
                    : undefined,
                quantity: params.quantity
                    ? new Decimal(params.quantity).toNumber()
                    : undefined,
                printTime: params.printTime
                    ? params.printTime.toString()
                    : undefined
            })
        }
    }
});
