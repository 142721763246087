import {sentinelMw} from '../api';
import environment from '../configs/environment.config';

// although this is related to tradeAdjustment, a resource file should only contain
// configs for the same resource. that includes GET/PUT/POST/DELETE on the same url.
// when it's a completely different api, use a separate resource file
export const sndrSubIdResource = {
    api: sentinelMw,
    url: 'viewgborefdata',
    useCache: !(environment.testEnv || environment.integrationEnv),
    parseResponse: ({data}) =>
        data.mmIds
            .filter((mmId) => mmId.status === 'Active')
            .map((data) => ({label: data.id, value: data.id})),
    defaultValue: []
};
