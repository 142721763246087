import React, {useMemo, useRef, useState, useEffect, useCallback} from 'react';
import * as yup from 'yup';
import cn from 'classnames';
import { NyFieldInput } from 'nyse-web-tools-common/lib/react/types';
import { makeStyles } from '@material-ui/core/styles';
import NyValidationTooltip from 'nyse-web-tools-common/lib/react/form/NyValidationTooltip/NyValidationTooltip';


type NyMultiEmailInputProps = NyFieldInput & {
    name?: string;
    emails?: string[];
    maxLength?: number;
    placeholder?: string;
    value?: string;
    separator?: string;
};

const useStyles = makeStyles((theme)=>({
    chips : {
        minHeight: '22px',
        lineHeight: 1,
        fontSize: '1em',
        borderColor: (props: NyFieldInput) => props.valid ? 'transparent' : ' #c9001a',
        backgroundColor: (props: NyFieldInput) => props.disabled || props.readOnly ?
            'rgba(210, 212, 211, 0.25)' :
            'white',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        //transition: 'background .5s',
        border: '1px solid transparent',
        boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)'
    },
    chipsInput : {
        display: 'inline-block',
        width: '40%',
        minHeight: '22px',
        marginTop: '2px',
        marginBottom: '2px',
        marginLeft: '5px',
        border: 0,
        outline: 'none',
        fontSize: '0.9rem'
    },
    chip : {
        display: 'inline-block',
        marginTop: '2px',
        marginBottom: '2px',
        marginLeft: '1px',
        marginRight: (props: NyFieldInput) => props.readOnly ? '1px' : '25px',
        position: 'relative'
    },
    chipValue : {
        display: 'inline-block',
        padding: '5px',
        paddingLeft: '15px',
        paddingRight: '7.5px',
        background: '#555',
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: '15px 0 0 15px'
    },
    chipValueOnly : {
        display: 'inline-block',
        padding: '5px',
        paddingLeft: '15px',
        paddingRight: '15px',
        background: '#555',
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: '15px 15px 15px 15px'
    },
    chipDeleteButton : {
        background: '#555',
        color: '#fff',
        border: 0,
        borderRadius: '0 15px 15px 0',
        padding: '5px 10px',
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: '-25px',
        lineHeight: '0.5',
        fontWeight: 'bold'
    },
}));


const MultiEmailInput: React.FC<NyMultiEmailInputProps> = React.memo(
    (props) => {

        const classes = useStyles(props);

        const flag = useRef(0);
        const emailInputRef = useRef(null);

        const [values, setValues] = useState({
            emails : [],
            value: '',
            focused: false,
            inputValue: ''
        });


        useEffect(()=>{
            if (flag.current == 0 ) {
                const items = props.value === undefined || props.value === ''
                                ? []
                                : props.value.split((props.separator === undefined || props.separator ==='')?',':props.separator);
                const vItems = items.filter(e => yup.string().email().isValidSync(e.trim()));
                setValues({
                    ...values,
                    emails: vItems
                });
            }
            flag.current = 1;
        }, [props.value])

        const separator = useMemo(()=>(props.separator === undefined || props.separator ==='')?',':props.separator, [props.separator]);

        const handleDelete = useCallback((e, index) => {
            e.stopPropagation();
            const items =  [
                ...values.emails.slice(0, index),
                ...values.emails.slice(index + 1)
            ];
            setValues({
                ...values,
                emails: items
            });
        }, [values.emails]);

        useEffect(() =>{
            if (props.onChange) {
                props.onChange(values?.emails.join(separator));
            }
        }, [values.emails, props.onChange])

        const handleOnBlur = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {

            const email = (e.currentTarget.value).trim();
            if (email && yup.string().email().isValidSync(email)) {
                setValues({
                    ...values,
                    emails: [...values.emails, email],
                    inputValue: '',
                    focused: false
                });
            }
            else {
                setValues({
                    ...values,
                    focused: false
                });
            }
        }, [values]);

        const handleOnFocus = useCallback(() => {
            setValues({
                ...values,
                focused: true
            });
        },[values]);

        const handleOnKeydown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
            switch (e.key) {
                case 'Enter':
                    e.preventDefault();
                    break;
                case 'Backspace':
                    if (!e.currentTarget.value) {
                        setValues({
                            ...values,
                            emails: [...values.emails.slice(0, -1)]
                        });
                    }
                    break;
                default:
            }
        }, [values]);

        const handleOnKeyup =useCallback(
            (e: React.KeyboardEvent<HTMLInputElement>) => {
                switch (e.key) {
                    case 'Enter':
                        const email = (!e.currentTarget.value) ? undefined: (e.currentTarget.value).trim();
                        if (email && yup.string().email().isValidSync(email)) {
                            setValues({
                                ...values,
                                emails: [...values.emails, email],
                                inputValue: '',
                                focused: false
                            });
                        }
                        break;
                    default:
                }
            }, [values]);


        const focusInput = (event) => {
            let children = event.target.children;
            if (children.length) children[children.length - 1].focus();
        };

        const onChange = useCallback((e)=> {
            const inputValue = e.currentTarget.value;
            setValues({
                ...values,
                inputValue
            })
            if (props.onChange) {
                const items = (inputValue != undefined && inputValue.length) > 0
                    ? [...values.emails, inputValue]
                    : values.emails;
                props.onChange(items.join(separator));
            }
        }, [values, props.onChange]);

        return (
            <NyValidationTooltip validation={props.validation}>
                <div className={cn(classes.chips)}
                    onClick={ (props.readOnly || props.disabled)
                        ? () => {}
                        : () => emailInputRef.current.focus()
                    }>
                    {values.emails.map((email: string, index: number) => {
                        return props.readOnly || props.disabled ? (
                            <span className={classes.chip} key={index}>
                                <span className={classes.chipValueOnly}>{email}</span>
                            </span>
                        ) : (
                            <span className={classes.chip} key={index}>
                                <span className={classes.chipValue}>{email}</span>
                                <button type="button" className={classes.chipDeleteButton}
                                        onClick={(e)=>handleDelete(e, index)}>x</button>
                            </span>
                        );
                    })}
                    {!(props.readOnly || props.disabled) && (<input
                        className={classes.chipsInput}
                        maxLength={64}
                        name={props.name}
                        placeholder={props.placeholder}
                        onBlur={handleOnBlur}
                        onChange={onChange}
                        onFocus={handleOnFocus}
                        onKeyDown={handleOnKeydown}
                        onKeyUp={handleOnKeyup}
                        value={values.inputValue}
                        ref={emailInputRef}
                    />)
                    }
                </div>
            </NyValidationTooltip>
        );
    }
);

MultiEmailInput.defaultProps = {
    disabled: false,
    maxLength: 500,
    readOnly: false,
    required: false,
    separator: ','
};

export default MultiEmailInput;
