import {sortBy} from 'lodash-es';

import {cdmMw} from '../api';

export type Documents = {
    category: string;
    index: number;
    resources: {
        fileName?: string;
        title?: string;
        type: string;
        url?: string;
    }[];
}[];

export default {
    api: cdmMw,
    url: 'api/documents',
    useCache: true,
    parseResponse: ({data}): Documents => {
        const resources = [...data.files, ...data.links].reduce(
            (resources, resource) => ({
                ...resources,
                [resource.categories[0]]: [
                    ...(resources[resource.categories[0]] || []),
                    {
                        ...resource,
                        fileName: resource.fileName
                            ? resource.fileName.split('.')[0]
                            : resource.fileName,
                        type: resource.type || 'link'
                    }
                ]
            }),
            {}
        );

        return Object.keys(resources)
            .sort()
            .map((key, index) => ({
                category: key,
                index,
                resources: sortBy(resources[key], ({title}) =>
                    title.toLowerCase()
                )
            }));
    },
    defaultValue: []
};
