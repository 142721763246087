import {LocalDateTime} from '@js-joda/core';
import {omit} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridProvider,
    AgGridTimestamp,
    AgGridToggleShowFilters,
    NyDateTimeRangeInput,
    NySymbolInput,
    NyTextInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import React from 'react';

import {NavigationSectionMenu} from '../components/navigation/navigation.section.menu';
import {FirmSelect} from '../components/selects/firm.select';
import {FirmParticipantSelect} from '../components/selects/firmParticipant.select';
import {SymbolGroupSelect} from '../components/selects/symbolGroup.select';
import {SymbolGroupFilterSelect} from '../components/selects/symbolGroupFilter.select';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {getMyVolumesSearch} from '../stores/myVolumes/myVolumesSearch.actions';

@connect(['firms', 'columnDefs', 'myVolumesSearch', 'platform', 'route'], {
    getMyVolumesSearch,
    setColumns
})
export class MyVolumesSearchPage extends React.Component {
    state = {
        filterValues: {
            symbolGroupType: ''
        },
        views: [
            {
                title: 'CADV',
                url: '/:platform/volumes/cadv'
            },
            {
                title: 'Broker Rank',
                url: '/:platform/volumes/broker-rank'
            },
            {
                title: 'Trade Count',
                url: '/:platform/volumes/trade-count'
            },
            {
                title: 'Search',
                url: '/:platform/volumes/search'
            }
        ]
    };

    _dateRange = {
        max: joda.endOfDay().plusSeconds(1),
        min: joda.startOfDay().minusMonths(6).minusSeconds(1)
    };

    _initialFilters = {
        crd: this.props.route.params.crd || (this.props.firms[0] || {}).crd,
        date: {
            start: joda.startOfDay(),
            end: joda.endOfDay()
        },
        participantId: [this.props.route.params.participantId].filter((p) => p)
    };

    _initValues = {
        date: {
            start: this.props.route.params.date
                ? joda.toStartOfDay(
                    LocalDateTime.parse(this.props.route.params.date)
                )
                : undefined,
            end: this.props.route.params.date
                ? joda.toEndOfDay(
                    LocalDateTime.parse(this.props.route.params.date),
                    true
                )
                : undefined
        }
    };

    _onFiltersChanged = (filterValues) => {
        const queryParams = omit(filterValues, ['date', 'symbol']);

        this.setState({
            filterValues,
            queryParams: Object.keys(queryParams).reduce(
                (acc, k) => ({
                    ...acc,
                    [k]: Array.isArray(queryParams[k])
                        ? queryParams[k][0]
                        : queryParams[k]
                }),
                {}
            )
        });
    };

    _onFirmChange = ({setValue}) => {
        setValue('participantId', '');
    };

    _onSymbolGroupChange = ({setValue}) => {
        setValue('symbolGroupValue', undefined);
    };

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                        <NavigationSectionMenu
                            queryParams={this.state.queryParams}
                            views={this.state.views}
                        />
                    </div>
                </div>
                <AgGridProvider
                    columnDefs={this.props.columnDefs.myVolumesSearch}
                    columnNamespace='myVolumesSearch'
                    filtersClassName='row space-bottom-1'
                    initialFilters={this._initialFilters}
                    onColumnsChanged={this.props.setColumns}
                    onFiltersChanged={this._onFiltersChanged}
                    onLoad={this.props.getMyVolumesSearch}
                    submitOnLoad={true}
                    submitOnNamespaceChanged={true}
                    {...this.props.myVolumesSearch}>
                    <AgGridProvider.Filters>
                        <div className='form-group col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <label>Date Range</label>
                            <NyDateTimeRangeInput
                                className='form-control'
                                format='MM/dd/yyyy'
                                initialEndValue={this._initValues.date.start}
                                initialStartValue={this._initValues.date.end}
                                max={this._dateRange.max}
                                min={this._dateRange.min}
                                name='date'
                                required
                            />
                        </div>
                        {this.props.firms.length > 1 && (
                            <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                                <label>Firm</label>
                                <FirmSelect
                                    name='crd'
                                    onChange={this._onFirmChange}
                                    required={true}
                                    showInactive={true}
                                />
                            </div>
                        )}
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>ETPID / MPID(s)</label>
                            <FirmParticipantSelect
                                crd={this.state.filterValues.crd}
                                isMulti={true}
                                name='participantId'
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Symbol(s)</label>
                            <NySymbolInput
                                name='symbol'
                                initialValue={this.props.route.params.symbol}
                                placeholder='delimited by ","'
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Broker Badge</label>
                            <NyTextInput name='badge' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Session Id</label>
                            <NyTextInput name='sessionId' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>MMID</label>
                            <NyUppercaseInput name='mmId' />
                        </div>
                        <div className='form-group col-xs-12 col-sm-3 col-md-2 col-lg-2'>
                            <label>Symbol Group</label>
                            <SymbolGroupFilterSelect
                                onChange={this._onSymbolGroupChange}
                            />
                        </div>
                        <div className='form-group col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                            <SymbolGroupSelect
                                group={this.state.filterValues.symbolGroupType}
                                isMulti={true}
                            />
                        </div>
                    </AgGridProvider.Filters>

                    <div className='row space-bottom-1'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                            <div className='flex-row flex-align-bottom'>
                                <AgGridProvider.SubmitBtn>
                                    Search
                                </AgGridProvider.SubmitBtn>
                                <AgGridProvider.ResetBtn>
                                    Reset
                                </AgGridProvider.ResetBtn>
                                <div className='btn-circle-grp flex-pull-right'>
                                    <AgGridToggleShowFilters />
                                    <AgGridExportMenu
                                        customHeader={
                                            this.props.myVolumesSearch.timestamp
                                        }
                                        exportFileName='myVolumesSearch'
                                        exportSheetName={
                                            this.props.platform.name
                                        }
                                    />
                                    <AgGridColumnMenuTrigger />
                                </div>
                            </div>
                        </div>
                    </div>

                    <AgGrid reactNext={true} />

                    <div className='row space-top-1'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                            <AgGridTimestamp timestampPrefix='Volumes' />
                        </div>
                    </div>

                    <div className='disclaimer space-top-1'>
                        This information is provided 'as is', and NYSE, NYSE
                        Arca, NYSE American, NYSE Arca Options, NYSE American
                        Options and their respective affiliates do not warrant
                        the accuracy, adequacy, timeliness or completeness of
                        this information. The information, which excludes any
                        data from the current trading day, is intended to be
                        used for informational purposes only and will not be
                        used to determine qualification for any rebates or
                        incentives.
                    </div>
                </AgGridProvider>
            </>
        );
    }
}
