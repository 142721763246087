import React from 'react';
import {agGridCellRenderer, SelectInput, TextInput} from 'nyse-web-tools-common/lib/react';
import createRendererColumnDef from '../../components/editor/AgRender';
import {MultiEditClearingNumberSelect} from '../../components/selects/multiEditClearingNumber.select';
import {MultiEditMmIdSelect} from '../../components/selects/multiEditMmid.select';
import {MultiEditOpenCloseSelect} from '../../components/selects/multiEditOpenClose.select';
import {MultiEditReasonCodeSelect} from '../../components/selects/multiEditReasonCode.select';
import {MultiEditStatusIcon} from '../../components/modals/tradeSummary/multiEditStatus.icon';
import {
    custFirmChoices,
    openCloseChoices,
    SideTradeType
} from '../../resources/tradeSummary.resource';
import {mapApiValue} from '../../utils/utilities';

export const tradeActionBothSideColumnDefs = [
    {
        headerName: 'Original Deal Id',
        field: 'dealIdOrig',
        type: 'numericString'
    },
    {
        headerName: 'Original Trade Date',
        field: 'origTdate',
        type: 'date'
    },
    {
        headerName: 'Ref Exec Time',
        field: 'refExecTs',
        type: 'dateTime'
    },
    {
        headerName: 'Deal Id',
        field: 'dealNum',
        type: 'numericString'
    },
    {
        field: 'symb',
        headerName: 'OSI Symbol'
    },
    {
        field: 'underlyingSymbol',
        headerName: 'Underlying Symbol'
    },
    {
        field: 'osiRootSymb',
        headerName: 'OSI Root Symbol'
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        type: 'date'
    },
    {
        field: 'putCall',
        headerName: 'Put/Call'
    },
    {
        field: 'strikePrice',
        headerName: 'Strike Price'
    },
    {
        field: 'execPrc',
        headerName: 'Price'
    },
    {
        field: 'execQty',
        headerName: 'Qty'
    },
    {
        field: 'custFirmId',
        headerName: 'Customer side: MPID'
    },
    {
        field: 'custOpenClose',
        headerName: 'Customer side: Open/Close',
        valueGetter: ({data}) =>
            mapApiValue(data, 'custOpenClose', openCloseChoices)
    },
    {
        field: 'custFirm',
        headerName: 'Customer side: Customer or Firm',
        valueGetter: ({data}) => mapApiValue(data, 'custFirm', custFirmChoices)
    },
    {
        field: 'custSndrSubId',
        headerName: 'Customer side: MMID'
    },
    {
        field: 'custClearingNumber',
        headerName: 'Customer side: Clearing #'
    },
    {
        field: 'custSubmittingBkrId',
        headerName: 'Customer side: Badge'
    },
    {
        field: 'custCmta',
        headerName: 'Customer side: CMTA'
    },
    {
        field: 'custAccount',
        headerName: 'Customer side: Account'
    },
    {
        field: 'custOptnlData',
        headerName: 'Customer side: Optional Data'
    },
    {
        field: 'contraOrdFirmId',
        headerName: 'Contra side: MPID'
    },
    {
        field: 'contraOrdOpenClose',
        headerName: 'Contra side: Open/Close',
        valueGetter: ({data}) =>
            mapApiValue(data, 'custOpenClose', openCloseChoices)
    },
    {
        field: 'contraOrdCustFirm',
        headerName: 'Contra side: Customer or Firm',
        valueGetter: ({data}) => mapApiValue(data, 'custFirm', custFirmChoices)
    },
    {
        field: 'contraOrdSndrSubId',
        headerName: 'Contra side: MMID'
    },
    {
        field: 'contraOrdClearingNumber',
        headerName: 'Contra side: Clearing #'
    },
    {
        field: 'contraOrdSubmittingBkrId',
        headerName: 'Contra side: Badge'
    },
    {
        field: 'contraOrdCmta',
        headerName: 'Contra side: CMTA'
    },
    {
        field: 'contraOrdAccount',
        headerName: 'Contra side: Account'
    },
    {
        field: 'contraOrdOptnlData',
        headerName: 'Contra side: Optional Data'
    }
];

export const tradeActionOneSideColumnDefs = [
    {
        headerName: 'Original Deal Id',
        field: 'dealIdOrig',
        type: 'numericString'
    },
    {
        headerName: 'Original Trade Date',
        field: 'origTdate',
        type: 'date'
    },
    {
        headerName: 'Ref Exec Time',
        field: 'refExecTs',
        type: 'dateTime'
    },
    {
        headerName: 'Deal Id',
        field: 'dealNum',
        type: 'numericString'
    },
    {
        field: 'symb',
        headerName: 'OSI Symbol'
    },
    {
        field: 'underlyingSymbol',
        headerName: 'Underlying Symbol'
    },
    {
        field: 'osiRootSymb',
        headerName: 'OSI Root Symbol'
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        type: 'date'
    },
    {
        field: 'putCall',
        headerName: 'Put/Call'
    },
    {
        field: 'strikePrice',
        headerName: 'Strike Price'
    },
    {
        field: 'execPrc',
        headerName: 'Price'
    },
    {
        field: 'execQty',
        headerName: 'Qty'
    },
    {
        field: 'custFirmId',
        headerName: 'MPID'
    },
    {
        field: 'custOpenClose',
        headerName: 'Open/Close',
        valueGetter: ({data}) =>
            mapApiValue(data, 'custOpenClose', openCloseChoices)
    },
    {
        field: 'custFirm',
        headerName: 'Customer or Firm',
        valueGetter: ({data}) => mapApiValue(data, 'custFirm', custFirmChoices)
    },
    {
        field: 'custSndrSubId',
        headerName: 'MMID'
    },
    {
        field: 'custClearingNumber',
        headerName: 'Clearing #'
    },
    {
        field: 'custSubmittingBkrId',
        headerName: 'Badge'
    },
    {
        field: 'custCmta',
        headerName: 'CMTA'
    },
    {
        field: 'custAccount',
        headerName: 'Account'
    },
    {
        field: 'custOptnlData',
        headerName: 'Optional Data'
    }
];

export const tradeActionMultiEditColumnDefs = [
    {
        headerName: 'Status',
        field: 'status',
        pinned: 'left',
        moveable: false,
        lockPosition: true,
        suppressToolPanel: true,
        cellRendererFramework: agGridCellRenderer((params) => <MultiEditStatusIcon {...params} />)
    },
    {
        field: 'custSide',
        headerName: 'Side',
    },
    {
        headerName: 'Original Deal Id',
        field: 'dealIdOrig',
        type: 'numericString'
    },
    {
        headerName: 'Original Trade Date',
        field: 'origTdate',
        type: 'date'
    },
    {
        headerName: 'Ref Exec Time',
        field: 'refExecTs',
        type: 'dateTime'
    },
    {
        headerName: 'Deal Id',
        field: 'dealNum',
        type: 'numericString'
    },
    {
        field: 'symb',
        headerName: 'OSI Symbol'
    },
    {
        field: 'underlyingSymbol',
        headerName: 'Underlying Symbol'
    },
    {
        field: 'osiRootSymb',
        headerName: 'OSI Root Symbol'
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        type: 'date'
    },
    {
        field: 'putCall',
        headerName: 'Put/Call'
    },
    {
        field: 'strikePrice',
        headerName: 'Strike Price'
    },
    {
        field: 'execPrc',
        headerName: 'Price'
    },
    {
        field: 'execQty',
        headerName: 'Qty'
    },
    createRendererColumnDef(
        {
            field: 'custFirm',
            headerName: 'Customer or Firm'
        },
        (params) => (
            <SelectInput
                name={params.name}
                value={params.value}
                onChange={params.onChange}
                options={custFirmChoices}
            />
        )
    ),
    {
        field: 'custFirmId',
        headerName: 'MPID',
        cellStyle: ({data}) => ({
            background: data?.custFirmIdIsUpdatedByUser ? 'yellow' : 'white'
        })
    },
    createRendererColumnDef(
        {
            field: 'custOpenClose',
            headerName: 'Open/Close'
        },
        (params) => (
            <MultiEditOpenCloseSelect
                {...params}
                name={params.name}
                value={params.value}
            />
        )
    ),
    createRendererColumnDef(
        {
            field: 'custSndrSubId',
            headerName: 'MMID'
        },
        (params) => (
            <MultiEditMmIdSelect
                {...params}
                name={params.name}
            />
        )
    ),
    createRendererColumnDef(
        {
            field: 'custClearingNumber',
            headerName: 'Clearing #'
        },
        (params) => (
            <MultiEditClearingNumberSelect
                {...params}
                name={params.name}
                value={params.value}
            />
        )
    ),
    createRendererColumnDef(
        {
            field: 'custSubmittingBkrId',
            headerName: 'Badge'
        },
        (params) => (
            params?.data?.custTradeType === SideTradeType.FLOOR_BROKER ||
            params?.data?.custTradeType === SideTradeType.FLOOR_BROKER_LOCAL_MM ||
            params?.data?.custTradeType === SideTradeType.FLOOR_BROKER_AWAY_MM ? (
                <SelectInput
                    name={params.name}
                    value={params.value}
                    onChange={params.onChange}
                    options={params?.context?.multiEdit?.custSubmittingBkrIdOptions || []}
                />
            ) : (
                <TextInput
                    name={params.name}
                    value={params.value}
                    disabled={!params?.data?.custSubmittingBkrId}
                    readOnly
                />
            )
        )
    ),
    createRendererColumnDef(
        {
            field: 'reasonCode',
            headerName: 'Reason Code',
            resizable: true,
            width: 350
        },
        (params) => (
            <MultiEditReasonCodeSelect
                {...params}
                name={params.name}
                value={params.value}
            />
        )
    ),
    createRendererColumnDef(
        {
            field: 'custCapStrategyId',
            headerName: 'Cap Strategy Id'
        },
        (params) => (
            !!params?.data?.outCry || (!!params?.data?.qcc &&
                [SideTradeType.FLOOR_BROKER, SideTradeType.FLOOR_BROKER_LOCAL_MM, SideTradeType.FLOOR_BROKER_AWAY_MM].includes(params.data.custTradeType)
            ) ? (
                <SelectInput
                    name={params.name}
                    value={params.value}
                    onChange={params.onChange}
                    options={params?.context?.multiEdit?.capStrategyIdOptions || []}
                />
            ) : (
                <TextInput
                    name={params.name}
                    value={params.value}
                    disabled
                    readOnly
                />
            )
        )
    ),
    createRendererColumnDef(
        {
            field: 'custCmta',
            headerName: 'CMTA'
        },
        (params) => (
            <TextInput
                maxLength={5}
                name={params.name}
                value={params.value}
                onChange={params.onChange}
            />
        )
    ),
    createRendererColumnDef(
        {
            field: 'custAccount',
            headerName: 'Account'
        },
        (params) => (
            <TextInput
                maxLength={20}
                name={params.name}
                value={params.value}
                onChange={params.onChange}
            />
        )
    ),
    createRendererColumnDef(
        {
            field: 'custOptnlData',
            headerName: 'Optional Data'
        },
        (params) => (
            <TextInput
                maxLength={16}
                name={params.name}
                value={params.value}
                onChange={params.onChange}
            />
        )
    )
];
