import {connectRouter, routerMiddleware} from 'connected-react-router';
import {get, merge, omit, orderBy} from 'lodash-es';
import passiveEventListener from 'nyse-web-tools-common/lib/utils/passiveEventListener';
import {applyMiddleware, compose, createStore} from 'redux';
import {getActionTypes, multiClientMiddleware} from 'redux-axios-middleware';
import thunk from 'redux-thunk';
import uuidv4 from 'uuid/v4';

import {cdmMw, nodeJs, sentinelMw, tdmMw} from '../api';
import environment from '../configs/environment.config';
import {events} from './events';

const combineEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const bindSyncReducer = (store, defaultState, version) => (state, action) => {
    const reducerKeys = Object.keys(store.reducer || {});

    const isInit = action.type === events._INIT_;
    const isFlush = action.type === events._SYNC_FLUSH_;
    const isRouteChange = action.type === events._SYNC_ROUTE_;
    const isSync =
        action.type === events._SYNC_RECEIVE_ &&
        store.key === action._key &&
        !store.stickyVersion;

    if (isSync) {
        // syncing from the adapter
        return action.payload;
    }

    if ((isInit || isFlush) && !store.adapter) {
        // initializing / flushing non-adapter store
        return store.defaultState;
    }

    if (
        !isInit &&
        !isSync &&
        !isFlush &&
        isRouteChange &&
        store.resetOnRouteChange
    ) {
        // if not initializing or syncing, but route changed...
        return store.defaultState;
    }

    if (isInit && store.adapter) {
        // initializing adapter store

        try {
            const storage =
                JSON.parse(window[store.adapter].getItem(store.key)) ||
                store.defaultState;

            const nextState = store.stickyVersion
                ? storage
                : storage._version !== version
                ? store.defaultState
                : storage;

            return merge({}, store.defaultState, {
                ...Object.keys(nextState).reduce((acc, key) => {
                    return {
                        ...acc,
                        [key]: store.excludes
                            ? omit(nextState[key], store.excludes)
                            : nextState[key]
                    };
                }, {}),
                _uuid: nextState._uuid
            });
        } catch (e) {
            return store.defaultState;
        }
    }

    if (isFlush && store.adapter && !store.stickyVersion) {
        // flushing adapter store
        const flushState = {
            ...store.defaultState,
            _uuid: uuidv4()
        };

        try {
            window[store.adapter].setItem(
                store.key,
                JSON.stringify(flushState)
            );
        } catch (e) {
            //
        }

        return flushState;
    }

    const type = reducerKeys.find((k) =>
        k === action.type || k.split(',').length > 0
            ? k.split(',').some((p) => p === action.type)
            : null
    );

    const nextState = type
        ? store.reducer[type](state, {
              ...action,
              _key: store.key,
              _defaultState: {
                  ...defaultState
              }
          })
        : state;

    const didStateChange = nextState !== state;

    // has state changed, and the store key has an adapter, then set it.
    if (didStateChange && store.adapter) {
        const parsedState = Object.keys(nextState).reduce(
            (acc, key) => ({
                ...acc,
                [key]: store.excludes
                    ? omit(nextState[key], store.excludes)
                    : nextState[key]
            }),
            {}
        );

        try {
            window[store.adapter].setItem(
                store.key,
                JSON.stringify({...parsedState, _uuid: uuidv4()})
            );
        } catch (e) {
            //
        }
    }

    return nextState;
};

const bindSyncListener = (store) => {
    store.dispatch({type: events._INIT_});

    passiveEventListener('storage', (event) => {
        try {
            if (event.storageArea === window.localStorage) {
                const nextState = JSON.parse(event.newValue);

                if (
                    nextState &&
                    nextState._uuid ===
                        get(store.getState()[event.key], '_uuid', null)
                ) {
                    return;
                }

                store.dispatch({
                    type: events._SYNC_RECEIVE_,
                    payload: nextState,
                    _key: event.key
                });
            }
        } catch (e) {
            // ignore other data format other than JSON
        }
    });
};

const combineReducers = (stores) => {
    const reducers = stores
        .filter(({reducer}) => reducer)
        .reduce(
            (acc, reducer) => ({
                ...acc,
                [reducer.key]: reducer.reducer
            }),
            {}
        );

    const selectors = orderBy(
        stores
            .filter(({selector}) => selector)
            .map((store) => ({
                ...store,
                priority: store.priority || 0
            })),
        ['priority']
    )
        .reverse()
        .reduce(
            (acc, reducer) => ({
                ...acc,
                [reducer.key]: reducer.selector
            }),
            {}
        );

    const reducerKeys = Object.keys(reducers || {});

    const selectorKeys = Object.keys(selectors || {});

    return (state = {}, action) => {
        let hasChanged = false;

        const nextState = {};

        for (let i = 0; i < reducerKeys.length; i++) {
            const key = reducerKeys[i];

            const reducer = reducers[key];

            const prevStateForReducer = state[key];

            const nextStateForReducer = reducer(prevStateForReducer, {
                ...action,
                _state: state
            });

            nextState[key] = nextStateForReducer;

            hasChanged =
                hasChanged || nextStateForReducer !== prevStateForReducer;
        }

        for (let i = 0; i < selectorKeys.length; i++) {
            const selectorKey2 = selectorKeys[i];

            const selector2 = selectors[selectorKey2];

            const prevStateForSelector2 = nextState[selectorKey2];

            const nextStateForSelector2 = selector2(nextState);

            nextState[selectorKey2] = nextStateForSelector2;

            hasChanged =
                hasChanged || nextStateForSelector2 !== prevStateForSelector2;
        }

        return hasChanged ? nextState : state;
    };
};

export const createSyncStore = (history, stores) => {
    const defaultState = stores.reduce(
        (s, store) => ({
            ...s,
            [store.key]: store.defaultState
        }),
        {}
    );

    const reducers = [
        ...stores.map((store) => ({
            ...store,
            reducer: store.reducer
                ? bindSyncReducer(store, defaultState, environment.version)
                : false
        })),
        {
            key: 'router',
            reducer: connectRouter(history)
        }
    ];

    const store = createStore(
        combineReducers(reducers),
        combineEnhancers(
            applyMiddleware(
                routerMiddleware(history),
                multiClientMiddleware(
                    {
                        node: {
                            client: nodeJs,
                            options: {
                                returnRejectedPromiseOnError: true
                            }
                        },
                        cdmMw: {
                            client: cdmMw,
                            options: {
                                returnRejectedPromiseOnError: true
                            }
                        },
                        tdmMw: {
                            client: tdmMw,
                            options: {
                                returnRejectedPromiseOnError: true
                            }
                        },
                        sentinel: {
                            client: sentinelMw,
                            options: {
                                returnRejectedPromiseOnError: true
                            }
                        }
                    },
                    {
                        onError: ({action, next, error}, options) => {
                            const nextAction = {
                                type: getActionTypes(action, options)[2],
                                error,
                                meta: {
                                    previousAction: action
                                }
                            };

                            next(nextAction);

                            return nextAction;
                        }
                    }
                ),
                thunk,
                ({getState}) => (next) => (action) =>
                    next({
                        ...action,
                        _state: getState()
                    })
            )
        )
    );

    bindSyncListener(store);

    return store;
};
