import {LocalDateTime} from '@js-joda/core';
import Decimal from 'decimal.js';
import {get} from 'lodash-es';
import numbro from 'numbro';

import {events} from '../events';

export const topAuctionsReducer = {
    [events.TOP_AUCTIONS_GET]: (state) => ({
        ...state,
        isLoading:
            get(state, 'rowData.length', 0) === 0 &&
            get(state, 'error', false) === false
    }),
    [events.TOP_AUCTIONS_SUCCESS]: (state, {payload: {data}}) => {
        return ({
            isLoading: false,
            hasData: get(data, "data", []).length > 0,
            more: false,
            rowData: get(data, "data", []).map((row) => ({
                ...row,
                mktimbalance: numbro.unformat(row.mktimbalance),
                totimbalance: numbro.unformat(row.totimbalance),
                matchedvol: numbro.unformat(row.matchedvol),
                indmatchprice: numbro.unformat(row.indmatchprice),
                netchange:
                    row.netchange === undefined
                        ? undefined
                        : numbro.unformat(row.netchange),
                positiveNetChange: new Decimal(
                    numbro.unformat(row.netchange || 0).toFixed(2)
                ).greaterThan(0),
                negativeNetChange: new Decimal(
                    numbro.unformat(row.netchange || 0).toFixed(2)
                ).lessThan(0)
            })),
            timestamp: LocalDateTime.now()

        });
        },
    [events.TOP_AUCTIONS_ERROR]: (state, {error}) => ({
        error,
        hasData: false,
        isLoading: false,
        more: false,
        rowData: [],
        timestamp: LocalDateTime.now()
    }),
    [events.TOP_AUCTIONS_RESET]: () => ({
        isLoading: false,
        rowData: [],
        timestamp: LocalDateTime.now()
    })
};
