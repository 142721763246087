import {LocalDateTime} from '@js-joda/core';
import Button from '@material-ui/core/Button';
import StorageIcon from '@material-ui/icons/Storage';
import {useFormikContext} from 'formik';
import {pick} from 'lodash-es';
import NyDateTimeInput from 'nyse-web-tools-common/lib/react/form/NyDateTimeInput/NyDateTimeInput';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySelectInput from 'nyse-web-tools-common/lib/react/form/NySelectInput/NySelectInput';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import NyTextInput from 'nyse-web-tools-common/lib/react/form/NyTextInput/NyTextInput';
import NyUppercaseInput from 'nyse-web-tools-common/lib/react/form/NyUppercaseInput/NyUppercaseInput';
import NyTooltip from 'nyse-web-tools-common/lib/react/generic/NyTooltip/NyTooltip';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import isEqual from 'react-fast-compare';
import {useToggle} from 'react-use';

import {FirmSelect} from '../../selects/firm.select';
import {OPRAExchangeSelect} from '../../selects/opraExchange.select';
import OPRASeqNumGrid from './opraSeqNumGrid';

const OPRAEntryForm = React.memo((props) => {
    const formCtx = useFormikContext();
    const printTimeMaxRange = useMemo(
        () => LocalDateTime.now().plusDays(60),
        []
    );
    const expirationMaxRange = useMemo(
        () => LocalDateTime.now().plusYears(11),
        []
    );

    const [showModal, toggleShowModal] = useToggle(false);
    const [hasSelected, setHasSelected] = useState(false);

    const isExistingEntry = !!formCtx.values.opraId;
    const isManualEntry = formCtx.values.opraEntryType === 'MANUAL';
    const isSequenceEntry = formCtx.values.opraEntryType === 'SEQUENCE';

    const hasReconciliation = formCtx.values.tplusNReconciliationAllowed;

    const isSequenceReset = useMemo(() => {
        const properties = [
            'callPut',
            'exchange',
            'price',
            'printTime',
            'opraSeqNumber',
            'quantity',
            'strikePrice'
        ];

        return isEqual(
            pick(formCtx.initialValues, properties),
            pick(formCtx.values, properties)
        );
    }, [formCtx.initialValues, formCtx.values]);

    const required = useMemo(
        () =>
            ({
                EQTY: ['shortSale'],
                ETO: ['expirationDate', 'strikePrice', 'callPut'],
                FLEX: ['expirationDate', 'strikePrice', 'callPut'],
                FUTR: ['expirationDate'],
                OTHR: ['expirationDate', 'callPut'],
                OTO: ['expirationDate', 'strikePrice', 'callPut']
            }[formCtx.values.qctType] || []),
        [formCtx.values.qctType]
    );

    const onCloseModal = useCallback(
        (trade) => {
            toggleShowModal();

            if (trade) {
                formCtx.setFieldValue('opraSeqNumber', trade.tradeSequence);
                formCtx.setFieldValue('callPut', trade.optionType);
                formCtx.setFieldValue('exchange', trade.exchange);
                formCtx.setFieldValue(
                    'expirationDate',
                    joda.parseDateString(trade.expirationDate)
                );
                formCtx.setFieldValue('price', trade.tradePrice);
                formCtx.setFieldValue(
                    'printTime',
                    joda.parseDateTimeString(trade.tradeDate)
                );
                formCtx.setFieldValue('quantity', trade.tradeSize);
                formCtx.setFieldValue('strikePrice', trade.strikePrice);

                setHasSelected(() => true);
            }
        },
        [formCtx.setFieldValue]
    );

    useEffect(() => {
        if (!props.show) {
            setHasSelected(() => false);
        }
    }, [props.show]);

    const requiresSeqNumSelection =
        (hasReconciliation && !hasSelected) || isSequenceReset;

    return (
        <div className='flex-column height-full'>
            <div className='row flex-1-1'>
                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Entry Type</label>
                    <NySelectInput
                        name='opraEntryType'
                        readOnly={isExistingEntry}>
                        <option value='MANUAL'>Manual</option>
                        <option value='SEQUENCE'>Sequence</option>
                    </NySelectInput>
                </div>
                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Firm</label>
                    <FirmSelect
                        defaultValue={!formCtx.values.crd}
                        name='crd'
                        readOnly
                        required
                    />
                </div>
                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Symbol</label>
                    <NyUppercaseInput name='symbol' readOnly required />
                </div>
                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Deal Id</label>
                    <NyNumberInput
                        name='dealId'
                        includeThousandsSeparator={false}
                        readOnly
                        required
                        valueAsString
                    />
                </div>
                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Original Deal Id</label>
                    <NyNumberInput
                        name='dealIdOrig'
                        includeThousandsSeparator={false}
                        readOnly
                        required
                        valueAsString
                    />
                </div>
                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Execution Time</label>
                    <NyDateTimeInput
                        className='form-control'
                        name='tradeExecTime'
                        readOnly
                        required
                    />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Sequence Number</label>
                    <NyTooltip
                        disabled={!requiresSeqNumSelection}
                        overlay='Please click the icon to select a valid OPRA Sequence Number'
                        trigger={['hover']}>
                        <div className='flex-row flex-align-center'>
                            <NyNumberInput
                                className={
                                    requiresSeqNumSelection ? 'ng-invalid' : ''
                                }
                                includeThousandsSeparator={false}
                                disabled={isManualEntry}
                                maxLength={10}
                                name='opraSeqNumber'
                                readOnly={hasReconciliation}
                                required={isSequenceEntry}
                            />
                            {hasReconciliation && (
                                <Button
                                    data-e2e-tag='selectSeqNumberBtn'
                                    color='secondary'
                                    onClick={() => toggleShowModal()}
                                    variant='contained'>
                                    <StorageIcon />
                                </Button>
                            )}
                        </div>
                    </NyTooltip>
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Hedge Ratio (%)</label>
                    <NyNumberInput
                        name='hedgeRatioPercentage'
                        max={100}
                        precision={6}
                    />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Price Spread</label>
                    <NyNumberInput name='priceSpread' precision={6} />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Strategy</label>
                    <NySelectInput name='strategy'>
                        <option value=''>-</option>
                        <option value='BW'>Buy / Write</option>
                        <option value='SPRD'>Spread</option>
                        <option value='STDL'>Straddle</option>
                        <option value='RVSL'>Reversal</option>
                        <option value='CONV'>Conversion</option>
                        <option value='OTHR'>Other</option>
                        <option value='MA'>Merger Arbitrage</option>
                        <option value='RA'>Risk Arbitrage</option>
                        <option value='CST'>
                            Convertible Security Transaction
                        </option>
                    </NySelectInput>
                </div>

                {/* ----  Manual Entry ---- */}
                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Exchange</label>
                    <OPRAExchangeSelect
                        name='exchange'
                        disabled={isSequenceEntry}
                        required={isManualEntry}
                    />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>CT Print</label>
                    <NyDateTimeInput
                        className='form-control'
                        disabled={isSequenceEntry}
                        max={printTimeMaxRange}
                        name='printTime'
                        readOnly={isSequenceEntry}
                        required={isManualEntry}
                    />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Expiration</label>
                    <NyDateTimeInput
                        className='form-control'
                        disabled={isSequenceEntry}
                        format='MM/dd/yyyy'
                        max={expirationMaxRange}
                        name='expirationDate'
                        readOnly={isSequenceEntry}
                        required={required.includes('expirationDate')}
                    />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Price</label>
                    <NyNumberInput
                        disabled={isSequenceEntry}
                        name='price'
                        precision={6}
                        required={isManualEntry}
                    />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>
                        {' '}
                        {formCtx.values.qctType !== 'EQTY'
                            ? 'Contracts'
                            : 'Volume'}
                    </label>
                    <NyNumberInput
                        disabled={isSequenceEntry}
                        name='quantity'
                        required={isManualEntry}
                    />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Strike Price</label>
                    <NyNumberInput
                        disabled={isSequenceEntry}
                        name='strikePrice'
                        precision={6}
                        required={required.includes('strikePrice')}
                    />
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Call / Put</label>
                    <NySelectInput
                        disabled={isSequenceEntry}
                        name='callPut'
                        required={required.includes('callPut')}>
                        <option value=''>-</option>
                        <option value='CALL'>Call</option>
                        <option value='PUT'>Put</option>
                        <option value='BOTH'>Both</option>
                    </NySelectInput>
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Short Sale Type</label>
                    <NySelectInput
                        disabled={isSequenceEntry}
                        name='shortSale'
                        required={required.includes('shortSale')}>
                        <option value=''>-</option>
                        <option value='LS'>Long Sale</option>
                        <option value='SSE'>Short Sale Exempt</option>
                        <option value='SSH'>Short Sale</option>
                        <option value='UNK'>Unknown</option>
                    </NySelectInput>
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>QCT Type</label>
                    <NySelectInput
                        disabled={isSequenceEntry}
                        name='qctType'
                        required={isManualEntry}>
                        <option value=''>-</option>
                        <option value='ETO'>Exchange Traded Option</option>
                        <option value='OTO'>OTC Traded Option</option>
                        <option value='FLEX'>Flex Option</option>
                        <option value='EQTY'>Equity</option>
                        <option value='FUTR'>Future</option>
                        <option value='OTHR'>Other</option>
                    </NySelectInput>
                </div>

                <div className='form-group col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                    <label>Comments</label>
                    <NyTextInput name='comments' />
                </div>
            </div>
            <div className='row flex-1 flex-align-bottom'>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                    <NySubmitAlert className='space-top-1 space-bottom-1' />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 space-top-1'>
                    <NySubmitBtn disabled={requiresSeqNumSelection} danger />
                    <NyResetBtn />
                </div>
                {hasReconciliation && (
                    <OPRASeqNumGrid
                        onClose={onCloseModal}
                        opraId={formCtx.values.opraId}
                        opraSeqNumber={formCtx.values.opraSeqNumber}
                        show={showModal}
                    />
                )}
            </div>
        </div>
    );
});

export default OPRAEntryForm;
