import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import useStore from '../../hooks/useStore.hook';
import {getMMTypes} from '../../stores/mmTypes/mmTypes.actions';

export type MMTypesSelectProps = {
    disabled?: boolean;
    isMulti?: boolean;
    required?: boolean;
};

export const MMTypesSelect: FunctionComponent<MMTypesSelectProps> = ({
    disabled = false,
    isMulti = false,
    required = false
}) => {
    const mmTypes = useStore('mmTypes');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMMTypes());
    }, [dispatch]);

    return (
        <NySelectInput
            disabled={disabled || !mmTypes.options.length}
            isMulti={isMulti}
            name='mmType'
            options={[
                ...(isMulti
                    ? []
                    : [
                          {
                              label: '-',
                              value: ''
                          }
                      ]),
                ...mmTypes.options
            ]}
            required={required}
            showLabelAsValue
        />
    );
};
