import cn from 'classnames';
import {get} from 'lodash-es';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

@connect(['downloads'])
export class DownloadProgress extends React.PureComponent {
    static propTypes = {
        fileName: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['bar', 'circle'])
    };

    static defaultProps = {
        type: 'bar'
    };

    render() {
        const download = get(this.props.downloads, this.props.fileName, {
            error: false,
            isDownloading: false,
            progress: 0
        });

        const progress =
            download.progress < 1 && this.props.type === 'bar'
                ? 'Preparing...'
                : download.progress.toFixed(0);

        return (
            <div
                className={cn({
                    [`download-${this.props.type}`]: true,
                    error: download.error,
                    hide: !download.isDownloading && !download.error
                })}
                data-progress={progress}
            />
        );
    }
}
