import isEqual from 'react-fast-compare';
import {useSelector} from 'react-redux';

export function useRouteEntitlement(): any[] {
    return useSelector(
        (ctx: any) =>
            ctx.route?.entitlement
                ? ctx.route.entitlement[ctx.platform.mic] || []
                : [],
        isEqual
    );
}
