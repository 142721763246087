import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import React from 'react';
import {useCallback} from 'react';

import {
    custFirmChoices,
    myOwnershipChoices,
    openCloseChoices
} from '../../resources/tradeSummary.resource';
import {mapApiValue} from '../../utils/utilities';

const useStyles = makeStyles((theme: any) => ({
    deleteBtn: {
        color: '#c9001a'
    }
}));

const AgDeleteBtn = (props) => {
    const classes = useStyles(props);
    const onClick = useCallback(() => {
        props.context.onClick(props);
    }, [props]);
    return (
        <IconButton
            data-e2e-tag='tradeAllocateDeleteBtn'
            data-modal-name='tradeAllocate'
            color='secondary'
            className={classes.deleteBtn}
            onClick={onClick}>
            <DeleteOutlineOutlinedIcon />
        </IconButton>
    );
};

const deleteBtn = {
    field: 'delete',
    pinned: 'right',
    suppressToolPanel: true,
    movable: false,
    resizable: false,
    suppressSizeToFit: true,
    headerName: '',
    valueGetter: ({data}) => data,
    minWidth: 50,
    width: 50,
    cellRendererFramework: AgDeleteBtn
};

const rowNumber = {
    headerName: 'Row #',
    pinned: 'left',
    valueGetter: 'node.rowIndex + 1'
};

export const tradeAllocateColumnDefs = [
    deleteBtn,
    rowNumber,
    {
        field: 'myOwnership',
        headerName: 'My Ownership',
        valueGetter: ({data}) =>
            mapApiValue(data, 'myOwnership', myOwnershipChoices)
    },
    {
        field: 'execQty',
        headerName: 'Slice Quantity',
        type: 'number'
    },
    {
        field: 'reasonCode',
        headerName: 'Change Reason'
    },
    {
        field: 'custFirmId',
        headerName: 'Customer side: MPID'
    },
    {
        field: 'custOpenClose',
        headerName: 'Customer side: Open/Close',
        valueGetter: ({data}) =>
            mapApiValue(data, 'custOpenClose', openCloseChoices)
    },
    {
        field: 'custFirm',
        headerName: 'Customer side: Customer or Firm',
        valueGetter: ({data}) => mapApiValue(data, 'custFirm', custFirmChoices)
    },
    {
        field: 'custSndrSubId',
        headerName: 'Customer side: MMID'
    },
    {
        field: 'custClearingNumber',
        headerName: 'Customer side: Clearing #'
    },
    {
        field: 'custCapStrategyId',
        headerName: 'Customer side: Cap Strategy'
    },
    {
        field: 'custSubmittingBkrId',
        headerName: 'Customer side: Badge'
    },
    {
        field: 'custCmta',
        headerName: 'Customer side: CMTA'
    },
    {
        field: 'custAccount',
        headerName: 'Customer side: Account'
    },
    {
        field: 'custOptnlData',
        headerName: 'Customer side: Optional Data'
    },
    {
        field: 'contraOrdFirmId',
        headerName: 'Contra side: MPID'
    },
    {
        field: 'contraOrdOpenClose',
        headerName: 'Contra side: Open/Close',
        valueGetter: ({data}) =>
            mapApiValue(data, 'contraOrdOpenClose', openCloseChoices)
    },
    {
        field: 'contraOrdCustFirm',
        headerName: 'Contra side: Customer or Firm',
        valueGetter: ({data}) =>
            mapApiValue(data, 'contraOrdCustFirm', custFirmChoices)
    },
    {
        field: 'contraOrdSndrSubId',
        headerName: 'Contra side: MMID'
    },
    {
        field: 'contraOrdClearingNumber',
        headerName: 'Contra side: Clearing #'
    },
    {
        field: 'contraOrdCapStrategyId',
        headerName: 'Contra side: Cap Strategy'
    },
    {
        field: 'contraOrdSubmittingBkrId',
        headerName: 'Contra side: Badge'
    },
    {
        field: 'contraOrdCmta',
        headerName: 'Contra side: CMTA'
    },
    {
        field: 'contraOrdAccount',
        headerName: 'Contra side: Account'
    },
    {
        field: 'contraOrdOptnlData',
        headerName: 'Contra side: Optional Data'
    }
];
