import {LocalDateTime} from '@js-joda/core';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {events} from '../events';

export const quoteMetricsReducer = {
    [events.QUOTE_METRICS_GET]: (state) => ({
        ...state,
        error: false,
        isLoading: true,
        rowData: [],
        pinnedBottomRowData: []
    }),
    [events.QUOTE_METRICS_SUCCESS]: (
        state,
        {
            payload: {
                config: {params},
                data: {data, meta}
            }
        }
    ) => ({
        ...state,
        error: false,
        isLoading: false,
        rowData: data,
        pinnedBottomRowData: [
            {
                exchangeGroup: 'Total',
                exchangeVolume: meta.totalVolume
            }
        ],
        timestamp: joda.format(
            LocalDateTime.parse(params.startDate),
            'MM/dd/yyyy'
        )
    }),
    [events.QUOTE_METRICS_ERROR]: (
        state,
        {
            error,
            meta: {
                previousAction: {
                    payload: {
                        request: {params}
                    }
                }
            }
        }
    ) => ({
        ...state,
        error,
        isLoading: false,
        rowData: [],
        timestamp: joda.format(
            LocalDateTime.parse(params.startDate),
            'MM/dd/yyyy'
        ),
        pinnedBottomRowData: []
    })
};
