import Grid from '@material-ui/core/Grid';
import NyCheckboxInput from 'nyse-web-tools-common/lib/react/form/NyCheckboxInput/NyCheckboxInput';
import NyNumberInput from 'nyse-web-tools-common/lib/react/form/NyNumberInput/NyNumberInput';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import NyTextInput from 'nyse-web-tools-common/lib/react/form/NyTextInput/NyTextInput';
import GridDivider from 'nyse-web-tools-common/lib/react/generic/GridDivider/GridDivider';
import React from 'react';

import useStore from '../../../hooks/useStore.hook';
import {IdbOriginator} from '../../../resources/idbOriginators.resource';
import {FirmSelect} from '../../selects/firm.select';
import {IbMpidSelect} from '../../selects/mpid.select';
import {TradingAccountsSelect} from '../../selects/tradingAccounts.select';

export const IdbOriginatorModal: React.FC<{values: IdbOriginator}> = React.memo(
    (props) => {
        const firms = useStore('firms');

        return (
            <Grid container alignItems='flex-end' spacing={2}>
                <Grid item xs={6}>
                    <NyCheckboxInput
                        name='isOriginator'
                        required={
                            !props.values.isOriginator && !props.values.isIdb
                        }
                        resetFields={[
                            'tradingAccount',
                            'mpId',
                            'oatsId',
                            'orderThresholdQuantity',
                            'orderThresholdValue',
                            'dailyOrderThresholdValue',
                            'originatorCrd',
                            'affiliateFlag',
                            'autoPublishEligible'
                        ]}>
                        Order Originator
                    </NyCheckboxInput>
                </Grid>
                <Grid item xs={6}>
                    <NyCheckboxInput
                        name='isIdb'
                        required={
                            !props.values.isOriginator && !props.values.isIdb
                        }>
                        Inter-Dealer Broker
                    </NyCheckboxInput>
                </Grid>
                <GridDivider xs={12} pt={0} />
                <Grid item xs={6}>
                    <label>IB</label>
                    <FirmSelect
                        name='crd'
                        resetFields={['mpId', 'tradingAccount']}
                        readOnly={firms.length < 2}
                        required
                    />
                </Grid>
                {props.values.isOriginator && (
                    <>
                        <Grid item xs={6}>
                            <label>MPID</label>
                            <IbMpidSelect
                                crd={props.values.crd}
                                name='mpId'
                                resetFields={['tradingAccount']}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label>Trading Account</label>
                            <TradingAccountsSelect
                                name='tradingAccount'
                                mpId={props.values.mpId}
                                crd={props.values.crd}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={6}>
                    <label>IDB/Originator Id</label>
                    <NyTextInput name='name' maxLength={4} required />
                </Grid>
                {props.values.isOriginator && (
                    <>
                        <Grid item xs={6}>
                            <label>Originator CRD</label>
                            <NyNumberInput
                                name='originatorCrd'
                                max={999999999}
                                includeThousandsSeparator={false}
                                required={props.values.isOriginator}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NyCheckboxInput name='affiliateFlag'>
                                Affiliate Flag
                            </NyCheckboxInput>
                        </Grid>
                        <Grid item xs={3}>
                            <NyCheckboxInput name='autoPublishEligible'>
                                Auto Publish
                            </NyCheckboxInput>
                        </Grid>
                        <Grid item xs={6}>
                            <label>OATS Id</label>
                            <NyTextInput
                                name='oatsId'
                                maxLength={9}
                                required={props.values.isOriginator}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label>Order Threshold Quantity</label>
                            <NyNumberInput
                                name='orderThresholdQuantity'
                                required={props.values.isOriginator}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label>Order Threshold Value</label>
                            <NyNumberInput
                                name='orderThresholdValue'
                                required={props.values.isOriginator}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label>Daily Total Threshold Value</label>
                            <NyNumberInput
                                name='dailyOrderThresholdValue'
                                required={props.values.isOriginator}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} component={NySubmitAlert} hasCloseBtn />
                <GridDivider />
                <Grid item xs={12}>
                    <NySubmitBtn />
                    <NyResetBtn />
                </Grid>
            </Grid>
        );
    }
);

IdbOriginatorModal.defaultProps = {
    // @ts-ignore
    values: {}
};
