import {useFormikContext} from 'formik';
import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import useMountedEffect from 'nyse-web-tools-common/lib/react/hooks/useMountedEffect/useMountedEffect';
import {NyFieldInput} from 'nyse-web-tools-common/lib/react/types';
import React, {useMemo} from 'react';

import {useEntitlement} from '../../hooks/useEntitlement';
import useUserFirms from '../../hooks/useUserFirms';

type FirmSelectProps = {
    showInactive?: boolean;
    isMulti?: boolean;
    resetFields?: string[];
} & Partial<NyFieldInput>;

export const FirmSelect = React.memo<FirmSelectProps>((props) => {
    const entitlement = useEntitlement();
    const userFirms = useUserFirms();
    const formikCtx = useFormikContext();

    const firms = useMemo(
        () =>
            (userFirms.data ?? []).filter(
                ({active}) => props.showInactive || active
            ),
        [props.showInactive, userFirms.data]
    );

    const options = useMemo(
        () => [
            ...(props.required || props.isMulti
                ? []
                : [
                      {
                          label: '-',
                          value: ''
                      }
                  ]),
            ...firms.map(({crd, name, active}) => ({
                label: !active ? `${name} - inactive` : name,
                value: crd
            }))
        ],
        [firms, props.isMulti, props.required]
    );

    useMountedEffect(() => {
        if (props.initialValue && options?.length !== 0) {
            if (options.find((e=> e.value === props.initialValue)))
                return formikCtx.setFieldValue(props.name, props.initialValue);
        }
        formikCtx.setFieldValue(props.name, options[0]?.value ?? '');
    }, [entitlement, options]);

    return (
        <NySelectInput
            disabled={props.disabled}
            initialValue={props.initialValue}
            isLoading={userFirms.isFetching}
            isMulti={props.isMulti}
            name={props.name}
            onChange={props.onChange}
            options={options}
            readOnly={props.readOnly}
            required={props.required}
            resetFields={props.resetFields}
        />
    );
});
