import {LocalDateTime} from '@js-joda/core';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {events} from '../events';

export const liquidityMetricsReducer = {
    [events.LIQUIDITY_METRICS_GET]: (state) => ({
        ...state,
        count: 0,
        error: false,
        isLoading: true,
        more: false,
        pinnedTopRowData: [],
        rowData: [],
        timestamp: false
    }),
    [events.LIQUIDITY_METRICS_SUCCESS]: (
        state,
        {
            payload: {
                config: {params},
                data: {data}
            },
            _state
        }
    ) => {
        const firm = _state.firms.find(({crd}) => crd === params.crd);

        return {
            ...state,
            isLoading: false,
            count: data.totalRecords,
            pinnedTopRowData: [
                {
                    ...data.exchange,
                    symbol: _state.platform.name,
                    isGroup: true
                },
                {
                    ...data.firm,
                    symbol: firm.name,
                    isGroup: true
                }
            ],
            more: params.limit === data.symbols.length,
            rowData: [...data.symbols],
            timestamp: joda.format(
                LocalDateTime.parse(params.startTime),
                'MM/dd/yyyy'
            )
        };
    },
    [events.LIQUIDITY_METRICS_ERROR]: (
        state,
        {
            error,
            meta: {
                previousAction: {
                    payload: {
                        request: {params}
                    }
                }
            }
        }
    ) => ({
        ...state,
        count: 0,
        error,
        isLoading: false,
        more: false,
        pinnedTopRowData: [],
        rowData: [],
        timestamp: joda.format(
            LocalDateTime.parse(params.startTime),
            'MM/dd/yyyy'
        )
    })
};
