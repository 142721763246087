import {omit} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {createDownloadAction} from '../downloads/downloads.actions';
import {events} from '../events';

const parseMatcherTime = (params) =>
    cleanObject({
        ...omit(params, ['date', 'matcherTime']),
        endMatcherTime: params.qctTrade
            ? joda.toEndOfDay(params.date, true).toString()
            : params.matcherTime.end
            ? params.matcherTime.end.toString()
            : '',
        startMatcherTime: params.qctTrade
            ? joda.toStartOfDay(params.date).toString()
            : params.matcherTime.start
            ? params.matcherTime.start.toString()
            : ''
    });

export const getTrades = (params) => ({
    types: [events.TRADES_GET, events.TRADES_SUCCESS, events.TRADES_ERROR],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/trades',
            method: 'GET',
            params: parseMatcherTime(params)
        }
    }
});

export const getTradesExport = createDownloadAction({
    client: 'cdmMw',
    url: 'api/trades/download',
    method: 'POST',
    parseData: parseMatcherTime
});
