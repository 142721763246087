import {cdmMw} from '../api';

export const capStrategyIdResource = {
    api: cdmMw,
    url: '/api/options-trades/cap-strategies',
    useCache: true,
    parseResponse: ({data}) =>
        data.map((data) => ({
            label: data.capStrategyId,
            value: data.capStrategyId
        })),
    defaultValue: []
};
