export const myVolumesSearchColumnDefs = [
    {
        field: 'categoryName',
        headerName: '-',
        suppressToolPanel: true,
        suppressMovable: true,
        resizable: false,
        suppressSizeToFit: true,
        sortable: false,
        width: 150
    },
    {
        field: 'total',
        headerName: 'Total',
        type: 'number'
    },
    {
        field: 'buy',
        headerName: 'Buy',
        type: 'number',
        width: 125
    },
    {
        field: 'sell',
        headerName: 'Sell',
        type: 'number',
        width: 125
    },
    {
        field: 'sshSshe',
        headerName: 'SSH & SSHE',
        type: 'number',
        width: 200
    },
    {
        field: 'openingAuction',
        headerName: 'Opening Auction',
        type: 'number',
        width: 100
    },
    {
        field: 'closingAuction',
        headerName: 'Closing Auction',
        type: 'number',
        width: 100
    },
    {
        field: 'totalAuction',
        headerName: 'Total Auction',
        type: 'number',
        width: 100
    },
    {
        field: 'buyPercentage',
        headerName: 'Buy %',
        type: 'percentage',
        width: 125
    },
    {
        field: 'sellPercentage',
        headerName: 'Sell %',
        type: 'percentage',
        width: 125
    },
    {
        field: 'sshSshePercentage',
        headerName: 'SSH & SSHE %',
        type: 'percentage',
        width: 125
    }
];
