import buttons from 'nyse-web-tools-common/lib/react/generic/StaticButtons/StaticButtons';
import React from 'react';

import {SubAccountModal} from '../../components/modals/subAccounts/subAccount.modal';
import {SubAccountContactModal} from '../../components/modals/subAccounts/subAccountContact.modal';
import subAccountResource from '../../resources/subAccount.resource';
import subAccountContactResource from '../../resources/subAccountContact.resource';

export const subAccountColumnDefs = [
    {
        cellRendererParams: {
            Form: SubAccountModal,
            Button: buttons.EditOutlined,
            name: 'editSubAccount',
            omitInitialValueFields: [
                'createdBy',
                'createdTime',
                'modifiedBy',
                'modifiedTime'
            ],
            parentTitle: 'Sub Accounts',
            // this is only required since the server sorts the response by modified date
            resetScrollToTop: true,
            resource: subAccountResource,
            resourceMethod: 'put',
            size: 25,
            title: 'Update'
        },
        field: 'editSubAccount',
        permissions: ['update sub accounts'],
        pinned: 'left',
        type: 'modal'
    },
    {
        cellRendererParams: {
            Form: SubAccountContactModal,
            Button: buttons.PersonAddOutlined,
            initialValueFields: ['crd', 'subAccountId'],
            name: 'createSubAccountContact',
            parentTitle: 'Sub Accounts',
            refreshValueFields: ['crd'],
            resetRowHeights: true,
            resource: subAccountContactResource,
            resourceMethod: 'post',
            size: 25,
            title: 'Create Contact'
        },
        field: 'createSubAccountContact',
        permissions: ['update sub accounts'],
        pinned: 'left',
        type: 'modal'
    },
    {
        cellRendererParams: {
            Button: buttons.DeleteOutlineOutlined,
            message: (data) => (
                <>
                    Do you want to delete Sub Account:{' '}
                    <strong>{data.name}</strong>?
                </>
            ),
            resource: subAccountResource,
            warning: true
        },
        field: 'removeSubAccount',
        permissions: ['delete sub accounts'],
        pinned: 'right',
        type: 'remove'
    },
    {
        field: 'mpId',
        headerName: 'MPID',
        type: 'agTreeBtn',
        minWidth: 100,
        maxWidth: 100
    },
    {
        field: 'tradingAccount',
        headerName: 'Trading Account'
    },
    {
        field: 'name',
        headerName: 'Sub Account'
    },
    {
        field: 'createdBy',
        headerName: 'Created By'
    },
    {
        field: 'createdTime',
        headerName: 'Created Time',
        type: 'dateTime'
    },
    {
        field: 'modifiedBy',
        headerName: 'Modified By'
    },
    {
        field: 'modifiedTime',
        headerName: 'Modified Time',
        type: 'dateTime'
    }
];
