import {LocalDateTime} from '@js-joda/core';
import {get} from 'lodash-es';

import {events} from '../events';

export const tradeSummaryReducer = {
    [events.TRADE_SUMMARY_GET]: (state) => ({
        ...state,
        isLoading: true
    }),
    [events.TRADE_SUMMARY_SUCCESS]: (
        state,
        {
            payload: {
                config: {params},
                data
            }
        }
    ) => ({
        ...state,
        isLoading: false,
        error: false,
        count: get(data, 'meta.totalRecords', 0),
        rowData: get(data, 'data', []).map((data) => ({
            ...data,
            crd: params.crd
        })),
        timestamp: LocalDateTime.now()
    }),
    [events.TRADE_SUMMARY_ERROR]: (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
        rowData: [],
        count: 0,
        timestamp: LocalDateTime.now()
    })
};
