import {noop} from 'lodash-es';
import {NySelectInput} from 'nyse-web-tools-common/lib/react';
import React, {FunctionComponent} from 'react';

export type SymbolGroupFilterSelectProps = {
    disabled?: boolean;
    onChange: (params: any) => unknown;
    required?: boolean;
};

export const SymbolGroupFilterSelect: FunctionComponent<SymbolGroupFilterSelectProps> = ({
    disabled = false,
    onChange = noop,
    required = false
}) => {
    return (
        <NySelectInput
            disabled={disabled}
            name='symbolGroupType'
            onChange={onChange}
            showLabelAsValue
            required={required}>
            <option value=''>-</option>
            <option value='FUND_NAME'>Funds / Issue</option>
            <option value='ARCA_ISSUER'>Issuers</option>
            <option value='TRUST_NAME'>Trusts</option>
            <option value='TAPE'>Tapes</option>
            <option value='ADVISOR'>Advisors</option>
            <option value='INDEX_2'>NYSE Equity Indices</option>
            <option value='INDEX_1'>Additional Indices</option>
        </NySelectInput>
    );
};
