import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export function getAuctionsData(params = {}) {
    return {
        types: [
            events.AUCTIONS_GET,
            events.AUCTIONS_SUCCESS,
            events.AUCTIONS_ERROR
        ],
        payload: {
            client: 'cdmMw',
            request: {
                url: '/api/auction',
                params:
                    cleanObject({
                        limit: params?.limit,
                        offset: params?.offset,
                        symbol: params?.symbol,
                        books: params?.books,
                        dmms: params?.dmms,
                        primaryExchange: params?.primary_exch,
                        auctionType: params?.auction_type,
                        mktimbside: params?.mktimbside,
                        totimbside: params?.totimbside,
                        hasimbalance: params?.hasimbalance,
                        mktimbalance: params?.mktimbalance,
                        matchedvol: params?.matchedvol,
                        aucind: params?.aucind,
                        frzind: params?.frzind,
                        startAuconlyprices: params?.auconlyprices?.start,
                        endAuconlyprices: params?.auconlyprices?.end,
                        startBookclearprices: params?.bookclearprices?.start,
                        endBookclearprices: params?.bookclearprices?.end,
                        startRefprices: params?.refprices?.start,
                        endRefprices: params?.refprices?.end,
                        autoRefresh: params?.autoRefresh
                    }),
                isPillarSource: true
            }
        }
    };
}
