import {get, omit} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getMyVolumesSearch = (params) => ({
    types: [
        events.MY_VOLUMES_SEARCH_GET,
        events.MY_VOLUMES_SEARCH_SUCCESS,
        events.MY_VOLUMES_SEARCH_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/volume/adhoc',
            params: cleanObject({
                ...omit(params, ['date', 'offset']),
                symbol: get(params, 'symbol', '').split('|'),
                endDate: joda.format(params.date.end, 'yyyy-MM-dd'),
                startDate: joda.format(params.date.start, 'yyyy-MM-dd')
            })
        }
    }
});
