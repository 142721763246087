import {events} from '../events';

export const mmTypesReducer = {
    [events.MM_TYPES_GET]: (state) => ({
        ...state,
        error: false,
        isLoading: true,
        options: []
    }),
    [events.MM_TYPES_SUCCESS]: (state, {payload: {data}}) => ({
        ...state,
        isLoading: false,
        options: data.data
    }),
    [events.MM_TYPES_ERROR]: (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
        options: []
    })
};
