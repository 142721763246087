import {Link, Modal} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {get, keyBy, noop} from 'lodash-es';
import {AgGrid, AgGridColumnMenuTrigger, AgGridProvider} from 'nyse-web-tools-common/lib/react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import usePlatforms from '../../hooks/usePlatforms';
import useResource from '../../hooks/useResource.hook';
import {marketStatusTraderUpdatesPanelColumnDefs} from '../../configs/columnDefs/marketStatusTraderUpdatesPanel.columnDefs';
import {mstuFieldResource, mstuSummariesResource} from '../../resources/mstu.resource';

const nthNumber = (n) => {
    if (n > 3 && n < 21) {
        return "th";
    }
    switch (n % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};

const formatDate = (date) => {
    const dateObj = {};
    new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    }).formatToParts(date).forEach(e => dateObj[e.type] = e.value);
    return `${dateObj['hour']}:${dateObj['minute']} ${dateObj['dayPeriod']} ${dateObj['month']} ${dateObj['day']}${nthNumber(dateObj['day'])}, ${dateObj['year']}`.toUpperCase();
};

const formatModalDate = (date) => {
    const dateObj = {};
    new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    }).formatToParts(date).forEach(e => dateObj[e.type] = e.value);
    return `${dateObj['month']} ${dateObj['day']}${nthNumber(dateObj['day'])}, ${dateObj['year']} ${dateObj['hour']}:${dateObj['minute']} ${dateObj['dayPeriod']}`;
};

const DetailModal: React.FC<{content: object, open: boolean, onClose: Function}> = React.memo((props) => {
    const previousStatusList = get(props, 'content.rawData.details', []).slice(1);
    const onClose = useCallback(() => props.onClose(), []);
    return (
        <Modal
            data-component='modal'
            data-type='modal'
            data-e2e-tag='detailModal'
            open={props.open}
            onClose={onClose}
        >
            <div
                style={{
                    padding: '2rem 6rem',
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxHeight: '80vh',
                    width: '70vw',
                    backgroundColor: '#fff',
                    border: '2px solid #fff',
                    boxShadow: '24',
                    overflow: 'auto'
                }}
            >
                <div
                    data-e2e-tag='modalCloseBtn'
                    style={{
                        textAlign: 'right',
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                        cursor: 'pointer'
                    }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </div>
                <div style={{fontSize: '1.2rem'}}>
                    <div>
                        <h1 style={{fontSize: '1.4rem', textAlign: 'center'}}>{get(props, 'content.description', '')}</h1>
                        <h3 style={{fontSize: '1.4rem', textTransform: 'none'}}>
                            <time>{get(props, 'content.modalDate', '')}</time>
                        </h3>
                        <div style={{textTransform: 'uppercase', height: '2rem'}}>
                            <span style={{fontWeight: 700}}>MARKET: </span>
                            <span data-name='modalMarket'>{get(props, 'content.market', '')}</span>
                        </div>
                        <div style={{textTransform: 'uppercase', height: '2rem'}}>
                            <span style={{fontWeight: 700}}>SERVICE: </span>
                            <span data-name='modalService'>{get(props, 'content.service', '')}</span>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: get(props, 'content.body', '')}}></div>
                    </div>
                    {
                        previousStatusList.length !== 0 && (
                            <div>
                                <h3 style={{fontSize: '2.25rem', margin: '0 0 0.75rem', padding: '0 0', textTransform: 'none'}}>Previous updates</h3>
                                {
                                    previousStatusList.map((previousStatus, idx) => (
                                        <div key={`modal_market_status_${idx}`}>
                                            <h4 style={{fontSize: '1.875rem'}}>{get(previousStatus, 'subject', '')}</h4>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div style={{flexBasis: '16.666667%', margin: '0 2.333333% 0 0'}}>{formatModalDate(get(previousStatus, 'publishedDate', ''))}</div>
                                                <div dangerouslySetInnerHTML={{__html: get(previousStatus, 'body', '')}} style={{flex: '1 1 0%'}}></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </Modal>
    );
});

const platformDescMap = {
    'ARCX': 'NYSE ARCA EQUITIES',
    'XASE': 'NYSE AMERICAN',
    'XCIS': 'NYSE NATIONAL',
    'XNYS': 'NYSE',
    'XCHI': 'NYSE CHICAGO',
    'ARCO': 'NYSE ARCA OPTIONS',
    'AMXO': 'NYSE AMERICAN OPTIONS'
};

const panelTypeMap = {
    'marketStatus': {
        link: 'https://www.nyse.com/market-status/history',
        systemId: '2',
        title: 'Market Status'
    },
    'traderUpdates': {
        link: 'https://www.nyse.com/trader-update/history',
        systemId: '1',
        title: 'Trader Updates'
    }
};

const PANEL_LIST_NUMBER = 5;

export const MarketStatusTraderUpdatesPanel: React.FC<{type: string}>  = React.memo((props) => {
    const {link, systemId, title} = useMemo(() => {
        const type = get(props, 'type', '');
        return {
            link: get(panelTypeMap, `${type}.link`, ''),
            systemId: get(panelTypeMap, `${type}.systemId`, ''),
            title: get(panelTypeMap, `${type}.title`, '')
        };
    }, [props.type]);

    const platforms = usePlatforms();
    const fieldResource = useResource(mstuFieldResource);
    const summariesResource = useResource(mstuSummariesResource);

    const [rowData, setRowData] = useState([]);

    const [modalDetail, setModalDetail] = useState({
        open: false,
        content: {}
    });

    useEffect(() => {
        if (!systemId) {
            return;
        }

        const fetchData = async () => {
            const fieldRes = await fieldResource.get(null, {systemId});
            if (!Array.isArray(fieldRes?.data) || fieldRes?.data?.length === 0) {
                return;
            }
            const set = new Set();
            platforms.forEach(platform => !!platform.mic && !!platformDescMap[platform.mic] && set.add(platformDescMap[platform.mic]));
            const arr = new Array();
            fieldRes.data.forEach(field => set.has(field.desc) && arr.push(field.value));

            const summariesRes = await summariesResource.get({fields: arr, page: 0, size: 5}, {systemId, filter: 'sort=publishedDate,desc'});
            if (!Array.isArray(summariesRes?.data?.content) || summariesRes?.data?.content?.length === 0) {
                return;
            }
            const marketField = keyBy(fieldRes?.data, 'value');
            const rowData = summariesRes?.data?.content.slice(0, PANEL_LIST_NUMBER).map((status) => {
                const marketList = [];
                const serviceList = [];
                get(status, 'fields', []).forEach(field => {
                    const type = get(marketField, `${field}.type`, '');
                    const desc = get(marketField, `${field}.desc`, '');
                    type === 'MARKET' && marketList.push(desc);
                    type === 'SERVICE' && serviceList.push(desc);
                });
                return {
                    body: get(status, 'details[0].body', ''),
                    date: formatDate(get(status, 'details[0].publishedDate', '')),
                    description: get(status, 'details[0].subject', ''),
                    market: marketList.sort().join(', '),
                    modalDate: formatModalDate(get(status, 'details[0].publishedDate', '')),
                    service: serviceList.sort().join(', '),
                    rawData: status
                };
            });

            setRowData(rowData);
        };

        fetchData();
    }, []);

    const onSelectionChanged = useCallback((selected) => selected.length === 1 && setModalDetail({open: true, content: get(selected, '0', {})}), []);

    const onModalClose = useCallback(() => setModalDetail({open: false, content: {}}), []);

    const getRowStyle = useCallback(() => ({cursor: 'pointer'}), []);

    return (
        <div style={{margin: '0 0 2em'}}>
            <AgGridProvider
                autoSize={true}
                columnDefs={marketStatusTraderUpdatesPanelColumnDefs}
                columnNamespace={`home${title.replace(/\s/g, '')}`}
                error={fieldResource.error || summariesResource.error}
                isLoading={fieldResource.pending || summariesResource.pending}
                onLoad={noop}
                onSelectionChanged={onSelectionChanged}
                rowData={rowData}
                rowSelection='single'
                submitOnLoad={false}
                suppressRowClickSelection={false}
            >
                <div className='panel-primary panel height-full'>
                    <div className='panel-heading flex-row flex-grow flex-align-center'>
                        <span className='panel-title'>{title}</span>
                        <AgGridColumnMenuTrigger className='flex-pull-right' />
                    </div>
                    <div className='panel-body'>
                        <AgGrid height={175} getRowStyle={getRowStyle} />
                    </div>
                    <div className='panel-footer flex-row flex-align-center'>
                        <div className='flex-pull-left flex-column flex-align-center'>
                            <Link href={link} target='_blank'>{`View ${title} History`}</Link>
                        </div>
                    </div>
                </div>
            </AgGridProvider>
            <DetailModal
                content={modalDetail.content}
                onClose={onModalClose}
                open={modalDetail.open}
            />
        </div>
    );
});
