export const riskSettingsTypeMap = {
    cred_ord_exec: 'Gross - Open + Executed',
    cred_ord: 'Gross (Open)',
    cred_exec: 'Gross (Executed)',
    ord_qty: 'Order Max Qty',
    ord_val: 'Order Max Notional',
    rolling_vol: 'Volume',
    rolling_trans: 'Transaction',
    rolling_pct: 'Percentage',
    trans_vol_pct: 'GRMP',
    rej_iso: 'Reject ISO',
    rej_iso_day: 'Reject ISO Day',
    rej_iso_ioc: 'Reject ISO IOC',
    rej_mkt_day: 'Reject Market Orders',
    rej_moo_moc: 'Reject MOO and MOC Orders',
    dupe_ord: 'Max Duplicate Orders',
    ord_rate: 'Order Rate Threshold - Max Orders',
    lmt_ord_prc_eq: 'Limit Order Price Protection',
    lmt_ord_prc_eq_cls_coef: 'Limit Order Price Protection',
    lmt_ord_prc_eq_trd_coef: 'Limit Order Price Protection',
    lmt_ord_prc_opt_leg: 'Single Leg Price Protection',
    lmt_ord_prc_opt_complex: 'Complex Price Protection',
    ord_qty_adv: 'Order Max Qty % ADV',
    rej_rst_sym: 'Reject Restricted Symbol',
    rej_sell_short: 'Reject Sell Short',
    rej_sell_short_ex: 'Reject Sell Short Exempt',
    locate_broker: 'Require LocateBroker',
    rej_early_sess: 'Reject Early Trading Session',
    rej_late_sess: 'Reject Late Trading Session'
};



