import {has} from 'lodash-es';
import {NyButton, NyModal} from 'nyse-web-tools-common/lib/react';
import connect from 'nyse-web-tools-common/lib/utils/redux.connect';
import PropTypes from 'prop-types';
import React from 'react';

import {
    getUser,
    refreshToken,
    resetUser,
    setUser
} from '../../stores/user/user.actions';

@connect(['user'], {getUser, refreshToken, resetUser, setUser})
export class SessionModal extends React.Component {
    static propTypes = {
        setUser: PropTypes.func,
        user: PropTypes.shape({
            exp: PropTypes.number,
            isAuthPending: PropTypes.bool,
            isJwtThreshold: PropTypes.bool,
            isJwtValid: PropTypes.bool,
            jwt: PropTypes.string,
            permissions: PropTypes.shape({
                ARCX: PropTypes.shape({
                    clientResourceNames: PropTypes.array
                }),
                XASE: PropTypes.shape({
                    clientResourceNames: PropTypes.array
                }),
                XCIS: PropTypes.shape({
                    clientResourceNames: PropTypes.array
                }),
                XNYS: PropTypes.shape({
                    clientResourceNames: PropTypes.array
                }),
                XCHI: PropTypes.shape({
                    clientResourceNames: PropTypes.array
                }),
                ARCO: PropTypes.shape({
                    clientResourceNames: PropTypes.array
                })
            }),
            rat: PropTypes.number,
            sentinel: PropTypes.shape({
                accessToken: PropTypes.string
            })
        })
    };

    static defaultProps = {
        user: {
            isAuthPending: false,
            isJwtThreshold: false,
            isJwtValid: false
        }
    };

    state = {
        countdown: null
    };

    componentDidMount() {
        this.tickInterval = setInterval(this.onTick, 1000);
        this.heartBeatInterval = setInterval(
            () => this.getUserInfo(false),
            30000
        );
        this.onTick();
        this.getUserInfo(true);
    }

    componentWillUnmount() {
        clearInterval(this.tickInterval);
        clearInterval(this.heartBeatInterval);
    }

    getCountdown = (toDate) => {
        const now = new Date();
        const difference = toDate.getTime() - now.getTime();

        if (difference <= 0) {
            return '00:00';
        } else {
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);

            minutes %= 60;

            seconds %= 60;

            return [minutes, seconds]
                .map((s) => (s < 10 ? `0${s}` : s))
                .join(':');
        }
    };

    getUserInfo = (init) => {
        if (this.shouldMakeHeartBeat(init)) {
            this.props.getUser();
        }
    };

    isJwtExpired = () => {
        return new Date().getTime() >= this.props.user.exp;
    };

    isJwtThreshold = () => {
        return (
            has(this.props.user, 'exp') &&
            this.props.user.exp - new Date().getTime() < 60 * 1000 * 30
        );
    };

    isJwtValid = () => {
        return (
            has(this.props.user, 'exp') &&
            new Date().getTime() < this.props.user.exp
        );
    };

    logout = () => {
        window.location.hash = '#/logout';
    };

    onTick = () => {
        if (this.props.user.isLogoutPending) {
            return;
        }

        if (!this.props.user.isJwtThreshold && this.isJwtThreshold()) {
            this.props.setUser({
                isJwtThreshold: true
            });
        }

        if (!this.props.user.isJwtValid && this.isJwtValid()) {
            this.props.setUser({
                isJwtValid: true
            });
        }

        if (this.isJwtExpired()) {
            this.props.resetUser();
        }

        if (this.isJwtThreshold()) {
            this.setState({
                countdown: this.getCountdown(new Date(this.props.user.exp))
            });
        }
    };

    shouldMakeHeartBeat = (init) => {
        return (
            this.isJwtValid() &&
            !this.props.user.isAuthPending &&
            !this.props.user.isLogoutPending &&
            (init ||
                (has(this.props.user, 'rat') &&
                    new Date().getTime() > this.props.user.rat + 30000))
        );
    };

    render() {
        return (
            <NyModal
                name='sessionTimeout'
                title='Session Timeout'
                show={
                    this.props.user.isJwtValid && this.props.user.isJwtThreshold
                }>
                <div className='row'>
                    <div className={'form-group col-md-12 col-lg-12 col-xl-12 cl-sm-12 col-xs-12 subheader ' +
                    'space-bottom-1 text-center'}>
                        <h4 className='space-top-1 space-bottom-1'>
                            {this.props.user.isAuthPending
                                ? 'Renewing your session...'
                                : 'Your session will expire in:'}
                        </h4>
                        {!this.props.user.isAuthPending && (
                            <h2 className='space-top-0 space-bottom-1'>
                                <strong className='text-danger'>
                                    {this.state.countdown}
                                </strong>
                            </h2>
                        )}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-xl-12 cl-sm-12 col-xs-12 text-right'>
                        <NyButton
                            primary
                            disabled={this.props.user.isAuthPending}
                            onClick={this.props.refreshToken}>
                            Renew Session
                        </NyButton>
                        <NyButton danger onClick={this.logout}>
                            Logout
                        </NyButton>
                    </div>
                </div>
            </NyModal>
        );
    }
}
