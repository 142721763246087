import DoneIcon from '@material-ui/icons/Done';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import React from 'react';

export const myFirmMpidsColumnDefs = [
    {
        headerName: 'Name',
        field: 'name',
        pinned: 'left',
        width: 280
    },
    {
        field: 'mpid',
        headerName: 'MPID',
        width: 120
    },
    {
        field: 'sendermpid',
        headerName: 'Sender MPID',
        width: 200
    },
    {
        headerName: 'Status',
        field: 'active_status',
        cellRendererFramework: ({data: {active_status}}) => (
            <div className='flex-row flex-align-center flex-grow'>
                {active_status === 'active' ? (
                    <DoneIcon className='num-positive' />
                ) : (
                    <ReportProblemOutlinedIcon className='num-negative' />
                )}
                &nbsp;{active_status}
            </div>
        ),
        width: 200
    },
    {
        field: 'mmsendermpid',
        headerName: 'MM Sender MPID',
        width: 200
    }
];
