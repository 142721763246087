import Tooltip from '@material-ui/core/Tooltip';
import React, {useMemo} from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

type HelpTooltipProps = {
    tooltip: string;
};

export const HelpTooltip: React.FC<HelpTooltipProps> = React.memo((props) => {

    return (
        <Tooltip title={props.tooltip}>
            <HelpOutlineIcon fontSize="small"/>
        </Tooltip>
    );

});
