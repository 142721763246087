import {omit} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getQuoteMetrics = (params) => ({
    types: [
        events.QUOTE_METRICS_GET,
        events.QUOTE_METRICS_SUCCESS,
        events.QUOTE_METRICS_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/quote-metrics/exchanges',
            params: cleanObject({
                ...omit(params, ['date']),
                endDate: joda.toEndOfDay(params.date).toString(),
                startDate: joda.toStartOfDay(params.date).toString()
            })
        }
    }
});
