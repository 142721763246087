import React, {useEffect, useRef} from 'react';
import {usePrevious} from 'react-use';
import {isEqual} from 'lodash-es';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {SnackbarProvider, useSnackbar} from 'notistack';
import PortClientWorker from './PortClientWorker';

const messageTypeMap = {
    'INFORMATION': 'info',
    'ERROR': 'error',
    'ANNOUNCEMENT': 'info'
};

const MessageWorker = React.memo(({clientConfig, subscriptionList}) => {
    const portClientWorker = null;
    const messageList = new Array();
    const readMessageIdSet = new Set();

    const messageChannel = new BroadcastChannel('messageChannel');

    const prevProps = usePrevious({clientConfig, subscriptionList});
    const portClientWorkerRef = useRef(portClientWorker);
    const messageListRef = useRef(messageList);
    const readMessageIdSetRef = useRef(readMessageIdSet);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const connectClient = (clientConfig, subscriptionList) => {
        const {
            url,
            authHeaders,
            reconnectDelay = 5000,
            heartbeatIncoming = 60000,
            heartbeatOutgoing = 60000,
        } = clientConfig;

        // invalid socket connection
        if (!url || !authHeaders?.accessToken) {
            return;
        }

        const worker = new PortClientWorker({
            url,
            authHeaders,
            reconnectDelay,
            heartbeatIncoming,
            heartbeatOutgoing,
            onConnected: () => {
                portClientWorkerRef.current = worker;
                subscriptionList && subscribeList(subscriptionList);
            },
            onMessageReceived: (message) => {
                messageList.push(message);
                messageListRef.current = messageList;
                // display message if tab is visible
                if (document.visibilityState === 'visible') {
                    displayMessage(message);
                }
            },
            onStompError: (message) => {
                if ((message?.headers?.message ?? '') === 'Access denied') {
                    worker?.destroyClient();
                }
            }
        });
    };

    const subscribeList = (subscriptionList) => {
        Array.isArray(subscriptionList) && subscriptionList.forEach(subscription => portClientWorkerRef.current?.subscribeClient(subscription));
    };

    const unsubscribeList = (subscriptionList) => {
        Array.isArray(subscriptionList) && subscriptionList.forEach(subscription => portClientWorkerRef.current?.unsubscribeClient(subscription));
    };

    const displayMessage = (message) => {
        const content = JSON.parse(message.content);
        const mic = (message?.destination ?? '').replace(new RegExp('\\b(topic|\.message)\\b', 'gi'), '').replace(new RegExp('\/', 'gi'), '').toUpperCase();
        const title = content?.title ?? '';
        const detail = content?.content ?? '';
        const timeStamp = content?.broadcastDateTime ? new Date(content.broadcastDateTime) : '';
        enqueueSnackbar(
            (
                <span style={{maxWidth: '400px', wordBreak: 'break-word', wordWrap: 'break-word'}}>
                    {title && <div style={{fontSize: '1.1em', fontWeight: 'bold'}}>{mic ? `[${mic}] ${title}` : title}</div>}
                    {detail && <div style={{fontSize: '1em', padding: '5px 0 0'}}>{detail}</div>}
                    {timeStamp && <div style={{fontSize: '0.9em', padding: '10px 0 0'}}>{`${timeStamp.toLocaleDateString()} ${timeStamp.toLocaleTimeString([], {timeZoneName: 'short'})}`}</div>}
                </span>
            ),
            {
                variant: messageTypeMap[content?.messageType] ?? 'default',
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                },
                style: {
                    textAlign: 'left',
                    top: -50
                },
                persist: true,
                action: (snackbarId) => (
                    <IconButton
                        style={{position: 'absolute', top: '2px', right: '0px'}}
                        color='inherit'
                        onClick={() => dismissSnackbar(message, snackbarId)}
                    >
                        <CloseIcon />
                    </IconButton>
                )
            },
        );
    };

    const handleMessageList = () => {
        // remove all snackbar
        closeSnackbar();
        if (document.visibilityState === 'visible') {
            // handle message list only if tab is visible
            const curMessageList = messageListRef.current;
            const curReadMessageIdSet = readMessageIdSetRef.current;

            curMessageList.forEach(message => {
                if (message?.id && !curReadMessageIdSet.has(message.id)) {
                    displayMessage(message);
                }
            });
        }
    };

    const dismissSnackbar = (message, snackbarId) => {
        readMessageIdSet.add(message?.id);
        readMessageIdSetRef.current = readMessageIdSet;
        messageChannel.postMessage(message?.id);
        closeSnackbar(snackbarId);
    };

    const closePortClientWorker = () => {
        portClientWorkerRef.current?.destroyWorker();
    };

    const disconnectClient = () => {
        portClientWorkerRef.current?.destroyClient();
    };

    const onChannelReceiveMessage = (e) => {
        readMessageIdSet.add(e.data);
        readMessageIdSetRef.current = readMessageIdSet;
        // refresh current visible tab
        handleMessageList();
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleMessageList);
        window.addEventListener('beforeunload', closePortClientWorker);
        messageChannel.addEventListener('message', onChannelReceiveMessage);

        return () => {
            document.removeEventListener('visibilitychange', handleMessageList);
            messageChannel.addEventListener('message', onChannelReceiveMessage);
        };
    }, []);

    useEffect(() => {
        if (!prevProps) {
            return;
        }

        if (!isEqual(prevProps.clientConfig, clientConfig)) {
            // build connection if clientConfig is updated
            // log in or refresh token
            if (portClientWorkerRef.current !== null) {
                // disconnect client before reconnect if one client exists (refresh token)
                disconnectClient();
            }
            // log in
            connectClient(clientConfig, subscriptionList);
        } else if (!isEqual(prevProps.subscriptionList, subscriptionList)) {
            if (portClientWorkerRef.current === null) {
                // build connection if subscriptionList is updated and worker does not exist
                connectClient(clientConfig, subscriptionList);
            } else {
                // unsubscribe all subscription before subscribe new subscription
                unsubscribeList(prevProps.subscriptionList);
                // subscribe if subscriptionList is updated and worker exists
                subscribeList(subscriptionList);
            }
        }
    }, [clientConfig, subscriptionList]);

    useEffect(() => () => {
        if (!clientConfig?.authHeaders?.accessToken) {
            // log out
            disconnectClient();
        }
    }, []);

    return null;
}, isEqual);

const MessageSnackbar = React.memo((props) =>
    <SnackbarProvider maxSnack={7} hideIconVariant>
        <MessageWorker {...props} />
    </SnackbarProvider>
);

export default MessageSnackbar;