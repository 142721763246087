import React from 'react';

export function serviceUnavailableError(
    detail: string,
    request: string
): {title: string; detail: React.ReactNode; type: string , request: string} {
    return {
        title: 'Service Unavailable',
        detail: (
            <span>
                {detail
                    ? `${detail}`
                    :'Service Unavailable. Please try again later.'}<br/>
                Please contact <strong>crs@nyse.com</strong> to report this issue.
            </span>
        ),
        type: 'danger',
        request: request
    };
}
