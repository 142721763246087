import {LocalDateTime, ZoneId} from '@js-joda/core';
import Button from '@material-ui/core/Button';
import {useFormikContext} from 'formik';
import {NyAlert} from 'nyse-web-tools-common/lib/react';
import NyDateTimeInput from 'nyse-web-tools-common/lib/react/form/NyDateTimeInput/NyDateTimeInput';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import React, {useEffect, useMemo, useState} from 'react';

import usePlatform from '../../../hooks/usePlatform';
import useUser from '../../../hooks/useUser.hook';

export const BlockTimeForm = React.memo(
    (props: {isBefore: boolean; time: string}) => {
        const formikCtx = useFormikContext();
        const user = useUser();
        const platform = usePlatform();
        const [currentBlockTime, setCurrentBlockTime] = useState(
            joda.parseTimeString('')
        );
        const [isBlockTimePassed, setIsBlockTimePassed] = useState(false);

        const hasUpdatePermission = useMemo(
            () =>
                (
                    user.permissions?.[platform]?.clientResourceNames ?? []
                )?.includes('update trade action block time'),
            [platform, user.permissions]
        );

        useEffect(() => {
            if (props.time) {
                const currentBlockTime = joda.parseTimeString(props.time);
                formikCtx.setFieldValue('time', currentBlockTime);
                setCurrentBlockTime(currentBlockTime);
                if (currentBlockTime) {
                    setIsBlockTimePassed(
                        LocalDateTime.now(
                            ZoneId.of('America/New_York')
                        ).compareTo(currentBlockTime) > 0
                    );
                }
            }
        }, []);

        const onReset = () => formikCtx.setFieldValue('time', currentBlockTime);
        return (
            <>
                <div className='form-group col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-12 space-top-3'>
                    <label>Trade Action Block Time - America/New York</label>
                    <NyDateTimeInput
                        disabled={isBlockTimePassed || !hasUpdatePermission}
                        className='form-control'
                        format='HH:mm:ss'
                        required
                        name='time'
                    />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                    <NySubmitAlert className='space-top-1 space-bottom-1' />
                    <NyAlert
                        show={props.isBefore}
                        detail={'Inputed time has passed in EST timezone.'}
                        type='danger'
                    />
                    <NyAlert
                        show={isBlockTimePassed}
                        detail={
                            'Scheduled time has passed and cannot be modified.'
                        }
                        type='warning'
                    />
                </div>
                {hasUpdatePermission && (
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 space-top-1'>
                        <NySubmitBtn disabled={isBlockTimePassed} />
                        {!isBlockTimePassed && (
                            <Button
                                data-e2e-tag='timeResetBtn'
                                color='secondary'
                                onClick={onReset}>
                                Reset
                            </Button>
                        )}
                    </div>
                )}
            </>
        );
    }
);
