import Decimal from 'decimal.js';

import {cdmMw} from '../api';

export default {
    api: cdmMw,
    url: (params) => `api/opra-entries/${params.opraId}/livevol`,
    useCache: false,
    parseResponse: ({data}) =>
        (data || []).map((row) => ({
            ...row,
            tradePrice: new Decimal(row.tradePrice || '0').toNumber(),
            tradeSize: new Decimal(row.tradeSize || '0').toNumber()
        })),
    defaultValue: []
};
