import {TemporalAdjusters} from '@js-joda/core';
import {omit} from 'lodash-es';
import joda from 'nyse-web-tools-common/lib/utils/joda';

import {cleanObject} from '../../utils/utilities';
import {events} from '../events';

export const getMyVolumesCadv = (params) => ({
    types: [
        events.MY_VOLUMES_CADV_GET,
        events.MY_VOLUMES_CADV_SUCCESS,
        events.MY_VOLUMES_CADV_ERROR
    ],
    payload: {
        client: 'cdmMw',
        request: {
            url: 'api/volume/mtd',
            params: cleanObject({
                ...omit(params, ['date', 'offset', 'searchBy']),
                ...(params.searchBy === 'month'
                    ? {
                          endDate: params.date
                              ? joda
                                    .toEndOfDay(params.date, true)
                                    .with(TemporalAdjusters.lastDayOfMonth())
                                    .toString() + 'Z'
                              : undefined,
                          startDate: params.date
                              ? joda
                                    .toStartOfDay(params.date)
                                    .with(TemporalAdjusters.firstDayOfMonth())
                                    .toString() + 'Z'
                              : undefined
                      }
                    : {
                          endDate: params.date
                              ? joda.toEndOfDay(params.date, true).toString() +
                                'Z'
                              : undefined,
                          startDate: params.date
                              ? joda.toStartOfDay(params.date).toString() + 'Z'
                              : undefined
                      })
            })
        }
    }
});
