import type {LocalDateTime} from '@js-joda/core';
import type {ColumnState} from 'ag-grid-community/dist/lib/columnController/columnController';
import {camelCase, capitalize} from 'lodash-es';
import {
    AgGrid,
    AgGridColumnMenuTrigger,
    AgGridExportMenu,
    AgGridPagination,
    AgGridProvider,
    AgGridToggleShowFilters,
    NyDateTimeInput,
    NySymbolInput,
    NyTextInput,
    NyUppercaseInput
} from 'nyse-web-tools-common/lib/react';
import joda from 'nyse-web-tools-common/lib/utils/joda';
import React, {FunctionComponent, useState} from 'react';
import {useDispatch} from 'react-redux';

import {DownloadBtn} from '../components/displays/download.btn';
import {NavigationSectionMenu} from '../components/navigation/navigation.section.menu';
import {FirmSelect} from '../components/selects/firm.select';
import {FirmParticipantSelect} from '../components/selects/firmParticipant.select';
import {MMTypesSelect} from '../components/selects/mmType.select';
import {
    Group,
    SymbolGroupSelect
} from '../components/selects/symbolGroup.select';
import {SymbolGroupFilterSelect} from '../components/selects/symbolGroupFilter.select';
import useStore from '../hooks/useStore.hook';
import {setColumns} from '../stores/columnDefs/columnDefs.actions';
import {
    getLiquidityMetrics,
    getSymbolsDownload
} from '../stores/liquidityMetrics/liquidityMetrics.actions';

type Filter = {
    crd: number;
    date: LocalDateTime;
    mmType: number[];
    limit: number;
    offset: number;
    participantId: string;
    symbol: string;
    symbolGroupType: Group;
    symbolGroupValue: string;
    view: string;
};

export const LiquidityMetricsPage: FunctionComponent = () => {
    const [firms, columnDefs, liquidityMetrics, platform, route] = [
        useStore('firms'),
        useStore('columnDefs'),
        useStore('liquidityMetrics'),
        useStore('platform'),
        useStore('route')
    ];
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState<string>('');
    const [filterValues, setFilterValues] = useState<Filter>({} as Filter);
    const views = [
        {
            params: {
                view: 'firm'
            },
            title: 'Firm',
            url: '/:platform/liquidity-metrics/:view'
        },
        {
            params: {
                view: 'mpid'
            },
            title: 'ETPID / MPID',
            url: '/:platform/liquidity-metrics/:view'
        },
        {
            params: {
                view: 'mmid'
            },
            title: 'MMID',
            url: '/:platform/liquidity-metrics/:view'
        }
    ];

    const dateRange = {
        max: joda.endOfDay(),
        min: joda.startOfDay().minusMonths(6)
    };

    const initialFilters = {
        crd: route.params.crd || (firms[0] || {}).crd,
        date: joda.startOfDay().minusDays(1),
        view: route.params.view
    };

    const initialPagination = {
        limit: 1000,
        offset: 0
    };

    const handleExportFileNameChange = (params): void => {
        setFileName(params.fileName);
    };

    const handleFiltersChanged = (filterValues: Filter): void => {
        setFilterValues(filterValues);
    };

    const handleExportSymbolsClick = (params): void => {
        dispatch(
            getSymbolsDownload({
                fileExtension: params.fileExtension,
                fileName: params.fileName,
                params: {
                    ...params,
                    ...filterValues
                },
                data: null
            })
        );
    };

    const handleLoad = (filterValues: Filter): void => {
        dispatch(
            getLiquidityMetrics({
                ...filterValues,
                view: route.params.view
            })
        );
    };

    const handleFirmChange = (params): void => {
        params.setValue('participantId', '');
    };

    const handleSymbolGroupChange = (params): void => {
        params.setValue('symbolGroupValue', '');
    };

    const handleColumnsChanged = (params: {
        namespace: string;
        columnDefs: ColumnState;
    }): void => {
        dispatch(setColumns(params));
    };

    const columnNamespace: string = camelCase(
        `liquidityMetrics${capitalize(route.params.view)}`
    );

    return (
        <>
            <div className='row'>
                <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12'>
                    <NavigationSectionMenu
                        queryParams={filterValues}
                        views={views}
                    />
                </div>
            </div>
            <AgGridProvider
                columnNamespace={columnNamespace}
                columnDefs={columnDefs[columnNamespace] ?? {default: []}}
                filtersClassName='row space-bottom-1'
                initialFilters={initialFilters}
                initialPagination={initialPagination}
                onFiltersChanged={handleFiltersChanged}
                onLoad={handleLoad}
                onColumnsChanged={handleColumnsChanged}
                submitOnNamespaceChanged
                submitOnLoad
                {...liquidityMetrics}>
                <AgGridProvider.Filters>
                    <div className='form-group col-lg-2 col-md-2 col-sm-4 col-xs-12'>
                        <label>Date</label>
                        <NyDateTimeInput
                            className='form-control'
                            initialValue={route.params.date}
                            format='MM/dd/yyyy'
                            name='date'
                            min={dateRange.min}
                            max={dateRange.max}
                            required
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>Symbol(s)</label>
                        <NySymbolInput
                            name='symbol'
                            initialValue={route.params.symbol}
                            placeholder='delimited by ","'
                        />
                    </div>
                    {firms.length > 1 && (
                        <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-1'>
                            <label>Firm</label>
                            <FirmSelect
                                name='crd'
                                onChange={handleFirmChange}
                                required
                            />
                        </div>
                    )}
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>ETPID / MPID</label>
                        <FirmParticipantSelect
                            crd={filterValues.crd}
                            name='participantId'
                        />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>MMID</label>
                        <NyUppercaseInput name='mmId' />
                    </div>
                    <div className='form-group col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                        <label>MM Type(s)</label>
                        <MMTypesSelect isMulti />
                    </div>
                    <div className='form-group col-lg-2 col-md-2 col-sm-3 col-xs-12'>
                        <label>Symbol Group</label>
                        <SymbolGroupFilterSelect
                            onChange={handleSymbolGroupChange}
                        />
                    </div>
                    <div className='form-group col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <SymbolGroupSelect
                            group={filterValues.symbolGroupType}
                            isMulti
                        />
                    </div>
                </AgGridProvider.Filters>

                <div className='row space-bottom-1'>
                    <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                        <div className='flex-row flex-align-bottom'>
                            <AgGridProvider.SubmitBtn>
                                Search
                            </AgGridProvider.SubmitBtn>
                            <AgGridProvider.ResetBtn>
                                Reset
                            </AgGridProvider.ResetBtn>
                            <div className='btn-circle-grp flex-pull-right'>
                                <AgGridToggleShowFilters />
                                <AgGridExportMenu
                                    exportFileName={columnNamespace}
                                    exportSheetName={platform.name}
                                    onChange={handleExportFileNameChange}>
                                    {route.params.view == 'mmid' && (
                                        <>
                                            <hr className='space-0' />
                                            <DownloadBtn
                                                data-e2e-tag='symbolsListExport'
                                                fileName={
                                                    fileName || columnNamespace
                                                }
                                                fileExtension='xlsx'
                                                onClick={
                                                    handleExportSymbolsClick
                                                }>
                                                Export Symbols List
                                            </DownloadBtn>
                                        </>
                                    )}
                                </AgGridExportMenu>
                                <AgGridColumnMenuTrigger />
                            </div>
                        </div>
                    </div>
                </div>

                <AgGrid reactNext />

                <AgGridPagination timestampPrefix='Metrics'>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1,000</option>
                    <option value={5000}>5,000</option>
                    <option value={10000}>10,000</option>
                </AgGridPagination>
            </AgGridProvider>
        </>
    );
};
