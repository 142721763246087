import Grid from '@material-ui/core/Grid';
import NyResetBtn from 'nyse-web-tools-common/lib/react/form/NyResetBtn/NyResetBtn';
import NySubmitAlert from 'nyse-web-tools-common/lib/react/form/NySubmitAlert/NySubmitAlert';
import NySubmitBtn from 'nyse-web-tools-common/lib/react/form/NySubmitBtn/NySubmitBtn';
import NyTextInput from 'nyse-web-tools-common/lib/react/form/NyTextInput/NyTextInput';
import GridDivider from 'nyse-web-tools-common/lib/react/generic/GridDivider/GridDivider';
import React from 'react';

import useStore from '../../../hooks/useStore.hook';
import {SubAccount} from '../../../resources/subAccount.resource';
import {FirmSelect} from '../../selects/firm.select';
import {IbMpidSelect} from '../../selects/mpid.select';
import {TradingAccountsSelect} from '../../selects/tradingAccounts.select';

export const SubAccountModal: React.FC<{values: SubAccount}> = React.memo(
    (props) => {
        const firms = useStore('firms');

        return (
            <Grid container alignItems='flex-end' spacing={2}>
                {firms.length > 1 && (
                    <Grid item xs={12}>
                        <label>IB</label>
                        <FirmSelect
                            disabled={!!props.values.subAccountId}
                            name='crd'
                            required
                            resetFields={['mpId', 'tradingAccount']}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <label>MPID</label>
                    <IbMpidSelect
                        crd={props.values.crd}
                        disabled={!!props.values.subAccountId}
                        name='mpId'
                        required
                        resetFields={['tradingAccount']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <label>Trading Account</label>
                    <TradingAccountsSelect
                        disabled={!!props.values.subAccountId}
                        name='tradingAccount'
                        mpId={props.values.mpId}
                        crd={props.values.crd}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <label>Sub Account</label>
                    <NyTextInput
                        autoFocus
                        name='name'
                        maxLength={16}
                        required
                    />
                </Grid>
                <Grid item xs={12} component={NySubmitAlert} hasCloseBtn />
                <GridDivider />
                <Grid item xs={12}>
                    <NySubmitBtn />
                    <NyResetBtn />
                </Grid>
            </Grid>
        );
    }
);
