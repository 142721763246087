import {events} from '../events';

export const riskMonitorAlertLimitReducer = {
    [events.RISK_MONITOR_ALERT_LIMIT_POST]: (state = {}) => ({
        ...state,
        error: false,
        isLoading: true,
        success: false
    }),
    [events.RISK_MONITOR_ALERT_LIMIT_SUCCESS]: (state) => ({
        ...state,
        error: false,
        isLoading: false,
        success: 'Your request was successfully submitted.'
    }),
    [events.RISK_MONITOR_ALERT_LIMIT_ERROR]: (state, {error}) => ({
        ...state,
        error,
        success: false,
        isLoading: false
    }),
    [events.RISK_MONITOR_ALERT_LIMIT_RESET]: (state) => ({
        ...state,
        error: false,
        isLoading: false,
        success: false
    })
};
